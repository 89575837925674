import axios from 'axios'

const pexelAxios = axios.create({
	baseURL: `https://api.pexels.com`,
	headers: {
		Authorization: process.env.NEXT_PUBLIC_PEXEL_API_KEY,
	},
})

const locales = {
	fr: 'fr-FR',
	en: 'en-US',
}

export default class PexelsApi {
	constructor(type, app_locale, is_background) {
		this.locale = locales[app_locale]
		this.type = type
		this.searchEndpoint = type === 'photos' ? '/v1/search' : '/videos/search'
		this.trendingEndpoint = type === 'photos' ? '/v1/curated' : '/videos/popular'
		this.orientation = is_background ? 'portrait' : ''
	}

	search({ query, per_page, page = 1 }) {
		return pexelAxios.get(this.searchEndpoint, {
			params: {
				query,
				per_page,
				page,
				locale: this.locale,
				orientation: this.orientation,
			},
		})
	}

	getTrending({ per_page, page }) {
		return pexelAxios.get(this.trendingEndpoint, {
			params: {
				per_page,
				page,
			},
		})
	}
}
