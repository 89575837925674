import { DialogContent } from '@mui/material';
import InstagramAssets from 'components/InstagramAssets';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalWrapper } from '../../ModalWrapper';
const ImportFromInstagramModal = forwardRef(function ImportFromInstagramModal({
  storyType,
  onAssetSelect
}, ref) {
  return <ModalWrapper ref={ref} title={<FormattedMessage id='modals.create_snackeet.steps.from_instagram' />} size='lg'>
			<DialogContent>
				<InstagramAssets isMultipleSelect={storyType !== 'single_page'} onAssetSelect={onAssetSelect} />
			</DialogContent>
		</ModalWrapper>;
});
ImportFromInstagramModal.propTypes = {
  storyType: PropTypes.string,
  onAssetSelect: PropTypes.func
};
export default ImportFromInstagramModal;