import { Box, Button, Typography } from '@mui/material';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import { ModalContent, QueryBuilderWrapper } from 'components/styled/Modals';
import { combinators, getValidOptions, operators, translations, validateQuery } from 'lib/constants';
import { useStoryState } from 'lib/editorStore';
import { extractConditionalFields } from 'lib/utils';
import { MaterialActionElement, MaterialDragHandle, MaterialFieldSelector, MaterialNotToggle, MaterialOperatorSelector, MaterialRemoveButton, MaterialValueEditor, MaterialValueSelector } from 'organisms/queryBuilder';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import { FormattedMessage, useIntl } from 'react-intl';
import QueryBuilder from 'react-querybuilder';
import { ModalWrapper } from '../ModalWrapper';

// Custome theme of QueryBuilder with material-ui
const controlElements = {
  addGroupAction: MaterialActionElement,
  addRuleAction: MaterialActionElement,
  cloneGroupAction: MaterialActionElement,
  cloneRuleAction: MaterialActionElement,
  combinatorSelector: MaterialValueSelector,
  fieldSelector: MaterialFieldSelector,
  notToggle: MaterialNotToggle,
  operatorSelector: MaterialOperatorSelector,
  removeGroupAction: MaterialRemoveButton,
  removeRuleAction: MaterialRemoveButton,
  valueEditor: MaterialValueEditor,
  dragHandle: MaterialDragHandle
};
const PageDisplayCondition = forwardRef(({
  condition,
  handleUpdateOnEnterCondition,
  disableBackdropClick = true,
  disableEscapeKeyDown = true
}, ref) => {
  const intl = useIntl();
  const conditionRef = useRef();
  const draftStory = useStoryState(s => s.draftStory);
  const page = useStoryState(s => s.page);
  const [query, setQuery] = useState(condition);
  const [errorCase, setErrorCase] = useState(0);
  const conditionalFields = extractConditionalFields(draftStory, page, true);
  const open = () => {
    conditionRef.current.open();
    setQuery(condition);
  };
  const close = () => {
    conditionRef.current.close();
  };

  // Save condition into context
  function confirmLogics() {
    handleUpdateOnEnterCondition(query);
    conditionRef.current.close();
  }

  // Validate condition each time it changes
  useEffect(() => {
    const NewerrorCase = validateQuery(query.rules);
    setErrorCase(NewerrorCase);
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [query]);
  useImperativeHandle(ref, () => ({
    open,
    close
  }));
  return <ModalWrapper title={intl.messages['modals.condition.header']} ref={conditionRef} size='md' hasCloseButton={false} disableBackdropClick={disableBackdropClick} disableEscapeKeyDown={disableEscapeKeyDown}>
				<ModalContent>
					<QueryBuilderWrapper>
						<QueryBuilderDnD dnd={{
          ...ReactDnD,
          ...ReactDndHtml5Backend
        }}>
							<QueryBuilder controlElements={controlElements} translations={translations} showCombinatorsBetweenRules={true} resetOnOperatorChange={false} combinators={combinators} fields={conditionalFields} getOperators={field => getValidOptions(conditionalFields, field, draftStory)} operators={operators} query={query} onQueryChange={setQuery} />
						</QueryBuilderDnD>
					</QueryBuilderWrapper>
					<Box display='flex' alignItems='center' pl={1}>
						{errorCase > 0 && <Typography color='error' variant='body1'>
								<FormattedMessage id={`conditions.logic_error.case_${errorCase}`} />
							</Typography>}
					</Box>
				</ModalContent>

				<Box width='100%' display='flex' alignItems='center' justifyContent='flex-end' p={1.5}>
					<Button variant='text' style={{
        marginRight: '16px'
      }} onClick={close}>
						{intl.messages['button.cancel']}
					</Button>

					<Button variant='contained' color='primary' disabled={errorCase > 0} onClick={confirmLogics}>
						{intl.messages['button.confirm']}
					</Button>
				</Box>
			</ModalWrapper>;
});
PageDisplayCondition.propTypes = {
  condition: PropTypes.object,
  handleUpdateOnEnterCondition: PropTypes.func,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool
};
PageDisplayCondition.displayName = 'PageDisplayCondition';
export default PageDisplayCondition;