import { Button } from '@mui/material';
import styled from '@styled';
export const NotFoundContainer = styled.div`
	display: grid;
	place-items: center;
	background: var(--background-color);
	text-align: center;
`;
export const GlassPanel = styled.div`
	position: relative;
	background: var(--panel-color);
	border-radius: 20px;
	padding: 40px;
	width: 80%;
	min-width: 280px;
	max-width: 500px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;

	z-index: 0;

	:before {
		content: '';
		z-index: -1;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(-45deg, var(--accent-color) 0%, var(--accent-color-darker) 100%);
		transform: translate3d(30px, 20px, 0) scale(0.95);
		filter: blur(10px);
		opacity: 0.4;
		transition: opacity 0.3s;
		border-radius: inherit;
	}

	::after {
		content: '';
		z-index: -1;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: inherit;
		border-radius: inherit;
	}
`;
export const ErrorText = styled.h1`
	font-size: 8em;
	background: linear-gradient(
		to right,
		var(--accent-color-lighter) 0%,
		var(--accent-color) 50%,
		var(--accent-color-darker) 100%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-weight: bold;
`;
export const SubHeader = styled.h2`
	font-size: 1.5em;
	color: var(--text-color);

	b {
		font-weight: inherit;
		background: linear-gradient(to right, var(--accent-color) 0%, var(--accent-color-darker) 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
`;
export const ReturnButton = styled(Button)`
	&& {
		margin-top: 10px;
		font-size: 1em;
		padding: 10px 50px;
		color: var(--accent-color);
		background-color: var(--button-background-color);
		border: 1px solid var(--accent-color);
		&:hover {
			background-color: var(--button-hover-background-color);
		}
	}
`;