import axios from 'axios'
import { API_URL } from 'lib/constants'
import _split from 'lodash/split'
import * as localStorageSvc from 'services/LocalStorageSvc'

export async function geFileMimeType(url) {
	try {
		const { headers } = await axios.head(url)
		const { 'content-type': contentType } = headers
		const [type, subType] = _split(contentType, '/')
		return { type, subType }
	} catch (error) {
		// Case error, reading content-type blocked by CORS
		// This is so tricky because error only happens with videos
		console.error('Error loading avatar', error)
		return { type: 'video' }
	}
}

export async function verifyCNAME(domain) {
	try {
		const { data } = await axios({
			method: 'get',
			url: `${API_URL}/domains/verify`,
			params: { domain },
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data.success
	} catch (error) {
		return false
	}
}

export async function isPublicEmail(email) {
	const { data } = await axios({
		method: 'get',
		url: `${API_URL}/utilities/is-public-email`,
		params: { email },
	})
	return data
}
