import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { BackgroundVideoContainer, VideoPoster } from '../styled';
export default function Video({
  value,
  inner_style,
  poster,
  outer_style,
  blur
}) {
  return <BackgroundVideoContainer inner_style={inner_style} outer_style={outer_style} $blur={blur}>
			{poster ? <VideoPoster position={inner_style}>
					<img src={poster} alt='' />
				</VideoPoster> : <ReactPlayer url={value} muted={true} volume={0} playsinline={false} height='100%' width='100%' controls={false} loop={false} className='react-player' />}
		</BackgroundVideoContainer>;
}
Video.propTypes = {
  value: PropTypes.string,
  inner_style: PropTypes.object,
  poster: PropTypes.string,
  outer_style: PropTypes.object
};