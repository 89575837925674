import { ModalWrapper } from 'components/Modals';
import PageActionButtonsSettings from 'components/PageActionButtonsSettings';
import PageAnswerButtonsSettings from 'components/PageAnswerButtonsSettings';
import { useStoryDispatch, useStoryState } from 'lib/editorStore';
import useStoryPagesOptions from 'lib/hooks/useStoryPagesOptions';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
export default function AnswersSettingsModal({
  trigger
}) {
  const ref = useRef();
  const intl = useIntl();
  const page = useStoryState(s => s.page);
  const draftStory = useStoryState(s => s.draftStory);
  const dispatch = useStoryDispatch();
  const redirectOptions = useStoryPagesOptions();
  function setPageType(data) {
    dispatch({
      type: 'SET_PAGE_TYPE',
      data
    });
  }
  function onCancel() {
    ref.current.close();
  }
  function onSubmitConfig({
    new_page,
    variables,
    display_live_results
  }) {
    const selectedElement = {
      _id: undefined,
      type: 'answers',
      payload: undefined
    };
    setPageType({
      newPage: new_page,
      variables,
      display_live_results,
      selectedElement
    });
    ref.current.close();
  }
  return <ModalWrapper title={intl.messages['modals.interaction_settings.multiple_choice']} trigger={trigger} disableBackdropClick hasCloseButton={false} ref={ref} size='lg' PaperProps={{
    sx: {
      height: '100%'
    }
  }}>
			{page.answers_properties.type === 'answer' ? <PageAnswerButtonsSettings onCancel={onCancel} onSubmitConfig={onSubmitConfig} nextPagesOptions={redirectOptions} currentPage={page} draftStory={draftStory} buttonType={_.first(page.answers).type} /> : <PageActionButtonsSettings onCancel={onCancel} onSubmitConfig={onSubmitConfig} nextPagesOptions={redirectOptions} currentPage={page} draftStory={draftStory} />}
		</ModalWrapper>;
}
AnswersSettingsModal.propTypes = {
  trigger: PropTypes.object
};