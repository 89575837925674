import Button from '@mui/material/Button';
import styled from '@styled';
import PropTypes from 'prop-types';
const StyledButton = styled(Button)`
	min-width: 20px;
	padding: 0px;
`;
const MaterialRemoveButton = ({
  className,
  handleOnClick,
  label,
  title
}) => <StyledButton variant='text' className={className} title={title} size='small' color='error' onClick={e => handleOnClick(e)}>
		{label}
	</StyledButton>;
MaterialRemoveButton.propTypes = {
  className: PropTypes.string,
  handleOnClick: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};
MaterialRemoveButton.displayName = 'MaterialRemoveButton';
export default MaterialRemoveButton;