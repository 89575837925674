import { Autocomplete, Box, Checkbox, TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PagePreviewStoryButton } from 'atoms';
import _ from 'lodash';
import MuiPhoneNumber from 'material-ui-phone-number';
import PropTypes from 'prop-types';
import { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { EmptyFormFields, FieldConsent, FormInteractionContainer, InterfaceContainer } from '../styled';
function FormPageInterface({
  page,
  theming,
  gdprConsent,
  messages
}) {
  const interaction = _.find(page.interactions, interaction => interaction.type === 'form');
  const form_has_required_field = !!_.find(interaction?.fields, field => field.required);
  return <InterfaceContainer style={page?.interface_style}>
			<div className='interactions'>
				<FormInteraction interaction={interaction} theming={theming} gdprConsent={gdprConsent} />

				<div className='form-actions'>
					{!form_has_required_field && <PagePreviewStoryButton name='next' theming={theming} messages={messages} buttonText={interaction.next_button} />}

					<PagePreviewStoryButton name='send' theming={theming} messages={messages} buttonText={interaction.send_button} />
				</div>
			</div>
		</InterfaceContainer>;
}
FormPageInterface.propTypes = {
  page: PropTypes.object,
  theming: PropTypes.object,
  gdprConsent: PropTypes.object,
  messages: PropTypes.object
};
export default memo(FormPageInterface);

// Use same makestyles as go.snackeet
const useFormStyle = makeStyles({
  field: props => ({
    marginBottom: '8px',
    width: '100%',
    '&:not(:last-of-type)': {
      'margin-bottom': '8px'
    },
    '& .MuiInputBase-input, .MuiOutlinedInput-input': {
      padding: '0 !important',
      outline: 'none',
      textAlign: props.textAlign,
      fontFamily: props.fontFamily || 'Poppins'
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: props.backgroundColor,
      borderColor: props.borderColor,
      borderRadius: props.borderRadius,
      borderWidth: props.borderWidth,
      borderStyle: 'solid',
      color: props.color,
      fontSize: props.fontSize,
      padding: '12px',
      width: props.width,
      fontWeight: props.fontWeight,
      textDecoration: props.textDecoration
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important'
    },
    '& .MuiPhoneNumber-positionStart': {
      pointerEvents: 'none'
    }
  })
});
const formFieldInputProps = {
  readOnly: true,
  disabled: true
};
function FormInteraction({
  interaction,
  theming,
  gdprConsent
}) {
  const fieldStyle = convertFieldstyle(theming.fields);
  const labelStyle = convertFieldstyle(theming.labels);
  const classes = useFormStyle({
    ...fieldStyle
  });
  const themingElement = theming.consent_message;
  if (_.isEmpty(interaction.fields)) {
    return <EmptyFormFields>
				<FormattedMessage id='editor.configuration.empty_form' />
			</EmptyFormFields>;
  }
  return <>
			<FormInteractionContainer>
				{_.map(interaction.fields, field => {
        if (field.type === 'select') {
          return <Fragment key={field._id}>
								{!_.isEmpty(field.title) && <Typography style={labelStyle}>{field.title}</Typography>}
								<Autocomplete id={`combo-box-${field._id}`} fullWidth options={field.settings.options} disabled readOnly renderInput={params => <TextField {...params} className={classes.field} variant='outlined' placeholder={field.placeholder} />} />
							</Fragment>;
        } else if (field.type === 'phone') {
          return <Fragment key={field._id}>
								{!_.isEmpty(field.title) && <Typography style={labelStyle}>{field.title}</Typography>}
								<MuiPhoneNumber variant='outlined' className={classes.field} inputProps={formFieldInputProps} defaultCountry={field?.settings?.defaultCountry || 'fr'} disableCountryCode={false} disableAreaCodes={true} countryCodeEditable={false} autoFormat={false} preferredCountries={['fr', 'us', 'uz']} />
							</Fragment>;
        } else if (field.type === 'checkbox') {
          return <FieldConsent key={field._id} theming={labelStyle}>
								<Checkbox color='primary' disabled />
								<div className='checkbox-title' dangerouslySetInnerHTML={{
              __html: field?.title?.replace(/<(?!\s*\/?\s*a\b)[^>]*>/gi, '')
            }} />
							</FieldConsent>;
        } else {
          return <Fragment key={field._id}>
								{!_.isEmpty(field.title) && <Typography style={labelStyle}>{field.title}</Typography>}
								<TextField variant='outlined' className={classes.field} placeholder={field.placeholder} maxRows={4} type={field.type} inputProps={formFieldInputProps} />
							</Fragment>;
        }
      })}

				{gdprConsent?.active && <FieldConsent key='gpdr-consent' theming={labelStyle}>
						<Checkbox color='primary' />
						<span style={{
          textDecoration: 'underline'
        }}>{gdprConsent?.message}</span>
					</FieldConsent>}

				{interaction?.form_properties?.hasCaptcha && <Box width='80%' display='flex' justifyContent='center' sx={{
        backgroundColor: '#fff',
        borderRadius: '4px',
        margin: '0 auto'
      }}>
						<img height={55} src='/static/element_preview/reCaptcha.png' alt='captcha' />
					</Box>}
			</FormInteractionContainer>
		</>;
}
FormInteraction.propTypes = {
  interaction: PropTypes.object,
  theming: PropTypes.object,
  gdprConsent: PropTypes.object
};
function convertFieldstyle(fieldstyle) {
  const result = _.cloneDeep(fieldstyle);
  switch (fieldstyle?.justifyContent) {
    case 'center':
      result.textAlign = 'center';
      break;
    case 'flex-end':
      result.textAlign = 'right';
      break;
    case 'flex-start':
    default:
      result.textAlign = 'left';
      break;
  }
  return result;
}