import { Delete, Settings, VerifiedUserOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, SvgIcon, TextField, Tooltip, Typography } from '@mui/material';
import { ConfirmationModal, GdprSettingsModal } from 'components/Modals';
import Hotspot from 'components/organisms/Hotspot';
import { FieldSettingsContainer } from 'components/styled/Modals';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
export default function GdprSettings({
  gdprConsent,
  handleChangeGdprConsent
}) {
  const confirmRef = useRef();
  const advancedSettingsRef = useRef();
  function onDeactivateGDPR() {
    handleChangeGdprConsent({
      path: 'active',
      value: false
    });
    confirmRef.current.close();
  }
  return <FieldSettingsContainer>
			<Hotspot message={'edition_menu.form.field.gdpr_tooltip'} IconProps={{
      fontSize: 'small'
    }} />
			<Box width='100%'>
				<div className='consent-container'>
					<Tooltip placement='bottom' title={<FormattedMessage id='edition_menu.form.field_type.consent' />}>
						<div className='sub-type-icon'>
							<SvgIcon>
								<VerifiedUserOutlined />
							</SvgIcon>
						</div>
					</Tooltip>
					<TextField fullWidth variant='outlined' value={gdprConsent.message} multiline minRows={2} onChange={({
          target: {
            value
          }
        }) => handleChangeGdprConsent({
          path: 'message',
          value
        })} />
				</div>
				<Typography variant='body2' textAlign='center'>
					<FormattedMessage id='edition_menu.gdpr.settings.description' />
				</Typography>
				<Box width='100%' display='flex' justifyContent='center' mt={0.6}>
					<Button variant='outlined' size='small' onClick={() => advancedSettingsRef.current.open()} startIcon={<Settings />}>
						<FormattedMessage id='edition_menu.form.field_description.advanced_settings.gdprConsent' />
					</Button>
				</Box>
			</Box>

			<Tooltip title={<FormattedMessage id='modals.form.gdpr_deactivate.title' />} placement='bottom'>
				<div>
					<IconButton size='small' onClick={() => confirmRef.current.open()}>
						<Delete />
					</IconButton>
				</div>
			</Tooltip>

			<ConfirmationModal size='sm' confirmationModalRef={confirmRef} title={<FormattedMessage id='modals.form.gdpr_deactivate.title' />} onConfirm={onDeactivateGDPR} firstLine={<FormattedMessage id='modals.form.gdpr_deactivate.sub_title' />} confirmText={<FormattedMessage id='common.yes' />} cancelText={<FormattedMessage id='common.no' />} />

			<GdprSettingsModal ref={advancedSettingsRef} gdprConsent={gdprConsent} handleChangeGdprConsent={handleChangeGdprConsent} />
		</FieldSettingsContainer>;
}
GdprSettings.propTypes = {
  gdprConsent: PropTypes.object,
  handleChangeGdprConsent: PropTypes.func
};