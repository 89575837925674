import { keyframes } from '@emotion/css';
import { css } from '@emotion/react';
import styled from '@styled';
import { BANNER_CIRCLE_SIZE } from 'lib/constants';
import { transparentize } from 'polished';
const pulseFrames = settings => keyframes`
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 ${transparentize(0.3, settings.end_color)};
	}
	70% {
		transform: scale(1.05);
		box-shadow: 0 0 0 12px ${transparentize(1, settings.end_color)};
	}
	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 ${transparentize(1, settings.end_color)};
	}
`;
const continuousPulseAnimation = ({
  $settings
}) => css`
	animation-name: ${pulseFrames($settings)};
	animation-duration: 2.5s;
	animation-iteration-count: infinite;
`;

// Ratio to compute the size of the play icon.
// The size of the icon should be the smallest possible while being big enough to cover snapshot image play icon
const PLAY_ICON_RATIO = 30 / 53;
export const StoryBorderSvg = styled.svg`
	position: absolute;
	fill: none;
	stroke-linecap: round;
	stroke-width: 2;
	stroke-dasharray: 1;
	stroke-dashoffset: 0;
	overflow: visible;
	top: -2px;
	left: -2px;
	width: ${({
  $size
}) => $size + 4}px;
	height: ${({
  $size
}) => $size + 4}px;
`;
export const StoryBubbleContainer = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	overflow: hidden;
	position: relative;
	align-items: center;
	flex-shrink: 0;
	user-select: none;
	border-radius: 50%;
	justify-content: center;

	img,
	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;
export const WidgetElementPreview = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: ${({
  theme
}) => theme.spacing(1)};
	cursor: pointer;

	.MuiSvgIcon-root {
		color: white;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		filter: drop-shadow(0px 5px 5px rgba(103, 102, 102, 0.82));
	}
`;
export const WidgetElementName = styled.div`
	font-size: 14px;
	text-align: center;
	display: -webkit-box;
	text-overflow: ellipsis;
	overflow: hidden;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	width: 100%;
`;
export const WidgetElement = styled.div`
	flex-shrink: 0;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: ${({
  theme
}) => theme.spacing(0, 1)};
	width: ${({
  $size
}) => $size + 30}px;
	position: relative;
	--banner-story-height: ${({
  $size
}) => $size ?? BANNER_CIRCLE_SIZE}px;
	--banner-story-width: ${({
  $size
}) => $size ?? BANNER_CIRCLE_SIZE}px;

	${StoryBubbleContainer} {
		width: calc(var(--banner-story-width) - 4px);
		height: calc(var(--banner-story-height) - 4px);
		transform: translateY(2px);
		border-radius: ${({
  $settings
}) => $settings?.border_radius}%;
	}

	${WidgetElementPreview} {
		border-radius: ${({
  $settings
}) => $settings?.border_radius}%;
		overflow: visible;
		transition: transform 0.1s linear;
		width: var(--banner-story-width);
		height: var(--banner-story-height);

		.MuiSvgIcon-root {
			width: calc(var(--banner-story-width) * ${PLAY_ICON_RATIO});
			height: calc(var(--banner-story-height) * ${PLAY_ICON_RATIO});
		}
	}
`;
export const StoryDimmer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	backdrop-filter: blur(1px) grayscale(1);
	border-radius: ${({
  $settings
}) => $settings?.border_radius}%;
`;
export const ActionButtons = styled.div`
	margin-top: ${({
  theme
}) => theme.spacing(1)};
	display: flex;
	align-items: center;

	.MuiIconButton-root {
		padding: 2px;
		border-radius: 8px;
	}

	.hoverable {
		transition: opacity 300ms ease-in;
		opacity: 0;
	}

	&:hover {
		.hoverable {
			opacity: 1;
		}
	}
`;
export const BubbleActionArea = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	&:hover {
		${WidgetElementPreview} {
			transform: scale(1.01);
		}

		${ActionButtons} {
			.hoverable {
				opacity: 1;
			}
		}
	}

	&.continuous-pulse {
		&:first-of-type {
			${WidgetElementPreview} {
				${continuousPulseAnimation};
			}
		}
	}
`;