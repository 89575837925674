import { Box, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import SearchBar from 'components/atoms/SearchBar';
import Loader from 'components/Loader';
import { ListContainer, MediaAssetCard } from 'containers/Editor/StoryEditor/MediaAssets/Galleries/MediaComponent/MediaComponent.style';
import { API_URL } from 'lib/constants';
import _ from 'lodash';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ImageSvc from 'services/ImageSvc';
import { useDebounce } from 'use-debounce';
export default function IllustrationsTab({
  onSelect
}) {
  const intl = useIntl();
  const customer = useSelector(state => state.customer);
  const [query, setSearchQuery] = useState('');
  const [debouncedQuery] = useDebounce(query, 500);
  const searchRegex = new RegExp(debouncedQuery, 'gi');
  const {
    data: images,
    isLoading
  } = useQuery({
    queryKey: ['illustrations', {
      search: debouncedQuery,
      lang: customer.locale
    }],
    queryFn: ({
      queryKey
    }) => {
      const [, {
        search,
        lang
      }] = queryKey;
      if (!search) return ImageSvc.getPreview();
      return ImageSvc.getImage({
        search,
        lang
      });
    }
  });
  return <>
			<Box sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      mb: 1
    }}>
				<SearchBar fullWidth variant='standard' value={query} onChange={setSearchQuery} placeholder={intl.formatMessage({
        id: `edition_menu.placeholder.images`
      })} disableUnderline sx={{
        maxWidth: 'unset',
        minWidth: 300
      }} />
				<Typography variant='h5' sx={{
        flexShrink: 0
      }}>
					<FormattedMessage id='edition_menu.image_selection_instructions' />
				</Typography>
			</Box>

			<ListContainer>
				{isLoading ? <Loader $heightPx={100} size={50} name='ThreeBounce' /> : _.map(images, (image, index) => {
        const name = image[customer.locale];
        const url = `${API_URL}/image/${image.type}/${image.id}`;
        return <MediaAssetCard key={index} onClick={() => onSelect({
          url
        })}>
								<img src={url} title={name} />
							</MediaAssetCard>;
      })}
			</ListContainer>
		</>;
}