import copy from 'copy-to-clipboard'
import { useSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useIntl } from 'react-intl'

export default function useClipboard() {
	const intl = useIntl()
	const { enqueueSnackbar } = useSnackbar()
	const hasClipboardItemSupport = typeof ClipboardItem !== 'undefined'

	const onCopySuccess = useCallback(() => {
		enqueueSnackbar(intl.messages['common.clipboard.success.copy'], {
			variant: 'success',
		})
	}, [enqueueSnackbar, intl.messages])

	const onCopyFail = useCallback(() => {
		enqueueSnackbar(intl.messages['common.clipboard.error.copy'], {
			variant: 'error',
		})
	}, [enqueueSnackbar, intl.messages])

	const copyToClipboard = useCallback(
		(type) => async (content) => {
			try {
				if (hasClipboardItemSupport) {
					// copy with Clipboard Item
					const blob = new Blob([content], { type })
					const data = [new ClipboardItem({ [type]: blob })]
					await navigator.clipboard.write(data)
				} else {
					copy(content, { format: type })
				}
				onCopySuccess()
			} catch (err) {
				onCopyFail()
			}
		},
		[onCopyFail, onCopySuccess]
	)

	return {
		hasClipboardItemSupport,
		copyHtml: copyToClipboard('text/html'),
		copyText: copyToClipboard('text/plain'),
	}
}
