import Button from '@mui/material/Button';
import styled from '@styled';
const ConfettiButton = styled(Button)`
	background-color: white;
	width: 100%;
	min-width: 40px;
	height: 32px;
	padding: 0 0;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0.5px solid rgb(72, 72, 71);
	border-radius: 4px;
	cursor: pointer;
	transition: all ease-in-out 100ms;

	&:hover {
		border: 1px solid rgb(0, 0, 0);
	}

	label {
		cursor: pointer;
	}
`;
export default ConfettiButton;