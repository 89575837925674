/* eslint-disable react/display-name */
import PropTypes from 'prop-types';
import Calendar from './Calendar';
import CountdownBlock from './Countdown';
import Icon from './Icon';
import IframeBlock from './Iframe';
import Image from './Image';
import Leaderboard from './Leaderboard';
import Location from './Location';
import MessengerBlock from './MessengerBlock';
import Networks from './NetworksBlock';
import Shape from './Shape';
import ShareBlock from './ShareBlock';
import SocialNetworkBlock from './SocialNetworkBlock';
import Text from './Text';
import Video from './Video';
const renderers = {
  image: context => <Image {...context} />,
  text: context => <Text {...context} />,
  video: context => <Video {...context} />,
  calendar: context => <Calendar {...context} />,
  shape: context => <Shape {...context} />,
  icon: context => <Icon {...context} />,
  location: context => <Location {...context} />,
  social_network: context => <SocialNetworkBlock {...context} />,
  messenger: context => <MessengerBlock {...context} />,
  iframe: context => <IframeBlock {...context} />,
  countdown: context => <CountdownBlock {...context} />,
  share: context => <ShareBlock {...context} />,
  leaderboard: context => <Leaderboard {...context} />,
  networks: context => <Networks {...context} />,
  default: () => null
};
export default function Block({
  type,
  ...context
}) {
  const renderer = renderers[type.replace('-', '_')] || renderers.default;
  return renderer(context);
}
Block.propTypes = {
  type: PropTypes.string.isRequired,
  context: PropTypes.object
};