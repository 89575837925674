// Taken from https://www.npmjs.com/package/mini-virtual-list
import { useLayoutEffect, useState } from 'react'

const useScroller = <T extends HTMLElement = HTMLElement>(
	node: HTMLElement
): { scrollTop: number; isScrolling: boolean } => {
	const [isScrolling, setIsScrolling] = useState(false)
	const [scrollTop, setScrollTop] = useState(0)

	useLayoutEffect(() => {
		let tick: number | undefined

		if (node) {
			const handleScroll = () => {
				if (tick) return
				tick = window.requestAnimationFrame(() => {
					setScrollTop(node.scrollTop)
					// eslint-disable-next-line no-void
					tick = void 0
				})
			}

			node.addEventListener('scroll', handleScroll)
			return () => {
				node.removeEventListener('scroll', handleScroll)
				if (tick) window.cancelAnimationFrame(tick)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [node])

	useLayoutEffect(() => {
		setIsScrolling(true)
		const to = window.setTimeout(() => {
			// This is here to prevent premature bail outs while maintaining high resolution
			// unsets. Without it there will always bee a lot of unnecessary DOM writes to style.
			setIsScrolling(false)
		}, 1000 / 6)
		return () => window.clearTimeout(to)
	}, [scrollTop])

	return { scrollTop, isScrolling }
}

export default useScroller
