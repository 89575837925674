import { CameraAlt, Mic, TextFields, Videocam } from '@mui/icons-material';
import { Box } from '@mui/material';
import _ from 'lodash';
import { memo } from 'react';
import { MediaAnswerButton, MediaAnswersInteractionContainer } from '../styled';
function MediaAnswerPageInterface({
  page
}) {
  const media_answer = _.find(page.interactions, ['type', 'media_answer']);
  const {
    _id,
    hasVideo,
    hasAudio,
    hasText,
    videoTitle,
    hasSelfie = false,
    selfieTitle = 'Selfie',
    audioTitle,
    textTitle,
    style,
    title,
    containerStyle = {
      color: '#000000',
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      fontFamily: 'Poppins',
      fontSize: '16px'
    }
  } = media_answer;
  return <MediaAnswersInteractionContainer style={page.interface_style}>
			<div className='interactions'>
				<Box width='100%' p={1} textAlign='center' className='answer-media-options' style={{
        backgroundColor: containerStyle?.backgroundColor || 'rgba(255, 255, 255, 0.6)'
      }}>
					<Box style={{
          fontFamily: containerStyle?.fontFamily || 'Poppins',
          fontSize: containerStyle?.fontSize || '16px',
          color: containerStyle?.color || '#000000'
        }} lineHeight={1.5}>
						{title}
					</Box>

					<Box width='100%' height={90} mt={1.5} mb={0.5} display='flex' justifyContent='space-evenly' alignItems='center' px={1.5} sx={{
          gap: '10px'
        }}>
						{hasVideo && <MediaAnswerButton fullWidth variant='contained' size='large' $isAlone={!hasAudio && !hasText && !hasSelfie} style={{
            fontFamily: 'Poppins',
            ...style
          }}>
								<Videocam fontSize='large' />
								{videoTitle}
							</MediaAnswerButton>}
						{hasSelfie && <MediaAnswerButton fullWidth variant='contained' size='large' $isAlone={!hasAudio && !hasText && !hasVideo} style={{
            fontFamily: 'Poppins',
            ...style
          }}>
								<CameraAlt fontSize='large' />
								{selfieTitle}
							</MediaAnswerButton>}
						{hasText && <MediaAnswerButton fullWidth variant='contained' size='large' $isAlone={!hasVideo && !hasAudio && !hasSelfie} style={{
            fontFamily: 'Poppins',
            ...style
          }}>
								<TextFields fontSize='large' />
								{textTitle}
							</MediaAnswerButton>}
						{hasAudio && <MediaAnswerButton fullWidth variant='contained' size='large' $isAlone={!hasVideo && !hasText && !hasSelfie} style={{
            fontFamily: 'Poppins',
            ...style
          }}>
								<Mic fontSize='large' />
								{audioTitle}
							</MediaAnswerButton>}
					</Box>
				</Box>
			</div>
		</MediaAnswersInteractionContainer>;
}
export default memo(MediaAnswerPageInterface);