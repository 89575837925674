import styled from '@styled';
import { extractPositionProperties } from 'lib/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { SocialIcon } from 'react-social-icons';
import BlockWrapper from './BlockWrapper';
function SocialNetworkBlock({
  outer_style,
  animation,
  networks,
  links,
  iconSize
}) {
  if (_.isEmpty(networks)) {
    return null;
  }
  const {
    positionProperties,
    visualProperties
  } = extractPositionProperties(outer_style);
  return <BlockWrapper animation={animation} positionProperties={positionProperties}>
			{/* { interaction.title && <div>{interaction.title}</div> } */}
			<div style={{
      ...visualProperties
    }}>
				{_.map(networks, (network, index) => shareRenderers(network)({
        key: index,
        links,
        size: iconSize
      }))}
			</div>
		</BlockWrapper>;
}
SocialNetworkBlock.propTypes = {
  outer_style: PropTypes.object,
  animation: PropTypes.object,
  networks: PropTypes.array,
  isRedirected: PropTypes.bool,
  links: PropTypes.object,
  iconSize: PropTypes.number
};
export default SocialNetworkBlock;

/* eslint-disable react/prop-types */
/* eslint-disable react/display-name  */
// 👉 Important: use React Key to force components reset on page change
function shareRenderers(name) {
  return ({
    key,
    links,
    size
  }) => {
    if (name === 'Website') {
      return <SocialNetworkLogo key={key} fgColor='white' bgColor='#ccc' size={size} />;
    }
    return <SocialNetworkLogo key={key} network={name === 'Twitter' ? 'x' : name.toLowerCase()} fgColor='white' size={size} $isInsta={name === 'Instagram'} onClick={() => window.open(links[name.toLowerCase()], '_blank')} />;
  };
}
/* eslint-enable react/display-name */
/* eslint-enable react/prop-types */

const SocialNetworkLogo = styled(SocialIcon)`
	width: ${({
  size
}) => size}px !important;
	height: ${({
  size
}) => size}px !important;
	margin: ${({
  theme
}) => theme.spacing(0.5, 1)};
	cursor: pointer;
	transition: all ease-out 0.3s;

	.social-container {
		background: ${({
  $isInsta
}) => $isInsta ? `radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)` : 'unset'};
		border-radius: ${({
  $isInsta
}) => $isInsta ? `50%` : 'none'};
	}

	.social-svg-mask {
		fill: ${({
  $isInsta
}) => $isInsta ? `transparent !important` : 'initial'};
	}

	&:hover {
		filter: brightness(0.9);
	}
`;