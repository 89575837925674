import { SvgIcon } from '@mui/material'

export default function LinkIcon(props) {
	return (
		<SvgIcon width='24px' height='24px' viewBox='0 0 36 36' {...props}>
			<path
				fill='currentColor'
				d='m17.6 24.32l-2.46 2.44a4 4 0 0 1-5.62 0a3.92 3.92 0 0 1 0-5.55l4.69-4.65a4 4 0 0 1 5.62 0a3.86 3.86 0 0 1 1 1.71a2 2 0 0 0 .27-.27l1.29-1.28a5.89 5.89 0 0 0-1.15-1.62a6 6 0 0 0-8.44 0l-4.7 4.69a5.91 5.91 0 0 0 0 8.39a6 6 0 0 0 8.44 0l3.65-3.62h-.5a8 8 0 0 1-2.09-.24Z'
			/>
			<path
				fill='currentColor'
				d='M28.61 7.82a6 6 0 0 0-8.44 0l-3.65 3.62h.49a8 8 0 0 1 2.1.28l2.46-2.44a4 4 0 0 1 5.62 0a3.92 3.92 0 0 1 0 5.55l-4.69 4.65a4 4 0 0 1-5.62 0a3.86 3.86 0 0 1-1-1.71a2 2 0 0 0-.28.23l-1.29 1.28a5.89 5.89 0 0 0 1.15 1.62a6 6 0 0 0 8.44 0l4.69-4.65a5.92 5.92 0 0 0 0-8.39Z'
			/>
		</SvgIcon>
	)
}
