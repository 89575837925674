import * as React from 'react'

function SvgText(props) {
	return (
		<svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M23.846 25.673H16.14L14.674 30H10l7.941-21h4.074L30 30h-4.674l-1.48-4.327zm-6.52-3.505h5.333l-2.681-7.86-2.652 7.86z'
				fill='#324370'
			/>
		</svg>
	)
}

export default SvgText
