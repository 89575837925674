import { Button, DialogActions, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalWrapper } from '../ModalWrapper';
import { BannerTypeSelector } from './CreateBannerModal';
export default function EditBannerTypeModal({
  initialBannerType,
  trigger,
  onUpdate
}) {
  const modalRef = useRef();
  const [bannerType, setBannerType] = useState(initialBannerType);
  function onClose() {
    modalRef.current?.close();
    setBannerType(initialBannerType);
  }
  function onConfirm() {
    onUpdate(bannerType);
    modalRef.current?.close();
  }
  const onBannerTypeSelect = selectedType => () => {
    if (selectedType === bannerType) {
      onConfirm();
    } else {
      setBannerType(selectedType);
    }
  };
  return <ModalWrapper ref={modalRef} title={<FormattedMessage id='modal.widget_banner.create_widget.title' />} trigger={trigger} hasCloseButton={false}>
			<DialogContent>
				<BannerTypeSelector bannerType={bannerType} onBannerTypeSelect={onBannerTypeSelect} />
			</DialogContent>

			<DialogActions>
				<Button variant='text' onClick={onClose}>
					<FormattedMessage id='button.cancel' />
				</Button>

				<Button variant='outlined' color='secondary' onClick={onConfirm}>
					<FormattedMessage id='button.confirm' />
				</Button>
			</DialogActions>
		</ModalWrapper>;
}
EditBannerTypeModal.propTypes = {
  initialBannerType: PropTypes.string,
  trigger: PropTypes.node,
  onUpdate: PropTypes.func
};