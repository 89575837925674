import axios from 'axios'
import { API_URL } from 'lib/constants'
import * as localStorageSvc from 'services/LocalStorageSvc'

const PublishedStoriesSvc = {
	get,
	getAll,
	partialUpdate,
	getTemplates,
	getPageTemplates,
	convertPageTemplate,
	updateIntegration,
	verifyStorySlug,
}

export default PublishedStoriesSvc

async function get({ story_id, project }) {
	const { data } = await axios.get(`${API_URL}/published/${story_id}`, {
		params: {
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
	return data.publishedStory
}

async function getAll({ project, fields }) {
	try {
		const { data } = await axios.get(`${API_URL}/published`, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
			params: {
				project,
				fields,
			},
		})
		return data.results
	} catch (err) {
		console.log('published.getAll', err)
		return []
	}
}

async function partialUpdate({ story_id, project, $set, $unset }) {
	return await axios.patch(
		`${API_URL}/published/${story_id}`,
		{ $set, $unset },
		{
			params: {
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		}
	)
}

async function getTemplates() {
	try {
		const { data } = await axios.get(`${API_URL}/published/templates`, {
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data.results
	} catch (err) {
		console.log('Snackeet.getTemplates', err)
		return []
	}
}

async function getPageTemplates({ project }) {
	try {
		const { data } = await axios.get(`${API_URL}/published/templates/pages`, {
			params: {
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data.pageTemplates
	} catch (err) {
		console.log('Snackeet.getPageTemplates', err)
		return {}
	}
}

async function convertPageTemplate({ project, pageTemplate }) {
	try {
		const { data } = await axios.post(`${API_URL}/published/templates/convert_page`, pageTemplate, {
			params: {
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data.pageTemplate
	} catch (err) {
		console.log('Snackeet.convertPageTemplate', err)
		return {}
	}
}

async function updateIntegration({ story_id, project, integrationName, integration }) {
	try {
		return await axios.patch(`${API_URL}/published/${story_id}/integrations`, integration, {
			params: {
				project,
				integrationName,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
	} catch (err) {
		console.log('Snackeet.getTemplates', err)
	}
}

async function verifyStorySlug({ story_slug, story_fqdn }) {
	if (!story_fqdn && story_slug === '') {
		return Promise.resolve(false)
	}

	const { data } = await axios.get(`${API_URL}/published/verify-slug`, {
		params: {
			story_slug,
			story_fqdn,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
	if (!data.success) {
		return false
	}
	return data.isUnique
}
