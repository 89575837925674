import { css } from '@emotion/react'
import {
	Accordion as MuiAccordion,
	AccordionDetails as MuiAccordionDetails,
	AccordionSummary as MuiAccordionSummary,
	Drawer,
} from '@mui/material'
import styled from '@styled'
import { APP_HEADER_HEIGHT, EDITOR_DRAWER_WIDTH, SLECTOR_DRAWER_WIDTH } from 'lib/constants'
import { transparentize } from 'polished'

// Base Templates
export const EditorPageLayout = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	overflow-x: auto;
	scrollbar-gutter: auto;
`
export const EditorSettingsSection = styled.div`
	background-color: ${({ theme }) => theme.palette.background.paper};
	padding: ${(props) => props.theme.spacing(3)};
	box-shadow: ${(props) => props.theme.shadows[1]};
	margin: 1px;
`

export const Accordion = styled(MuiAccordion)`
	border-radius: 4px;
	box-shadow: none;
	margin: ${(props) => props.theme.spacing(1, 0)};
	&.Mui-expanded {
		margin: ${(props) => props.theme.spacing(1, 0)};
	}

	::before {
		content: none !important;
	}
	::after {
		content: none !important;
	}
`
export const AccordionSummary = styled(MuiAccordionSummary)`
	margin: 0px !important;
	min-height: 64px;
`
export const AccordionDetails = styled(MuiAccordionDetails)`
	padding: ${(props) => props.theme.spacing(0, 1)};
	display: flex;
	flex-direction: column;
`

/* ---- CSS HELPERS ---- */

export const interactionsSelectedStyle = ({ theme }) => css`
	box-shadow: inset 0 0 0 2px ${theme.palette.primary.main};
	background-color: ${transparentize(0.8, theme.palette.primary.main)};
	pointer-events: all;
	cursor: move;
`

export const interfaceElementSelectedStyle = ({ theme }) => css`
	outline: 3px solid ${theme.palette.secondary.main};
`

export const EditorDrawer = styled(Drawer)``

EditorDrawer.defaultProps = {
	ModalProps: {
		BackdropProps: {
			invisible: true,
		},
	},
	PaperProps: {
		sx: {
			top: `${APP_HEADER_HEIGHT}px`,
			height: `calc(100% - ${APP_HEADER_HEIGHT}px)`,
			width: `${EDITOR_DRAWER_WIDTH}px`,
		},
	},
}

export const SlectorDrawer = styled(Drawer)``

SlectorDrawer.defaultProps = {
	ModalProps: {
		BackdropProps: {
			invisible: true,
		},
	},
	PaperProps: {
		sx: {
			top: `${APP_HEADER_HEIGHT}px`,
			height: `calc(100% - ${APP_HEADER_HEIGHT}px)`,
			width: `${SLECTOR_DRAWER_WIDTH}px`,
		},
	},
}
