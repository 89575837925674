import _ from 'lodash'
import { nanoid } from 'nanoid'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrganizationId } from 'redux/organization/selectors'
import { getCurrentProjectId, getProjectRecentlyUsed } from 'redux/project/selectors'
import { updateProject } from 'redux/project/slice'
import ProjectsSvc from 'services/ProjectsSvc'

export default function useProjectRecentlyUsed() {
	const project_id = useSelector(getCurrentProjectId)
	const organization_id = useSelector(getCurrentOrganizationId)
	const currentRecentlyUsed = useSelector(getProjectRecentlyUsed)
	const dispatch = useDispatch()

	const updateRecentlyUsed = useCallback(
		async (type, value) => {
			const newRecentlyUsed = _.cloneDeep(currentRecentlyUsed)

			// find index of url in recently_used
			const index = _.findIndex(newRecentlyUsed[type], (media) => _.isEqual(media.url, value.url))

			if (index !== 0) {
				if (index > 0) {
					newRecentlyUsed[type].splice(index, 1)
				}

				if (newRecentlyUsed[type].length === 15) {
					newRecentlyUsed[type].pop()
				}

				newRecentlyUsed[type].unshift({ _id: nanoid(), ...value })

				await ProjectsSvc.update(project_id, organization_id, {
					recently_used: newRecentlyUsed,
				})

				dispatch(updateProject({ recently_used: newRecentlyUsed }))
			}
		},
		[currentRecentlyUsed, dispatch, organization_id, project_id]
	)

	const removeRecentlyUsed = useCallback(
		async (type, file_id) => {
			const newRecentlyUsed = _.cloneDeep(currentRecentlyUsed)

			// find index of url in recently_used
			const index = _.findIndex(newRecentlyUsed[type], (media) => _.isEqual(media.file_id, file_id))

			if (index > -1) {
				newRecentlyUsed[type].splice(index, 1)

				await ProjectsSvc.update(project_id, organization_id, {
					recently_used: newRecentlyUsed,
				})

				dispatch(updateProject({ recently_used: newRecentlyUsed }))
			}
		},
		[currentRecentlyUsed, dispatch, organization_id, project_id]
	)

	const updateRecentlyUsedStories = useCallback(
		async (stories) => {
			const newRecentlyUsed = _.cloneDeep(currentRecentlyUsed)

			newRecentlyUsed.storiesGraph = stories

			await ProjectsSvc.update(project_id, organization_id, {
				recently_used: newRecentlyUsed,
			})

			dispatch(updateProject({ recently_used: newRecentlyUsed }))
		},
		[currentRecentlyUsed, dispatch, organization_id, project_id]
	)

	const removeRecentlyUsedStory = useCallback(
		async (story_id) => {
			const newRecentlyUsed = _.cloneDeep(currentRecentlyUsed)

			// find index of url in recently_used
			const index = _.findIndex(newRecentlyUsed?.storiesGraph, (story) => _.isEqual(story.story_id, story_id))

			if (index > -1) {
				newRecentlyUsed.storiesGraph.splice(index, 1)

				await ProjectsSvc.update(project_id, organization_id, {
					recently_used: newRecentlyUsed,
				})

				dispatch(updateProject({ recently_used: newRecentlyUsed }))
			}
		},
		[currentRecentlyUsed, dispatch, organization_id, project_id]
	)

	return {
		updateRecentlyUsed,
		removeRecentlyUsed,
		updateRecentlyUsedStories,
		removeRecentlyUsedStory,
	}
}
