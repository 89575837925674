import { FormControl } from '@mui/material';
import styled from '@styled';
import { TextAnswerButton } from 'containers/Editor/StoryEditor/Preview/Answers.style';
import { SocialIcon } from 'react-social-icons';
import { snackeet_dark_grey } from 'styles/colors';
export const EditorContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow: auto;

	.title {
		font-weight: bold;
		font-size: 22px;
		margin-bottom: 16px;
	}
`;
export const EditorSettingsSection = styled.div`
	width: 80%;
	max-width: 900px;
	background-color: white;
	padding: ${props => props.theme.spacing(2, 3)};
	box-shadow: ${props => props.theme.shadows[1]};
	border-radius: 8px;
	margin: 16px auto;

	.preview-container {
		padding: ${props => props.theme.spacing(1)};
		border: 1px solid ${snackeet_dark_grey};
		border-radius: 4px;
		background: rgba(0, 0, 0, 0.06);

		.MuiTypography-root {
			margin: 12px 0px;
		}
	}

	.brand-logo-container {
		width: 150px;
		height: 150px;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.no-logo {
			font-weight: bold;
			font-size: 32px;
			font-family: 'Archivo Black';
			color: ${snackeet_dark_grey};
		}
	}

	.brand-favicon-container {
		width: 65px;
		height: 65px;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.no-logo {
			font-weight: bold;
			font-size: 32px;
			font-family: 'Archivo Black';
			color: ${snackeet_dark_grey};
		}
	}

	@media (min-width: 1376px) {
		max-width: 1100px;
	}
`;
export const ProjectsAccessWrapper = styled(FormControl)`
	margin: ${({
  theme
}) => theme.spacing(2, 0)};
	width: 100%;
	justify-content: space-evenly;
	flex-direction: row;
`;
export const SettingsField = styled.div`
	display: grid;
	grid-template-columns: 120px 1fr;
	align-items: center;
	margin: ${({
  theme
}) => theme.spacing(1)};
	padding: ${({
  theme
}) => theme.spacing(1)};
	// Border css taken from Material UI Outlined input
	border-width: 0.5px;
	border-style: solid;
	border-radius: ${({
  theme
}) => theme.shape.borderRadius}px;
	border-color: ${({
  theme
}) => theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'};

	&:hover {
		border-color: ${({
  theme
}) => theme.palette.text.primary};
	}

	.field-name {
		font-weight: bold;
		font-size: 14px;
	}

	.field-value {
		padding-left: ${({
  theme
}) => theme.spacing(1)};
	}
`;
export const BrandButton = styled(TextAnswerButton)`
	background-color: ${({
  disabled
}) => disabled ? '#CCCCCC !important' : 'inherit'};

	&.MuiButtonBase-root {
		width: 250px;
		&:hover {
			transform: scale(1.02);
		}
	}
`;
export const SocialNetworkLogo = styled(SocialIcon)`
	width: 40px !important;
	height: 40px !important;
	margin: ${({
  theme
}) => theme.spacing(0.5, 1)};

	.social-container {
		background: ${({
  $isInsta
}) => $isInsta ? `radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)` : 'unset'};
		border-radius: ${({
  $isInsta
}) => $isInsta ? `50%` : 'none'};
	}

	.social-svg-mask {
		fill: ${({
  $isInsta
}) => $isInsta ? `transparent !important` : 'initial'};
	}
`;