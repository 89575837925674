// Required: project, projects list, organization_id, customer role in organization
// Via role of client in organization -> 2 cases
// Case admin or super_admin: Display projects list to choose accessible projects
// Case agent: Only current project
// Then confirmation step and loading

import { Alert, Box, Button, Checkbox, CircularProgress, DialogActions, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import { ModalContent, ProjectsAccessWrapper } from 'components/styled/Modals';
import { sleep } from 'lib/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCustomerRights } from 'redux/customer/selectors';
import { getCurrentOrganizationId } from 'redux/organization/selectors';
import { getCurrentProjectId } from 'redux/project/selectors';
import OrganizationsSvc from 'services/OrganizationsSvc';
import useAsyncEffect from 'use-async-effect';
import { useImmer } from 'use-immer';
import { ModalWrapper } from './ModalWrapper';
export default function CreateBrandModal({
  onCreate,
  trigger
}) {
  const intl = useIntl();
  const modalWrapperRef = useRef();
  const organizationId = useSelector(getCurrentOrganizationId);
  const projectId = useSelector(getCurrentProjectId);
  const currentCustomerRights = useSelector(getCustomerRights);
  const [states, setStates] = useImmer({
    accessProjects: [projectId],
    name: '',
    organizationProjects: [],
    is_saving: false
  });
  useAsyncEffect(async isMounted => {
    try {
      const projects = await OrganizationsSvc.getProjects(organizationId);
      if (isMounted()) {
        setStates(draft => {
          draft.organizationProjects = projects;
        });
      }
    } catch (error) {}
  }, []);

  // Handle Change Project Access
  function handleProjectAccessChange(project_id) {
    if (!_.includes(states.accessProjects, project_id)) {
      setStates(draft => {
        draft.accessProjects.push(project_id);
      });
    } else {
      setStates(draft => {
        draft.accessProjects = draft.accessProjects.filter(_id => _id !== project_id);
      });
    }
  }

  // Handle Change Brand name
  function handleChangeBrandName(value) {
    setStates(draft => {
      draft.name = value;
    });
  }
  // Create a new Brand
  async function createNewBrand() {
    setStates(draft => {
      draft.is_saving = true;
    });
    const data = await onCreate(states.name, states.accessProjects);
    await sleep(1000);
    setStates(draft => {
      draft.is_saving = false;
    });
    modalWrapperRef.current?.close();
  }
  return <>
			<ModalWrapper title={intl.messages['modals.create_brand.title']} trigger={trigger} size='sm' ref={modalWrapperRef}>
				<ModalContent>
					<Box mb={1.5}>
						<Typography variant='h3' style={{
            marginBottom: '4px'
          }}>
							{intl.messages['modals.create_brand.name_label']}
						</Typography>
						<TextField fullWidth variant='outlined' placeholder={intl.messages['modals.create_brand.name_placeholder']} value={states.name} onChange={evt => handleChangeBrandName(evt.target.value)} error={_.isEmpty(states.name)} />
					</Box>

					{currentCustomerRights.isAdmin ? <>
							<Typography variant='h3'>{intl.messages['modals.create_brand.access_admin']}</Typography>
							<ProjectsAccessWrapper component='fieldset'>
								<FormGroup>
									{_.map(states.organizationProjects.slice(0, Math.ceil(states.organizationProjects.length / 2)), project => <FormControlLabel key={project._id} control={<Checkbox checked={_.includes(states.accessProjects, project._id)} name={project._id} onChange={() => handleProjectAccessChange(project._id)} />} label={project.name} />)}
								</FormGroup>
								<FormGroup>
									{_.map(states.organizationProjects.slice(Math.ceil(states.organizationProjects.length / 2)), project => <FormControlLabel key={project._id} control={<Checkbox checked={_.includes(states.accessProjects, project._id)} name={project._id} onChange={() => handleProjectAccessChange(project._id)} />} label={project.name} />)}
								</FormGroup>
							</ProjectsAccessWrapper>
						</> : <>
							<Typography variant='h3'>{intl.messages['modals.create_brand.access_agent']}</Typography>
							<Alert severity='warning'>
								<Typography variant='body2'>
									{intl.messages['modals.create_brand.warning_agent']}
								</Typography>
							</Alert>
						</>}
				</ModalContent>

				<DialogActions>
					<Button variant='text' onClick={() => modalWrapperRef.current?.close()}>
						<FormattedMessage id='button.cancel' />
					</Button>
					<Button variant='outlined' color='secondary' onClick={() => createNewBrand()} disabled={states.is_saving || _.isEmpty(states.name)}>
						{states.is_saving && <CircularProgress style={{
            marginRight: '8px'
          }} color='primary' size={20} />}
						<FormattedMessage id='button.confirm' />
					</Button>
				</DialogActions>
			</ModalWrapper>
		</>;
}
CreateBrandModal.propTypes = {
  onCreate: PropTypes.func,
  trigger: PropTypes.object
};