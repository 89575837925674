import { CircularProgress } from '@mui/material';
import styled from '@styled';
export const TimerLayer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden
	box-sizing: border-box;
	pointer-events: none;
	top: 0;
	left: 0;
`;
export const TimerContainer = styled.div`
	border: none;
	pointer-events: auto;
	transform: ${({
  $outerStyle
}) => $outerStyle.transform};
	color: ${({
  $innerStyle
}) => $innerStyle.color};
	width: ${({
  $outerStyle
}) => $outerStyle.width};
`;
export const CustomCircularProgress = styled(CircularProgress)`
	width: ${({
  width
}) => width} !important;
	height: ${({
  height
}) => height} !important;
	color: ${({
  $borderColor
}) => $borderColor} !important;

	.MuiCircularProgress-circle {
		stroke-width: ${({
  $borderWidth
}) => $borderWidth};
		fill: ${({
  $bgColor
}) => $bgColor};
	}
`;