import { Box, TextField } from '@mui/material';
import styled from '@styled';
import VariablesSelector from 'organisms/VariablesSelector';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
export default function TextFieldVariables({
  textValue,
  variableParams,
  placeholder,
  onChange,
  minRows = 2,
  maxRows = 4,
  disabled = false,
  className,
  isMultiline = true,
  isSendingBlue = false,
  localVariable,
  outlined = true,
  ...delegatedProps
}) {
  const inputRef = useRef();
  const [text, setText] = useState(textValue || '');
  const [cursorSelector, setCursorSelector] = useState(text?.length);
  const handleCursorPosition = useCallback(evt => {
    setCursorSelector(evt.target.selectionStart);
  }, []);
  const delayedCursorPosition = useDebouncedCallback(handleCursorPosition, 1000);
  const handleOnChange = useCallback(evt => {
    onChange(evt.target.value);
    setText(evt.target.value);
    delayedCursorPosition(evt);
  }, [delayedCursorPosition, onChange]);
  const addStrAtCursor = useCallback((str, index, stringToAdd) => {
    return `${str.substring(0, index)}${stringToAdd}${str.substring(index, str.length)}`;
  }, []);
  const addTextVariable = useCallback(variable => {
    const newVar = isSendingBlue ? `{{params.${variable}}}` : `{{${variable}}}`;
    const addVarible = addStrAtCursor(text, cursorSelector, newVar);
    setText(addVarible);
    onChange(addVarible);
  }, [addStrAtCursor, cursorSelector, isSendingBlue, onChange, text]);
  useEffect(() => {
    setText(textValue);
  }, [textValue]);
  return <>
			<TextFieldContainer className={className}>
				<TextField ref={inputRef} variant={outlined ? 'outlined' : 'standard'} fullWidth multiline={isMultiline} minRows={minRows} maxRows={maxRows} placeholder={placeholder} value={text} onChange={handleOnChange} onClick={handleCursorPosition} disabled={disabled} {...delegatedProps} />
				{!disabled && <Box position='absolute' top={3} right={5}>
						<VariablesSelector localVariable={localVariable} onAdd={addTextVariable} />
					</Box>}
			</TextFieldContainer>
		</>;
}
TextFieldVariables.propTypes = {
  textValue: PropTypes.string,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  variableParams: PropTypes.array,
  localVariable: PropTypes.array,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isMultiline: PropTypes.bool,
  isSendingBlue: PropTypes.bool,
  className: PropTypes.string,
  outlined: PropTypes.bool
};
export const TextFieldContainer = styled.div`
	position: relative;
	width: 100%;
	svg {
		width: 40px;
	}
	/* .MuiOutlinedInput-input {
		padding: 18.5px 14px!important;
	} */
`;