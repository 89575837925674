import { Box, Button, CardActionArea, DialogActions, Tooltip, Typography } from '@mui/material';
import { STORIES_COLORS } from 'lib/constants';
import useSubscriptionFeatures from 'lib/hooks/useSubscriptionFeatures';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalWrapper } from '../ModalWrapper';
import { BannerDialogContent, WidgetCard, WidgetCardContainer } from './BannerModals.style';
const INITIAL_STORY_TYPE = undefined;
const INITIAL_BANNER_TYPE = undefined;
export default function CreateBannerModal({
  trigger,
  onCreate
}) {
  const modalRef = useRef();
  const [storyType, setStoryType] = useState(INITIAL_STORY_TYPE);
  const [bannerType, setBannerType] = useState(INITIAL_BANNER_TYPE);
  const {
    hasAmp,
    version
  } = useSubscriptionFeatures();
  function onClose() {
    modalRef.current?.close();
    setStoryType(INITIAL_STORY_TYPE);
    setBannerType(INITIAL_BANNER_TYPE);
  }
  function onConfirm() {
    onCreate(storyType, bannerType);
    modalRef.current?.close();
  }
  const onStoryTypeSelect = selectedType => () => {
    setStoryType(selectedType);
  };
  const onBannerTypeSelect = selectedType => () => {
    setBannerType(selectedType);
  };
  return <ModalWrapper ref={modalRef} title={<FormattedMessage id='modal.widget_banner.create_widget.title' />} trigger={trigger} hasCloseButton={false}>
			<BannerDialogContent>
				<StoryTypeSelector hasAmp={hasAmp} version={version} storyType={storyType} onStoryTypeSelect={onStoryTypeSelect} />
				<Box my={2} />
				<BannerTypeSelector bannerType={bannerType} onBannerTypeSelect={onBannerTypeSelect} />
			</BannerDialogContent>

			<DialogActions>
				<Button variant='text' onClick={onClose}>
					<FormattedMessage id='button.cancel' />
				</Button>

				<Button variant='outlined' color='secondary' onClick={onConfirm} disabled={!storyType || !bannerType}>
					<FormattedMessage id='button.confirm' />
				</Button>
			</DialogActions>
		</ModalWrapper>;
}
CreateBannerModal.propTypes = {
  trigger: PropTypes.object,
  onCreate: PropTypes.func
};
function StoryTypeSelector({
  hasAmp,
  version,
  storyType,
  onStoryTypeSelect
}) {
  function renderTooltipMessage() {
    return !hasAmp ? <FormattedMessage id='subscription.features.amp.disabled' /> : '';
  }
  return <>
			<Box fontSize={18} color='text.primary' mb={1} fontWeight='bold'>
				<FormattedMessage id='modal.widget_banner.create_widget.select_story.title' />
			</Box>
			<Box fontSize={16} color='text.secondary' mb={4}>
				<FormattedMessage id='modal.widget_banner.create_widget.select_story.description' />
			</Box>
			<Box display='flex' justifyContent='space-evenly' mb={2}>
				<WidgetCardContainer>
					<WidgetCard $isSelected={storyType === 'snackeet'} onClick={onStoryTypeSelect('snackeet')}>
						<CardActionArea sx={{
            height: '100px'
          }}>
							<Typography variant='h1' sx={{
              textAlign: 'center',
              mb: 1,
              fontSize: '28px'
            }}>
								<b style={{
                color: STORIES_COLORS.classic
              }}>
									<FormattedMessage id='common.snackeet_type.classic' />
								</b>{' '}
								Stories
							</Typography>
						</CardActionArea>
					</WidgetCard>
				</WidgetCardContainer>
				<Tooltip title={renderTooltipMessage()}>
					<WidgetCardContainer $isDisabled={!hasAmp}>
						<WidgetCard $isDisabled={!hasAmp} $isSelected={storyType === 'amp'} onClick={onStoryTypeSelect('amp')}>
							<CardActionArea sx={{
              height: '100px'
            }}>
								<Typography variant='h1' sx={{
                textAlign: 'center',
                mb: 1,
                fontSize: '28px'
              }}>
									<b style={{
                  color: STORIES_COLORS.amp
                }}>
										<FormattedMessage id='common.snackeet_type.amp' />
									</b>{' '}
									Stories
								</Typography>
							</CardActionArea>
						</WidgetCard>
					</WidgetCardContainer>
				</Tooltip>
			</Box>
		</>;
}
StoryTypeSelector.propTypes = {
  hasAmp: PropTypes.bool,
  version: PropTypes.string,
  storyType: PropTypes.string,
  onStoryTypeSelect: PropTypes.func
};
export function BannerTypeSelector({
  bannerType,
  onBannerTypeSelect
}) {
  return <>
			<Box fontSize={18} color='text.primary' mb={1} fontWeight='bold'>
				<FormattedMessage id='modal.widget_banner.create_widget.select_design.title' />
			</Box>
			<Box fontSize={16} color='text.secondary' mb={4}>
				<FormattedMessage id='modal.widget_banner.create_widget.select_design.description' />
			</Box>
			<Box display='flex' justifyContent='space-evenly' mb={2}>
				<WidgetCardContainer>
					<WidgetCard $isSelected={bannerType === 'bubble'} onClick={onBannerTypeSelect('bubble')}>
						<CardActionArea>
							<div className='image-container'>
								<Image fill src='/static/images/banner_types/bubbles.svg' title='Bubbles' alt='Bubbles' />
							</div>
						</CardActionArea>
					</WidgetCard>
					<div className='caption'>
						<FormattedMessage id='modal.widget_banner.create_widget.select_design.caption.bubbles' />
					</div>
				</WidgetCardContainer>

				<WidgetCardContainer>
					<WidgetCard $isSelected={bannerType === 'card'} onClick={onBannerTypeSelect('card')}>
						<CardActionArea>
							<div className='image-container'>
								<Image fill src='/static/images/banner_types/cards.svg' title='Cards' alt='Cards' />
							</div>
						</CardActionArea>
					</WidgetCard>
					<div className='caption'>
						<FormattedMessage id='modal.widget_banner.create_widget.select_design.caption.cards' />
					</div>
				</WidgetCardContainer>
			</Box>
		</>;
}
StoryTypeSelector.propTypes = {
  bannerType: PropTypes.string,
  onBannerTypeSelect: PropTypes.func
};