import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
const MaterialValueEditor = ({
  fieldData,
  operator,
  value,
  handleOnChange,
  title,
  className,
  type,
  inputType,
  values
}) => {
  const intl = useIntl();
  useEffect(() => {
    if (inputType === 'number' && !['between', 'notBetween'].includes(operator) && typeof value === 'string' && value.includes(',')) {
      handleOnChange('');
    }
  }, [inputType, operator, value, handleOnChange]);
  function handleToggleCheckbox(name) {
    const currentValues = _.isEmpty(value) ? [] : value.split(',');
    if (_.includes(currentValues, name)) {
      handleOnChange(_.trim(_.reduce(_.filter(currentValues, val => val !== name), (sum, ans) => `${sum},${ans}`, ''), ','));
    } else {
      handleOnChange(_.trim(_.reduce([...currentValues, name], (sum, ans) => `${sum},${ans}`, ''), ','));
    }
  }
  if (_.includes(['null', 'notNull', 'is_true', 'is_false', 'quiz_correct', 'quiz_incorrect', 'quiz_partial', 'media_is_video', 'media_is_audio', 'media_is_text', 'media_is_answered', 'media_is_null', 'answer_isAnswered', 'answer_isNotAnswered', 'wheel_win', 'wheel_lose', 'wheel_played', 'wheel_notPlayed', 'is_quiz_true', 'is_quiz_false'], operator)) {
    return null;
  }
  const placeHolderText = fieldData?.placeholder ?? intl.messages['common.cond.placeholder'];
  // const inputTypeCoerced = ['between', 'notBetween'].includes(operator)
  // 	? 'text'
  // 	: inputType || 'text'

  // Operation compare with multiple variables
  if (_.includes(['var_greatest', 'var_smallest'], operator)) {
    return <Box key={fieldData?.name}>
				{_.map(values, (val, index) => <FormControlLabel key={index} control={<Checkbox color='primary' name={`checkbox-${val.name}`} checked={getCheckboxChecked(value, val.name)} onChange={() => handleToggleCheckbox(val.name)} />} label={val.label} />)}
			</Box>;
  }

  // Operation compare 2 variables
  if (operator.startsWith('var_')) {
    return <FormControl key={fieldData?.name} variant='outlined' className={className}>
				<Select variant='outlined' value={value} onChange={evt => handleOnChange(evt.target.value)}>
					{values.map(v => <MenuItem key={v.name} value={v.name}>
							<b>{v.label}</b>
						</MenuItem>)}
				</Select>
			</FormControl>;
  }
  switch (type) {
    case 'select':
      return <FormControl key={fieldData?.name} variant='outlined' className={className}>
					<Select variant='outlined' value={value} onChange={evt => handleOnChange(evt.target.value)}>
						{values.map(v => <MenuItem key={v.name} value={v.name}>
								<b>{v.label}</b>
							</MenuItem>)}
					</Select>
				</FormControl>;
    case 'checkbox':
      return <Checkbox key={fieldData?.name} className={className} onChange={evt => handleOnChange(evt.target.checked)} checked={!!value} />;
    case 'radio':
      return <FormControl key={fieldData?.name} variant='standard' className={className} title={title} component='fieldset'>
					<RadioGroup value={value} onChange={evt => handleOnChange(evt.target.value)}>
						{values.map(v => <FormControlLabel key={v.name} value={v.name} control={<Radio />} label={v.label} />)}
					</RadioGroup>
				</FormControl>;
    case 'date':
      if (operator === 'sameDay') {
        return <TextField key={fieldData?.name} variant='outlined' type='date' value={value} title={title} className={className} placeholder={placeHolderText} onChange={evt => handleOnChange(evt.target.value)} error={_.isEmpty(value)} />;
      }
      return <TextField key={fieldData?.name} variant='outlined' type='datetime-local' value={value} title={title} className={className} placeholder={placeHolderText} onChange={evt => handleOnChange(evt.target.value)} error={_.isEmpty(value)} />;
    default:
      return <TextField key={fieldData?.name} variant='outlined' type={type} value={value} title={title} className={className} placeholder={placeHolderText} onChange={evt => handleOnChange(evt.target.value)} error={_.isEmpty(value)} />;
  }
};
MaterialValueEditor.propTypes = {
  fieldData: PropTypes.object,
  operator: PropTypes.string,
  value: PropTypes.string,
  handleOnChange: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  type: PropTypes.string,
  inputType: PropTypes.string,
  values: PropTypes.array
};
MaterialValueEditor.displayName = 'MaterialValueEditor';
export default MaterialValueEditor;

// Helper functions

function getCheckboxChecked(value, name) {
  if (!_.isEmpty(value)) {
    if (_.includes(value, name)) return true;
  }
  return false;
}