import type { TooltipProps } from '@mui/material';
import { Tooltip, Typography } from '@mui/material';
import styled from '@styled';
import type { HotspotIconProps } from 'components/icons/custom/HotspotIcon';
import HotspotIcon from 'components/icons/custom/HotspotIcon';
import React, { FC, memo } from 'react';
import { FormattedMessage } from 'react-intl';

// Define the shape of the props using an interface
interface HotspotProps {
  IconProps?: HotspotIconProps;
  placement?: TooltipProps['placement'];
  title?: string;
  message?: string;
  footer?: string;
  component?: React.ReactNode;
}
const Hotspot: FC<HotspotProps> = memo(function Hotspot({
  IconProps,
  placement,
  title,
  message,
  footer,
  component
}) {
  return <Tooltip placement={placement} title={component ?? <>
						{title && <Typography variant='h4' component='div'>
								<FormattedMessage id={title} />
							</Typography>}
						{message && <Typography variant='body1' gutterBottom component='div'>
								<FormattedMessage id={message} />
							</Typography>}

						{footer && <Typography fontStyle='italic' component='div'>
								<FormattedMessage id={footer} />
							</Typography>}
					</>}>
			<IconWrapper>
				<HotspotIcon {...IconProps} />
			</IconWrapper>
		</Tooltip>;
});
export default Hotspot;
const IconWrapper = styled.span`
	cursor: help;

	.MuiSvgIcon-fontSizeMedium {
		font-size: 1rem;
	}
	.MuiSvgIcon-fontSizeSmall {
		font-size: 0.8rem;
	}
`;