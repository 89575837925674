import React, { useContext } from 'react';
import PickerContext from '../../context/PickerContext';
import Category from './Category';
export interface FeaturedCategoryProps {
  image: string;
  name: string;
}
export default function FeaturedCategory({
  image,
  name
}: FeaturedCategoryProps): JSX.Element {
  const [pickerContext, setPickerContext] = useContext(PickerContext);
  function onClick(): void {
    const contextCopy = Object.assign({}, pickerContext);
    contextCopy.searchTerm = name;
    setPickerContext(contextCopy);
  }
  return <Category image={image} text={name} onClick={onClick} />;
}