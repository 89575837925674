import AmpFormSettings from 'components/AmpFormSettings';
import { ModalWrapper } from 'components/Modals';
import { useStoryDispatch, useStoryState } from 'lib/editorStore';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
export default function AmpFormSettingsModal({
  trigger
}) {
  const ref = useRef();
  const draftStory = useStoryState(s => s.draftStory);
  const recently_used = useStoryState(s => s.draftStory?.recently_used);
  const brand_colors = useStoryState(s => s.draftStory?.brand_colors);
  const brand_fontFamily = useStoryState(s => s.draftStory?.brand_fontFamily);
  const page = useStoryState(s => s.page);
  const currentForm = _.find(page?.amp_attachments, ['type', 'amp_form']);
  const dispatch = useStoryDispatch();
  function onCancel() {
    ref.current.close();
  }
  function onSubmitConfig({
    variables,
    newInteraction
  }) {
    dispatch({
      type: 'UPDATE_ATTACHMENT',
      data: {
        newInteraction,
        variables
      }
    });
    ref.current.close();
  }
  function onRecentColorsUpdate(color) {
    dispatch({
      type: 'SET_RECENTLY_USED',
      data: {
        path: 'colors.block',
        value: color
      }
    }, false);
  }
  return <ModalWrapper title={<FormattedMessage id='modals.amp_form.modal.settings' />} trigger={trigger} disableBackdropClick disableEscapeKeyDown hasCloseButton={false} size='lg' ref={ref}>
			<AmpFormSettings onCancel={onCancel} onSubmitConfig={onSubmitConfig} currentPage={page} draftStory={draftStory} currentForm={currentForm} onRecentColorsUpdate={onRecentColorsUpdate} recentColors={recently_used?.colors?.block} brandColors={brand_colors} recentFonts={recently_used?.font_families} brandFontFamily={brand_fontFamily} />
		</ModalWrapper>;
}
AmpFormSettingsModal.propTypes = {
  trigger: PropTypes.object
};