import { CustomIcon } from 'components/icons';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { useIntl } from 'react-intl';
import { OutlinkAttachmentContainer, OutlinkButton, OutlinkButtonImage } from './style';
export default function OutlinkAttachment({
  attachment
}) {
  const {
    _id,
    link,
    name,
    isDarkTheme,
    hasIcon,
    iconUrl
  } = attachment;
  const intl = useIntl();
  return <OutlinkAttachmentContainer>
			<CustomIcon name='AmpSwipeUp' style={{
      marginBottom: '8px',
      color: isDarkTheme ? 'black' : 'white'
    }} />

			<OutlinkButton $isDarkTheme={isDarkTheme} $hasIcon={hasIcon}>
				<OutlinkIcon hasIcon={hasIcon} iconUrl={iconUrl} />
				<span>{name || 'Swipe up'}</span>
			</OutlinkButton>
		</OutlinkAttachmentContainer>;
}
OutlinkAttachment.propTypes = {
  attachment: PropTypes.object
};
const OutlinkIcon = memo(function OutlinkIcon({
  hasIcon,
  iconUrl
}) {
  if (!hasIcon) return null;
  if (iconUrl) return <OutlinkButtonImage src={iconUrl} />;
  return <CustomIcon name='AmpLinkIcon' />;
});