import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { CustomCircularProgress, TimerContainer, TimerLayer } from './styled';
function StoryTimer({
  timer
}) {
  const progress = 100;
  if (!timer?.enabled || !timer?.visible) {
    return null;
  }
  return <TimerLayer>
			<TimerContainer id={`preview-timer-${timer._id}`} $outerStyle={timer?.outer_style} $innerStyle={timer?.inner_style}>
				<Box position='absolute' display='flex' alignItems='center'>
					<CustomCircularProgress variant='determinate' value={progress} width={timer?.outer_style.width} height={timer?.outer_style.height} $borderColor={timer?.inner_style.borderColor} $borderWidth={timer?.inner_style.borderWidth} $bgColor={timer?.inner_style.backgroundColor} />
					<Box top={0} left={0} bottom={0} right={0} position='absolute' display='flex' alignItems='center' justifyContent='center'>
						<Typography variant='caption' component='div' style={{
            fontSize: timer?.inner_style.fontSize
          }}>
							{`${timer.value}s`}
						</Typography>
					</Box>
				</Box>
			</TimerContainer>
		</TimerLayer>;
}
StoryTimer.propTypes = {
  timer: PropTypes.object
};
export default memo(StoryTimer);