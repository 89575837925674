import { css } from '@emotion/react';
import { Clear, Publish } from '@mui/icons-material';
import { Box, Button, CircularProgress, DialogActions, IconButton } from '@mui/material';
import styled from '@styled';
import { ConfirmationModal } from 'components/Modals';
import { LoaderContainer } from 'containers/Editor/StoryEditor/MediaAssets/Galleries/Galleries.style';
import useFiles from 'lib/hooks/useFiles';
import useUpload from 'lib/hooks/useUpload';
import { MIME_TYPES } from 'lib/upload';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useAsyncEffect from 'use-async-effect';
import { useImmer } from 'use-immer';
import { ModalWrapper } from '../ModalWrapper';
const fileImporterStyle = {
  display: 'none'
};
const StoryFaviconModal = forwardRef(({
  storyFavicon,
  onStoryFaviconSelected
}, ref) => {
  const [state, setState] = useImmer({
    isLoading: true,
    isDeleting: false,
    selectedFavicon: undefined,
    faviconToDelete: undefined,
    faviconList: []
  });
  const deleteModalRef = useRef();
  const fileImporterRef = useRef();
  const intl = useIntl();
  const {
    isUploading,
    handleUpload
  } = useUpload({
    acceptedMimeTypes: Object.values(MIME_TYPES.image)
  });
  const {
    getFaviconFiles,
    deleteFile
  } = useFiles();
  function onConfirm() {
    onStoryFaviconSelected(state.selectedFavicon);
    ref.current?.close();
  }
  function resetState() {
    setState(draft => {
      const selectedFavicon = _.find(draft.faviconList, {
        _id: storyFavicon?._id
      });
      draft.isLoading = false;
      draft.selectedFavicon = selectedFavicon;
      draft.faviconToDelete = undefined;
    });
  }
  function onClose() {
    resetState();
    ref.current?.close();
  }
  async function onFileSelected(evt) {
    // Convert FileList to array of Files
    const files = await handleUpload([...evt.target.files], '/upload/favicon');
    if (files.length === 1) {
      // Automatically select favicon if only one file uploaded
      const faviconFile = files[0];
      onStoryFaviconSelected(faviconFile);
      setState(draft => {
        draft.isLoading = false;
        draft.faviconList.push(faviconFile);
        draft.selectedFavicon = faviconFile;
        draft.faviconToDelete = undefined;
      });
      ref.current?.close();
    } else {
      setState(draft => {
        draft.faviconList.push(...files);
      });
    }
  }
  function onDelete(faviconFile) {
    setState(draft => {
      draft.faviconToDelete = faviconFile;
    });
    deleteModalRef.current?.open();
  }
  async function onConfirmDelete() {
    const isCurrentFavicon = state.faviconToDelete._id === storyFavicon?._id;

    // Prevent triggering delete multiple times
    if (state.isDeleting) return;
    setState(draft => {
      draft.isDeleting = true;
    });
    await deleteFile(state.faviconToDelete._id);
    setState(draft => {
      _.remove(draft.faviconList, {
        _id: draft.faviconToDelete?._id
      });
      draft.faviconToDelete = undefined;
      draft.isDeleting = false;
    });

    // Reset story favicon
    if (isCurrentFavicon) {
      onStoryFaviconSelected(undefined);
    }
    deleteModalRef.current?.close();
  }
  function onSelect(faviconFile) {
    setState(draft => {
      draft.selectedFavicon = faviconFile;
    });
  }
  useAsyncEffect(async isMounted => {
    const faviconList = await getFaviconFiles();
    if (isMounted()) {
      setState(draft => {
        draft.isLoading = false;
        draft.faviconList = faviconList;
      });
    }
  }, []);
  useEffect(() => {
    if (storyFavicon?._id !== state.selectedFavicon?._id) {
      const selectedFavicon = _.find(state.faviconList, ['_id', storyFavicon?._id]);
      onSelect(selectedFavicon);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [storyFavicon, state.faviconList]);
  return <ModalWrapper size='sm' ref={ref} title={!state.isLoading ? <FormattedMessage id='modals.story_favicon.title' values={{
    count: state.faviconList.length
  }} /> : ''} onClose={resetState}>
			<Box overflow='auto' px={2} py={3} display='flex' flexDirection='column' alignItems='center'>
				<Box mb={2}>
					<Button size='large' variant='contained' color='primary' disabled={isUploading} onClick={() => fileImporterRef.current?.click()} startIcon={!isUploading ? <Publish /> : <CircularProgress color='primary' size={20} />}>
						<FormattedMessage id='button.import' />
					</Button>
				</Box>

				{state.isLoading ? <LoaderContainer>
						<CircularProgress disableShrink />
					</LoaderContainer> : <ImagesList faviconList={state.faviconList} selectedFavicon={state.selectedFavicon} onSelect={onSelect} onDelete={onDelete} />}

				<ConfirmationModal size='sm' confirmationModalRef={deleteModalRef} title={<FormattedMessage id='modal.file_deletion.confirmation.header.image' />} onConfirm={onConfirmDelete} firstLine={<FormattedMessage id='modal.file_deletion.confirmation.sub_header' />} secondLine={<FormattedMessage id='modal.file_deletion.confirmation.instructions' />} confirmText={<FormattedMessage id='common.yes' />} cancelText={<FormattedMessage id='common.no' />} />
			</Box>

			<input ref={fileImporterRef} style={fileImporterStyle} id='file-import-trigger' multiple type='file' accept='image/jpeg,image/png,image/svg+xml' onChange={onFileSelected} />

			<DialogActions>
				<Button variant='text' onClick={onClose}>
					<FormattedMessage id='button.cancel' />
				</Button>

				<Button variant='outlined' color='secondary' onClick={onConfirm} disabled={!storyFavicon && !state.selectedFavicon}>
					<FormattedMessage id='button.confirm' />
				</Button>
			</DialogActions>
		</ModalWrapper>;
});
StoryFaviconModal.propTypes = {
  storyFavicon: PropTypes.object,
  onStoryFaviconSelected: PropTypes.func
};
StoryFaviconModal.displayName = 'StoryFaviconModal';
export default StoryFaviconModal;
function ImagesList({
  faviconList,
  selectedFavicon,
  onSelect,
  onDelete
}) {
  if (_.isEmpty(faviconList)) {
    return <img width={300} src='/static/placeholders/no_files.svg' />;
  }
  return <Box width='100%' overflow='auto' display='flex' justifyContent='space-evenly' alignItems='center'>
			{_.map(faviconList, faviconFile => <FaviconCard key={faviconFile._id} $isSelected={selectedFavicon?._id === faviconFile._id}>
					<img alt={faviconFile._id} src={faviconFile.url} onClick={() => onSelect(faviconFile)} />
					<IconButton title={<FormattedMessage id='common.delete' />} size='small' onClick={() => onDelete(faviconFile)}>
						<Clear />
					</IconButton>
				</FaviconCard>)}
		</Box>;
}
ImagesList.propTypes = {
  faviconList: PropTypes.array,
  selectedFavicon: PropTypes.object,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func
};
const selectedStyle = ({
  $isSelected,
  theme
}) => $isSelected && css`
		outline: 4px solid ${theme.palette.primary.main}!important;
	`;
const FaviconCard = styled.div`
	display: grid;
	place-items: center;
	position: relative;
	overflow: hidden;
	height: 100px;
	width: 100px;
	cursor: pointer;
	margin: ${({
  theme
}) => theme.spacing(1)};
	transition: transform 150ms ease-in;
	outline: none;
	${selectedStyle}

	&:hover {
		outline: ${({
  theme
}) => `1px solid ${theme.palette.text.primary}`};
		.MuiIconButton-root {
			display: block;
		}
	}

	img {
		width: 100%;
	}

	.MuiIconButton-root {
		display: none;
		position: absolute;
		top: 4px;
		right: 4px;
		color: #fff;
		background-color: rgba(0, 0, 0, 0.6);

		&:hover {
			background-color: rgba(0, 0, 0, 1);
		}
	}
`;