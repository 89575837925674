import _ from 'lodash';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { GameInteractionContainer } from '../styled';
import WheelComponent from './GamesComponents/WheelComponent';
function GamePageInterface({
  page,
  theming,
  gdprConsent,
  wheelComponentState
}) {
  const game = _.find(page.interactions, ['type', 'game']);
  const {
    components,
    sub_type
  } = game;
  function renderGame(sub_type) {
    switch (sub_type) {
      case 'wheel':
        {
          return <WheelComponent components={components} pageModal={page?.modal} theming={theming} gdprConsent={gdprConsent} wheelComponentState={wheelComponentState} />;
        }
      default:
        break;
    }
  }
  return <GameInteractionContainer style={page?.interface_style}>
			<div className='interactions'>{renderGame(sub_type)}</div>
		</GameInteractionContainer>;
}
GamePageInterface.propTypes = {
  page: PropTypes.object,
  theming: PropTypes.object,
  gdprConsent: PropTypes.object,
  wheelComponentState: PropTypes.string
};
export default memo(GamePageInterface);