import { Chip } from '@mui/material';
import styled from '@styled';
import { FormattedMessage } from 'react-intl';
const BaseChip = styled(Chip)`
	cursor: inherit;

	&&.MuiChip-root {
		height: 20px;
	}

	&&.MuiChip-sizeSmall {
		height: 16px;
	}

	.MuiChip-label {
		font-size: 11px;
	}

	.MuiChip-labelSmall {
		font-size: 9px;
	}
`;
const StyledPremiumChip = styled(BaseChip)``;
export function PremiumChip({
  size = 'small',
  ...delegatedProps
}) {
  return <StyledPremiumChip label='Premium' color='secondary' size={size} {...delegatedProps} />;
}
const StyledNewChip = styled(BaseChip)`
	margin-left: 8px;
`;
export function NewChip({
  size = 'small',
  ...delegatedProps
}) {
  return <StyledNewChip label={<FormattedMessage id='common.new' />} color='secondary' size={size} {...delegatedProps} />;
}
const StyledBetaChip = styled(BaseChip)`
	.MuiChip-label {
		font-size: 9px;
	}
`;
export function BetaChip({
  size = 'small',
  ...delegatedProps
}) {
  return <StyledBetaChip label='Beta' color='primary' size={size} {...delegatedProps} />;
}