import { Box,
// Input,
Popover, Typography } from '@mui/material';
import styled from '@styled';
import CustomColorPicker from 'components/atoms/CustomColorPicker';
import { ColorPreview } from 'components/styled';
import _ from 'lodash';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

// 👉 Incomplete Component
// Need an input to write color hexadecimal code
// However, would also need input for opacity,
// and to properly convert RGBA <-> Hex + Opacity

export default function ColorInput({
  label,
  hasLabel = true,
  hasWrapper = true,
  value,
  updateValue,
  recentColors = [],
  displayRecentColors = true,
  brandColors = [],
  updateRecentColors,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'left'
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right'
  }
}) {
  const popupId = `picker-popup`;
  const popupState = usePopupState({
    variant: 'popover',
    popupId
  });
  function onColorPickerChange(rgbaString) {
    updateValue(rgbaString);

    // Update recent colors
    if (updateRecentColors) {
      updateRecentColors(rgbaString);
    }
  }
  if (!value) {
    return null;
  }
  return <ColorInputContainer className={hasWrapper && 'property-line'}>
			{hasLabel && <Typography variant='body2' component='label' className='property-field'>
					{label || <FormattedMessage id='common.color' />}
				</Typography>}

			{hasWrapper ? <div className='property-content' style={{
      justifyContent: 'flex-start',
      padding: '0px 0px 0px 4px'
    }}>
					<Box display='flex' width='90px' alignItems='center'>
						<ColorPreview {...bindTrigger(popupState)} $colorValue={value} />
						<Typography variant='body2' style={{
          marginLeft: '6px',
          textTransform: 'uppercase',
          fontSize: '10.5px'
        }}>
							<b>{RGBToHex(value)}</b>
						</Typography>
					</Box>

					{
      // Display recent colors
      displayRecentColors && _.map(_.slice(recentColors, 0, 6), (recentColor, index) => {
        return <ColorPreview key={index} $isSmall $colorValue={recentColor.color} style={{
          marginLeft: '4px'
        }} onClick={() => {
          updateValue(recentColor.color);
        }} />;
      })}
				</div> : <>
					<ColorPreview {...bindTrigger(popupState)} $colorValue={value} />
					<Typography variant='body2' style={{
        marginLeft: '6px',
        textTransform: 'uppercase'
      }}>
						<b>{RGBToHex(value)}</b>
					</Typography>
				</>}

			<Popover {...bindPopover(popupState)} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
				<CustomColorPicker onUpdate={onColorPickerChange} value={value} recentColors={recentColors} brandColors={brandColors} />
			</Popover>
		</ColorInputContainer>;
}
ColorInput.propTypes = {
  label: PropTypes.any,
  hasLabel: PropTypes.bool,
  hasWrapper: PropTypes.bool,
  value: PropTypes.string,
  updateValue: PropTypes.func,
  recentColors: PropTypes.array,
  brandColors: PropTypes.array,
  updateRecentColors: PropTypes.func
};

// Styled components
const ColorInputContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	max-width: 350px;
	padding: 0px;

	label {
		min-width: 50px;
	}

	& > *:first-child {
		margin-left: 0;
	}
	& > *:last-child {
		margin-right: 0;
	}
`;

// Helper functions
function RGBToHex(rgb) {
  if (rgb.startsWith('#')) return rgb;

  // Choose correct separator
  const sep = rgb.indexOf(',') > -1 ? ',' : ' ';
  // Turn "rgb(r,g,b)" into [r,g,b]
  rgb = rgb.substr(5).split(')')[0].split(sep);
  let r = (+rgb[0]).toString(16);
  let g = (+rgb[1]).toString(16);
  let b = (+rgb[2]).toString(16);
  if (r.length === 1) {
    r = '0' + r;
  }
  if (g.length === 1) {
    g = '0' + g;
  }
  if (b.length === 1) {
    b = '0' + b;
  }
  return '#' + r + g + b;
}