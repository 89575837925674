import { keyframes } from '@emotion/css';
import styled from '@styled';
import { transparentize } from 'polished';
import PropTypes from 'prop-types';
import { useState } from 'react';
export default function PulseButton({
  inner_style,
  placement,
  display,
  setDisplay
}) {
  const [visited, setVisited] = useState(false);
  function toggleDisplay() {
    setDisplay(!display);
    setVisited(true);
  }
  return <ClickableArea onClick={toggleDisplay} $placement={placement}>
			<PulsingButton $color={inner_style.backgroundColor} $visited={visited} />
		</ClickableArea>;
}
PulseButton.propTypes = {
  inner_style: PropTypes.object,
  placement: PropTypes.string,
  display: PropTypes.bool,
  setDisplay: PropTypes.func
};
const pulse = color => keyframes`
	0% {
		transform: scale(0.92);
		box-shadow: 0 0 0 0 ${transparentize(0.3, color)};
	}
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 5px ${transparentize(1, color)};
	}
	100% {
		transform: scale(0.92);
		box-shadow: 0 0 0 0 ${transparentize(1, color)};
	}
`;
const PulsingButton = styled.div`
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: ${props => props.$color};
	box-shadow: ${props => `0 0 0 0 ${transparentize(0, props.$color)}`};
	animation: ${props => !props.$visited && pulse(props.$color)} 2s infinite;
	opacity: ${props => props.$visited && 0.4};
	&:hover {
		animation: none;
	}
`;
const ClickableArea = styled.div`
	position: absolute;
	width: 14px;
	height: 14px;
	top: ${props => props.$placement === 'bottom' ? '13px' : '79px'};
	left: 68px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	pointer-events: auto;
`;