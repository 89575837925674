import { SvgIcon } from '@mui/material'

export default function AmpLinkIcon(props) {
	return (
		<SvgIcon width='24px' height='24px' viewBox='0 0 24 24' {...props}>
			<path
				fillOpacity='0.1'
				d='M 12 0 c 6.6 0 12 5.4 12 12 s -5.4 12 -12 12 S 0 18.6 0 12 S 5.4 0 12 0 Z'
			></path>
			<path d='m 13.8 14.6 l 0.2 0.5 l -0.2 0.5 l -1.5 1.4 c -0.7 0.7 -1.7 1.1 -2.7 1.1 A 4 4 0 0 1 6.9 17 a 3.9 3.9 0 0 1 -1.1 -2.7 a 4 4 0 0 1 1.1 -2.7 l 1.5 -1.5 l 0.5 -0.1 l 0.5 0.2 l 0.2 0.5 l -0.2 0.5 l -1.5 1.5 c -0.5 0.5 -0.7 1.1 -0.7 1.7 c 0 0.6 0.3 1.3 0.7 1.7 c 0.5 0.5 1.1 0.7 1.7 0.7 s 1.3 -0.3 1.7 -0.7 l 1.5 -1.5 c 0.3 -0.3 0.7 -0.3 1 0 Z M 17 7 a 3.9 3.9 0 0 0 -2.7 -1.1 A 4 4 0 0 0 11.6 7 l -1.5 1.5 l -0.1 0.4 l 0.2 0.5 l 0.5 0.2 l 0.5 -0.2 l 1.5 -1.5 c 0.5 -0.5 1.1 -0.7 1.7 -0.7 c 0.6 0 1.3 0.3 1.7 0.7 c 0.5 0.5 0.7 1.1 0.7 1.7 c 0 0.6 -0.3 1.3 -0.7 1.7 l -1.5 1.5 l -0.2 0.5 l 0.2 0.5 l 0.5 0.2 l 0.5 -0.2 l 1.5 -1.5 c 0.7 -0.7 1.1 -1.7 1.1 -2.7 c -0.1 -1 -0.5 -1.9 -1.2 -2.6 Z m -7.9 7.2 l 0.2 0.5 l 0.5 0.2 l 0.5 -0.2 l 4.5 -4.5 l 0.2 -0.5 l -0.2 -0.5 c -0.3 -0.2 -0.8 -0.2 -1 0.1 l -4.5 4.5 l -0.2 0.4 Z'></path>
		</SvgIcon>
	)
}
