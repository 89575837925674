import { StyledSystemButton } from 'containers/Editor/StoryEditor/style';
import { useStoryState } from 'lib/editorStore';
import _ from 'lodash';
import PropTypes from 'prop-types';
export function StoryButton({
  name,
  fullWidth = false,
  buttonText,
  roundedButton
}) {
  const messages = useStoryState(s => s.draftStory.messages);
  const theming = useStoryState(s => s.draftStory.theming);
  const selectedElement = useStoryState(s => s.selectedElement);
  const buttonStyle = getButtonStyle(theming.buttons, roundedButton);
  const isSelected = selectedElement.type === 'system_buttons' && selectedElement.payload?.name === name;
  return <StyledSystemButton id={`system_button_${name}`} data-selecto-target={true} style={buttonStyle} fullWidth={fullWidth} variant='contained' $isSelected={isSelected}>
			{buttonText ?? messages.buttons[name]}
		</StyledSystemButton>;
}
StoryButton.propTypes = {
  name: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  fullWidth: PropTypes.bool,
  roundedButton: PropTypes.object
};
function getButtonStyle(defaultStyle, roundedButton) {
  const style = _.cloneDeep(defaultStyle);
  if (roundedButton && roundedButton.enabled) {
    _.assign(style, roundedButton.style);
  }
  return style;
}
export function PagePreviewStoryButton({
  name,
  buttonText,
  fullWidth = false,
  roundedButton,
  theming,
  messages
}) {
  const buttonStyle = getButtonStyle(theming.buttons, roundedButton);
  return <StyledSystemButton style={buttonStyle} fullWidth={fullWidth} variant='contained' disabled>
			{buttonText ?? messages.buttons[name]}
		</StyledSystemButton>;
}