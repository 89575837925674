import { useStoryState } from 'lib/editorStore'
import _ from 'lodash'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'

function getStoryPagesOpionsSlice(s) {
	return {
		currentPageId: s.page._id,
		pages: _.map(s.draftStory.pages, (page) => _.pick(page, ['_id', 'name', 'type', 'disabled'])),
	}
}

export default function useStoryPagesOptions(opts = {}) {
	const { countCurrentPage = false, hasNoneRedirection = false, hasGoBack = false } = opts
	const intl = useIntl()
	const { currentPageId, pages } = useStoryState(getStoryPagesOpionsSlice)

	const pageOptions = useMemo(() => {
		const options = _.flatMap(pages, (page, pageIndex) => {
			if ((page._id === currentPageId && !countCurrentPage) || page?.disabled) {
				return []
			}
			return [
				{
					key: pageIndex,
					text: page.name,
					label: intl.messages[`interaction.page_type.${page.type}`],
					value: page._id,
				},
			]
		})

		options.unshift({
			text: intl.messages['edition_menu.navigation.next_page'],
			value: 'next',
			key: 'next_key',
		})

		if (hasGoBack) {
			options.unshift({
				text: intl.messages['edition_menu.navigation.go_back'],
				value: 'back',
				key: 'go_back',
			})
		}

		if (hasNoneRedirection) {
			options.unshift({
				text: intl.messages['edition_menu.navigation.none'],
				value: 'none',
				key: 'none_key',
			})
		}

		return options
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countCurrentPage, currentPageId, hasGoBack, hasNoneRedirection, pages])

	return pageOptions
}
