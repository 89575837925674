import { css } from '@emotion/react'
import { Check } from '@mui/icons-material'
import { Box } from '@mui/material'
import styled from '@styled'
import { APP_HEADER_HEIGHT, TRANSPARENT_IMG } from 'lib/constants'
import { snackeet_border, snackeet_dark_blue, snackeet_yellow } from 'styles/colors'

export const AppHeader = styled(Box)`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	z-index: 3;
	height: ${APP_HEADER_HEIGHT}px;
	background-color: ${({ theme }) => theme.palette.background.paper};
	border-bottom: ${snackeet_border} solid 1px;
	box-shadow: 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
`
AppHeader.defaultProps = {
	display: 'grid',
	gridAutoFlow: 'column',
	gap: 1,
	alignItems: 'center',
}

export const Spacer = styled.div`
	flex-grow: 1;
`

export const FormPageContainer = styled.div`
	height: 100%;
	display: grid;
	place-items: center;
	grid-template-columns: 1fr 1fr;
	max-height: 100vh;
	overflow: hidden;
	background-color: rgb(248 248 248);

	.form-cover {
		width: 100%;
		height: 100vh;

		.img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	.header {
		padding: ${({ theme }) => theme.spacing(4, 0)};
		display: grid;
		place-items: center;

		img {
			width: 200px;
		}
	}

	.form-container {
		position: relative;
		border-radius: 10px;
		padding: ${({ theme }) => theme.spacing(5)};
		width: 70%;
		overflow: auto;

		@media (max-width: 768px) {
			width: 100%;
		}
	}

	form {
		width: 100%;
		display: flex;
		flex-direction: column;
		padding: ${({ theme }) => theme.spacing(2, 3)};
		background: ${({ theme }) => theme.palette.background.paper};
		border-radius: 10px;
		box-shadow: ${({ theme }) => theme.shadows[3]};
	}

	.title {
		/* color: ${({ theme }) => theme.palette.primary.main}; */
		font-size: 22px;
		line-height: 32px;
		text-rendering: optimizelegibility;
		-webkit-font-smoothing: antialiased;
		font-weight: 700;
	}

	.subtitle,
	.title {
		text-align: center;
	}

	.subtitle {
		font-size: 18px;
		margin-top: ${({ theme }) => theme.spacing(3)};
		line-height: 24px;
		& > a {
			margin: 0 8px;
			cursor: pointer;
		}
	}

	.grow {
		flex-grow: 1;
	}

	.unvalide-username {
		display: flex;
		align-items: baseline;

		svg {
			width: 20px;
			color: red;
		}
	}

	.password-reset-link {
		text-align: center;
		margin: 16px;
		align-self: flex-end;
		cursor: pointer;
		text-transform: capitalize;
	}

	.cgv {
		margin: ${({ theme }) => theme.spacing(2, 0)};
		.MuiTypography-body1 {
			font-size: 14px !important;
		}
	}

	.MuiGrid-container:not(:last-of-type) {
		margin-bottom: 8px;
	}
	.MuiFormControl-root {
		margin-top: 8px;
	}
	.MuiAlert-root {
		margin: ${({ theme }) => theme.spacing(1, 0)};
	}

	footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 8px;

		button {
			width: 100%;
			span {
				margin: auto;
			}
		}

		.MuiFormHelperText-root {
			text-align: center;
		}
	}

	@media (max-width: 1050px) {
		grid-template-columns: repeat(1, 1fr);

		.form-cover {
			display: none;
		}
	}
`

export const StyledUrl = styled.a`
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	font-weight: bold;
	text-decoration: underline;
	color: ${snackeet_dark_blue};
	&:hover {
		color: ${snackeet_yellow};
	}
`

export const HOVERABLE_ICON_SIZE = 20
export const HOVERABLE_ICON_MARGIN = 2

const disabledStyle = ({ theme, disabled }) =>
	disabled &&
	css`
		color: ${theme.palette.text.disabled}!important;
		cursor: not-allowed;
	`

const activatedStyle = ({ theme, $isActive }) =>
	$isActive &&
	css`
		color: ${theme.palette.success.main}!important;
	`

export const HoverableIcon = styled.div`
	cursor: pointer;
	margin: 0 ${HOVERABLE_ICON_MARGIN}px;
	${disabledStyle}
	${activatedStyle}

	.MuiSvgIcon-root {
		height: ${({ $height }) => $height ?? HOVERABLE_ICON_SIZE}px;
		width: ${({ $width }) => $width ?? HOVERABLE_ICON_SIZE}px;
	}

	&:hover {
		color: ${({ theme }) => theme.palette.secondary.main};
	}
`

export const ColorPreview = styled.div`
	border: 1px solid #ccc;
	border-radius: 4px;
	height: 30px;
	width: 30px;
	background-image: url(${TRANSPARENT_IMG});
	background-size: 400px 400px;
	box-shadow: ${({ theme }) => theme.shadows[1]};
	cursor: pointer;
	position: relative;

	${({ $isLarge }) =>
		$isLarge &&
		css`
			height: 60px;
			width: 60px;
		`}

	&::after {
		position: absolute;
		content: ' ';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 3px; /* 4 - 1px from border width */
		z-index: 0;
		background-color: ${({ $colorValue }) => $colorValue ?? '#fff'};
	}

	${({ $isSmall }) =>
		$isSmall &&
		css`
			height: 20px;
			width: 20px;
			border-radius: 10px;
			box-shadow: none;
			background-size: 300px 300px;

			&::after {
				border-radius: 10px;
			}
		`}

	&:hover {
		border-color: ${({ theme }) => theme.palette.text.primary};
	}
`

export const Figure = styled.figure`
	display: flex;
	flex-direction: ${({ $isHorizontal }) => ($isHorizontal ? 'row' : 'column')};
	align-items: center;
	gap: 16px;

	img {
		height: ${({ height }) => height || 200}px;
	}

	figcaption {
		font-size: 18px;
		text-align: center;
		line-height: 1.5em;
	}
`

export const ScrollableContainer = styled(Box)`
	position: relative;
	overflow: auto;
	display: flex;
	flex-direction: column;

	&:after {
		content: '';
		position: absolute;
		top: 50px;
		width: 100%;
		height: 20px;
		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 100%);
	}
`

export const StickyCheck = styled(Box)`
	position: absolute;
	color: white;
	background-color: ${({ theme }) => theme.palette.primary.dark};
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`
StickyCheck.defaultProps = {
	top: 10,
	right: 10,
	zIndex: 1,
	padding: 1,
	children: <Check />,
}
