import { Button, ButtonBase } from '@mui/material';
import styled from '@styled';
import { EditorPageLayout, interfaceElementSelectedStyle } from 'components/styled/Editor';
import { EDITOR_LEFT_PANEL_WIDTH, EDITOR_PROPERTIES_WIDTH } from 'lib/constants';

// Ensure EDitZone has minimum 660px, which is the min width for the TopToolbar NOT to overflow
export const StoryEditorContainer = styled(EditorPageLayout)`
	grid-template-columns:
		${EDITOR_LEFT_PANEL_WIDTH}px minmax(550px, 1fr)
		${EDITOR_PROPERTIES_WIDTH}px;
	background-color: #e1e1e1;
`;
export const EditZoneContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
`;
export const WatermarkContainer = styled.div`
	position: absolute;
	top: 40%;
	left: 0;
	transform: translateY(-50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 160px;
	height: max-content;
	color: #afafaf;
	padding: ${({
  theme
}) => theme.spacing(0, 1)};
	user-select: none;

	.page-index {
		font-size: 72px;
		line-height: 72px;
		margin: 0;
		visibility: ${props => props.$hideIndex && 'hidden'};
	}

	.page-name {
		font-size: ${props => props.$hideIndex ? 32 : 24}px;
		line-height: 26px;
		margin: 0;
	}
`;
export const StyledSystemButton = styled(Button)`
	pointer-events: auto;
	overflow: hidden;
	margin-top: 3px !important;
	transition: transform 200ms ease-in-out;
	will-change: transform;

	${({
  theme,
  $isSelected
}) => $isSelected && interfaceElementSelectedStyle({
  theme
})};

	&.MuiButtonBase-root {
		min-width: 100px;
		max-width: 300px;
		min-height: 40px;
		border-radius: 60px;
		padding: ${({
  theme
}) => theme.spacing(1, 4)};
		display: flex;
		align-items: center;
		position: relative;
		text-transform: initial;
		line-height: 1.5;

		&:hover {
			transform: scale(1.02);
		}
	}

	&.MuiButton-label {
		display: flex;
		justify-content: center;
		cursor: pointer;
	}
	&.MuiButton-endIcon {
		margin-left: 4px;
	}
`;
export const StyledConfirmButton = styled(StyledSystemButton)`
	width: 100%;
	margin-top: ${({
  theme
}) => theme.spacing(1)};
`;
export const AmpSharingContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	margin-bottom: ${({
  theme
}) => theme.spacing(5)};
`;
export const AmpSharingIcon = styled.div`
	position: relative;
	width: 60px;
	height: 60px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 50px;
	border-radius: 4px;

	.icon-label {
		position: absolute;
		top: 55px;
		width: 100%;
		padding-top: 6px;
		text-align: center;
		font-weight: bold;
	}
`;
export const TwitterIcon = styled(AmpSharingIcon)`
	background-color: #000000;
	background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="1200" height="1227" fill="none"><path d="M714.163 519.284 1160.89 0h-105.86L667.137 450.887 357.328 0H0l468.492 681.821L0 1226.37h105.866l409.625-476.152 327.181 476.152H1200L714.137 519.284h.026zM569.165 687.828l-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721H892.476L569.165 687.854v-.026z" fill="%23fff"/></svg>');
`;
export const FacebookIcon = styled(AmpSharingIcon)`
	background-color: #32529f;
	background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%23fff" d="M212 197h-37v60h37v176h70V257h50l5-60h-55v-33c0-14 3-20 17-20h38V83h-49c-52 0-76 23-76 67v47z"/></svg>');
`;
export const LinkedinIcon = styled(AmpSharingIcon)`
	background-color: #9aa0a6;
	background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="29"><path fill="none" stroke="%23FFF" stroke-width="3" d="M8.7 26v-5.7H2V2h26v18.4H15.9z"/></svg>');
`;
export const PinterestIcon = styled(AmpSharingIcon)`
	background-color: #e60023;
	background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%23fff" d="M267 77c-101 0-151 71-151 131 0 36 14 69 43 81 5 2 9 0 11-6l4-16c1-6 1-8-3-12-8-10-14-23-14-42 0-53 40-101 104-101 57 0 88 35 88 81 0 61-27 112-67 112-22 0-39-18-33-40 6-27 18-56 18-75 0-17-9-32-28-32-23 0-41 24-41 55 0 20 7 33 7 33l-27 115c-9 34-2 76-1 80 0 3 4 3 5 1 2-2 29-35 38-69l15-58c7 14 29 27 51 27 68 0 114-62 114-145 0-62-53-120-133-120z"/></svg>');
`;
export const TumblrIcon = styled(AmpSharingIcon)`
	background-color: #3c5a77;
	background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%23fff" d="M211 80c-2 19-7 34-13 46a96 96 0 0 1-59 50v50h39v125c0 16 1 28 5 37 3 8 9 16 18 24s20 13 32 17c13 5 27 7 43 7a174 174 0 0 0 81-20v-56a97 97 0 0 1-54 18c-10 0-19-3-27-7-6-4-10-8-12-14s-3-19-3-40v-91h85v-56h-85V80h-50z"/></svg>');
`;
export const LineIcon = styled(AmpSharingIcon)`
	background-color: #52b448;
	background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="%23fff" d="M443 233c0-84-84-152-188-152S67 149 67 233c0 76 67 139 157 151 7 1 15 4 17 9s1 12 1 17l-3 16c-1 5-4 19 16 10s108-63 148-109c27-30 40-60 40-94zm-254 45a4 4 0 0 1-4 4h-53a4 4 0 0 1-2-1 4 4 0 0 1-1-3v-82a4 4 0 0 1 4-3h13a4 4 0 0 1 3 3v65h36a4 4 0 0 1 4 4zm32 0a4 4 0 0 1-4 4h-13a4 4 0 0 1-4-4v-82a4 4 0 0 1 4-3h13a4 4 0 0 1 4 3zm90 0a4 4 0 0 1-3 4h-13a4 4 0 0 1-1 0h-1v-1a3 3 0 0 1-1-1l-38-50v48a4 4 0 0 1-4 4h-13a4 4 0 0 1-4-4v-82a4 4 0 0 1 4-3h16v1l38 51v-49a4 4 0 0 1 4-3h13a4 4 0 0 1 3 3zm73-69a4 4 0 0 1-3 4h-36v14h36a4 4 0 0 1 3 4v13a4 4 0 0 1-3 4h-36v13h36a4 4 0 0 1 3 4v13a4 4 0 0 1-3 4h-53a4 4 0 0 1-3-1 4 4 0 0 1-1-3v-82a4 4 0 0 1 1-2 4 4 0 0 1 3-1h53a4 4 0 0 1 3 3z" data-name="レイヤー 1"/></svg>');
`;
export const WhappsappIcon = styled(AmpSharingIcon)`
	background-color: #25d366;
	background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="46" height="46"><path fill="%23FFF" d="M35.4 10.4a18.27 18.27 0 0 0-31.2 13c0 3.2.9 6.3 2.4 9.1L4 42l9.7-2.5c2.7 1.5 5.7 2.2 8.7 2.2a18.4 18.4 0 0 0 13-31.3zM22.5 38.6c-2.7 0-5.4-.7-7.7-2.1l-.6-.3-5.8 1.5L9.9 32l-.4-.6c-4.4-7.1-2.3-16.5 4.9-20.9 7.2-4.4 16.5-2.3 20.9 4.9 4.4 7.2 2.3 16.5-4.9 20.9-2.3 1.5-5.1 2.3-7.9 2.3zm8.8-11.1-1.1-.5s-1.6-.7-2.6-1.2c-.1 0-.2-.1-.3-.1-.3 0-.5.1-.7.2l-1.5 1.7c-.1.2-.3.3-.5.3h-.1c-.1 0-.3-.1-.4-.2l-.5-.2c-1.1-.5-2.1-1.1-2.9-1.9-.2-.2-.5-.4-.7-.6-.7-.7-1.4-1.5-1.9-2.4l-.1-.2c-.1-.1-.1-.2-.2-.4 0-.2 0-.4.1-.5 0 0 .4-.5.7-.8.2-.2.3-.5.5-.7.2-.3.3-.7.2-1-.1-.5-1.3-3.2-1.6-3.8-.2-.3-.4-.4-.7-.5h-1.1c-.2 0-.4.1-.6.1l-.1.1c-.2.1-.4.3-.6.4-.2.2-.3.4-.5.6a5.1 5.1 0 0 0-.6 5.4l.1.3c.9 1.9 2.1 3.6 3.7 5.1l.4.4c.3.3.6.5.8.8 2.1 1.8 4.5 3.1 7.2 3.8.3.1.7.1 1 .2h1c.5 0 1.1-.2 1.5-.4.3-.2.5-.2.7-.4l.2-.2c.2-.2.4-.3.6-.5.2-.2.4-.4.5-.6.2-.4.3-.9.4-1.4v-.7s-.1-.1-.3-.2z"/></svg>');
`;
export const EmailIcon = styled(AmpSharingIcon)`
	background-color: #9aa0a6;
	background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="%23fff"><path d="M20 4H4a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') !important;
`;
export const SmsIcon = styled(AmpSharingIcon)`
	background-color: #ca2b63;
	background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="29"><path fill="none" stroke="%23FFF" stroke-width="3" d="M8.7 26v-5.7H2V2h26v18.4H15.9z"/></svg>');
`;
export const AddElementButton = styled(ButtonBase)`
	margin: auto;
	border-radius: 50%;
	display: grid;
	place-items: center;

	background: #000000; /* fallback for old browsers */
	background: linear-gradient(to right, #434343, #000000);
	color: white;
	padding: ${({
  theme
}) => theme.spacing(1)};
	box-shadow: ${({
  theme
}) => theme.shadows[5]};

	// add grow animation on hover
	transition: transform 200ms ease-in-out;
	will-change: transform;
	&:hover {
		transform: scale(1.1);
	}

	.MuiSvgIcon-root {
		font-size: 50px;
	}
`;