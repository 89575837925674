import PageTemplateSelector from 'components/PageTemplateSelector';
import { useStoryDispatch, useStoryState } from 'lib/editorStore';
import { sleep } from 'lib/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PublishedStoriesSvc from 'services/PublishedStoriesSvc';
import { ConfirmationModal } from '..';
import { ModalWrapper } from '../ModalWrapper';
const SelectPageTemplateModal = forwardRef(function SelectPageTemplateModal({
  trigger,
  onOpen
}, ref) {
  const intl = useIntl();
  const changePageLayoutRef = useRef();
  const project = useStoryState(s => s.draftStory.project);
  const page = useStoryState(s => s.page);
  const dispatch = useStoryDispatch();
  const [currentLayout, setCurrentLayout] = useState();
  const [isProcessing, setIsProcessing] = useState(false);
  async function changePageLayout() {
    if (isProcessing) return;
    setIsProcessing(true);
    // Avoid spaming confirm button which causes crash

    let pageLayout = currentLayout;
    if (!_.isEmpty(currentLayout)) {
      pageLayout = await PublishedStoriesSvc.convertPageTemplate({
        project,
        pageTemplate: currentLayout
      });
    }
    dispatch({
      type: 'CHANGE_PAGE_LAYOUT',
      data: {
        page_id: page._id,
        layout: pageLayout,
        intl
      }
    });
    changePageLayoutRef.current.close();
    ref.current.close();
    // Disable confirm button to avoid spam clicks
    await sleep(1000);
    setIsProcessing(false);
  }
  function onSelect(selectedLayout) {
    setCurrentLayout(selectedLayout);
    setIsProcessing(false);
    changePageLayoutRef.current.open();
  }
  function resetStates() {
    setCurrentLayout({});
  }
  return <>
			<ModalWrapper title={intl.messages['modals.create_snackeet.steps.from_template']} trigger={trigger} size='lg' ref={ref} onOpen={onOpen}>
				<PageTemplateSelector isProcessing={isProcessing} onSelect={onSelect} trigger={trigger} isLayoutOnly />
			</ModalWrapper>

			<ConfirmationModal size='sm' isConfirmDisabled={isProcessing} confirmationModalRef={changePageLayoutRef} title={<FormattedMessage id='editor.page_templates.modal.title' />} onClose={resetStates} onConfirm={changePageLayout} firstLine={<FormattedMessage id='editor.page_templates.modal.description' />} confirmText={<FormattedMessage id='common.yes' />} cancelText={<FormattedMessage id='common.no' />} />
		</>;
});
SelectPageTemplateModal.propTypes = {
  trigger: PropTypes.object,
  onOpen: PropTypes.func
};
export default SelectPageTemplateModal;