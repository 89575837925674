import * as React from 'react'

function SvgBackward(props) {
	return (
		<svg width='1em' height='1em' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M17.163 21.145l-8.325-3.652c-.805-.353-.795-1.499.016-1.838l11.496-4.81a1 1 0 01.8.012l11.015 5.009c.787.358.78 1.478-.011 1.826l-7.992 3.514'
				stroke='#324370'
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
			<path
				d='M20.706 16.175l-.112 13m-2.979-2.526l2.979 2.526m0 0l3.021-2.474'
				stroke='#484747'
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
		</svg>
	)
}

export default SvgBackward
