import { Check } from '@mui/icons-material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { AmpInteractionBase, PromptTextContainer, QuizOption, QuizOptionsContainer } from './style';
const Alphabets = {
  0: 'A',
  1: 'B',
  2: 'C',
  3: 'D'
};
export default function AmpQuizInteraction({
  ampInteraction
}) {
  const intl = useIntl();
  const {
    _id,
    prompt_text,
    prompt_size,
    prompt_color,
    theme,
    backgroundColor,
    alignment,
    option_style,
    option_color,
    options,
    outer_style
  } = ampInteraction;
  return <AmpInteractionBase $backgroundColor={backgroundColor} $isTransparent={option_style === 'transparent'} style={outer_style}>
			{/* Prompt text */}
			<PromptTextContainer $promptColor={prompt_color} $alignment={alignment} $promptSize={prompt_size}>
				<p>{prompt_text}</p>
			</PromptTextContainer>

			{/* Options */}
			<QuizOptionsContainer $theme={theme} $optionStyle={option_style} $optionColor={option_color} $isTransparent={option_style === 'transparent'}>
				{_.map(options, (option, index) => <QuizOption key={option._id} $isCorrect={option.isCorrect} className='option-container'>
						<div className='list-alphabet'>
							{option.isCorrect ? <Check size='small' /> : `${Alphabets[index]}`}
						</div>
						<span>{option.title}</span>
					</QuizOption>)}
			</QuizOptionsContainer>
		</AmpInteractionBase>;
}
AmpQuizInteraction.propTypes = {
  ampInteraction: PropTypes.object
};