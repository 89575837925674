import { Checkbox, FormControlLabel } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
export function FieldCheckbox({
  value,
  field_name,
  handleUpdate
}) {
  function handleChange({
    target: {
      name,
      checked
    }
  }) {
    handleUpdate({
      [name]: checked
    });
  }
  return <FormControlLabel control={<Checkbox checked={value} onChange={handleChange} name={field_name} color='secondary' />} label={<b>{_.upperCase(field_name)}</b>} labelPlacement='end' />;
}
FieldCheckbox.propTypes = {
  value: PropTypes.bool,
  field_name: PropTypes.string.isRequired,
  handleUpdate: PropTypes.func.isRequired
};
FieldCheckbox.defaultProps = {
  value: false
};