import { PREVIEW_HEIGHT, PREVIEW_WIDTH } from 'lib/constants';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { memo } from 'react';
import AmpAttachments from './amp_attachments';
import AmpInteractions from './amp_interactions';
import BackgroundElement from './BackgroundElements';
import Block from './Blocks';
import ButtonLists from './ButtonLists';
import Carousels from './Carousels';
import Interface from './interactions';
import { AmpShareIcon, Container, DisplayContainer, MainContainer, ProgressBarItem, StoryControlsContainer, StorySystemLayerContainer } from './styled';
import Tags from './Tags';
const sizeToRatio = {
  small: 0.182,
  medium: 0.5,
  large: 0.7,
  extra_lage: 0.9
};
const sizeToWidth = {
  small: 65,
  medium: 180,
  large: 251,
  extra_lage: 322
};
function PagePreview({
  page,
  theming,
  messages,
  gdprConsent,
  storyType,
  size = 'small',
  // Size is a predefined value or the width of the preview
  isRewardsEnabled,
  hasQuiz = false,
  wheelComponentState
}) {
  const scaleRatio = sizeToRatio[size] ?? size / PREVIEW_WIDTH;
  const width = sizeToWidth[size] ?? size;
  const height = width * (PREVIEW_HEIGHT / PREVIEW_WIDTH);
  return <MainContainer $width={width} $height={height} $scaleRatio={scaleRatio}>
			<div>
				<DisplayContainer>
					<StorySystemLayerContainer>
						<Container>
							{[...Array(6).keys()].map((value, index) => <ProgressBarItem key={index} $isCompleted={index <= 0} />)}
						</Container>
						<StoryControlsContainer>
							<AmpShareIcon />
						</StoryControlsContainer>
					</StorySystemLayerContainer>

					<BackgroundElement key={`background-element-${page._id}`} {...page.background_element} />

					<div className='blocks'>
						{_.map(page.blocks, block => <Block key={block._id} {...block} />)}
					</div>

					<ButtonLists page={page} />

					<Carousels page={page} />

					<Tags page={page} />
				</DisplayContainer>

				{storyType !== 'amp' && <Interface page={page} theming={theming} messages={messages} gdprConsent={gdprConsent} storyType={storyType} isRewardsEnabled={isRewardsEnabled} hasQuiz={hasQuiz} wheelComponentState={wheelComponentState} />}

				{storyType === 'amp' && <>
						<AmpInteractions amp_interactions={page.amp_interactions} />

						<AmpAttachments amp_attachments={page.amp_attachments} />
					</>}

				{/* { !snackeet.story_settings.disable_powered_by && <TradeMarkFooter /> } */}
			</div>
		</MainContainer>;
}
PagePreview.propTypes = {
  page: PropTypes.object,
  theming: PropTypes.object,
  messages: PropTypes.object,
  gdprConsent: PropTypes.object,
  storyType: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isRewardsEnabled: PropTypes.bool,
  hasQuiz: PropTypes.bool,
  wheelComponentState: PropTypes.string
};
export default memo(PagePreview);