import { Checkbox, InputLabel, MenuItem, Select } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { DropdownContainer, DropdownOption } from './Dropdown.style';
export default function Dropdown({
  labelId,
  label,
  options,
  value,
  onChange,
  placeholder,
  useFontFamilies = false,
  fullWidth = true,
  nullable = false,
  multiple = false,
  maxWidth,
  renderValue,
  ...delegatedProps
}) {
  const [open, setOpen] = useState(false);
  function handleOpen(evt) {
    setOpen(true);
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    evt.preventDefault();
  }
  function handleClose(evt) {
    setOpen(false);
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    evt.preventDefault();
  }
  return <DropdownContainer $maxWidth={maxWidth} fullWidth={fullWidth} error={!_.some(options, {
    value
  }) && !multiple && !nullable}>
			{label && labelId && <InputLabel id={labelId}>{label}</InputLabel>}
			<Select open={open} labelId={labelId} value={value} fullWidth={fullWidth} onOpen={handleOpen} onClose={handleClose} onChange={onChange} multiple={multiple} displayEmpty={!!placeholder} renderValue={renderValue} {...delegatedProps}>
				{placeholder && <MenuItem value='' disabled>
						<DropdownOption>
							<em>{placeholder}</em>
						</DropdownOption>
					</MenuItem>}
				{renderItems({
        options,
        value,
        multiple,
        useFontFamilies
      })}
			</Select>
		</DropdownContainer>;
}
Dropdown.propTypes = {
  labelId: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  useFontFamilies: PropTypes.bool,
  nullable: PropTypes.bool,
  multiple: PropTypes.bool,
  renderValue: PropTypes.any
};
function renderItems({
  options,
  value,
  multiple,
  useFontFamilies
}) {
  if (multiple) {
    return _.map(options, (option, index) => <MenuItem key={index} value={option.value}>
				<DropdownOption>
					<Checkbox color='primary' size='small' checked={value.indexOf(option.value) > -1} />
					{option.text}
				</DropdownOption>
			</MenuItem>);
  }
  return _.map(options, (option, index) => <MenuItem key={index} value={option.value} style={useFontFamilies && option.value ? {
    fontFamily: option.value
  } : {}}>
			<DropdownOption>
				{option.label ? <>
						<b>{option.text}</b>
						<i>{`(${option.label})`}</i>
					</> : option.text}
			</DropdownOption>
		</MenuItem>);
}