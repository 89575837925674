import { css } from '@emotion/react';
import { Card, DialogContent } from '@mui/material';
import styled from '@styled';
export const WidgetCardContainer = styled.div`
	flex: 1;
	max-width: 300px;
	margin: ${({
  theme
}) => theme.spacing(0, 2)};
	cursor: ${({
  $isDisabled
}) => $isDisabled ? 'not-allowed' : 'pointer'};

	.caption {
		margin-top: 8px;
		font-size: 16px;
		text-align: center;
	}
`;
const isSelectedStyle = ({
  theme
}) => css`
	outline-width: 5px;
	outline-color: ${theme.palette.primary.main};

	&:hover {
		outline-width: 5px;
	}
`;
const isDisabledStyle = css`
	pointer-events: none;
	filter: grayscale(100%);
`;
export const WidgetCard = styled(Card)`
	outline-color: black;
	outline-style: solid;
	outline-width: 0px;

	&:hover {
		outline-width: 1px;
	}

	${({
  $isSelected
}) => $isSelected && isSelectedStyle};
	${({
  $isDisabled
}) => $isDisabled && isDisabledStyle};

	.MuiButtonBase-root {
		cursor: inherit;
	}

	.MuiTouchRipple-child {
		background-color: ${({
  theme
}) => theme.palette.primary.main};
	}

	.image-container {
		position: relative;
		aspect-ratio: 16 / 8;
		margin: ${({
  theme
}) => theme.spacing(0, 5)};
	}
`;
export const BannerDialogContent = styled(DialogContent)`
	display: flex;
	flex-direction: column;
`;