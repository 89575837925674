import styled from '@styled';
export const HighlightedDropzone = styled.div<{
  $highlight: boolean;
}>`
	position: absolute;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.5);
	color: white;
	backdrop-filter: blur(5px);
	display: grid;
	place-items: center;
	opacity: ${({
  $highlight
}) => $highlight ? 1 : 0};
	transition: opacity 0.3s;
	pointer-events: none;
`;