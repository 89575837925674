import { Clear, Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
export default function SearchBar({
  value,
  onChange,
  placeholder,
  size = 'medium',
  fullWidth = true,
  variant = 'standard',
  disableUnderline = false,
  sx,
  ...other
}) {
  const [query, setQuery] = useState(value);
  const delayedChange = useDebouncedCallback(onChange, 500);
  const disableUnderLineProps = variant === 'outlined' ? {} : {
    disableUnderline
  };
  function resetSearch() {
    setQuery('');
    onChange('');
  }
  function handleQuery({
    target: {
      value
    }
  }) {
    setQuery(value);
    delayedChange(value);
  }
  return <TextField size={size} placeholder={placeholder} value={query} onChange={handleQuery} fullWidth={fullWidth} variant={variant} sx={{
    bgcolor: 'background.paper',
    py: 1,
    px: 0.5,
    borderRadius: '10px',
    maxWidth: 240,
    boxShadow: 1,
    ...sx
  }} InputProps={{
    startAdornment: <InputAdornment position='start'>
						{value === '' ? <IconButton size='small'>
								<Search />
							</IconButton> : <IconButton onClick={resetSearch} size='small'>
								<Clear />
							</IconButton>}
					</InputAdornment>,
    ...disableUnderLineProps
  }} {...other} />;
}
SearchBar.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  disableUnderline: PropTypes.bool,
  sx: PropTypes.object
};