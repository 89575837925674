import { css } from '@emotion/react';
import { Button, Modal, Rating } from '@mui/material';
import styled from '@styled';
import { PREVIEW_HEIGHT, PREVIEW_WIDTH } from 'lib/constants';
import { getObjectPosition, transformToObjPostion } from 'lib/utils';
export const MainContainer = styled.div`
	width: ${({
  $width
}) => $width}px;
	height: ${({
  $height
}) => $height}px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	will-change: transform;
	backface-visibility: hidden;
	transform: translateZ(0);

	& > div {
		position: relative;
		background-color: transparent;
		width: ${PREVIEW_WIDTH}px;
		/* DO NOT CHANGE THE CODE BELOW, IT SAVED US WHEN CHROME UPDATE TO v90 */
		/* THEY CHANGED THE WAYS OF COMPUTING HEIGHT AND WIDTH SO MIN-HEIGHT TO SURVIVE */
		/* SAFARI SAID NAH NAH NAH, SO WE RETURN THE HEIGHT AND PLACE IT BEFORE THE MIN_HEIGHT */
		height: ${PREVIEW_HEIGHT}px;
		min-height: ${PREVIEW_HEIGHT}px;
		will-change: transform;
		backface-visibility: hidden;
		transform: scale(${({
  $scaleRatio
}) => $scaleRatio}) translateZ(0px) perspective(1000px);
	}
`;
export const BaseInteraction = styled.div`
	pointer-events: auto;
	cursor: inherit;
	border-radius: 4px;
	width: auto;
	max-height: 100%;
	margin: 0;
	padding: ${({
  theme
}) => theme.spacing(0, 2.5)};
	overflow: auto;
`;
const lonelyButton = css`
	height: 80px;
	width: 260px;

	display: flex;
	flex-direction: row;

	svg {
		margin-right: 8px;
	}
`;
export const MediaAnswerButton = styled(Button)`
	height: 80px;
	min-width: 64px;
	max-width: 260px;
	overflow: hidden;
	font-size: 14px;
	text-align: center;
	padding: 16px 16px;

	display: flex;
	flex-direction: column;

	${({
  $isAlone
}) => $isAlone && lonelyButton}
`;
export const DisplayContainer = styled.div`
	width: 359px;
	height: 630px;
	position: relative;
	transform: translateZ(0) translate3d(0, 0, 0);
	background: #fff;
	pointer-events: none;
	border-radius: 10px;
	overflow: hidden;
	filter: drop-shadow(0 0 8px rgb(14 19 24 / 30%));

	.nav {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 60px;
		top: calc(50% - 30px);
		border-radius: 30px;
		box-sizing: content-box;
		z-index: 1;
	}

	.left {
		left: 0;
		padding-right: 8px;
		pointer-events: none;

		div {
			border-radius: 30px;
			padding: 1px 3px !important;
			justify-content: flex-start;
			border-top-left-radius: 0px !important;
			border-bottom-left-radius: 0px !important;
		}
	}

	.right {
		right: 0;
		padding-left: 16px;
		pointer-events: none;

		div {
			border-radius: 30px;
			padding: 1px 3px !important;
			justify-content: flex-end;
			border-top-right-radius: 0px !important;
			border-bottom-right-radius: 0px !important;
		}
	}

	.blocks {
		overflow: hidden;
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		pointer-events: none;

		& > * {
			position: absolute;
		}
	}
`;
DisplayContainer.defaultProps = {
  id: 'display-pane'
};
export const InterfaceContainer = styled.div`
	position: absolute;
	bottom: 30px;
	left: 0;
	right: 0;
	width: 100%;
	max-height: 70%; /* ⚠️ Limit size of interface and allow scroll when too many form fields */
	padding: ${({
  theme
}) => theme.spacing(1)};
	box-sizing: border-box;
	line-height: 16px;

	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-end;

	transform: translate3d(0, 0, 0);
	will-change: transform;
	backface-visibility: hidden;

	.interactions {
		width: 100%;
		padding: ${({
  theme
}) => theme.spacing(1)};
		max-height: 100%;
		display: flex;
		flex-direction: column;
		overflow: auto;

		& > div {
			margin-bottom: ${({
  theme
}) => theme.spacing(1)};
		}
		& > div:last-of-type {
			margin-bottom: 0;
		}
	}

	.form-actions {
		display: flex;
		justify-content: center;
		width: 100%;
		padding: ${({
  theme
}) => theme.spacing(1, 2, 2)};

		& > button,
		& > span {
			margin: 0 auto;
		}
	}

	// Used in endPage
	.ending-page-actions {
		width: 100%;
		padding: ${({
  theme
}) => theme.spacing(1, 2, 2)};

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;

		.MuiButtonBase-root {
			width: 300px;
			min-height: 40px;
			margin: ${({
  theme
}) => theme.spacing(0.5, 'auto')};
		}
	}
`;
InterfaceContainer.defaultProps = {
  id: 'interface-pane'
};

/* ---- RATING ---- */

export const RatingInteractionContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${({
  theme
}) => theme.palette.background.paper};
	border-radius: ${({
  theme
}) => theme.shape.borderRadius}px;
	width: 100%;
	min-height: 70px;
	padding: ${({
  theme
}) => theme.spacing(2)};

	& > .MuiRating-root {
		margin: ${({
  theme
}) => theme.spacing(1)};
	}
`;
export const RatingContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	width: 100%;
	padding: ${({
  theme
}) => theme.spacing(1, 0)};
	div {
		transition: transform 150ms ease-in-out;
		width: 70px;
		height: 70px;
		border-radius: 50%;
		background-size: cover;
		cursor: pointer;
	}
	div:hover {
		transform: scale(1.1);
	}
`;
export const RedMuiRating = styled(Rating)`
	color: red;
`;

/* ---- MEDIA ANSWER ---- */

export const MediaAnswersInteractionContainer = styled(InterfaceContainer)`
	// Used in media answer
	.answer-media-options {
		background-color: rgba(255, 255, 255, 0.6);
		border-radius: 4px;
	}

	.answer-media-answered {
		.MuiButton-root {
			width: 80%;
			border-radius: 27px;
		}

		.button-answered {
			background-color: #00cc00;
			color: white;
			margin-bottom: 16px;

			.MuiButton-label {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
	}
`;

/* ---- FORM INTERACTION ---- */
export const FormInteractionContainer = styled(BaseInteraction)`
	padding-top: 4px;
	padding-bottom: 4px;
	display: flex;
	flex-direction: column;
	height: fit-content;
	width: 100%;
	& .muiformcontrol-root:last-of-type {
		margin-bottom: 0;
	}
`;
export const EmptyFormFields = styled(BaseInteraction)`
	color: ${({
  theme
}) => theme.palette.primary.main};
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 22px 15px;
	width: 100%;
	cursor: pointer;
`;
export const ConsentMessage = styled.div`
	pointer-events: auto;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 0;
	font-size: 12px;
	padding: 0 20px;
	cursor: pointer;
	border: transparent 3px solid;
	border-radius: 4px;

	span {
		color: ${props => props.theming.color};
	}
	.MuiCheckbox-colorPrimary {
		color: ${props => props.theming.color};
		padding: 0 4px 0 0;
	}
`;
export const FieldConsent = styled.div`
	width: 100%;
	pointer-events: auto;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 0;
	font-size: ${props => props.theming.fontSize};
	font-family: ${props => props.theming.fontFamily};
	cursor: pointer;
	border: transparent 3px solid;
	border-radius: 4px;

	&:not(:last-of-type) {
		margin-bottom: ${({
  theme
}) => theme.spacing(0.5)};
	}

	span {
		text-align: left;
		color: ${props => props.theming.color};
	}

	.checkbox-title {
		color: ${props => props.theming.color};
		font-size: inherit;

		a {
			color: ${props => props.theming.color};
			font-size: inherit;
			text-decoration: underline;
			pointer-events: none;
		}
	}

	.MuiCheckbox-colorPrimary {
		color: ${props => props.theming.color};
		padding: 0 4px 0 0;
	}
`;

/* ---- GAME INTERACTION ---- */
export const GameInteractionContainer = styled(InterfaceContainer)`
	.interactions {
		overflow: hidden;
	}
`;
export const BackgroundColorContainer = styled.div`
	position: absolute;
	background-color: ${({
  $color
}) => $color};
	width: 100%;
	height: 100%;
	pointer-events: none;
	${({
  $gradient
}) => `background: ${$gradient};`};
`;
const ImageAuto = ({
  position
}) => css`
	img {
		object-fit: ${position.backgroundSize};
		object-position: ${position.backgroundPosition};
	}
`;
const ImageReframeCss = ({
  position
}) => css`
	img {
		object-fit: cover;
		object-position: ${getObjectPosition(position)};
	}
`;
const ImgZoomCss = ({
  position
}) => css`
	img {
		transform-origin: ${getObjectPosition(position)};
	}
`;
export const BackgroundImage = styled.div`
	width: 100%;
	height: 100%;
	pointer-events: none;
	filter: ${({
  $blur
}) => $blur ? `blur(${$blur})` : 'none'};

	img {
		width: 100%;
		height: 100%;
		transform: ${({
  position
}) => position.zoom ? `scale(${position.zoom})` : 'initial'};

		object-fit: ${({
  position
}) => position.objectFit};
		object-position: ${({
  position
}) => transformToObjPostion(position.transform, position.zoom)};
	}

	${({
  position
}) => position.backgroundSize && ImageAuto({
  position
})};
	${({
  position
}) => position?.croppedArea && ImageReframeCss({
  position
})};
	${({
  position
}) => position?.croppedArea && position?.zoom > 1 && ImgZoomCss({
  position
})};
`;
export const BackgroundDimmer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: #000;
	opacity: ${props => props.$opacity};
`;
export const ShareModal = styled(Modal)`
	.MuiPaper-root {
		max-width: ${PREVIEW_WIDTH}px;
		width: 100%;
		padding: 15px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.share-icons {
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			gap: 10px;
			overflow: auto;
		}
	}
`;
export const LinkedinButtonShare = styled(Button)`
	height: 50px;
	width: 50px;
	border-radius: 50%;

	&:hover {
		background-color: transparent !important;
	}
`;
export const ButtonContainer = styled.div`
	width: 100%;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border-top: 1px solid #ccc;

	.close {
		position: absolute;
		top: 6px;
		right: 5px;
		z-index: 1;
		background-color: rgba(255, 255, 255, 0.5);

		.MuiSvgIcon-root {
			height: 40px;
			width: 40px;
		}
	}
`;
const videoMixin = css`
	video {
		position: unset;
	}

	video::-webkit-media-text-track-display {
		white-space: pre-wrap !important;
		font-size: inherit !important;
	}
`;
const VideoZoomCss = ({
  inner_style
}) => css`
	transform-origin: ${getObjectPosition(inner_style)};
`;
export const BackgroundVideoContainer = styled.div`
	position: relative;
	border-radius: 10px;
	height: 100%;
	width: 100%;
	background-color: ${({
  inner_style
}) => inner_style.backgroundColor ? inner_style.backgroundColor : 'rgba(255, 255, 255, 1)'};
	border-color: ${props => props.outer_style.borderColor};
	border-style: ${props => props.outer_style.borderStyle};
	border-width: ${props => props.outer_style.borderWidth};
	overflow: hidden;
	filter: ${({
  $blur
}) => $blur ? `blur(${$blur})` : 'none'};

	.react-player {
		max-width: 100%;
		pointer-events: none;

		& > video {
			position: unset;
			object-fit: cover;
			object-position: ${({
  inner_style
}) => getObjectPosition(inner_style)};
			transform: ${({
  inner_style
}) => inner_style.zoom ? `scale(${inner_style.zoom})` : 'initial'};

			${({
  inner_style
}) => inner_style?.croppedArea && inner_style?.zoom > 1 && VideoZoomCss({
  inner_style
})};

			&::-webkit-media-text-track-display {
				white-space: pre-wrap !important;
			}
		}
	}
`;
export const VideoPoster = styled.div`
	width: 100%;
	height: 100%;
	pointer-events: none;
	opacity: ${props => props.isReady ? 0 : 1};
	position: absolute;
	transition: all 300ms ease-in-out;

	img {
		width: 100%;
		height: 100%;

		object-fit: ${({
  position
}) => position.objectFit};
		object-position: ${({
  position
}) => transformToObjPostion(position.transform)};
		transform: ${({
  position
}) => position.zoom ? `scale(${position.zoom})` : 'initial'};
	}

	${({
  position
}) => position.backgroundSize && ImageAuto({
  position
})};
	${({
  position
}) => position?.croppedArea && ImageReframeCss({
  position
})};
	${({
  position
}) => position?.croppedArea && position?.zoom > 1 && ImgZoomCss({
  position
})};
`;
export const BlockVideoPoster = styled.div`
	width: 100%;
	height: 100%;
	pointer-events: none;
	opacity: ${({
  isReady
}) => isReady ? 0 : 1};
	position: absolute;
	z-index: 2;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: ${({
  inner_style
}) => getObjectPosition(inner_style)};
		transform: ${({
  inner_style
}) => inner_style.zoom ? `scale(${inner_style.zoom})` : 'initial'};

		${({
  inner_style
}) => inner_style?.zoom > 1 && VideoZoomCss};
	}
`;
const maskImageCss = ({
  $maskImage
}) => $maskImage && css`
		mask-image: ${`url(${$maskImage})`};
		mask-size: 100% 100%;
	`;
export const StyledPlayer = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	pointer-events: none;

	${maskImageCss};

	video {
		object-fit: cover;
		object-position: ${({
  inner_style
}) => getObjectPosition(inner_style)};
		transform: ${({
  inner_style
}) => inner_style.zoom ? `scale(${inner_style.zoom})` : 'initial'};

		${({
  inner_style
}) => inner_style?.zoom > 1 && VideoZoomCss};

		&::-webkit-media-text-track-display {
			whitespace: pre-wrap !important;
		}
	}
`;
export const VideoBlockContainer = styled.div`
	display: grid;
	place-items: center;
	& > div {
		max-width: 100%;
	}

	${videoMixin}
`;

// Story Controls
export const StorySystemLayerContainer = styled.div`
	position: absolute;
	top: 0;
	background: linear-gradient(180deg, #0004, #0002 33%, #0001 67%, #0000) !important;
	height: 100px;
	width: 100%;
	z-index: 1;
	transition: opacity 0.3s cubic-bezier(0, 0, 0.2, 1);
	pointer-events: none;
	border-top-left-radius: var(--story-border-radius);
	border-top-right-radius: var(--story-border-radius);
`;
export const Container = styled.ol`
	display: flex;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 5;
	margin: unset;
	padding: 5px 10px 0 10px;
`;
export const ProgressBarItem = styled.li`
	height: 2px;
	flex-grow: 1;
	border-radius: 4px;
	margin: 0 5px;
	display: flex;
	background-image: -webkit-linear-gradient(
		left,
		rgba(255, 255, 255, 0.8) 0%,
		rgba(255, 255, 255, 0.8) 50%,
		hsla(0, 0%, 100%, 0.4) 50.001%,
		hsla(0, 0%, 100%, 0.4) 100%
	);
	background-repeat: no-repeat;
	background-size: 200%;
	background-position: ${({
  $isCompleted
}) => $isCompleted ? '0 0' : '100% 0'};
`;
export const StoryControlsContainer = styled.div`
	position: absolute;
	top: 10px;
	right: 5px;
	z-index: 4;
	display: flex;
	align-items: center;
	pointer-events: all;
`;
export const StoryControlsButton = styled.button`
	color: white;
	background: unset;
	background-position: center;
	padding: 1px 6px;
	height: 48px;
	width: 48px;
	border: none;
	cursor: pointer;
	transition: background-color 150ms ease-in-out;
	opacity: ${props => props.$isActive ? 1 : 0.7};

	@media (hover: hover) {
		:hover {
			background-color: rgba(0, 0, 0, 0.1) !important;
		}
	}
	@media (hover: none) {
		:hover {
			background-color: rgba(0, 0, 0, 0.1) !important;
		}
	}

	:active {
		opacity: 1;
		background-color: rgba(0, 0, 0, 0.2) !important;
	}

	&[disabled] {
		opacity: 0.5;
		pointer-events: none;
	}
`;
export const AmpShareIcon = styled(StoryControlsButton)`
	background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none"><path fill="%23fff" d="m21.1 25.4 9.87-9.86 1.42 1.42-9.86 9.87z"/><path stroke="%23fff" stroke-linecap="round" stroke-width="1.9" d="m16.85 19.82 15.33-4.14c.04 0 .07.03.06.06l-4.02 15.34a1.6 1.6 0 0 1-2.97.33l-2.93-5.73-5.77-2.88a1.6 1.6 0 0 1 .3-2.98z"/></svg>');
`;
export const StyledSystemButton = styled(Button)`
	pointer-events: auto;
	overflow: hidden;
	margin-top: 3px !important;
	transition: transform 200ms ease-in-out;
	will-change: transform;

	&.MuiButtonBase-root {
		min-width: 100px;
		max-width: 300px;
		min-height: 40px;
		border-radius: 60px;
		padding: ${({
  theme
}) => theme.spacing(1, 4)};
		display: flex;
		align-items: center;
		position: relative;
		text-transform: initial;
		line-height: 1.5;

		&:hover {
			transform: scale(1.02);
		}
	}

	&.MuiButton-label {
		display: flex;
		justify-content: center;
		cursor: pointer;
	}
	&.MuiButton-endIcon {
		margin-left: 4px;
	}
`;