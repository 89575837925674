import { Divider, List, ListItem, ListItemText, Popover, Tooltip } from '@mui/material';
import styled from '@styled';
import SearchBar from 'components/atoms/SearchBar';
import { CustomIcon } from 'components/icons';
import { HoverableIcon } from 'components/styled';
import { useStoryState } from 'lib/editorStore';
import _ from 'lodash';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useIntl } from 'react-intl';
const VariablesPopover = styled(Popover)`
	.MuiPaper-rounded {
		border-radius: 10px;
	}
	.MuiList-root {
		overflow: auto;
		max-width: 250px;
		max-height: 300px;
		scrollbar-gutter: stable;
	}
	.MuiListItemText-root {
		font-size: 14px;
	}
`;
const VariablesHeader = styled.div`
	width: 100%;
	padding: ${({
  theme
}) => theme.spacing(2, 2, 1, 2)};

	p {
		font-size: 14px;
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;
		margin: unset;
		margin-bottom: ${({
  theme
}) => theme.spacing(1)};
	}

	.MuiOutlinedInput-root {
		border-radius: 20px;
	}
`;
const defaultAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right'
};
const defaultTransformOrigin = {
  vertical: 'top',
  horizontal: 'right'
};
export default function VariablesSelector({
  localVariable,
  onAdd,
  isClassicStory = false
}) {
  const intl = useIntl();
  const variables = useStoryState(s => s.draftStory?.variables);
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'variable-picker',
    disableAutoFocus: true
  });
  const [search, setSearch] = useState('');
  const variableParams = isClassicStory ? ['score', 'max_score', ..._.flatMap(variables, variable => [variable.name])] : _.flatMap(variables, variable => [variable.name]);
  const onSelect = variable => () => {
    onAdd(variable);
    popupState.close();
  };
  return <>
			<Tooltip title={intl.messages['edition_menu.hovertools.variables']} placement='top'>
				<HoverableIcon {...bindTrigger(popupState)} $width={40} $height={20}>
					<CustomIcon name='Variables' width={40} height={20} />
				</HoverableIcon>
			</Tooltip>

			<VariablesPopover {...bindPopover(popupState)} anchorOrigin={defaultAnchorOrigin} transformOrigin={defaultTransformOrigin}>
				<VariablesHeader>
					<p>{intl.formatMessage({
            id: 'edition_menu.variables.insert'
          }, {
            count: 2
          })}</p>
					<SearchBar fullWidth variant='standard' value={search} onChange={setSearch} placeholder={intl.formatMessage({
          id: 'edition_menu.variables.search'
        }, {
          count: 2
        })} disableUnderline sx={{
          py: 0.5
        }} />
				</VariablesHeader>
				<List dense>
					{localVariable && <>
							{_.map(applyFilter(localVariable, search), variable => <ListItem key={variable} button onClick={onSelect(variable)}>
									<ListItemText primary={variable} disableTypography />
								</ListItem>)}
							<Divider />
						</>}
					{_.map(applyFilter(variableParams, search), variable => <ListItem key={variable} button onClick={onSelect(variable)}>
							<ListItemText primary={variable} disableTypography />
						</ListItem>)}
				</List>
			</VariablesPopover>
		</>;
}
VariablesSelector.propTypes = {
  localVariable: PropTypes.array,
  onAdd: PropTypes.func,
  isQuiz: PropTypes.bool
};
function applyFilter(variables, search) {
  if (!search) {
    return variables;
  }

  // Filter by search query (ignore description)
  const searchReg = new RegExp(search, 'i');
  return _.filter(variables, variable => searchReg.test(variable));
}