import { css } from '@emotion/react';
import { Button } from '@mui/material';
import styled from '@styled';
import { EditorPageLayout } from 'components/styled/Editor';
export const SettingsPageLayout = styled(EditorPageLayout)`
	grid-template-columns: auto 1fr;
	grid-gap: ${props => props.theme.spacing(2)};
	padding: ${props => props.theme.spacing(2)};

	header {
		margin-bottom: ${props => props.theme.spacing(2)};
		background-color: white;
		width: max-content;
		padding: ${props => props.theme.spacing(3)};
		box-shadow: ${props => props.theme.shadows[1]};
	}
`;
const Option = styled.div`
	border: 2px solid ${({
  theme
}) => theme.palette.secondary.main};
	width: 60px;
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border-radius: 5px;
	margin: auto;
	> div:first-child {
		background-color: ${({
  theme
}) => theme.palette.secondary.main};
		border-radius: 5px;
	}
`;
export const FullScreenOption = styled(Option)`
	> div:first-child {
		width: 45px;
		height: 45px;
	}
`;
export const EmbeddedOption = styled(Option)`
	> div:first-child {
		height: 22px;
		width: 100%;
	}
`;
export const WidgetOption = styled(Option)`
	> div:first-child {
		position: absolute;
		width: 28px;
		height: 45px;
		bottom: -5px;
		right: -5px;
	}
`;
const activeStyle = ({
  $isActive,
  theme
}) => $isActive && css`
		outline: 2px solid ${theme.palette.primary.main};
		&:hover {
			outline: 2px solid ${theme.palette.primary.main}!important;
		}
	`;
export const WidgetPositionContainer = styled.div`
	background-color: #ccc;
	cursor: pointer;
	width: 80px;
	height: 60px;
	position: relative;
	border-radius: 8px;

	&:hover {
		outline: 1px solid #1a1a1a;
	}

	${activeStyle}

	&.bottom {
		& > div {
			bottom: 5px;
		}
	}
	&.top {
		& > div {
			top: 5px;
		}
	}
	&.left {
		& > div {
			left: 5px;
		}
	}
	&.right {
		& > div {
			right: 5px;
		}
	}
`;
export const WidgetPositionElement = styled.div`
	position: absolute;
	border: 1px solid #1a1a1a;
	background-color: ${({
  theme
}) => theme.palette.primary.dark};
	border-radius: 4px;

	&.square {
		height: 20px;
		width: 20px;
	}
	&.horizontal {
		width: 20px;
		height: 15px;
	}
	&.vertical {
		width: 15px;
		height: 20px;
	}
`;
export const ScriptContainer = styled.pre`
	width: 100%;
	border-radius: 10px;
	background-color: #2d2d2d;
	padding: 10px;
	padding-right: 100px;

	code {
		tab-size: 1;
		padding: 0;
	}

	.script-email {
		word-break: break-all;
		white-space: initial;
	}
`;
export const CopyButton = styled(Button)`
	display: ${({
  $alwaysVisible
}) => $alwaysVisible ? 'block' : 'none'};
	position: absolute;
	top: 12px;
	right: 10px;
	background-color: white;
	border: 1px solid transparent;
	min-width: unset;

	&:hover {
		background-color: white;
	}

	${ScriptContainer}:hover & {
		display: block;
	}
`;
CopyButton.defaultProps = {
  size: 'small',
  variant: 'outlined',
  color: 'secondary'
};
export const CopySection = styled.div`
	width: ${({
  $fullWidth
}) => $fullWidth ? '100%' : 'fit-content'};
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;

	min-width: 500px;
	padding: ${({
  theme
}) => theme.spacing(0, 1)};

	&:not(:last-of-type) {
		margin-bottom: 0;
	}

	.email-container {
		padding: 10px;
		padding-right: 100px;
	}
`;
export const VariablesContainer = styled.div`
	min-width: 500px;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: ${({
  theme
}) => theme.spacing(1, 2)};
	align-items: center;
	padding: ${({
  theme
}) => theme.spacing(1)};

	.variable-card {
		width: 100%;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: ${({
  theme
}) => theme.spacing(0, 1)};
		border-radius: ${({
  theme
}) => theme.spacing(0.5)};
		box-shadow: ${({
  theme
}) => theme.shadows[2]};
	}
`;
export const QRCodeContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	min-width: 500px;
	margin: ${({
  theme
}) => theme.spacing(1, 0)};

	.MuiButtonBase-root {
		svg {
			width: 20px;
			height: 20px;
		}
	}
`;
export const LabelInput = styled.label`
	font-size: 14px;
	color: ${({
  theme
}) => theme.palette.primary.main};
	margin-right: ${({
  theme
}) => theme.spacing(1.5)};
`;
export const InputContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	padding: ${({
  theme
}) => theme.spacing(1)};

	& > div {
		display: flex;
		align-items: center;
	}

	input {
		padding: ${({
  theme
}) => theme.spacing(1, 2)};
	}
`;
export const OverlayImage = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.4);
	color: white;
`;