import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
const MaterialValueSelector = ({
  className,
  handleOnChange,
  options,
  value
}) => {
  // console.log(options)

  return <FormControl variant='outlined' className={className}>
			<Select variant='outlined' value={value} onChange={e => handleOnChange(e.target.value)}>
				{options.map(option => {
        const key = `key-${option.id ?? option.name}`;
        return <MenuItem key={key} value={option.name}>
							{option.label}
						</MenuItem>;
      })}
			</Select>
		</FormControl>;
};
MaterialValueSelector.propTypes = {
  className: PropTypes.string,
  handleOnChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.string
};
MaterialValueSelector.displayName = 'MaterialValueSelector';
export default MaterialValueSelector;