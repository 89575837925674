import { Button, DialogActions, DialogContent, DialogContentText, TextField } from '@mui/material';
import { forwardRef, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModalWrapper } from '../ModalWrapper';
const DeleteDomainModal = forwardRef(function DeleteDomainModal({
  domain,
  onConfirm,
  onClose
}, ref) {
  const intl = useIntl();
  const [value, setValue] = useState('');
  const onSubmit = useCallback(() => {
    if (value !== domain?.fqdn) return;
    onConfirm();
    ref.current?.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain?.fqdn, onConfirm, value]);
  const handleEnterKey = useCallback(evt => {
    if ((evt.key === 'Enter' || evt.keyCode === 13) && value === domain?.fqdn) {
      onSubmit();
    }
  }, [value, domain?.fqdn, onSubmit]);
  return <ModalWrapper ref={ref} size='xs' title={<FormattedMessage id='domains.delete_modal.title' />} onClose={() => {
    setValue('');
    onClose();
  }}>
			<DialogContent dividers>
				<DialogContentText component='div'>
					<FormattedMessage id='domains.delete_modal.instructions' />
				</DialogContentText>

				<DialogContentText color='primary'>
					<b>{domain?.fqdn}</b>
				</DialogContentText>

				<TextField sx={{
        mt: 1
      }} size='small' variant='outlined' fullWidth={true} placeholder={domain?.fqdn ?? ''} value={value} onChange={evt => setValue(evt.target.value)} error={value !== '' && value !== domain?.fqdn} onKeyUp={handleEnterKey} />
			</DialogContent>

			<DialogActions>
				<Button variant='text' onClick={() => ref.current?.close()}>
					<FormattedMessage id='common.no' />
				</Button>
				<Button variant='outlined' color='secondary' disabled={value !== domain?.fqdn} onClick={onSubmit}>
					<FormattedMessage id='common.yes' />
				</Button>
			</DialogActions>
		</ModalWrapper>;
});
export default DeleteDomainModal;