import { immerable } from 'immer'
import _ from 'lodash'
import { nanoid } from 'nanoid'

export class Button {
	[immerable] = true

	constructor(button) {
		if (button) {
			return this._createFromExisting(button)
		} else {
			return this._createNew()
		}
	}

	_createFromExisting(button) {
		this._id = button._id
		this.type = button.type
		this.title = button.title
		this.rewards = button.rewards
		this.payload = button.payload
	}

	_createNew() {
		this._id = nanoid()
		this.title = ''
	}

	setType(type) {
		this.type = type
		this.payload = initializePayload(type)
		this.rewards = initializeRewards(type)
		return this
	}

	setTitle(title) {
		this.title = title
		return this
	}

	setButtonProperties(path, value) {
		if (path === 'title') {
			return this.setTitle(value)
		}

		_.set(this, path, value)
		return this
	}

	duplicate() {
		this._id = nanoid()
		return this
	}
}

function initializePayload(buttonType) {
	switch (buttonType) {
		case 'text':
			return {
				next_page: 'next',
				correct: false,
				isHidden: false,
			}
		case 'image':
			return {
				next_page: 'next',
				correct: false,
				image_url: '',
				isHidden: false,
			}
		case 'link':
			return {
				web_url: '',
				auto_redirect: false,
				isHidden: false,
			}
		case 'file_uploader':
			return {
				file_types: ['pdf', 'image', 'video'],
				isHidden: false,
			}
		case 'file_downloader':
			return {
				file: {},
				isHidden: false,
			}
		case 'other_answer':
			return {
				next_page: 'next',
			}
	}
}

function initializeRewards(buttonType) {
	switch (buttonType) {
		case 'text':
		case 'image':
			return {
				enabled: false,
				emoji: '👍',
			}
	}
}
