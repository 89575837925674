import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
const MaterialNotToggle = ({
  className,
  handleOnChange,
  label,
  checked,
  title
}) => <FormControlLabel className={className} title={title} control={<Switch color='primary' checked={!!checked} onChange={e => handleOnChange(e.target.checked)} />} label={label} />;
MaterialNotToggle.displayName = 'MaterialNotToggle';
MaterialNotToggle.propTypes = {
  className: PropTypes.string,
  handleOnChange: PropTypes.func,
  label: PropTypes.string,
  checked: PropTypes.bool,
  title: PropTypes.string
};
export default MaterialNotToggle;