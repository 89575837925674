import { Box, DialogActions } from '@mui/material';
import AmpFormSettings from 'components/AmpFormSettings';
import AttachmentTemplateSelector from 'components/AttachmentTemplateSelector';
import { CreateStoryDetails, CreateStoryInput } from 'components/Modals/StoriesListModals/CreateStoryModal/CreateStoryModal.style';
import PagePreview from 'components/PagePreview/index';
import { FormSettingsWrapper } from 'components/styled/Modals';
import * as translation from 'lib/constants/InteractionTranslation';
import { useStoryDispatch, useStoryState } from 'lib/editorStore';
import { getAttachmentElement } from 'lib/models';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import SubmitButton from 'organisms/buttons/SubmitButton';
import Dropdown from 'organisms/Dropdown';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useImmer } from 'use-immer';
import { ModalWrapper } from '../ModalWrapper';
const THEME_OPTIONS = [{
  text: <FormattedMessage id='common.theme.light' />,
  value: 'light',
  key: 0
}, {
  text: <FormattedMessage id='common.theme.dark' />,
  value: 'dark',
  key: 1
}];
export default function CreateAmpFormModal({
  trigger
}) {
  const intl = useIntl();
  const ref = useRef();
  const dispatch = useStoryDispatch();
  const draftStory = useStoryState(s => s.draftStory);
  const page = useStoryState(s => s.page);
  const [step, setStep] = useState(0);
  const [ampForm, setAmpForm] = useImmer(initAmpForm(draftStory.story_settings.language));
  function handleChangeAmpFormProperty(path, value) {
    setAmpForm(draft => {
      draft[path] = value;
    });
  }
  function resetAmpForm() {
    setAmpForm(() => initAmpForm(draftStory.story_settings.language));
    setStep(0);
  }
  function onPreviousStep() {
    if (step > 0) {
      setStep(step - 1);
    } else {
      setStep(0);
    }
  }

  // Apply template
  function onSelectTemplate(template) {
    if (template) {
      // Clean up template
      const newTemplate = _.cloneDeep(template);
      // Clean up variables
      _.forEach(newTemplate.content, field => {
        if (field?.variable) {
          field.variable = '';
        }
      });
      // Assign variables by default
      _.forEach(newTemplate.content, field => {
        if (field.type === 'input') {
          field.variable = generateVariableName(field.sub_type, draftStory.variables, newTemplate.content);
        }
      });
      // Apply template
      setAmpForm(draft => {
        draft.content = newTemplate.content;
        draft.submit = newTemplate.submit;
        draft.success = newTemplate.success;
        draft.styles = newTemplate.styles;
        draft.theme = newTemplate.theme;
      });
    }
    setStep(2);
  }
  function onSubmitConfig({
    variables,
    newInteraction
  }) {
    dispatch({
      type: 'ADD_AMP_ATTACHMENT',
      data: {
        variables,
        newInteraction
      }
    });
    ref.current.close();
  }
  return <ModalWrapper title={generateModalTile(step)} trigger={trigger} ref={ref}
  // Avoid close modal when editing interacttion
  disableBackdropClick={step === 1} disableEscapeKeyDown={step === 1} hasCloseButton={step !== 1} onClose={resetAmpForm} size='lg'>
			{step === 0 && <>
					<FormSettingsWrapper style={{
        gridTemplateColumns: '500px 1fr'
      }}>
						<Box width='100%' height='250px' display='flex' justifyContent='center' alignItems='end' overflow='hidden'>
							<PagePreview size='extra_large' page={{
            ...page,
            amp_attachments: [ampForm]
          }} theming={draftStory.theming} messages={draftStory.messages} storyType={draftStory.type} />
						</Box>

						<CreateStoryDetails>
							<div className='story-field '>
								<Box mx={2}>
									<FormattedMessage id='edition_menu.amp_form.cta_text.title' />
								</Box>
								<CreateStoryInput value={ampForm?.cta_text} placeholder={intl.messages['form.story.name']} onChange={({
              target: {
                value
              }
            }) => handleChangeAmpFormProperty('cta_text', value)} />
							</div>

							<div className='story-field '>
								<Box mx={2}>
									<FormattedMessage id='edition_menu.amp_interactions.style.theme' />
								</Box>
								<Dropdown variant='standard' disableUnderline fullWidth={false} value={ampForm.theme} options={THEME_OPTIONS} onChange={({
              target: {
                value
              }
            }) => handleChangeAmpFormProperty('theme', value)} />
							</div>
						</CreateStoryDetails>
					</FormSettingsWrapper>
					<DialogActions>
						<SubmitButton variant='contained' color='primary' disabled={_.isEmpty(ampForm.cta_text)} onClick={() => setStep(1)}>
							<FormattedMessage id='button.next' />
						</SubmitButton>
					</DialogActions>
				</>}
			{step === 1 && <AttachmentTemplateSelector onPreviousStep={onPreviousStep} onSelect={onSelectTemplate} attachmentType='amp_form' />}
			{step === 2 && <AmpFormSettings onPreviousStep={onPreviousStep} onSubmitConfig={onSubmitConfig} currentForm={ampForm} currentPage={page} draftStory={draftStory} />}
		</ModalWrapper>;
}
CreateAmpFormModal.propTypes = {
  trigger: PropTypes.object
};

// Generate title of modal via step
function generateModalTile(step) {
  switch (step) {
    case 0:
      return <FormattedMessage id='modals.amp_form.modal.create' />;
    case 1:
      return <FormattedMessage id='modals.amp_form.modal.template' />;
    case 2:
      return <FormattedMessage id='modals.amp_form.modal.settings' />;
  }
}
function initAmpForm(lang) {
  return {
    _id: `amp-${nanoid()}`,
    type: 'amp_form',
    cta_text: translation.amp_attachment_cta_text[lang],
    theme: 'light',
    content: [],
    styles: {
      fontFamily: 'Roboto'
    },
    success: [getAttachmentElement({
      type: 'content',
      sub_type: 'title',
      value: 'All done. Thanks!'
    }), getAttachmentElement({
      type: 'content',
      sub_type: 'paragraph',
      value: `Your answers have been submitted. Thank you for taking the time to fill in the form.`
    }), getAttachmentElement({
      type: 'content',
      sub_type: 'button'
    })],
    submit: {
      title: 'Submit',
      styles: {
        color: 'rgba(255, 255, 255, 1)',
        backgroundColor: 'rgba(82, 112, 232, 1)',
        borderWidth: '1px',
        fontSize: '24px',
        borderStyle: 'solid',
        borderColor: 'rgba(255, 255, 255, 1)',
        borderRadius: '16px'
      }
    }
  };
}

// Helper function
function generateVariableName(sub_type, variables, fields) {
  if (sub_type === 'email') return 'email';
  let variableName = sub_type;
  let isInValid = _.some(variables, {
    name: variableName,
    isUsed: true
  }) || _.some(fields, {
    variable: variableName
  });
  let i = 0;
  while (isInValid) {
    i++;
    variableName = `${sub_type}_${i}`;
    isInValid = _.some(variables, {
      name: variableName,
      isUsed: true
    }) || _.some(fields, {
      variable: variableName
    });
  }
  return variableName;
}