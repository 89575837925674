import * as React from 'react'

function Svg3Number(props) {
	return (
		<svg width='1em' height='1em' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M1.458 2.604h.407c.256-.004.457-.072.604-.203a.675.675 0 00.22-.53c0-.5-.25-.75-.748-.75-.234 0-.421.067-.562.202a.7.7 0 00-.208.53H.629c0-.337.123-.618.369-.84.248-.225.563-.337.943-.337.403 0 .718.106.947.32.228.212.342.508.342.887 0 .185-.06.365-.181.539-.12.174-.282.304-.49.39.235.074.416.197.543.369.128.172.193.381.193.63 0 .382-.125.686-.375.91-.25.225-.575.338-.976.338-.4 0-.726-.109-.978-.326C.716 4.517.59 4.23.59 3.875h.545c0 .225.073.404.22.54.146.134.342.201.588.201.262 0 .462-.068.6-.205.14-.137.209-.333.209-.589 0-.248-.076-.438-.229-.571-.152-.133-.372-.201-.659-.205h-.407v-.442z'
				fill='#000'
			/>
		</svg>
	)
}

export default Svg3Number
