import { Button, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import PropTypes from 'prop-types';
import { ModalWrapper } from './ModalWrapper';
export default function ComfirmModal({
  confirmationModalRef,
  size,
  title,
  onClose,
  onConfirm,
  onCancel,
  firstLine,
  secondLine,
  confirmText,
  cancelText,
  isConfirmDisabled = false
}) {
  return <ModalWrapper size={size} ref={confirmationModalRef} title={title} onClose={onClose}>
			<DialogContent dividers>
				{secondLine ? <>
						<DialogContentText variant='h2' gutterBottom color='primary' style={{
          fontSize: '20px'
        }}>
							{firstLine}
						</DialogContentText>
						<DialogContentText variant='h3' style={{
          fontSize: '16px'
        }}>
							{secondLine}
						</DialogContentText>
					</> : <DialogContentText variant='h3' style={{
        fontSize: '16px'
      }}>
						{firstLine}
					</DialogContentText>}
			</DialogContent>

			<DialogActions>
				<Button variant='text' onClick={() => {
        if (onCancel) {
          onCancel();
        }
        confirmationModalRef.current?.close();
      }}>
					{cancelText}
				</Button>
				{confirmText && <Button variant='outlined' color='secondary' disabled={isConfirmDisabled} onClick={onConfirm}>
						{confirmText}
					</Button>}
			</DialogActions>
		</ModalWrapper>;
}
ComfirmModal.propTypes = {
  confirmationModalRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.any
  })]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isConfirmDisabled: PropTypes.bool,
  firstLine: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondLine: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  confirmText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};