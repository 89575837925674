import { ArrowForwardIos, AttachFile, GetApp, Launch } from '@mui/icons-material';
import cx from 'classnames';
import { PagePreviewStoryButton } from 'components/atoms';
import { StyledConfirmButton } from 'containers/Editor/StoryEditor/style';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { InterfaceContainer } from '../styled';
import { AnswersContainer, ImageAnswerButton, ImageAnswerChoiceLetter, ImageAnswerOption, ImageQuizAnswerChoice, StyledActionButton, StyledTextField, TextAnswerButtonWithResults, TextAnswerChoiceLetter, TextQuizAnswerChoice } from './Answers.style';
function MultipleChoicesInterface({
  page,
  theming,
  messages,
  storyType,
  isRewardsEnabled
}) {
  const hasOtherAnswer = typeof page.answers_properties?.other_answer === 'object';
  const hasImageButtons = _.head(page.answers)?.type === 'image';
  const isQuiz = Boolean(storyType === 'classic' && page.answers_properties?.count_in_results);
  const hasChoiceLetters = page.answers_properties?.has_choice_letters ?? true;
  const visibleAnswers = _.filter(page?.answers, answer => !answer?.payload?.isHidden);
  function renderImageButtons() {
    return _.map(visibleAnswers, (answer, index) => <ImageAnswerButton key={answer._id} style={theming.image_answers} className={cx({
      // Only display correct/incorrect icon on quiz page with results
      selected: isQuiz,
      correct: isQuiz && answer.payload.correct,
      incorrect: isQuiz && !answer.payload.correct
    })}>
				<ImageAnswerOption>
					{answer.payload.image_url && <img src={answer.payload.image_url} />}
					{answer.title.trim() !== '' && <span>{answer.title}</span>}
				</ImageAnswerOption>

				{isQuiz && <ImageQuizAnswerChoice />}
				{!isQuiz && hasChoiceLetters && <ImageAnswerChoiceLetter>{String.fromCharCode(65 + index)}</ImageAnswerChoiceLetter>}
			</ImageAnswerButton>);
  }
  function renderTextButtons() {
    return _.map(visibleAnswers, (answer, index) => <TextAnswerButtonWithResults key={answer._id} style={theming.answers.default}>
				{hasChoiceLetters && !isQuiz && <TextAnswerChoiceLetter>{String.fromCharCode(65 + index)}</TextAnswerChoiceLetter>}
				{isQuiz && <TextQuizAnswerChoice className={cx({
        correct: answer.payload.correct,
        incorrect: !answer.payload.correct
      })} />}
				<span style={{
        gridColumn: 2
      }}>{answer.title}</span>
			</TextAnswerButtonWithResults>);
  }
  function renderActionButtons() {
    return _.map(page.answers, answer => <StyledActionButton id={answer._id} key={answer._id} style={theming.answers.default}>
				{answer.type === 'link' && <Launch fontSize='small' />}
				{answer.type === 'file_uploader' && <AttachFile fontSize='small' />}
				{answer.type === 'file_downloader' && <GetApp fontSize='small' />}
				{answer.title}
			</StyledActionButton>);
  }
  if (page.type !== 'multiple_choice') return null;
  if (page.answers_properties.type === 'action') {
    return <InterfaceContainer style={page.interface_style}>
				<AnswersContainer>
					<div className='answers'>{renderActionButtons()}</div>

					<ConfirmButton page={page} theming={theming} messages={messages} />

					{page.answers_properties.next_button && <PagePreviewStoryButton fullWidth name='next' theming={theming} messages={messages} />}
				</AnswersContainer>
			</InterfaceContainer>;
  }
  return <InterfaceContainer style={page.interface_style}>
			<AnswersContainer $hasImageButtons={hasImageButtons}>
				<div className='answers'>{hasImageButtons ? renderImageButtons() : renderTextButtons()}</div>

				{hasOtherAnswer && <OtherAnswer buttonStyle={theming.answers.default} otherAnswer={page.answers_properties.other_answer} />}

				<ConfirmButton page={page} theming={theming} messages={messages} />

				{isRewardsEnabled && <div id='preview-emoji-reward-container-id' />}
			</AnswersContainer>
		</InterfaceContainer>;
}
MultipleChoicesInterface.propTypes = {
  page: PropTypes.object,
  theming: PropTypes.object,
  messages: PropTypes.object,
  storyType: PropTypes.string,
  isRewardsEnabled: PropTypes.bool
};
export default memo(MultipleChoicesInterface);
function OtherAnswer({
  otherAnswer,
  buttonStyle
}) {
  const style = _.pick(buttonStyle, ['borderRadius', 'fontSize', 'fontFamily', 'fontWeight', 'textDecoration']);
  return <StyledTextField key={otherAnswer._id} style={style} InputProps={{
    readOnly: true
  }} placeholder={otherAnswer.title} />;
}
OtherAnswer.propTypes = {
  otherAnswer: PropTypes.object,
  buttonStyle: PropTypes.object
};
function ConfirmButton({
  page,
  theming,
  messages
}) {
  const confirmButtonText = page?.answers_properties?.confirm_button || messages.buttons.confirm;
  const confirmButtonStyle = theming.confirm_button;
  const hasConfirmButton = containConfirmButton(page);
  const isQuiz = Boolean(page.answers_properties?.count_in_results);
  return hasConfirmButton ? <StyledConfirmButton id='confirm_button' style={confirmButtonStyle}>
			<div>{confirmButtonText}</div>
		</StyledConfirmButton> : isQuiz ? <StyledConfirmButton id='confirm_button' style={confirmButtonStyle}>
			<ArrowForwardIos />
		</StyledConfirmButton> : null;
}
ConfirmButton.propTypes = {
  page: PropTypes.object,
  theming: PropTypes.object,
  messages: PropTypes.object
};

// helper function
function containConfirmButton(page) {
  if (page.answers_properties.type === 'answer') {
    if (page.answers_properties.multiple_selection || typeof page.answers_properties.other_answer === 'object') {
      return true;
    }
  } else if (page.answers_properties.type === 'action') {
    const uploader_buttons = _.filter(page.answers, ['type', 'file_uploader']);
    return page.answers_properties.type === 'action' && uploader_buttons.length > 0;
  }
  return false;
}