import isEqual from 'fast-deep-equal'
import { applyPatches, produce, produceWithPatches } from 'immer'
import { AmpInteractionTypes, ANSWERS_STATES, initGdprConsent, INTERACTION_TYPES } from 'lib/constants'
import { Page } from 'lib/models'
import {
	defaultMessages_DE,
	defaultMessages_EN,
	defaultMessages_ES,
	defaultMessages_FR,
} from 'lib/models/DefaultMessages'
import {
	copyElement,
	createSelectedElementFromArray,
	filterPasteElementsByStoyType,
	isSingleTextBlockSelected,
	isStoryPublishable,
	isTimerDisabled,
	updatePageVariables,
} from 'lib/utils'
import _ from 'lodash'
import { elementsToCopyStorage } from 'services/LocalStorageSvc'

import { EMPTY_ELEMENT, INITIAL_TEXT_EDITION_STATE } from './state'

// Variables for undo/redo feature
let changes = {} // Where immer patches will be stored
let currentVersion = -1 // Version tracking for immer patches
const noOfVersionsSupported = 100
export const patchGeneratingReducer = produceWithPatches(reducer)

export function getDispatcher(set) {
	// Reset currentVersion to make sure we start fresh
	changes = {}
	currentVersion = -1

	return (actions, undoable = true) =>
		set(
			(state) => {
				const [nextState, patches, inversePatches] = patchGeneratingReducer(state, actions, undoable)

				if (undoable && !isEqual(state.draftStory, nextState.draftStory)) {
					currentVersion++

					changes[currentVersion] = {
						redo: patches,
						undo: inversePatches,
					}

					delete changes[currentVersion + 1]
					delete changes[currentVersion - noOfVersionsSupported]
				}

				return nextState
			},
			false, // Merge instead of replace
			createDevToolsAction(actions)
		)
}

function reducer(state, actions, undoable = true) {
	const previousState = _.cloneDeep(state)
	const currentPage = _.find(state.draftStory.pages, ['_id', state.page._id])

	for (const action of _.castArray(actions)) {
		switch (action.type) {
			/* ---- Editor Mechanisms ---- */
			case 'FOCUS_BACKGROUND':
				state.isBackgroundActive = true
				state.selectedElement = EMPTY_ELEMENT
				state.isOnTextEffect = false
				break

			case 'OPEN_ORDER_LAYERS':
				state.isLayersSectionActive = true
				break

			case 'TOGGLE_ORDER_LAYERS':
				state.isLayersSectionActive = !previousState.isLayersSectionActive
				break

			case 'UPDATE_CURRENT_ELEMENT_HOVER':
				state.currentElementHover = action.data
				break

			case 'CLEAN_UP_ELEMENT_HOVER':
				state.currentElementHover = ''
				break

			case 'SUBTITLES_DRAWER_READY':
				state.isSubtitleDrawerReady = true
				break

			case 'OPEN_SUBTITLES_DRAWER':
				state.isDisplaySubtitleDrawer = true
				break

			case 'CLOSE_SUBTITLES_DRAWER':
				state.isDisplaySubtitleDrawer = false
				state.isSubtitleDrawerReady = false
				break

			case 'OPEN_ADVANCED_LOGIC':
				state.isDisplayAdvancedLogic = true
				break

			case 'CLOSE_ADVANCED_LOGIC':
				state.isDisplayAdvancedLogic = false
				break

			case 'OPEN_SINGLE_PAGE_OVERLAY':
				state.isDisplaySinglePageOverlay = true
				break

			case 'CLOSE_SINGLE_PAGE_OVERLAY':
				state.isDisplaySinglePageOverlay = false
				break

			case 'OPEN_PAGE_ANIMATION_DRAWER':
				state.isDisplayPageAnimationDrawer = true
				break

			case 'CLOSE_PAGE_ANIMATION_DRAWER':
				state.isDisplayPageAnimationDrawer = false
				break

			case 'OPEN_SHARE_SETTING_DRAWER':
				state.isDisplayShareDrawer = true
				break

			case 'CLOSE_SHARE_SETTING_DRAWER':
				state.isDisplayShareDrawer = false
				break

			case 'OPEN_ADD_ELEMENT_DRAWER':
				state.isDisplayElementsDrawer = true
				break

			case 'CLOSE_ADD_ELEMENT_DRAWER':
				state.isDisplayElementsDrawer = false
				break

			case 'TOGGLE_DISPLAY_SHARE':
				state.draftStory.toggleSharing()
				break

			case 'SET_SHARE': {
				state.draftStory.setSharing(action)
				break
			}

			case 'SET_CURRENT_VIEW':
				// Unselect element
				state.selectedElement = EMPTY_ELEMENT
				state.isConfirmButtonSettingsDisplayed = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE

				state.view = action.data
				break

			case 'FOCUS_TEXT_BLOCK': {
				if (isSingleTextBlockSelected(state.selectedElement)) {
					state.textEditionState.isTextEditing = true
					state.textEditionState.blockId = state.selectedElement._id
				}
				break
			}

			case 'BLUR_TEXT_BLOCK': {
				if (isSingleTextBlockSelected(state.selectedElement)) {
					state.textEditionState = INITIAL_TEXT_EDITION_STATE
				}
				break
			}

			case 'MARK_STORY_AS_STALE': {
				state.isStale = true
				break
			}

			case 'SET_IS_AUTO_SAVING':
				state.autoSaveStatus = 'saving'
				break

			case 'SET_SNACKEET_AUTO_SAVED': {
				state.isDirty = false
				state.autoSaveStatus = 'saved'
				if (action.data?.updatedAt) {
					state.draftStory.updated_at = action.data?.updatedAt
				}
				break
			}

			case 'UNDO': {
				if (!state.canUndo) break
				return produce(applyPatches(previousState, changes[currentVersion--].undo), (newDraft) => {
					newDraft.canUndo = changes.hasOwnProperty(currentVersion)
					newDraft.canRedo = true
					newDraft.isDirty = true
				})
			}

			case 'REDO': {
				if (!state.canRedo) break
				return produce(applyPatches(previousState, changes[++currentVersion].redo), (newDraft) => {
					newDraft.canUndo = true
					newDraft.canRedo = changes.hasOwnProperty(currentVersion + 1)
					newDraft.isDirty = true
				})
			}

			case 'SET_ANSWERS_THEMING_PATH':
				state.answersState = action.data
				break

			/* ---- Snackeet/Story Config ---- */
			case 'SET_STORY_NAME': {
				state.draftStory.name = action.data
				break
			}

			case 'SET_STORY_TAGS': {
				state.draftStory.tags = action.data
				break
			}

			case 'SET_STORY_LANGUAGE': {
				if (action.data === 'fr') {
					state.draftStory.setStoryLanguage('fr', defaultMessages_FR)
				} else if (action.data === 'es') {
					state.draftStory.setStoryLanguage('es', defaultMessages_ES)
				} else if (action.data === 'de') {
					state.draftStory.setStoryLanguage('de', defaultMessages_DE)
				} else {
					state.draftStory.setStoryLanguage('en', defaultMessages_EN)
				}
				break
			}

			case 'SET_STORY_PUBLISHER': {
				state.draftStory.setAmpStoryPublisher(action.data)
				break
			}

			case 'SET_STORY_SEO_CONFIG': {
				state.draftStory.setAmpStorySeoConfig(action.data)
				break
			}

			case 'SET_BRAND_PRESET': {
				state.draftStory.setStoryBrand(action.data)
				break
			}

			case 'SET_RECENTLY_USED': {
				const { path, value } = action.data
				state.draftStory.updateRecentlyUsed({ path, value })
				break
			}

			case 'PARTIAL_EDIT_THEMING': {
				const { path, value } = action.data
				state.draftStory.updateTheming({ path, value })
				break
			}

			case 'SET_ANSWER_BUTTON_SHAPE': {
				state.draftStory.setAnswerButtonShape(action.data)
				break
			}

			case 'PARTIAL_EDIT_MESSAGES': {
				const { path, value } = action.data
				state.draftStory.updateMessages({ path, value })
				break
			}

			case 'RESET_DEFAULT_ERROR_MESSAGES':
				if (state.draftStory.story_settings.language === 'fr') {
					state.draftStory.messages.error_messages[action.data] =
						defaultMessages_FR.error_messages[action.data]
				} else if (state.draftStory.story_settings.language === 'es') {
					state.draftStory.messages.error_messages[action.data] =
						defaultMessages_ES.error_messages[action.data]
				} else if (state.draftStory.story_settings.language === 'de') {
					state.draftStory.messages.error_messages[action.data] =
						defaultMessages_DE.error_messages[action.data]
				} else {
					state.draftStory.messages.error_messages[action.data] =
						defaultMessages_EN.error_messages[action.data]
				}
				break

			case 'RESET_DEFAULT_THEMING':
				state.draftStory.resetTheming(action.data)
				break

			case 'SET_STORY_METADATA':
				state.draftStory.setMetadata(action.data)
				break

			case 'SET_STORY_CUSTOM_URL':
				state.draftStory.setStoryCustomUrl(action.data)
				break

			case 'SET_STORY_FAVICON':
				state.draftStory.setStoryFavicon(action.data)
				break

			case 'TOGGLE_PROGRESS_BAR':
				state.draftStory.story_settings.disable_progress_bar =
					!previousState.draftStory.story_settings.disable_progress_bar ?? true
				break

			case 'TOGGLE_WHITE_LABEL':
				state.draftStory.story_settings.disable_powered_by =
					!previousState.draftStory.story_settings.disable_powered_by
				break

			case 'TOGGLE_DESKTOP_WALLPAPER': {
				if (!state.draftStory.story_settings.desktop_wallpaper) {
					state.draftStory.story_settings.desktop_wallpaper = {
						enabled: false,
						type: 'image',
						value: '',
					}
				}
				state.draftStory.story_settings.desktop_wallpaper.enabled =
					!state.draftStory.story_settings.desktop_wallpaper.enabled
				break
			}

			case 'SET_DESKTOP_WALLPAPER': {
				if (!state.draftStory.story_settings.desktop_wallpaper) {
					state.draftStory.story_settings.desktop_wallpaper = {
						enabled: false,
						type: 'image',
						value: '',
					}
				}
				state.draftStory.story_settings.desktop_wallpaper = {
					...state.draftStory.story_settings.desktop_wallpaper,
					...action.data,
				}
				break
			}

			case 'TOGGLE_DISPLAY_NAVIGATION_BUTTONS': {
				const story_settings = state.draftStory.story_settings
				const currentValue = story_settings.display_navigation_buttons ?? true
				story_settings.display_navigation_buttons = !currentValue

				// Set tap by default when no arrows and navigation method is currently set to none
				if (!story_settings.display_navigation_buttons && story_settings.navigation_method === 'none') {
					story_settings.navigation_method = 'tap'
				}

				break
			}

			case 'SET_NAVIGATION_METHOD':
				state.draftStory.story_settings.navigation_method = action.payload
				break

			case 'SET_STORY_EXPIRATION_SETTINGS': {
				state.draftStory.story_settings.expiration = _.cloneDeep(action.data)
				break
			}

			/* ---- EDITOR ELEMENTS ---- */
			case 'SET_ON_TEXT_EFFECT':
				state.isOnTextEffect = action.value
				break

			case 'COPY_ELEMENTS': {
				if (!_.isEmpty(state.selectedElement)) {
					state.contextMenu = {}
					const elementsToCopy = getElementsToCopy(state.selectedElement)

					if (!_.isEmpty(elementsToCopy)) {
						state.elementsToCopy = elementsToCopy
						elementsToCopyStorage.set(elementsToCopy)
					}
				}

				break
			}

			case 'CUT_ELEMENTS': {
				if (!_.isEmpty(state.selectedElement)) {
					state.contextMenu = {}

					const elementsToCopy = getElementsToCopy(state.selectedElement)
					if (!_.isEmpty(elementsToCopy)) {
						state.elementsToCopy = elementsToCopy
						elementsToCopyStorage.set(elementsToCopy)

						// Remove elements
						const operations = _.map(_.castArray(state.elementsToCopy), (element) => ({
							_id: element._id,
							element_type: element.type,
						}))
						currentPage.removeBlock(operations)
						currentPage.removeTag(operations)
						currentPage.removeCarousel(operations)
						currentPage.removeButtonList(operations)
						state.selectedElement = EMPTY_ELEMENT

						state.page = currentPage
					}
				}

				break
			}

			case 'PASTE_ELEMENT': {
				// Close context menu
				state.contextMenu = {}
				const elementsToCopy = elementsToCopyStorage.get() || []

				if (_.isEmpty(elementsToCopy)) {
					break
				}

				// Filter all element to copy by story type
				const filteredElementsToCopy = filterPasteElementsByStoyType(elementsToCopy, state.draftStory.type)

				const copiedElements = []
				for (const element of filteredElementsToCopy) {
					const duplicatedElement = copyElement(element)

					const pageSection =
						element.type === 'tag'
							? 'tags'
							: element.type === 'carousel'
							? 'carousels'
							: element.type === 'buttonList'
							? 'buttonLists'
							: 'blocks'

					if (!currentPage[pageSection]) {
						currentPage[pageSection] = []
					}

					currentPage[pageSection].push(duplicatedElement[0])

					copiedElements.push({
						_id: duplicatedElement[0]._id,
						type: ['tag', 'carousel', 'buttonList'].includes(element.type) ? element.type : 'block',
						payload: duplicatedElement[0],
					})
				}

				state.selectedElement = copiedElements
				state.page = currentPage
				state.isBackgroundActive = false
				state.isOnTextEffect = false

				break
			}

			case 'UNSELECT_ELEMENT': {
				state.selectedElement = EMPTY_ELEMENT
				state.isConfirmButtonSettingsDisplayed = false
				state.isOnTextEffect = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE
				state.isBackgroundActive = true

				// Reset all carousel and buttonList Item
				state.currentElementHover = ''
				state.currentCarouselItem = ''
				state.currentButtonListButton = ''
				break
			}

			case 'FOCUS_CURRENT_ELEMENT': {
				state.selectedElement = action.data
				state.isBackgroundActive = false
				state.isOnTextEffect = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE

				// Blur element that has document focus
				document.activeElement?.blur()
				break
			}

			case 'UPDATE_ELEMENTS': {
				const operations = _.castArray(action.data)
				const blockOperations = _.filter(operations, ['element_type', 'block'])
				const tagOperations = _.filter(operations, ['element_type', 'tag'])
				const carouselOperations = _.filter(operations, ['element_type', 'carousel'])
				const buttonListOperations = _.filter(operations, ['element_type', 'buttonList'])
				const timerOperations = _.filter(operations, ['element_type', 'timer'])
				const pageOperations = _.filter(operations, ['element_type', 'interaction_layer'])
				const interactionOperations = _.filter(operations, ['element_type', 'interaction'])
				const answersOperations = _.filter(operations, ['element_type', 'answers_properties'])
				const ampInteractionOperations = _.filter(operations, (operation) =>
					_.includes(AmpInteractionTypes, operation?.element_type)
				)

				currentPage.updateBlock(blockOperations)
				currentPage.updateTag(tagOperations)
				currentPage.updateCarousel(carouselOperations)
				currentPage.updateButtonList(buttonListOperations)
				currentPage.updateTimer(timerOperations)
				currentPage.updateInterfaceLayer(pageOperations)
				currentPage.updateInteraction(interactionOperations)
				currentPage.updateAnswerProperties(answersOperations)
				currentPage.updateAmpInteractions(ampInteractionOperations)

				state.page = currentPage
				break
			}

			case 'DUPLICATE_ELEMENTS': {
				const operations = action.data
					? _.castArray(action.data)
					: _.map(_.castArray(state.selectedElement), (element) => ({
							_id: element._id,
							element_type: element.type,
					  }))
				const blockOperations = _.filter(operations, ['element_type', 'block'])
				const tagOperations = _.filter(operations, ['element_type', 'tag'])
				const carouselOperations = _.filter(operations, ['element_type', 'carousel'])
				const buttonListOperations = _.filter(operations, ['element_type', 'buttonList'])

				const duplicatedBlocks = currentPage.duplicateBlock(blockOperations)
				const duplicatedTags = currentPage.duplicateTag(tagOperations)
				const duplicatedCarousels = currentPage.duplicateCarousel(carouselOperations)
				const duplicatedButtonLists = currentPage.duplicateButtonList(buttonListOperations)

				currentPage.blocks = _.concat(currentPage.blocks, duplicatedBlocks)
				currentPage.tags = _.concat(currentPage.tags, duplicatedTags)
				currentPage.carousels = _.concat(currentPage.carousels || [], duplicatedCarousels)
				currentPage.buttonLists = _.concat(currentPage.buttonLists || [], duplicatedButtonLists)

				state.page = currentPage

				// Update selectedElements
				const selectedBlocks = _.map(duplicatedBlocks, (element) => ({
					_id: element._id,
					type: 'block',
					payload: element,
				}))
				const selectedTags = _.map(duplicatedTags, (element) => ({
					_id: element._id,
					type: 'tag',
					payload: element,
				}))
				const selectedCarousels = _.map(duplicatedCarousels, (element) => ({
					_id: element._id,
					type: 'carousel',
					payload: element,
				}))
				const selectedButtonLists = _.map(duplicatedButtonLists, (element) => ({
					_id: element._id,
					type: 'buttonList',
					payload: element,
				}))
				state.selectedElement = createSelectedElementFromArray([
					...selectedBlocks,
					...selectedTags,
					...selectedCarousels,
					...selectedButtonLists,
				])
				state.contextMenu = {}
				break
			}

			case 'DELETE_ELEMENTS': {
				const operations = _.castArray(action.data)
				const idsToRemove = _.map(operations, '_id')
				const blockOperations = _.filter(operations, ['element_type', 'block'])
				const tagOperations = _.filter(operations, ['element_type', 'tag'])
				const carouselOperations = _.filter(operations, ['element_type', 'carousel'])
				const buttonListOperations = _.filter(operations, ['element_type', 'buttonList'])
				const timerOperations = _.filter(operations, ['element_type', 'timer'])
				const ampInteractionOperations = _.filter(operations, (operation) =>
					_.includes(AmpInteractionTypes, operation.element_type)
				)

				currentPage.removeBlock(blockOperations)
				currentPage.removeTag(tagOperations)
				currentPage.removeCarousel(carouselOperations)
				currentPage.removeButtonList(buttonListOperations)
				currentPage.hideTimer(timerOperations)
				currentPage.removeAmpInteractions(ampInteractionOperations)

				// Update selected elements
				const updatedSelectedElements = _.castArray(state.selectedElement)
				_.remove(updatedSelectedElements, (el) => idsToRemove.includes(el._id))
				if (updatedSelectedElements.length === 0) {
					state.selectedElement = EMPTY_ELEMENT
				} else if (updatedSelectedElements.length === 1) {
					state.selectedElement = updatedSelectedElements
				}

				state.page = currentPage
				state.contextMenu = {}
				state.isBackgroundActive = true
				break
			}

			case 'COPY_TEXT_STYLE': {
				const stylesToCopy = _.pick(action.data, ['outer_style', 'inner_style', 'effect'])

				state.copiedTextStyles = _.omit(stylesToCopy, ['outer_style.transform'])
				state.contextMenu = {}

				break
			}

			case 'PASTE_TEXT_STYLE': {
				currentPage.applyCopiedStyles({
					_id: action.value,
					copiedTextStyles: state.copiedTextStyles,
				})

				state.page = currentPage
				state.contextMenu = {}

				break
			}

			/* ---- Background ---- */
			case 'UPDATE_BACKGROUND_ELEMENT': {
				currentPage.updateBackgroundElement(action.data)
				state.page = currentPage

				const currentBackgroundState = _.cloneDeep(currentPage.background_element)
				if (currentBackgroundState?.type === 'color') {
					state.draftStory.updateRecentlyUsed({
						path: 'background',
						value: currentBackgroundState,
					})
				}
				break
			}

			case 'CHANGE_BACKGROUND_ELEMENT_TYPE': {
				const newBackgroundType = action.data
				const currentBackgroundState = _.cloneDeep(currentPage.background_element)
				state.backgroundStates = {
					...state.backgroundStates,
					[currentBackgroundState.type]: currentBackgroundState,
				}
				currentPage.changeBackgroundElementType({
					newBackgroundType,
					backgroundStates: state.backgroundStates,
				})
				state.page = currentPage

				const NewBackgroundState = _.cloneDeep(currentPage.background_element)
				if (NewBackgroundState?.type === 'color') {
					state.draftStory.updateRecentlyUsed({
						path: 'background',
						value: NewBackgroundState,
					})
				}
				break
			}

			case 'BLOCK_TO_BACKGROUND': {
				const { type, value, file_id, poster, _id, subtitles_props } = action.data
				const currentBackgroundState = _.cloneDeep(currentPage.background_element)
				// Check current type of block & backgound
				// If different then change background type
				if (type !== currentBackgroundState.type) {
					state.backgroundStates = {
						...state.backgroundStates,
						[currentBackgroundState.type]: currentBackgroundState,
					}
					currentPage.changeBackgroundElementType({
						newBackgroundType: type,
						backgroundStates: state.backgroundStates,
					})
				}

				// Update background value
				currentPage.updateBackgroundElement({ path: 'value', value })
				if (poster) {
					currentPage.updateBackgroundElement({
						path: 'poster',
						value: poster,
					})
				}

				if (subtitles_props) {
					currentPage.updateBackgroundElement({
						path: 'subtitles_props',
						value: subtitles_props,
					})
				}

				if (file_id) {
					currentPage.updateBackgroundElement({
						path: 'file_id',
						value: file_id,
					})
				} else {
					currentPage.updateBackgroundElement({
						path: 'file_id',
						value: '',
						isSet: false,
					})
				}

				// Unselect Remove current element
				state.selectedElement = EMPTY_ELEMENT
				currentPage.removeBlock({ _id })

				// Focus on background
				state.isBackgroundActive = true

				state.page = currentPage
				state.contextMenu = {}
				break
			}

			case 'BACKGROUND_TO_BLOCK': {
				const newBlock = currentPage.addBlock(action.data)

				currentPage.changeBackgroundElementType({
					newBackgroundType: 'color',
					backgroundStates: state.backgroundStates,
				})

				state.selectedElement = {
					_id: newBlock._id,
					type: 'block',
					payload: newBlock,
				}
				state.page = currentPage
				state.isBackgroundActive = false
				state.isDisplayElementsDrawer = false
				state.isOnTextEffect = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE

				break
			}

			case 'TOGGLE_BACKGROUND_GRADIENT':
				currentPage.toggleBackgroundGradient()
				state.page = currentPage
				break

			/* ---- AMP Interactions & Attachments ---- */
			case 'ADD_OUTLINK_ATTACHMENT': {
				const newAmpAttachment = currentPage.addOutlinkAttachment(action.data)
				state.selectedElement = {
					_id: newAmpAttachment._id,
					type: newAmpAttachment.type,
					payload: newAmpAttachment,
				}
				state.isBackgroundActive = false
				state.isOnTextEffect = false
				state.isDisplayElementsDrawer = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE
				state.page = currentPage
				break
			}

			case 'ADD_AMP_ATTACHMENT': {
				const { newInteraction, variables } = action.data

				if (variables) {
					state.draftStory.variables = variables
				}

				const newAmpAttachment = currentPage.addAttachment(newInteraction)
				state.selectedElement = {
					_id: newAmpAttachment._id,
					type: newAmpAttachment.type,
					payload: newAmpAttachment,
				}
				state.isBackgroundActive = false
				state.isOnTextEffect = false
				state.isDisplayElementsDrawer = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE
				state.page = currentPage
				break
			}

			case 'UPDATE_ATTACHMENT': {
				const { newInteraction, variables } = action.data

				if (variables) {
					state.draftStory.variables = variables
				}
				const newAmpAttachment = currentPage.updateAttachment(newInteraction)
				state.selectedElement = {
					_id: newAmpAttachment._id,
					type: newAmpAttachment.type,
					payload: newAmpAttachment,
				}
				state.isBackgroundActive = false
				state.isOnTextEffect = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE
				state.page = currentPage
				break
			}

			case 'REMOVE_AMP_ATTACHMENT': {
				const attachment_id = action.data
				currentPage.removeAmpAttachment(attachment_id)
				state.page = currentPage
				if (state.selectedElement._id === attachment_id) {
					state.selectedElement = EMPTY_ELEMENT
				}
				break
			}

			case 'UPDATE_OUTLINK':
				currentPage.updateOutlink(action.data)
				state.page = currentPage
				break

			case 'UPDATE_AMP_FORM':
				currentPage.updateAmpForm(action.data)
				state.page = currentPage
				break

			case 'UPDATE_AMP_ATTACHMENT':
				currentPage.updateAmpAttachment(action.data)
				state.page = currentPage
				break

			case 'ADD_AMP_INTERACTION': {
				const newAmpInteraction = currentPage.addAmpInteraction(action.data)
				state.selectedElement = {
					_id: newAmpInteraction._id,
					type: newAmpInteraction.type,
					payload: newAmpInteraction,
				}
				state.isBackgroundActive = false
				state.isOnTextEffect = false
				state.isDisplayElementsDrawer = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE
				state.page = currentPage
				break
			}

			case 'UPDATE_AMP_INTERACTION': {
				currentPage.updateAmpInteraction(action.data)
				state.page = currentPage
				break
			}

			case 'REMOVE_AMP_INTERACTION': {
				const { _id } = action.data
				currentPage.removeAmpInteraction(_id)
				state.page = currentPage
				if (state.selectedElement._id === _id) {
					state.selectedElement = EMPTY_ELEMENT
				}
				break
			}

			/* ---- Interactions ---- */
			case 'UPDATE_FORM': {
				currentPage.updateFormField(action.data)
				state.page = currentPage
				break
			}

			case 'SET_RATING': {
				// Edit rating interaction
				const newRating = currentPage.setRating(state.selectedElement._id, action.data)
				if (newRating && state.selectedElement.type === 'rating') {
					state.selectedElement = {
						_id: newRating._id,
						type: newRating.type,
						payload: newRating,
					}
				}
				state.page = currentPage
				break
			}

			case 'SET_MEDIA_ANSWER': {
				// Edit Media answer interaction
				const newMediaAnswer = currentPage.setMediaAnswer(state.selectedElement._id, action.data)
				if (newMediaAnswer && state.selectedElement.type === 'media_answer') {
					state.selectedElement = {
						_id: newMediaAnswer._id,
						type: newMediaAnswer.type,
						payload: newMediaAnswer,
					}
				}
				state.page = currentPage
				break
			}

			case 'EDIT_CURRENT_INTERACTION':
				currentPage.setInteraction(action.data)
				state.page = currentPage
				break

			case 'ADD_GAME_PRIZE':
				currentPage.addPrize(action.data)
				state.page = currentPage
				break

			case 'REMOVE_GAME_PRIZE':
				currentPage.removePrize(action.data)
				state.page = currentPage
				break

			case 'UPDATE_GAME_PRIZE':
				currentPage.updatePrize(action.data)
				state.page = currentPage
				break

			case 'UPDATE_GAME_MODAL':
				currentPage.updateGameModal(action.data)
				state.page = currentPage
				break

			/* ---- Wheel component ---- */
			case 'CHANGE_WHEEL_COMPONENT':
				state.wheelComponentState = action.data
				break

			/* ---- Button Answers ---- */
			case 'TOGGLE_MULTIPLE_SELECTION':
				currentPage.toggleMultipleSelection()
				state.page = currentPage
				break

			case 'TOGGLE_LIMIT_ANSWERS':
				currentPage.toggleLimitAnswers()
				state.page = currentPage
				break

			case 'SET_LIMIT_ANSWERS_COUNT':
				currentPage.setLimitAnswersCount(action.data)
				state.page = currentPage
				break

			case 'SET_PAGE_CONFIRM_BUTTON':
				currentPage.setPageConfirmButton(action.data)
				state.page = currentPage
				break

			case 'TOGGLE_PARTIAL_CORRECT':
				currentPage.togglePartialCorrect()
				state.page = currentPage
				break

			case 'TOGGLE_PAGE_COUNT_IN_RESULTS':
				currentPage.toggleCountInResults()
				state.page = currentPage
				break

			case 'TOGGLE_OTHER_ANSWER':
				currentPage.toggleOtherAnswer(action.data)
				state.page = currentPage
				break

			case 'TOGGLE_NEXT_BUTTON':
				currentPage.toggleNextButton()
				state.page = currentPage
				break

			case 'ADD_ANSWER':
				currentPage.addAnswer(action.data)
				state.selectedElement = {
					_id: undefined,
					type: 'answers',
					payload: undefined,
				}
				state.page = currentPage
				break

			case 'DELETE_ANSWER': {
				const { answer_id } = action.data
				currentPage.deleteAnswer(answer_id)
				state.page = currentPage
				break
			}

			case 'UPDATE_ANSWER': {
				const { answer_id, path, value } = action.data
				const currentAnswer = _.find(currentPage.answers, (answer) => answer._id === answer_id)
				const otherAnswer =
					currentPage.answers_properties.other_answer._id === answer_id &&
					currentPage.answers_properties.other_answer

				if (currentAnswer) {
					currentAnswer.setButtonProperties(path, value)
				}
				if (otherAnswer) {
					otherAnswer.setButtonProperties(path, value)
				}

				state.page = currentPage
				break
			}

			case 'REORDER_ANSWERS': {
				currentPage.reorderAnswer(action.data)
				state.page = currentPage
				break
			}

			// Page Rewards
			case 'TOGGLE_REWARDS': {
				currentPage.togglePageRewards()
				state.page = currentPage
				break
			}

			case 'UPDATE_REWARD_EMOJI': {
				const { value } = action.data
				currentPage.updateRewardEmoji(value)
				state.page = currentPage
				break
			}

			// Rounded Button

			case 'TOGGLE_ROUNDED_BUTTON': {
				currentPage.toggleRoundedButton()
				state.page = currentPage
				break
			}

			case 'UPDATE_ROUNDED_BUTTON_STYLE': {
				currentPage.updateRoundedButtonStyle(_.castArray(action.data))
				state.page = currentPage
				break
			}

			case 'SET_MULTIPLE_SELECTION_BUTTON': {
				for (const answer of currentPage.answers) {
					answer
						.setButtonProperties('payload.next_page', action.data)
						.setButtonProperties('rewards.enabled', false)
				}
				state.page = currentPage
				break
			}

			case 'SET_QUIZ_ANSWER_CORRECT_REDIRECT':
				currentPage.setCorrectRedirect(action.data)
				state.page = currentPage
				break

			case 'SET_QUIZ_ANSWER_INCORRECT_REDIRECT':
				currentPage.setIncorrectRedirect(action.data)
				state.page = currentPage
				break

			case 'SET_QUIZ_ANSWER_PARTIAL_REDIRECT':
				currentPage.setPartialRedirect(action.data)
				state.page = currentPage
				break

			/* ---- Blocks ---- */
			case 'ADD_BLOCK': {
				const newBlock = currentPage.addBlock(action.data)
				state.selectedElement = {
					_id: newBlock._id,
					type: 'block',
					payload: newBlock,
				}
				state.page = currentPage
				state.isBackgroundActive = false
				state.isDisplayElementsDrawer = false
				state.isOnTextEffect = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE

				if (action?.data?.subType === 'button') {
					state.currentBlockEditorTab = 'cta'
				}
				break
			}

			case 'ADD_TEXT_FROM_TEMPLATE': {
				// Close context menu
				const elementsToCopy = action?.data?.elements || []

				if (_.isEmpty(elementsToCopy)) {
					break
				}

				const copiedElements = []
				for (const element of elementsToCopy) {
					const duplicatedElement = copyElement(element)

					const pageSection = element.type === 'tag' ? 'tags' : 'blocks'

					currentPage[pageSection].push(duplicatedElement[0])

					copiedElements.push({
						_id: duplicatedElement[0]._id,
						type: element.type === 'tag' ? 'tag' : 'block',
						payload: duplicatedElement[0],
					})
				}

				state.selectedElement = copiedElements
				state.page = currentPage
				state.isBackgroundActive = false
				state.isDisplayElementsDrawer = false
				state.isOnTextEffect = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE

				break
			}

			case 'UPDATE_BLOCK': {
				currentPage.updateBlock(action.data)
				state.page = currentPage
				break
			}

			case 'REORDER_BLOCK':
				currentPage.reorderBlocks(action.data)
				state.page = currentPage
				break

			case 'REORDER_ENDINGS':
				state.draftStory.reorderEndings(action.data)
				break

			case 'SET_CURRENT_BLOCK_EDITOR_TAB':
				state.currentBlockEditorTab = action.data
				break

			case 'SET_CURRENT_TEXT_ACCORDION_EXPAND':
				state.currentTextBlockExpand = action.data
				break

			case 'REORDER_BLOCK_CONTEXT_MENU': {
				currentPage.reorderBlockContextMenu(state.selectedElement, action.data)
				state.contextMenu = {}
				state.page = currentPage
				break
			}

			case 'SET_SOCIAL_NETWORK_BLOCK': {
				currentPage.setSocialNetwork(action.data)
				state.page = currentPage
				const block = _.find(currentPage.blocks, (block) => block._id === state.selectedElement._id)
				state.selectedElement = {
					_id: block._id,
					type: 'block',
					payload: block,
				}
				break
			}

			case 'SET_MESSENGER_BLOCK': {
				currentPage.setMessenger(action.data)
				state.page = currentPage
				const block = _.find(currentPage.blocks, (block) => block._id === state.selectedElement._id)
				state.selectedElement = {
					_id: block._id,
					type: 'block',
					payload: block,
				}
				break
			}

			case 'UPDATE_MESSENGER_BLOCK_PROPERTY': {
				currentPage.updateMessengerProperties(action.data)
				state.page = currentPage
				const block = _.find(currentPage.blocks, (block) => block._id === state.selectedElement._id)
				state.selectedElement = {
					_id: block._id,
					type: 'block',
					payload: block,
				}
				break
			}

			case 'SET_NETWORKS_BLOCK': {
				currentPage.setNetworks(action.data)
				state.page = currentPage
				const block = _.find(currentPage.blocks, (block) => block._id === state.selectedElement._id)
				state.selectedElement = {
					_id: block._id,
					type: 'block',
					payload: block,
				}
				break
			}

			case 'UPDATE_NETWORKS_BLOCK_PROPERTY': {
				currentPage.updateNetworksProperties(action.data)
				state.page = currentPage
				const block = _.find(currentPage.blocks, (block) => block._id === state.selectedElement._id)
				state.selectedElement = {
					_id: block._id,
					type: 'block',
					payload: block,
				}
				break
			}

			case 'REORDER_ELEMENT_BLOCK': {
				currentPage.reorderElementBlock(action.data)
				state.page = currentPage
				break
			}

			/* ---- Tags ---- */
			case 'CREATE_TAG': {
				const newTag = currentPage.createTag()
				state.page = currentPage
				state.selectedElement = {
					_id: newTag._id,
					type: 'tag',
					payload: newTag,
				}
				state.isBackgroundActive = false
				state.isDisplayElementsDrawer = false
				state.isOnTextEffect = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE
				break
			}

			case 'UPDATE_TAG': {
				currentPage.updateTag(action.data)
				state.page = currentPage
				break
			}

			case 'UPDATE_PLACEMENT': {
				const _id = action.data
				currentPage.updateTagPlacement(_id)
				state.page = currentPage
				break
			}

			/* ---- Carousels ---- */
			case 'ADD_CAROUSEL': {
				const newCarousel = currentPage.addCarousel(action.data)
				state.page = currentPage

				state.selectedElement = {
					_id: newCarousel._id,
					type: 'carousel',
					payload: newCarousel,
				}
				state.isBackgroundActive = false
				state.isDisplayElementsDrawer = false
				state.isOnTextEffect = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE
				break
			}
			case 'UPDATE_CAROUSEL': {
				currentPage.updateCarousel(action.data)
				state.page = currentPage
				break
			}
			case 'UPDATE_CAROUSEL_ITEM':
				currentPage.updateCarouselItem(action.data)
				state.page = currentPage
				break
			case 'SET_CAROUSEL_ITEM':
				currentPage.setCarouselItem(action.data)
				state.page = currentPage
				break
			case 'SET_CURRENT_CAROUSEL_ITEM':
				state.currentCarouselItem = action.data
				break

			/* ---- Button List ---- */
			case 'ADD_BUTTON_LIST': {
				const newButtonList = currentPage.addButtonList()
				state.page = currentPage

				state.selectedElement = {
					_id: newButtonList._id,
					type: 'buttonList',
					payload: newButtonList,
				}
				state.isBackgroundActive = false
				state.isDisplayElementsDrawer = false
				state.isOnTextEffect = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE
				break
			}
			case 'UPDATE_BUTTON_LIST': {
				currentPage.updateButtonList(action.data)
				state.page = currentPage
				break
			}
			case 'UPDATE_BUTTON_LIST_BUTTON':
				currentPage.updateButtonListButton(action.data)
				state.page = currentPage
				break

			case 'SET_CURRENT_BUTTON_LIST_BUTTON':
				state.currentButtonListButton = action.data
				break
			/* ---- Timer ---- */

			case 'UPDATE_TIMER': {
				const operations = _.castArray(action.data)
				const styleOperations = _.filter(operations, {
					operation_type: 'style',
				})
				const settingsOperations = _.filter(operations, {
					operation_type: 'setting',
				})

				if (!_.isEmpty(styleOperations)) {
					state.draftStory.updateTimerStyle(styleOperations) // Style changed accross the story
					state.page = _.cloneDeep(_.find(state.draftStory.pages, ['_id', currentPage._id]))
				} else if (!_.isEmpty(settingsOperations)) {
					currentPage.updateTimer(settingsOperations)
					state.page = currentPage
				}

				// Reset selection if timer currently selected but is disabled
				if (state.selectedElement.type === 'timer' && !currentPage.timer.enabled) {
					state.selectedElement = EMPTY_ELEMENT
				}
				break
			}

			case 'TOGGLE_TIMER_VISIBLE':
				currentPage.toggleTimerVisible()
				state.page = currentPage
				break

			/* ---- Variables ---- */
			case 'UPDATE_VARIABLES':
				state.draftStory.variables = action.data
				break

			case 'SET_VARIABLE': {
				currentPage.setVariable(action.data)
				state.page = currentPage
				break
			}

			case 'UNSET_VARIABLE': {
				currentPage.unsetVariable()
				state.page = currentPage
				break
			}

			/* ---- Pages Config ---- */
			case 'SET_FOCUS_PAGE': {
				const { page_id, autoFocusBackground = false } = action.data
				state.view = 'config'
				state.page = _.cloneDeep(_.find(state.draftStory.pages, ['_id', page_id]))

				// Always display background when opening a page
				if (autoFocusBackground) state.isBackgroundActive = true

				state.isOnTextEffect = false
				state.selectedElement = EMPTY_ELEMENT
				state.isConfirmButtonSettingsDisplayed = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE
				state.answersState = ANSWERS_STATES[0]
				state.backgroundStates = {}
				break
			}

			case 'SET_FOCUS_LOGIC': {
				const { page_id, autoFocusBackground = false } = action.data
				const page = _.cloneDeep(_.find(state.draftStory.pages, ['_id', page_id]))
				state.page = page
				state.view = 'config'

				if (
					!_.isEmpty(page?.logics) ||
					(page?.on_enter?.enabled && !_.isEmpty(page?.on_enter?.condition?.rules))
				) {
					state.isDisplayAdvancedLogic = true
				}

				// Always display background when opening a page
				if (autoFocusBackground) state.isBackgroundActive = true

				state.isOnTextEffect = false
				state.selectedElement = EMPTY_ELEMENT
				state.isConfirmButtonSettingsDisplayed = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE
				state.answersState = ANSWERS_STATES[0]
				state.backgroundStates = {}
				break
			}

			case 'CREATE_PAGE': {
				const { insert_position, newPage, variables, display_live_results, gdprConsent } = action.data

				// Reinitialize Page
				const new_page = new Page(newPage)

				if (variables) {
					state.draftStory.variables = variables
				}

				if (gdprConsent) {
					state.draftStory.gdprConsent = gdprConsent
				}

				if (display_live_results !== undefined) {
					state.draftStory.story_settings.display_live_results = display_live_results
				}

				state.draftStory.pages.splice(insert_position, 0, new_page)
				state.page = _.cloneDeep(new_page)

				// Reset selection
				state.selectedElement = EMPTY_ELEMENT
				state.isBackgroundActive = true

				state.isOnTextEffect = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE
				break
			}

			case 'DUPLICATE_PAGE': {
				const { page_id } = action.data

				// Unselect element
				state.selectedElement = EMPTY_ELEMENT
				state.isConfirmButtonSettingsDisplayed = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE

				// Duplicate page
				const duplicatedPage = state.draftStory.duplicatePage(page_id, state.draftStory.variables)
				state.page = _.cloneDeep(duplicatedPage)
				state.draftStory.variables = updatePageVariables(duplicatedPage, state.draftStory.variables)
				state.textEditionState = INITIAL_TEXT_EDITION_STATE
				break
			}

			// Page Disabled
			case 'TOGGLE_PAGE_DISABLED': {
				const { page_id } = action.data
				state.draftStory.togglePageDisable(page_id)
				break
			}

			case 'CHANGE_PAGE_LAYOUT': {
				const { page_id, layout } = action.data

				// Unselect element
				state.selectedElement = EMPTY_ELEMENT
				state.isConfirmButtonSettingsDisplayed = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE

				// Apply new template
				const changedLayoutPage = state.draftStory.changePageLayout(page_id, layout, state.draftStory.variables)
				state.page = _.cloneDeep(changedLayoutPage)
				state.draftStory.variables = updatePageVariables(changedLayoutPage, state.draftStory.variables)
				state.textEditionState = INITIAL_TEXT_EDITION_STATE
				break
			}

			case 'DELETE_PAGE': {
				const { page_id } = action.data
				const previousPageIndex = state.draftStory.deletePage(page_id)
				state.page = _.cloneDeep(state.draftStory.pages[previousPageIndex])
				state.selectedElement = EMPTY_ELEMENT
				state.isBackgroundActive = true
				state.isOnTextEffect = false
				state.isConfirmButtonSettingsDisplayed = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE
				state.answersState = ANSWERS_STATES[0]
				state.backgroundStates = {}
				break
			}

			case 'CLASSIC_TO_SINGLE_PAGE': {
				state.draftStory.classicToSinglePage()
				state.page = _.cloneDeep(state.draftStory.pages[0])
				state.selectedElement = EMPTY_ELEMENT
				state.isBackgroundActive = true
				state.isOnTextEffect = false
				state.isConfirmButtonSettingsDisplayed = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE
				state.answersState = ANSWERS_STATES[0]
				state.backgroundStates = {}
				break
			}

			case 'SINGLE_TO_CLASSIC_PAGE': {
				const { insert_position, newPage, variables, display_live_results, gdprConsent } = action.data

				// Reinitialize Page
				const new_page = new Page(newPage)

				state.draftStory.singlePageToClassic(new_page)

				if (variables) {
					state.draftStory.variables = variables
				}

				if (gdprConsent) {
					state.draftStory.gdprConsent = gdprConsent
				}

				if (display_live_results !== undefined) {
					state.draftStory.story_settings.display_live_results = display_live_results
				}

				state.page = _.cloneDeep(new_page)

				// Reset selection
				state.selectedElement = EMPTY_ELEMENT
				state.isBackgroundActive = true

				state.isOnTextEffect = false
				state.textEditionState = INITIAL_TEXT_EDITION_STATE
				break
			}

			case 'REORDER_PAGES':
				state.draftStory.reorderPages(action.data)
				break

			case 'SET_PAGE_NAME':
				state.draftStory.setPageName(action.data._id, action.data.value)
				break

			case 'SET_PAGE_ANIMATION':
				currentPage.setAnimation(action.data)
				state.page = currentPage
				break

			case 'SET_RESTART_ALLOWED':
				currentPage.setRestartAllowed(action.data)
				state.page = currentPage
				break

			case 'SET_REDIRECTION_BUTTON': {
				currentPage.setRedirectionButton(action.data)
				if (!currentPage.redirection_button?.active && state.selectedElement.type === 'system_buttons') {
					state.selectedElement = EMPTY_ELEMENT
				}
				state.page = currentPage
				break
			}

			case 'DISPLAY_START_BUTTON':
				currentPage.displayStartButton()
				state.page = currentPage
				break

			case 'HIDE_START_BUTTON': {
				currentPage.hideStartButton()
				if (state.selectedElement.type === 'system_buttons') {
					state.selectedElement = EMPTY_ELEMENT
				}
				state.page = currentPage
				break
			}

			case 'TOGGLE_END_PAGE_RESULTS': {
				const storyResults = currentPage.toggleStoryResults()
				state.page = currentPage
				if (storyResults) {
					if (storyResults.visible) {
						state.selectedElement = {
							_id: storyResults._id,
							type: 'story_results',
							payload: storyResults,
						}
						state.isBackgroundActive = false
					} else {
						state.selectedElement = EMPTY_ELEMENT
						state.isBackgroundActive = true
					}
					state.isOnTextEffect = false
					state.textEditionState = INITIAL_TEXT_EDITION_STATE
				}
				break
			}

			case 'ACTIVE_STORY_RESULTS': {
				const storyResults = currentPage.activeStoryResults()
				state.page = currentPage
				if (storyResults) {
					state.selectedElement = {
						_id: storyResults._id,
						type: 'story_results',
						payload: storyResults,
					}
					state.isBackgroundActive = false

					state.isOnTextEffect = false
					state.textEditionState = INITIAL_TEXT_EDITION_STATE
				}
				break
			}

			case 'SET_PAGE_NEXT':
				currentPage.setPageNext(action.data)
				// Special case: Re-enable timer if start page without start button and timer disabled
				if (
					currentPage.type === 'start_page' &&
					!currentPage.display_start_button &&
					!currentPage.timer.enabled
				) {
					currentPage.updateTimer({
						operation_type: 'setting',
						path: 'enabled',
						value: true,
					})
				}

				state.page = currentPage
				break

			case 'SET_PAGE_TYPE': {
				const { newPage, variables, display_live_results, selectedElement, gdprConsent } = action.data

				currentPage._createFromExisting(newPage)

				if (variables) {
					state.draftStory.variables = variables
				}

				if (display_live_results !== undefined) {
					state.draftStory.story_settings.display_live_results = display_live_results
				}

				if (gdprConsent) {
					state.draftStory.gdprConsent = gdprConsent
				}

				state.selectedElement = selectedElement

				// Only open background selection when blank page
				state.isBackgroundActive = currentPage.type === 'blank'
				state.isOnTextEffect = false
				state.page = _.cloneDeep(currentPage)
				break
			}

			case 'ADD_INTERACTION_TO_END_PAGE': {
				const { rating_category, lang } = action.data
				const newInteraction = currentPage.addRatingToEndPage(rating_category, lang)

				state.selectedElement = {
					_id: newInteraction._id,
					type: 'rating',
					payload: newInteraction,
				}

				state.isOnTextEffect = false
				state.isBackgroundActive = false
				state.page = _.cloneDeep(currentPage)
				break
			}

			case 'REMOVE_INTERACTION_FROM_END_PAGE': {
				currentPage.removeRatingFromEndPage()

				state.selectedElement = EMPTY_ELEMENT
				state.isBackgroundActive = true
				state.page = _.cloneDeep(currentPage)
				break
			}

			case 'UPDATE_PAGE_FORM': {
				const { newForm, variables, gdprConsent } = action.data

				currentPage.updateForm(newForm)

				if (variables) {
					state.draftStory.variables = variables
				}

				if (gdprConsent) {
					state.draftStory.gdprConsent = gdprConsent
				}

				state.selectedElement = {
					_id: newForm._id,
					type: 'form',
					payload: newForm,
				}

				state.isBackgroundActive = currentPage.type === 'blank'
				state.isOnTextEffect = false

				state.page = currentPage
				break
			}
			case 'UPDATE_PAGE_WHEEL': {
				const { newInteraction, newModal, variables, gdprConsent } = action.data

				currentPage.updateGameInteraction(newInteraction)
				currentPage.updateModal(newModal)

				if (variables) {
					state.draftStory.variables = variables
				}

				if (gdprConsent) {
					state.draftStory.gdprConsent = gdprConsent
				}

				state.selectedElement = {
					_id: newInteraction._id,
					type: 'game',
					payload: newInteraction,
				}

				state.isBackgroundActive = currentPage.type === 'blank'
				state.isOnTextEffect = false

				state.page = currentPage
				break
			}

			case 'SET_PAGE_BLANK': {
				const { _id, intl } = action.data

				const blocks = _.cloneDeep(currentPage.blocks)

				currentPage.setType({
					type: 'blank',
					blocks,
					currentFontFamily: state.draftStory.theming?.textBlock?.fontFamily,
					lang: state.draftStory.story_settings.language,
				})

				state.selectedElement = EMPTY_ELEMENT

				// Only open background selection when blank page
				state.isBackgroundActive = true
				state.isOnTextEffect = false
				state.page = currentPage

				break
			}

			case 'UPDATE_GDPR': {
				const operations = action.data
				if (operations) {
					state.draftStory.gdprConsent = initGdprConsent(
						state.draftStory.gdprConsent,
						state.draftStory?.story_settings?.language
					)

					for (const operation of _.castArray(operations)) {
						state.draftStory.gdprConsent[operation.path] = operation.value
					}
				}
				break
			}

			case 'UPDATE_PAGE_LOGICS':
				currentPage.setLogics(action.data)
				state.page = currentPage
				break

			case 'TOGGLE_ON_ENTER_CONDITION':
				currentPage.toggleOnEnterCondition(action.data)
				state.page = currentPage
				break

			case 'SET_ON_ENTER_CONDITION':
				currentPage.setOnEnterCondition(action.data)
				currentPage.toggleOnEnterCondition(!_.isEmpty(action.data?.rules))
				state.page = currentPage
				break

			case 'SET_ON_ENTER_REDIRECT':
				currentPage.setOnEnterRedirect(action.data)
				state.page = currentPage
				break

			case 'SET_PAGE_OVERLAY': {
				currentPage.setPageOverlay(action.data)
				state.page = currentPage
				break
			}

			/* ---- Published Story ---- */
			case 'SET_PUBLISHED':
				state.published = _.assign(state.published, state.draftStory)
				state.canPublish = false
				break

			case 'TOGGLE_PUBLISHED_ACTIVE':
				state.published.active = action.payload
				break

			case 'SET_PUBLISHED_STORY_SCHEDULE':
				state.published.story_schedule = action.payload
				break

			case 'ROLLBACK_TO_PUBLISHED':
				state.draftStory = _.cloneDeep(
					_.assign(
						state.draftStory,
						_.omit(state.published, [
							'_id',
							'active',
							'updated_at',
							'updated_by',
							'_landed_count',
							'_unique_users_count',
							'_completed_count',
							'snapshot_url_gif',
							'snapshot_url_png',
						])
					)
				)
				state.canPublish = false
				state.page = _.cloneDeep(_.head(state.draftStory.pages))
				break

			/* ---- Integrations ---- */
			case 'SET_INTEGRATION': {
				const { integrationName, integrationData } = action.data
				state.draftStory.setIntegration(integrationName, integrationData)
				break
			}

			/* ---- Story Settings ---- */
			case 'UPDATE_NOTIFICATION': {
				const operations = _.castArray(action.data)
				state.draftStory.updateNotification(operations)
				break
			}

			case 'UPDATE_STORY_REPLY': {
				state.draftStory.updateReply(action.data)
				break
			}

			case 'TOGGLE_REDIRECT_ON_COMPLETED': {
				state.draftStory.toggleRedirectOnCompleted()
				break
			}

			case 'TOGGLE_REFRESH_USER': {
				state.draftStory.toggleRefreshUser()
				break
			}

			case 'TOGGLE_API_TEMPLATE': {
				state.draftStory.toggleApiTemplate()
				break
			}

			/* --- Context Menu ---- */
			case 'OPEN_CONTEXT_MENU': {
				const { xPos, yPos, origin } = action.data
				state.contextMenu = {
					xPos,
					yPos,
					origin,
				}
				break
			}
			case 'CLOSE_CONTEXT_MENU': {
				state.contextMenu = {}
				break
			}

			case 'TOGGLE_DISPLAY_LIVE_RESULTS':
				state.draftStory.toggleDisplayLiveResults()
				break

			case 'STORY_FEEDBACK.UPDATE_ERRORS': {
				state.storyFeedback.storyErrors = action.data
				break
			}
			case 'STORY_FEEDBACK.UPDATE_RECOMMENDATIONS': {
				state.storyFeedback.storyRecommendations = action.data
				break
			}
			case 'STORY_FEEDBACK.UPDATE_SELECTED_FEEDBACK': {
				state.storyFeedback.selectedFeedback = action.data
				break
			}
			case 'STORY_FEEDBACK.RESET_SELECTED_FEEDBACK': {
				state.storyFeedback.selectedFeedback = {}
				break
			}
			case 'ADD_LEADERBOARD':
				state.draftStory.leaderboard_settings = {
					leaderboard_id: action.data,
					enabled: true,
					unique_key_type: 'email',
				}
				state.published.leaderboard_settings = {
					leaderboard_id: action.data,
					enabled: true,
					unique_key_type: 'email',
				}
				break

			case 'REMOVE_LEADERBOARD':
				state.draftStory.leaderboard_settings = {}
				state.published.leaderboard_settings = {}
				break

			default:
				throw new Error(`invalid action type: ${action.type}`)
		}
	}

	if (isStoryPublishable(state.draftStory, state.published)) {
		state.canPublish = true
	}

	const isTimestampRefreshed = previousState.draftStory.updated_at !== state.draftStory.updated_at
	if (!isEqual(previousState.draftStory, state.draftStory) && !isTimestampRefreshed) {
		if (undoable) {
			state.canUndo = true
			state.canRedo = false
		}
		state.isDirty = true
		state.autoSaveStatus = 'unsaved'
		updateSelectedElement(state)

		// Disable timer if condition met (after type of page change for ex)
		if (state.page?.timer?.enabled && isTimerDisabled(state.page)) {
			state.page.timer.enabled = false
		}
	}
}

function updateSelectedElement(state) {
	if (_.isEmpty(state.selectedElement)) return

	const selectedElement = _.map(_.castArray(state.selectedElement), (element) => ({
		...element,
		payload: getUpdatedElement(element),
	}))

	state.selectedElement = createSelectedElementFromArray(selectedElement)

	function getUpdatedElement(element) {
		if (element.type === 'block') {
			return _.find(state.page.blocks, ['_id', element._id])
		}
		if (element.type === 'tag') {
			return _.find(state.page.tags, ['_id', element._id])
		}
		if (element.type === 'answers') {
			return undefined
		}
		if (element.type === 'system_buttons') {
			return { name: element.payload.name }
		}
		if (INTERACTION_TYPES.includes(element.type)) {
			return _.find(state.page.interactions, ['_id', element._id])
		}
		if (AmpInteractionTypes.includes(element.type)) {
			return _.find(state.page.amp_interactions, ['_id', element._id])
		}
		if (element.type === 'carousel') {
			return _.find(state.page.carousels, ['_id', element._id])
		}
		if (element.type === 'buttonList') {
			return _.find(state.page.buttonLists, ['_id', element._id])
		}
	}
}

function getElementsToCopy(selectedElement) {
	return _.flatMap(_.castArray(selectedElement), (element) => {
		if (
			INTERACTION_TYPES.includes(element.type) ||
			['timer', ...AmpInteractionTypes, 'form', 'outlink', 'amp_form'].includes(element.payload?.type)
		) {
			return []
		}

		return [element.payload]
	})
}

/**
 * Wraps a given action or an array of actions for better display in Redux DevTools.
 *
 * If the input action is an array of 2 or more operations, it creates a BATCH_ACTIONS object that groups the array of actions.
 * This makes it easier to understand the sequence of dispatched actions in the Redux DevTools monitor.
 *
 * If the input action is not an array, it returns the action unchanged.
 *
 * @param {Object|Object[]} actions - A single action object or an array of action objects.
 * @returns {Object} - A wrapped action object for better display in Redux DevTools.
 */
function createDevToolsAction(actions) {
	if (Array.isArray(actions)) {
		return actions.length > 1 ? { type: 'BATCH_ACTIONS', actions } : actions[0]
	}

	return actions
}
