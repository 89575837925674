import styled from '@styled';
import { extractPositionProperties } from 'lib/utils';
import PropTypes from 'prop-types';
import BlockWrapper from './BlockWrapper';
export default function Iframe({
  value,
  outer_style,
  animation
}) {
  const {
    positionProperties,
    visualProperties
  } = extractPositionProperties(outer_style);
  function preventIframeClick(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  if (value) {
    return <BlockWrapper animation={animation} positionProperties={positionProperties}>
				<div style={{
        ...visualProperties,
        overflow: 'hidden'
      }}>
					<IframeContainer dangerouslySetInnerHTML={{
          __html: value
        }} onClick={preventIframeClick} />
				</div>
			</BlockWrapper>;
  }
  return null;
}
Iframe.propTypes = {
  value: PropTypes.string,
  outer_style: PropTypes.object,
  animation: PropTypes.object,
  required: PropTypes.bool
};
const IframeContainer = styled.div`
	width: 100%;
	height: 100%;
	pointer-events: none;

	iframe {
		width: 100% !important;
		height: 100% !important;
		border: none;
	}
`;