import { css } from '@emotion/react';
import { Alert, Button } from '@mui/material';
import styled from '@styled';
import { OrganizationSettingsRightSection } from 'components/styled/Settings';
import { transparentize } from 'polished';
export const SubscriptionButton = styled(Button)`
	width: max-content;
	font-size: auto;
	color: ${({
  $subColor,
  $isActive
}) => $isActive ? $subColor : 'white'};
	background-color: ${({
  $subColor,
  $isActive
}) => $isActive ? 'white' : $subColor};
	border: 1px solid ${({
  $subColor
}) => $subColor};
	&:hover {
		background-color: ${({
  $subColor,
  $isActive
}) => $isActive ? 'white' : $subColor};
	}
`;
export const FlexBanner = styled(Alert)`
	flex-shrink: 0;
	margin-bottom: ${({
  theme
}) => theme.spacing(1)};

	.MuiAlert-icon {
		align-items: center;
		padding: 0;
	}
	.MuiAlert-message {
		display: flex;
		align-items: center;
		padding: 0;
	}
	p {
		margin-right: ${({
  theme
}) => theme.spacing(1.5)};
		font-size: 14px;
	}
	.MuiButton-root {
		text-align: center;
		font-size: 11px;
	}
`;
export const PricingSection = styled(OrganizationSettingsRightSection)``;
PricingSection.defaultProps = {
  ...OrganizationSettingsRightSection.defaultProps,
  flexDirection: 'row',
  p: 2,
  mb: 1,
  minWidth: 800,
  width: '100%'
};
export const PricingColumn = styled.div`
	display: flex;
	flex-direction: column;
	padding: 8px;
	height: max-content;

	&:not(:last-of-type) {
		border-right: 1px solid ${props => props.theme.palette.divider};
	}

	& > span {
		margin-top: 4px;
		padding: 8px;
		min-height: 36px;
		max-height: 36px;
	}

	& > div:first-child {
		height: 130px;
		padding: 8px;
		flex-shrink: 0;
	}

	/* For RedCross and Greencheck */
	svg {
		margin: 0;
		height: 16px;
	}
`;
export const FeaturesList = styled(PricingColumn)`
	flex: 0 0 300px;
`;
const isActiveStyle = ({
  $isActive,
  theme
}) => $isActive && css`
		background-color: ${transparentize(0.9, theme.palette.primary.main)};
		outline: 2px solid ${theme.palette.primary.main};
	`;
export const PlanColumn = styled(PricingColumn)`
	${isActiveStyle}
	flex: 1;
	max-width: 250px;
	align-items: center;

	& > span {
		text-align: center;
	}
`;
export const PlanHeader = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: ${({
  theme
}) => theme.spacing(1, 0)};

	.price {
		/* font-weight: bold; */
		font-size: 16px;
		margin: ${({
  theme
}) => theme.spacing(2, 0)};
	}
`;