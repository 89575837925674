import styled from '@styled';
import { BaseInteraction } from './style';

// Use padding-bottom instead of row-gap in parent container to separate fields to make the area clickable.
// This allows the users to click the area in between the fields to select the form.

export const FieldWrapper = styled.div(({
  theme,
  $fieldStyle
}) => `
		&:not(:last-of-type) {
			padding-bottom: ${theme.spacing(1)};
		}

		.MuiInputBase-root {
			cursor: inherit;
		}

		.MuiInputBase-input,
		.MuiAutocomplete-input,
		.MuiOutlinedInput-input {
			cursor: inherit;
			padding: 0 !important;
			outline: none;
			text-align: ${$fieldStyle?.textAlign};
			font-family: ${$fieldStyle?.fontFamily ?? 'Poppins'};
		}
		.MuiOutlinedInput-root {
			background-color: ${$fieldStyle?.backgroundColor};
			border-color: ${$fieldStyle?.borderColor};
			border-radius: ${$fieldStyle?.borderRadius};
			border-width: ${$fieldStyle?.borderWidth};
			border-style: solid;
			font-size: ${$fieldStyle?.fontSize};
			color: ${$fieldStyle?.color};
			width: ${$fieldStyle?.width};
			font-weight: ${$fieldStyle?.fontWeight};
			text-decoration: ${$fieldStyle?.textDecoratione};
			padding: 12px;
		}
		.MuiOutlinedInput-notchedOutline {
			border: none !important;
		}
		.MuiSelect-select {
			color: #afafaf;
			padding-right: 20px !important;
			font-size: 12px;
		}
		.MuiNativeSelect-select {
			width: max-content;
			color: #AFAFAF;
			padding: 0px !important;
			padding-right: 22px !important;
			font-size: 11px;
			height: 19px;
		}
		.MuiPhoneNumber-positionStart {
			pointer-events: none;
		}
	`);
export const EmptyFormFields = styled(BaseInteraction)`
	color: ${({
  theme
}) => theme.palette.primary.main};
	font-weight: 600;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 22px 15px;
	width: 100%;
	cursor: pointer;
`;
export const ConsentMessage = styled.div`
	pointer-events: auto;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 0;
	font-size: 12px;
	padding: 0 20px;
	cursor: pointer;
	border: ${({
  theme,
  $isSelected
}) => $isSelected ? theme.palette.secondary.main : 'transparent'} 3px solid;
	border-radius: 4px;

	span {
		color: ${props => props.theming.color};
	}
	.MuiCheckbox-colorPrimary {
		color: ${props => props.theming.color};
		padding: 0 4px 0 0;
	}
`;
export const FieldConsent = styled.div`
	width: 100%;
	pointer-events: auto;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 0;
	font-size: ${props => props.theming.fontSize};
	font-family: ${props => props.theming.fontFamily};
	cursor: pointer;
	border: transparent 3px solid;
	border-radius: 4px;

	&:not(:last-of-type) {
		margin-bottom: ${({
  theme
}) => theme.spacing(0.5)};
	}

	span {
		color: ${props => props.theming.color};
	}

	.checkbox-title {
		color: ${props => props.theming.color};
		font-size: inherit;

		a {
			color: ${props => props.theming.color};
			font-size: inherit;
			text-decoration: underline;
			pointer-events: none;
		}
	}

	.MuiCheckbox-colorPrimary {
		color: ${props => props.theming.color};
		padding: 0 4px 0 0;
	}
`;

// Add Padding for form field box-shadow
export const FormInteractionContainer = styled(BaseInteraction)`
	padding-top: 4px;
	padding-bottom: 4px;
	display: flex;
	flex-direction: column;
	height: fit-content;
	width: 100%;

	${FieldWrapper} {
		cursor: ${({
  isSelected
}) => isSelected ? 'inherit' : 'pointer'};
	}
`;