import { Box, Tab, Tabs, Tooltip } from '@mui/material';
import styled from '@styled';
import PropTypes from 'prop-types';
import { snackeet_grey } from 'styles/colors';
export const CustomTabs = styled(Tabs)`
	&.MuiTabs-root {
		border: 1px solid ${snackeet_grey};
		border-right: none;
		border-radius: 4px;
		background-color: white;
		margin-bottom: 0;
		width: ${({
  $width = 'auto'
}) => $width};
		flex-shrink: 0;
	}
	.MuiTabs-indicator {
		background-color: ${({
  theme
}) => theme.palette.secondary.main};
		background-color: ${props => props.$isError && props.theme.palette.error.main};
		background-color: ${props => props.$isWarning && props.theme.palette.secondary.main};
		height: 5px;
	}
`;
CustomTabs.defaultProps = {
  indicatorColor: 'secondary'
};

// const createAriaProps = ({ name }) => ({
// 	id: `tab-${name}`,
// 	'aria-controls': `tabpanel-${name}`,
// })
// const StyledCustomTab = styled(Tab).attrs(createAriaProps)`
const StyledCustomTab = styled(Tab)`
	&.MuiTab-root {
		text-transform: none;
		min-width: 66px;
		flex: 1;
		font-weight: ${({
  theme
}) => theme.typography.fontWeightRegular};
		border-radius: 4px;
		border-right: 1px solid ${snackeet_grey};

		&:hover {
			color: ${props => props.$isError && props.theme.palette.error.main};
			color: ${props => props.$isWarning && props.theme.palette.secondary.main};
			opacity: 1;
		}
	}

	&.Mui-selected {
		color: ${props => props.$isError && props.theme.palette.error.main};
		color: ${props => props.$isWarning && props.theme.palette.secondary.main};
		font-weight: ${({
  theme
}) => theme.typography.fontWeightMedium};

		svg {
			path: {
				fill: ${({
  theme
}) => theme.palette.primary.dark};
				fill: ${props => props.$isError && props.theme.palette.error.main};
				fill: ${props => props.$isWarning && props.theme.palette.secondary.main};
			}
			rect: {
				fill: ${({
  theme
}) => theme.palette.primary.dark};
				fill: ${props => props.$isError && props.theme.palette.error.main};
				fill: ${props => props.$isWarning && props.theme.palette.secondary.main};
			}
		}
	}
`;
export function CustomTab({
  tooltip,
  icon,
  label,
  name,
  ...delegatedProps
}) {
  if (tooltip && (icon || label)) {
    return <StyledCustomTab name={name} {...delegatedProps} label={<Tooltip title={tooltip}>
						<Box width='100%' display='flex' justifyContent='center'>
							{icon || label}
						</Box>
					</Tooltip>}></StyledCustomTab>;
  }
  return <StyledCustomTab icon={icon} label={label} name={name} {...delegatedProps} />;
}
CustomTab.propTypes = {
  tooltip: PropTypes.node,
  icon: PropTypes.node,
  label: PropTypes.node,
  name: PropTypes.string
};
export function CustomTabPanel({
  children,
  isSelected,
  name,
  ...other
}) {
  return <div role='tabpanel' hidden={!isSelected} id={`tabpanel-${name}`} aria-labelledby={`tab-${name}`} {...other}>
			{isSelected && <>{children}</>}
		</div>;
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  isSelected: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};