import { Box, Button, DialogContent, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as authSvc from 'services/AuthSvc';
import { useImmer } from 'use-immer';
import { ModalWrapper } from './ModalWrapper';
export default function VerifyEmailModal({
  verifyEmailRef,
  email,
  customer_id,
  hasCloseButton = true,
  disableBackdropClick = false,
  disableEscapeKeyDown = false
}) {
  const intl = useIntl();
  const {
    enqueueSnackbar
  } = useSnackbar();
  const [states, setStates] = useImmer({
    email: '',
    isLoading: false,
    counter: 0
  });

  // Timer to countdown Resend Button
  useEffect(() => {
    const timer = states.counter > 0 && setInterval(() => setStates(draft => {
      draft.counter = draft.counter - 1;
    }), 1000);
    return () => clearInterval(timer);
  }, [states.counter]);
  async function onResend() {
    try {
      await authSvc.resendVerificationEmail({
        email,
        customer_id
      });
      enqueueSnackbar(intl.messages['verification_email.success'], {
        variant: 'success',
        autoHide: 10000
      });

      // Reset counter for 2 minutes
      setStates(draft => {
        draft.counter = 120;
      });
    } catch (error) {
      enqueueSnackbar(intl.messages['verification_email.error'], {
        variant: 'error'
      });
    }
  }
  return <ModalWrapper size='md' ref={verifyEmailRef} title={intl.messages['modals.verification_email.title']} hasCloseButton={hasCloseButton} disableBackdropClick={disableBackdropClick} disableEscapeKeyDown={disableEscapeKeyDown}>
			<DialogContent>
				<Typography gutterBottom variant='h3' style={{
        fontSize: '16px',
        marginBottom: '16px'
      }}>
					{intl.messages['modals.verification_email.header_1']}
				</Typography>

				<Typography variant='h3' style={{
        fontSize: '16px',
        marginBottom: '16px'
      }}>
					<FormattedMessage id='modals.verification_email.header_2' values={{
          email
        }} />
				</Typography>

				{disableBackdropClick && <Typography variant='h3' style={{
        fontSize: '16px'
      }}>
						<FormattedMessage id='modals.verification_email.header_3' />
					</Typography>}

				<Box textAlign='center' mt={4}>
					{states.counter > 0 && <Typography variant='body2'>
							<FormattedMessage id='modals.verification_email.delay_message' values={{
            counter: states.counter
          }} />
						</Typography>}

					<Button variant='contained' color='primary' disabled={states.counter > 0} onClick={() => onResend()} style={{
          margin: '8px auto 16px'
        }}>
						{intl.messages['modals.verification_email.resend_button']}
					</Button>

					<Typography gutterBottom variant='h3' style={{
          fontSize: '16px'
        }}>
						<FormattedMessage id='modals.verification_email.instruction' />
					</Typography>
				</Box>
			</DialogContent>
		</ModalWrapper>;
}
VerifyEmailModal.propTypes = {
  verifyEmailRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.any
  })]),
  email: PropTypes.string,
  customer_id: PropTypes.string,
  hasCloseButton: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool
};