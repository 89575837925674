import { ArrowBack, Instagram } from '@mui/icons-material';
import { Alert, AlertTitle, Box, Button, CircularProgress, Link, Typography } from '@mui/material';
import { Figure } from 'components/styled';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getInstagramAccounts } from 'redux/organization/selectors';
import AccountItem from './AccountItem';
import useFbLogin, { permissions } from './hooks/useFbLogin';
import { AccountsList, ViewHeader } from './InstagramAssets.styles';
export default function AccountsView({
  selectedAccount,
  onAccountSelect,
  onAccountDelete,
  goToView
}) {
  const instagramAccounts = useSelector(getInstagramAccounts);
  const {
    isLoading,
    isLoggedIn,
    isAuthorized,
    isEachScopeAuthorized,
    isNotSupport,
    login
  } = useFbLogin();
  if (isNotSupport) {
    return <Box width='100%' height='300px' p={4} display='flex' alignItems='center' justifyContent='center' textAlign='center'>
				<Typography variant='h2'>
					<FormattedMessage id='integrations.instagram.accounts.error' />
				</Typography>
			</Box>;
  }
  return <>
			<ViewHeader>
				{!_.isEmpty(instagramAccounts) && selectedAccount && <Button onClick={goToView('media')} startIcon={<ArrowBack />}>
						<FormattedMessage id='integrations.instagram.accounts.button.back' />
					</Button>}
				<Box margin='auto' gridArea='title'>
					<Typography variant='h1'>
						<FormattedMessage id='integrations.instagram.accounts.title' />
					</Typography>
				</Box>
			</ViewHeader>

			<IntegrationAlert isEachScopeAuthorized={isEachScopeAuthorized} />

			{_.isEmpty(instagramAccounts) ? <Figure>
					<img src='/static/placeholders/login.svg' />
					<figcaption>
						<FormattedMessage id='integrations.instagram.accounts.empty' />
					</figcaption>
				</Figure> : <AccountsList>
					{_.map(instagramAccounts, account => <AccountItem key={account._id} expiration account={account} onSelect={onAccountSelect(account)} onDelete={onAccountDelete(account)} isSelected={selectedAccount?.pageId === account.pageId} />)}
				</AccountsList>}
			<Box display='flex' alignItems='center' my={2}>
				<Button color='primary' variant='contained' onClick={login} startIcon={!isLoading && <Instagram />}>
					{isLoading && <Box mr={1}>
							<CircularProgress color='primary' size={15} />
						</Box>}
					{isLoggedIn && isAuthorized ? <FormattedMessage id='integrations.instagram.accounts.button.manage' /> : <FormattedMessage id='integrations.instagram.accounts.button.login' />}
				</Button>
			</Box>
		</>;
}
AccountsView.propTypes = {
  selectedAccount: PropTypes.object,
  onAccountSelect: PropTypes.func,
  onAccountDelete: PropTypes.func,
  goToView: PropTypes.func
};
function IntegrationAlert({
  isEachScopeAuthorized
}) {
  if (!isEachScopeAuthorized) {
    return <Box my={2}>
				<Alert severity='error'>
					<AlertTitle>
						<FormattedMessage id='integrations.instagram.accounts.alert.error.title' />
					</AlertTitle>
					<Box whiteSpace='pre-wrap' mb={0.5}>
						<FormattedMessage id='integrations.instagram.accounts.alert.error.description' />
						<ul>
							{_.map(permissions, permission => <a key={permission} href={`https://developers.facebook.com/docs/permissions/reference/${permission}`} target='_blank' rel='noreferrer'>
									<li>{permission}</li>
								</a>)}
						</ul>
					</Box>
				</Alert>
			</Box>;
  }
  return <Box my={2}>
			<Alert severity='warning'>
				<AlertTitle>
					<FormattedMessage id='integrations.instagram.accounts.alert.warning.title' />
				</AlertTitle>
				<Box whiteSpace='pre-wrap' mb={0.5}>
					<FormattedMessage id='integrations.instagram.accounts.alert.warning.description' />
				</Box>
				<Link href='https://help.instagram.com/570895513091465?helpref=related' target='_blank' underline='hover'>
					<FormattedMessage id='integrations.instagram.accounts.alert.warning.link' />
				</Link>
			</Alert>
		</Box>;
}