import { ButtonBase } from '@mui/material';
import styled from '@styled';
export const ButtonListContainer = styled.div`
	position: absolute;
	pointer-events: auto;

	width: 100%;
	padding-top: 8px;
	overflow: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	&:hover {
		outline: ${({
  theme
}) => `1px solid ${theme.palette.primary.main}`};
	}
`;
export const ButtonsWrapper = styled.div`
	width: 100%;
	overflow: auto;
	pointer-events: auto;
	cursor: inherit;
	display: flex;
	flex-direction: column;
	align-items: ${({
  $isStretch
}) => $isStretch ? 'stretch' : 'flex-start'};
	justify-content: ${({
  $isCenter
}) => $isCenter ? 'center' : 'flex-start'};

	gap: 12px;
	max-height: 100%;
	margin: 0;
	padding: ${({
  theme
}) => theme.spacing(1, 2)};

	::-webkit-scrollbar {
		width: 3px;
	}

	::-webkit-scrollbar-track {
		background: rgba(70, 70, 70, 0.3);
	}

	::-webkit-scrollbar-thumb {
		background-color: rgb(70, 70, 70);
	}

	mask-image: linear-gradient(0deg, transparent 0%, black 3%, black 97%, transparent 100%);
`;
export const CTAButton = styled(ButtonBase)`
	pointer-events: none;
	overflow: hidden;
	flex-shrink: 0;

	&.MuiButtonBase-root {
		box-sizing: border-box;
		position: relative;
		width: 100%;
		min-height: 38px;
		font-size: inherit;
		font-family: inherit;
		padding: ${({
  theme
}) => theme.spacing(1, 1)};
		border-radius: 60px;
		display: block;
		align-items: center;
		text-transform: initial;
		line-height: 1.5;
		word-break: break-word;
		white-space: break-spaces;
		text-align: inherit;
	}
`;
export const ImageCTAButton = styled(CTAButton)`
	&.MuiButtonBase-root {
		display: grid;
		grid-template-columns: 30px 1fr;
		gap: 8px;

		img {
			/* border-radius: 50%; */
			object-fit: cover;
		}
	}
`;
export const MaskImage = styled.div`
	width: 28px;
	height: 28px;
	background-color: ${({
  $color
}) => $color || '#000'};
	mask-image: url(${({
  $maskImageUrl
}) => $maskImageUrl});
	mask-size: 100% 100%;
`;