import axios from 'axios'

const pixabayKey = '25917528-e029a747fa2ec13493c5dac42'

const pixabayAxios = axios.create({
	baseURL: `https://pixabay.com/api`,
})

export default class PixabayApi {
	constructor(type, app_locale, is_background) {
		this.locale = app_locale
		this.type = type
		if (type === 'photos') {
			this.orientation = is_background ? 'vertical' : 'all'
		}
		this.searchEndpoint = type === 'photos' ? '/' : '/videos'
	}

	search({ query, per_page, page = 1 }) {
		return pixabayAxios.get(this.searchEndpoint, {
			params: {
				key: pixabayKey,
				q: query,
				per_page,
				page,
				lang: this.locale,
				safesearch: true,
				orientation: this.orientation,
			},
		})
	}

	getDefault({ per_page, page }) {
		return pixabayAxios.get(this.searchEndpoint, {
			params: {
				key: pixabayKey,
				q: '',
				per_page,
				page,
				lang: this.locale,
				safesearch: true,
				orientation: this.orientation,
			},
		})
	}
}
