import { DialogActions, DialogContent, DialogContentText, TextField, Typography } from '@mui/material';
import { ModalWrapper } from 'components/Modals';
import SubmitButton from 'organisms/buttons/SubmitButton';
import PropTypes from 'prop-types';
import { forwardRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
const DeleteStoryModal = forwardRef(function DeleteStoryModal({
  draftStory,
  publishedStory,
  deleteStory,
  onClose
}, deleteModalRef) {
  const intl = useIntl();
  const [input, setInput] = useState('');
  function handleEnterKey(evt) {
    if ((evt.key === 'Enter' || evt.keyCode === 13) && input === draftStory.name) {
      onDelete(evt);
    }
  }
  async function onDelete(evt) {
    await deleteStory(evt);
    setInput('');
    deleteModalRef.current?.close();
  }
  function handleChange({
    target: {
      value
    }
  }) {
    setInput(value);
  }
  return <ModalWrapper ref={deleteModalRef} size='xs' title={intl.messages['modal.snackeet_deletion.header']} onClose={onClose}>
			<DialogContent dividers style={{
      height: '220px'
    }}>
				<Typography variant='h2' gutterBottom color='primary'>
					<FormattedMessage id='modal.snackeet_deletion.confirmation.header' />
				</Typography>

				<DialogContentText component='div'>
					<FormattedMessage id='modal.snackeet_deletion.confirmation.instructions' />
				</DialogContentText>
				<DialogContentText color='primary'>
					<b>{draftStory?.name}</b>
				</DialogContentText>

				<TextField sx={{
        mt: 1
      }} size='small' variant='outlined' fullWidth={true} label={intl.messages['modal.snackeet_deletion.name']} placeholder={draftStory?.name} value={input} onChange={handleChange} error={input !== '' && input !== draftStory?.name} onKeyUp={handleEnterKey} />
			</DialogContent>

			<DialogActions>
				<SubmitButton disabled={input !== draftStory?.name} onClick={onDelete}>
					<FormattedMessage id='button.confirm' />
				</SubmitButton>
			</DialogActions>
		</ModalWrapper>;
});
DeleteStoryModal.propTypes = {
  draftStory: PropTypes.object,
  publishedStory: PropTypes.object,
  deleteStory: PropTypes.func,
  onClose: PropTypes.func
};
export default DeleteStoryModal;