import PropTypes from 'prop-types';
import { VideoBlockContainer } from '../styled';
export default function BlockWrapper({
  children,
  positionProperties,
  videoProperties
}) {
  if (videoProperties) {
    return <VideoBlockContainer style={positionProperties} inner_style={videoProperties}>
				{children}
			</VideoBlockContainer>;
  }
  return <div style={positionProperties}>{children}</div>;
}
BlockWrapper.propTypes = {
  animation: PropTypes.object,
  children: PropTypes.node,
  positionProperties: PropTypes.object,
  videoProperties: PropTypes.object
};