import { InsertDriveFileOutlined, PersonOutline } from '@mui/icons-material';
import { Box, Button, SvgIcon, TextField, Typography } from '@mui/material';
import { IOSSwitch } from 'components/atoms';
import { ModalContent, WebhookTypeBox } from 'components/styled/Modals';
import { isUrl } from 'lib/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModalWrapper } from './ModalWrapper';
const event_options = ['snackeet_interaction', 'file_uploaded'];
const icon_list = {
  snackeet_interaction: <PersonOutline />,
  file_uploaded: <InsertDriveFileOutlined />
};
const WebhookModal = forwardRef(({
  currentWebhook,
  step = 0,
  setStep,
  isCreateNew,
  handleFieldChange,
  onSaveWebhook,
  onClose,
  onCancel
}, ref) => {
  const intl = useIntl();
  return <ModalWrapper size='sm' ref={ref} title={<FormattedMessage id='modals.webhook.title' />} onClose={onClose}>
				<ModalContent>
					{step === 0 && <>
							<Box width='100%' display='grid' gridTemplateColumns='1fr 1fr' gap='8px' mb={3}>
								{_.map(event_options, option => <WebhookTypeBox key={option} $isSelected={currentWebhook.eventType === option} onClick={() => handleFieldChange('eventType', option)}>
										<SvgIcon>{icon_list[option]}</SvgIcon>
										<Typography variant='h2' className='title'>
											{intl.messages[`modals.webhook.eventType.${option}`]}
										</Typography>
										<Typography variant='body1' className='title'>
											<FormattedMessage id={`modals.webhook.eventType.${option}.description`} />
										</Typography>
									</WebhookTypeBox>)}
							</Box>
							<Box display='flex' alignItems='center' justifyContent='flex-end'>
								<Button variant='contained' style={{
            marginRight: '8px'
          }} onClick={onCancel}>
									<FormattedMessage id='button.cancel' />
								</Button>
								<Button variant='contained' color='secondary' onClick={() => setStep(1)}>
									<FormattedMessage id='button.next' />
								</Button>
							</Box>
						</>}

					{step === 1 && <>
							<Typography variant='h2' style={{
          marginBottom: '10px'
        }}>
								{intl.messages[`modals.webhook.eventType.${currentWebhook?.eventType}`]}
							</Typography>
							<Box mb={2}>
								<Typography variant='h4'>
									<FormattedMessage id='modals.webhook.url_header' />
								</Typography>
								<TextField fullWidth multiline placeholder={intl.messages['modals.webhook.url_placeholder']} variant='outlined' value={currentWebhook.url} error={!isUrl(currentWebhook.url).success} onChange={({
            target: {
              value
            }
          }) => handleFieldChange('url', value)} />

								{_.isEmpty(currentWebhook.url) && <Typography variant='body2' color='error'>
										{intl.messages['modals.webhook.url_error.empty']}
									</Typography>}
								{!_.isEmpty(currentWebhook.url) && !isUrl(currentWebhook.url).success && <Typography variant='body2' color='error'>
										{intl.messages['modals.webhook.url_error.invalid']}
									</Typography>}
							</Box>

							{currentWebhook.eventType === 'snackeet_interaction' && <Box mb={2} display='flex' justifyContent='space-between' alignItems='center'>
									<Typography variant='h4'>
										{intl.messages['integratins.webhook.hotspot.completed_only.title']}
									</Typography>

									<IOSSwitch checked={!!currentWebhook?.completedOnly} color='primary' onChange={({
            target: {
              checked
            }
          }) => handleFieldChange('completedOnly', checked)} />
								</Box>}

							<Box mb={2}>
								<Typography variant='h4'>{intl.messages['modals.webhook.headers_title']}</Typography>
								<Typography variant='body2' className='headers-title'>
									{intl.messages['modals.webhook.headers_name']}
								</Typography>
								<TextField fullWidth multiline placeholder={intl.messages['common.name']} variant='outlined' value={currentWebhook.headers.name} onChange={({
            target: {
              value
            }
          }) => handleFieldChange('headers.name', value)} />
								<Typography variant='body2' className='headers-title'>
									{intl.messages['modals.webhook.headers_value']}
								</Typography>
								<TextField fullWidth multiline placeholder={intl.messages['common.value']} variant='outlined' value={currentWebhook.headers.value} onChange={({
            target: {
              value
            }
          }) => handleFieldChange('headers.value', value)} />
							</Box>

							<Box display='flex' alignItems='center' justifyContent='flex-end'>
								{isCreateNew ? <Button variant='contained' style={{
            marginRight: '8px'
          }} onClick={() => setStep(0)}>
										<FormattedMessage id='button.previous' />
									</Button> : <Button variant='contained' style={{
            marginRight: '8px'
          }} onClick={onCancel}>
										<FormattedMessage id='button.cancel' />
									</Button>}
								<Button variant='contained' color='secondary' disabled={!isUrl(currentWebhook.url).success} onClick={() => onSaveWebhook(currentWebhook)}>
									<FormattedMessage id='button.save' />
								</Button>
							</Box>
						</>}
				</ModalContent>
			</ModalWrapper>;
});
WebhookModal.propTypes = {
  currentWebhook: PropTypes.object,
  step: PropTypes.number,
  setStep: PropTypes.func,
  isCreateNew: PropTypes.bool,
  handleFieldChange: PropTypes.func,
  onSaveWebhook: PropTypes.func,
  onClose: PropTypes.func,
  onCancel: PropTypes.func
};
WebhookModal.displayName = 'WebhookModal';
export default WebhookModal;