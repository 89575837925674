import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Button, CardContent, CardMedia, Chip, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import cx from 'classnames';
import { ModalWrapper } from 'components/Modals/ModalWrapper';
import { StickyCheck } from 'components/styled';
import { BrandWrapper } from 'components/styled/Settings';
import BrandCard from 'containers/BrandsList/BrandCard';
import { SNACKEET_STORY_FEATURES, STORIES_COLORS } from 'lib/constants';
import { EventEmitter } from 'lib/EventEmitter';
import useSubscriptionFeatures from 'lib/hooks/useSubscriptionFeatures';
import _ from 'lodash';
import Dropdown from 'organisms/Dropdown';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getAppLocale } from 'redux/customer/selectors';
import { getCurrentOrganizationId } from 'redux/organization/selectors';
import { getCurrentProjectId } from 'redux/project/selectors';
import BrandsSvc from 'services/BrandsSvc';
import { useImmer } from 'use-immer';
import { BrandsContainer, CreateStoryContent, CreateStoryDetails, CreateStoryInput, CreateStoryModalFooterContainer, NoBrandPlaceholder, StoryCard, StoryCardsContainer, StoryGroup } from './CreateStoryModal.style';
import ImportFromInstagramModal from './ImportFromInstagramModal';
import StoryTemplateSelection from './StoryTemplateSelection';
const languageOptions = [{
  key: 'fr',
  value: 'fr',
  text: <FormattedMessage id='common.language.french' />
}, {
  key: 'en',
  value: 'en',
  text: <FormattedMessage id='common.language.english' />
}, {
  key: 'es',
  value: 'es',
  text: <FormattedMessage id='common.language.spanish' />
}, {
  key: 'de',
  value: 'de',
  text: <FormattedMessage id='common.language.german' />
}];
export default function CreateStoryModal({
  trigger
}) {
  const intl = useIntl();
  const organizationId = useSelector(getCurrentOrganizationId);
  const projectId = useSelector(getCurrentProjectId);
  const appLocale = useSelector(getAppLocale);
  const {
    hasAmp
  } = useSubscriptionFeatures();
  const [state, setState] = useImmer({
    step: 0,
    type: 'classic',
    name: '',
    language: appLocale,
    brandId: '',
    createType: 'scratch',
    templateId: ''
  });
  const setStep = useCallback(value => setState(draft => {
    draft.step = value;
  }), [setState]);
  const setType = useCallback(value => setState(draft => {
    draft.type = value;
  }), [setState]);
  const setName = useCallback(value => setState(draft => {
    draft.name = value;
  }), [setState]);
  const setLanguage = useCallback(value => setState(draft => {
    draft.language = value;
  }), [setState]);
  const instagramModalRef = useRef();
  const createStoryModalRef = useRef();
  const {
    data: brands
  } = useQuery({
    queryKey: ['brands', {
      organization_id: organizationId,
      project: projectId
    }],
    queryFn: ({
      queryKey
    }) => {
      const {
        project,
        organization_id
      } = queryKey[1];
      return BrandsSvc.getAll({
        organization_id,
        project
      });
    }
  });

  // Handle change story type
  const selectStoryType = storyType => () => {
    // Ignore if AMP stories are disabled
    if (storyType === 'amp' && !hasAmp) {
      return;
    }
    if (state.type === storyType) {
      setStep(1);
    } else {
      setType(storyType);
    }
  };

  // Handle choose Brand
  function handleChangeBrand(brand_id) {
    setState(draft => {
      draft.brandId = brand_id;
    });
  }

  // Handle change Story name
  function handleName({
    target: {
      value
    }
  }) {
    if (value.length < 26) {
      setName(value);
    }
  }

  // Handle change story language
  function handleLanguage({
    target: {
      value
    }
  }) {
    setLanguage(value);
  }
  function resetStates() {
    setState(draft => {
      draft.step = 0;
      draft.type = 'classic';
      draft.name = '';
      draft.language = appLocale;
      draft.brandId = '';
      draft.createType = 'scratch';
      draft.templateId = '';
    });
  }
  function onAssetSelect(assets) {
    EventEmitter.dispatch('CREATE_FROM_INSTAGRAM', {
      type: state.type,
      name: state.name.trim(),
      assets,
      language: state.language,
      organization_id: organizationId,
      brandId: state.brandId
    });
    instagramModalRef.current?.close();
    createStoryModalRef.current?.close();
    resetStates();
  }
  const onStoryStarterSelect = ({
    templateId,
    createType = 'scratch'
  } = {}) => {
    const payload = {
      type: state.type,
      name: state.name.trim(),
      language: state.language,
      brandId: state.brandId,
      organization_id: organizationId
    };
    switch (createType) {
      case 'defaultTemplate':
        EventEmitter.dispatch('CREATE_FROM_DEFAULT_TEMPLATE', {
          ...payload,
          templateId
        });
        break;
      case 'myTemplate':
        EventEmitter.dispatch('CREATE_FROM_MY_TEMPLATE', {
          ...payload,
          templateId
        });
        break;
      case 'scratch':
        EventEmitter.dispatch('CREATE_FROM_SCRATCH', {
          ...payload,
          language: state.language
        });
        break;
    }
    createStoryModalRef.current?.close();
    resetStates();
  };
  function getCurrentTitle() {
    if (state.step === 0) {
      return <FormattedMessage id='modals.create_snackeet.steps.story_type' />;
    }
    if (state.step === 1) {
      return <FormattedMessage id='modals.create_snackeet.steps.story_details' />;
    }
    return <FormattedMessage id='modals.create_snackeet.steps.from_template' />;
  }
  const inputRef = useHotkeys('enter, return', () => {
    if (state.type && state.name) {
      setStep(2);
    }
  }, {
    preventDefault: true,
    enableOnFormTags: true
  }, [state.type, state.name]);

  // Automatic input focus
  useEffect(() => {
    if (state.step === 1) {
      inputRef.current.focus();
    }
  }, [state.step, inputRef]);
  return <>
			<ImportFromInstagramModal ref={instagramModalRef} storyType={state.type} onAssetSelect={onAssetSelect} />

			<ModalWrapper title={<Box fontWeight='500'>{getCurrentTitle()}</Box>} trigger={trigger} ref={createStoryModalRef} fullScreen onClose={resetStates}>
				{state.step === 0 && <StoryCardsContainer>
						<StoryGroup $storyColor={STORIES_COLORS.classic} className={cx({
          selected: state.type === 'classic'
        })} onClick={selectStoryType('classic')}>
							{state.type === 'classic' && <StickyCheck top={30} right={30} />}

							<div>
								<h1>
									<FormattedMessage id='modals.create_snackeet.snackeet_story.title' />
								</h1>
								<h2>
									<FormattedMessage id='modals.create_snackeet.snackeet_story.description' />
								</h2>
							</div>
							<Box width='100%' display='flex' justifyContent='center' flexWrap='wrap' gap={1}>
								{_.map(SNACKEET_STORY_FEATURES, feature => <Chip key={feature.name} variant='outlined' sx={theme => ({
              borderColor: STORIES_COLORS.classic,
              backgroundColor: 'background.paper',
              ...theme.typography.body1,
              fontWeight: 500
            })} label={<FormattedMessage id={`modals.create_snackeet.story_features.${feature.name}`} />} />)}
							</Box>

							<StoryCard>
								<CardContent>
									<div>
										<b>
											<FormattedMessage id='modals.create_snackeet.description_header.classic' />
										</b>
									</div>
									<div>
										<FormattedMessage id='modals.create_snackeet.description_list.classic' />
									</div>
								</CardContent>

								<CardMedia component='video' alt='Survey Story' src='https://static.snackeet.com/snackeet/static/editor_assets/Snackeet%20Story%20Demo.mp4' title='Snackeet Story' autoPlay muted loop sx={{
              aspectRatio: '185 / 330',
              backgroundColor: 'white'
            }} />
							</StoryCard>
						</StoryGroup>

						<Box sx={{
          placeSelf: 'center'
        }}>
							<Typography variant='h2'>{intl.messages['common.comb.or']}</Typography>
						</Box>

						<StoryGroup $storyColor={STORIES_COLORS.amp} className={cx({
          selected: state.type === 'amp'
        })} onClick={selectStoryType('amp')}>
							{state.type === 'amp' && <StickyCheck top={30} right={30} />}

							<div>
								<h1>
									<FormattedMessage id='modals.create_snackeet.amp_story.title' />
								</h1>
								<h2>
									<FormattedMessage id='modals.create_snackeet.amp_story.description' />
								</h2>
							</div>
							{/* <Box width='100%' display='flex' justifyContent='center' flexWrap='wrap' gap={1}>
        {_.map(AMP_STORY_FEATURES, (feature) => (
        	<Chip
        		key={feature.name}
        		variant='outlined'
        		sx={(theme) => ({
        			borderColor: STORIES_COLORS.amp,
        			backgroundColor: 'background.paper',
        			...theme.typography.body1,
        			fontWeight: 500,
        		})}
        		label={
        			<FormattedMessage
        				id={`modals.create_snackeet.story_features.${feature.name}`}
        			/>
        		}
        	/>
        ))}
        </Box> */}

							{/* <StoryCard raised>
        <CardContent>
        	<div>
        		<b>
        			<FormattedMessage id='modals.create_snackeet.description_header.amp_story' />
        		</b>
        	</div>
        	<div>
        		<FormattedMessage id='modals.create_snackeet.description_list.amp_story' />
        	</div>
        </CardContent>
        	<CardMedia
        	component='video'
        	alt='Google AMP'
        	src='https://static.snackeet.com/snackeet/static/editor_assets/AMP%20Story%20Demo.mp4'
        	title='Google AMP'
        	autoPlay
        	muted
        	loop
        	sx={{
        		aspectRatio: '185 / 330',
        		backgroundColor: 'white',
        	}}
        />
        </StoryCard> */}
						</StoryGroup>
					</StoryCardsContainer>}

				{state.step === 1 && <CreateStoryContent>
						<CreateStoryDetails>
							<div className='story-field '>
								<Box mx={2} fontWeight='bold'>
									<FormattedMessage id='form.name' />
								</Box>
								<CreateStoryInput inputRef={inputRef} value={state.name} placeholder={intl.messages['form.story.name']} onChange={handleName} error={!state.name} />
								<label>{`${state.name.length} / 25`}</label>
							</div>

							<div className='story-field'>
								<Box mx={2} fontWeight='bold'>
									<FormattedMessage id='common.language' />
								</Box>
								<Dropdown variant='standard' disableUnderline fullWidth={false} value={state.language} options={languageOptions} onChange={handleLanguage} />
							</div>

							{!_.isEmpty(brands) && <>
									<BrandsContainer>
										<Box pb={1}>{intl.messages['brand_card.choose_a_brand']}</Box>
										<BrandWrapper $isActive={state.brandId === ''} onClick={() => handleChangeBrand('')}>
											{state.brandId === '' && <StickyCheck />}
											<NoBrandPlaceholder>
												<h1>{intl.messages['brand_card.no_brand']}</h1>
											</NoBrandPlaceholder>
										</BrandWrapper>
										{_.map(brands, (brand, index) => <BrandWrapper key={brand._id} $isActive={brand._id === state.brandId} onClick={() => handleChangeBrand(brand._id)}>
												{brand._id === state.brandId && <StickyCheck />}
												<BrandCard key={brand._id} index={index} brand={brand} isSmall />
											</BrandWrapper>)}
									</BrandsContainer>
								</>}
						</CreateStoryDetails>
					</CreateStoryContent>}

				{state.step === 2 && <CreateStoryContent>
						<StoryTemplateSelection instagramModalRef={instagramModalRef} storyType={state.type} onSelect={onStoryStarterSelect} />
					</CreateStoryContent>}

				<CreateStoryModalFooter type={state.type} name={state.name} step={state.step} setStep={setStep} />
			</ModalWrapper>
		</>;
}
CreateStoryModal.propTypes = {
  trigger: PropTypes.object
};
const BUTTON_WIDTH = 200;
function CreateStoryModalFooter({
  type,
  name,
  step,
  setStep
}) {
  if (step === 0) {
    return <CreateStoryModalFooterContainer>
				<div></div>

				<Stepper currentStep={step} />

				<Box width={BUTTON_WIDTH} ml='auto'>
					<Button fullWidth variant='contained' color='primary' onClick={() => setStep(1)} disabled={!type} endIcon={<ChevronRight />}>
						<FormattedMessage id='button.next' />
					</Button>
				</Box>
			</CreateStoryModalFooterContainer>;
  }
  if (step === 1) {
    return <CreateStoryModalFooterContainer>
				<Box width={BUTTON_WIDTH} mr='auto'>
					<Button fullWidth variant='text' onClick={() => setStep(0)} startIcon={<ChevronLeft />}>
						<FormattedMessage id='button.previous' />
					</Button>
				</Box>

				<Stepper currentStep={step} />

				<Box width={BUTTON_WIDTH} ml='auto'>
					<Button fullWidth variant='contained' color='primary' onClick={() => setStep(2)} disabled={!type || name.trim().length === 0} endIcon={<ChevronRight />}>
						<FormattedMessage id='button.next' />
					</Button>
				</Box>
			</CreateStoryModalFooterContainer>;
  }
  if (step === 2) {
    return <CreateStoryModalFooterContainer>
				<Box width={BUTTON_WIDTH} mr='auto'>
					<Button fullWidth variant='text' onClick={() => setStep(1)} startIcon={<ChevronLeft />}>
						<FormattedMessage id='button.previous' />
					</Button>
				</Box>

				<Stepper currentStep={step} />
			</CreateStoryModalFooterContainer>;
  }
}
function Stepper({
  currentStep,
  totalSteps = 3
}) {
  return <Box className='stepper' m='auto'>
			<Chip color='primary' label={`${currentStep + 1} / ${totalSteps}`} />
		</Box>;
}