import { Box, DialogActions } from '@mui/material';
import AmpAttchmentSettings from 'components/AmpAttachmentSettings';
import AttachmentTemplateSelector from 'components/AttachmentTemplateSelector';
import { CreateStoryDetails, CreateStoryInput } from 'components/Modals/StoriesListModals/CreateStoryModal/CreateStoryModal.style';
import PagePreview from 'components/PagePreview/index';
import { FormSettingsWrapper } from 'components/styled/Modals';
import * as translation from 'lib/constants/InteractionTranslation';
import { useStoryDispatch, useStoryState } from 'lib/editorStore';
import { getAttachmentElement } from 'lib/models';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import SubmitButton from 'organisms/buttons/SubmitButton';
import Dropdown from 'organisms/Dropdown';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useImmer } from 'use-immer';
import { ModalWrapper } from '../ModalWrapper';
const THEME_OPTIONS = [{
  text: <FormattedMessage id='common.theme.light' />,
  value: 'light',
  key: 0
}, {
  text: <FormattedMessage id='common.theme.dark' />,
  value: 'dark',
  key: 1
}];
export default function CreateAmpAttachmentModal({
  trigger
}) {
  const intl = useIntl();
  const ref = useRef();
  const dispatch = useStoryDispatch();
  const draftStory = useStoryState(s => s.draftStory);
  const page = useStoryState(s => s.page);
  const [step, setStep] = useState(0);
  const [ampAttachment, setAmpAttachment] = useImmer(initAmpAttachment(draftStory.story_settings.language));
  function handleChangeAmpFormProperty(path, value) {
    setAmpAttachment(draft => {
      draft[path] = value;
    });
  }
  function resetAmpForm() {
    setAmpAttachment(() => initAmpAttachment(draftStory.story_settings.language));
    setStep(0);
  }
  function onPreviousStep() {
    if (step > 0) {
      setStep(step - 1);
    } else {
      setStep(0);
    }
  }

  // Apply template
  function onSelectTemplate(template) {
    if (template) {
      // Clean up template
      const newTemplate = _.cloneDeep(template);
      // Apply template
      setAmpAttachment(draft => {
        draft.content = newTemplate.content;
        draft.styles = newTemplate.styles;
        draft.theme = newTemplate.theme;
      });
    }
    setStep(2);
  }
  function onSubmitConfig(newInteraction) {
    dispatch({
      type: 'ADD_AMP_ATTACHMENT',
      data: {
        newInteraction
      }
    });
    ref.current.close();
  }
  return <ModalWrapper title={generateModalTile(step)} trigger={trigger} ref={ref}
  // Avoid close modal when editing interacttion
  disableBackdropClick={step === 1} disableEscapeKeyDown={step === 1} hasCloseButton={step !== 1} onClose={resetAmpForm} size='lg'>
			{step === 0 && <>
					<FormSettingsWrapper style={{
        gridTemplateColumns: '500px 1fr'
      }}>
						<Box width='100%' height='250px' display='flex' justifyContent='center' alignItems='end' overflow='hidden'>
							<PagePreview size='extra_large' page={{
            ...page,
            amp_attachments: [ampAttachment]
          }} theming={draftStory.theming} messages={draftStory.messages} storyType={draftStory.type} />
						</Box>

						<CreateStoryDetails>
							<div className='story-field '>
								<Box mx={2}>
									<FormattedMessage id='edition_menu.amp_form.cta_text.title' />
								</Box>
								<CreateStoryInput value={ampAttachment?.cta_text} placeholder={intl.messages['form.story.name']} onChange={({
              target: {
                value
              }
            }) => handleChangeAmpFormProperty('cta_text', value)} />
							</div>

							<div className='story-field '>
								<Box mx={2}>
									<FormattedMessage id='edition_menu.amp_interactions.style.theme' />
								</Box>
								<Dropdown fullWidth={false} variant='standard' disableUnderline value={ampAttachment.theme} options={THEME_OPTIONS} onChange={({
              target: {
                value
              }
            }) => handleChangeAmpFormProperty('theme', value)} />
							</div>
						</CreateStoryDetails>
					</FormSettingsWrapper>
					<DialogActions>
						<SubmitButton variant='contained' color='primary' disabled={_.isEmpty(ampAttachment.cta_text)} onClick={() => setStep(1)}>
							<FormattedMessage id='button.next' />
						</SubmitButton>
					</DialogActions>
				</>}
			{step === 1 && <AttachmentTemplateSelector onPreviousStep={onPreviousStep} onSelect={onSelectTemplate} attachmentType='amp_attachment' />}
			{step === 2 && <AmpAttchmentSettings onPreviousStep={onPreviousStep} onSubmitConfig={onSubmitConfig} currentAttachment={ampAttachment} currentPage={page} draftStory={draftStory} />}
		</ModalWrapper>;
}
CreateAmpAttachmentModal.propTypes = {
  trigger: PropTypes.object
};
function generateModalTile(step) {
  switch (step) {
    case 0:
      return <FormattedMessage id='modals.amp_attachment.modal.create' />;
    case 1:
      return <FormattedMessage id='modals.amp_attachment.modal.template' />;
    case 2:
      return <FormattedMessage id='modals.amp_attachment.modal.settings' />;
  }
}

// Helper function
function initAmpAttachment(lang) {
  return {
    _id: `amp-${nanoid()}`,
    type: 'amp_attachment',
    cta_text: translation.amp_attachment_cta_text[lang],
    theme: 'light',
    styles: {
      fontFamily: 'Roboto'
    },
    content: [getAttachmentElement({
      type: 'content',
      sub_type: 'title',
      value: 'Inspiration of the Day'
    }), getAttachmentElement({
      type: 'content',
      sub_type: 'paragraph',
      value: `Life is a journey, and every step you take is a chance to learn and grow. Embrace the challenges and savor the victories.`
    }), getAttachmentElement({
      type: 'content',
      sub_type: 'button'
    })]
  };
}