import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrganizationId } from 'redux/organization/selectors'
import { updateOrganization } from 'redux/organization/slice'
import { getCurrentProjectId } from 'redux/project/selectors'
import InstagramSvc from 'services/InstagramSvc'
import { useImmer } from 'use-immer'

const initialState = {
	isLoading: false,
	isLoggedIn: false,
	isAuthorized: false,
	isEachScopeAuthorized: true,
	isNotSupport: false,
}
export const permissions = [
	'pages_show_list',
	'pages_read_engagement',
	'instagram_manage_insights',
	'instagram_basic',
	'business_management',
]

export default function useFbLogin() {
	const [state, setState] = useImmer(initialState)
	const organizationId = useSelector(getCurrentOrganizationId)
	const projectId = useSelector(getCurrentProjectId)
	const dispatchRedux = useDispatch()

	const login = useCallback(() => {
		setState((draft) => {
			draft.isLoading = true
		})
		const fbLoginOptions = {
			scope: permissions.join(','),
			return_scopes: true,
		}
		// Re-ask for Declined Permissions
		if (!state.isEachScopeAuthorized) {
			fbLoginOptions.auth_type = 'rerequest'
		}

		function handleError(err) {
			console.error('Facebook login:', err)
			setState((draft) => {
				draft.isLoading = false
			})
		}

		// FB.login only allows synchronous callback function
		FB.login(function (response) {
			try {
				if (!hasAllScopes(response.authResponse?.grantedScopes)) {
					setState((draft) => {
						draft.isLoading = false
						draft.isEachScopeAuthorized = false
					})
				} else if (response.status === 'connected') {
					InstagramSvc.updateAccounts({
						access_token: response.authResponse.accessToken,
						fb_user_id: response.authResponse.userID,
						organization_id: organizationId,
						project_id: projectId,
					})
						.then((updatedOrganization) => {
							dispatchRedux(updateOrganization(updatedOrganization))

							setState((draft) => {
								draft.isLoading = false
								draft.isLoggedIn = true
								draft.isAuthorized = true
								draft.isEachScopeAuthorized = true
							})
						})
						.catch(handleError)
				} else {
					throw new Error('Not connected')
				}
			} catch (err) {
				handleError(err)
			}
		}, fbLoginOptions)
	}, [dispatchRedux, organizationId, projectId, setState, state.isEachScopeAuthorized])

	useEffect(() => {
		// Place a condition to handle FB undefined then crash application
		if (FB) {
			FB.init({
				version: process.env.NEXT_PUBLIC_FB_API_VERSION,
				appId: process.env.NEXT_PUBLIC_FB_APP_ID,
				cookie: true,
				xfbml: true,
			})

			// Check if user logged
			FB.getLoginStatus((authResponse) => {
				setState((draft) => {
					draft.isAuthorized = authResponse.status === 'connected'
					draft.isLoggedIn = ['connected', 'not_authorized'].includes(authResponse.status)
				})
			})
		} else {
			setState((draft) => {
				draft.isNotSupport = true
			})
		}
	}, [setState])

	return {
		...state,
		login,
	}
}

function hasAllScopes(grantedScopes = []) {
	const grantedScopesArray = grantedScopes.split(',')
	return permissions.every((permission) => grantedScopesArray.includes(permission))
}
