import { saturate, shade, tint } from 'polished';
import { ErrorText, GlassPanel, NotFoundContainer, ReturnButton, SubHeader } from './CustomNotFound.styles';
export default function NotFoundPage({
  title,
  subHeader,
  accentColor = '#ff8008',
  ctaText,
  ctaUrl
}) {
  return <NotFoundContainer style={{
    '--background-color': '#ffffff',
    '--panel-color': '#f0f4f8',
    '--text-color': '#000000',
    '--button-background-color': '#ffffff',
    '--button-hover-background-color': '#f9f9f9',
    '--accent-color': accentColor,
    '--accent-color-lighter': tint(0.2, accentColor),
    '--accent-color-darker': shade(0.1, saturate(0.5, accentColor))
  }}>
			<GlassPanel>
				<ErrorText>404</ErrorText>
				{subHeader ? <SubHeader>{subHeader}</SubHeader> : <SubHeader>
						The story <b>does not exist</b> or has been <b>unpublished</b>
					</SubHeader>}
				<ReturnButton variant='contained' href={ctaUrl || 'https://snackeet.com/?utm_source=404'}>
					{ctaText || 'Return to Snackeet'}
				</ReturnButton>
			</GlassPanel>
		</NotFoundContainer>;
}