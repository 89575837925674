import styled from '@styled';
import { PREVIEW_HEIGHT, PREVIEW_WIDTH } from 'lib/constants';
import { tint } from 'polished';

// Ratio to compute height of play icon.
// The size of the icon should be the smallest possible while being big enough to cover snapshot image play icon
const PLAY_ICON_RATIO = 20 / 50;
export const PageStacks = styled.div`
	opacity: 0;
	display: flex;
	align-items: center;
	z-index: -1;
	position: absolute;
	top: 0;
	right: -8px;
	bottom: 0;
	transition:
		transform 0.2s,
		opacity 0.1s;

	& > div {
		width: 6px;
		border-top-right-radius: 16px;
		border-bottom-right-radius: 16px;
	}

	& > div:first-child {
		background: ${({
  $color
}) => $color};
		margin: 0 2px;
		height: 87%;
	}

	& > div:nth-child(2) {
		background: ${({
  $color
}) => tint(0.4, $color)};
		height: 80%;
	}
`;
export const StoryCardContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	overflow: hidden;
	position: relative;
	align-items: center;
	flex-shrink: 0;
	user-select: none;
	justify-content: center;
	color: white;
	box-shadow: ${({
  $cardShadow,
  theme
}) => $cardShadow ? theme.shadows[2] : 'unset'};
	border-radius: var(--banner-card-radius);

	img,
	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
		user-select: none;
		-webkit-touch-callout: none;
	}
`;
export const ActionButtons = styled.div`
	background: white;
	border-radius: 8px;
	margin-top: ${({
  theme
}) => theme.spacing(1)};
	display: flex;
	align-items: center;

	.MuiIconButton-root {
		padding: 2px;
		border-radius: 8px;
	}

	.hoverable {
		transition: opacity 300ms ease-in;
		opacity: 0;
	}

	&:hover {
		.hoverable {
			opacity: 1;
		}
	}
`;
export const BannerCardContainer = styled.div`
	box-sizing: border-box;
	position: relative;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: ${({
  theme
}) => theme.spacing(0, 2)};
	cursor: pointer;
	will-change: transform;
	transition: transform 0.1s linear;

	/* Dimensions */
	--banner-story-height: ${({
  $height
}) => $height ? `${$height}px` : 'auto'};
	--banner-story-width: ${({
  $width
}) => $width ? `${$width}px` : '200px'};
	aspect-ratio: ${PREVIEW_WIDTH} / ${PREVIEW_HEIGHT};
	width: var(--banner-story-width);
	height: var(--banner-story-height);
`;
export const BannerCardActionArea = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	&:hover {
		${BannerCardContainer} {
			transform: scale(1.01);
		}

		${ActionButtons} {
			.hoverable {
				opacity: 1;
			}
		}
	}
`;
export const Overlay = styled.div`
	box-sizing: inherit;
	position: absolute;
	inset: 0;
	background: linear-gradient(0deg, rgba(33, 33, 33, 0.7), rgba(33, 33, 33, 0));
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	border-radius: var(--banner-card-radius);
	overflow: hidden;
`;
export const PlayIconContainer = styled.div`
	.MuiSvgIcon-root {
		transition: opacity 0.1s;
		color: white;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		filter: drop-shadow(0px 5px 5px rgba(103, 102, 102, 0.82));
		width: calc(var(--banner-story-width) * ${PLAY_ICON_RATIO});
		height: calc(var(--banner-story-height) * ${PLAY_ICON_RATIO});
	}
`;
export const CardCaption = styled.div`
	box-sizing: border-box;
	color: #fff;
	max-height: 50%;
	padding: 15% 5% 10% 5%;
	width: 100%;
	font-size: ${({
  $fontSize
}) => $fontSize ?? 16}px;
	white-space: pre-line;
	text-align: start;
	line-height: 1em;
`;