import { Cancel, CheckCircle } from '@mui/icons-material';
import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material';
import { ImportModalContent } from 'components/styled/Modals';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalWrapper } from './ModalWrapper';
const ImportingModal = forwardRef(({
  importStatus,
  // done, verifying, uploading, or error
  errorMsg,
  disableBackdropClick = true,
  disableEscapeKeyDown = true
}, ref) => {
  const [progress, setProgress] = useState(0);
  const [overtime, setOvertime] = useState(false);
  useEffect(() => {
    const timer = setInterval(() => {
      if (importStatus !== 'verifying') {
        setProgress(100);
      } else {
        setProgress(prevValue => prevValue >= 90 ? 99 : prevValue + 10);
      }
    }, 3500);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (progress >= 99) {
      setOvertime(true);
    }
  }, [progress]);
  useEffect(() => {
    if (importStatus === 'done') {
      setProgress(0);
      setOvertime(false);
      ref.current.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importStatus]);
  const onOpen = () => {
    setProgress(0);
    setOvertime(false);
  };
  const onClose = () => {
    setProgress(0);
    setOvertime(false);
  };
  function renderModalContent() {
    switch (importStatus) {
      case 'done':
        return <>
							<Box sx={{
            fontSize: 100,
            color: 'success.main'
          }}>
								<CheckCircle fontSize='inherit' />
							</Box>

							<Box sx={{
            fontSize: 16
          }}>
								<Typography variant='h1' sx={{
              fontWeight: 500,
              fontSize: 24,
              mb: 1
            }}>
									<FormattedMessage id='modals.import.status.done' />
								</Typography>

								<p>
									<FormattedMessage id='modals.import.status.done.description' />
								</p>
							</Box>

							<Button variant='outlined' color='secondary' size='large' onClick={() => ref.current.close()}>
								<FormattedMessage id='button.confirm' />
							</Button>
						</>;
      case 'uploading':
      case 'verifying':
        return <>
							<CircularProgressWithLabel size={100} value={progress} />

							<Box sx={{
            fontSize: 16
          }}>
								<Typography variant='h1' sx={{
              fontWeight: 500,
              fontSize: 24,
              mb: 1
            }}>
									<FormattedMessage id={`modals.import.status.${importStatus}`} />
								</Typography>
								<p>
									<FormattedMessage id='modals.import.status.uploading.description' />
								</p>

								{overtime && <Alert severity='info' sx={{
              mt: 1
            }}>
										<FormattedMessage id='modals.import.overtime' />
									</Alert>}
							</Box>
						</>;
      case 'error':
        return <>
							<Box sx={{
            fontSize: 100,
            color: 'error.main'
          }}>
								<Cancel fontSize='inherit' />
							</Box>

							<Box sx={{
            fontSize: 16
          }}>
								<Typography variant='h1' sx={{
              fontWeight: 500,
              fontSize: 24,
              mb: 1
            }}>
									<FormattedMessage id={`modals.import.status.error`} />
								</Typography>

								{errorMsg && <p>{errorMsg}</p>}
							</Box>

							<Button variant='outlined' size='large' onClick={() => ref.current.close()}>
								<FormattedMessage id='button.close' />
							</Button>
						</>;
      default:
        return null;
    }
  }
  return <ModalWrapper ref={ref} title={<FormattedMessage id='modals.import.header' />} size='sm' onOpen={onOpen} onClose={onClose} hasCloseButton={['error', 'done'].includes(importStatus)} disableBackdropClick={disableBackdropClick} disableEscapeKeyDown={disableEscapeKeyDown}>
				<ImportModalContent sx={{
      height: 'auto!important'
    }}>{renderModalContent()}</ImportModalContent>
			</ModalWrapper>;
});
ImportingModal.propTypes = {
  importStatus: PropTypes.string,
  errorMsg: PropTypes.string,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool
};
ImportingModal.displayName = 'ImportingModal';
export default ImportingModal;
function CircularProgressWithLabel({
  value,
  ...delegatedProps
}) {
  return <Box position='relative' display='inline-flex'>
			<CircularProgress {...delegatedProps} />
			<Box top={0} left={0} bottom={0} right={0} position='absolute' display='flex' alignItems='center' justifyContent='center'>
				<Typography style={{
        fontSize: 25
      }} variant='caption' component='div' color='textSecondary'>
					{`${Math.round(value)}%`}
				</Typography>
			</Box>
		</Box>;
}
CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired
};