import styled from '@styled';
import { gradientToCSS } from 'lib/utils';
import PropTypes from 'prop-types';
const Overlay = styled.div`
	border-radius: var(--story-border-radius);
	position: absolute;
	background: ${props => props.$backgroundColor};
	opacity: ${props => props.$opacity};
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;
export default function BackgroundDimmer({
  dimmer_opacity,
  dimmer_colors = ['rgba(0, 0, 0, 1)'],
  dimmer_gradient = {
    type: 'linear',
    direction: 0
  }
}) {
  const backgroundColor = dimmer_colors.length > 1 ? gradientToCSS({
    ...dimmer_gradient,
    colors: dimmer_colors
  }) : dimmer_colors[0];
  return <Overlay $opacity={dimmer_opacity} $backgroundColor={backgroundColor} />;
}
BackgroundDimmer.propTypes = {
  dimmer_opacity: PropTypes.string,
  dimmer_colors: PropTypes.array,
  dimmer_gradient: PropTypes.object
};