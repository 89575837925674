import { Box, Typography } from '@mui/material';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import PickerContext from '../../context/PickerContext';
import Search from './Search';
import Title from './Title';
export default function Header() {
  const [pickerContext] = useContext(PickerContext);
  return <>
			<div className='gpr-header'>{pickerContext.showTrending ? <Title>Trending GIFs</Title> : <Search />}</div>
			<Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      mx: 1
    }}>
				<Typography variant='h5' sx={{
        px: 0.5
      }}>
					{pickerContext.showTrending || pickerContext.searchTerm ? <FormattedMessage id='edition_menu.gif_selection_instructions' /> : <FormattedMessage id='edition_menu.gif_instructions' />}
				</Typography>
				<a target='_blank' rel='noreferrer' href='https://giphy.com' className='powered-by'>
					<img src='/static/integration_logos/tenor-powered-by.png' />
				</a>
			</Box>
		</>;
}