import * as React from 'react'

function Svg6Number(props) {
	return (
		<svg width='1em' height='1em' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M2.797.731v.46h-.1c-.422.008-.758.133-1.008.375-.25.243-.394.583-.433 1.023.224-.258.531-.387.92-.387.37 0 .667.131.887.393.223.261.334.6.334 1.013 0 .44-.12.791-.36 1.055-.238.264-.558.396-.96.396-.409 0-.74-.157-.994-.47-.254-.314-.38-.718-.38-1.212v-.208c0-.785.166-1.385.5-1.799C1.54.954 2.038.741 2.7.731h.097zm-.712 1.922a.865.865 0 00-.513.167.87.87 0 00-.325.42v.198c0 .352.08.635.237.85.159.215.356.322.592.322.244 0 .436-.09.574-.27.141-.179.211-.414.211-.705 0-.293-.071-.53-.214-.71a.676.676 0 00-.562-.272z'
				fill='#000'
			/>
		</svg>
	)
}

export default Svg6Number
