import { Add, Remove } from '@mui/icons-material';
import { Box, Popover } from '@mui/material';
import _ from 'lodash';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDebouncedCallback } from 'use-debounce';
import { DecreaseButton, IncreaseButton, NumberInputOption, NumberInputTextField } from './NumberInput.style';
export default function NumberInput({
  initial_value,
  label,
  maxValue = 100,
  minValue = 0,
  onUpdate,
  inputStep = 1,
  disabled = false,
  debounceDelay = 100,
  presetValues = [],
  sx
}) {
  const [value, setValue] = useState(initial_value);
  const [isError, setError] = useState(false);
  const presetPopupState = usePopupState({
    variant: 'popover',
    popupId: 'preset-picker',
    disableAutoFocus: true
  });
  const debouncedUpdate = useDebouncedCallback(value => onUpdate(parseFloat(value)), debounceDelay);
  const handleInputChange = useCallback(({
    target: {
      value
    }
  }) => setValue(value), []);
  const handleBlur = useCallback(() => {
    if (value >= minValue && value <= maxValue) {
      onUpdate(parseFloat(value));
      setError(false);
    } else {
      setError(true);
    }
  }, [maxValue, minValue, onUpdate, value]);
  const handlePreset = useCallback(value => {
    setValue(value);
    setError(false);
    if (value >= minValue && value <= maxValue) {
      onUpdate(parseFloat(value));
    }
  }, [maxValue, minValue, onUpdate]);
  const handleIncrease = useCallback(() => {
    let newValue = parseFloat(value) + inputStep;
    if (inputStep % 1 !== 0) {
      newValue = newValue.toFixed(1);
    }
    if (newValue > maxValue) {
      newValue = maxValue;
    }
    setValue(newValue);
    if (newValue >= minValue && newValue <= maxValue) {
      debouncedUpdate(newValue);
      setError(false);
    } else {
      setError(true);
    }
  }, [debouncedUpdate, inputStep, maxValue, minValue, value]);
  const handleDecrease = useCallback(() => {
    let newValue = parseFloat(value) - inputStep;
    if (inputStep % 1 !== 0) {
      newValue = newValue.toFixed(1);
    }
    if (newValue < minValue) {
      newValue = minValue;
    }
    setValue(newValue);
    if (newValue >= minValue && newValue <= maxValue) {
      debouncedUpdate(newValue);
      setError(false);
    } else {
      setError(true);
    }
  }, [debouncedUpdate, inputStep, maxValue, minValue, value]);

  // Handle Enter key
  const inputRef = useHotkeys('Enter, Return', handleBlur, {
    preventDefault: true,
    enableOnFormTags: true
  }, [handleBlur]);

  // Handle Undo/Redo
  useEffect(() => {
    setValue(initial_value);
  }, [initial_value]);

  // Scroll selected element into view when popup opens
  useEffect(() => {
    if (presetPopupState.isOpen) {
      setTimeout(() => {
        const activeElement = document.querySelector('.preset-list .active');
        if (activeElement) {
          activeElement.scrollIntoView({
            behavior: 'instant',
            block: 'center',
            inline: 'nearest'
          });
        }
      });
    }
  }, [presetPopupState.isOpen]);
  return <Box display='grid' gridTemplateColumns='1fr 1.5fr 1fr' height='30px' width='90%' sx={sx}>
			<DecreaseButton disabled={disabled || value <= minValue} onClick={handleDecrease}>
				<Remove />
			</DecreaseButton>
			<NumberInputTextField ref={inputRef} value={value} error={isError} onBlur={handleBlur} onChange={handleInputChange} {...bindTrigger(presetPopupState)} />
			<IncreaseButton disabled={disabled || value >= maxValue} onClick={handleIncrease}>
				<Add />
			</IncreaseButton>

			{!_.isEmpty(presetValues) && <Popover {...bindPopover(presetPopupState)} placement='top' anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }} transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}>
					<Box width='100%' maxHeight='150px' overflow='auto'>
						{_.map(presetValues, preset => <NumberInputOption key={preset} onClick={() => handlePreset(preset)} $isActive={preset === value}>
								{preset}
							</NumberInputOption>)}
					</Box>
				</Popover>}
		</Box>;
}
NumberInput.propTypes = {
  initial_value: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  onUpdate: PropTypes.func,
  inputStep: PropTypes.number,
  disabled: PropTypes.bool,
  debounceDelay: PropTypes.number,
  presetValues: PropTypes.array
};