import { extractPositionProperties } from 'lib/utils';
import PropTypes from 'prop-types';
import BlockWrapper from './BlockWrapper';
const calendarStyle = {
  border: 'none',
  width: '100%',
  height: '100%'
};
export default function Calendar({
  value,
  outer_style,
  animation
}) {
  const {
    positionProperties,
    visualProperties
  } = extractPositionProperties(outer_style);
  return <BlockWrapper animation={animation} positionProperties={positionProperties}>
			<div style={{
      ...visualProperties
    }}>
				<iframe src={value} style={calendarStyle} />
			</div>
		</BlockWrapper>;
}
Calendar.propTypes = {
  value: PropTypes.string,
  outer_style: PropTypes.object,
  animation: PropTypes.object
};