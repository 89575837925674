import { TrashIcon } from '@heroicons/react/24/outline';
import { Check } from '@mui/icons-material';
import { Box, Button, DialogActions, IconButton, SvgIcon, TextField, Typography } from '@mui/material';
import { HotspotIcon } from 'components/icons/custom';
import { ChipVariable, DefaultVariable, ModalContent } from 'components/styled/Modals';
import { LEADERBOARD_VARIABLES, VARIABLE_TYPE_OPTIONS, VARIABLES_TYPES_COLORS } from 'lib/constants';
import { useStoryDispatch, useStoryState } from 'lib/editorStore';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import slugify from 'slugify';
import { useImmer } from 'use-immer';
import { ModalWrapper } from '../ModalWrapper';
export default function StoryVariablesModal({
  storyVariablesRef
}) {
  const dispatch = useStoryDispatch();
  const createVariableRef = useRef();
  const {
    variables,
    type,
    pages
  } = useStoryState(s => s.draftStory);
  const [currentVariables, setVariables] = useState(variables);
  const [states, setStates] = useImmer({
    newVariable: {
      name: '',
      type: 'text'
    },
    fieldError: null
  });

  // Update local state when context state changes
  useEffect(() => {
    if (!_.isEqual(currentVariables, variables)) {
      setVariables(() => variables);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [variables]);

  // Update context state when local state changes
  useEffect(() => {
    if (!_.isEqual(currentVariables, variables)) {
      onUpdateVariables(currentVariables);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [currentVariables]);
  function onUpdateVariables(newVariables) {
    dispatch({
      type: 'UPDATE_VARIABLES',
      data: newVariables
    });
  }
  function handleChangeNewVariableName(value) {
    setStates(draft => {
      draft.fieldError = null;
    });
    const slugValue = slugify(value, {
      remove: /[*+~%\<>§/;`=.(){}?,'"!:@#^|]/g
    });
    setStates(draft => {
      draft.newVariable.name = slugValue;
    });
  }
  function handleChangeNewVariableType(value) {
    setStates(draft => {
      draft.newVariable.type = value;
    });
  }
  function addNewVariable() {
    if (_.isEmpty(states.newVariable.name)) {
      return setStates(draft => {
        draft.fieldError = 'Variable cannot be empty!';
      });
    }
    const doesVariableExist = _.some(variables, {
      name: states.newVariable.name
    });
    if (doesVariableExist) {
      return setStates(draft => {
        draft.fieldError = 'Variable already exists !';
      });
    }
    const newVariable = _.cloneDeep(states.newVariable);
    dispatch({
      type: 'UPDATE_VARIABLES',
      data: [...variables, {
        ...newVariable,
        default: false,
        isUsed: false
      }]
    });
    setStates(draft => {
      draft.newVariable = {
        name: '',
        type: 'text'
      };
      draft.fieldError = null;
    });
    createVariableRef.current.close();
  }
  function removeVariable(name) {
    const currentVariables = _.cloneDeep(variables);
    dispatch({
      type: 'UPDATE_VARIABLES',
      data: _.filter(currentVariables, variable => variable.name !== name)
    });
  }
  const handleOpen = () => {
    createVariableRef.current.open();
  };
  return <>
			<ModalWrapper size='md' ref={storyVariablesRef} hasCloseButton={false} disableBackdropClick title='Variables'>
				<ModalContent>
					<div className='variables-container'>
						{type === 'classic' && <Box>
								<Typography variant='h2' className='variables-title'>
									<FormattedMessage id='modals.variables.leaderboard.subtitle' />
								</Typography>

								<div className='sub-container'>
									{currentVariables.filter(variable => variable.default && _.includes(LEADERBOARD_VARIABLES, variable.name)).map(variable => <DefaultVariable key={variable.name} $isOnlyView>
												<Typography variant='body2'>{variable.name}</Typography>
												<Box textAlign='center'>
													<ChipVariable size='small' label={<Typography variant='body2'>
																<FormattedMessage id={`edition_menu.form.field_type.${variable.type}`} />
															</Typography>} $bgColor={VARIABLES_TYPES_COLORS[variable.type]} />
												</Box>

												{variable.isUsed && <Box display='flex' alignItems='center' justifyContent='flex-end'>
														<Typography variant='body2'>
															<FormattedMessage id='modals.variables.assigned' />
														</Typography>
														<Box display='flex' justifyContent='center' alignItems='center'>
															<HotspotIcon fontSize='inherit' sx={{
                      ml: 1
                    }} />
														</Box>
													</Box>}
											</DefaultVariable>)}
								</div>
							</Box>}
						<Box>
							<Typography variant='h2' className='variables-title'>
								<FormattedMessage id='modals.variables.default.subtitle' />
							</Typography>

							<div className='sub-container'>
								{currentVariables.filter(variable => variable.default && !_.includes(LEADERBOARD_VARIABLES, variable.name)).map(variable => <DefaultVariable key={variable.name} $isOnlyView>
											<Typography variant='body2'>{variable.name}</Typography>
											<Box textAlign='center'>
												<ChipVariable size='small' label={<Typography variant='body2'>
															<FormattedMessage id={`edition_menu.form.field_type.${variable.type}`} />
														</Typography>} $bgColor={VARIABLES_TYPES_COLORS[variable.type]} />
											</Box>

											{variable.isUsed && <Box display='flex' alignItems='center' justifyContent='flex-end'>
													<Typography variant='body2'>
														<FormattedMessage id='modals.variables.assigned' />
													</Typography>

													<Box display='flex' justifyContent='center' alignItems='center'>
														<HotspotIcon fontSize='inherit' sx={{
                      ml: 1
                    }} />
													</Box>
												</Box>}
										</DefaultVariable>)}
							</div>
						</Box>
						<Box>
							<Box display='flex' alignItems='center' marginY='10px'>
								<Typography variant='h2' className='variables-title variable-perso'>
									<FormattedMessage id='modals.variables.custom.title' />
								</Typography>

								<Button variant='contained' color='primary' size='small' onClick={handleOpen}>
									<FormattedMessage id='modals.variables.custom.create' />
								</Button>
							</Box>

							<div className='sub-container'>
								{variables.filter(variable => !variable.default).map(variable => <DefaultVariable key={variable.name}>
											<Typography variant='body2'>{variable.name}</Typography>
											<Box textAlign='center'>
												<ChipVariable size='small' label={<Typography variant='body2'>
															<FormattedMessage id={`edition_menu.form.field_type.${variable.type}`} />
														</Typography>} $bgColor={VARIABLES_TYPES_COLORS[variable.type]} />
											</Box>

											{variable.isUsed ? <Box display='flex' alignItems='center' justifyContent='flex-end'>
													<Typography variant='body2'>
														<FormattedMessage id='modals.variables.assigned' />
													</Typography>
													<Box display='flex' justifyContent='center' alignItems='center'>
														<HotspotIcon fontSize='inherit' sx={{
                      ml: 1
                    }} />
													</Box>
												</Box> : <Box display='flex' alignItems='center' justifyContent='flex-end'>
													<IconButton size='small' onClick={evt => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    removeVariable(variable.name);
                  }}>
														<SvgIcon>
															<TrashIcon />
														</SvgIcon>
													</IconButton>
												</Box>}
										</DefaultVariable>)}
							</div>
						</Box>
					</div>
				</ModalContent>
				<DialogActions>
					<Button variant='contained' color='secondary' onClick={() => {
          storyVariablesRef.current.close();
        }}>
						<FormattedMessage id='common.theming.confirm_button' />
					</Button>
				</DialogActions>
			</ModalWrapper>

			<ModalWrapper size='sm' ref={createVariableRef} title={<FormattedMessage id='modals.variables.custom.create' />}>
				<ModalContent>
					<Typography variant='body2' className='custom-subtitle'>
						<FormattedMessage id='modals.variables.custom.subtitle' />
					</Typography>
					<div className='create-variable'>
						<div className='form-variable'>
							<Box>
								<Typography variant='h4'>
									<FormattedMessage id='modals.variables.create_variable.type' />
								</Typography>
								<Box px={2} py={1} width='100%' display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr' gap={3}>
									{_.map(VARIABLE_TYPE_OPTIONS, type => <Box key={type.value} display='flex' justifyContent='space-between' alignItems='center' onClick={() => handleChangeNewVariableType(type.value)} p={1} sx={{
                  border: `1.5px solid ${states.newVariable.type === type.value ? VARIABLES_TYPES_COLORS[type.value] : '#ccc'}`,
                  color: `${states.newVariable.type === type.value ? VARIABLES_TYPES_COLORS[type.value] : '#ccc'}`,
                  borderRadius: '4px',
                  cursor: 'pointer'
                }}>
											<b>{type.text}</b>
											{states.newVariable.type === type.value && <Check size='small' />}
										</Box>)}
								</Box>
								<Box height={30}>
									<Typography variant='body2'>
										<FormattedMessage id={`modals.variables.description.${states.newVariable.type}`} />
									</Typography>
								</Box>
							</Box>
							<Box mb={1}>
								<Typography variant='h4' gutterBottom>
									<FormattedMessage id='modals.variables.create_variable.name' />
								</Typography>

								<TextField variant='outlined' fullWidth error={states.fieldError} placeholder='Custom variable' InputProps={{
                disableUnderline: false
              }} value={states.newVariable.name} onChange={({
                target: {
                  value
                }
              }) => handleChangeNewVariableName(value)} helperText={states.fieldError} />
							</Box>
						</div>
						<Box display='flex' justifyContent='flex-end'>
							<Button variant='contained' color='primary' onClick={addNewVariable}>
								<FormattedMessage id='modals.variables.create_variable.create_btn' />
							</Button>
						</Box>
					</div>
				</ModalContent>
			</ModalWrapper>
		</>;
}
StoryVariablesModal.propTypes = {
  storyVariablesRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.any
  })])
};