import { CogIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Add } from '@mui/icons-material';
import { Box, Button, DialogActions, SvgIcon } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Loader from 'components/Loader';
import { ConfirmationModal, ManageTemplateAccessModal } from 'components/Modals';
import { TemplateSectionButtonsContainer, TemplateSectionCategory, TemplatesSection, TemplatesSelectionContainer } from 'components/Modals/StoriesListModals/CreateStoryModal/CreateStoryModal.style';
import SubmitButton from 'components/organisms/buttons/SubmitButton';
import { Figure, StickyCheck } from 'components/styled';
import { CustomPageTemplateCard, TemplatesWrapper } from 'components/styled/Modals';
import { useStoryState } from 'lib/editorStore';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCustomerRights } from 'redux/customer/selectors';
import { getCurrentOrganizationId } from 'redux/organization/selectors';
import TemplatesSvc from 'services/TemplatesSvc';
import { useImmer } from 'use-immer';
import AmpAttachmentPreview from './AmpAttachmentSettings/AmpAttachmentPreview';
import AmpFormPreview from './AmpFormSettings/AmpFormPreview';
import AttachmentPreview from './AttachmentSettings/AttachmentPreview';
const attachmentTemplateSelectorSlice = s => ({
  page: s.page,
  project: s.draftStory.project,
  totalPages: s.draftStory?.pages?.length,
  theming: s.draftStory.theming,
  messages: s.draftStory.messages,
  gdprConsent: s.draftStory?.gdprConsent,
  storyType: s.draftStory.type
});
export default function AttachmentTemplateSelector({
  onSelect,
  onCancel,
  onPreviousStep,
  attachmentType,
  isProcessing
}) {
  const confirmationRef = useRef();
  const accessModalRef = useRef();
  const intl = useIntl();
  const organizationId = useSelector(getCurrentOrganizationId);
  const currentCustomerRights = useSelector(getCustomerRights);
  const {
    page,
    project,
    totalPages,
    theming,
    messages,
    gdprConsent,
    storyType
  } = useStoryState(attachmentTemplateSelectorSlice);
  const [state, setState] = useImmer({
    currentAttachment: undefined,
    templateToUpdate: {}
  });
  const setCurrentSection = useCallback(value => setState(draft => {
    draft.currentSection = value;
  }), [setState]);
  const {
    data: myAttachmentTemplates,
    isLoading: isLoadingAttachmentTemplates
  } = useQuery({
    queryKey: ['attachmentTemplates', {
      organization_id: organizationId,
      project
    }],
    queryFn: ({
      queryKey
    }) => {
      const {
        project,
        organization_id
      } = queryKey[1];
      return TemplatesSvc.getAttachmentTemplates({
        project,
        organization_id
      });
    }
  });
  const queryClient = useQueryClient();
  const updateAttachmentTemplateMutation = useMutation({
    mutationFn: TemplatesSvc.updateAttachmentTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['attachmentTemplates']
      });
    }
  });
  const deleteAttachmentTemplateMutation = useMutation({
    mutationFn: TemplatesSvc.removeAttachmentTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['attachmentTemplates']
      });
      setState(draft => {
        // If removed template is chosen then reset currentAttachment
        if (draft.currentAttachment?._id === draft.templateToUpdate?.attachment?._id) {
          draft.currentAttachment = undefined;
        }
        draft.templateToUpdate = {};
      });
      confirmationRef.current.close();
    }
  });
  const filteredAttachmentTemplates = _.filter(myAttachmentTemplates, template => template.attachment.type === attachmentType).reverse();
  async function handleLayoutSelection(attachment) {
    if (attachment !== state.currentAttachment) {
      setState(draft => {
        draft.currentAttachment = attachment;
      });
    } else if (!isProcessing) {
      onSelect(state.currentAttachment);
    }
  }
  function openConfirmationModal(template) {
    setState(draft => {
      draft.templateToUpdate = template;
    });
    confirmationRef.current.open();
  }
  function openAccessModal(template) {
    setState(draft => {
      draft.templateToUpdate = template;
    });
    accessModalRef.current.open();
  }

  // Handle Change Project Access
  function handleProjectAccessChange(project_id) {
    if (!_.includes(state.templateToUpdate.accessibleProjects, project_id)) {
      setState(draft => {
        draft.templateToUpdate.accessibleProjects.push(project_id);
      });
    } else {
      setState(draft => {
        draft.templateToUpdate.accessibleProjects = draft.templateToUpdate.accessibleProjects.filter(_id => _id !== project_id);
      });
    }
  }
  function isSelected(templateId) {
    return state.currentAttachment?._id === templateId;
  }
  return <>
			<TemplatesSelectionContainer $noNavBar={true}>
				<div />
				<div>
					<TemplateSectionButtonsContainer>
						{/* CREATE FROM SCRATCH */}
						<Button variant='contained' color='primary' startIcon={<Add />} onClick={() => onSelect(undefined)} data-intercom-target='From Scratch'>
							<FormattedMessage id='modals.create_snackeet.steps.from_scratch' />
						</Button>
					</TemplateSectionButtonsContainer>

					<TemplatesSection>
						<div>
							<TemplateSectionCategory>
								{intl.messages['modals.templates.tabs.custom']}
							</TemplateSectionCategory>

							<TemplatesWrapper>
								{isLoadingAttachmentTemplates ? <Loader $heightPx={100} size={50} name='ThreeBounce' /> : _.isEmpty(filteredAttachmentTemplates) ? <Figure height={100}>
										<img src='/static/placeholders/empty_folder.svg' />
										<figcaption>
											<FormattedMessage id='modals.templates.attachment.empty' />
										</figcaption>
									</Figure> : _.map(filteredAttachmentTemplates, template => <CustomPageTemplateCard key={template._id} $isActive={isSelected(template.attachment._id)}>
											{isSelected(template.attachment._id) && <StickyCheck />}

											<div className='cover'>
												<Button variant='contained' color='secondary' size='large' onClick={() => handleLayoutSelection(template.attachment)}>
													{intl.messages['modals.templates.button.select']}
												</Button>

												{currentCustomerRights.isAdmin && <Box bgcolor={'white'}>
														<Button variant='outlined' size='small' onClick={() => openAccessModal(template)} startIcon={<SvgIcon>
																	<CogIcon />
																</SvgIcon>}>
															{intl.messages['modals.templates.button.manage']}
														</Button>
													</Box>}
												<Box bgcolor={'white'}>
													<Button color='error' variant='contained' size='small' onClick={() => openConfirmationModal(template)} startIcon={<SvgIcon>
																<TrashIcon />
															</SvgIcon>}>
														{intl.messages['button.delete']}
													</Button>
												</Box>
											</div>

											<Box height='240px' width='100%' overflow='hidden' borderRadius='5px'>
												<div style={{
                    width: '360px',
                    transform: 'scale(0.325)',
                    transformOrigin: 'top left',
                    border: '1px #ccc solid'
                  }}>
													{attachmentType === 'amp_attachment' && <AmpAttachmentPreview content={template?.attachment?.content} cta_text={template?.attachment?.cta_text} styles={template?.attachment?.styles} theme={template?.attachment?.theme} />}
													{attachmentType === 'amp_form' && <AmpFormPreview content={template?.attachment?.content} success={template?.attachment?.success} submit={template?.attachment?.submit} cta_text={template?.attachment?.cta_text} styles={template?.attachment?.styles} theme={template?.attachment?.theme} isForm />}
													{attachmentType === 'story_attachment' && <AttachmentPreview content={template?.attachment?.content} styles={template?.attachment?.styles} theme={template?.attachment?.theme} />}
												</div>
											</Box>
										</CustomPageTemplateCard>)}
							</TemplatesWrapper>
						</div>
					</TemplatesSection>
				</div>
			</TemplatesSelectionContainer>

			<ConfirmationModal size='sm' confirmationModalRef={confirmationRef} title={<FormattedMessage id='modals.templates.delete.title' />} onConfirm={() => deleteAttachmentTemplateMutation.mutate({
      project,
      organization_id: organizationId,
      attachmentTemplate_id: state.templateToUpdate?._id
    })} firstLine={<FormattedMessage id='modal.file_deletion.confirmation.sub_header' />} secondLine={<FormattedMessage id='modal.file_deletion.confirmation.instructions' />} confirmText={<FormattedMessage id='common.yes' />} cancelText={<FormattedMessage id='common.no' />} />
			<ManageTemplateAccessModal ref={accessModalRef} templateToUpdate={state.templateToUpdate} onProjectAccessChange={handleProjectAccessChange} onConfirm={() => updateAttachmentTemplateMutation.mutate({
      project,
      organization_id: organizationId,
      attachmentTemplate: state.templateToUpdate
    })} />

			<DialogActions>
				{onPreviousStep ? <Button variant='text' color='primary' onClick={onPreviousStep}>
						<FormattedMessage id='button.previous' />
					</Button> : <div />}

				{onCancel ? <Button variant='outlined' onClick={onCancel}>
						<FormattedMessage id='button.cancel' />
					</Button> : <div />}

				<SubmitButton data-intercom-target='Confirm Button' variant='outlined' color='primary' loading={isProcessing} disabled={isProcessing} onClick={() => {
        if (isProcessing) return;
        onSelect(state.currentAttachment);
      }}>
					<FormattedMessage id='button.next' />
				</SubmitButton>
			</DialogActions>
		</>;
}
AttachmentTemplateSelector.propTypes = {
  onSelect: PropTypes.func,
  onCancel: PropTypes.func,
  onPreviousStep: PropTypes.func,
  attachmentType: PropTypes.string,
  isProcessing: PropTypes.bool
};