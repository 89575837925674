import * as React from 'react'

function SvgToFront(props) {
	return (
		<svg width='1em' height='1em' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M23 16l8.355 3.58c.808.347.808 1.493 0 1.84l-11.454 4.908a1 1 0 01-.8-.005L8.043 21.408c-.79-.35-.792-1.47-.004-1.826L16 16'
				stroke='#484747'
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
			<path
				d='M28.5 23l3.663 1.998a1 1 0 01-.096 1.802l-12.17 5.036a1 1 0 01-.79-.01L7.85 26.822a1 1 0 01-.073-1.791L11.5 23M19.5 21V8m3 2.5l-3-2.5m0 0l-3 2.5'
				stroke='#484747'
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
		</svg>
	)
}

export default SvgToFront
