import { SliderThumb } from '@mui/material/Slider';
import PropTypes from 'prop-types';
import { AmpSliderInteractionBase, SliderContainer, SliderTextContainer, StyledSlider } from './style';
export default function AmpSliderInteraction({
  ampInteraction
}) {
  const {
    _id,
    prompt_text,
    outer_style,
    option_color,
    theme,
    icon,
    alignment,
    option_style
  } = ampInteraction;
  function CustomThumbComponent(props) {
    const {
      children,
      ...other
    } = props;
    return <SliderThumb {...other}>
				{' '}
				{children}
				{icon}
			</SliderThumb>;
  }
  return <AmpSliderInteractionBase $backgroundColor={option_color} $isTransparent={option_style === 'transparent'} style={outer_style}>
			{/* Prompt text */}
			<SliderTextContainer $theme={theme} $alignment={alignment}>
				<p>{prompt_text}</p>
			</SliderTextContainer>

			{/* Options */}
			<SliderContainer $theme={theme} $optionStyle={option_style} $isTransparent={option_style === 'transparent'}>
				<StyledSlider slots={{
        thumb: CustomThumbComponent
      }} $optionColor={option_color} $theme={theme} valueLabelDisplay='auto' aria-label='pretto slider' defaultValue={10} />
			</SliderContainer>
		</AmpSliderInteractionBase>;
}
AmpSliderInteraction.propTypes = {
  ampInteraction: PropTypes.object
};