// Form Interaction
export const form_field_placeholder_text = {
	en: 'Type something...',
	fr: 'Tapez quelque chose...',
	es: 'Escribe algo...',
	de: 'Schreibe etwas...',
}
export const form_field_placeholder_longText = {
	en: 'Type something...',
	fr: 'Tapez quelque chose...',
	es: 'Escribe algo...',
	de: 'Schreibe etwas...',
}
export const form_field_placeholder_lastname = {
	en: 'Enter your last name',
	fr: 'Entrez votre nom de famille',
	es: 'Ingresa tu apellido',
	de: 'Geben Sie Ihren Nachnamen ein',
}
export const form_field_placeholder_firstname = {
	en: 'Enter your first name',
	fr: 'Entrez votre nom',
	es: 'Ingresa tu apellido',
	de: 'Geben Sie Ihren Nachnamen ein',
}
export const form_field_placeholder_website = {
	en: 'Enter your website',
	fr: 'Entrez votre site Web',
	es: 'Ingresa a tu sitio web',
	de: 'Geben Sie Ihre Website ein',
}
export const form_field_placeholder_email = {
	en: 'Enter your email',
	fr: 'Entrer votre Email',
	es: 'Introduce tu correo electrónico',
	de: 'Geben sie ihre E-Mail Adresse ein',
}
export const form_field_placeholder_username = {
	en: 'Enter your Username',
	fr: "Entrer votre nom d'utilisateur",
	es: 'Ingrese su nombre de usuario',
	de: 'Geben Sie Ihren Benutzernamen ein',
}
export const form_field_placeholder_phone = {
	en: 'Enter your phone',
	fr: 'Entrez votre téléphone',
	es: 'Introduce tu teléfono',
	de: 'Geben Sie Ihr Telefon ein',
}
export const form_field_placeholder_number = {
	en: 'Enter a number',
	fr: 'Entrez un nombre',
	es: 'Ingrese un numero',
	de: 'Geben Sie eine Zahl ein',
}
export const form_field_placeholder_date = {
	en: 'Enter a date',
	fr: 'entrez une date',
	es: 'introduce una fecha',
	de: 'Geben Sie ein Datum ein',
}
export const form_field_placeholder_select = {
	en: 'Choose an option',
	fr: 'Choisissez une option',
	es: 'Elige una opcion',
	de: 'Wähle eine Option',
}
export const form_field_placeholder_checkbox = {
	en: 'Subscribe to our newsletter',
	fr: 'Abonnez-vous à notre newsletter',
	es: 'Suscríbete a nuestro boletín',
	de: 'Abonniere unseren Newsletter',
}
export const form_field_placeholder_consent = {
	en: 'Subscribe to our newsletter',
	fr: 'Abonnez-vous à notre newsletter',
	es: 'Suscríbete a nuestro boletín',
	de: 'Abonniere unseren Newsletter',
}
export const form_field_label_consent = {
	en: 'Subscribe to our newsletter',
	fr: 'Abonnez-vous à notre newsletter',
	es: 'Suscríbete a nuestro boletín',
	de: 'Abonniere unseren Newsletter',
}

export const form_field_label_firstname = {
	en: 'First Name',
	fr: 'Prénom',
	es: 'Nombre',
	de: 'Vorname',
}
export const form_field_label_lastname = {
	en: 'Last Name',
	fr: 'Nom de famille',
	es: 'Apellido',
	de: 'Nachname',
}
export const form_field_label_text = {
	en: 'Text',
	fr: 'Texte',
	es: 'Texto',
	de: 'Text',
}
export const form_field_label_longText = {
	en: 'Long Text',
	fr: 'Texte long',
	es: 'Texto largo',
	de: 'Langer Text',
}
export const form_field_label_email = {
	en: 'Email',
	fr: 'Email',
	es: 'Correo electrónico',
	de: 'E-Mail',
}
export const form_field_label_phone = {
	en: 'Phone',
	fr: 'Téléphone',
	es: 'Teléfono',
	de: 'Telefon',
}
export const form_field_label_website = {
	en: 'Website',
	fr: 'Site Web',
	es: 'Sitio web',
	de: 'Website',
}
export const form_field_label_number = {
	en: 'Number',
	fr: 'Nombre',
	es: 'Número',
	de: 'Nummer',
}
export const form_field_label_date = {
	en: 'Date',
	fr: 'Date',
	es: 'Fecha',
	de: 'Datum',
}

// Rating Interaction
export const rating_title = {
	en: 'Rate us!',
	fr: 'Évaluez nous!',
	es: '¡Nos califica!',
	de: 'Bewerten Sie uns!',
}

// Media Answer Interaction
export const media_answer_title = {
	en: 'How would you like to answer?',
	fr: 'Comment voulez-vous répondre ?',
	es: '¿Cómo le gustaría responder?',
	de: 'Wie möchten Sie antworten?',
}
export const media_answer_videoButton = {
	en: 'Video',
	fr: 'Vidéo',
	es: 'Video',
	de: 'Video',
}
export const media_answer_audioButton = {
	en: 'Audio',
	fr: 'Audio',
	es: 'Audio',
	de: 'Audio',
}
export const media_answer_textButton = {
	en: 'Text',
	fr: 'Texte',
	es: 'Texto',
	de: 'Text',
}
export const media_answer_selfieButton = {
	en: 'Selfie',
	fr: 'Selfie',
	es: 'Selfie',
	de: 'Selfie',
}
export const media_answer_start_button = {
	en: 'start',
	fr: 'démarrer',
	es: 'comenzar',
	de: 'start',
}
export const media_answer_restart_button = {
	en: 'restart',
	fr: 'redémarrer',
	es: 'Reanudar',
	de: 'Neustart',
}
export const media_answer_confirm_button = {
	en: 'Confirm',
	fr: 'Valider',
	es: 'Confirmar',
	de: 'Bestätigen',
}
export const media_answer_text_placeholder = {
	en: 'Type something...',
	fr: 'Tapez quelque chose...',
	es: 'Escribe algo...',
	de: 'Schreibe etwas...',
}
export const media_answer_modify_response = {
	en: 'Modify response',
	fr: 'Modifier la réponse',
	es: 'Modificar respuesta',
	de: 'Antwort ändern',
}
export const media_answer_continue_button = {
	en: 'Continue',
	fr: 'Continuer',
	es: 'Continuar',
	de: 'Weitermachen',
}

// Game Interaction
export const game_playButton = {
	en: 'Spin the wheel',
	fr: 'Tourne la roue',
	es: 'Gira la rueda',
	de: 'Drehe das Rad',
}
export const game_nextButton = {
	en: 'Go Next',
	fr: 'Suivant',
	es: 'Próxima',
	de: 'Nächste',
}
export const game_modal_winning_header = {
	en: 'Won',
	fr: 'Gagné',
	es: 'Ganado',
	de: 'Gewonnen',
}
export const game_modal_winning_description = {
	en: 'Congratulation! You won {{prize}}',
	fr: 'Félicitations ! Vous avez gagné {{prize}}',
	es: '¡Felicitaciones! Has ganado {{prize}}',
	de: 'Herzlichen Glückwunsch! Du hast {{prize}} gewonnen',
}
export const game_modal_winning_button = {
	en: 'Link',
	fr: 'Lien',
	es: 'Enlace',
	de: 'Link',
}

export const game_modal_losing_header = {
	en: 'Lost',
	fr: 'Perdu',
	es: 'Perdido',
	de: 'Verloren',
}

export const game_modal_losing_description = {
	en: `Too bad. You'll have better luck next time!`,
	fr: 'Dommage. Vous aurez plus de chance la prochaine fois !',
	es: '¡Qué pena! ¡Tendrás mejor suerte la próxima vez!',
	de: 'Schade. Beim nächsten Mal hast du mehr Glück!',
}

export const game_modal_losing_button = {
	en: 'Link',
	fr: 'Lien',
	es: 'Enlace',
	de: 'Link',
}

export const game_modal_form_header = {
	en: 'Thank you for playing!',
	fr: "Merci d'avoir joué !",
	es: '¡Gracias por jugar!',
	de: 'Vielen Dank fürs Spielen!',
}

export const game_modal_form_description = {
	en: 'Enter your email to claim your prize.',
	fr: 'Saisissez votre adresse e-mail pour réclamer votre prix.',
	es: 'Ingresa tu correo electrónico para reclamar tu premio.',
	de: 'Gib deine E-Mail-Adresse ein, um deinen Preis einzufordern.',
}

export const game_modal_form_placeholder = {
	en: 'Enter your email',
	fr: 'Saisissez votre adresse e-mail',
	es: 'Ingresa tu correo electrónico',
	de: 'Gib deine E-Mail-Adresse ein',
}
export const game_modal_form_submitButton = {
	en: 'Submit',
	fr: 'Envoyer',
	es: 'Enviar',
	de: 'Absenden',
}

// AMP Outlink
export const amp_outlink_text = {
	en: 'Swipe up',
	fr: 'Glissez vers le haut',
	es: 'Desliza hacia arriba',
	de: 'Nach oben wischen',
}

// AMP Attachment
export const amp_attachment_cta_text = {
	en: 'Swipe up',
	fr: 'Glissez vers le haut',
	es: 'Desliza hacia arriba',
	de: 'Nach oben wischen',
}

export const rating_feedback_title = {
	en: 'Share your experience',
	fr: 'Partagez votre expérience',
	es: 'Comparte tu experiencia',
	de: 'Teilen Sie Ihre Erfahrung',
}

export const rating_feedback_placeholder = {
	en: 'In a few words...',
	fr: 'En quelques mots...',
	es: 'En pocas palabras...',
	de: 'In wenigen Worten...',
}

export const rating_feedback_submit_button = {
	en: 'Send',
	fr: 'Envoyer',
	es: 'Enviar',
	de: 'Senden',
}
