import { ModalWrapper } from 'components/Modals';
import WheelSettings from 'components/WheelSettings';
import { useStoryDispatch, useStoryState } from 'lib/editorStore';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
export default function WheelSettingsModal({
  trigger
}) {
  const ref = useRef();
  const draftStory = useStoryState(s => s.draftStory);
  const page = useStoryState(s => s.page);
  const recently_used = useStoryState(s => s.draftStory?.recently_used);
  const brand_colors = useStoryState(s => s.draftStory?.brand_colors);
  const brand_fontFamily = useStoryState(s => s.draftStory?.brand_fontFamily);
  const dispatch = useStoryDispatch();
  function onRecentColorsUpdate(color) {
    dispatch({
      type: 'SET_RECENTLY_USED',
      data: {
        path: 'colors.block',
        value: color
      }
    }, false);
  }
  function onCancel() {
    ref.current.close();
  }
  function onSubmitConfig({
    variables,
    newInteraction,
    gdprConsent,
    newModal
  }) {
    dispatch({
      type: 'UPDATE_PAGE_WHEEL',
      data: {
        newInteraction,
        newModal,
        variables,
        gdprConsent
      }
    });
    ref.current.close();
  }
  return <ModalWrapper title={<FormattedMessage id='modals.interaction_settings.form' />} trigger={trigger} disableBackdropClick hasCloseButton={false} size='lg' ref={ref} PaperProps={{
    sx: {
      height: '100%'
    }
  }}>
			<WheelSettings onCancel={onCancel} onSubmitConfig={onSubmitConfig} currentPage={page} draftStory={draftStory} recentColors={recently_used?.colors?.block} rencentFonts={recently_used?.font_families} brandColors={brand_colors} brandFontFamily={brand_fontFamily} updateRecentColor={onRecentColorsUpdate} />
		</ModalWrapper>;
}
WheelSettingsModal.propTypes = {
  trigger: PropTypes.object
};