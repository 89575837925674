import { Button, Checkbox, DialogActions, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ModalWrapper } from 'components/Modals';
import { ModalContent, ProjectsAccessWrapper } from 'components/styled/Modals';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentOrganizationId } from 'redux/organization/selectors';
import OrganizationsSvc from 'services/OrganizationsSvc';
const ManageTemplateAccessModal = forwardRef(({
  templateToUpdate,
  onProjectAccessChange,
  onConfirm
}, ref) => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const {
    data: organizationProjects = []
  } = useQuery({
    queryKey: ['projects', {
      organization_id: organizationId
    }],
    queryFn: ({
      queryKey
    }) => {
      const {
        organization_id
      } = queryKey[1];
      return OrganizationsSvc.getProjects(organization_id);
    }
  });
  return <ModalWrapper size='sm' ref={ref} title={<FormattedMessage id='organization.projects.access.title' />}>
			<ModalContent>
				<Typography variant='h3' gutterBottom>
					<FormattedMessage id='modals.templates.instruction' />
				</Typography>

				<ProjectsAccessWrapper component='fieldset'>
					<FormGroup>
						{_.map(organizationProjects.slice(0, Math.ceil(organizationProjects.length / 2)), project => <FormControlLabel key={project._id} control={<Checkbox checked={_.includes(templateToUpdate?.accessibleProjects, project._id)} name={project._id} onChange={() => onProjectAccessChange(project._id)} />} label={project.name} />)}
					</FormGroup>
					<FormGroup>
						{_.map(organizationProjects.slice(Math.ceil(organizationProjects.length / 2)), project => <FormControlLabel key={project._id} control={<Checkbox checked={_.includes(templateToUpdate?.accessibleProjects, project._id)} name={project._id} onChange={() => onProjectAccessChange(project._id)} />} label={project.name} />)}
					</FormGroup>
				</ProjectsAccessWrapper>
			</ModalContent>
			<DialogActions>
				<Button variant='text' onClick={() => ref.current?.close()}>
					<FormattedMessage id='button.cancel' />
				</Button>
				<Button variant='outlined' color='secondary' onClick={() => {
        onConfirm();
        ref.current.close();
      }}>
					<FormattedMessage id='button.confirm' />
				</Button>
			</DialogActions>
		</ModalWrapper>;
});
ManageTemplateAccessModal.propTypes = {
  templateToUpdate: PropTypes.object,
  onProjectAccessChange: PropTypes.func,
  onConfirm: PropTypes.func
};
ManageTemplateAccessModal.displayName = 'ManageTemplateAccessModal';
export default ManageTemplateAccessModal;