import * as React from 'react'

function Svg8Number(props) {
	return (
		<svg width='1em' height='1em' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M3.257 1.848a.984.984 0 01-.17.568 1.135 1.135 0 01-.454.39c.222.095.398.235.527.419.131.183.196.391.196.624 0 .369-.125.663-.375.881-.248.22-.575.329-.981.329-.41 0-.74-.11-.987-.329-.246-.22-.37-.514-.37-.881 0-.23.063-.439.188-.624.127-.186.302-.327.524-.422a1.118 1.118 0 01-.448-.39 1.005 1.005 0 01-.164-.565c0-.36.115-.645.346-.856.23-.21.534-.316.911-.316.375 0 .678.105.908.316.233.211.349.496.349.856zm-.443 1.989a.783.783 0 00-.228-.583.8.8 0 00-.592-.226c-.244 0-.44.075-.589.223-.146.148-.22.344-.22.586s.072.433.214.571c.145.139.345.208.601.208.254 0 .453-.07.598-.208.144-.14.216-.33.216-.571zM2 1.12a.701.701 0 00-.519.2c-.13.13-.196.309-.196.535 0 .217.065.393.194.528A.7.7 0 002 2.583a.69.69 0 00.519-.2.725.725 0 00.196-.527.723.723 0 00-.202-.53A.69.69 0 002 1.121z'
				fill='#000'
			/>
		</svg>
	)
}

export default Svg8Number
