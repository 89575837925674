import { Button, SvgIcon, Typography } from '@mui/material';
import styled from '@styled';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
PropertyBlockTitle.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actionTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  onActionClick: PropTypes.func,
  sx: PropTypes.object
};
export default function PropertyBlockTitle({
  title,
  actionTitle,
  icon,
  onActionClick,
  sx
}) {
  function handleClick(evt) {
    evt.preventDefault();
    onActionClick(evt);
  }
  return <Title variant='h2' sx={sx}>
			<div>
				{icon && <div className='icon'>{icon}</div>}
				{title}
			</div>
			{onActionClick && <Button color='error' variant='outlined' size='small' onClick={handleClick} startIcon={<SvgIcon>{actionTitle}</SvgIcon>}>
					<FormattedMessage id='button.delete' />
				</Button>}
		</Title>;
}
const Title = styled(Typography)`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	font-weight: 700;

	& > div:first-child {
		display: flex;
		align-items: center;

		.icon {
			padding: 5px;
		}
	}

	.MuiLink-root {
		cursor: pointer;
	}
`;