import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { ChevronLeft, Close, Delete, DragIndicator, Edit, Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, IconButton, Popover, Radio, TextField, Tooltip, Typography } from '@mui/material';
import { IOSSwitch } from 'atoms';
import cx from 'classnames';
import { ImagePreview } from 'components/atoms/AssetsPreview/MediaPreview';
import { CustomTab, CustomTabs } from 'components/atoms/CustomTabs';
import { ConfirmationModal, ImagesSelectorModal, VariablesModal } from 'components/Modals';
import PagePreview from 'components/PagePreview/index';
import { AnswerButtonSettingsWrapper, ButtonSettingHeader, ButtonSettingsContainer } from 'components/styled/Modals';
import { DEFAULT_REWARDS_CONFIG } from 'lib/constants';
import { Button as ButtonModel, Page as PageModel } from 'lib/models';
import { cleanUpCurrentPageVariables, getValidVariableTypes, isTimerDisabled, reorder, unassignPageVariable, updateVariableData, variableExists } from 'lib/utils';
import _ from 'lodash';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import ConfettiButton from 'organisms/buttons/ConfettiButton';
import Dropdown from 'organisms/Dropdown';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useReward } from 'react-rewards';
import slugify from 'slugify';
import { useImmer } from 'use-immer';
import Hotspot from './organisms/Hotspot';
const display_participants_opts = [{
  _id: '0',
  text: <FormattedMessage id='edition_menu.answer.praticipants.percentage' />,
  value: 'percentage'
}, {
  _id: '1',
  text: <FormattedMessage id='edition_menu.answer.praticipants.number' />,
  value: 'number'
}, {
  _id: '2',
  text: <FormattedMessage id='edition_menu.answer.praticipants.number_and_icon' />,
  value: 'number_and_icon'
}];
function useEmojiReward({
  enabled,
  emoji
} = {
  enabled: false,
  emoji: '👍'
}) {
  const [isFirstMount, setIsFirstMount] = useState(true);
  const {
    reward
  } = useReward('preview-emoji-reward-container-id', 'emoji', {
    ...DEFAULT_REWARDS_CONFIG,
    emoji: [emoji]
  });
  useEffect(() => {
    if (isFirstMount) {
      setIsFirstMount(false);
      return;
    }

    // Trigger reward only if emoji is enabled and not first mount
    if (enabled) reward();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, emoji]);
  return reward;
}
export default function PageAnswerButtonsSettings({
  onPreviousStep,
  onCancel,
  onSubmitConfig,
  currentPage,
  draftStory,
  buttonType = 'text',
  nextPagesOptions = []
}) {
  const intl = useIntl();
  const changeVariableRef = useRef();
  const [states, setStates] = useImmer({
    buttonType,
    currentPage: new PageModel(currentPage),
    display_live_results: draftStory.story_settings.display_live_results,
    previousTextAnswersState: undefined,
    previousImageAnswersState: undefined
  });
  const [tab, setTab] = useState('buttons');
  const [currentVariables, setVariables] = useImmer(cleanUpCurrentPageVariables({
    currentPage,
    currentVariables: draftStory.variables
  }));
  const [newVariable, setNewVariable] = useImmer({
    name: '',
    type: 'text'
  });
  const [fieldError, setFieldError] = useState(null);
  const popupStateEmojiPicker = usePopupState({
    variant: 'popover',
    popupId: `emoji-picker-page-rewards`
  });
  useEmojiReward(states.currentPage.rewards);
  const isQuiz = Boolean(draftStory.type === 'classic' && states.currentPage.answers_properties.count_in_results);
  const hasMultipleSelection = states.currentPage.answers_properties.multiple_selection;

  // Save last button type config
  useEffect(() => {
    if (states.buttonType === 'image') {
      setStates(draft => {
        draft.previousImageAnswersState = _.cloneDeep(draft.currentPage.answers);
      });
    } else if (states.buttonType === 'text') {
      setStates(draft => {
        draft.previousTextAnswersState = _.cloneDeep(draft.currentPage.answers);
      });
    }
  }, [setStates, states.currentPage.answers, states.buttonType]);
  function handleChangeButtonType({
    target: {
      value: buttonType
    }
  }) {
    if (!['image', 'text'].includes(buttonType)) {
      return;
    }
    setStates(draft => {
      draft.buttonType = buttonType;
      draft.currentPage.setAnswerButtonType(buttonType);

      // Restore saved state if it exists
      if (buttonType === 'image' && draft.previousImageAnswersState) {
        draft.currentPage.answers = draft.previousImageAnswersState;
      }
      if (buttonType === 'text' && draft.previousTextAnswersState) {
        draft.currentPage.answers = draft.previousTextAnswersState;
      }
    });
  }
  function handleToggleLiveResults() {
    setStates(draft => {
      draft.display_live_results = !draft.display_live_results;
    });
  }
  function handleChangeAnswerProperties(path, value) {
    setStates(draft => {
      draft.currentPage.answers_properties[path] = value;
    });
  }
  function handleToggleMultipleSelection(checked) {
    if (checked) {
      setStates(draft => {
        draft.currentPage.answers_properties.multiple_selection = checked;
        for (const answer of draft.currentPage.answers) {
          answer.payload.next_page = 'next';
          answer.rewards.enabled = false;
        }
        if (draft.currentPage.answers_properties.other_answer) {
          draft.currentPage.answers_properties.other_answer.payload.next_page = 'next';
        }
      });
    } else {
      setStates(draft => {
        draft.currentPage.answers_properties.multiple_selection = checked;
      });
    }
  }
  function handleChangeMultipleSelectionRedirection(value) {
    setStates(draft => {
      for (const answer of draft.currentPage.answers) {
        answer.payload.next_page = value;
      }
      if (draft.currentPage.answers_properties.other_answer) {
        draft.currentPage.answers_properties.other_answer.payload.next_page = value;
      }
    });
  }
  function handleChangePageRewards(path, value) {
    setStates(draft => {
      draft.currentPage.rewards[path] = value;
    });
  }
  function handleToggleOtherAnswer(checked) {
    if (checked) {
      setStates(draft => {
        const otherAnswer = new ButtonModel().setType('other_answer').setTitle(intl.formatMessage({
          id: 'edition_menu.answer.other_answer.title'
        }));
        draft.currentPage.answers_properties.other_answer = otherAnswer;
      });
    } else {
      setStates(draft => {
        draft.currentPage.answers_properties.other_answer = false;
      });
    }
  }
  function handleChangeOtherAnswerProperties(path) {
    return ({
      target: {
        value
      }
    }) => {
      setStates(draft => {
        if (!(draft.currentPage.answers_properties.other_answer instanceof ButtonModel)) {
          draft.currentPage.answers_properties.other_answer = new ButtonModel();
        }
        draft.currentPage.answers_properties.other_answer.setButtonProperties(path, value);
      });
    };
  }
  function handleQuizModeToggle({
    target: {
      checked
    }
  }) {
    setStates(draft => {
      if (checked) {
        draft.currentPage.enableQuizMode();
      } else {
        draft.currentPage.disableQuizMode();
      }
    });
  }
  function handleChoiceLettersToggle({
    target: {
      checked
    }
  }) {
    setStates(draft => {
      if (checked) {
        draft.currentPage.answers_properties.has_choice_letters = true;
      } else {
        draft.currentPage.answers_properties.has_choice_letters = false;
      }
    });
  }
  function handleResponsePercentageToggle({
    target: {
      checked
    }
  }) {
    setStates(draft => {
      if (checked) {
        draft.currentPage.answers_properties.count_response_percentage = true;
      } else {
        draft.currentPage.answers_properties.count_response_percentage = false;
      }
    });
    if (checked) {
      // Disable other answer
      handleToggleOtherAnswer(false);
    }
  }
  function handleChangeParticipantDisplay({
    target: {
      value
    }
  }) {
    setStates(draft => {
      draft.currentPage.answers_properties.participant_display = value;
    });
  }

  // Variables

  function openVariablesModal() {
    changeVariableRef.current.open();
  }
  function handleNewVariableNameChange(value) {
    setFieldError(null);
    const slugValue = slugify(value, {
      remove: /[*+~%\<>§/;`=.(){}?,'"!:@#^|]/g
    });
    setNewVariable(draft => {
      draft.name = slugValue;
    });
  }
  function handleNewVariableTypeChange(value) {
    setNewVariable(draft => {
      draft.type = value;
    });
  }
  function addVariable() {
    if (_.isEmpty(newVariable.name)) {
      return setFieldError('Variable cannot be empty!');
    }
    if (variableExists({
      name: newVariable.name,
      currentVariables
    })) {
      return setFieldError('Variable already exists !');
    }
    setVariables(draft => {
      draft.push({
        ...newVariable,
        default: false,
        isUsed: false
      });
    });
    setNewVariable(draft => {
      draft.name = '';
      draft.type = 'text';
    });
    setFieldError(null);
  }
  function removeVariable(name) {
    const index = currentVariables.findIndex(variable => variable.name === name && !variable.default);
    if (index >= 0) {
      setVariables(draft => {
        draft.splice(index, 1);
      });
    }
  }
  function unassignVariable(name) {
    const idx = currentVariables.findIndex(variable => variable.name === name);
    if (idx >= 0) {
      setVariables(draft => {
        draft[idx] = unassignPageVariable(currentVariables[idx], states.currentPage._id);
      });
    }
    setStates(draft => {
      draft.currentPage.variable = '';
    });
  }
  function assignVariable(name) {
    if (states.currentPage?.variable) {
      const index = currentVariables.findIndex(variable => variable.name === states.currentPage?.variable.variable);
      if (index >= 0) {
        setVariables(draft => {
          draft[index] = unassignPageVariable(currentVariables[index], states.currentPage._id);
        });
      }
    }
    const idx = currentVariables.findIndex(variable => variable.name === name);
    if (idx >= 0) {
      setVariables(draft => {
        draft[idx] = updateVariableData(currentVariables[idx], {
          page: {
            _id: states.currentPage._id,
            type: states.currentPage.type
          }
        });
      });
    }
    setStates(draft => {
      draft.currentPage.variable = name;
    });
    setNewVariable(draft => {
      draft.name = '';
      draft.type = 'text';
    });
    setFieldError(null);
    changeVariableRef.current.close();
  }
  function onCloseVariablesModal() {
    setNewVariable(draft => {
      draft.name = '';
      draft.type = 'text';
    });
    setFieldError(null);
  }
  // ------------------------------------

  function handleSubmit() {
    const newPage = _.cloneDeep(states.currentPage);

    // Disable timer if condition met
    if (newPage?.timer?.enabled && isTimerDisabled(newPage)) {
      newPage.timer.enabled = false;
    }
    onSubmitConfig({
      new_page: newPage,
      variables: currentVariables,
      display_live_results: states.display_live_results
    });
  }
  return <>
			<AnswerButtonSettingsWrapper>
				{/* PREVIEW Section */}
				<Box width='100%' height='100%' display='flex' justifyContent='center' alignItems='center' overflow='hidden'>
					<PagePreview size='large' page={states.currentPage} theming={draftStory.theming} messages={draftStory.messages} gdprConsent={draftStory?.gdprConsent} storyType={draftStory.type} isRewardsEnabled />
				</Box>

				{/* SETTINGS Section */}
				<Box display='flex' flexDirection='column' overflow='hidden'>
					<Box sx={{
          width: '95%',
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          alignSelf: 'flex-start',
          alignItems: 'center',
          columnGap: 7,
          mb: 2
        }}>
						<Box sx={{
            gridRow: '1 / 2',
            gridColumn: '1 / 2',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2
          }}>
							<Box display='flex' alignItems='center' sx={{
              gap: '4px'
            }}>
								<Typography variant='h2'>
									<FormattedMessage id='edition_menu.answer.count_in_results' />
								</Typography>
								<Hotspot title='edition_menu.quiz_mode.title' message='edition_menu.quiz_mode.description' />
							</Box>

							<IOSSwitch checked={isQuiz ?? false} onChange={handleQuizModeToggle} color='primary' />
						</Box>

						{isQuiz && <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            justifyContent: 'space-between'
          }}>
								<Box>
									<Typography variant='h2'>
										<FormattedMessage id='edition_menu.answer.display_results.quiz' />
									</Typography>
								</Box>

								<IOSSwitch checked={states.display_live_results ?? false} onChange={handleToggleLiveResults} color='primary' />
							</Box>}

						<Box sx={{
            gridRow: '2 / 3',
            gridColumn: '1 / 2'
          }}>
							<Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2
            }}>
								<Typography variant='h2'>
									<FormattedMessage id='edition_menu.answer.count_response_percentage' />
								</Typography>

								<IOSSwitch checked={states.currentPage.answers_properties.count_response_percentage ?? false} onChange={handleResponsePercentageToggle} color='primary' />
							</Box>
							{states.currentPage?.answers_properties?.count_response_percentage && <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2
            }}>
									<Typography width='300px' variant='h2'>
										<FormattedMessage id='edition_menu.answer.praticipants.title' />
									</Typography>

									<Dropdown fullWidth={false} onChange={handleChangeParticipantDisplay} options={display_participants_opts} value={states.currentPage?.answers_properties?.participant_display || 'percentage'} />
								</Box>}
						</Box>

						{/* CHOICE LETTERS */}
						<Box sx={{
            gridRow: '2 / 3',
            gridColumn: '2 / 3',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2
          }}>
							<Box>
								<Typography variant='h2'>
									<FormattedMessage id='edition_menu.answer.display_choice_letters' />
								</Typography>
							</Box>

							<IOSSwitch checked={states.currentPage.answers_properties?.has_choice_letters ?? true} onChange={handleChoiceLettersToggle} color='primary' />
						</Box>
					</Box>

					<Box display='flex' alignItems='center' gap={1}>
						<CustomTabs value={tab} indicatorColor='secondary' textColor='primary' onChange={(evt, value) => setTab(value)} sx={{
            width: '500px!important'
          }}>
							<CustomTab label={<FormattedMessage id='common.buttons' />} value='buttons' />
							<CustomTab label={<FormattedMessage id='edition_menu.form.field_description.advanced_settings' />} value='advanced-settings' />
						</CustomTabs>
					</Box>

					<Box sx={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          px: 1,
          py: 3,
          rowGap: 2,
          borderLeft: '1px solid',
          borderColor: 'divider',
          minHeight: '300px'
        }}>
						{tab === 'advanced-settings' && <>
								<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' px={2}>
									<Box>
										<Typography variant='h2'>
											<FormattedMessage id='edition_menu.answer.multiple_selection' />
										</Typography>
										<Typography variant='body1'>
											<FormattedMessage id='edition_menu.answer.multiple_selection.description' />
										</Typography>
									</Box>

									<IOSSwitch checked={hasMultipleSelection} onChange={({
                target: {
                  checked
                }
              }) => handleToggleMultipleSelection(checked)} color='primary' />
								</Box>

								{hasMultipleSelection && !isQuiz && <Box display='grid' gridTemplateColumns='2fr repeat(2, 1fr)' gap={2} alignItems='center' className='properties-container'>
										<Typography variant='h3'>
											<FormattedMessage id='edition_menu.answer_config.header.redirect_to' />
										</Typography>
										<Typography variant='h3'>
											<FormattedMessage id='edition_menu.answer_config.header.limit_choices' />
										</Typography>
										<Typography variant='h3'>
											<FormattedMessage id='edition_menu.answer_config.header.confirm_emoji' />
										</Typography>

										<Dropdown onChange={({
                target: {
                  value
                }
              }) => handleChangeMultipleSelectionRedirection(value)} variant='outlined' placeholder={intl.formatMessage({
                id: 'edition_menu.navigation.your_pages'
              })} options={nextPagesOptions} value={_.head(states.currentPage.answers)?.payload?.next_page} />

										<Box display='grid' gridTemplateColumns='1fr 1fr' gap={1} alignItems='center'>
											<IOSSwitch checked={states.currentPage.answers_properties.limit_answers} onChange={({
                  target: {
                    checked
                  }
                }) => handleChangeAnswerProperties('limit_answers', checked)} color='primary' />

											{states.currentPage.answers_properties.limit_answers && <TextField type='number' variant='outlined' value={states.currentPage.answers_properties.limit_answers_count} onChange={({
                  target: {
                    value
                  }
                }) => handleChangeAnswerProperties('limit_answers_count', value)} />}
										</Box>

										<MultipleChoiceConfirmReward states={states} popupStateEmojiPicker={popupStateEmojiPicker} handleChangePageRewards={handleChangePageRewards} />
									</Box>}

								{hasMultipleSelection && isQuiz && <Box className='properties-container'>
										<Box display='grid' gridTemplateColumns='2fr repeat(2, 1fr)' gap={2} alignItems='center'>
											<Box display='flex' alignItems='center' justifyContent='flex-start'>
												<Typography variant='h3'>
													<FormattedMessage id='edition_menu.answer.partial_correct' />
												</Typography>
												<Box ml={1}>
													<Hotspot title='edition_menu.quizz.answers.partial_correct.title' message='edition_menu.quizz.answers.partial_correct.message' />
												</Box>
											</Box>
											<Typography variant='h3'>
												<FormattedMessage id='edition_menu.answer_config.header.limit_choices' />
											</Typography>
											<Typography variant='h3'>
												<FormattedMessage id='edition_menu.answer_config.header.confirm_emoji' />
											</Typography>

											<IOSSwitch checked={states.currentPage.answers_properties.partial_correct} onChange={({
                  target: {
                    checked
                  }
                }) => handleChangeAnswerProperties('partial_correct', checked)} color='primary' />

											<Box display='grid' gridTemplateColumns='1fr 1fr' gap='4px' alignItems='center'>
												<IOSSwitch checked={states.currentPage.answers_properties.limit_answers} onChange={({
                    target: {
                      checked
                    }
                  }) => handleChangeAnswerProperties('limit_answers', checked)} color='primary' />

												{states.currentPage.answers_properties.limit_answers && <TextField type='number' variant='outlined' value={states.currentPage.answers_properties.limit_answers_count} onChange={({
                    target: {
                      value
                    }
                  }) => handleChangeAnswerProperties('limit_answers_count', value)} />}
											</Box>

											<MultipleChoiceConfirmReward states={states} popupStateEmojiPicker={popupStateEmojiPicker} handleChangePageRewards={handleChangePageRewards} />
										</Box>

										<Box display='grid' gridTemplateColumns='auto 1fr' gap={1} alignItems='center' mt={2}>
											<Box mb={0.5} className='redirect-title'>
												<FormattedMessage id='edition_menu.answer.quiz_redirect_start' />
												<span className='correct-answer'>
													<FormattedMessage id='edition_menu.answer.quiz_redirect_correct' />
												</span>
												<FormattedMessage id='edition_menu.answer.quiz_redirect_end' />
											</Box>
											<Dropdown variant='outlined' onChange={({
                  target
                }) => handleChangeAnswerProperties('correct_redirect', target.value)} placeholder={intl.formatMessage({
                  id: 'edition_menu.navigation.your_pages'
                })} options={nextPagesOptions} value={states.currentPage.answers_properties?.correct_redirect} />

											<Box mb={0.5} className='redirect-title'>
												<FormattedMessage id='edition_menu.answer.quiz_redirect_start_next' />
												<span className='incorrect-answer'>
													<FormattedMessage id='edition_menu.answer.quiz_redirect_incorrect' />
												</span>
												<FormattedMessage id='edition_menu.answer.quiz_redirect_end' />
											</Box>
											<Dropdown variant='outlined' onChange={({
                  target
                }) => handleChangeAnswerProperties('incorrect_redirect', target.value)} placeholder={intl.formatMessage({
                  id: 'edition_menu.navigation.your_pages'
                })} options={nextPagesOptions} value={states.currentPage.answers_properties?.incorrect_redirect} />
										</Box>
										{states.currentPage.answers_properties.partial_correct && <Box display='grid' gridTemplateColumns='1.5fr 1fr' gap='4px' alignItems='center' mb={1} className='quizz-answer-redirection'>
												<Box mb={0.5} className='redirect-title'>
													<FormattedMessage id='edition_menu.answer.quiz_redirect_start_next' />
													<span className='partial-answer'>
														<FormattedMessage id='edition_menu.answer.quiz_redirect_partial' />
													</span>
													<FormattedMessage id='edition_menu.answer.quiz_redirect_end' />
												</Box>
												<Dropdown variant='outlined' onChange={({
                  target
                }) => handleChangeAnswerProperties('partial_redirect', target.value)} placeholder={intl.formatMessage({
                  id: 'edition_menu.navigation.your_pages'
                })} options={nextPagesOptions} value={states.currentPage.answers_properties?.partial_redirect} />
											</Box>}
									</Box>}

								{/* VARIABLE SETTINGS */}
								<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' flexWrap='wrap' px={2}>
									<Box width='70%'>
										<Typography variant='h2'>Variable</Typography>
										<Typography variant='body1'>
											<FormattedMessage id='edition_menu.answer.variable.description' />
										</Typography>
									</Box>

									{states.currentPage?.variable ? <Box sx={{
                display: 'flex',
                alignItems: 'center',
                p: 0.5,
                m: 1,
                border: '0.5px solid rgb(72, 72, 71)',
                borderRadius: 1
              }}>
											<Typography variant='h3' sx={{
                  mx: 0.5
                }}>
												{states.currentPage?.variable}
											</Typography>
											<IconButton size='small' onClick={openVariablesModal}>
												<Edit />
											</IconButton>
											<IconButton size='small' onClick={() => unassignVariable(states.currentPage?.variable)}>
												<Close />
											</IconButton>
										</Box> : <Button variant='contained' color='info' size='small' onClick={openVariablesModal} sx={{
                flexShrink: 0,
                m: 1
              }}>
											<FormattedMessage id='edition_menu.variables.add' values={{
                  count: 1
                }} />
										</Button>}
								</Box>
							</>}

						{tab === 'buttons' && <>
								<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' px={1}>
									<Box>
										<Typography variant='h2'>
											<FormattedMessage id='edition_menu.answer.required_answer' />
										</Typography>
										<Typography variant='body1'>
											<FormattedMessage id='edition_menu.answer.required_answer.description' />
										</Typography>
									</Box>

									<IOSSwitch checked={states.currentPage.answers_properties.required_answer ?? false} onChange={({
                target: {
                  checked
                }
              }) => handleChangeAnswerProperties('required_answer', checked)} color='primary' />
								</Box>

								<Box display='flex' alignItems='center' px={1} mb={1}>
									<Typography variant='h2' sx={{
                mr: 6
              }}>
										<FormattedMessage id='edition_menu.answer_config.button_type.title' />
									</Typography>

									<FormControlLabel control={<Radio checked={states.buttonType === 'text'} value='text' onChange={handleChangeButtonType} />} label={intl.formatMessage({
                id: 'edition_menu.answer_config.button_type.type.text'
              })} labelPlacement='end' />
									<FormControlLabel control={<Radio checked={states.buttonType === 'image'} value='image' onChange={handleChangeButtonType} />} label={intl.formatMessage({
                id: 'edition_menu.answer_config.button_type.type.image'
              })} labelPlacement='end' />
								</Box>

								<ButtonsList setStates={setStates} states={states} isQuiz={isQuiz} hasMultipleSelection={hasMultipleSelection} nextPagesOptions={nextPagesOptions} />

								{!isQuiz && <>
										<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' px={1}>
											<Box>
												<Typography variant='h2'>
													<FormattedMessage id='edition_menu.answer.other_answer' />
												</Typography>
												<Typography variant='body1'>
													<FormattedMessage id='edition_menu.answer.other_answer.description' />
												</Typography>
											</Box>

											<IOSSwitch checked={!!states.currentPage.answers_properties.other_answer} onChange={({
                  target: {
                    checked
                  }
                }) => handleToggleOtherAnswer(checked)} color='primary' />
										</Box>

										{Boolean(states.currentPage.answers_properties.other_answer) && <Box className='properties-container'>
												<Box sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  columnGap: 2,
                  rowGap: 1,
                  alignItems: 'center',
                  alignItems: 'start'
                }}>
													<Typography variant='h3' sx={{
                    fontWeight: 'bold'
                  }}>
														<FormattedMessage id='edition_menu.answer_config.header.title' />
													</Typography>
													<Typography variant='h3' sx={{
                    fontWeight: 'bold'
                  }}>
														<FormattedMessage id='edition_menu.answer_config.header.redirect_to' />
													</Typography>

													<TextField fullWidth variant='outlined' value={states.currentPage.answers_properties.other_answer.title} onChange={handleChangeOtherAnswerProperties('title')} error={_.isEmpty(states.currentPage.answers_properties.other_answer.title)} helperText={_.isEmpty(states.currentPage.answers_properties.other_answer.title) && <FormattedMessage id='edition_menu.answer.title.error' />} />

													{hasMultipleSelection ? <Typography variant='body2'>
															<FormattedMessage id='edition_menu.answer_config.header.multiple_redirect' />
														</Typography> : <Dropdown onChange={handleChangeOtherAnswerProperties('payload.next_page')} variant='outlined' placeholder={intl.formatMessage({
                    id: 'edition_menu.navigation.your_pages'
                  })} options={nextPagesOptions} value={states.currentPage.answers_properties.other_answer.payload.next_page} />}
												</Box>
											</Box>}
									</>}
							</>}
					</Box>
				</Box>
			</AnswerButtonSettingsWrapper>

			<VariablesModal ref={changeVariableRef} currentVariables={currentVariables} currentVariable={states.currentPage?.variable} allowType={getValidVariableTypes('page', states.currentPage)} assignVariable={assignVariable} removeVariable={removeVariable} addNewVariable={addVariable} newVariable={newVariable} handleChangeNewVariableName={handleNewVariableNameChange} handleChangeNewVariableType={handleNewVariableTypeChange} fieldError={fieldError} onClose={onCloseVariablesModal} />

			<Box sx={{
      py: 2,
      px: 6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: 2
    }}>
				{onPreviousStep ? <Button variant='text' onClick={onPreviousStep} startIcon={<ChevronLeft />}>
						<FormattedMessage id='button.previous' />
					</Button> : onCancel ? <Button variant='text' onClick={onCancel}>
						<FormattedMessage id='button.cancel' />
					</Button> : <div />}

				<Button data-intercom-target='Confirm Button' variant='contained' disabled={confirmIsDiabled(states.buttonType, states.currentPage.answers_properties, states.currentPage.answers)} onClick={handleSubmit} sx={{
        mr: 4
      }}>
					<FormattedMessage id='common.theming.confirm_button' />
				</Button>
			</Box>
		</>;
}
PageAnswerButtonsSettings.propTypes = {
  onPreviousStep: PropTypes.func,
  onCancel: PropTypes.func,
  currentPage: PropTypes.object,
  draftStory: PropTypes.object,
  buttonType: PropTypes.string,
  nextPagesOptions: PropTypes.array
};
function ButtonsList({
  setStates,
  states,
  isQuiz,
  hasMultipleSelection,
  nextPagesOptions
}) {
  const intl = useIntl();
  const confirmationRef = useRef();
  const [nextPage, setNextPage] = useState('next');
  const [currentAnswerId, setCurrentAnswerId] = useState();
  const onAnswersDragEnd = useCallback(result => {
    if (!result.destination) {
      return;
    }
    setStates(draft => {
      draft.currentPage.answers = reorder(draft.currentPage.answers, result.source.index, result.destination.index);
    });
  }, [setStates]);
  const handleChangeAnswerTitle = useCallback((_id, value) => {
    setStates(draft => {
      const answer = _.find(draft.currentPage.answers, {
        _id
      });
      if (answer) answer.title = value;
    });
  }, [setStates]);
  const handleChangeAnswerPayload = useCallback((_id, path, value) => {
    setStates(draft => {
      const answer = _.find(draft.currentPage.answers, {
        _id
      });
      if (answer) answer.payload[path] = value;
    });
  }, [setStates]);
  const handleApplyNextToAll = useCallback(value => {
    setStates(draft => {
      for (const answer of draft.currentPage.answers) {
        answer.payload.next_page = value;
      }
    });
  }, [setStates]);
  const handleChangeAnswerRewards = useCallback((_id, path, value) => {
    setStates(draft => {
      const answer = _.find(draft.currentPage.answers, {
        _id
      });
      if (answer) answer.rewards[path] = value;
    });
  }, [setStates]);
  const handleAddAnswer = useCallback(() => {
    setStates(draft => {
      const newAnswer = new ButtonModel().setType(draft.buttonType);
      draft.currentPage.answers.push(newAnswer);
    });
  }, [setStates]);
  const handleRemoveAnswer = useCallback(_id => {
    setStates(draft => {
      _.remove(draft.currentPage.answers, {
        _id
      });
    });
  }, [setStates]);
  const onSelect = useCallback(answerId => ({
    url
  }) => {
    handleChangeAnswerPayload(answerId, 'image_url', url);
  }, [handleChangeAnswerPayload]);
  const onUploadSuccess = useCallback(answerId => uploadedFiles => {
    if (uploadedFiles.length === 1) {
      const {
        _id: file_id,
        url,
        name,
        instagramId
      } = uploadedFiles[0];
      handleChangeAnswerPayload(answerId, 'image_url', url);
    }
  }, [handleChangeAnswerPayload]);
  function onOpenConfirmModal(answerId, nextPage) {
    setCurrentAnswerId(answerId);
    setNextPage(nextPage);
    confirmationRef.current.open();
  }
  function onConfirmRedirect() {
    handleChangeAnswerPayload(currentAnswerId, 'next_page', nextPage);
    confirmationRef.current.close();
  }
  function onApplyToAll() {
    handleApplyNextToAll(nextPage);
    confirmationRef.current.close();
  }
  return <>
			<ButtonSettingHeader $isCounted={isQuiz}>
				<Box minWidth='24px'>
					{isQuiz && <Typography variant='h3' style={{
          textAlign: 'center',
          width: '80px'
        }}>
							<FormattedMessage id='edition_menu.answer.state.correct' />
						</Typography>}
				</Box>
				{states.buttonType === 'image' ? <Box sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 35%',
        flexShrink: 0,
        gap: 0.5,
        placeItems: 'center'
      }}>
						<Typography variant='h3'>
							<FormattedMessage id='edition_menu.answer_config.header.title' />
						</Typography>
						<Typography variant='h3'>Image</Typography>
					</Box> : <Typography variant='h3'>
						<FormattedMessage id='edition_menu.answer_config.header.title' />
					</Typography>}
				<Typography variant='h3'>
					<FormattedMessage id='edition_menu.answer_config.header.emoticone' />
				</Typography>
				<Typography variant='h3'>
					<FormattedMessage id='edition_menu.answer_config.header.redirect_to' />
				</Typography>
				<div />
			</ButtonSettingHeader>

			<DragDropContext onDragEnd={onAnswersDragEnd}>
				<Droppable droppableId='droppable'>
					{(provided, snapshot) => <div {...provided.droppableProps} ref={provided.innerRef}>
							{_.map(states.currentPage.answers, (answer, index) => <Draggable key={answer._id} draggableId={answer._id} index={index}>
									{(provided, snapshot) => <ButtonSettingsContainer key={answer._id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={cx({
              correct: isQuiz && answer?.payload?.correct,
              dragging: snapshot.isDragging,
              isHidden: answer?.payload?.isHidden
            })}>
											<Box display='flex' alignItems='center'>
												<DragIndicator />

												{/* Answer Correct Status */}
												{isQuiz && <Checkbox checked={answer.payload.correct} onChange={({
                  target: {
                    checked
                  }
                }) => handleChangeAnswerPayload(answer._id, 'correct', checked)} />}
											</Box>

											{/* BUTTON PAYLOAD */}
											{states.buttonType === 'text' ? <TextField fullWidth multiline size='small' variant='outlined' value={answer.title} onChange={evt => handleChangeAnswerTitle(answer._id, evt.target.value)} error={_.isEmpty(answer.title)} helperText={_.isEmpty(answer.title) && <FormattedMessage id='edition_menu.answer.title.error' />} /> : <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 35%',
                flexShrink: 0,
                gap: 0.5,
                placeItems: 'center'
              }}>
													<TextField fullWidth multiline size='small' variant='outlined' value={answer.title} onChange={evt => handleChangeAnswerTitle(answer._id, evt.target.value)} />
													<ImagesSelectorModal trigger={<ImagePreview inputValue={answer.payload.image_url} sx={{
                  height: '80px',
                  width: '100%'
                }} />} inputValue={answer.payload.image_url} onSelect={onSelect(answer._id)} onUploadSuccess={onUploadSuccess(answer._id)} />
												</Box>}

											{/* ANSWER EMOJI */}
											<Box sx={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 0.5,
                alignItems: 'center'
              }}>
												<Tooltip title={hasMultipleSelection ? <FormattedMessage id='edition_menu.answer_config.header.emoticone.tooltip_multipleSelection' /> : <FormattedMessage id='edition_menu.answer_config.header.emoticone.tooltip' />} placement='bottom'>
													<div style={{
                    cursor: hasMultipleSelection ? 'not-allowed' : undefined
                  }}>
														<IOSSwitch checked={!!answer.rewards?.enabled} onChange={({
                      target: {
                        checked
                      }
                    }) => handleChangeAnswerRewards(answer._id, 'enabled', checked)} color='primary' disabled={hasMultipleSelection} />
													</div>
												</Tooltip>

												<EmojiPopup key={answer._id} answer={answer} handleChangeAnswerRewards={handleChangeAnswerRewards} />
											</Box>

											{/* REDIRECTION */}
											{hasMultipleSelection ? <Typography variant='body2'>
													<FormattedMessage id='edition_menu.answer_config.header.multiple_redirect' />
												</Typography> : <Dropdown onChange={({
                target: {
                  value
                }
              }) => onOpenConfirmModal(answer._id, value)} variant='outlined' placeholder={intl.formatMessage({
                id: 'edition_menu.navigation.your_pages'
              })} options={nextPagesOptions} value={answer.payload.next_page} />}

											<Box>
												<Tooltip title={states.currentPage.answers.length > 1 ? <FormattedMessage id='button.delete' /> : <FormattedMessage id='edition_menu.quizz.answers.delete_error' />} placement='bottom'>
													<div>
														<IconButton size='small' disabled={states.currentPage.answers.length <= 1} onClick={() => handleRemoveAnswer(answer._id)}>
															<Delete fontSize='small' />
														</IconButton>
													</div>
												</Tooltip>

												<Tooltip title={answer?.payload?.isHidden ? <FormattedMessage id='edition_menu.answer.isHidden' /> : <FormattedMessage id='edition_menu.answer.isVisible' />} placement='bottom'>
													<div>
														{answer?.payload?.isHidden ? <IconButton size='small' disabled={states.currentPage.answers.length <= 1} onClick={() => handleChangeAnswerPayload(answer._id, 'isHidden', false)}>
																<VisibilityOff fontSize='small' />
															</IconButton> : <IconButton size='small' disabled={states.currentPage.answers.length <= 1} onClick={() => handleChangeAnswerPayload(answer._id, 'isHidden', true)}>
																<Visibility fontSize='small' />
															</IconButton>}
													</div>
												</Tooltip>
											</Box>
										</ButtonSettingsContainer>}
								</Draggable>)}
							{provided.placeholder}
						</div>}
				</Droppable>
			</DragDropContext>

			<Button variant='contained' color='secondary' onClick={handleAddAnswer} sx={{
      mx: 'auto'
    }}>
				<FormattedMessage id='edition_menu.answer.add_button.answer' />
			</Button>

			<ConfirmationModal size='sm' confirmationModalRef={confirmationRef} title={<FormattedMessage id='edition_menu.modal.nextPage.title' />} onConfirm={onConfirmRedirect} onCancel={onApplyToAll} firstLine={<FormattedMessage id='edition_menu.modal.nextPage.description' />} confirmText={<FormattedMessage id='edition_menu.modal.nextPage.btn_only' />} cancelText={<FormattedMessage id='edition_menu.modal.nextPage.to_all' />} />
		</>;
}
function MultipleChoiceConfirmReward({
  states,
  popupStateEmojiPicker,
  handleChangePageRewards
}) {
  return <Box display='grid' gridTemplateColumns='1fr 1fr' gap={1} alignItems='center'>
			<IOSSwitch checked={!!states.currentPage.rewards?.enabled} onChange={({
      target: {
        checked
      }
    }) => handleChangePageRewards('enabled', checked)} color='primary' />

			{!!states.currentPage.rewards?.enabled && <>
					<Tooltip title={<FormattedMessage id='edition_menu.amp_interaction.tooltip.confetti' />} placement='bottom'>
						<ConfettiButton {...bindTrigger(popupStateEmojiPicker)}>
							<Typography variant='h3' component='label' style={{
            fontSize: '28px'
          }}>
								{states.currentPage.rewards.emoji}
							</Typography>
						</ConfettiButton>
					</Tooltip>
					<Popover {...bindPopover(popupStateEmojiPicker)} anchorOrigin={{
        vertical: 'center',
        horizontal: 'left'
      }} transformOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}>
						<Box width='280px'>
							<Picker data={data} onEmojiSelect={emoji => {
            handleChangePageRewards('emoji', emoji.native);
            popupStateEmojiPicker.close();
          }} perLine={7} exceptEmojis={['recent']} />
						</Box>
					</Popover>
				</>}
		</Box>;
}
function EmojiPopup({
  answer,
  handleChangeAnswerRewards
}) {
  const popupStateEmojiPicker = usePopupState({
    variant: 'popover',
    popupId: `emoji-picker-${answer._id}`
  });
  useEmojiReward(answer.rewards);
  return answer?.rewards?.enabled && <>
				<Tooltip title={<FormattedMessage id='edition_menu.amp_interaction.tooltip.confetti' />} placement='bottom'>
					<ConfettiButton {...bindTrigger(popupStateEmojiPicker)}>
						<Typography variant='h3' component='label' style={{
          fontSize: '28px'
        }}>
							{answer.rewards.emoji}
						</Typography>
					</ConfettiButton>
				</Tooltip>
				<Popover {...bindPopover(popupStateEmojiPicker)} anchorOrigin={{
      vertical: 'center',
      horizontal: 'left'
    }} transformOrigin={{
      vertical: 'center',
      horizontal: 'center'
    }}>
					<Box width='280px'>
						<Picker onEmojiSelect={emoji => {
          handleChangeAnswerRewards(answer._id, 'emoji', emoji.native);
          popupStateEmojiPicker.close();
        }} perLine={7} exceptEmojis={['recent']} />
					</Box>
				</Popover>
			</>;
}
EmojiPopup.propTypes = {
  answer: PropTypes.object,
  handleChangeAnswerRewards: PropTypes.func
};
function confirmIsDiabled(buttonType, answers_properties, answers) {
  if (answers_properties.other_answer) {
    if (_.isEmpty(answers_properties?.other_answer?.title)) {
      return true;
    }
  }
  if (buttonType === 'text') {
    if (_.some(answers, answer => _.isEmpty(answer.title))) {
      return true;
    }
  }
  if (buttonType === 'image') {
    if (_.some(answers, answer => _.isEmpty(answer.payload?.image_url))) {
      return true;
    }
  }
  return false;
}