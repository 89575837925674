import { Box, Checkbox, Typography } from '@mui/material';
import { WHEEL_POINTER_SHAPE, WHEEL_POINTER_TRANSPARENT } from 'lib/constants';
import cloneDeep from 'lodash/cloneDeep';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FieldConsent, StyledSystemButton } from '../../styled';
import { FormInputModal, SnackeetWheel, SpinWheelButton, WheelModal } from './WheelComponent.style';
const Wheel = dynamic(() => import('react-custom-roulette').then(mod => mod.Wheel), {
  ssr: false
});
function WheelComponent({
  components,
  pageModal,
  theming,
  gdprConsent,
  wheelComponentState = 'options'
}) {
  const {
    wheel,
    button,
    button_next,
    button_submit = {},
    button_link = {},
    pointer = {}
  } = components;
  const labelStyle = convertFieldstyle(theming?.labels);
  const buttonStyle = theming?.buttons;
  const [mustSpin, setMustSpin] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  function handleSpinClick() {
    setPrizeNumber(weighted_random(wheel.properties.prizes));
    setMustSpin(true);
    setIsDisabled(true);
  }
  function weighted_random(options) {
    let i;
    const weights = [];
    for (i = 0; i < options.length; i++) {
      weights[i] = options[i].weight + (weights[i - 1] || 0);
    }
    const random = Math.random() * weights[weights.length - 1];
    for (i = 0; i < weights.length; i++) {
      if (weights[i] > random) {
        break;
      }
    }
    return i;
  }
  return <>
			<SnackeetWheel>
				<Box maxHeight={320} maxWidth={320}>
					<Wheel prizeNumber={prizeNumber} mustStartSpinning={mustSpin}
        // onStopSpinning={handleStopSpin}
        data={wheel.properties.prizes} backgroundColors={wheel.style.backgroundColors} textColors={wheel.style.textColors} fontSize={wheel.style.fontSize} innerRadius={wheel.style.innerRadius} innerBorderColor={wheel.style.innerBorderColor} innerBorderWidth={wheel.style.innerBorderWidth} outerBorderColor={wheel.style.outerBorderColor} outerBorderWidth={wheel.style.outerBorderWidth} radiusLineColor={wheel.style.radiusLineColor} radiusLineWidth={wheel.style.radiusLineWidth} textDistance={wheel.properties.textDistance} perpendicularText={wheel.properties.perpendicularText} spinDuration={0.2} pointerProps={{
          src: WHEEL_POINTER_TRANSPARENT,
          style: {
            width: '50px',
            height: '50px',
            backgroundColor: pointer?.color || '#E24A2B',
            maskImage: `url(${WHEEL_POINTER_SHAPE})`,
            maskSize: '100% 100%'
          }
        }} />
				</Box>
				<SpinWheelButton $textColor={button.style.color} $backgroundColor={button.style.backgroundColor} $borderWidth={button.style.borderWidth} $borderColor={button.style.borderColor} $borderRadius={button.style.borderRadius} $fontSize={button.style.fontSize} onClick={() => handleSpinClick()} disabled={isDisabled}>
					{button.properties.title}
				</SpinWheelButton>
			</SnackeetWheel>

			{['winning', 'losing', 'form'].includes(wheelComponentState) && <WheelModal $backgroundColor={pageModal[wheelComponentState].style.backgroundColor} $color={pageModal[wheelComponentState].style.color}>
					<Box>
						<Typography variant='h1' style={{
          wordBreak: 'break-word',
          whiteSpace: 'break-spaces'
        }}>
							{pageModal[wheelComponentState].title}
						</Typography>
						{['winning', 'losing'].includes(wheelComponentState) ? <>
								<Box display='flex' justifyContent='center'>
									<img className={wheelComponentState === 'winning' ? 'win-img' : 'lose-img'} src={getImgSrc(wheelComponentState, pageModal[wheelComponentState])} alt='' />
								</Box>
								<div className='modal-description'>
									<Typography variant='h3'>{pageModal[wheelComponentState].description}</Typography>
								</div>
								{wheelComponentState === 'winning' && pageModal[wheelComponentState].hasCode && <Box display='flex' justifyContent='center' fontSize='16px' fontWeight='bold' margin='5px'>
										Code: xxxxx
									</Box>}
							</> : <>
								<div className='modal-description'>
									<Typography variant='h3'>{pageModal[wheelComponentState].description}</Typography>
								</div>
								<FormInputModal fullWidth placeholder={pageModal[wheelComponentState].placeholder} />
							</>}
					</Box>

					{wheelComponentState === 'form' && gdprConsent?.active && <FieldConsent theming={labelStyle}>
							<Checkbox color='primary' disabled />
							<span style={{
          textDecoration: 'underline'
        }}>{gdprConsent?.message}</span>
						</FieldConsent>}

					{['winning', 'losing'].includes(wheelComponentState) && pageModal[wheelComponentState]?.cta?.isActive ? <StyledSystemButton style={{
        ...buttonStyle,
        ...button_link?.style
      }}>
							{pageModal[wheelComponentState].cta.title}
						</StyledSystemButton> : wheelComponentState === 'form' && <StyledSystemButton style={{
        ...buttonStyle,
        ...button_submit?.style
      }}>
								{pageModal.form.submit_button}
							</StyledSystemButton>}
					{['winning', 'losing'].includes(wheelComponentState) && <StyledSystemButton style={button_next.style}>
							{button_next.properties.title}
						</StyledSystemButton>}
				</WheelModal>}
		</>;
}
WheelComponent.propTypes = {
  components: PropTypes.object,
  pageModal: PropTypes.object,
  theming: PropTypes.object,
  gdprConsent: PropTypes.object,
  wheelComponentState: PropTypes.string
};
export default WheelComponent;

// helper function
function getImgSrc(status, modalData) {
  if (status === 'winning') {
    return modalData?.img_url ? modalData.img_url : '/static/images/confettis.png';
  } else {
    return modalData?.img_url ? modalData.img_url : '/static/images/sad_face.png';
  }
}
// Same as FormComponent
function convertFieldstyle(fieldstyle) {
  const result = cloneDeep(fieldstyle);
  switch (fieldstyle.justifyContent) {
    case 'center':
      result.textAlign = 'center';
      break;
    case 'flex-end':
      result.textAlign = 'right';
      break;
    case 'flex-start':
    default:
      result.textAlign = 'left';
      break;
  }
  return result;
}