import styled from '@styled';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
export default function AmpAttachmentPreview({
  content,
  cta_text,
  styles,
  theme
}) {
  function preventIframeClick(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  function renderElement(element) {
    switch (element.sub_type) {
      case 'title':
        {
          const textWithBreaks = element.value.split('\n').map((line, index, array) => <Fragment key={index}>
						{line}
						{index < array.length - 1 && <br />}
					</Fragment>);
          const styles = getTextElementStyles(element);
          return <h2 key={element._id} style={styles}>
						{textWithBreaks}
					</h2>;
        }
      case 'paragraph':
        {
          // Map each line to a <p> element with <br> for line breaks
          const textWithBreaks = element.value.split('\n').map((line, index, array) => <Fragment key={index}>
						{line}
						{index < array.length - 1 && <br />}
					</Fragment>);
          const styles = getTextElementStyles(element);
          return <p key={element._id} style={styles}>
						{textWithBreaks}
					</p>;
        }
      case 'disclamer':
        {
          const textWithBreaks = element.value.split('\n').map((line, index, array) => <Fragment key={index}>
						{line}
						{index < array.length - 1 && <br />}
					</Fragment>);
          const styles = getTextElementStyles(element);
          return <p key={element._id} style={styles}>
						{textWithBreaks}
					</p>;
        }
      case 'button':
        return <button key={element._id} className='submit-button' style={element.styles}>
						{element.value}
					</button>;
      case 'iframe':
        return <IframeContainer style={element.styles} onClick={preventIframeClick}>
						<iframe style={element.styles} height={element.height} src={element.value}></iframe>
					</IframeContainer>;
      case 'image':
        return <img key={element._id} className='element-img' src={element.value} />;
    }
  }
  return <FormWrapper $fontFamily={styles.fontFamily} $theme={theme}>
			<div className='modal-header'>
				<div className='header-title-and-close'>
					<span className='attachment-title'>{cta_text}</span>
				</div>
			</div>
			<div className='form-wrapper'>{content.map(element => renderElement(element))}</div>
		</FormWrapper>;
}
AmpAttachmentPreview.propTypes = {
  content: PropTypes.array,
  cta_text: PropTypes.string,
  styles: PropTypes.object,
  theme: PropTypes.string
};

// Styled components
const FormWrapper = styled.div`
	width: 370px;
	max-height: 100%;
	border-radius: 16px;
	box-shadow: -2px -4px 32px -2px rgba(0, 0, 0, 0.38);
	background-color: ${({
  $theme
}) => $theme === 'dark' ? 'rgb(32, 33, 37)' : 'rgb(250, 250, 250)'};
	font-family: ${({
  $fontFamily
}) => $fontFamily};
	overflow: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	.form-wrapper {
		width: 100%;
		background-color: ${({
  $theme
}) => $theme === 'dark' ? 'rgb(32, 33, 37)' : 'rgb(250, 250, 250)'};
		padding: 8px 16px 16px;
		border-bottom-right-radius: 16px;
		border-bottom-left-radius: 16px;
		color: ${({
  $theme
}) => $theme === 'light' ? 'rgb(32, 33, 37)' : 'rgb(250, 250, 250)'};
	}

	h2 {
		font-size: 1.75rem;
		line-height: 2rem;
		font-weight: bold;
		margin: 8px 0px;
		font-family: inherit;
	}

	p {
		font-size: 1rem;
		line-height: 1.25rem;
		margin: 8px 0px;
		font-family: inherit;
	}

	.element-img {
		width: 100%;
		height: auto;
		margin: 8px 0;
		border-radius: 4px;
	}

	.modal-header {
		background-color: ${({
  $theme
}) => $theme === 'dark' ? 'rgb(32, 33, 37)' : 'rgb(250, 250, 250)'};
		color: ${({
  $theme
}) => $theme === 'light' ? 'rgb(32, 33, 37)' : 'rgb(250, 250, 250)'};
		display: flex;
		justify-content: center;
		position: sticky;
		top: 0;
		border-top-right-radius: 16px;
		border-top-left-radius: 16px;
	}
	.header-title-and-close {
		height: 44px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		border-top-right-radius: 16px;
		border-top-left-radius: 16px;
		box-shadow: 0px 0px 1px #ccc;
	}
	.attachment-title {
		display: block;
		position: absolute;
		width: calc(100% - 80px);
		font-size: 18px;
		font-family: 'Roboto', sans-serif;
		font-weight: bold;
		line-height: 40px;
		overflow: hidden;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.field-container {
		margin: 8px 0px;
	}

	.submit-container {
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 24px 0px 8px;
	}

	.submit-button {
		width: 100%;
		margin: 8px auto 8px;
		padding: 8px 16px;
		min-height: 48px;
		font-family: inherit;
	}
`;
const IframeContainer = styled.div`
	width: 100%;
	height: 100%;
	pointer-events: none;
	margin: 8px auto 8px;

	iframe {
		width: 100% !important;
		border: none;
	}
`;
function getTextElementStyles(element) {
  return _.pick(element?.styles, ['fontSize', 'color']);
}