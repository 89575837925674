import { Switch } from '@mui/material';
import styled from '@styled';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import { snackeet_dark_grey } from 'styles/colors';
export default function IOSSwitch({
  name,
  color,
  checked,
  onChange,
  disabled = false,
  ...delegatedProps
}) {
  return <StyledSwitch name={name} color={color} checked={checked} onChange={onChange} disabled={disabled} {...delegatedProps} />;
}
IOSSwitch.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};
IOSSwitch.defaultProps = {
  color: 'primary'
};
const SWITCH_HEIGHT = 23;
const SWITCH_WIDTH = 38;
const THUMB_SIZE = SWITCH_HEIGHT - 4;
const StyledSwitch = styled(Switch)`
	width: ${SWITCH_WIDTH}px;
	height: ${SWITCH_HEIGHT}px;
	padding: 0;
	margin: ${({
  theme
}) => theme.spacing(1)};

	& .MuiSwitch-switchBase {
		padding: 0;
		margin: 2px;
		transition-duration: 300ms;

		&.Mui-checked {
			transform: translateX(16px);
			color: #fff;
			& + .MuiSwitch-track {
				background-color: ${({
  theme,
  color
}) => _get(theme.palette, color)};
				opacity: 1;
				border: 0;
			}
			&.Mui-disabled + .MuiSwitch-track {
				opacity: 0.5;
			}
		}

		&.Mui-focusVisible .MuiSwitch-thumb {
			color: ${({
  theme,
  color
}) => _get(theme.palette, color)};
			border: 6px solid #fff;
		}
		&.Mui-disabled .MuiSwitch-thumb {
			color: ${({
  theme
}) => theme.palette.grey[100]};
		}
		&.Mui-disabled + .MuiSwitch-track {
			opacity: 0.7;
		}
	}

	& .MuiSwitch-thumb {
		box-sizing: border-box;
		width: ${THUMB_SIZE}px;
		height: ${THUMB_SIZE}px;
	}

	& .MuiSwitch-track {
		border-radius: ${SWITCH_HEIGHT / 2}px;
		background-color: ${snackeet_dark_grey};
		opacity: 1;
		transition: ${({
  theme
}) => theme.transitions.create(['background-color'], {
  duration: 500
})};
	}
`;
StyledSwitch.defaultProps = {
  focusVisibleClassName: '.Mui-focusVisible',
  disableRipple: true
};