import type { AxiosResponse } from 'axios'
import axios from 'axios'
import { API_URL } from 'lib/constants'
import * as localStorageSvc from 'services/LocalStorageSvc'

const ExportSvc = {
	exportUsers,
	exportStory,
}
export default ExportSvc

type ExportFormat = 'json' | 'csv' | undefined

type ExportUsersRequestProps = {
	project: string
	story_id: string
	format: ExportFormat
}

function exportUsers({ project, story_id, format }: ExportUsersRequestProps): Promise<AxiosResponse> {
	return axios({
		method: 'get',
		url: `${API_URL}/export/users`,
		params: {
			project,
			story_id,
			format,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
}

type ExportPagesRequestProps = ExportUsersRequestProps & {
	pages: string[]
}

function exportStory({ project, story_id, format, pages }: ExportPagesRequestProps): Promise<AxiosResponse> {
	return axios({
		method: 'post',
		url: `${API_URL}/export/story`,
		data: {
			project,
			story_id,
			format,
			pages,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
		responseType: 'blob',
	})
}
