import { Box } from '@mui/material';
import styled from '@styled';
import { snackeet_dark_grey } from 'styles/colors';
export const BrandCardContainer = styled.div`
	width: 100%;
	padding: ${({
  theme
}) => theme.spacing(1)};

	.brand-items {
		width: 100%;
		display: grid;
		grid-template-columns: 120px 120px 1fr;
		grid-template-areas:
			'brand-logo brand-colors brand-fields'
			'brand-font-family brand-font-family brand-buttons';
		grid-gap: 8px;
		place-items: center;
		padding-bottom: 8px;
	}

	.brand-colors {
		grid-area: brand-colors;
	}

	.brand-fields {
		grid-area: brand-fields;
		width: 100%;
	}

	.brand-font-family {
		grid-area: brand-font-family;
		text-align: center;
	}

	.brand-buttons {
		grid-area: brand-buttons;
	}

	.brand-small {
		display: flex;
		width: 700px;
		justify-content: space-around;
		align-items: flex-start;
		column-gap: 8px;
	}
`;
export const BrandCardInfo = styled.a`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: ${({
  theme
}) => theme.spacing(0, 1)};
	overflow: hidden;
	text-decoration: unset;

	.tools {
		display: flex;
		justify-content: flex-end;

		& > div {
			padding: 5px;
			border-radius: 50%;
			transition: all ease-out 0.3s;

			&:hover {
				background: #e6e6e6;
			}
			svg {
				color: ${snackeet_dark_grey};
				height: 20px;
			}
		}
	}

	.brand-name {
		font-weight: bold;
		font-size: 20px;
		color: ${({
  theme
}) => theme.palette.text.primary};
		margin: ${({
  theme
}) => theme.spacing(0, 2, 0.5, 0)};

		:hover {
			text-decoration: underline;
		}
	}
`;
export const LogoPreset = styled(Box)`
	grid-area: brand-logo;
	display: grid;
	place-items: center;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 4px;
	}

	.no-logo {
		text-align: center;
		font-weight: bold;
		line-height: 1.1em;
		font-size: 26px;
		font-family: 'Archivo Black';
		color: ${snackeet_dark_grey};
	}
`;
LogoPreset.defaultProps = {
  width: 60,
  height: 60
};
export const ColorCircle = styled.div`
	position: absolute;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	left: ${({
  $left
}) => $left}px;
	top: 2px;
	background-color: ${({
  $color
}) => $color};
	box-shadow: ${({
  theme
}) => theme.shadows[2]};
	transition: transform 200ms ease-in-out;

	&:hover {
		transform: scale(1.04);
	}
`;
export const TextSample = styled.div`
	font-family: '${({
  $fontFamily
}) => $fontFamily}';
	font-size: ${({
  $fontSize = '14px'
}) => $fontSize};
	line-height: 1em;
`;