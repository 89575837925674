import { Box, Typography } from '@mui/material';
import VideoThumbnail from 'components/atoms/AssetsPreview/VideoThumbnail';
import { pauseVideo, playVideo } from 'lib/utils/mediaUtils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AssetName, DeleteButton, ListContainer, MediaAssetCard } from './MediaComponent.style';
const pixabay_poster_baseURL = 'https://i.vimeocdn.com/video/';
export default function VideoList({
  videos,
  onSelect,
  onDelete,
  listType
}) {
  if (_.isEmpty(videos)) {
    return <Box width='100%' height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
				<img src='/static/placeholders/upload_media.svg' height={300} alt='Upload media' />
				<Typography variant='h2'>
					<FormattedMessage id='edition_menu.video.empty_list' />
				</Typography>
			</Box>;
  }
  const renderVideoListPexels = videos => <>
			{_.map(videos, (video, key) => {
      const {
        link,
        width,
        height
      } = getOptimisedPixelVideo(video.video_files);
      return <MediaAssetCard key={key}>
						<video preload='none' loop muted onMouseOver={playVideo} onMouseOut={pauseVideo} src={link} poster={video.image} onClick={() => onSelect({
          url: link,
          poster: video?.image
        })} />
					</MediaAssetCard>;
    })}
		</>;
  const renderVideoPixabay = videos => <>
			{_.map(videos, (video, key) => {
      const {
        url,
        width,
        height
      } = video.videos.small;
      const poster = `${pixabay_poster_baseURL}${video.picture_id}_${width}x${height}.jpg`;
      return <MediaAssetCard key={key}>
						<video preload='none' loop muted onMouseOver={playVideo} onMouseOut={pauseVideo} src={url} poster={poster} onClick={() => onSelect({
          url,
          poster
        })} />
					</MediaAssetCard>;
    })}
		</>;
  const renderVideoListGallery = videos => <>
			{_.map(videos, video => <MediaAssetCard key={video._id}>
					<VideoThumbnail preload='none' loop muted onMouseOver={playVideo} onMouseOut={pauseVideo} src={video.url} type={`${video.type}/${video.sub_type}`} poster={video?.related?.thumbnail} onClick={() => onSelect({
        url: video.url,
        file_id: video._id,
        name: video.name,
        poster: video?.related?.thumbnail
      })} />
					{typeof onDelete === 'function' && <DeleteButton fileId={video._id} onDelete={onDelete} />}
					<AssetName name={video.name} />
				</MediaAssetCard>)}
		</>;
  const renderVideoRecentlyUsed = videos => <>
			{_.map(videos, video => <MediaAssetCard key={video._id}>
					<VideoThumbnail preload='none' loop muted onMouseOver={playVideo} onMouseOut={pauseVideo} src={video.url} poster={video?.poster} onClick={() => onSelect({
        url: video.url,
        file_id: video.file_id,
        name: video.name,
        poster: video?.poster
      })} />
					<AssetName name={video.name ?? video.url} />
				</MediaAssetCard>)}
		</>;
  const renderStoryVideoList = videos => <>
			{_.map(videos, (video, index) => <MediaAssetCard key={index}>
					<VideoThumbnail preload='none' loop muted onMouseOver={playVideo} onMouseOut={pauseVideo} src={video.url} poster={video?.poster} onClick={() => onSelect({
        url: video.url,
        poster: video?.poster
      })} />
					<AssetName name={video.name ?? video.url} />
				</MediaAssetCard>)}
		</>;
  function renderVideoList({
    listType,
    videos
  }) {
    switch (listType) {
      case 'pexels':
        return renderVideoListPexels(videos);
      case 'pixabay':
        return renderVideoPixabay(videos);
      case 'recently_used':
        return renderVideoRecentlyUsed(videos);
      case 'story_videos':
        return renderStoryVideoList(videos);
      default:
        return renderVideoListGallery(videos);
    }
  }
  return <>
			<Box sx={{
      textAlign: 'center',
      my: 1
    }}>
				<Typography variant='h5'>
					<FormattedMessage id='edition_menu.video_selection_instructions' />
				</Typography>
			</Box>

			<ListContainer>{renderVideoList({
        listType,
        videos
      })}</ListContainer>
		</>;
}
VideoList.propTypes = {
  videos: PropTypes.array,
  handleUrl: PropTypes.func,
  onDelete: PropTypes.func,
  listType: PropTypes.string
};

// Helper function

// Get the best quality SD if empty, take the smallest HD
function getOptimisedPixelVideo(video_files) {
  const sdVideos = _.filter(video_files, ['quality', 'sd']);
  if (_.isEmpty(sdVideos)) {
    const hdVideos = _.filter(video_files, ['quality', 'hd']);
    return _.minBy(hdVideos, 'width');
  }
  return _.maxBy(sdVideos, 'width');
}