export const buttonStyles = {
	text: {
		// Url buttons
		url_click: {
			value: '<p><strong>Click Here</strong></p>',
			subType: 'button',
			cta_options: {
				redirectionType: 'external',
				link: '',
				enabled: true,
			},
			inner_style: {
				color: 'rgba(255, 255, 255, 100)',
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				fontSize: '17px',
				fontFamily: 'Roboto',
				justifyContent: 'center',
				textAlign: 'center',
				lineHeight: '1.25',
				paddingLeft: '5px',
				paddingTop: '15px',
				paddingRight: '5px',
				paddingBottom: '15px',
			},
			outer_style: {
				width: '120px',
				height: 'auto',
				opacity: '100%',
				transform: 'translateX(120px) translateY(315px) rotate(0deg)',
				top: '0px',
				left: '0px',
				borderColor: 'rgba(0, 0, 0, 100)',
				borderStyle: 'solid',
				borderRadius: '120px',
				borderWidth: '2px',
			},
		},
		url_shop: {
			value: '<p><strong>🛒 Shop Now</strong></p>',
			subType: 'button',
			cta_options: {
				redirectionType: 'external',
				link: '',
				enabled: true,
			},
			inner_style: {
				color: 'rgba(255, 255, 255, 100)',
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				fontSize: '17px',
				fontFamily: 'Roboto',
				justifyContent: 'center',
				textAlign: 'center',
				lineHeight: '1.25',
				paddingLeft: '5px',
				paddingTop: '15px',
				paddingRight: '5px',
				paddingBottom: '15px',
			},
			outer_style: {
				width: '140px',
				height: 'auto',
				opacity: '100%',
				transform: 'translateX(120px) translateY(315px) rotate(0deg)',
				top: '0px',
				left: '0px',
				borderColor: 'rgba(0, 0, 0, 100)',
				borderStyle: 'solid',
				borderRadius: '120px',
				borderWidth: '0px',
			},
		},
		url_book: {
			value: '<p><strong>🗓️ Book Now</strong></p>',
			subType: 'button',
			cta_options: {
				redirectionType: 'external',
				link: '',
				enabled: true,
			},
			inner_style: {
				color: 'rgba(255, 255, 255, 100)',
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				fontSize: '17px',
				fontFamily: 'Roboto',
				justifyContent: 'center',
				textAlign: 'center',
				lineHeight: '1.25',
				paddingLeft: '5px',
				paddingTop: '15px',
				paddingRight: '5px',
				paddingBottom: '15px',
			},
			outer_style: {
				width: '140px',
				height: 'auto',
				opacity: '100%',
				transform: 'translateX(120px) translateY(315px) rotate(0deg)',
				top: '0px',
				left: '0px',
				borderColor: 'rgba(0, 0, 0, 100)',
				borderStyle: 'solid',
				borderRadius: '100px',
				borderWidth: '0px',
			},
		},
		url_openInNewTab: {
			value: '<p><strong>Open In New Tab 🚀</strong></p>',
			subType: 'button',
			cta_options: {
				redirectionType: 'external',
				link: '',
				enabled: true,
			},
			inner_style: {
				color: 'rgba(255, 255, 255, 100)',
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				fontSize: '17px',
				fontFamily: 'Roboto',
				justifyContent: 'center',
				textAlign: 'center',
				lineHeight: '1.25',
				paddingLeft: '5px',
				paddingTop: '15px',
				paddingRight: '5px',
				paddingBottom: '15px',
			},
			outer_style: {
				width: '180px',
				height: 'auto',
				opacity: '100%',
				transform: 'translateX(105px) translateY(300px) rotate(0deg)',
				top: '0px',
				left: '0px',
				borderColor: 'rgba(0, 0, 0, 1)',
				borderStyle: 'solid',
				borderRadius: '120px',
				borderWidth: '0px',
			},
		},

		// Download button
		download_now: {
			value: '<p><strong>↓ Download Now</strong></p>',
			subType: 'button',
			cta_options: {
				redirectionType: 'download',
				link: '',
				enabled: true,
				file: {},
			},
			inner_style: {
				color: 'rgba(255, 255, 255, 100)',
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				fontSize: '17px',
				fontFamily: 'Roboto',
				justifyContent: 'center',
				textAlign: 'center',
				lineHeight: '1.25',
				paddingLeft: '5px',
				paddingTop: '15px',
				paddingRight: '5px',
				paddingBottom: '15px',
			},
			outer_style: {
				width: '150px',
				height: 'auto',
				opacity: '100%',
				transform: 'translateX(120px) translateY(300px) rotate(0deg)',
				top: '0px',
				left: '0px',
				borderColor: 'rgba(0, 0, 0, 100)',
				borderStyle: 'solid',
				borderRadius: '120px',
				borderWidth: '0px',
			},
		},
		download_save: {
			value: '<p><strong>💾 Save File</strong></p>',
			subType: 'button',
			cta_options: {
				redirectionType: 'download',
				link: '',
				enabled: true,
				file: {},
			},
			inner_style: {
				color: 'rgba(255, 255, 255, 100)',
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				fontSize: '17px',
				fontFamily: 'Roboto',
				justifyContent: 'center',
				textAlign: 'center',
				lineHeight: '1.25',
				paddingLeft: '5px',
				paddingTop: '15px',
				paddingRight: '5px',
				paddingBottom: '15px',
			},
			outer_style: {
				width: '120px',
				height: 'auto',
				opacity: '100%',
				transform: 'translateX(120px) translateY(300px) rotate(0deg)',
				top: '0px',
				left: '0px',
				borderColor: 'rgba(0, 0, 0, 100)',
				borderStyle: 'solid',
				borderRadius: '100px',
				borderWidth: '0px',
			},
		},
		// Popup buttons
		popup_read: {
			value: '<p><strong>Read More...</strong></p>',
			subType: 'button',
			cta_options: {
				redirectionType: 'attachment',
				link: '',
				enabled: true,
				attachment: {},
			},
			inner_style: {
				color: 'rgba(255, 255, 255, 100)',
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				fontSize: '17px',
				fontFamily: 'Roboto',
				justifyContent: 'center',
				textAlign: 'center',
				lineHeight: '1.25',
				paddingLeft: '5px',
				paddingTop: '15px',
				paddingRight: '5px',
				paddingBottom: '15px',
			},
			outer_style: {
				width: '120px',
				height: 'auto',
				opacity: '100%',
				transform: 'translateX(120px) translateY(300px) rotate(0deg)',
				top: '0px',
				left: '0px',
				borderColor: 'rgba(0, 0, 0, 100)',
				borderStyle: 'solid',
				borderRadius: '100px',
				borderWidth: '0px',
			},
		},
		popup_open: {
			value: '<p><strong>Open Now</strong></p>',
			subType: 'button',
			cta_options: {
				redirectionType: 'attachment',
				link: '',
				enabled: true,
				attachment: {},
			},
			inner_style: {
				color: 'rgba(255, 255, 255, 100)',
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				fontSize: '17px',
				fontFamily: 'Roboto',
				justifyContent: 'center',
				textAlign: 'center',
				lineHeight: '1.25',
				paddingLeft: '5px',
				paddingTop: '15px',
				paddingRight: '5px',
				paddingBottom: '15px',
			},
			outer_style: {
				width: '120px',
				height: 'auto',
				opacity: '100%',
				transform: 'translateX(120px) translateY(300px) rotate(0deg)',
				top: '0px',
				left: '0px',
				borderColor: 'rgba(0, 0, 0, 100)',
				borderStyle: 'solid',
				borderRadius: '100px',
				borderWidth: '0px',
			},
		},
		// Contact buttons
		email_text: {
			value: '<p><strong>✉️ Send Email</strong></p>',
			subType: 'button',
			cta_options: {
				redirectionType: 'mailto',
				link: '',
				enabled: true,
			},
			inner_style: {
				color: 'rgba(255, 255, 255, 100)',
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				fontSize: '17px',
				fontFamily: 'Roboto',
				justifyContent: 'center',
				textAlign: 'center',
				lineHeight: '1.25',
				paddingLeft: '5px',
				paddingTop: '15px',
				paddingRight: '5px',
				paddingBottom: '15px',
			},
			outer_style: {
				width: '120px',
				height: 'auto',
				opacity: '100%',
				transform: 'translateX(120px) translateY(300px) rotate(0deg)',
				top: '0px',
				left: '0px',
				borderColor: 'rgba(0, 0, 0, 100)',
				borderStyle: 'solid',
				borderRadius: '120px',
				borderWidth: '0px',
			},
		},
		phone_text: {
			value: '<p><strong>☎️ Call Me</strong></p>',
			subType: 'button',
			cta_options: {
				redirectionType: 'phoneTo',
				phoneNumber: '',
				enabled: true,
			},
			inner_style: {
				color: 'rgba(255, 255, 255, 100)',
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				fontSize: '17px',
				fontFamily: 'Roboto',
				justifyContent: 'center',
				textAlign: 'center',
				lineHeight: '1.25',
				paddingLeft: '5px',
				paddingTop: '15px',
				paddingRight: '5px',
				paddingBottom: '15px',
			},
			outer_style: {
				width: '120px',
				height: 'auto',
				opacity: '100%',
				transform: 'translateX(120px) translateY(300px) rotate(0deg)',
				top: '0px',
				left: '0px',
				borderColor: 'rgba(0, 0, 0, 100)',
				borderStyle: 'solid',
				borderRadius: '120px',
				borderWidth: '0px',
			},
		},
		sms_text: {
			value: '<p><strong>💬 Send SMS</strong></p>',
			subType: 'button',
			cta_options: {
				redirectionType: 'messageTo',
				defaultMessage: '',
				enabled: true,
			},
			inner_style: {
				color: 'rgba(255, 255, 255, 100)',
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				fontSize: '17px',
				fontFamily: 'Roboto',
				justifyContent: 'center',
				textAlign: 'center',
				lineHeight: '1.25',
				paddingLeft: '5px',
				paddingTop: '15px',
				paddingRight: '5px',
				paddingBottom: '15px',
			},
			outer_style: {
				width: '120px',
				height: 'auto',
				opacity: '100%',
				transform: 'translateX(120px) translateY(300px) rotate(0deg)',
				top: '0px',
				left: '0px',
				borderColor: 'rgba(0, 0, 0, 100)',
				borderStyle: 'solid',
				borderRadius: '120px',
				borderWidth: '0px',
			},
		},
		// Text Copy buttons
		copyText_code: {
			value: '<p><strong>COPY CODE</strong></p>',
			subType: 'button',
			cta_options: {
				redirectionType: 'copyToClipboard',
				copyValue: '',
				enabled: true,
			},
			inner_style: {
				color: 'rgba(255, 255, 255, 100)',
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				fontSize: '17px',
				fontFamily: 'Roboto',
				justifyContent: 'center',
				textAlign: 'center',
				lineHeight: '1.25',
				paddingLeft: '5px',
				paddingTop: '15px',
				paddingRight: '5px',
				paddingBottom: '15px',
			},
			outer_style: {
				width: '140px',
				height: 'auto',
				opacity: '100%',
				transform: 'translateX(120px) translateY(300px) rotate(0deg)',
				top: '0px',
				left: '0px',
				borderColor: 'rgba(0, 0, 0, 100)',
				borderStyle: 'solid',
				borderRadius: '120px',
				borderWidth: '0px',
			},
		},
		copyText_save: {
			value: '<p><strong>TPXZG8U</strong></p>',
			subType: 'button',
			cta_options: {
				redirectionType: 'copyToClipboard',
				copyValue: '',
				enabled: true,
			},
			inner_style: {
				color: 'rgba(255, 255, 255, 100)',
				backgroundColor: 'rgba(0, 0, 0, 0.7)',
				fontSize: '17px',
				fontFamily: 'Roboto',
				justifyContent: 'center',
				textAlign: 'center',
				lineHeight: '1.25',
				paddingLeft: '5px',
				paddingTop: '15px',
				paddingRight: '5px',
				paddingBottom: '15px',
			},
			outer_style: {
				width: '140px',
				height: 'auto',
				opacity: '100%',
				transform: 'translateX(120px) translateY(300px) rotate(0deg)',
				top: '0px',
				left: '0px',
				borderColor: 'rgba(0, 0, 0, 100)',
				borderStyle: 'solid',
				borderRadius: '120px',
				borderWidth: '0px',
			},
		},
	},
	shape: {
		rectangle: {
			value: 'rectangle',
			subType: 'button_invisible',
			outer_style: {
				transform: 'translateX(10px) translateY(25px) rotate(0deg)',
				top: '0px',
				left: '0px',
				width: '240px',
				height: '100px',
				opacity: '100%',
				color: 'rgba(0, 0, 0, 0)',
				backgroundColor: 'rgba(255, 255, 255, 0)',
				borderStyle: 'solid',
				borderWidth: '0px',
				borderColor: 'rgba(0, 0, 0, 0)',
				borderRadius: '0px',
			},
		},
		circle: {
			value: 'rectangle',
			subType: 'button_invisible',
			outer_style: {
				transform: 'translateX(10px) translateY(25px) rotate(0deg)',
				top: '0px',
				left: '0px',
				width: '200px',
				height: '200px',
				opacity: '100%',
				color: 'rgba(0, 0, 0, 0)',
				backgroundColor: 'rgba(255, 255, 255, 0)',
				borderStyle: 'solid',
				borderWidth: '0px',
				borderColor: 'rgba(0, 0, 0, 0)',
				borderRadius: '200px',
			},
		},
	},
	icon: {
		goBack: {
			value: 'https://static.snackeet.com/snackeet_assets/V2/essential/fi-rr-arrow-small-left.png',
			subType: 'button',
			inner_style: {
				color: 'rgba(255, 255, 255, 1)',
				fontSize: '40px',
			},
			outer_style: {
				transform: 'translateX(10px) translateY(25px) rotate(0deg)',
				top: '0px',
				left: '0px',
				width: '60px',
				height: '60px',
				opacity: '100%',
				backgroundColor: 'rgba(82, 113, 255, 100)',
				borderStyle: 'solid',
				borderWidth: '0px',
				borderColor: 'rgba(0, 0, 0, 0)',
				borderRadius: '40px',
			},
			cta_options: {
				redirectionType: 'internal',
				link: 'back',
				enabled: true,
			},
		},
		goNext: {
			value: 'https://static.snackeet.com/snackeet_assets/V2/essential/fi-rr-arrow-small-right.png',
			subType: 'button',
			inner_style: {
				color: 'rgba(255, 255, 255, 1)',
				fontSize: '40px',
			},
			outer_style: {
				transform: 'translateX(150px) translateY(25px) rotate(0deg)',
				top: '0px',
				left: '0px',
				width: '60px',
				height: '60px',
				opacity: '100%',
				backgroundColor: 'rgba(82, 113, 255, 100)',
				borderStyle: 'solid',
				borderWidth: '0px',
				borderColor: 'rgba(0, 0, 0, 0)',
				borderRadius: '40px',
			},
			cta_options: {
				redirectionType: 'internal',
				link: 'next',
				enabled: true,
			},
		},
	},
}
