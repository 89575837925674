import each from 'lodash/each'
import map from 'lodash/map'
import remove from 'lodash/remove'
import { useEffect } from 'react'

type Callback = (data?: any) => void

interface Events {
	[event: string]: Callback[]
}

interface EventSubscriptions {
	[event: string]: Callback
}

export const EventEmitter = {
	events: {} as Events,

	dispatch(event: string, data?: any): void {
		if (!this.events[event]) return
		each(this.events[event], (callback: Callback) => callback(data))
	},

	subscribe(event: string, callback: Callback): () => void {
		if (!this.events[event]) this.events[event] = []
		this.events[event].push(callback)
		return () => this.unsubscribe(event, callback)
	},

	unsubscribe(event: string, callback?: Callback): void {
		if (!callback) {
			delete this.events[event]
		} else {
			remove(this.events[event], (c: Callback) => c === callback)
		}
	},
}

export default EventEmitter

export function useCustomEvent(eventSubscriptions: EventSubscriptions, dependencyArray = []) {
	useEffect(
		() => {
			const unsubscribes = map(eventSubscriptions, (callback, event) => EventEmitter.subscribe(event, callback))

			return () => {
				each(unsubscribes, (unsub) => unsub())
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		dependencyArray
	)
}
