import { CalendarIcon } from '@heroicons/react/20/solid';
import { FiberManualRecord, PhotoLibrary } from '@mui/icons-material';
import { Box, Button, Checkbox, DialogActions, DialogContent, List, ListItemButton, ListItemIcon, ListItemText, SvgIcon, Tooltip, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import styled from '@styled';
import Loader from 'components/Loader';
import { SnackeetCardLabel } from 'components/styled/SnackeetCard';
import { STORIES_COLORS } from 'lib/constants';
import { hasQuizPage } from 'lib/editorStore/selectors';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentProjectId } from 'redux/project/selectors';
import PublishedStoriesSvc from 'services/PublishedStoriesSvc';
import useAsyncEffect from 'use-async-effect';
import { useImmer } from 'use-immer';
import { ModalWrapper } from './ModalWrapper';
const WIDGET_STORIES_FIELDS = 'story_id,name,active,snapshot_url_png,updated_at,type,variables,pages,leaderboard_settings';
const DESCRIPTION_SECONDARY_PROPS = {
  variantMapping: {
    body2: 'div'
  }
};
export default function AddLeaderboardStoriesModal({
  trigger,
  selectedStories = [],
  onStoryAdd,
  leaderboardId
}) {
  const modalRef = useRef();
  const project = useSelector(getCurrentProjectId);
  const [state, setState] = useImmer({
    isLoading: true,
    workspaceStories: [],
    selectedStories: []
  });
  const prevStateSelectedStories = _.cloneDeep(selectedStories);
  const onConfirm = useCallback(() => {
    onStoryAdd(state.selectedStories);
    modalRef.current.close();
  }, [onStoryAdd, selectedStories, state]);
  useAsyncEffect(async isMounted => {
    const workspaceStories = await PublishedStoriesSvc.getAll({
      project,
      fields: WIDGET_STORIES_FIELDS
    });
    if (isMounted()) {
      setState(draft => {
        draft.isLoading = false;
        draft.workspaceStories = _.filter(workspaceStories, story => hasQuizPage({
          draftStory: story
        })).sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.updated_at) - new Date(a.updated_at);
        });
      });
    }
  }, [project]);

  // Update inner state selected stories based on the stories provided in props
  useEffect(() => {
    if (!_.isEqual(selectedStories, state.selectedStories)) {
      setState(draft => {
        draft.selectedStories = selectedStories;
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [selectedStories, setState]);

  // Open modal on mount when no selected stories after a certain delay
  useEffect(() => {
    if (_.isEmpty(selectedStories)) {
      setTimeout(() => modalRef.current?.open(), 1000);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  return <ModalWrapper size='md' ref={modalRef} trigger={trigger} title={<FormattedMessage id='modal.widget_banner.story_add.header' />}>
			<StyledDialogContent>
				{state.isLoading ? <Loader $heightPx={100} size={50} name='ThreeBounce' /> : <>
						<Box px={1} mb={1} fontSize={14} display='flex' alignItems='center' justifyContent='space-between'>
							<Box flex={1}>
								<p>
									<FormattedMessage id='leaderboard.stories_modal.select_instruction' />
								</p>
							</Box>
						</Box>
						<StoriesList stories={state.workspaceStories} selectedStories={state.selectedStories} setState={setState} leaderboardId={leaderboardId} />
					</>}
			</StyledDialogContent>

			<DialogActions>
				<Button variant='text' onClick={() => modalRef.current?.close()}>
					<FormattedMessage id='button.cancel' />
				</Button>

				<Button variant='outlined' color='secondary' onClick={onConfirm} disabled={_.isEqual(prevStateSelectedStories, state.selectedStories)}>
					<FormattedMessage id='button.confirm' />
				</Button>
			</DialogActions>
		</ModalWrapper>;
}
AddLeaderboardStoriesModal.propTypes = {
  trigger: PropTypes.node,
  selectedStories: PropTypes.array,
  onStoryAdd: PropTypes.func,
  leaderboardId: PropTypes.string
};
function StoriesList({
  stories,
  selectedStories,
  setState,
  leaderboardId
}) {
  const handleItemClick = useCallback((storyId, isDisabled) => () => {
    if (isDisabled) return;
    setState(draft => {
      if (draft.selectedStories.includes(storyId)) {
        _.remove(draft.selectedStories, id => id === storyId);
      } else {
        draft.selectedStories.push(storyId);
      }
    });
  }, [setState]);
  if (_.isEmpty(stories)) {
    return <Box width='100%' height='200px' display='flex' justifyContent='center' alignItems='center'>
				<Typography color='textSecondary' variant='h2'>
					<FormattedMessage id='leaderboard.stories_modal.no_stories' />
				</Typography>
			</Box>;
  }
  return <StyledList>
			{_.map(stories, story => <ListItemButton key={story.story_id} onClick={handleItemClick(story.story_id, isStoryDisabled(story, leaderboardId))} disabled={(isStoryDisabled(story, leaderboardId) || !isStoryValid(story)) && !selectedStories.includes(story.story_id)}>
					<Box position='relative'>
						<StoryCard>
							{story?.snapshot_url_png ? <img src={story?.snapshot_url_png} /> : <PhotoLibrary />}
						</StoryCard>
						<Tooltip title={story.active ? <FormattedMessage id='common.active' /> : <FormattedMessage id='common.disabled' />}>
							<StoryStatus $isActive={story.active}>
								<FiberManualRecord fontSize='small' />
							</StoryStatus>
						</Tooltip>
					</Box>
					<ListItemText primary={<b>{story.name}</b>} secondary={<StoryDescription story={story} isDisabled={(isStoryDisabled(story, leaderboardId) || !isStoryValid(story)) && !selectedStories.includes(story.story_id)} isInvalid={!isStoryValid(story)} />} secondaryTypographyProps={DESCRIPTION_SECONDARY_PROPS} />
					<ListItemIcon>
						<Checkbox checked={selectedStories.includes(story.story_id)} disableRipple />
					</ListItemIcon>
				</ListItemButton>)}
		</StyledList>;
}
StoriesList.propTypes = {
  stories: PropTypes.array,
  selectedStories: PropTypes.array,
  setState: PropTypes.func,
  leaderboardId: PropTypes.string
};
function StoryDescription({
  story,
  isDisabled = false,
  isInvalid = false
}) {
  return <Box display='flex' flexDirection='column' alignItems='flex-start'>
			<SnackeetCardLabel $color={STORIES_COLORS[story.type]}>
				<FormattedMessage id={`common.snackeet_type.${story.type}`} />
			</SnackeetCardLabel>
			{isDisabled ? isInvalid ? <Box pl={0.5}>
						<FormattedMessage id='leaderboard.stories_modal.story_isInvalid' />
					</Box> : <Box pl={0.5}>
						<FormattedMessage id='leaderboard.stories_modal.story_isUsed' />
					</Box> : <Box display='flex' alignItems='center' pl={0.5}>
					<SvgIcon fontSize='small'>
						<CalendarIcon />
					</SvgIcon>
					<Box mr={1}>
						<FormattedMessage id='stories_list.published' />
					</Box>
					<div>
						<FormattedDate value={new Date(story.updated_at)} year='numeric' month='numeric' day='numeric' />
					</div>
				</Box>}
		</Box>;
}
StoryDescription.propTypes = {
  story: PropTypes.object,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool
};
const StyledList = styled(List)`
	display: grid;
	grid-template-columns: repeat(2, minmax(auto, 350px));
	justify-content: center;
	grid-auto-flow: dense;
	overflow: auto;

	.MuiListItemText-primary {
		padding-left: ${({
  theme
}) => theme.spacing(0.5)};
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		&:hover {
			white-space: initial;
		}
	}

	.MuiListItemText-secondary {
		.MuiSvgIcon-root {
			margin-right: ${({
  theme
}) => theme.spacing(1)};
		}
	}
`;
const StyledDialogContent = styled(DialogContent)`
	display: flex;
	flex-direction: column;
`;
const StoryCard = styled.div`
	position: relative;
	margin: 0px 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	aspect-ratio: 359 / 630;
	width: 50px;
	border-radius: 8px;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;
const StoryStatus = styled.div`
	position: absolute;
	bottom: 0px;
	right: 18px;
	border-radius: 50%;
	background-color: ${({
  theme
}) => theme.palette.background.paper};
	color: ${({
  $isActive
}) => $isActive ? green[500] : red[500]};
	display: grid;
	place-items: center;
`;

// Helper functions
function isStoryDisabled(story, leaderboardId) {
  return story.leaderboard_settings?.leaderboard_id && leaderboardId !== story.leaderboard_settings?.leaderboard_id;
}
function isStoryValid(story) {
  const emailVariable = _.find(story.variables, ['name', 'email']);
  if (!emailVariable) {
    return false;
  } else if (!emailVariable.isUsed) {
    return false;
  }
  const usernameVariable = _.find(story.variables, ['name', '_username']);
  if (!usernameVariable) {
    return false;
  } else if (!usernameVariable.isUsed) {
    return false;
  }
  return true;
}