import * as React from 'react'

function Svg2Number(props) {
	return (
		<svg width='1em' height='1em' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M3.465 5H.67v-.39l1.476-1.64c.22-.248.37-.45.452-.604.084-.156.126-.317.126-.483a.765.765 0 00-.203-.548.707.707 0 00-.539-.214c-.27 0-.48.077-.63.232-.148.152-.222.365-.222.638H.588c0-.392.126-.71.378-.952.254-.242.593-.363 1.016-.363.397 0 .71.104.94.313.231.207.347.484.347.83 0 .42-.268.92-.803 1.5L1.323 4.557h2.142V5z'
				fill='#000'
			/>
		</svg>
	)
}

export default Svg2Number
