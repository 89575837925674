import { Box, CircularProgress } from '@mui/material';
import SnackeetButton from 'organisms/buttons/SnackeetButton';
import PropTypes from 'prop-types';
export default function SubmitButton({
  children,
  loading,
  endIcon,
  ...delegatedProps
}) {
  return <SnackeetButton type='submit' variant='contained' color='primary' endIcon={!loading && endIcon} $isLoading={loading} {...delegatedProps}>
			{children}
			{loading && <Box ml={1}>
					<CircularProgress color='primary' size={20} />
				</Box>}
		</SnackeetButton>;
}
SubmitButton.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  endIcon: PropTypes.node
};