import { Input, Slider as MuiSlider, Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from '@styled';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { SliderContainer, SliderSection } from './Sliders.style';
export default function Slider({
  initial_value,
  units,
  sliderStep,
  label,
  maxValue = 100,
  minValue = 0,
  onUpdate,
  disabled = false,
  hasInput = true,
  debounceDelay = 100,
  sx
}) {
  const [value, setValue] = useState(initial_value);
  const [inputValue, setInputValue] = useState(initial_value);
  const [isError, setError] = useState(false);
  const debouncedUpdate = useDebouncedCallback(value => onUpdate(parseFloat(value)), debounceDelay);
  const handleSliderChange = (event, value) => {
    if (parseFloat(value) <= maxValue) {
      setValue(value);
      setInputValue(value);
      setError(false);
      debouncedUpdate(value);
    }
  };
  const handleChangeCommitted = (event, value) => {
    setValue(value);
    setInputValue(value);
    setError(false);
    debouncedUpdate(value);
  };
  const handleInputChange = event => {
    setInputValue(event.target.value);
  };
  function handleEnterKey(evt) {
    if (evt.key === 'Enter' || evt.keyCode === 13) {
      handleBlur();
    }
  }
  function handleBlur() {
    if (inputValue >= minValue && inputValue <= maxValue) {
      setValue(parseFloat(inputValue));
      debouncedUpdate(parseFloat(inputValue));
      setError(false);
    } else {
      setError(true);
    }
  }

  // Handle Undo/Redo
  useEffect(() => {
    setValue(initial_value);
    setInputValue(initial_value);
  }, [initial_value]);
  return <SliderContainer sx={sx}>
			{label && <Typography variant='body1' component='label'>
					{label}
				</Typography>}

			<SliderSection>
				<MuiSlider color='primary' value={value} step={sliderStep} min={minValue} max={maxValue} onChange={handleSliderChange} onChangeCommitted={handleChangeCommitted} disabled={disabled} valueLabelDisplay='auto' slots={{
        valueLabel: ValueLabelComponent
      }} />

				{hasInput && <>
						<Input className='slider-input' type='number' value={parseFloat(inputValue)} onChange={handleInputChange} onBlur={handleBlur} disabled={disabled} onKeyUp={handleEnterKey} error={isError} />

						<div className='slider-unit'>
							<b>{units}</b>
						</div>
					</>}
			</SliderSection>
		</SliderContainer>;
}
Slider.propTypes = {
  initial_value: PropTypes.number,
  units: PropTypes.string,
  sliderStep: PropTypes.number,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  onUpdate: PropTypes.func,
  disabled: PropTypes.bool,
  debounceDelay: PropTypes.number,
  hasInput: PropTypes.bool
};
const BootstrapTooltip = styled(({
  className,
  ...props
}) => <Tooltip {...props} classes={{
  popper: className
}} />)(({
  theme
}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'rgba(255, 255, 255, 0.9)',
    padding: '3px 8px',
    fontSize: '10px',
    marginBottom: '6px !important'
  }
}));
function ValueLabelComponent(props) {
  const {
    children,
    open,
    value
  } = props;
  return <BootstrapTooltip open={open} enterTouchDelay={0} placement='top' title={value}>
			{children}
		</BootstrapTooltip>;
}
ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired
};