import { Cancel, Create, DragIndicator, PlayArrow, Warning } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import cx from 'classnames';
import { isStoryNotScheduled } from 'lib/utils';
import PropTypes from 'prop-types';
import { forwardRef, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ActionButtons, BubbleActionArea, StoryDimmer, WidgetElement, WidgetElementName, WidgetElementPreview } from './BannerBubble.style';
import StoryBorder from './StoryBorder';
import StoryBubble from './StoryBubble';
const BannerBubble = forwardRef(function BannerBubble({
  widget,
  story,
  platform = 'desktop',
  size,
  elementIndex,
  $disabledAnimation,
  disabledName = false,
  draggableProps,
  dragHandleProps,
  onSelect,
  onDelete,
  ...delegatedProps
}, ref) {
  const intl = useIntl();
  const isStoryDisabled = story.active === false || isStoryNotScheduled(story.story_schedule);
  const handleElementClick = useCallback(evt => {
    typeof onSelect === 'function' && onSelect(evt);
  }, [onSelect]);
  const hasOptions = typeof onSelect === 'function' || typeof onDelete === 'function' || Boolean(dragHandleProps);
  const $size = size ?? widget.placement[platform].size;
  const borderAnimationName = $disabledAnimation ? 'none' : widget.appearance.story_border.animation;
  return <BubbleActionArea {...draggableProps} $settings={widget.appearance.story_border} style={{
    ...draggableProps?.style,
    '--banner-element-index': elementIndex
  }} className={cx({
    'continuous-pulse': borderAnimationName === 'continuous-pulse'
  })}>
			<WidgetElement
    // Use key to re-render and re-trigger animation
    // key={`element-${story.story_id}-${animationToggle}`}
    ref={ref} $size={$size} $settings={widget.appearance.story_border} onClick={handleElementClick} {...delegatedProps}>
				<WidgetElementPreview>
					<StoryBorder widgetId={widget._id} size={$size} settings={widget.appearance.story_border} />
					<StoryBubble thumbnail={story.thumbnail ?? story.snapshot_url_png} thumbnailType={story.thumbnail_type ?? 'image'} />
					{widget.appearance.banner.has_play_icon && <PlayArrow />}
					{isStoryDisabled && <StoryDimmer $settings={widget.appearance.story_border} />}
				</WidgetElementPreview>

				{!disabledName && <Tooltip title={isStoryDisabled ? intl.messages['widget_editor.stories.alert.story_disabled'] : ''} position='bottom'>
						<WidgetElementName style={widget.appearance.story_text.common}>
							{isStoryDisabled && <Warning fontSize='small' sx={{
            display: 'inline',
            color: 'warning.light',
            mr: 0.25,
            mb: -0.5
          }} />}
							{story.display_name ?? story.name}
						</WidgetElementName>
					</Tooltip>}
			</WidgetElement>

			{hasOptions && <ActionButtons>
					{typeof onSelect === 'function' && <IconButton size='small' onClick={onSelect} className='hoverable'>
							<Create />
						</IconButton>}

					{dragHandleProps && <IconButton {...dragHandleProps} size='large'>
							<DragIndicator />
						</IconButton>}

					{typeof onDelete === 'function' && <IconButton size='small' onClick={onDelete} className='hoverable'>
							<Cancel />
						</IconButton>}
				</ActionButtons>}
		</BubbleActionArea>;
});
BannerBubble.propTypes = {
  widget: PropTypes.object.isRequired,
  story: PropTypes.object.isRequired,
  platform: PropTypes.string,
  size: PropTypes.number,
  elementIndex: PropTypes.number,
  $disabledAnimation: PropTypes.bool,
  disabledName: PropTypes.bool,
  draggableProps: PropTypes.object,
  dragHandleProps: PropTypes.object,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func
};
export default BannerBubble;