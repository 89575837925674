import { AlternateEmailOutlined, AssignmentIndOutlined, CheckBoxOutlined, Close, DateRangeOutlined, Delete, DragIndicator, Edit, LanguageOutlined, ListOutlined, LooksOneOutlined, MessageOutlined, PersonOutline, PhoneOutlined, Settings } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControlLabel, IconButton, SvgIcon, TextField, Tooltip, Typography } from '@mui/material';
import { ConfirmationModal, FieldAdvancedSettingsModal, ListSettingsModal, VariablesModal } from 'components/Modals';
import { FieldSettingsContainer } from 'components/styled/Modals';
import dayjs from 'lib/dayjs';
import { getValidVariableTypes, unassignFieldVariable, updateVariableData, variableExists } from 'lib/utils';
import _ from 'lodash';
import MuiPhoneNumber from 'material-ui-phone-number';
import Dropdown from 'organisms/Dropdown';
import { forwardRef, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import slugify from 'slugify';
import { useImmer } from 'use-immer';
const SUB_TYPES_LIST = ['firstname', 'lastname', 'email', 'phone', 'text', 'number', 'date', 'select', 'website', 'checkbox'];
const ICONS_LIST = {
  firstname: <PersonOutline />,
  lastname: <PersonOutline />,
  email: <AlternateEmailOutlined />,
  phone: <PhoneOutlined />,
  text: <MessageOutlined />,
  number: <LooksOneOutlined />,
  date: <DateRangeOutlined />,
  select: <ListOutlined />,
  website: <LanguageOutlined />,
  checkbox: <CheckBoxOutlined />,
  username: <AssignmentIndOutlined />
};
const formDateFormatOptions = [{
  text: <FormattedMessage id='edition_menu.form.field_settings.date_format.eu' />,
  value: 'DD/MM/YYYY'
}, {
  text: <FormattedMessage id='edition_menu.form.field_settings.date_format.us' />,
  value: 'MM/DD/YYYY'
}];
const formFieldInputProps = {
  readOnly: true,
  disabled: true
};
const FieldSettings = forwardRef(function FieldSettings({
  field,
  canDelete,
  handleRemoveField,
  handleChangeFieldProperty,
  handleChangeFieldSettings,
  handleChangeSettings,
  isDragging,
  currentVariables,
  currentPage,
  setVariables,
  ...delegatedProps
}, ref) {
  const intl = useIntl();
  const changeVariableRef = useRef();
  const advancedSettingsRef = useRef();
  const confirmRef = useRef();
  const listSettingsRef = useRef();
  const [newVariable, setNewVariable] = useImmer({
    name: '',
    type: 'text'
  });
  const [fieldError, setFieldError] = useState(null);
  function openVariablesModal() {
    changeVariableRef.current.open();
  }
  function handleNewVariableNameChange(value) {
    setFieldError(null);
    const slugValue = slugify(value, {
      remove: /[*+~%\<>§/;`=.(){}?,'"!:@#^|]/g
    });
    setNewVariable(draft => {
      draft.name = slugValue;
    });
  }
  function handleNewVariableTypeChange(value) {
    setNewVariable(draft => {
      draft.type = value;
    });
  }
  function addVariable() {
    if (_.isEmpty(newVariable.name)) {
      return setFieldError('Variable cannot be empty!');
    }
    if (variableExists({
      name: newVariable.name,
      currentVariables
    })) {
      return setFieldError('Variable already exists !');
    }
    setVariables(draft => {
      draft.push({
        ...newVariable,
        default: false,
        isUsed: false
      });
    });
    setNewVariable(draft => {
      draft.name = '';
      draft.type = 'text';
    });
    setFieldError(null);
  }
  function removeVariable(name) {
    const index = currentVariables.findIndex(variable => variable.name === name && !variable.default);
    if (index >= 0) {
      setVariables(draft => {
        draft.splice(index, 1);
      });
    }
  }
  function unassignVariable(name, fieldId) {
    handleChangeFieldProperty(fieldId, 'variable', '');
    const idx = currentVariables.findIndex(variable => variable.name === name);
    if (idx >= 0) {
      setVariables(draft => {
        draft[idx] = unassignFieldVariable(currentVariables[idx], fieldId);
      });
    }
  }
  function assignVariable(name) {
    // If form field already has variable, then clean up current variable
    if (field.variable) {
      const index = currentVariables.findIndex(variable => variable.name === field.variable);
      if (index >= 0) {
        setVariables(draft => {
          draft[index] = unassignFieldVariable(currentVariables[index], field._id);
        });
      }
    }

    // Update variable of form field
    handleChangeFieldProperty(field._id, 'variable', name);

    // Update variables
    const idx = currentVariables.findIndex(variable => variable.name === name);
    if (idx >= 0) {
      setVariables(draft => {
        draft[idx] = updateVariableData(currentVariables[idx], {
          page: {
            _id: currentPage._id,
            type: currentPage.type
          },
          field: field._id
        });
      });
    }
    setNewVariable(draft => {
      draft.name = '';
      draft.type = 'text';
    });
    setFieldError(null);
    changeVariableRef.current.close();
  }
  function onCloseVariablesModal() {
    setNewVariable(draft => {
      draft.name = '';
      draft.type = 'text';
    });
    setFieldError(null);
  }
  function onRemoveField() {
    handleRemoveField(field._id);
    confirmRef.current.close();
  }
  function renderInputField(type, sub_type) {
    switch (type) {
      case 'date':
        return <div className='placeholder-container'>
						<Tooltip placement='bottom' title={<FormattedMessage id={`edition_menu.form.field_type.${sub_type}`} />}>
							<div className='sub-type-icon'>
								<SvgIcon>{ICONS_LIST[sub_type]}</SvgIcon>
							</div>
						</Tooltip>
						<Dropdown variant='outlined' options={formDateFormatOptions} value={field.settings.format} onChange={({
            target: {
              value
            }
          }) => handleChangeFieldSettings(field._id, 'format', value)} />
					</div>;
      case 'phone':
        return <div className='placeholder-container'>
						<Tooltip placement='bottom' title={<FormattedMessage id={`edition_menu.form.field_type.${sub_type}`} />}>
							<div className='sub-type-icon'>
								<SvgIcon>{ICONS_LIST[sub_type]}</SvgIcon>
							</div>
						</Tooltip>
						<MuiPhoneNumber key={field._id} variant='outlined' fullWidth inputProps={formFieldInputProps} defaultCountry={field?.settings?.defaultCountry || 'fr'} disableCountryCode={false} disableAreaCodes={true} countryCodeEditable={false} autoFormat={false} onChange={(event, country) => handleChangeFieldSettings(field._id, 'defaultCountry', country.countryCode)} />
					</div>;
      case 'checkbox':
        return <div className='consent-container'>
						<Tooltip placement='bottom' title={<FormattedMessage id={`edition_menu.form.field_type.${sub_type}`} />}>
							<div className='sub-type-icon'>
								<SvgIcon>{ICONS_LIST[sub_type]}</SvgIcon>
							</div>
						</Tooltip>
						<TextField fullWidth variant='outlined' value={field.title} multiline minRows={2} onChange={evt => handleChangeFieldProperty(field._id, 'title', evt.target.value)} />
					</div>;
      default:
        return <div className='placeholder-container'>
						<Tooltip placement='bottom' title={<FormattedMessage id={`edition_menu.form.field_type.${sub_type}`} />}>
							<div className='sub-type-icon'>
								<SvgIcon>{ICONS_LIST[sub_type]}</SvgIcon>
							</div>
						</Tooltip>
						<TextField fullWidth variant='outlined' value={field.placeholder} onChange={evt => handleChangeFieldProperty(field._id, 'placeholder', evt.target.value)} />
					</div>;
    }
  }
  function renderAdvancedSettings(type) {
    switch (type) {
      case 'email':
        if (field?.settings?.hasDefaultDomain) {
          return <Box width='100%' display='flex' alignItems='center' justifyContent='center'>
							<RenderDefaultDomains defaultDomains={field.settings?.defaultDomains} />

							<IconButton size='small' onClick={() => advancedSettingsRef.current.open()}>
								<Settings />
							</IconButton>
						</Box>;
        }
        return <Box display='flex' justifyContent='center'>
						<Button variant='outlined' size='small' onClick={() => advancedSettingsRef.current.open()} startIcon={<Settings />}>
							<FormattedMessage id='edition_menu.form.field_description.advanced_settings.email' />
						</Button>
					</Box>;
      case 'number':
        if (field?.settings?.activeMinMax || field?.settings?.digit_limit) {
          return <Box width='100%' display='flex' justifyContent='space-evenly' alignItems='center'>
							{field?.settings?.digit_limit && <Typography variant='body1'>
									<b>
										<FormattedMessage id='edition_menu.form.field.number_limit.detail' />
									</b>{' '}
									{field.settings.digit_number}
								</Typography>}

							{field?.settings?.activeMinMax && <>
									<Typography variant='body1'>
										<b>Min:</b> {field.settings.min}
									</Typography>

									<Typography variant='body1'>
										<b>Max:</b> {field.settings.max}
									</Typography>
								</>}

							<IconButton size='small' onClick={() => advancedSettingsRef.current.open()}>
								<Settings />
							</IconButton>
						</Box>;
        }
        return <Box display='flex' justifyContent='center'>
						<Button variant='outlined' size='small' onClick={() => advancedSettingsRef.current.open()} startIcon={<Settings />}>
							<FormattedMessage id='edition_menu.form.field_description.advanced_settings.number' />
						</Button>
					</Box>;
      case 'text':
      case 'firstname':
      case 'lastname':
        if (field?.settings?.char_limit) {
          return <Box width='100%' display='flex' justifyContent='center' alignItems='center'>
							<Typography variant='body1'>
								<b>
									<FormattedMessage id='edition_menu.form.field.text_limit.detail' />
								</b>{' '}
								{field.settings.char_number}
							</Typography>

							<IconButton size='small' onClick={() => advancedSettingsRef.current.open()}>
								<Settings />
							</IconButton>
						</Box>;
        }
        return <Box display='flex' justifyContent='center'>
						<Button variant='outlined' size='small' onClick={() => advancedSettingsRef.current.open()} startIcon={<Settings />}>
							<FormattedMessage id='edition_menu.form.field_description.advanced_settings.text' />
						</Button>
					</Box>;
      case 'date':
        if (field?.settings?.activeDateRange) {
          return <Box width='100%' display='flex' justifyContent='space-evenly' alignItems='center'>
							<Typography variant='body1'>
								<b>
									<FormattedMessage id='edition_menu.form.field_description.before' />
								</b>{' '}
								{dayjs(field.settings.minDate).format('DD/MM/YYYY')}
							</Typography>

							<Typography variant='body1'>
								<b>
									<FormattedMessage id='edition_menu.form.field_description.after' />
								</b>{' '}
								{dayjs(field.settings.maxDate).format('DD/MM/YYYY')}
							</Typography>

							<IconButton size='small' onClick={() => advancedSettingsRef.current.open()}>
								<Settings />
							</IconButton>
						</Box>;
        }
        return <Box display='flex' justifyContent='center'>
						<Button variant='outlined' size='small' onClick={() => advancedSettingsRef.current.open()} startIcon={<Settings />}>
							<FormattedMessage id='edition_menu.form.field_description.advanced_settings.date' />
						</Button>
					</Box>;
      case 'select':
        return <Box width='100%' display='flex' justifyContent='space-evenly' alignItems='center'>
						{field?.settings?.multiple && <Typography variant='body1'>
								<b>
									<FormattedMessage id='edition_menu.form.field_description.multiple_selections' />
								</b>
							</Typography>}

						{field?.settings?.limit_choices && <Typography variant='body1'>
								<b>
									<FormattedMessage id='edition_menu.form.field_description.limit_selections' />
								</b>{' '}
								{field?.settings?.limit}
							</Typography>}

						<Button variant='contained' size='small' onClick={() => listSettingsRef.current.open()} startIcon={<Settings />}>
							<FormattedMessage id='edition_menu.form.field_description.options_settings' />
						</Button>
					</Box>;
      case 'checkbox':
        return <Typography variant='body2' color='textSecondary'>
						<FormattedMessage id='edition_menu.form.consent.customize_message.instruction' />
					</Typography>;
      default:
        return <div />;
    }
  }
  return <>
			<FieldSettingsContainer ref={ref} $isDragging={isDragging} {...delegatedProps}>
				<div className='drag-handle'>
					<DragIndicator />
				</div>

				<Box width='100%'>
					{field.sub_type !== 'checkbox' && <Box className='field-title'>
							<Typography variant='body1' noWrap>
								<b>
									<FormattedMessage id='edition_menu.form.label.title' />
								</b>
							</Typography>
							<TextField fullWidth variant='outlined'
          // disableUnderline
          placeholder={intl.formatMessage({
            id: 'edition_menu.form.label.placeholder'
          })} value={field.title || ''} onChange={evt => handleChangeFieldProperty(field._id, 'title', evt.target.value)} />
						</Box>}

					{renderInputField(field.type, field.sub_type)}
					<Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
						<Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}>
							<Tooltip title={<FormattedMessage id='edition_menu.form.field_description.tooltip.required' />} placement='bottom'>
								<div>
									<FormControlLabel value='start' control={<Checkbox onChange={({
                  target: {
                    checked
                  }
                }) => handleChangeFieldProperty(field._id, 'required', checked)} checked={field.required} color='primary' />} label={<FormattedMessage id='edition_menu.form.field_description.required' />} labelPlacement='start' sx={{
                  ml: 0,
                  mr: 3
                }} />
								</div>
							</Tooltip>

							{field?.variable ? <>
									<Tooltip title={<div>
												<FormattedMessage id='edition_menu.form.variable.tooltip.title' />
												<FormattedMessage id='edition_menu.form.variable.tooltip.subtitle' />
											</div>} placement='bottom'>
										<Typography variant='body1' style={{
                  marginRight: 8
                }}>
											<b>Var:</b> {field?.variable}
										</Typography>
									</Tooltip>
									<IconButton size='small' onClick={openVariablesModal}>
										<Edit fontSize='small' />
									</IconButton>
									<IconButton size='small' onClick={() => unassignVariable(field.variable, field._id)}>
										<Close fontSize='small' />
									</IconButton>
								</> : <Tooltip title={<div>
											<FormattedMessage id='edition_menu.form.variable.tooltip.title' />
											<FormattedMessage id='edition_menu.form.variable.tooltip.subtitle' />
										</div>} placement='bottom'>
									<Button variant='outlined' size='small' onClick={openVariablesModal}>
										<FormattedMessage id='edition_menu.variables.add' values={{
                  count: 1
                }} />
									</Button>
								</Tooltip>}
						</Box>

						<Box>{renderAdvancedSettings(field.type)}</Box>
					</Box>
				</Box>

				<Tooltip title={canDelete ? <FormattedMessage id='button.delete' /> : <FormattedMessage id='edition_menu.form.field.delete_error' />} placement='bottom'>
					<div>
						<IconButton size='small' disabled={!canDelete} onClick={() => confirmRef.current.open()}>
							<Delete />
						</IconButton>
					</div>
				</Tooltip>
			</FieldSettingsContainer>

			<ConfirmationModal size='sm' confirmationModalRef={confirmRef} title={<FormattedMessage id='modals.form.field_remove.title' />} onConfirm={onRemoveField} firstLine={<FormattedMessage id='modals.form.field_remove.sub_title' />} secondLine={<FormattedMessage id='modal.file_deletion.confirmation.instructions' />} confirmText={<FormattedMessage id='common.yes' />} cancelText={<FormattedMessage id='common.no' />} />

			<FieldAdvancedSettingsModal ref={advancedSettingsRef} field_id={field._id} field_settings={field.settings} field_type={field.type} handleChangeFieldSettings={handleChangeSettings} />

			<ListSettingsModal ref={listSettingsRef} field_id={field._id} field_settings={field.settings} handleChangeFieldSettings={handleChangeSettings} />

			<VariablesModal ref={changeVariableRef} currentVariables={currentVariables} currentVariable={field?.variable} allowType={getValidVariableTypes('field', field)} assignVariable={assignVariable} removeVariable={removeVariable} addNewVariable={addVariable} newVariable={newVariable} handleChangeNewVariableName={handleNewVariableNameChange} handleChangeNewVariableType={handleNewVariableTypeChange} fieldError={fieldError} onClose={onCloseVariablesModal} />
		</>;
});
function RenderDefaultDomains({
  defaultDomains
}) {
  const [domain, ...rest] = defaultDomains;
  return <Typography variant='body1'>
			<b>
				<FormattedMessage id='edition_menu.form.field_description.defaultDomain' />
			</b>{' '}
			{domain}
			{!_.isEmpty(rest) && <Tooltip title={<Box>
							{_.map(rest, (d, index) => <Typography key={index} variant='body2'>
									{d}
								</Typography>)}
						</Box>} placement='bottom'>
					<span>, +{rest.length}</span>
				</Tooltip>}
		</Typography>;
}
FieldSettings.displayName = 'FieldSettings';
export default FieldSettings;