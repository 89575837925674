import { ExpandMore } from '@mui/icons-material';
import { Box, Button, DialogActions, IconButton, TextField, Typography } from '@mui/material';
import { ConsentModalContainer } from 'components/styled/Modals';
import isEqual from 'fast-deep-equal';
import { isUrl } from 'lib/utils';
import SnackeetButton from 'organisms/buttons/SnackeetButton';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useImmer } from 'use-immer';
import { ModalWrapper } from '../ModalWrapper';
const GdprSettingsModal = forwardRef(({
  gdprConsent,
  handleChangeGdprConsent
}, ref) => {
  const intl = useIntl();
  const configRef = useRef();
  const [settings, setSettings] = useImmer(gdprConsent);

  // Keep local state up to date
  useEffect(() => {
    if (!isEqual(gdprConsent, settings)) {
      setSettings(() => gdprConsent);
    }
  }, [gdprConsent]);
  const open = () => {
    configRef.current.open();
  };
  const close = () => {
    configRef.current.close();
  };
  function handleChangeProperty(path, value) {
    setSettings(draft => {
      draft[path] = value;
    });
  }
  function onConfirm() {
    handleChangeGdprConsent([{
      path: 'title',
      value: settings.title
    }, {
      path: 'text',
      value: settings.text
    }, {
      path: 'button',
      value: settings.button
    }, {
      path: 'validation_button',
      value: settings.validation_button
    }, {
      path: 'url',
      value: settings.url
    }]);
    configRef.current.close();
  }
  useImperativeHandle(ref, () => ({
    open,
    close
  }));
  return <ModalWrapper title={intl.messages['modals.form.gdpr_settings.title']} ref={configRef} size='lg' hasCloseButton={false} disableBackdropClick>
			<Box width='100%' height='100%' overflow='auto' display='grid' gridTemplateColumns='400px 1fr' p={1}>
				<Box width='400px' display='flex' justifyContent='center' alignItems='center'>
					<ConsentModalContainer $isRelative onClick={evt => {
          evt.stopPropagation();
          evt.preventDefault();
        }}>
						<IconButton size='large'>
							<ExpandMore />
						</IconButton>
						<div style={{
            width: '100%'
          }}>
							<h2>{settings.title}</h2>
							{/* Remove all html tags except a */}
							<div style={{
              width: '100%',
              overflowX: 'hidden',
              padding: '0px 4px'
            }} dangerouslySetInnerHTML={{
              __html: settings?.text?.replace(/<(?!\s*\/?\s*a\b)[^>]*>/gi, '')
            }} />
							<SnackeetButton $isRounded={true}>{settings.button}</SnackeetButton>
							<SnackeetButton $isRounded={true} className='accept-button'>
								{settings.validation_button}
							</SnackeetButton>
						</div>
					</ConsentModalContainer>
				</Box>

				<Box overflow='auto' px={2} width='100%'>
					<Box mb={1}>
						<Typography variant='h4'>
							<FormattedMessage id='common.title' />
						</Typography>
						<TextField fullWidth multiline variant='outlined' minRows={2} placeholder={intl.messages['common.title']} onChange={({
            target: {
              value
            }
          }) => handleChangeProperty('title', value)} value={settings.title} margin='dense' />
					</Box>
					<Box mb={1}>
						<Typography variant='h4'>
							<FormattedMessage id='edition_menu.form.consent.text' />
						</Typography>
						<TextField fullWidth multiline variant='outlined' minRows={9} placeholder={intl.messages['edition_menu.form.consent.text.placeholder']} onChange={({
            target: {
              value
            }
          }) => handleChangeProperty('text', value)} value={settings.text} margin='dense' />
						<Typography variant='body2' color='textSecondary'>
							<FormattedMessage id='edition_menu.form.consent.customize_message.instruction' />
						</Typography>
					</Box>
					<Box mb={1}>
						<Typography variant='h4'>
							<FormattedMessage id='edition_menu.form.consent.button_hyperlien' />
						</Typography>
						<TextField fullWidth multiline variant='outlined' minRows={2} placeholder={intl.messages['edition_menu.form.consent.button_hyperlien.placeholder']} onChange={({
            target: {
              value
            }
          }) => handleChangeProperty('button', value)} value={settings.button} margin='dense' />
					</Box>
					<Box mb={1}>
						<Typography variant='h4'>
							<FormattedMessage id='edition_menu.form.consent.url' />
						</Typography>
						<TextField fullWidth multiline variant='outlined' minRows={2} placeholder={intl.messages['edition_menu.form.consent.url.placeholder']} onChange={({
            target: {
              value
            }
          }) => handleChangeProperty('url', value)} value={settings.url || ''} margin='dense' error={settings?.url ? !isUrl(settings.url)?.success : false} />
						{settings.url && !isUrl(settings.url).success && <Typography color='error'>
								<FormattedMessage id={isUrl(settings.url).messageId} />
							</Typography>}
					</Box>
					<Box mb={1}>
						<Typography variant='h4'>
							<FormattedMessage id='edition_menu.form.consent.button_validation' />
						</Typography>
						<TextField fullWidth multiline variant='outlined' minRows={2} placeholder={intl.messages['edition_menu.form.consent.validation_button.placeholder']} onChange={({
            target: {
              value
            }
          }) => handleChangeProperty('validation_button', value)} value={settings.validation_button} margin='dense' />
					</Box>
				</Box>
			</Box>

			<DialogActions>
				<Button variant='text' color='primary' onClick={() => close()}>
					<FormattedMessage id='button.cancel' />
				</Button>

				<Button variant='outlined' color='secondary' disabled={settings?.url ? !isUrl(settings.url)?.success : false} onClick={() => onConfirm()}>
					<FormattedMessage id='common.theming.confirm_button' />
				</Button>
			</DialogActions>
		</ModalWrapper>;
});
GdprSettingsModal.propTypes = {
  gdprConsent: PropTypes.object,
  handleChangeGdprConsent: PropTypes.func
};
GdprSettingsModal.displayName = 'GdprSettingsModal';
export default GdprSettingsModal;