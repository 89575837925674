import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'lib/dayjs';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getAppLocale } from 'redux/customer/selectors';
export default function CustomDatePicker({
  onChange,
  value,
  ...delegatedProps
}) {
  const appLocale = useSelector(getAppLocale);
  return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={appLocale}>
			<DatePicker value={dayjs(value)} onChange={onChange} {...delegatedProps} />
		</LocalizationProvider>;
}
CustomDatePicker.propTypes = {
  locale: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date), PropTypes.object])
};