import _ from 'lodash'
import { useSelector } from 'react-redux'
import { subscriptionSelector } from 'redux/subscription/selectors'

export default function useSubscriptionFeatures({ members, pages, banners, stories } = {}) {
	const subscription = useSelector(subscriptionSelector)
	const { feature_set: featureSet } = subscription
	const subscriptionFeatures = getSubscriptionFeatures(subscription)

	// Check for limit of members number
	if (_.isArray(members)) {
		subscriptionFeatures.hasEnoughSeats = members.length >= parseFloat(featureSet.seats)
	}

	// Check for limit of banners
	if (_.isArray(banners)) {
		subscriptionFeatures.hasEnoughBanners = banners.length >= parseFloat(featureSet.story_banners)
	}

	// check for limit of stories
	if (_.isArray(stories)) {
		subscriptionFeatures.hasEnoughStories = stories.length >= parseFloat(featureSet.stories_count)
	}

	return subscriptionFeatures
}

function getSubscriptionFeatures(subscription) {
	const { feature_set: featureSet } = subscription

	const commonFields = {
		isActive: undefined,
		object: subscription.object,
		planId: undefined,
		status: undefined,
		featureSet,
		ownerId: subscription.owner_id,
		hasAmp: Boolean(featureSet.amp_stories),
		hasAutomaticReply: hasAutomaticReply(subscription),
		hasCustomFonts: Boolean(featureSet.custom_fonts),
	}

	if (subscription.object === 'license') {
		return {
			...commonFields,
			isLicense: true,
			isActive: true,
			planId: subscription.planId,
			invoice_item_uuid: subscription.invoice_item_uuid,
			status: 'isActivated',
			version: subscription.version,
		}
	} else if (subscription.object === 'subscription') {
		const [planId, currency, period] = _.split(subscription.item_price_id, '-')
		return {
			...commonFields,
			isSubscription: true,
			isActive: ['in_trial', 'active'].includes(subscription.status),
			itemPriceId: subscription.item_price_id,
			planId,
			currency,
			period: _.toLower(period).replace(/ly$/, ''),
			status: subscription.status,
		}
	}
}

// For subscriptions, only allow automatic reply for active and paid plans
function hasAutomaticReply(subscription) {
	if (subscription.object === 'license') return Boolean(subscription.feature_set.automatic_reply)

	return (
		subscription.status === 'active' &&
		!/starter/i.test(subscription.item_price_id) &&
		Boolean(subscription.feature_set.automatic_reply)
	)
}
