import RatingFeedback from 'components/RatingFeedback';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalWrapper } from '../ModalWrapper';
export default function RatingFeedbackSettingsModal({
  trigger,
  rating,
  onSubmitConfig,
  recentColors,
  brandColors,
  recentFonts,
  updateRecentColors
}) {
  const ref = useRef();
  // const [attachment, setAttachment] = useImmer(initAttachment(currentAttachment, draftStory.story_settings.language))

  function submitConfig(form) {
    onSubmitConfig('feedback_form', form);
    ref.current.close();
  }
  return <ModalWrapper title={<FormattedMessage id='edition_menu.rating.modal.title' />} trigger={trigger} ref={ref}
  // onOpen={resetAttachment}
  // Avoid close modal when editing interacttion
  disableBackdropClick size='lg'>
			<RatingFeedback onSubmitConfig={submitConfig} rating={rating} recentColors={recentColors} brandColors={brandColors} recentFonts={recentFonts} updateRecentColor={updateRecentColors} />
		</ModalWrapper>;
}
RatingFeedbackSettingsModal.propTypes = {
  trigger: PropTypes.object,
  rating: PropTypes.object,
  updateCtaBlock: PropTypes.func,
  isCreating: PropTypes.bool
};