import { SvgIcon } from '@mui/material'

export default function InstagramReelsIcon(props) {
	return (
		<SvgIcon width='24' height='24' viewBox='0 0 24 24' fill='currentColor' {...props}>
			<line
				fill='none'
				stroke='currentColor'
				stroke-linejoin='round'
				stroke-width='2'
				x1='2.049'
				x2='21.95'
				y1='7.002'
				y2='7.002'
			></line>
			<line
				fill='none'
				stroke='currentColor'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='2'
				x1='13.504'
				x2='16.362'
				y1='2.001'
				y2='7.002'
			></line>
			<line
				fill='none'
				stroke='currentColor'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='2'
				x1='7.207'
				x2='10.002'
				y1='2.11'
				y2='7.002'
			></line>
			<path
				d='M2 12.001v3.449c0 2.849.698 4.006 1.606 4.945.94.908 2.098 1.607 4.946 1.607h6.896c2.848 0 4.006-.699 4.946-1.607.908-.939 1.606-2.096 1.606-4.945V8.552c0-2.848-.698-4.006-1.606-4.945C19.454 2.699 18.296 2 15.448 2H8.552c-2.848 0-4.006.699-4.946 1.607C2.698 4.546 2 5.704 2 8.552Z'
				fill='none'
				stroke='currentColor'
				stroke-linecap='round'
				stroke-linejoin='round'
				stroke-width='2'
			></path>
			<path
				d='M9.763 17.664a.908.908 0 0 1-.454-.787V11.63a.909.909 0 0 1 1.364-.788l4.545 2.624a.909.909 0 0 1 0 1.575l-4.545 2.624a.91.91 0 0 1-.91 0Z'
				fill-rule='evenodd'
			></path>
		</SvgIcon>
	)
}
