import styled from '@styled';
import { extractPositionProperties, isRectangleShape } from 'lib/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { memo } from 'react';
import BlockWrapper from './BlockWrapper';
const ShapeElement = styled.div`
	width: 100%;
	height: 100%;
	background-color: ${props => props.$backgroundColor};

	transform: ${props => props.$imageTransform};
	mask-image: ${({
  $maskImage
}) => `url(${$maskImage})`};
	mask-size: 100% 100%;
`;
function Shape({
  value,
  subType,
  outer_style,
  inner_style,
  animation
}) {
  const shapeTransform = inner_style?.transform;
  const {
    positionProperties,
    visualProperties
  } = extractPositionProperties(outer_style);
  const borderProperties = isRectangleShape(value) ? subType === 'button_invisible' ? {
    backgroundColor: 'transparent',
    background: 'repeating-linear-gradient(45deg,#227c9d,#227c9d .7px,transparent .7px,transparent 10px)',
    borderWidth: '0px'
  } : {
    ..._.omit(visualProperties, ['backgroundColor']),
    backgroundColor: outer_style.color
  } : {};
  return <BlockWrapper animation={animation} positionProperties={{
    ...positionProperties,
    ...borderProperties
  }}>
			<ShapeElement $imageTransform={shapeTransform} $backgroundColor={outer_style.color} $maskImage={isRectangleShape(value) ? undefined : value} />
		</BlockWrapper>;
}
Shape.propTypes = {
  value: PropTypes.string,
  subType: PropTypes.string,
  outer_style: PropTypes.object,
  inner_style: PropTypes.object,
  animation: PropTypes.object
};
export default memo(Shape);