/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import EndPageInterface from './EndPageInterface';
import FormPageInterface from './FormPageInterface';
import GamePageInterface from './GamePageInterface';
import MediaAnswerPageInterface from './MediaAnswerPageInterface';
import MultipleChoicesInterface from './MultipleChoicesInterface';
import RatingPageInterface from './RatingPageInterface';
import StartPageInterface from './StartPageInterface';
import StoryTimer from './TimerComponent';
export default function Interface({
  page,
  theming,
  messages,
  gdprConsent,
  storyType,
  isRewardsEnabled,
  hasQuiz,
  wheelComponentState
}) {
  // 👉 Important: use React Key to force components reset on page change
  function renderInterface(type) {
    switch (type) {
      case 'start_page':
        return <StartPageInterface key={page._id} page={page} theming={theming} messages={messages} />;
      case 'ending_page':
        return <EndPageInterface key={page._id} page={page} storyType={storyType} theming={theming} messages={messages} hasQuiz={hasQuiz} />;
      case 'multiple_choice':
        return <MultipleChoicesInterface key={page._id} page={page} theming={theming} messages={messages} storyType={storyType} isRewardsEnabled={isRewardsEnabled} />;
      case 'media_answer':
        return <MediaAnswerPageInterface key={page._id} page={page} />;
      case 'form':
        return <FormPageInterface key={page._id} page={page} theming={theming} messages={messages} gdprConsent={gdprConsent} />;
      case 'rating':
        return <RatingPageInterface key={page._id} page={page} />;
      case 'game':
        return <GamePageInterface key={page._id} page={page} theming={theming} gdprConsent={gdprConsent} wheelComponentState={wheelComponentState} />;
      default:
        return null;
    }
  }
  return <>
			<StoryTimer key={`preview-timer-${page._id}`} timer={page.timer} />
			{renderInterface(page.type)}
		</>;
}
Interface.propTypes = {
  page: PropTypes.object,
  theming: PropTypes.object,
  messages: PropTypes.object,
  gdprConsent: PropTypes.object,
  storyType: PropTypes.string,
  isRewardsEnabled: PropTypes.bool,
  hasQuiz: PropTypes.bool
};