import { DialogActions, DialogContent, DialogContentText, TextField, Typography } from '@mui/material';
import { ModalWrapper } from 'components/Modals';
import SubmitButton from 'organisms/buttons/SubmitButton';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
export default function DeleteBrandModal({
  brand,
  trigger,
  deleteBrand,
  onOpen,
  onClose
}) {
  const intl = useIntl();
  const deleteModalRef = useRef();
  const [input, setInput] = useState('');
  function handleEnterKey(evt) {
    if ((evt.key === 'Enter' || evt.keyCode === 13) && input === brand.name) {
      onDelete();
    }
  }
  async function onDelete() {
    await deleteBrand(brand._id);
    setInput('');
    deleteModalRef.current?.close();
  }
  function handleChange({
    target: {
      value
    }
  }) {
    setInput(value);
  }
  return <ModalWrapper ref={deleteModalRef} trigger={trigger} size='xs' title={intl.messages['modals.delete_brand.modal_title']} onOpen={onOpen} onClose={onClose}>
			<DialogContent dividers style={{
      flex: '1 1 220px'
    }}>
				<Typography variant='h2' gutterBottom color='primary'>
					<FormattedMessage id='modals.delete_brand.header' />
				</Typography>

				<DialogContentText component='div'>
					<FormattedMessage id='modals.delete_brand.instruction' />
				</DialogContentText>

				<DialogContentText color='primary'>
					<b>{brand.name}</b>
				</DialogContentText>

				<TextField sx={{
        mt: 1
      }} size='small' variant='outlined' fullWidth={true} label={intl.messages['modals.delete_brand.placeholder']} placeholder={brand.name} value={input} onChange={handleChange} error={input !== '' && input !== brand.name} onKeyUp={handleEnterKey} />
			</DialogContent>

			<DialogActions>
				<SubmitButton disabled={input !== brand.name} onClick={onDelete}>
					<FormattedMessage id='button.confirm' />
				</SubmitButton>
			</DialogActions>
		</ModalWrapper>;
}
DeleteBrandModal.propTypes = {
  brand: PropTypes.object,
  trigger: PropTypes.node,
  deleteBrand: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};