import { ArrowBack } from '@mui/icons-material';
import { Box, Divider, IconButton, Link, Tooltip, Typography } from '@mui/material';
import SearchBar from 'atoms/SearchBar';
import Loader from 'components/Loader';
import { useStoryDispatch, useStoryState } from 'lib/editorStore';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import SvgSvc from 'services/SvgSvc';
import useAsyncEffect from 'use-async-effect';
import { useDebouncedCallback } from 'use-debounce';
import { ShapeGrid, ShapeSectionHeader } from './ShapeSelector.style';
export default function ShapeSelector({
  onShapeAdded,
  onIconSelected,
  isBlockFrame = false,
  isIcon = false
}) {
  const intl = useIntl();
  const dispatch = useStoryDispatch();
  const customer = useSelector(state => state.customer);
  const selectedElement = useStoryState(s => s.selectedElement);
  const recently_used = useStoryState(s => s.drafStory?.recently_used);
  const [svgSection, setSvgSection] = useState('loading');
  const [svgRecentlyUsed, setSvgRecentlyUsed] = useState([]);
  const [svgList, setSvgList] = useState([]);
  const [svgPreviewList, setSvgPreviewList] = useState([]);
  const [search, setSearch] = useState('');
  const [globalSearch, setGlobalSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const searchRegex = new RegExp(search, 'gi');
  const globalSearchRegex = new RegExp(globalSearch, 'gi');
  const delayedSearch = useDebouncedCallback(getShapesSearch, 1000);
  useAsyncEffect(async isMounted => {
    const svgs = await SvgSvc.getPreview();
    if (isMounted()) {
      setSvgPreviewList(svgs);
      setSvgSection('preview');
    }
  }, []);
  useAsyncEffect(async isMounted => {
    let recentSvgs = [];
    if (!_.isEmpty(recently_used?.shape?.shapes)) {
      recentSvgs = await SvgSvc.getRecentlyUsed({
        shapes: recently_used?.shape?.shapes,
        limit: 6
      });
    }
    if (isMounted()) {
      if (!_.isEmpty(recentSvgs)) {
        setSvgRecentlyUsed(recentSvgs);
      }
    }
  }, [recently_used?.shape?.shapes]);
  async function handleClickSvgSection(svgType) {
    setSvgSection('loading');
    if (svgType === 'recently_used') {
      const results = await SvgSvc.getRecentlyUsed({
        shapes: recently_used?.shape?.shapes,
        limit: 0
      });
      setSvgList(results);
    } else {
      const results = await SvgSvc.get({
        svgType
      });
      setSvgList(results);
    }
    setSvgSection(svgType);
  }
  function onShapeSelected(evt, id, sectionName, sectionSVG) {
    evt.stopPropagation();
    evt.preventDefault();

    // const {
    // 	props: { width = 80, height = 80 },
    // } = ReactHtmlParser(sectionSVG)
    const width = 80;
    const height = 80;
    const ratio = parseInt(width) * (80 / parseInt(height));
    const newWidth = Math.floor(ratio);
    const url = `https://static.snackeet.com/snackeet_assets/V2/${sectionName}/${id.replaceAll(' ', '%20')}.png`;
    if (typeof onIconSelected === 'function') {
      onIconSelected(url);
    }
    if (isBlockFrame) {
      onShapeAdded();
      dispatch({
        type: 'UPDATE_BLOCK',
        data: {
          _id: selectedElement._id,
          path: 'inner_style.maskImage',
          value: url
        }
      });
    } else if (_.isFunction(onShapeAdded)) {
      onShapeAdded();
      if (isIcon) {
        dispatch({
          type: 'ADD_BLOCK',
          data: {
            blockType: 'icon',
            initialProperties: {
              value: url
              // 'outer_style.color': recently_used?.shape?.color,
              // 'outer_style.width': `${newWidth}px`,
            }
          }
        });
      } else {
        dispatch({
          type: 'ADD_BLOCK',
          data: {
            blockType: 'shape',
            initialProperties: {
              value: url,
              'outer_style.color': recently_used?.shape?.color,
              'outer_style.width': `${newWidth}px`
            }
          }
        });
      }
    } else {
      dispatch({
        type: 'UPDATE_BLOCK',
        data: {
          _id: selectedElement._id,
          path: 'value',
          value: url
        }
      });
    }
    dispatch({
      type: 'SET_RECENTLY_USED',
      data: {
        path: 'shape.shapes',
        value: {
          id,
          sectionName
        }
      }
    }, false);
  }
  function handleShapeSearch(value) {
    setSearch(value);
  }
  function handleShapeGlobalSearch(value) {
    delayedSearch(value);
    setGlobalSearch(value);
  }
  async function getShapesSearch(search) {
    setIsLoading(true);
    const results = await SvgSvc.getSvg({
      search,
      lang: customer.locale
    });
    setSvgList(results);
    setIsLoading(false);
  }
  function renderCurrentSvgSection() {
    if (svgSection === 'loading') {
      return <Box height={200}>
					<Loader $inline size={50} name='ThreeBounce' />
				</Box>;
    }
    if (svgSection === 'preview') {
      return <>
					{globalSearch.trim() !== '' ? isLoading ? <Box height={200}>
								<Loader $inline size={50} name='ThreeBounce' />
							</Box> : <ShapeGrid>
								{svgList.filter(elem => elem[customer.locale].match(globalSearchRegex)).map((elem, idx) => {
            return <div key={elem.id} title={elem[customer.locale]} dangerouslySetInnerHTML={{
              __html: elem.html
            }} onClick={event => onShapeSelected(event, elem.id, elem.type, elem.html)}></div>;
          })}
							</ShapeGrid> : <>
							{!_.isEmpty(svgRecentlyUsed) && <>
									<Fragment key={'recently_used'}>
										<Box display='flex' justifyContent='space-between' px={2} py={1}>
											<Typography variant='h4'>
												<FormattedMessage id={`editor.menu_shape.section.recently_used`} />
											</Typography>
											<Link component='button' onClick={() => handleClickSvgSection('recently_used')}>
												<FormattedMessage id='editor.menu_shape.see_all' />
											</Link>
										</Box>

										<ShapeGrid>
											{_.map(svgRecentlyUsed, recentSvg => <div key={recentSvg.id} dangerouslySetInnerHTML={{
                  __html: recentSvg.html
                }} onClick={event => onShapeSelected(event, recentSvg.id, recentSvg.type, recentSvg.html)} />)}
										</ShapeGrid>
									</Fragment>
									<Divider />
								</>}
							{getShapesBySection(svgPreviewList, isIcon).map((section, idx) => <Fragment key={idx}>
									{idx !== 0 && <Divider />}
									<Box display='flex' justifyContent='space-between' px={2} py={1}>
										<Typography variant='h4'>
											<FormattedMessage id={`editor.menu_shape.section.${section.className}`} />
										</Typography>
										{section.className === 'classic' ? <div /> : <Link component='button' onClick={() => handleClickSvgSection(section.className)}>
												<FormattedMessage id='editor.menu_shape.see_all' />
											</Link>}
									</Box>

									<ShapeGrid>
										{_.keysIn(section).map((key, index) => {
                if (key !== 'className') {
                  return <div key={key} dangerouslySetInnerHTML={{
                    __html: section[key]
                  }} onClick={event => onShapeSelected(event, key, section.className, section[key])} />;
                }
                return null;
              })}
									</ShapeGrid>
								</Fragment>)}
						</>}
				</>;
    }
    return <>
				<ShapeGrid>
					{svgList.filter(elem => elem[customer.locale].match(searchRegex)).map(elem => <div key={elem.id} title={elem[customer.locale]} dangerouslySetInnerHTML={{
          __html: elem.html
        }} onClick={event => onShapeSelected(event, elem.id, elem.type || svgSection, elem.html)}></div>)}
				</ShapeGrid>
			</>;
  }
  return <Box display='flex' flexDirection='column' width='100%'>
			<Box sx={{
      position: 'sticky',
      top: 0,
      zIndex: 2,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomColor: 'divider',
      mb: 1
    }}>
				<ShapeSectionHeader>
					{svgSection !== 'preview' ? <Tooltip title={intl.messages['button.back_to_list']}>
							<IconButton aria-label='go-back' size='small' onClick={() => setSvgSection('preview')}>
								<ArrowBack />
							</IconButton>
						</Tooltip> : <span />}
					{svgSection !== 'loading' && <Typography variant='h4'>
							<FormattedMessage id={`editor.menu_shape.section.${svgSection}`} />
						</Typography>}
				</ShapeSectionHeader>

				{svgSection === 'preview' ? <SearchBar value={globalSearch} onChange={handleShapeGlobalSearch} placeholder={intl.messages['editor.menu_shape.section.placeholder']} disableUnderline sx={{
        boxShadow: 'none',
        maxWidth: 'unset',
        px: 2,
        py: 1
      }} /> : svgSection !== 'recently_used' && <SearchBar value={search} onChange={handleShapeSearch} placeholder={intl.formatMessage({
        id: 'editor.menu_shape.section.placeholder'
      })} disableUnderline sx={{
        boxShadow: 'none',
        maxWidth: 'unset',
        px: 2,
        py: 1
      }} />}
			</Box>
			{renderCurrentSvgSection()}
		</Box>;
}
ShapeSelector.propTypes = {
  onShapeAdded: PropTypes.func,
  onIconSelected: PropTypes.func,
  isBlockFrame: PropTypes.bool,
  isIcon: PropTypes.bool
};
function getShapesBySection(svgPreviewList, isIcon) {
  if (isIcon) {
    return _.filter(svgPreviewList, section => _.includes(['social_network', 'survey', 'information', 'education', 'emoji', 'arrows'], section.className));
  } else {
    return _.filter(svgPreviewList, section => !_.includes(['social_network', 'survey', 'information', 'education', 'emoji'], section.className));
  }
}