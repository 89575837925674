import PropTypes from 'prop-types';
import { BackgroundImage } from '../styled';
export default function Image({
  value,
  inner_style,
  outer_style,
  blur
}) {
  return <BackgroundImage style={outer_style} position={inner_style} $blur={blur}>
			<img src={value} alt='' />
		</BackgroundImage>;
}
Image.propTypes = {
  value: PropTypes.string.isRequired,
  inner_style: PropTypes.object,
  outer_style: PropTypes.object
};