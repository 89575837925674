import { LockOutlined } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { FlexBanner } from 'containers/OrganizationSettings/SettingsPages/Plans/Plans.style';
import useSubscriptionFeatures from 'lib/hooks/useSubscriptionFeatures';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentOrganizationId, getCurrentSlug, getInstagramAccounts } from 'redux/organization/selectors';
import { updateOrganization } from 'redux/organization/slice';
import { getCurrentProjectId } from 'redux/project/selectors';
import InstagramSvc from 'services/InstagramSvc';
import { useImmer } from 'use-immer';
import AccountsView from './AccountsView';
import { InstagramAssetsContainer } from './InstagramAssets.styles';
import MediaView from './MediaView';
const VIEWS = ['accounts', 'media'];
const ASSETS_TYPE = ['posts', 'stories', 'reels'];
export default function InstagramAssets({
  assetsTypes = ASSETS_TYPE,
  // Array of assets tabs displayed in MediaView
  mediaTypes,
  // Array of strings of Facebook media type for filtering (can be either IMAGE or VIDEO or undefined for both)
  isMultipleSelect = false,
  // Enable or disable multiple selction mode
  onAssetSelect
}) {
  const organizationId = useSelector(getCurrentOrganizationId);
  const projectId = useSelector(getCurrentProjectId);
  const currentSlug = useSelector(getCurrentSlug);
  const {
    featureSet: {
      instagram: hasInstagram
    }
  } = useSubscriptionFeatures();
  const instagramAccounts = useSelector(getInstagramAccounts);
  const dispatchRedux = useDispatch();
  const [state, setState] = useImmer({
    view: _.isEmpty(instagramAccounts) || !hasInstagram ? 'accounts' : 'media',
    selectedAccount: _.first(instagramAccounts)
  });
  const onAccountSelect = useCallback(account => async () => {
    setState(draft => {
      draft.selectedAccount = account;
      draft.view = 'media';
    });
  }, [setState]);
  const onAccountDelete = useCallback(account => async () => {
    const updatedOrganization = await InstagramSvc.deleteAccount({
      page_id: account.pageId,
      organization_id: organizationId,
      project_id: projectId
    });
    dispatchRedux(updateOrganization(updatedOrganization));
    setState(draft => {
      if (draft.selectedAccount?.pageId === account.pageId) {
        draft.selectedAccount = undefined;
      }
      draft.view = 'accounts';
    });
  }, [organizationId, projectId, dispatchRedux, setState]);
  const goToView = useCallback(viewId => () => {
    setState(draft => {
      if (VIEWS.includes(viewId)) {
        draft.view = viewId;
      }
    });
  }, [setState]);
  if (!hasInstagram) {
    return <Box width='100%' height='100%' minHeight='400px' display='flex' alignItems='center' justifyContent='center'>
				<Box width='max-content'>
					<FlexBanner severity='warning' icon={<LockOutlined />}>
						<p>
							<FormattedMessage id='subscription.features.disabled' />
						</p>
						<Button href={`/${currentSlug}/settings?tab=subscription`} variant='contained' color='secondary'>
							<FormattedMessage id='subscription.features.upgrade_plan' />
						</Button>
					</FlexBanner>
				</Box>
			</Box>;
  }
  return <InstagramAssetsContainer>
			{state.view === 'accounts' && <AccountsView selectedAccount={state.selectedAccount} onAccountSelect={onAccountSelect} onAccountDelete={onAccountDelete} goToView={goToView} isDisabled={!hasInstagram} />}

			{state.view === 'media' && <MediaView selectedAccount={state.selectedAccount} assetsTypes={assetsTypes} mediaTypes={mediaTypes} isMultipleSelect={isMultipleSelect} goToView={goToView} onAccountSelect={onAccountSelect} onAssetSelect={onAssetSelect} />}
		</InstagramAssetsContainer>;
}
InstagramAssets.propTypes = {
  assetsTypes: PropTypes.array,
  mediaTypes: PropTypes.array,
  isMultipleSelect: PropTypes.bool,
  onAssetSelect: PropTypes.func.isRequired
};