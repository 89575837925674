// ⚠️ Custom loader feature was deprecated and not used anymore

import { css } from '@emotion/react';
import { Clear, Publish } from '@mui/icons-material';
import { Box, Button, Chip, CircularProgress, DialogActions, IconButton, Tooltip } from '@mui/material';
import styled from '@styled';
import { ConfirmationModal } from 'components/Modals';
import { LoaderContainer } from 'containers/Editor/StoryEditor/MediaAssets/Galleries/Galleries.style';
import useFiles from 'lib/hooks/useFiles';
import useUpload from 'lib/hooks/useUpload';
import { MIME_TYPES } from 'lib/upload';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultLoaderUrl } from 'redux/project/selectors';
import { updateProject } from 'redux/project/slice';
import ProjectsSvc from 'services/ProjectsSvc';
import useAsyncEffect from 'use-async-effect';
import { useImmer } from 'use-immer';
import { ModalWrapper } from './ModalWrapper';
const fileImporterStyle = {
  display: 'none'
};
const chipSxProp = {
  display: 'none',
  maxWidth: 'unset',
  position: 'absolute',
  bottom: 4,
  right: 4,
  fontSize: '12px'
};
const defaultChipSxProp = {
  ...chipSxProp,
  display: 'flex',
  backgroundColor: 'grey.200'
};
const CustomLoaderModal = forwardRef(({
  bannerLoader,
  onBannerLoaderSelect
}, ref) => {
  const intl = useIntl();
  const {
    enqueueSnackbar
  } = useSnackbar();
  const dispatch = useDispatch();
  const deleteModalRef = useRef();
  const defaultLoaderModalRef = useRef();
  const fileImporterRef = useRef();
  const [state, setState] = useImmer({
    isLoading: true,
    isDeleting: false,
    selectedLoader: undefined,
    loaderToDelete: undefined,
    loaderList: []
  });
  const organisationId = useSelector(state => state.organization?._id);
  const projectId = useSelector(state => state.project?._id);
  const defaultLoaderUrl = useSelector(getDefaultLoaderUrl);
  const {
    isUploading,
    handleUpload
  } = useUpload({
    acceptedMimeTypes: Object.values(MIME_TYPES.image)
  });
  const {
    getLoaderFiles,
    deleteFile
  } = useFiles();
  function onConfirm() {
    if (state.selectedLoader) {
      onBannerLoaderSelect({
        type: state.selectedLoader?.isDefault ? 'workspace' : 'custom',
        url: state.selectedLoader?.url
      });
    }
    ref.current?.close();
  }
  function resetState() {
    setState(draft => {
      const selectedLoader = _.find(draft.loaderList, {
        url: bannerLoader?.url
      });
      draft.isLoading = false;
      draft.selectedLoader = selectedLoader;
      draft.loaderToDelete = undefined;
    });
  }
  function onClose() {
    resetState();
    ref.current?.close();
  }
  async function onFileSelected(evt) {
    // Convert FileList to array of Files and upload
    const files = await handleUpload([...evt.target.files], '/upload/loader');
    if (files.length === 1) {
      // Automatically select favicon if only one file uploaded
      const loaderFile = files[0];
      onBannerLoaderSelect({
        type: 'custom',
        url: loaderFile.url
      });
      setState(draft => {
        draft.isLoading = false;
        draft.loaderList.push(loaderFile);
        draft.selectedLoader = loaderFile;
        draft.loaderToDelete = undefined;
      });
      ref.current?.close();
    } else {
      setState(draft => {
        draft.loaderList.push(...files);
      });
    }
  }
  function onDelete(loaderFile) {
    setState(draft => {
      draft.loaderToDelete = loaderFile;
    });
    deleteModalRef.current?.open();
  }
  async function onConfirmDelete() {
    const isCurrentLoader = state.loaderToDelete.url === bannerLoader?.url;

    // Prevent triggering delete multiple times
    if (state.isDeleting) return;
    setState(draft => {
      draft.isDeleting = true;
    });
    await deleteFile(state.loaderToDelete._id);
    setState(draft => {
      _.remove(draft.loaderList, {
        _id: draft.loaderToDelete?._id
      });
      draft.loaderToDelete = undefined;
      draft.isDeleting = false;
    });

    // Reset current loader to workspace loader
    if (isCurrentLoader) {
      onBannerLoaderSelect({
        type: 'workspace',
        url: defaultLoaderUrl
      });
    }
    deleteModalRef.current?.close();
  }
  function onSelectAsDefault(loaderFile) {
    onSelect(loaderFile);
    defaultLoaderModalRef.current?.open();
  }
  async function onConfirmSetAsDefault() {
    try {
      await ProjectsSvc.update(projectId, organisationId, {
        loader: state.selectedLoader?.url
      });
      dispatch(updateProject({
        loader: state.selectedLoader?.url
      }));
      enqueueSnackbar(intl.formatMessage({
        id: 'toast.success.update.project'
      }), {
        variant: 'success'
      });
    } catch (error) {
      enqueueSnackbar(intl.formatMessage({
        id: 'toast.error.update.project'
      }), {
        variant: 'error'
      });
    }
    defaultLoaderModalRef.current?.close();
  }
  function onSelect(loaderFile) {
    setState(draft => {
      draft.selectedLoader = loaderFile;
    });
  }
  useAsyncEffect(async isMounted => {
    const loaderList = await getLoaderFiles();
    if (isMounted()) {
      setState(draft => {
        draft.isLoading = false;
        draft.loaderList = loaderList;
      });
    }
  }, []);
  useEffect(() => {
    if (bannerLoader?.url !== state.selectedLoader?.url) {
      const currentLoaderFile = _.find(state.loaderList, {
        url: bannerLoader?.url
      });
      onSelect(currentLoaderFile);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [bannerLoader, state.loaderList]);
  return <ModalWrapper size='sm' ref={ref} title={!state.isLoading ? <FormattedMessage id='modals.widget_loader.title' values={{
    count: state.loaderList.length
  }} /> : ''} onClose={resetState}>
			<Box overflow='auto' px={2} py={3} display='flex' flexDirection='column' alignItems='center'>
				<Box mb={1}>
					<Button size='large' variant='contained' color='primary' disabled={isUploading} onClick={() => fileImporterRef.current?.click()} startIcon={!isUploading ? <Publish /> : <CircularProgress color='primary' size={20} />}>
						<FormattedMessage id='button.import' />
					</Button>
				</Box>

				{state.isLoading ? <LoaderContainer>
						<CircularProgress disableShrink />
					</LoaderContainer> : <ImagesList bannerLoader={bannerLoader} defaultLoaderUrl={defaultLoaderUrl} loaderList={state.loaderList} selectedLoader={state.selectedLoader} onSelect={onSelect} onDelete={onDelete} onSelectAsDefault={onSelectAsDefault} />}

				<ConfirmationModal size='sm' confirmationModalRef={deleteModalRef} title={<FormattedMessage id='modal.file_deletion.confirmation.header.image' />} onConfirm={onConfirmDelete} firstLine={<FormattedMessage id='modal.file_deletion.confirmation.sub_header' />} secondLine={<FormattedMessage id='modal.file_deletion.confirmation.instructions' />} confirmText={<FormattedMessage id='common.yes' />} cancelText={<FormattedMessage id='common.no' />} />

				<ConfirmationModal size='sm' confirmationModalRef={defaultLoaderModalRef} title={<FormattedMessage id='modals.widget_loader.button.default' />} onConfirm={onConfirmSetAsDefault} firstLine={<FormattedMessage id='modals.widget_loader.confirm_default.firstLine' />} secondLine={<FormattedMessage id='modals.widget_loader.confirm_default.secondLine' />} confirmText={<FormattedMessage id='common.yes' />} cancelText={<FormattedMessage id='common.no' />} />
			</Box>

			<input ref={fileImporterRef} style={fileImporterStyle} id='file-import-trigger' multiple type='file' accept='image/jpeg,image/png,image/svg+xml' onChange={onFileSelected} />

			<DialogActions>
				<Button variant='text' onClick={onClose}>
					<FormattedMessage id='button.cancel' />
				</Button>

				<Button variant='outlined' color='secondary' onClick={onConfirm} disabled={!bannerLoader && !state.selectedLoader}>
					<FormattedMessage id='button.confirm' />
				</Button>
			</DialogActions>
		</ModalWrapper>;
});
CustomLoaderModal.propTypes = {
  storyFavicon: PropTypes.object,
  onStoryFaviconSelected: PropTypes.func
};
CustomLoaderModal.displayName = 'CustomLoaderModal';
export default CustomLoaderModal;
function ImagesList({
  defaultLoaderUrl,
  loaderList,
  bannerLoader,
  selectedLoader,
  onSelect,
  onDelete,
  onSelectAsDefault
}) {
  const intl = useIntl();
  const hasExternalImageLink = bannerLoader?.url !== defaultLoaderUrl && !_.some(loaderList, {
    url: bannerLoader?.url
  });
  return <Box width='100%' overflow='auto' display='flex' justifyContent='space-evenly' alignItems='center' p={2}>
			<LoaderCard $isSelected={selectedLoader?.url === defaultLoaderUrl}>
				<img alt='Default Loader' src={defaultLoaderUrl} onClick={() => onSelect({
        isDefault: true,
        url: defaultLoaderUrl
      })} />
				<DefaultLabel />
			</LoaderCard>

			{/* if loader is an external link, the selectedLoader is undefined */}
			{hasExternalImageLink && <LoaderCard $isSelected={!selectedLoader}>
					<img alt='External Image' src={bannerLoader?.url} onClick={() => onSelect(undefined)} />
					<Chip size='small' label={<FormattedMessage id='edition_menu.cta_options.redirection_type.external' />} sx={chipSxProp} />
				</LoaderCard>}

			{_.map(loaderList, loaderFile => defaultLoaderUrl !== loaderFile.url && <LoaderCard key={loaderFile._id} $isSelected={selectedLoader?._id === loaderFile._id}>
							<img alt={loaderFile._id} src={loaderFile.url} onClick={() => onSelect(loaderFile)} />
							<IconButton size='small' title={intl.formatMessage({
        id: 'common.delete'
      })} onClick={() => onDelete(loaderFile)} sx={{
        display: 'none',
        position: 'absolute',
        top: -10,
        right: -10,
        color: '#fff',
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 1)'
        }
      }}>
								<Clear />
							</IconButton>
							<Chip color='primary' size='small' label={<FormattedMessage id='modals.widget_loader.button.default' />} onClick={() => onSelectAsDefault(loaderFile)} sx={chipSxProp} />
						</LoaderCard>)}
		</Box>;
}
ImagesList.propTypes = {
  defaultLoaderUrl: PropTypes.string,
  loaderList: PropTypes.array,
  bannerLoader: PropTypes.object,
  selectedLoader: PropTypes.object,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
  onSelectAsDefault: PropTypes.func
};
function DefaultLabel() {
  return <Tooltip title={<FormattedMessage id='modals.widget_loader.tooltip.default' />} placement='bottom'>
			<Chip size='small' label={<FormattedMessage id='modals.widget_loader.label.default' />} sx={defaultChipSxProp} />
		</Tooltip>;
}
const selectedStyle = ({
  theme
}) => css`
	outline: 4px solid ${theme.palette.primary.main}!important;
`;
const LoaderCard = styled.div`
	overflow: visible;
	display: grid;
	place-items: center;
	position: relative;
	height: 100px;
	width: 100px;
	cursor: pointer;
	margin: ${({
  theme
}) => theme.spacing(1)};
	transition: transform 150ms ease-in;
	outline: none;
	${({
  $isSelected
}) => $isSelected ? selectedStyle : ''}

	&:hover {
		outline: ${({
  theme
}) => `1px solid ${theme.palette.text.primary}`};
		.MuiIconButton-root {
			display: block;
		}
		.MuiChip-clickable {
			display: flex;
		}
	}

	img {
		width: 100%;
		height: 100%;
		overflow: hidden;
		object-fit: cover;
	}
`;