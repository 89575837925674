import { SvgIcon } from '@mui/material'

export default function Share(props) {
	return (
		<SvgIcon width='24px' height='24px' viewBox='0 0 21 21' {...props}>
			<path
				fill='none'
				stroke='currentColor'
				strokeLinecap='round'
				strokeLinejoin='round'
				d='m14.5 7.5l-3.978-4l-4.022 4m4.022-3.979V15.5m-3.022-5h-2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-2'
			/>
		</SvgIcon>
	)
}
