import { css } from '@emotion/react';
import styled from '@styled';
import { transparentize } from 'polished';
const interfaceElementSelectedStyle = ({
  theme
}) => css`
	box-shadow: inset 0 0 0 2px ${theme.palette.primary.main};
	background-color: ${transparentize(0.8, theme.palette.primary.main)};
	pointer-events: all;
`;
const attachmentUnselected = $theme => css`
	background: ${$theme === 'dark' ? 'linear-gradient(0,hsla(0,0%,100%,0.25),hsla(0,0%,100%,0))' : 'linear-gradient(0,rgba(0,0,0,0.15),transparent)'};
`;
export const OutlinkAttachmentContainer = styled.div`
	width: 100%;
	height: 84px;
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-bottom: 14px;

	${({
  $isSelected,
  theme
}) => $isSelected && interfaceElementSelectedStyle({
  theme
})};
`;
export const OutlinkButton = styled.div`
	max-width: calc(100% - 64px);
	height: 16px;
	border-radius: 30px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
	padding: 10px 6px;
	display: flex;
	background-color: ${({
  $isDarkTheme
}) => $isDarkTheme ? 'rgb(0,0,0)' : 'rgb(255,255,255)'};
	color: ${({
  $isDarkTheme
}) => $isDarkTheme ? 'rgb(255,255,255)' : 'rgb(0,0,0)'};
	place-items: center;
	box-sizing: content-box;

	span {
		display: block;
		max-width: 210px;
		color: ${({
  $isDarkTheme
}) => $isDarkTheme ? 'rgb(255,255,255)' : 'rgb(0,0,0)'};
		font-family: Roboto, sans-serif;
		font-weight: 700;
		font-size: 16px;
		letter-spacing: 0.3px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding: ${({
  $hasIcon
}) => $hasIcon ? '0 8px 0 6px' : '0 8px'};
		cursor: pointer;
	}
`;
export const OutlinkButtonImage = styled.div`
	height: 24px;
	width: 24px;
	vertical-align: middle;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50%;
	border-radius: 50%;
	background-image: url(${({
  src
}) => src});
`;
export const AttachmentContainer = styled.div`
	width: 100%;
	height: 92px;
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 8px;

	${({
  theme,
  $isSelected,
  $theme
}) => $isSelected ? interfaceElementSelectedStyle({
  theme
}) : attachmentUnselected($theme)};
`;
export const AttachmentButton = styled.div`
	height: 32px;
	width: 32px;
	margin-bottom: 12px;
	border-radius: 50%;
	background-color: ${({
  $theme
}) => $theme === 'dark' ? '#000' : '#fff'};

	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;
export const AttachmentText = styled.span`
	display: block;
	margin-bottom: 20px;
	max-width: 210px;
	line-height: 20px;
	font-family: Roboto, sans-serif;
	font-size: 16px;
	font-weight: 700;
	letter-spacing: 0.3px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: ${({
  $theme
}) => $theme === 'dark' ? '#000' : '#fff'};
	cursor: pointer;
`;