import { css } from '@emotion/react'
import { Box, Button, Chip, DialogContent, FormControl } from '@mui/material'
import styled from '@styled'
import { Figure } from 'components/styled'
import { SelectableOption } from 'components/styled/Settings'
import { PREVIEW_HEIGHT, PREVIEW_WIDTH } from 'lib/constants'
import { transparentize } from 'polished'
import {
	snackeet_blue,
	snackeet_border,
	snackeet_dark_grey,
	snackeet_grey,
	snackeet_text,
	snackeet_yellow,
} from 'styles/colors'

const isStoryCardDisabledStyle = css`
	cursor: not-allowed;
	filter: grayscale(100%);
	background-color: ${snackeet_grey};
	color: ${snackeet_dark_grey};
	box-shadow: none;
	border: none;

	&:hover {
		transform: none;
	}
`

export const TEMPLATE_CARD_WIDTH = 130

export const TemplateCard = styled.figure`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 8px;
	position: relative;
	margin: 0;
	padding: ${({ theme }) => theme.spacing(1, 2)};
	border-radius: 4px;
	cursor: pointer;
	overflow: hidden;

	& > img {
		object-fit: cover;
		aspect-ratio: ${PREVIEW_WIDTH} / ${PREVIEW_HEIGHT};
		width: ${TEMPLATE_CARD_WIDTH}px;
		box-shadow: ${({ theme }) => theme.shadows[2]};
		border: none;
		border-radius: 4px;
	}

	& > .MuiButton-root {
		pointer-events: none;
		opacity: 0;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	figcaption {
		margin: ${({ theme }) => theme.spacing(1, 0)};
		color: ${({ theme }) => theme.palette.text.primary};
		font-size: 16px;
		font-weight: bold;
		cursor: inherit;
		width: 140px;
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		word-break: break-word;
		text-align: center;
	}

	&:hover {
		background: ${({ theme }) => theme.palette.action.hover};
		& > .MuiButton-root {
			opacity: 1;
		}
		img {
			filter: blur(1px);
		}
	}
`

const activeCardStyle = ({ $isActive, theme }) =>
	$isActive &&
	css`
		background: ${theme.palette.action.selected};
		&:hover {
			background: ${theme.palette.action.selected};
		}
	`

export const PageTemplateCard = styled(TemplateCard)`
	${activeCardStyle};
`

export const CustomPageTemplateCard = styled(PageTemplateCard)`
	.cover {
		position: absolute;
		inset: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		row-gap: 16px;
		background-color: rgba(255, 255, 255, 0.8);
		opacity: 0;
		cursor: pointer;
		z-index: 40;
		transition: all ease-in 150ms;

		&:hover {
			opacity: 1;
		}
	}
`

export const TemplatesWrapper = styled.div`
	width: 100%;
	margin: 0 auto;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(${TEMPLATE_CARD_WIDTH + 20}px, 1fr));
	grid-gap: ${({ theme }) => theme.spacing(2)};
	align-items: stretch;
	justify-content: flex-start;
	padding: ${({ theme }) => theme.spacing(1, 3)};
	overflow: auto;

	${Figure} {
		grid-column: 1 / -1;
	}
`

export const CreateStoryCard = styled(TemplateCard)`
	.templateImage {
		img {
			max-height: 80px;
			height: 100%;
			width: auto;
			object-fit: contain;
		}

		${({ $isDisabled }) => $isDisabled && isStoryCardDisabledStyle}
	}

	box-shadow: ${({ $isDisabled }) => $isDisabled && 'none'};
`

export const AnswerButtonSettingsWrapper = styled.div`
	width: 100%;
	height: 100%;
	margin: 0 auto;
	overflow: auto;
	display: grid;
	grid-template-columns: minmax(250px, 1fr) 2fr;
	grid-gap: 16px;
	padding: ${({ theme }) => theme.spacing(0, 1, 1, 1)};

	.MuiTypography-h3 {
		font-weight: bold;
	}

	.properties-container {
		border-left: 0.5px solid rgb(72, 72, 71);
		margin: ${({ theme }) => theme.spacing(0, 2.5, 2, 2.5)};
		padding: ${({ theme }) => theme.spacing(0, 5, 0, 1)};
		box-sizing: border-box;
		margin-top: -8px;

		.quizz-answer-redirection {
			.redirect-title {
				font-size: 15px;
			}

			.correct-answer {
				font-weight: bold;
				color: ${({ theme }) => theme.palette.success.main};
			}
			.incorrect-answer {
				font-weight: bold;
				color: ${({ theme }) => theme.palette.error.main};
			}
			.partial-answer {
				font-weight: bold;
				color: ${({ theme }) => theme.palette.secondary.main};
			}

			.MuiInput-root {
				background-color: white;
				padding-left: ${({ theme }) => theme.spacing(1)};
			}
		}

		.MuiOutlinedInput-root {
			input {
				padding: ${({ theme }) => theme.spacing(1, 1.5)};
			}
		}

		.MuiOutlinedInput-input {
			padding: ${({ theme }) => theme.spacing(1.2, 1.5)};
		}
	}

	.action-settings-header {
		display: grid;
		grid-template-columns: 20px 1fr 70px 3fr 30px;
		grid-gap: 8px;
		margin-bottom: 4px;

		.MuiTypography-h3 {
			font-weight: bold;
		}
	}

	.property-header {
		width: 100%;
		height: 25px;
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		grid-gap: 16px;
		place-items: center;
		color: #8b8b8b;
		margin: ${({ theme }) => theme.spacing(1, 0)};

		.line-before {
			background-color: #8b8b8b;
			width: 100%;
			height: 1px;
		}
		.line-after {
			background-color: #8b8b8b;
			width: 100%;
			height: 1px;
		}
	}
`

const gridStyle = css`
	width: 100%;
	display: grid;
	grid-template-columns: auto 2fr minmax(100px, 120px) minmax(220px, 1fr) 24px;
	grid-gap: 8px;
`

export const ButtonSettingHeader = styled.div`
	${gridStyle}
	place-items: center;

	.MuiTypography-h3 {
		font-weight: bold;
	}
`

export const ButtonSettingsContainer = styled.div`
	${gridStyle}
	align-items: center;
	padding: 6px;
	margin-bottom: ${({ theme }) => theme.spacing(1.5)};
	border-radius: 4px;
	transition: background-color ease-in-out 250ms;

	.MuiInputBase-root {
		background-color: ${({ theme }) => theme.palette.background.paper};
	}

	background-color: rgba(72, 72, 71, 0.05);

	&.dragging {
		background-color: rgb(179, 229, 252) !important;
	}

	&.correct {
		background-color: #dcedc8;
	}

	&.isHidden {
		background-color: rgba(72, 72, 71, 0.5);
	}
`

export const ActionSettingsContainer = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 20px 1fr 70px 3fr 30px;
	grid-gap: 8px;
	align-items: center;
	padding: 6px;
	margin-bottom: ${({ theme }) => theme.spacing(1.5)};
	background-color: ${({ $isDragging }) => ($isDragging ? 'rgb(179, 229, 252)' : 'rgba(72, 72, 71, 0.05)')};
	border-radius: 4px;

	.MuiInputBase-root {
		background-color: ${({ theme }) => theme.palette.background.paper};
	}

	/* .MuiTextField-root {
		background-color: white;
	} */

	.MuiInputLabel-formControl {
		top: 2px;
		left: 4px;
		font-weight: bold;
		z-index: 9;
	}

	.MuiOutlinedInput-multiline {
		padding: 0px;
	}
`

export const FileDownloaderInput = styled.div`
	width: 100%;
	padding: ${({ theme }) => theme.spacing(1)};
	display: flex;
	align-items: center;
	flex-direction: column;

	.MuiButton-root {
		width: 200px;
	}
`

export const FileEntry = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: ${({ theme }) => transparentize(0.8, theme.palette.primary.main)};
	width: 100%;
	border-radius: 4px;
	padding: ${({ theme }) => theme.spacing(1)};

	span {
		flex: 1;
		text-align: center;
		text-overflow: ellipsis;
		overflow: hidden;
		height: 15px;
	}

	.MuiIconButton-root {
		margin-left: ${({ theme }) => theme.spacing(1)};
	}
`

export const WheelSettingsContainer = styled.div`
	width: 100%;
	height: 100%;
	margin: 0 auto;
	overflow: auto;
	display: grid;
	grid-template-columns: minmax(250px, 1fr) 2.5fr;
	grid-gap: 16px;
	padding-bottom: ${({ theme }) => theme.spacing(1)};

	.vertical-property-line {
		margin: ${({ theme }) => theme.spacing(1.5, 0)};

		.vertical-property-field {
			padding: ${({ theme }) => theme.spacing(1)};
			background-color: #f9fafb;
			border-radius: 8px;
			border: solid #afafaf;
			font-weight: bold;
			font-size: 12px;
			border-width: 1px 1px 0px 1px;
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
		}

		.vertical-property-content {
			padding: ${({ theme }) => theme.spacing(1)};
			border-radius: 8px;
			background-color: white;
			border: 1px solid #afafaf;
			border-top-left-radius: 0px;
			border-top-right-radius: 0px;
		}
	}

	.property-line {
		width: 100%;
		display: flex;
		align-items: center;
	}

	.property-field {
		min-width: 90px;
		width: 90px;
		min-height: 38px;
		height: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		text-align: left;
		/* font-weight: bold; */
		font-size: 12px;
		padding: ${({ theme }) => theme.spacing(0.5, 1)};
	}

	.property-content {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: ${({ theme }) => theme.spacing(0, 1)};

		.MuiAutocomplete-inputRoot {
			padding: 4px 65px 4px 4px;
		}

		h3 {
			text-overflow: ellipsis;
			max-width: 100px;
			overflow: hidden;
		}
	}
`

export const FormSettingsWrapper = styled.div`
	width: 100%;
	height: 100%;
	margin: 0 auto;
	overflow: auto;
	display: grid;
	grid-template-columns: minmax(250px, 1fr) 2.5fr;
	grid-gap: 16px;
	padding-bottom: ${({ theme }) => theme.spacing(1)};

	.properties-container {
		border-left: 0.5px solid rgb(72, 72, 71);
		margin: ${({ theme }) => theme.spacing(0, 2.5, 2, 2.5)};
		padding: ${({ theme }) => theme.spacing(0, 5, 0, 1)};
		box-sizing: border-box;

		.MuiOutlinedInput-root {
			input {
				padding: ${({ theme }) => theme.spacing(1, 1.5)};
			}
		}

		.MuiOutlinedInput-input {
			padding: ${({ theme }) => theme.spacing(1.2, 1.5)};
		}
	}

	.action-settings-header {
		display: grid;
		grid-template-columns: 20px 1fr 70px 3fr 30px;
		grid-gap: 8px;
		margin-bottom: 4px;

		.MuiTypography-h3 {
			font-weight: bold;
		}
	}

	.property-header {
		width: 100%;
		height: 25px;
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		grid-gap: 16px;
		place-items: center;
		color: #8b8b8b;
		margin: ${({ theme }) => theme.spacing(1, 0)};

		.line-before {
			background-color: #8b8b8b;
			width: 100%;
			height: 1px;
		}
		.line-after {
			background-color: #8b8b8b;
			width: 100%;
			height: 1px;
		}
	}
`

export const AmpFormSettingsWrapper = styled(FormSettingsWrapper)`
	grid-template-columns: minmax(350px, 1fr) 2fr;

	.MuiTabs-root {
		width: 400px;
		margin-bottom: 0;
	}

	.attachment-content-scrollable {
		height: calc(100vh - 295px);
		overflow: auto;
	}

	.properties-wrapper {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		margin-top: 8px;
	}

	.property-line {
		width: 100%;
		display: flex;
		align-items: center;
	}

	.property-field {
		min-width: 90px;
		width: 90px;
		min-height: 38px;
		height: 100%;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-weight: bold;
		font-size: 12px;
		padding: ${({ theme }) => theme.spacing(0.5, 1)};
	}

	.property-content {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: ${({ theme }) => theme.spacing(0, 1)};

		.MuiAutocomplete-inputRoot {
			padding: 4px 65px 4px 4px;
		}

		h3 {
			text-overflow: ellipsis;
			max-width: 100px;
			overflow: hidden;
		}
	}
`

export const FieldSettingsContainer = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 40px 1fr 40px;
	grid-gap: 8px;
	place-items: center;
	padding: 6px;
	background-color: ${({ $isDragging }) => ($isDragging ? 'rgb(179, 229, 252)' : 'rgba(72, 72, 71, 0.05)')};
	margin-bottom: ${({ theme }) => theme.spacing(1.5)};
	border-radius: 4px;
	transition: background-color ease-in-out 250ms;

	.MuiInputBase-root {
		background-color: white;
	}

	.MuiOutlinedInput-root {
		input {
			padding: ${({ theme }) => theme.spacing(1, 1.5)};
		}
	}
	.MuiOutlinedInput-input {
		padding: ${({ theme }) => theme.spacing(1.2, 1.5)};
	}

	.MuiOutlinedInput-multiline {
		padding: 0px;
	}

	.placeholder-container {
		display: flex;
		align-items: center;
		height: 36px;

		.MuiOutlinedInput-root {
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
		}
		.MuiFormControl-root {
			height: 100%;
		}
	}

	.consent-container {
		display: flex;

		.MuiOutlinedInput-root {
			border-top-left-radius: 0px;
		}
		.MuiFormControl-root {
			height: 100%;
		}

		.sub-type-icon {
			height: 33px;
		}

		.MuiInputBase-multiline {
			padding: 0px;
		}
	}

	.field-title {
		display: grid;
		grid-template-columns: auto 1fr;
		grid-gap: 4px;
		align-items: center;
		margin-bottom: 4px;

		.MuiInputBase-root {
			input {
				padding: 6px 8px;
			}
		}
	}

	.sub-type-icon {
		height: 100%;
		width: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #dedddd;
		border: 1px solid rgba(0, 0, 0, 0.23);
		border-right-width: 0px;
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
`

export const WheelOptionSettingsContainer = styled(FieldSettingsContainer)`
	border: ${({ $isDragging }) => ($isDragging ? snackeet_blue : snackeet_border)} solid 1px;

	&:hover {
		border-color: ${snackeet_text};
	}
`

export const AddNewFieldContainer = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
	grid-gap: 8px;
	align-items: center;
	margin: ${({ theme }) => theme.spacing(1, 0, 2)};
	padding: ${({ theme }) => theme.spacing(0, 1)};
`

export const AddNewAmpFieldContainer = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(50px, 70px));
	grid-gap: 8px;
	margin: ${({ theme }) => theme.spacing(0.5, 0, 1.5)};
	padding: ${({ theme }) => theme.spacing(0, 1)};

	p {
		font-size: 12px;
	}
`

export const ChipVariable = styled(Chip)`
	background: ${({ $bgColor }) => transparentize(0.8, $bgColor)};
	border: 1px solid ${({ $bgColor }) => $bgColor};
	width: 70px;

	p {
		font-weight: bold;
	}
`

export const ModalContent = styled(DialogContent)`
	padding: ${({ theme }) => theme.spacing(0, 3, 2, 3)};
	min-height: ${({ $isCondition }) => ($isCondition ? 'calc(100vh - 220px - 64px)' : 'unset')};

	.section-label {
		font-family: 'Poppins', sans-serif;
		font-weight: 600;
	}

	.variables-container {
		width: 100%;
	}
	.sub-container-header {
		width: 100%;
		display: grid;
		grid-template-columns: 40% 20% auto;
		grid-gap: 4px;
		font-weight: bold;
		align-items: center;
		padding: ${({ theme }) => theme.spacing(0, 1.5)};
		margin: ${({ theme }) => theme.spacing(1, 0, 0)};
	}
	.sub-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 5px 10px;
		overflow: auto;
		padding: ${({ theme }) => theme.spacing(0, 1)};
	}
	.create-variable-header {
		width: 100%;
		display: grid;
		grid-template-columns: 50% 25% auto;
		grid-gap: 4px;
		align-items: center;
		margin: ${({ theme }) => theme.spacing(1.5, 0)};
	}
	.create-variable {
		width: 100%;
		align-items: center;
		margin: ${({ theme }) => theme.spacing(0, 0, 2)};
	}
	.form-variable {
		margin: 8px auto 20px;

		& > div {
			margin: 8px;
		}
	}
	.variables-title {
		font-size: 16px;
		margin: ${({ theme }) => theme.spacing(1)};
	}
	.variable-perso {
		margin-right: ${({ theme }) => theme.spacing(2)};
	}
	.custom-subtitle {
		font-size: 12px;
	}

	.logic-card {
		position: relative;
	}
	.logic-indicator {
		position: absolute;
		top: 20px;
		left: -8px;
	}
`

export const WebhookTypeBox = styled.div`
	width: 100%;
	height: 220px;
	padding: 8px;
	border: ${({ $isSelected }) => ($isSelected ? snackeet_blue : 'transparent')} solid 4px;
	border-radius: 4px;
	cursor: pointer;
	transition: all 200ms ease-in;

	svg {
		width: 60px;
		height: 60px;
		margin-bottom: 8px;
	}

	&:hover {
		border-color: ${snackeet_yellow};
	}

	.title {
		margin-bottom: 8px;
	}
`

export const ImportModalContent = styled(DialogContent)`
	padding: ${({ theme }) => theme.spacing(0, 3, 3, 3)};
	height: 400px;
	display: grid;
	grid-gap: 16px;
	place-items: center;
	text-align: center;
`

export const WebhookModalContent = styled(DialogContent)`
	padding: ${({ theme }) => theme.spacing(0, 3, 2, 3)};

	.headers-title {
		margin-top: ${({ theme }) => theme.spacing(1)};
	}

	.MuiInputBase-root {
		padding: ${({ theme }) => theme.spacing(1.5, 1)};
	}
`

export const DefaultVariable = styled.div`
	pointer-events: ${({ $isOnlyView, disabled }) => ($isOnlyView || disabled ? 'none' : 'all')};
	width: 100%;
	height: 35px;
	display: grid;
	grid-template-columns: 60% 20% auto;
	grid-gap: 4px;
	background-color: ${({ disabled }) => (disabled ? '#e9e9e9' : '#FFF')};
	color: ${({ theme, isAssigned, disabled }) =>
		disabled ? '#b0b0b0' : isAssigned ? theme.palette.primary.main : 'inherit'};
	border-radius: 10px;
	padding: ${({ theme }) => theme.spacing(0, 1.25)};
	margin: 4px auto;
	align-items: center;
	cursor: pointer;
	outline: ${({ theme, isAssigned }) => (isAssigned ? `3px solid ${theme.palette.primary.main}` : ` 1px solid #ccc`)};

	&:hover {
		outline: 3px solid ${snackeet_blue};
	}

	& > p {
		font-size: 15px;
	}

	${ChipVariable} {
		${({ disabled }) => disabled && 'background-color: rgba(140, 140, 140, 0.2);'}
	}
`

export const ParamVariable = styled.div`
	width: 100%;
	height: 30px;
	display: grid;
	grid-template-columns: 40% 20% auto;
	grid-gap: 4px;
	border: 1px solid #ccc;
	border-color: ${({ theme, isAssigned }) => isAssigned && theme.palette.primary.main};
	padding: ${({ theme }) => theme.spacing(0, 0.5)};
	margin: 4px auto 10px;
	align-items: center;
	cursor: pointer;

	&:hover {
		border-color: ${snackeet_blue};
		color: ${snackeet_blue};
	}
`

const absoluteStyle = css`
	position: absolute;
	left: 0;
	bottom: 0;
`

export const ConsentModalContainer = styled.div`
	${({ $isRelative }) => !$isRelative && absoluteStyle}

	width: ${PREVIEW_WIDTH}px;
	background-color: ${({ theme }) => theme.palette.background.primary};
	border-radius: 10px;
	padding: 5px 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	color: ${snackeet_text};
	box-shadow: -2px -4px 32px -2px rgba(0, 0, 0, 0.38);
	z-index: 9999;
	& > div:nth-child(1) {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	& > div:nth-child(2) {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		text-align: center;
		padding: 0 10px;
		h2 {
			margin: 15px 0 30px;
		}
		div {
			max-height: 300px;
			min-width: 290px;
			overflow-y: auto;
			white-space: pre-line;
			text-align: justify;
			margin-bottom: 30px;
			&::-webkit-scrollbar {
				width: 4px;
				height: 4px;
			}
			/* Handle */
			&::-webkit-scrollbar-thumb {
				background: #646464;
				border-radius: 6.5px;
			}
		}
	}
	.MuiButton-root {
		background-color: ${snackeet_border};
		margin-bottom: 20px;
		&:hover {
			background-color: ${snackeet_grey};
		}
	}
	.accept-button {
		background-color: ${snackeet_yellow};
	}
`

export const ImportLists = styled.div`
	width: 100%;
	height: 500px;
	padding: ${({ theme }) => theme.spacing(1)};
	display: grid;
	grid-auto-flow: column;
	grid-gap: ${({ theme }) => theme.spacing(2)};
	overflow: auto;
`

export const ListContainer = styled.div`
	width: 100%;
	height: 100%;
	border: 2px solid #ccc;
	padding: ${({ theme }) => theme.spacing(1)};
	cursor: pointer;
	border-color: ${(props) => props.$isActive && snackeet_yellow};

	&:hover {
		border-color: ${(props) => (props.$isActive ? snackeet_yellow : snackeet_blue)};
	}

	.list-header {
		text-align: center;
		font-weight: bold;
		margin-bottom: ${({ theme }) => theme.spacing(1)};
	}

	.option-container {
		min-width: 200px;
		max-width: 700px;
		max-height: 500px;
		display: grid;
		grid-template-rows: repeat(auto-fill, 14px);
		grid-auto-flow: column;
		grid-gap: ${({ theme }) => theme.spacing(0.5)};

		& > div {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
`

export const InputList = styled.div`
	width: 100%;
	/* height: 500px; */
	padding: ${({ theme }) => theme.spacing(1)};
	display: grid;
	place-items: center;
	overflow: auto;

	ul {
		width: 80%;
	}

	.MuiListItem-root {
		width: 100%;
		display: flex;
		flex-direction: row !important;
		align-items: center;
		justify-content: flex-start;
		height: 40px;
		font-size: 14px;
		padding: ${({ theme }) => theme.spacing(1)};
		margin: ${({ theme }) => theme.spacing(0, 0.5, 1.5)} !important;
		border: solid 1px #ccc;
	}

	.option-container {
		width: 100%;
		display: flex;
		align-items: center;
	}
`

export const ButtonsContainer = styled.div`
	width: 100%;
	padding: ${({ theme }) => theme.spacing(1)};
	display: flex;
	align-items: center;
	justify-content: flex-end;

	& > * {
		margin-left: ${({ theme }) => theme.spacing(2)};
	}
`

const displayErrorStyle = ({ $isError, theme }) =>
	$isError &&
	css`
		border-color: ${theme.palette.error.light};
	`

export const ConditionWrapper = styled(Box)`
	padding: ${({ theme }) => theme.spacing(1)};
	margin-bottom: ${({ theme }) => theme.spacing(2)};
	border: 1.5px solid #ccc;
	border-radius: ${({ theme }) => theme.spacing(0.5)};
	background-color: rgba(255, 255, 255, 1);

	${displayErrorStyle};
`

export const DeleteConsequenceButton = styled(Button)`
	min-width: 20px;
	margin-left: ${({ theme }) => theme.spacing(0.5)};
	padding: 0px;
`

export const ConditionCardWrapper = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: ${({ $isAlways }) => ($isAlways ? '100px auto' : '50px auto')};
	grid-gap: ${({ $isConditional }) => ($isConditional ? 0 : 4)}px;
	margin-bottom: ${({ $lastItem }) => ($lastItem ? 0 : 4)}px;

	.condition-header {
		padding-top: ${({ theme }) => theme.spacing(1)};

		.MuiSelect-outlined {
			padding: ${({ theme }) => theme.spacing(1, 3, 1, 1)};
			background-color: white;
			font-size: 12px;
			font-weight: bold;
		}
	}

	.consequences-container {
		padding: ${({ theme }) => theme.spacing(0.5, 1)};
		border-left: 1px solid #7f81a2;
		border-radius: ${({ theme }) => theme.spacing(0.5)};
	}

	.consequence {
		width: 100%;
		display: grid;
		grid-gap: 8px;
		align-items: center;

		.MuiSelect-outlined {
			padding: ${({ theme }) => theme.spacing(1, 3, 1, 1)};
			background-color: white;
			overflow: hidden;
			font-size: 11px;
		}

		.MuiAutocomplete-inputRoot {
			padding: ${({ theme }) => theme.spacing(0)};
			background-color: white;
			overflow: hidden;
			font-size: 12px;
		}

		.MuiOutlinedInput-input {
			padding: ${({ theme }) => theme.spacing(0.75, 1, 1, 1)};
			background-color: white;
			overflow: hidden;
			font-size: 11px;
		}
	}
`

export const QueryBuilderWrapper = styled.div`
	.queryBuilder {
		width: 100%;

		.ruleGroup {
			padding: ${({ theme }) => theme.spacing(1)};
			border: 1px solid #7f81a2;
			border-radius: ${({ theme }) => theme.spacing(0.5)};
			margin: ${({ theme }) => theme.spacing(1, 0, 1, 1)};

			.betweenRules {
				.MuiSelect-outlined {
					padding: 4px 32px 4px 4px;
					font-size: 12px;
				}
			}
		}

		.ruleGroup-header {
			display: grid;
			grid-template-columns: 1fr 150px 100px auto;
			grid-template-areas: 'dragButton addRule addGroup removeBtn';
			grid-gap: ${({ theme }) => theme.spacing(1)};
			align-items: center;
			margin-bottom: 8px;

			.queryBuilder-dragHandle {
				grid-area: dragButton;
				cursor: pointer;
			}
			.ruleGroup-addRule {
				grid-area: addRule;
			}
			.ruleGroup-addGroup {
				grid-area: addGroup;
			}
			.ruleGroup-remove {
				grid-area: removeBtn;
				width: 40px;
			}
		}

		.rule {
			display: grid;
			grid-template-columns: 20px 1fr 0.5fr 1fr auto;
			grid-gap: ${({ theme }) => theme.spacing(1)};
			align-items: center;
			margin: ${({ theme }) => theme.spacing(0.5, 0, 0.5, 1)};
			padding: ${({ theme }) => theme.spacing(0, 0.5)};

			.MuiSelect-outlined {
				padding: ${({ theme }) => theme.spacing(1, 1)};
				font-size: 12px;
			}

			.MuiOutlinedInput-input {
				padding: ${({ theme }) => theme.spacing(1, 2, 1, 1)};
				font-size: 12px;
			}

			.MuiAutocomplete-inputRoot {
				padding: ${({ theme }) => theme.spacing(0, 2, 0, 0)};
				font-size: 12px;
			}

			.queryBuilder-dragHandle {
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
			}
		}

		.dndOver {
			padding-bottom: 10px;
			border-bottom: 3px dashed #9c27b0;
			margin-bottom: 5px;
			transition: all ease-in-out 150ms;
		}

		.dndDragging {
			background: rgba(0, 75, 183, 0.3);
		}

		.rule-fields,
		.rule-operators,
		.rule-value {
			.MuiInputBase-root {
				background-color: white;
				border-radius: ${({ theme }) => theme.spacing(0.5)};
				font-size: 12px;
			}
		}

		.rule-operators {
			.MuiSelect-select {
				font-weight: bold;
				font-size: 12px;
			}
		}

		.ruleGroup-combinators {
			background-color: white;
			margin: ${({ theme }) => theme.spacing(0, 1)};
			border-radius: ${({ theme }) => theme.spacing(0.5)};

			.MuiSelect-select {
				font-weight: bold;
				padding: ${({ theme }) => theme.spacing(1, 4, 1, 1)};
				font-size: 12px;
			}
		}
	}
`

export const PageInputContainer = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	overflow: hidden;

	.content-preview {
		height: calc(100vh - 125px);
		max-height: 600px;
	}

	.pageName-container {
		background-color: #ededed;
		text-align: center;
		padding: 8px;
		margin-top: 8px;
		margin-bottom: -8px;
		text-align: center;

		.MuiOutlinedInput-root {
			background-color: white;
			width: 70%;
			margin: auto;
		}
	}
`

export const CreatePageScroller = styled.div`
	max-height: 600px;
	width: 400px;
	overflow-y: auto;
	padding-bottom: ${({ theme }) => theme.spacing(2)};

	.page-title {
		width: 100%;
		padding: ${({ theme }) => theme.spacing(1.5, 1.5, 1, 1.5)};
		font-weight: bold;
		position: relative;
	}
`

const reponseTypeDisabledStyles = ({ $isDisabled }) =>
	$isDisabled &&
	css`
		cursor: not-allowed;
		filter: grayscale(100%);
		background-color: ${({ theme }) => theme.palette.background.primary};
		color: ${snackeet_dark_grey};

		&:hover {
			background-color: unset;
			color: black;
		}
	`

export const ResponseType = styled(SelectableOption)`
	&& {
		background-color: ${({ $isActive }) => ($isActive ? `#6B9DFE` : 'unset')};
		color: ${(props) => (props.isActive ? 'white' : 'black')};
		margin-bottom: 2px;
	}

	&:hover {
		background-color: #6b9dfe;
		color: white;
	}

	.MuiChip-root {
		margin-left: 8px;
		height: 24px;
	}

	${reponseTypeDisabledStyles}
`

export const PreviewResponse = styled.div`
	height: 440px;
	aspect-ratio: 359 / 630;
	border-radius: ${({ theme }) => theme.spacing(0.5)};
	padding: 8px;
	background-image: linear-gradient(
		45deg,
		hsl(238deg 35% 28%) 0%,
		hsl(233deg 40% 41%) 8%,
		hsl(230deg 55% 55%) 26%,
		hsl(227deg 80% 65%) 50%,
		hsl(225deg 88% 72%) 76%,
		hsl(223deg 98% 80%) 100%
	);
	color: white;

	.response-content {
		height: 350px;
		width: 100%;
		text-align: center;
		margin: ${({ theme }) => theme.spacing(2, 'auto', 10)};
		h4 {
			font-size: 13px;
		}

		img {
			width: 100%;
			border-radius: ${({ theme }) => theme.spacing(0.5)};
			margin-bottom: ${({ theme }) => theme.spacing(2)};
		}
	}

	@media (max-height: 720px) {
		height: 395px;

		.response-content {
			height: 250px;
			text-align: center;
			margin: ${({ theme }) => theme.spacing(4, 'auto', 10)};

			h4 {
				font-size: 11px;
			}
		}
	}

	@media (max-height: 660px) {
		height: 315px;

		.response-content {
			height: 250px;
			width: 150px;
			text-align: center;
			margin: ${({ theme }) => theme.spacing(4, 'auto', 10)};

			h4 {
				font-size: 9px;
			}
		}
	}
`

export const RatingContainer = styled.div`
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	padding: ${({ theme }) => theme.spacing(1.5, 0)};
	height: 75px;
	background-color: ${(props) => (props.$isSelected ? props.theme.palette.background.primary : 'white')};
	margin: ${({ theme }) => theme.spacing(4, 1)};
	border: 3px solid ${(props) => (props.$isSelected ? props.theme.palette.secondary.main : 'transparent')};
	border-radius: ${({ theme }) => theme.spacing(0.5)};
	transition: all 250ms ease-in-out;

	div {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		background-size: cover;
	}
	&:hover {
		border-color: ${({ theme }) => theme.palette.secondary.main};
		background-color: ${({ theme }) => theme.palette.background.primary};
	}
`

export const MediaSelectorSideBar = styled.div`
	height: 100%;
	width: 230px;
	overflow-x: hidden;
	overflow-y: auto;
	padding: ${({ theme }) => theme.spacing(2, 0)};
	border-right: 1px solid ${({ theme }) => theme.palette.background.primary};
	flex-shrink: 0;
`

const mediaCategorySelectedStyle = ({ $isSelected, theme }) =>
	$isSelected &&
	css`
		background: ${theme.palette.primary.dark};
		color: white;

		&:hover {
			transform: unset;
		}
	`
export const MediaCategory = styled.div`
	display: flex;
	align-items: center;
	padding: ${({ theme }) => theme.spacing(1.5, 1.5, 1.5, 2)};
	width: 100%;
	height: 3em;
	font-size: 18px;
	background: none;
	color: inherit;
	cursor: pointer;
	transition: all 250ms ease-in-out;

	&:hover {
		transform: scale(1.03);
	}

	${mediaCategorySelectedStyle}

	img {
		height: 24px;
		width: 24px;
		object-fit: cover;
		border-radius: 4px;
	}
`

export const ProjectsAccessWrapper = styled(FormControl)`
	margin: ${({ theme }) => theme.spacing(2, 0)};
	width: 100%;
	justify-content: space-evenly;
	flex-direction: row;
`

export const GroupTitle = styled.div`
	margin: ${({ theme }) => theme.spacing(1)};
	text-transform: uppercase;
	color: ${snackeet_blue};
`
