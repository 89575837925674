/*  Improved hook to manage upload.
	Handles upload from multiple files or urls, and accepts functions for on success & failure.
	Also returns ImportingModal component with its own props for easier use.
	The Snackbars are displayed if the ImportingModal is not opened.
	Pattern seen in:
	- https://blog.bitsrc.io/new-react-design-pattern-return-component-from-hooks-79215c3eac00
*/

import { ImportingModal } from 'components/Modals'
import { UrlAssetIdentifier } from 'lib/models'
import { DEFAULT_MIME_TYPES, FileValidationError, isFileOk } from 'lib/upload'
import _ from 'lodash'
import { useSnackbar } from 'notistack'
import { useCallback, useMemo, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { getCurrentProjectId } from 'redux/project/selectors'
import UploadSvc from 'services/UploadSvc'
import { useImmer } from 'use-immer'

const DEFAULT_SNACKBAR_OPTS = {
	autoHideDuration: 3000,
	anchorOrigin: {
		vertical: 'top',
		horizontal: 'center',
	},
}

export default function useUpload({ acceptedMimeTypes = DEFAULT_MIME_TYPES, onUploadSuccess, onUploadFailure } = {}) {
	const intl = useIntl()
	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	const project = useSelector(getCurrentProjectId)

	const importingRef = useRef()
	const snackbarIdsRef = useRef({ upload: null, uploadError: null })
	const [state, setState] = useImmer({
		importStatus: 'done', // done, verifying, uploading, or error
		isUploading: false,
		errorMsg: '',
	})

	const startUpload = useCallback(() => {
		// Notify upload state
		if (!importingRef.current?.isOpened) {
			snackbarIdsRef.current.upload = enqueueSnackbar(intl.formatMessage({ id: 'toast.info.uploading' }), {
				variant: 'info',
				...DEFAULT_SNACKBAR_OPTS,
			})
		}

		setState((draft) => {
			draft.importStatus = 'uploading'
			draft.isUploading = true
		})
	}, [enqueueSnackbar, intl, setState])

	const verifyFiles = useCallback(
		async (filesToUpload) => {
			setState((draft) => {
				draft.importStatus = 'verifying'
			})

			const fileVerificationOutcomes = await Promise.allSettled(
				_.map(filesToUpload, async (file) => {
					await isFileOk(file, acceptedMimeTypes)
					return file
				})
			)

			const validFiles = _.flatMap(fileVerificationOutcomes, (outcome) =>
				outcome.status === 'fulfilled' ? outcome.value : []
			)
			const invalidFiles = _.flatMap(fileVerificationOutcomes, (outcome) =>
				outcome.status === 'rejected' ? outcome.reason : []
			)

			if (invalidFiles.length > 0) {
				snackbarIdsRef.current.upload && closeSnackbar(snackbarIdsRef.current)
				snackbarIdsRef.current.uploadError && closeSnackbar(snackbarIdsRef.current.uploadError)

				snackbarIdsRef.current.uploadError = enqueueSnackbar(
					intl.formatMessage({ id: 'toast.error.upload_file.invalid_files' }, { count: invalidFiles.length }),
					{
						variant: 'uploadErrorReport',
						anchorOrigin: DEFAULT_SNACKBAR_OPTS.anchorOrigin,
						autoHideDuration: null,
						invalidFiles,
					}
				)
			}

			return validFiles
		},
		[acceptedMimeTypes, closeSnackbar, enqueueSnackbar, intl, setState]
	)

	const handleFileUpload = useCallback(
		async (filesToUpload, uploadRoute) => {
			const validFiles = await verifyFiles(filesToUpload)
			if (validFiles.length === 0) {
				throw new Error('No valid files found')
			}

			// Upload step
			startUpload()
			const formData = new FormData()
			for (const file of validFiles) {
				formData.append('blob', file, file.name)
			}
			return UploadSvc.upload({
				project,
				url: uploadRoute,
				formData,
			})
		},
		[project, startUpload, verifyFiles]
	)

	const handleUrlUpload = useCallback(
		(urlAssetIdentifiers) => {
			startUpload()
			return UploadSvc.uploadFromUrl({ project, urlAssetIdentifiers })
		},
		[project, startUpload]
	)

	const handleUpload = useCallback(
		async (files, uploadRoute = '/upload') => {
			try {
				const filesToUpload = _.castArray(files)

				if (!canUploadFiles(filesToUpload)) {
					throw new Error(`Files must be either only Blobs or URLs`)
				}

				importingRef.current?.open()

				const uploadResponse =
					filesToUpload[0] instanceof Blob
						? await handleFileUpload(filesToUpload, uploadRoute)
						: await handleUrlUpload(filesToUpload)

				if (typeof onUploadSuccess === 'function') {
					onUploadSuccess(uploadResponse.files)
				}

				// Notify
				if (!importingRef.current?.isOpened) {
					snackbarIdsRef.current.upload && closeSnackbar(snackbarIdsRef.current.upload)
					enqueueSnackbar(
						intl.formatMessage(
							{ id: 'toast.success.upload_file' },
							{ count: uploadResponse.files?.length ?? 1 }
						),
						{
							variant: 'success',
							...DEFAULT_SNACKBAR_OPTS,
						}
					)
				}
				setState((draft) => {
					draft.importStatus = 'done'
					draft.errorMsg = ''
					draft.isUploading = false
				})

				return uploadResponse.files
			} catch (err) {
				console.error(err)
				setState((draft) => {
					if (err.message) {
						draft.errorMsg = err.message
					}
					draft.importStatus = 'error'
					draft.isUploading = false
				})

				if (!importingRef.current?.isOpened && err instanceof FileValidationError) {
					snackbarIdsRef.current.upload && closeSnackbar(snackbarIdsRef.current.upload)
					enqueueSnackbar(intl.formatMessage({ id: 'toast.error.upload_file' }), {
						variant: 'error',
						...DEFAULT_SNACKBAR_OPTS,
					})
				}
				if (typeof onUploadFailure === 'function') {
					onUploadFailure()
				}
				return []
			}
		},
		[
			handleFileUpload,
			handleUrlUpload,
			onUploadSuccess,
			setState,
			closeSnackbar,
			enqueueSnackbar,
			intl,
			onUploadFailure,
		]
	)

	const importingModalProps = useMemo(
		() => ({
			ref: importingRef,
			importStatus: state.importStatus,
			errorMsg: state.errorMsg,
		}),
		[state.errorMsg, state.importStatus]
	)

	return {
		...state,
		handleUpload,
		importingModalProps,
		ImportingModal,
	}
}

function canUploadFiles(files) {
	const isFilesOnly = _.every(files, (file) => file instanceof Blob)
	const isUrlsOnly = _.every(files, (file) => file instanceof UrlAssetIdentifier)
	return isFilesOnly || isUrlsOnly
}
