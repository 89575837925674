import { Cancel, CheckCircle, Restore } from '@mui/icons-material';
import { Box, Button, CircularProgress, DialogActions, InputAdornment, TextField } from '@mui/material';
import styled from '@styled';
import { FORBIDDEN_SLUGS, SHORT_SNACKEET_URL } from 'lib/constants';
import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PublishedStoriesSvc from 'services/PublishedStoriesSvc';
import { useDebouncedCallback } from 'use-debounce';
import { useImmer } from 'use-immer';
import { ModalWrapper } from '../ModalWrapper';
const Loader = styled(CircularProgress)`
	width: 30px !important;
	height: 30px !important;
	display: flex;
`;
const StorySlugModal = forwardRef(({
  storySlug,
  storyId,
  storyCustomFQDN,
  onStorySlugUpdate
}, ref) => {
  const storyDomain = `${storyCustomFQDN ?? SHORT_SNACKEET_URL}/`;
  const [state, setState] = useImmer({
    isVerifying: false,
    isSlugValid: true,
    currentStorySlug: storySlug ?? storyId
  });
  const verifyStorySlug = useDebouncedCallback(async ({
    story_slug,
    story_fqdn
  }) => {
    setState(draft => {
      draft.isVerifying = true;
    });
    const isValid = !FORBIDDEN_SLUGS.includes(story_slug) && (story_slug === storyId || story_slug === storySlug || (await PublishedStoriesSvc.verifyStorySlug({
      story_slug,
      story_fqdn
    })));
    setState(draft => {
      draft.isDirty = false;
      draft.isVerifying = false;
      draft.isSlugValid = isValid;
    });
  }, 500);
  const canSave = !state.isDirty && state.isSlugValid && !state.isVerifying;
  function handleInputChange({
    target: {
      value
    }
  }) {
    // Allow slash character for multiple paths
    const sanitizedSlug = value.replace(/\s/g, '');

    // Split the slug based on slashes and take only the first 3 parts, then join back
    const story_slug = sanitizedSlug.split('/').slice(0, 3).join('/');
    if (state.currentStorySlug !== story_slug) {
      setState(draft => {
        draft.currentStorySlug = story_slug;
        draft.isDirty = true;
      });
      verifyStorySlug({
        story_slug,
        story_fqdn: storyCustomFQDN
      });
    }
  }
  function onConfirm() {
    // Handle case where story_slug is story_id
    if (state.currentStorySlug === storyId) {
      onStorySlugUpdate(undefined);
    } else {
      onStorySlugUpdate(state.currentStorySlug);
    }
    ref.current?.close();
  }
  function handleEnterKey(evt) {
    if ((evt.key === 'Enter' || evt.keyCode === 13) && canSave) {
      onConfirm();
    }
  }
  function resetState() {
    setState(draft => {
      draft.isVerifying = false;
      draft.isSlugValid = true;
      draft.currentStorySlug = storySlug ?? storyId;
    });
  }
  function onClose() {
    resetState();
    ref.current?.close();
  }
  function onResetSlug() {
    setState(draft => {
      draft.isVerifying = false;
      draft.isSlugValid = true;
      draft.currentStorySlug = storyId;
    });
    onStorySlugUpdate(undefined);
    ref.current?.close();
  }
  useEffect(() => {
    if (storySlug !== state.currentStorySlug) {
      setState(draft => {
        draft.currentStorySlug = storySlug ?? storyId;
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [setState, storyId, storySlug]);
  return <ModalWrapper size='sm' ref={ref} title={<FormattedMessage id='modals.story_slug.header' />} onClose={resetState}>
			<Box overflow='auto' px={2} py={3} display='flex' flexDirection='column'>
				<TextField fullWidth error={!state.isSlugValid} variant='outlined' label={<FormattedMessage id='modals.story_slug.input.label' />} helperText={!state.isSlugValid && <FormattedMessage id='modals.story_slug.input.error' />} InputProps={{
        startAdornment: <InputAdornment position='start'>{storyDomain}</InputAdornment>,
        endAdornment: <Box ml={1}>
								{state.isVerifying ? <Loader /> : state.isSlugValid ? <CheckCircle color='primary' /> : <Cancel color='error' />}
							</Box>
      }} value={state.currentStorySlug} onChange={handleInputChange} onKeyUp={handleEnterKey} />
			</Box>

			<DialogActions>
				<Button variant='text' onClick={onClose}>
					<FormattedMessage id='button.cancel' />
				</Button>

				<Button variant='outlined' color='primary' onClick={onResetSlug} disabled={storyId === state.currentStorySlug}>
					<Restore />
					<Box ml={0.5}>
						<FormattedMessage id='button.reset' />
					</Box>
				</Button>

				<Button disabled={!canSave} variant='outlined' color='secondary' onClick={onConfirm}>
					<FormattedMessage id='button.save' />
				</Button>
			</DialogActions>
		</ModalWrapper>;
});
StorySlugModal.propTypes = {
  storySlug: PropTypes.string,
  storyId: PropTypes.string,
  storyCustomFQDN: PropTypes.string,
  onStorySlugUpdate: PropTypes.func
};
StorySlugModal.displayName = 'StorySlugModal';
export default StorySlugModal;