import { Button, Menu, MenuItem, Slider as MuiSlider, Tooltip } from '@mui/material';
import { MAX_ZOOM_PERCENTAGE, MIN_ZOOM_PERCENTAGE, PREVIEW_HEIGHT, PREVIEW_WIDTH } from 'lib/constants';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ZoomSliderContainer } from './Sliders.style';
import useSlider from './useSlider';
const anchorOrigin = {
  vertical: 'top',
  horizontal: 'center'
};
const transformOrigin = {
  vertical: 'bottom',
  horizontal: 'center'
};
const zoomOptions = [300, 200, 125, 100, 75, 50, 25, 10, 'fit', 'fill'];
export default function ZoomSlider({
  infiniteViewerRef,
  zoomPercentage,
  setZoomPercentage,
  sliderStep = 1,
  maxValue = MAX_ZOOM_PERCENTAGE,
  minValue = MIN_ZOOM_PERCENTAGE,
  disabled = false,
  debounceDelay = 100
}) {
  const intl = useIntl();
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'zoom-slider'
  });
  const handleOptionClick = useCallback(option => () => {
    setZoomPercentage(getOptionZoomPercentage(option));
    if (['fit', 'fill'].includes(option)) {
      infiniteViewerRef.current?.scrollCenter();
    }
  }, [setZoomPercentage]);
  const {
    value,
    handleSliderChange,
    handleChangeCommitted
  } = useSlider({
    initialValue: zoomPercentage,
    onUpdate: setZoomPercentage,
    debounceDelay
  });
  return <ZoomSliderContainer>
			<Tooltip title='Zoom'>
				<Button {...bindTrigger(popupState)}>{value}%</Button>
			</Tooltip>

			<Menu anchorOrigin={anchorOrigin} transformOrigin={transformOrigin} {...bindMenu(popupState)}>
				{zoomOptions.map(option => <MenuItem key={option} selected={option === zoomPercentage} onClick={handleOptionClick(option)}>
						{typeof option === 'number' ? `${option}%` : intl.messages[`common.${option}`]}
					</MenuItem>)}
			</Menu>

			<MuiSlider color='primary' value={typeof value === 'number' ? value : 0} step={sliderStep} min={minValue} max={maxValue} onChange={handleSliderChange} onChangeCommitted={handleChangeCommitted} disabled={disabled} />
		</ZoomSliderContainer>;
}
ZoomSlider.propTypes = {
  infiniteViewerRef: PropTypes.object,
  zoomPercentage: PropTypes.number,
  setZoomPercentage: PropTypes.func,
  sliderStep: PropTypes.number,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  disabled: PropTypes.bool,
  debounceDelay: PropTypes.number
};
const MARGIN = 30;
export function getOptionZoomPercentage(option) {
  if (typeof option === 'number') {
    return option;
  } else {
    const wrapper = document.getElementById('edit-zone-preview');
    const wrapperDimensions = wrapper.getBoundingClientRect();
    if (option === 'fit') {
      const fitZoom = (wrapperDimensions.height - MARGIN) / PREVIEW_HEIGHT;
      const zoomPercentage = parseFloat((fitZoom * 100).toFixed(0));
      return zoomPercentage;
    } else if (option === 'fill') {
      const fillZoom = (wrapperDimensions.width - MARGIN) / PREVIEW_WIDTH;
      const zoomPercentage = parseFloat((fillZoom * 100).toFixed(0));
      return zoomPercentage;
    }
  }
}