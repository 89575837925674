/* eslint-disable react/display-name */
// import { makeStyles } from '@mui/material/styles';
import BackgroundDimmer from 'components/atoms/DimmerBackground';
import PropTypes from 'prop-types';
import { memo } from 'react';
import Color from './Color';
import Image from './Image';
import Video from './Video';
const renderers = {
  image: context => <Image {...context} />,
  color: context => <Color {...context} />,
  video: context => <Video {...context} />,
  default: context => null
};
const BackgroundElement = ({
  type,
  ...context
}) => {
  const renderer = renderers[type] || renderers.default;
  return <>
			{renderer(context)}
			<BackgroundDimmer {...context} />
		</>;
};
BackgroundElement.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.array,
  context: PropTypes.object
};
export default memo(BackgroundElement);