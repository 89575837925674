import { Box, Button, DialogActions, TextField, Typography } from '@mui/material';
import { ColorInput, NumberInput } from 'atoms';
import { AmpFormSettingsWrapper } from 'components/styled/Modals';
import { rating_feedback_placeholder, rating_feedback_submit_button, rating_feedback_title } from 'lib/constants/InteractionTranslation';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useImmer } from 'use-immer';
import RatingFeedbackPreview from './RatingFeedbackPreview';
export default function AttchmentSettings({
  onSubmitConfig,
  rating,
  recentColors,
  brandColors,
  updateRecentColor,
  onRecentColorsUpdate
}) {
  const intl = useIntl();
  const [states, setStates] = useImmer(initForm(rating?.feedback_form));
  function handlePropertyChange(property, value) {
    setStates(draft => {
      draft[property] = value;
    });
  }
  function handleButtonStyleChange(property, value) {
    setStates(draft => {
      draft.button_style[property] = value;
    });
    if (property === 'color' || property === 'backgroundColor') {
      updateRecentColor(value);
    }
  }
  function handleSubmit() {
    onSubmitConfig(states);
  }
  return <>
			<AmpFormSettingsWrapper>
				<Box width='100%' height='100%' display='flex' justifyContent='center' alignItems='center' overflow='hidden' p={1}>
					<RatingFeedbackPreview category={rating?.category} max={rating?.max} form={states} style={rating?.style} />
				</Box>

				<Box px={1}>
					<Box mb={1}>
						<Typography variant='h4'>
							<FormattedMessage id='edition_menu.rating.modal.form_title' />
						</Typography>
						<TextField fullWidth variant='outlined' value={states.title} multiline rows={2} maxRows={4} onChange={({
            target: {
              value
            }
          }) => handlePropertyChange('title', value)} />
					</Box>
					<Box mb={1}>
						<Typography variant='h4'>
							<FormattedMessage id='edition_menu.rating.modal.form_messenger_placeholder' />
						</Typography>
						<TextField fullWidth variant='outlined' value={states?.message_placeholder} onChange={({
            target: {
              value
            }
          }) => handlePropertyChange('message_placeholder', value)} />
					</Box>
					<Box mb={1}>
						<Typography variant='h4'>
							<FormattedMessage id='edition_menu.rating.modal.form_submit_btn' />
						</Typography>
						<TextField fullWidth variant='outlined' value={states?.button_title} onChange={({
            target: {
              value
            }
          }) => handlePropertyChange('button_title', value)} />
						<Box className='properties-wrapper'>
							<div className='property-line'>
								<Typography variant='body1' component='label' className='property-field'>
									<FormattedMessage id='common.size' />
								</Typography>
								<div className='property-content'>
									<NumberInput initial_value={parseInt(states?.button_style?.fontSize || '16px')} onUpdate={value => handleButtonStyleChange('fontSize', `${value}px`)} maxValue={450} minValue={6} units='px' inputStep={1} presetValues={[6, 8, 10, 12, 14, 16, 18, 21, 24, 26, 28, 32, 36, 42, 48, 56, 64, 72, 80, 96]} />
								</div>
							</div>
							<div className='property-line'>
								<Typography variant='body1' component='label' className='property-field'>
									<FormattedMessage id='common.radius' />
								</Typography>
								<div className='property-content'>
									<NumberInput initial_value={parseInt(states?.button_style?.borderRadius || '0px')} onUpdate={value => handleButtonStyleChange('borderRadius', `${value}px`)} maxValue={100} units='px' inputStep={1} presetValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} />
								</div>
							</div>
						</Box>
						<Box className='properties-wrapper'>
							<ColorInput value={states?.button_style?.color} updateValue={value => handleButtonStyleChange('color', value)} recentColors={recentColors} brandColors={brandColors} updateRecentColors={onRecentColorsUpdate} />
							<ColorInput value={states?.button_style?.backgroundColor} updateValue={value => handleButtonStyleChange('backgroundColor', value)} label={intl.messages['common.background_color']} recentColors={recentColors} brandColors={brandColors} updateRecentColors={onRecentColorsUpdate} />
						</Box>
					</Box>
				</Box>
			</AmpFormSettingsWrapper>

			<DialogActions>
				<Button data-intercom-target='Confirm Button' variant='contained' color='secondary' onClick={handleSubmit}
      // disabled={isInvalidAttachment(states?.content)}
      >
					<FormattedMessage id='common.theming.confirm_button' />
				</Button>
			</DialogActions>
		</>;
}
AttchmentSettings.propTypes = {
  onSubmitConfig: PropTypes.func,
  rating: PropTypes.object,
  draftStory: PropTypes.object,
  recentColors: PropTypes.array,
  brandColors: PropTypes.array,
  recentFonts: PropTypes.array,
  brandFontFamily: PropTypes.string,
  onRecentColorsUpdate: PropTypes.func
};
function initForm(form) {
  if (!form) {
    return {
      title: rating_feedback_title.en,
      message_placeholder: rating_feedback_placeholder.en,
      button_title: rating_feedback_submit_button.en,
      button_style: {
        fontSize: '16px',
        borderRadius: '0px',
        color: 'rgba(255, 255, 255, 100)',
        backgroundColor: '#484747'
      }
    };
  }
  return form;
}