import { Button, Checkbox, CircularProgress, DialogActions, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { ModalContent, ProjectsAccessWrapper } from 'components/styled/Modals';
import { NEW_TAGS, TAGS_TEMPLATE } from 'lib/constants';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useImmer } from 'use-immer';
import { ModalWrapper } from '../ModalWrapper';
const ChangeTemplateTagsModal = forwardRef(function ChangeTemplateTagsModal({
  draftStory,
  publishedStory,
  onClose,
  onOpen,
  updateTags
}, ref) {
  const [states, setStates] = useImmer({
    currentTags: draftStory?.tags || [],
    isSaving: false
  });
  useEffect(() => {
    setStates(draft => {
      if (draftStory) {
        draft.currentTags = draftStory?.tags;
      } else {
        draft.currentTags = [];
      }
    });
  }, [draftStory, setStates]);
  function tagsChange(tag) {
    if (!_.includes(states.currentTags, tag)) {
      setStates(draft => {
        draft.currentTags.push(tag);
      });
    } else {
      setStates(draft => {
        draft.currentTags = draft.currentTags.filter(t => t !== tag);
      });
    }
  }

  // Save as template
  async function saveAsTemplate() {
    setStates(draft => {
      draft.isSaving = true;
    });
    await updateTags(states.currentTags);
    setStates(draft => {
      draft.isSaving = false;
    });
    ref.current?.close();
  }
  return <>
			<ModalWrapper title={<FormattedMessage id='stories_list.tags.modal_titles' />} size='sm' ref={ref} onClose={onClose} onOpen={onOpen}>
				<ModalContent>
					<Typography variant='h3'>
						<FormattedMessage id='story_setting.template_tags.description' />
					</Typography>
					<ProjectsAccessWrapper component='fieldset'>
						<FormGroup>
							{_.map(NEW_TAGS, tag => <FormControlLabel key={tag} control={<Checkbox checked={_.includes(states.currentTags, tag)} name={tag} onChange={() => tagsChange(tag)} />} label={<b>
											<FormattedMessage id={`common.story_tag.${tag}`} />
										</b>} />)}
						</FormGroup>
						<FormGroup>
							{_.map(TAGS_TEMPLATE.slice(0, Math.ceil(TAGS_TEMPLATE.length / 2)), tag => <FormControlLabel key={tag} control={<Checkbox checked={_.includes(states.currentTags, tag)} name={tag} onChange={() => tagsChange(tag)} />} label={<FormattedMessage id={`common.story_tag.${tag}`} />} />)}
						</FormGroup>
						<FormGroup>
							{_.map(TAGS_TEMPLATE.slice(Math.ceil(TAGS_TEMPLATE.length / 2)), tag => <FormControlLabel key={tag} control={<Checkbox checked={_.includes(states.currentTags, tag)} name={tag} onChange={() => tagsChange(tag)} />} label={<FormattedMessage id={`common.story_tag.${tag}`} />} />)}
						</FormGroup>
					</ProjectsAccessWrapper>
				</ModalContent>

				<DialogActions>
					<Button variant='text' onClick={() => ref.current?.close()}>
						<FormattedMessage id='button.cancel' />
					</Button>
					<Button variant='outlined' color='secondary' onClick={() => saveAsTemplate()} disabled={states.isSaving}>
						{states.isSaving && <CircularProgress style={{
            marginRight: '8px'
          }} color='primary' size={20} />}
						<FormattedMessage id='button.confirm' />
					</Button>
				</DialogActions>
			</ModalWrapper>
		</>;
});
ChangeTemplateTagsModal.propTypes = {
  trigger: PropTypes.object,
  onClose: PropTypes.func,
  onOpen: PropTypes.func
};
export default ChangeTemplateTagsModal;