import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { AssetName, DeleteButton, ListContainer, MediaAssetCard } from './MediaComponent.style';
export default function ImageList({
  images,
  onSelect,
  onDelete,
  listType
}) {
  if (_.isEmpty(images)) {
    return <Box width='100%' height='100%' display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
				<img src='/static/placeholders/upload_media.svg' height={300} alt='Upload media' />
				<Typography variant='h2'>
					<FormattedMessage id='edition_menu.image.empty_list' />
				</Typography>
			</Box>;
  }
  const renderImageListPexels = images => <>
			{_.map(images, image => <MediaAssetCard key={image.id}>
					<img alt={image.photographer} src={image.src.medium} onClick={() => onSelect({
        url: image.src.large2x
      })} />
				</MediaAssetCard>)}
		</>;
  const renderImageListPixabay = images => <>
			{_.map(images, image => <MediaAssetCard key={image.id}>
					<img alt={image.pageURL} src={image.webformatURL} onClick={() => onSelect({
        url: image.largeImageURL
      })} />
				</MediaAssetCard>)}
		</>;
  const renderImageListGallery = images => <>
			{_.map(images, image => <MediaAssetCard key={image._id}>
					<img alt={image._id} src={image?.related?.thumbnail || image.url} onClick={() => onSelect({
        url: image.url,
        file_id: image._id,
        name: image.name
      })} />
					{typeof onDelete === 'function' && <DeleteButton fileId={image._id} onDelete={onDelete} />}
					<AssetName name={image.name} />
				</MediaAssetCard>)}
		</>;
  const renderImageRecentlyUsed = images => <>
			{_.map(images, image => <MediaAssetCard key={image._id}>
					<img alt={image._id} src={image.url} onClick={() => onSelect({
        url: image.url,
        file_id: image._id,
        name: image.name
      })} />
					<AssetName name={image?.name ?? image?.url} />
				</MediaAssetCard>)}
		</>;
  function renderImageList({
    listType,
    images
  }) {
    switch (listType) {
      case 'pexels':
        return renderImageListPexels(images);
      case 'pixabay':
        return renderImageListPixabay(images);
      case 'recently_used':
        return renderImageRecentlyUsed(images);
      default:
        return renderImageListGallery(images);
    }
  }
  return <>
			<Box sx={{
      textAlign: 'center',
      my: 1
    }}>
				<Typography variant='h5'>
					<FormattedMessage id='edition_menu.image_selection_instructions' />
				</Typography>
			</Box>

			<ListContainer>{renderImageList({
        listType,
        images
      })}</ListContainer>
		</>;
}
ImageList.propTypes = {
  images: PropTypes.array,
  handleUrl: PropTypes.func,
  onDelete: PropTypes.func,
  listType: PropTypes.string
};