import { gradientToCSS } from 'lib/utils';
import PropTypes from 'prop-types';
import { BackgroundColorContainer } from '../styled';
export default function Color({
  value,
  outer_style,
  gradient_props
}) {
  return <BackgroundColorContainer $color={value} style={outer_style} $gradient={Boolean(gradient_props?.enabled) && gradientToCSS(gradient_props?.gradient)} />;
}
Color.propTypes = {
  value: PropTypes.string,
  outer_style: PropTypes.object,
  gradient_props: PropTypes.object
};
Color.defaultProps = {
  value: 'transparent'
};