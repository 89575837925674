import _ from 'lodash'

/* Adapted from validator.js */
const default_fqdn_options = {
	require_tld: true,
	require_subdomain: true,
	allow_underscores: true,
	allow_trailing_dot: false,
	allow_numeric_tld: false,
	allow_wildcard: false,
}

export function isFQDN(str, userOptions) {
	if (typeof str !== 'string') {
		throw new Error('Expected string')
	}

	const options = { ...default_fqdn_options, userOptions }

	/* Remove the optional trailing dot before checking validity */
	if (options.allow_trailing_dot && str[str.length - 1] === '.') {
		str = str.substring(0, str.length - 1)
	}

	/* Remove the optional wildcard before checking validity */
	if (options.allow_wildcard === true && str.indexOf('*.') === 0) {
		str = str.substring(2)
	}

	const parts = str.split('.')
	const tld = parts[parts.length - 1]

	if (options.require_tld) {
		// disallow fqdns without tld
		if (parts.length < 2) {
			return false
		}

		if (!/^([a-z\u00A1-\u00A8\u00AA-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]{2,}|xn[a-z0-9-]{2,})$/i.test(tld)) {
			return false
		}

		// disallow spaces
		if (/\s/.test(tld)) {
			return false
		}
	}

	if (options.require_tld && options.require_subdomain) {
		if (parts.length < 3) {
			return false
		}
	}

	// reject numeric TLDs
	if (!options.allow_numeric_tld && /^\d+$/.test(tld)) {
		return false
	}

	return parts.every((part) => {
		if (part.length > 63) {
			return false
		}

		if (!/^[a-z_\u00a1-\uffff0-9-]+$/i.test(part)) {
			return false
		}

		// disallow full-width chars
		if (/[\uff01-\uff5e]/.test(part)) {
			return false
		}

		// disallow parts starting or ending with hyphen
		if (/^-|-$/.test(part)) {
			return false
		}

		if (!options.allow_underscores && /_/.test(part)) {
			return false
		}

		return true
	})
}

export function isDomainLimitReached({ newFQDN, allowedFQDNCount, storyId, storyRedirections }) {
	const domainsList = _.uniq(
		_.map(
			[
				// Consider that the current story redirection has the newFQDN
				..._.reject(storyRedirections, ['story_id', storyId]),
				{ story_id: storyId, story_fqdn: newFQDN },
			],
			(storyRedirection) => {
				const parts = storyRedirection.story_fqdn.split('.')
				return parts.slice(-2).join('.')
			}
		)
	)

	return allowedFQDNCount < domainsList.length
}
