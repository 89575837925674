import { extractPositionProperties, isVimeoVideo } from 'lib/utils';
import PropTypes from 'prop-types';
import { memo } from 'react';
import ReactPlayer from 'react-player';
import { BlockVideoPoster, StyledPlayer } from '../styled';
import BlockWrapper from './BlockWrapper';
import IframeVideo from './IframeVideo';
function Video({
  value,
  outer_style,
  inner_style,
  poster,
  animation
}) {
  const {
    positionProperties,
    visualProperties
  } = extractPositionProperties(outer_style, 'video');
  return <BlockWrapper animation={animation} positionProperties={positionProperties} videoProperties={inner_style}>
			<div style={{
      position: 'relative',
      // Create new CSS stacking context for poster & video
      ...visualProperties,
      transform: inner_style?.transform,
      filter: inner_style?.filter
    }}>
				{ReactPlayer.canPlay(value) || isVimeoVideo(value) ? <>
						{poster ? <BlockVideoPoster inner_style={inner_style}>
								<img src={poster} alt='' />
							</BlockVideoPoster> : <StyledPlayer inner_style={inner_style} $maskImage={inner_style?.maskImage}>
								<ReactPlayer url={value} volume={0} playsinline={true} width='100%' height='100%' muted={true} />
							</StyledPlayer>}
					</> : <IframeVideo value={value} />}
			</div>
		</BlockWrapper>;
}
Video.propTypes = {
  value: PropTypes.string,
  outer_style: PropTypes.object,
  inner_style: PropTypes.object,
  poster: PropTypes.string,
  animation: PropTypes.object
};
export default memo(Video);