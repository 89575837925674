import * as React from 'react'

function Svg5Number(props) {
	return (
		<svg width='1em' height='1em' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M.919 2.861L1.136.734H3.32v.501H1.596l-.13 1.163c.21-.123.447-.184.713-.184.388 0 .697.129.925.387.229.255.343.602.343 1.04 0 .44-.119.786-.357 1.04-.236.252-.568.378-.993.378-.377 0-.685-.105-.923-.314C.936 4.536.8 4.247.767 3.878h.512c.034.244.12.429.261.554.14.123.326.184.557.184.252 0 .449-.086.591-.258.145-.171.217-.409.217-.712 0-.285-.078-.513-.234-.685a.786.786 0 00-.618-.26c-.237 0-.422.05-.557.154l-.143.118-.434-.112z'
				fill='#000'
			/>
		</svg>
	)
}

export default Svg5Number
