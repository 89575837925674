import { css } from '@emotion/react';
import styled from '@styled';
export const CarouselContainer = styled.div`
	position: absolute;
	pointer-events: auto;

	width: 100%;
	padding-top: 8px;
	overflow: auto;

	&::-webkit-scrollbar {
		display: none;
	}
`;
export const CarouselSlider = styled.div`
	width: 100%;
	overflow: auto;

	&::-webkit-scrollbar {
		display: none;
	}
`;
export const CarouselWrapper = styled.div`
	width: ${({
  $isCenter
}) => $isCenter ? '100%' : 'max-content'};
	overflow: auto;
	pointer-events: auto;
	cursor: inherit;
	display: flex;
	align-items: ${({
  $isStretch
}) => $isStretch ? 'stretch' : 'flex-start'};
	justify-content: ${({
  $isCenter
}) => $isCenter ? 'center' : 'flex-start'};

	gap: 16px;
	max-height: 100%;
	margin: 0;
	padding: ${({
  theme
}) => theme.spacing(1, 2)};
`;
export const CarouselCard = styled.div`
	width: ${({
  $width
}) => $width};
	height: auto;
	aspect-ratio: 4 / 6;
	overflow: hidden;
	position: relative;
	background-color: transparent !important;

	box-shadow: 0 0 4px #ccc;

	border-radius: 8px;
	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.cover {
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: ${({
  $overlayColor
}) => $overlayColor};
		font-size: inherit;
		color: inherit;

		span {
			display: -webkit-box;
			max-height: 3.6em;
			width: 100%;
			font-size: inherit;
			line-height: 1.2em;
			word-wrap: break-word;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			font-family: inherit;
			color: inherit;
		}
	}

	.play-container {
		position: absolute;
		z-index: 12;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: inherit;
	}

	${({
  $shape
}) => $shape === 'square' && carouselCardSquare}
`;
const carouselCardSquare = ({
  $width
}) => css`
	width: ${$width};
	height: ${$width};
	aspect-ratio: 1 / 1;
`;
export const CarouselDetailedCard = styled.div`
	padding: 16px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	gap: 8px;
	text-align: center;

	border-radius: 8px;
	cursor: pointer;

	.img-container {
		width: ${({
  $width
}) => $width};
		height: auto;
		aspect-ratio: 5 / 6;
		border-radius: 8px;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.detailed-title {
		width: ${({
  $width
}) => $width};
		padding: 0px 4px;
		font-size: inherit;

		span {
			display: -webkit-box;
			max-height: 4.2em;
			width: 100%;
			font-size: inherit;
			line-height: 1.4em;
			word-wrap: break-word;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
		}
	}

	.detailed-subtitle {
		width: ${({
  $width
}) => $width};
		font-size: 0.8em;
	}

	${({
  $shape
}) => $shape === 'square' && carouselDetailedCardSquare}
	${({
  $hasShadow
}) => $hasShadow && cardShadowStyle};
`;
const carouselDetailedCardSquare = ({
  $width
}) => css`
	.img-container {
		width: ${$width};
		height: ${$width};
		aspect-ratio: 1 / 1;
		border-radius: 8px;
		overflow: hidden;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
`;
const cardShadowStyle = ({
  $shadowColor
}) => css`
	box-shadow: 0 0 4px ${$shadowColor};
`;
export const CarouselRoundedCard = styled.div`
	padding: 0px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	gap: 8px;
	text-align: center;
	background-color: transparent !important;

	border-radius: 8px;
	cursor: pointer;

	.img-container {
		width: ${({
  $width
}) => $width};
		height: ${({
  $width
}) => $width};
		aspect-ratio: 1 / 1;
		border-radius: 200px;
		overflow: hidden;
		position: relative;
		font-size: inherit;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border: 4px solid #fff;
			border-radius: 120px;
		}

		.play-container {
			position: absolute;
			font-size: inherit;
			z-index: 12;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.3);
			border: 4px solid #fff;
			border-radius: 200px;
		}
	}

	.detailed-title {
		width: ${({
  $width
}) => $width};
		padding: 0px 4px;
		font-size: inherit;

		span {
			display: -webkit-box;
			height: 4.2em;
			width: 100%;
			font-size: inherit;
			line-height: 1.4em;
			word-wrap: break-word;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
		}
	}
`;
export const CarouselProductCard = styled.div`
	padding: 8px;
	overflow: hidden;
	display: grid;
	grid-template-columns: 80px 1fr;
	align-items: center;
	width: 310px;

	gap: 8px;
	backdrop-filter: blur(5px);
	color: #000;

	border-radius: 6px;
	cursor: pointer;

	.img-container {
		width: 80px;
		height: 80px;
		aspect-ratio: 1 / 1;
		border-radius: 8px;
		overflow: hidden;
		position: relative;
		backdrop-filter: none;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.detailed-title {
		width: 100%;
		padding: 0px 4px;
		font-size: inherit;
		backdrop-filter: none;

		.title {
			display: -webkit-box;
			height: 2.8em;
			width: 100%;
			font-size: 11px;
			line-height: 1.4em;
			word-wrap: break-word;
			overflow: hidden;
			font-weight: 700;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		.description {
			display: -webkit-box;
			height: 1.4em;
			width: 100%;
			font-size: 12px;
			line-height: 1.4em;
			word-wrap: break-word;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			margin-top: 4px;
		}

		.btn {
			background-color: #000;
			color: #fff;
			border-radius: 40px;
			padding: 4px 8px;
			font-size: 11px;
			margin: 4px auto 0px;
			text-align: center;
		}
	}
`;