import { Box, CircularProgress, Typography } from '@mui/material';
import styled from '@styled';
import { useStoryState } from 'lib/editorStore';
import PropTypes from 'prop-types';
export function PreviewTimer() {
  const timer = useStoryState(s => s.page?.timer);
  if (!timer?.enabled) {
    return null;
  }
  return <TimerLayer>
			<TimerContainer id={timer._id} data-selecto-target={true} data-element-type='timer' $outerStyle={timer.outer_style} $innerStyle={timer.inner_style}>
				{timer?.visible && <CircularProgressWithLabel variant='determinate' value={timer.value} innerStyle={timer.inner_style} outerStyle={timer.outer_style} />}
			</TimerContainer>
		</TimerLayer>;
}
function CircularProgressWithLabel({
  variant,
  value,
  innerStyle,
  outerStyle
}) {
  return <Box position='relative' display='flex' alignItems='center'>
			<CustomCircularProgress variant={variant} value={100} width={outerStyle.width} height={outerStyle.height} $borderColor={innerStyle.borderColor} $borderWidth={innerStyle.borderWidth} $bgColor={innerStyle.backgroundColor} />
			<Box top={0} left={0} bottom={0} right={0} position='absolute' display='flex' justifyContent='center' alignItems='center'>
				<Typography variant='caption' component='div' style={{
        fontSize: innerStyle.fontSize
      }}>
					{`${value}s`}
				</Typography>
			</Box>
		</Box>;
}
CircularProgressWithLabel.propTypes = {
  variant: PropTypes.string,
  value: PropTypes.number,
  innerStyle: PropTypes.object,
  outerStyle: PropTypes.object
};
const TimerLayer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: hidden;
	box-sizing: border-box;
	pointer-events: none;
	top: 0;
	left: 0;
`;
const TimerContainer = styled.div`
	border: none;
	pointer-events: auto;
	transform: ${({
  $outerStyle
}) => $outerStyle.transform};
	width: ${({
  $outerStyle
}) => $outerStyle.width};
	color: ${({
  $innerStyle
}) => $innerStyle.color};
	max-width: 150px;
`;
const CustomCircularProgress = styled(CircularProgress)`
	width: ${({
  width
}) => width} !important;
	height: ${({
  height
}) => height} !important;
	color: ${({
  $borderColor
}) => $borderColor};

	.MuiCircularProgress-circle {
		stroke-width: ${({
  $borderWidth
}) => $borderWidth};
		fill: ${({
  $bgColor
}) => $bgColor};
	}
`;