import { Publish, VideoLibrary } from '@mui/icons-material';
import { Box, Button, CircularProgress, TextField, Tooltip, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import InstagramAssets from 'components/InstagramAssets';
import { MediaCategory, MediaSelectorSideBar } from 'components/styled/Modals';
import { PexelsGallery, RecentVideosGallery, VideoGallery } from 'containers/Editor/StoryEditor/MediaAssets/Galleries';
import { VIDEO_HOSTING_SUPPORTED, VIDEOS_SELECTOR_LIST } from 'lib/constants';
import useUpload from 'lib/hooks/useUpload';
import { UrlAssetIdentifier } from 'lib/models';
import { MIME_TYPES } from 'lib/upload';
import { isValidVideosUrl } from 'lib/utils';
import _ from 'lodash';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { getProjectRecentlyUsed } from 'redux/project/selectors';
import VideoList from '../../containers/Editor/StoryEditor/MediaAssets/Galleries/MediaComponent/VideoList';
import { ModalWrapper } from '.';
import { HighlightedDropzone } from './SelectorModal.style';
const fileImporterStyle = {
  display: 'none'
};
const previewImageStyle = {
  width: '100%',
  objectFit: 'contain'
};
const pexelsRegex = /pexels/i;
const snackeetStorage = /storage.googleapis.com\/snackeet/i;
const VideosSelectorModal = forwardRef(function VideosSelectorModal({
  trigger,
  onSelect,
  inputValue,
  onUploadSuccess,
  isBackground = false,
  isAmp = false,
  storyVideos = []
}, ref) {
  const videosModalRef = useRef();
  const fileImporterRef = useRef();
  const queryClient = useQueryClient();
  const [highlight, setHighlight] = useState(false);
  const [tab, setTab] = useState('import');
  const currentRecentlyUsed = useSelector(getProjectRecentlyUsed);
  useImperativeHandle(ref, () => ({
    open: () => videosModalRef.current?.open(),
    close: () => videosModalRef.current?.close()
  }));
  const {
    isUploading,
    handleUpload,
    ImportingModal,
    importingModalProps
  } = useUpload({
    acceptedMimeTypes: Object.values(MIME_TYPES.video),
    onUploadSuccess
  });
  const videoCategories = _.isEmpty(storyVideos) ? VIDEOS_SELECTOR_LIST : [{
    logo: <VideoLibrary size='large' />,
    value: 'story_videos'
  }, ...VIDEOS_SELECTOR_LIST];

  // Preset category via current link
  useEffect(() => {
    if (inputValue) {
      if (!_.isEmpty(currentRecentlyUsed?.videos)) {
        setTab('recently_used');
      } else if (snackeetStorage.test(inputValue)) {
        setTab('import');
      } else if (pexelsRegex.test(inputValue)) {
        setTab('pexels');
      }
    }
  }, [currentRecentlyUsed?.videos, inputValue]);
  const onFileSelected = useCallback(async files => {
    const uploadedFiles = await handleUpload(files);
    queryClient.invalidateQueries({
      queryKey: ['videos']
    });

    // Close if only one file was uploaded
    if (uploadedFiles.length === 1) {
      videosModalRef.current.close();
    }

    // Clear the selected file and allows the file input to be re-opened for selection
    if (fileImporterRef.current) fileImporterRef.current.value = null;

    // Open import tab after upload
    if (tab === 'recently_used') {
      setTab('import');
    }
  }, [handleUpload, queryClient, tab]);
  const onDragOver = useCallback(evt => {
    evt.preventDefault();
    setHighlight(true);
  }, []);
  const onDragLeave = useCallback(() => {
    setHighlight(false);
  }, []);
  const onFileDrop = useCallback(evt => {
    evt.preventDefault();
    const files = Array.from(evt.dataTransfer.files);
    onFileSelected(files);
    setHighlight(false);
  }, [onFileSelected]);
  const onFileImporterChange = useCallback(evt => {
    const files = Array.from(evt.target.files);
    onFileSelected(files);
  }, [onFileSelected]);
  function onVideoSelected({
    url,
    file_id,
    name,
    poster,
    instagramId
  }) {
    onSelect({
      url,
      file_id,
      name,
      poster,
      instagramId
    });
    videosModalRef.current.close();
  }
  async function onInstagramVideoImageSelected(evtData) {
    const fileUrl = new UrlAssetIdentifier({
      url: evtData.media_url,
      instagramId: evtData.id
    });
    await handleUpload(fileUrl);
    videosModalRef.current.close();
  }
  function renderMediaSelector(category) {
    switch (category) {
      case 'import':
      case 'recently_used':
        return <Box sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          overflow: 'auto'
        }} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onFileDrop}>
						<Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2
          }}>
							<Button size='large' variant='contained' color='secondary' disabled={isUploading} onClick={() => fileImporterRef.current?.click()} startIcon={!isUploading ? <Publish /> : <CircularProgress color='primary' size={20} />}>
								<FormattedMessage id='button.import' />
							</Button>
							<Typography variant='h4'>
								<FormattedMessage id='modals.images.import.instruction' />
							</Typography>
						</Box>

						{category === 'import' ? <VideoGallery onSelect={onVideoSelected} /> : <RecentVideosGallery onSelect={onVideoSelected} />}

						<HighlightedDropzone $highlight={highlight}>
							<Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
								<Image src='/static/placeholders/upload_media.svg' alt='Upload media' height={300} width={300} />
								<Box sx={{
                mt: -4,
                fontSize: '1.5rem'
              }}>
									<FormattedMessage id='modals.videos.import.dropzone' />
								</Box>
							</Box>
						</HighlightedDropzone>
					</Box>;
      case 'story_videos':
        return <VideoList videos={storyVideos} onSelect={onVideoSelected} listType='story_videos' />;
      case 'link':
        return <LinkTab inputValue={inputValue} onVideoSelected={onVideoSelected} isBackground={isBackground} isAmp={isAmp} />;
      case 'pexels':
        return <PexelsGallery search_type='videos' handleUrl={onVideoSelected} is_background={isBackground} />;
      // case 'pixabay':
      // 	return <PixabayGallery search_type='videos' handleUrl={onVideoSelected} is_background={isBackground} />
      case 'instagram':
        return <InstagramAssets mediaTypes={['VIDEO']} onAssetSelect={onInstagramVideoImageSelected} />;
    }
  }
  return <>
			<ModalWrapper title={<FormattedMessage id='modals.videos.header' />} trigger={trigger} ref={videosModalRef} size='lg' sx={{
      '.MuiDialog-paper': {
        height: '100%'
      }
    }}>
				<Box sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'auto'
      }}>
					<MediaSelectorSideBar>
						{_.map(videoCategories, (category, index) => <MediaCategory key={index} $isSelected={tab === category.value} onClick={() => setTab(category.value)}>
								{typeof category.logo === 'string' ? <img src={category.logo} /> : <>{category.logo}</>}
								<Box mx={1}>
									<FormattedMessage id={`modals.videos.type_${category.value}`} />
								</Box>
							</MediaCategory>)}
					</MediaSelectorSideBar>

					<Box sx={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 0.5,
          flex: 1
        }}>
						{renderMediaSelector(tab)}
					</Box>
				</Box>

				<input ref={fileImporterRef} style={fileImporterStyle} type='file' multiple accept={Object.values(MIME_TYPES.video).join(',')} onChange={onFileImporterChange} />
			</ModalWrapper>

			<ImportingModal {...importingModalProps} />
		</>;
});
VideosSelectorModal.propTypes = {
  trigger: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  isBackground: PropTypes.bool,
  isAmp: PropTypes.bool
};
export default VideosSelectorModal;
function LinkTab({
  inputValue,
  onVideoSelected,
  isBackground,
  isAmp
}) {
  const intl = useIntl();
  const [currentInput, setCurrentInput] = useState(inputValue);
  const [hasError, setHasError] = useState(false);
  const inputRef = useHotkeys('enter, return', () => {
    if (!hasError) {
      onVideoSelected({
        url: currentInput
      });
    }
  }, {
    preventDefault: true,
    enableOnFormTags: true
  }, [onVideoSelected, hasError, currentInput]);
  const handleInputChange = useCallback(({
    target: {
      value
    }
  }) => {
    setCurrentInput(value);
    setHasError(!isValidVideosUrl(value));
  }, []);

  // Handle if video is incompatible with the platform
  const handleVideoError = useCallback(() => {
    setHasError(true);
  }, []);
  function renderVideoPreview() {
    if (isAmp) {
      return <video src={currentInput} onError={handleVideoError} width='auto' height='100%' controls />;
    }
    return <ReactPlayer url={currentInput} width='auto' height='100%' controls />;
  }
  return <Box width='80%' display='flex' flexDirection='column' alignItems='center'>
			<Box display='flex' justifyContent='center' height={300} width='100%' mb={1}>
				{!!currentInput && !hasError ? renderVideoPreview() : <img src='/static/placeholders/media_link.svg' style={previewImageStyle} />}
			</Box>

			{!isAmp && <>
					<Box width='100%' my={1} display='flex' alignItems='center' justifyContent='space-between'>
						{_.map(VIDEO_HOSTING_SUPPORTED, brand => <Tooltip key={brand.name} title={brand.name}>
								<img width={40} src={brand.logo} alt={brand.name} />
							</Tooltip>)}
					</Box>
					<Box fontSize={12} mt={1}>
						<FormattedMessage id='editor.video_import.info' />
					</Box>
				</>}

			<Box my={1} width='100%'>
				<TextField ref={inputRef} multiline minRows={4} size='small' fullWidth variant='outlined' placeholder={intl.formatMessage({
        id: 'placeholder.video_url'
      })} onChange={handleInputChange} value={currentInput} error={hasError} />
			</Box>

			{hasError && <Box color='error.main' fontSize={11} mb={1}>
					<FormattedMessage id='modals.videos.link.invalid' />
				</Box>}

			<Button variant='contained' color='primary' size='large' disabled={hasError} onClick={() => onVideoSelected({
      url: currentInput
    })}>
				<FormattedMessage id='button.confirm' />
			</Button>
		</Box>;
}