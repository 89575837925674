import { css } from '@emotion/react';
import Slider from '@mui/material/Slider';
import styled from '@styled';
import { readableColor } from 'polished';
const OPTION_STYLE = {
  light: {
    flat: css`
			border: 0.5px solid #ccc;
		`,
    shadow: css`
			box-shadow: rgba(60, 60, 60, 0.2) 0px 2px;
		`,
    transparent: css`
			border: none;
		`
  },
  dark: {
    flat: css`
			border: 0.5px solid #afafaf;
		`,
    shadow: css`
			box-shadow: rgba(0, 0, 0, 0.6) 0px 2px;
		`,
    transparent: css`
			border: none;
		`
  }
};
const IMAGE_TRANSPARENT_BORDER = {
  light: css`
		border: 2.5px solid white;
	`,
  dark: css`
		border: 2.5px solid black;
	`
};
const TRANSPARENT_STYLE = css`
	background-color: transparent !important;
`;
const PROMPT_SIZE = {
  small: css`
		font-size: 14px;
	`,
  medium: css`
		font-size: 17px;
	`,
  large: css`
		font-size: 22px;
	`
};
export const AmpInteractionBase = styled.div`
	width: 300px;
	height: auto;
	font-size: 14px;
	border-radius: 2em;
	background-color: ${({
  $backgroundColor
}) => $backgroundColor};
	position: absolute;

	${({
  $isTransparent
}) => $isTransparent && TRANSPARENT_STYLE};
	/* overflow: hidden; */
`;
export const PromptTextContainer = styled.div`
	width: 100%;
	font-size: 14px;
	padding: 1em;
	text-align: ${({
  $alignment
}) => $alignment};

	p {
		text-align: ${({
  $alignment
}) => $alignment};
		margin: 0 auto;
		color: ${({
  $promptColor
}) => $promptColor};
		font-weight: 700;
		font-family: Poppins, sans-serif;
		${({
  $promptSize
}) => PROMPT_SIZE[$promptSize]};
		line-height: 1.2em;
	}
`;
export const OptionsContainer = styled.div`
	width: 100%;
	font-size: 14px;
	padding: 0.5em;
	background-color: ${({
  $theme
}) => $theme === 'dark' ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)'};
	border-radius: 0.5em;

	${({
  $isTransparent
}) => $isTransparent && TRANSPARENT_STYLE};

	.option-container:not(:first-of-type) {
		margin-top: 0.5em;
	}

	.option-container {
		width: 100%;
		padding: 0.3em 0.6em;
		font-size: 14px;
		border-radius: 0.5em;
		background-color: ${({
  $theme
}) => $theme === 'dark' ? 'rgba(32, 33, 36, 1)' : 'rgba(255, 255, 255, 1)'};
		min-height: 2.5em;
		overflow: hidden;
		display: flex;
		align-items: center;

		${({
  $optionStyle,
  $theme
}) => OPTION_STYLE[$theme][$optionStyle]};

		span {
			display: -webkit-box;
			max-height: 3em;
			width: 100%;
			font-size: 14px;
			line-height: 1.2em;
			word-wrap: break-word;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			font-weight: 700;
			font-family: Poppins, sans-serif;
			color: ${({
  $theme
}) => $theme === 'light' ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)'};
		}
	}
`;
export const AmpSliderInteractionBase = styled(AmpInteractionBase)``;
export const SliderTextContainer = styled.div`
	width: 100%;
	padding: 1.2em;
	text-align: center;

	p {
		text-align: center;
		margin: 0 auto;
		color: ${({
  $theme
}) => $theme === 'dark' ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)'};
		font-weight: 700;
		font-family: Poppins, sans-serif;
		font-size: 20px;
		line-height: 1.2em;
	}
`;
export const SliderContainer = styled.div`
	width: 100%;
	height: 50px;
	padding-left: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({
  $theme
}) => $theme === 'dark' ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)'};
	border-radius: 100px;
`;

// Binary Poll

export const AmpBinaryInteractionBase = styled(AmpInteractionBase)`
	width: 250px;
	height: auto;
	font-size: 14px;
	border-radius: 2em;
	background-color: ${({
  $backgroundColor
}) => $backgroundColor};
`;
export const BinaryOptionsContainer = styled.div`
	width: 100%;
	height: 5em;
	font-size: 14px;
	background-color: ${({
  $theme
}) => $theme === 'dark' ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)'};
	border-radius: 1em;
	display: flex;

	.option-container {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.5em 0.75em;
		font-size: 14px;

		span {
			display: -webkit-box;
			text-align: center;
			max-height: 3em;
			width: 100%;
			font-size: 24.5px;
			line-height: 1.2em;
			word-wrap: break-word;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			font-weight: 700;
			font-family: Poppins, sans-serif;
			color: ${({
  $optionColor
}) => $optionColor};
		}
	}

	.divider {
		width: 1px;
		height: 100%;
		background-color: rgba(167, 173, 180, 0.3);
	}
`;

// Image Poll

export const AmpImageInteractionBase = styled(AmpInteractionBase)`
	width: 250px;
	height: auto;
	font-size: 14px;
	border-radius: 3.75em 3.75em 4.5em 4.5em;
	background-color: ${({
  $backgroundColor
}) => $backgroundColor};

	${({
  $isTransparent
}) => $isTransparent && TRANSPARENT_STYLE};
`;
export const ImageOptionsContainer = styled.div`
	width: 100%;
	font-size: 14px;
	padding: 1em;
	background-color: ${({
  $theme
}) => $theme === 'dark' ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)'};
	border-radius: 4.375em;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	flex-wrap: wrap;

	${({
  $isTransparent
}) => $isTransparent && TRANSPARENT_STYLE};

	.option-container {
		width: 80px;
		height: 80px;
		margin: 0 auto;
		background-color: #ccc;
		border-radius: 50%;
		overflow: hidden;

		${({
  $isTransparent,
  $theme
}) => $isTransparent && IMAGE_TRANSPARENT_BORDER[$theme]};

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.option-container:nth-child(4) {
		margin-top: 0.6em;
	}
`;

// Quiz

export const QuizOptionsContainer = styled.div`
	width: 100%;
	font-size: 14px;
	padding: 0.5em;
	background-color: ${({
  $theme
}) => $theme === 'dark' ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)'};
	border-radius: 2em;
	${({
  $isTransparent
}) => $isTransparent && TRANSPARENT_STYLE};

	.option-container:not(:first-of-type) {
		margin-top: 0.5em;
	}

	.option-container {
		width: 100%;
		padding: 0.5em 1em 0.5em 0.5em;
		font-size: 14px;
		border-radius: 2em;
		background-color: ${({
  $theme
}) => $theme === 'dark' ? 'rgba(32, 33, 36, 1)' : 'rgba(255, 255, 255, 1)'};
		height: 40px;
		overflow: hidden;
		display: flex;
		align-items: center;

		${({
  $optionStyle,
  $theme
}) => OPTION_STYLE[$theme][$optionStyle]};

		.list-alphabet {
			height: 28px;
			width: 28px;
			font-weight: 700;
			font-family: Poppins, sans-serif;
			color: ${({
  $optionColor
}) => $optionColor};
			border: ${({
  $optionColor
}) => $optionColor} 1.75px solid;
			background-color: transparent;
			border-radius: 50%;
			margin-right: 1em;
			display: flex;
			flex-shrink: 1;
			justify-content: center;
			align-items: center;
		}

		span {
			display: -webkit-box;
			max-height: 3.5em;
			width: calc(100% - 35px);
			font-size: 12.5px;
			line-height: 1.2em;
			word-wrap: break-word;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			font-family: Poppins, sans-serif;
			color: ${({
  $theme
}) => $theme === 'light' ? 'rgba(0, 0, 0, 1)' : 'rgba(167, 173, 180, 1)'};
		}
	}
`;
export const QuizOption = styled.div`
	background-color: ${({
  $isCorrect
}) => $isCorrect ? 'rgb(91, 186, 116) !important' : 'inherit'};

	.list-alphabet {
		background-color: ${({
  $isCorrect
}) => $isCorrect ? 'rgb(255, 255, 255) !important' : 'inherit'};
		border-color: ${({
  $isCorrect
}) => $isCorrect ? 'rgb(255, 255, 255) !important' : 'inherit'};
		color: ${({
  $isCorrect
}) => $isCorrect ? 'rgb(91, 186, 116) !important' : 'inherit'};
	}

	span {
		color: ${({
  $isCorrect
}) => $isCorrect ? 'rgb(255, 255, 255) !important' : 'inherit'};
	}
`;

// Image Quiz

export const ImageQuizOptionsContainer = styled.div`
	width: 100%;
	font-size: 14px;
	padding: 1em;
	background-color: ${({
  $theme
}) => $theme === 'dark' ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)'};
	border-radius: 4.375em;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	flex-wrap: wrap;
	${({
  $isTransparent
}) => $isTransparent && TRANSPARENT_STYLE};

	.option-container {
		width: 80px;
		height: 80px;
		margin: 0 auto;
		background-color: #ccc;
		border-radius: 50%;
		position: relative;

		${({
  $isTransparent,
  $theme
}) => $isTransparent && IMAGE_TRANSPARENT_BORDER[$theme]};

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 50%;
		}

		.list-alphabet {
			height: 21px;
			width: 21px;
			position: absolute;
			bottom: 2px;
			right: 2px;
			font-size: 12px;
			font-weight: 700;
			font-family: Poppins, sans-serif;
			color: ${({
  $optionColor
}) => readableColor($optionColor)};
			background-color: ${({
  $optionColor
}) => $optionColor};
			border: ${({
  $theme
}) => $theme === 'dark' ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)'} 1.75px solid;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;

			.MuiSvgIcon-root {
				width: 0.55em;
				height: 0.55em;
			}
		}
	}

	.option-container:nth-child(4) {
		margin-top: 1em;
	}
`;
export const ImageQuizOption = styled.div`
	.list-alphabet {
		background-color: ${({
  $isCorrect
}) => $isCorrect ? 'rgb(91, 186, 116) !important' : 'inherit'};
		color: ${({
  $isCorrect
}) => $isCorrect ? 'rgb(255, 255, 255) !important' : 'inherit'};
	}
`;
export const StyledSlider = styled(Slider)`
	width: 90%;
	color: ${({
  $optionColor
}) => $optionColor};
	height: 20px;
	pointer-events: none;

	.MuiSlider-thumb {
		height: 36px;
		width: 36px;
		font-size: 20px;
		background-color: ${({
  $optionColor
}) => $optionColor};
		border: ${({
  $theme
}) => $theme === 'dark' ? 'rgba(0, 0, 0, 1)' : 'rgba(255, 255, 255, 1)'} 1px solid;
	}
	.MuiSlider-track {
		height: 20px;
		border-radius: 10px;
	}
	.MuiSlider-rail {
		height: 20px;
		border-radius: 10px;
	}
`;