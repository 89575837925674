import { Delete, DragIndicator, FileCopy } from '@mui/icons-material';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import { QueryBuilderDnD } from '@react-querybuilder/dnd';
import DisplayRedirections from 'components/organisms/DisplayRedirections';
import Dropdown from 'components/organisms/Dropdown';
import GroupDropdown from 'components/organisms/LogicGroupDropdown';
import { ConditionCardWrapper, ConditionWrapper, DeleteConsequenceButton, ModalContent, QueryBuilderWrapper } from 'components/styled/Modals';
import { combinators, cond_headers, consequences_options, getValidOptions, operators, translations, validateLogic } from 'lib/constants';
import { useStoryState } from 'lib/editorStore';
import { extractConditionalFields, reorder, sleep } from 'lib/utils';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { MaterialActionElement, MaterialDragHandle, MaterialFieldSelector, MaterialNotToggle, MaterialOperatorSelector, MaterialRemoveButton, MaterialValueEditor, MaterialValueSelector } from 'organisms/queryBuilder';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import * as ReactDnD from 'react-dnd';
import * as ReactDndHtml5Backend from 'react-dnd-html5-backend';
import { FormattedMessage, useIntl } from 'react-intl';
import QueryBuilder from 'react-querybuilder';
import { useDebouncedCallback } from 'use-debounce';
import { useImmer } from 'use-immer';
import { ModalWrapper } from '../ModalWrapper';

// Custome theme of QueryBuilder with material-ui
const controlElements = {
  addGroupAction: MaterialActionElement,
  addRuleAction: MaterialActionElement,
  cloneGroupAction: MaterialActionElement,
  cloneRuleAction: MaterialActionElement,
  combinatorSelector: MaterialValueSelector,
  fieldSelector: MaterialFieldSelector,
  notToggle: MaterialNotToggle,
  operatorSelector: MaterialOperatorSelector,
  removeGroupAction: MaterialRemoveButton,
  removeRuleAction: MaterialRemoveButton,
  valueEditor: MaterialValueEditor,
  dragHandle: MaterialDragHandle
};
const DEFAULT_CONDITION = {
  id: 'root',
  combinator: 'and',
  not: false,
  rules: []
};
const PageConditionSettings = forwardRef(({
  logics,
  nextPagesOptions,
  handleUpdateLogics,
  handleOpenVariablesModal,
  disableBackdropClick = true,
  disableEscapeKeyDown = true
}, ref) => {
  const intl = useIntl();
  const conditionRef = useRef();
  const endRef = useRef();
  const draftStory = useStoryState(s => s.draftStory);
  const page = useStoryState(s => s.page);
  const [states, setStates] = useImmer({
    logics: initialLogics(logics),
    status: []
  });
  const conditionalFields = extractConditionalFields(draftStory, page);
  const numericalVariables = _.map(_.filter(draftStory.variables, ['type', 'number']), variable => ({
    name: variable.name,
    type: 'variable'
  }));
  const textVariables = _.map(_.filter(draftStory.variables, ['type', 'text']), variable => ({
    name: variable.name,
    type: 'variable'
  }));
  const incrementList = [{
    value: 'number',
    type: 'group',
    options: [{
      name: 'inputNumber',
      type: 'number'
    }]
  }, {
    value: 'variable',
    type: 'group',
    options: [...numericalVariables]
  }];
  const open = () => {
    conditionRef.current.open();
  };
  const close = () => {
    conditionRef.current.close();
  };
  async function addNewLogic() {
    setStates(draft => {
      draft.logics.push({
        _id: nanoid(),
        cond_header: 'if',
        conditions: DEFAULT_CONDITION,
        consequences: [{
          _id: nanoid()
        }]
      });
      draft.status.push(false);
    });
    await sleep(200);
    endRef.current.scrollIntoView({
      behavior: 'smooth'
    });
  }
  async function CloneLogic(logic) {
    setStates(draft => {
      draft.logics.push({
        ...logic,
        _id: nanoid()
      });
      draft.status.push(false);
    });
    await sleep(200);
    endRef.current.scrollIntoView({
      behavior: 'smooth'
    });
  }
  function updateStatus(value, logic_index) {
    setStates(draft => {
      draft.status[logic_index] = value;
    });
  }

  // Save condition into context
  function confirmLogics() {
    handleUpdateLogics(states.logics);
    conditionRef.current.close();
  }
  function updateHeaderCondtion(cond_header, logic_index) {
    setStates(draft => {
      draft.logics[logic_index].cond_header = cond_header;
      if (cond_header === 'always') {
        delete draft.logics[logic_index].conditions;
      }
    });
  }
  function updateConditions(conditions, logic_index) {
    setStates(draft => {
      draft.logics[logic_index].conditions = conditions;
    });
  }
  function removeLogic(logic_index) {
    setStates(draft => {
      draft.logics.splice(logic_index, 1);
      draft.status.splice(logic_index, 1);
    });
  }

  // Handle Consequences
  function addConsequense(logic_index) {
    setStates(draft => {
      draft.logics[logic_index].consequences.push({
        _id: nanoid()
      });
    });
  }
  function removeConsequence(conq_index, logic_index) {
    setStates(draft => {
      draft.logics[logic_index].consequences.splice(conq_index, 1);
    });
  }
  function updateConsequenceCondition(condition, conq_index, logic_index) {
    setStates(draft => {
      if (draft.logics[logic_index].consequences[conq_index]?.condition && draft.logics[logic_index].consequences[conq_index].condition.type !== condition.type) {
        draft.logics[logic_index].consequences[conq_index].condition = condition;
        delete draft.logics[logic_index].consequences[conq_index].variable;
        delete draft.logics[logic_index].consequences[conq_index].increment;
        delete draft.logics[logic_index].consequences[conq_index].incrementValue;
      } else {
        draft.logics[logic_index].consequences[conq_index].condition = condition;
      }
    });
  }
  function updateConsequenceVariable(variable, conq_index, logic_index) {
    setStates(draft => {
      draft.logics[logic_index].consequences[conq_index].variable = variable;
    });
  }
  function updateConsequenceIncrement(increment, conq_index, logic_index) {
    setStates(draft => {
      draft.logics[logic_index].consequences[conq_index].increment = increment;
    });
  }
  function updateConsequenceIncrementValue(incrementValue, conq_index, logic_index) {
    setStates(draft => {
      draft.logics[logic_index].consequences[conq_index].incrementValue = incrementValue;
    });
  }
  function onLogicsDragEnd(result) {
    if (!result.destination) {
      return;
    }
    setStates(draft => {
      draft.logics = reorder(draft.logics, result.source.index, result.destination.index);
    });
  }
  useImperativeHandle(ref, () => ({
    open,
    close
  }));
  return <ModalWrapper title={intl.messages['modals.condition.header']} ref={conditionRef} size='lg' hasCloseButton={false} disableBackdropClick={disableBackdropClick} disableEscapeKeyDown={disableEscapeKeyDown}>
				<ModalContent $isCondition>
					<Box ml={4}>
						<DisplayRedirections page={page} />
					</Box>

					<DragDropContext onDragEnd={onLogicsDragEnd}>
						<Droppable droppableId='droppable'>
							{(provided, snapshot) => <div {...provided.droppableProps} ref={provided.innerRef}>
									{!_.isEmpty(states.logics) && _.map(states.logics, (logic, index) => {
              if (logic.cond_header === 'if') {
                return <Draggable key={logic._id} draggableId={logic._id} index={index}>
														{provided => <div key={logic._id} ref={provided.innerRef} {...provided.draggableProps} className='logic-card'>
																<div {...provided.dragHandleProps} className='logic-indicator'>
																	<DragIndicator />
																</div>
																<ConditionCard key={logic._id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} isDragging={snapshot.isDragging} logic_index={index} logic={logic} nextPagesOptions={nextPagesOptions} draftStory={draftStory} updateConsequenceCondition={updateConsequenceCondition} updateConsequenceVariable={updateConsequenceVariable} updateConsequenceIncrement={updateConsequenceIncrement} updateConsequenceIncrementValue={updateConsequenceIncrementValue} removeConsequence={removeConsequence} conditionalFields={conditionalFields} numericalVariables={numericalVariables} textVariables={textVariables} incrementList={incrementList} updateHeaderCondtion={updateHeaderCondtion} updateConditions={updateConditions} addConsequense={addConsequense} updateStatus={updateStatus} removeLogic={removeLogic} cloneLogic={CloneLogic} />
															</div>}
													</Draggable>;
              }
              return <Draggable key={logic._id} draggableId={logic._id} index={index}>
													{provided => <div key={logic._id} ref={provided.innerRef} {...provided.draggableProps} className='logic-card'>
															<div {...provided.dragHandleProps} className='logic-indicator'>
																<DragIndicator />
															</div>
															<AlwaysCard key={logic._id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} isDragging={snapshot.isDragging} logic_index={index} logic={logic} nextPagesOptions={nextPagesOptions} updateConsequenceCondition={updateConsequenceCondition} updateConsequenceVariable={updateConsequenceVariable} updateConsequenceIncrement={updateConsequenceIncrement} updateConsequenceIncrementValue={updateConsequenceIncrementValue} removeConsequence={removeConsequence} numericalVariables={numericalVariables} textVariables={textVariables} incrementList={incrementList} updateHeaderCondtion={updateHeaderCondtion} addConsequense={addConsequense} updateStatus={updateStatus} removeLogic={removeLogic} />
														</div>}
												</Draggable>;
            })}
									{provided.placeholder}
								</div>}
						</Droppable>
					</DragDropContext>

					{/* To Scroll to end of  */}
					<div ref={endRef} />
				</ModalContent>
				<Box pl={5} pt={1} width='100%' display='flex' alignItems='center' justifyContent='flex-start'>
					<Button variant='outlined' color='primary' onClick={addNewLogic}>
						{intl.messages['conditions.add_logic']}
					</Button>
				</Box>

				<Box px={5}>
					<Divider />
					<Typography variant='h3' gutterBottom>
						<FormattedMessage id='condition.excute.order' />
					</Typography>
					{_.some(states.logics, logic => _.some(logic.consequences, conq => conq?.condition?.value === 'redirect')) && <Typography variant='h3'>{getNextPageName(page, draftStory.pages)}</Typography>}
				</Box>

				<Box width='100%' display='flex' alignItems='center' justifyContent='flex-end' p={1.5} pt={1}>
					<Button variant='text' color='error' style={{
        marginRight: '16px'
      }} onClick={close}>
						{intl.messages['button.cancel']}
					</Button>

					<Button variant='outlined' style={{
        marginRight: '16px'
      }} color='secondary' onClick={handleOpenVariablesModal}>
						{intl.messages['edition_menu.advanced_logic.button.config_variables']}
					</Button>

					<Button variant='contained' color='primary' disabled={!getStatusBoolean(states.status)} onClick={confirmLogics}>
						{intl.messages['button.confirm']}
					</Button>
				</Box>
			</ModalWrapper>;
});
PageConditionSettings.propTypes = {
  logics: PropTypes.array,
  nextPagesOptions: PropTypes.array,
  handleUpdateLogics: PropTypes.func,
  handleOpenVariablesModal: PropTypes.func,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool
};
PageConditionSettings.displayName = 'PageConditionSettings';
export default PageConditionSettings;
function ConditionCard({
  logic_index,
  logic,
  nextPagesOptions,
  draftStory,
  updateConsequenceCondition,
  updateConsequenceVariable,
  updateConsequenceIncrement,
  updateConsequenceIncrementValue,
  removeConsequence,
  conditionalFields,
  numericalVariables,
  textVariables,
  incrementList,
  updateHeaderCondtion,
  updateConditions,
  addConsequense,
  updateStatus,
  removeLogic,
  cloneLogic
}) {
  const intl = useIntl();
  const {
    cond_header,
    conditions,
    consequences
  } = logic;
  const [status, setStatus] = useState(0);
  const filtered_consequences_options = _.some(consequences, conq => conq?.condition?.value === 'redirect') ? _.filter(consequences_options, option => option.value !== 'logic') : consequences_options;
  useEffect(() => {
    const newStatus = validateLogic(logic);
    setStatus(newStatus);
    if (newStatus > 0) {
      updateStatus(false, logic_index);
    } else {
      updateStatus(true, logic_index);
    }
  }, [logic, logic_index, updateStatus]);
  function handleUpdateHeaderCondtion({
    target: {
      value
    }
  }) {
    updateHeaderCondtion(value, logic_index);
  }
  function handleAddConsequense() {
    addConsequense(logic_index);
  }
  function updateQuery(query) {
    updateConditions(query, logic_index);
  }
  function handleUpdateConsequenceCondition(condition, conq_index) {
    updateConsequenceCondition(condition, conq_index, logic_index);
  }
  function handleUpdateConsequenceVariable(variable, conq_index) {
    updateConsequenceVariable(variable, conq_index, logic_index);
  }
  function handleUpdateConsequenceIncrement(increment, conq_index) {
    updateConsequenceIncrement(increment, conq_index, logic_index);
  }
  function handleUpdateIncrementValue(value, conq_index) {
    updateConsequenceIncrementValue(value, conq_index, logic_index);
  }
  function handleRemoveConsequence(conq_index) {
    removeConsequence(conq_index, logic_index);
  }
  return <Box display='grid' gridTemplateColumns='25px auto'>
			<Typography variant='h1'>
				<b>{`${logic_index + 1}.`}</b>
			</Typography>
			<ConditionWrapper width='100%' mb={1} $isError={status > 0}>
				<Box display='grid' gridTemplateColumns='1.3fr 1fr' gap={1}>
					<ConditionCardWrapper $isConditional>
						<div className='condition-header'>
							<Dropdown fullWidth variant='outlined' value={cond_header} options={cond_headers} onChange={handleUpdateHeaderCondtion} />
						</div>
						<QueryBuilderWrapper>
							<QueryBuilderDnD dnd={{
              ...ReactDnD,
              ...ReactDndHtml5Backend
            }}>
								<QueryBuilder controlElements={controlElements} translations={translations} showCombinatorsBetweenRules={true} resetOnOperatorChange={false} combinators={combinators} fields={conditionalFields} getOperators={field => getValidOptions(conditionalFields, field, draftStory)} operators={operators} query={conditions} onQueryChange={updateQuery} enableDragAndDrop={true} />
							</QueryBuilderDnD>
						</QueryBuilderWrapper>
					</ConditionCardWrapper>

					<ConditionCardWrapper $lastItem>
						<Box mt={2.5} display='flex' alignItems='flex-start'>
							<Typography variant='h4'>
								<FormattedMessage id='conditions.consequence.then' />
							</Typography>
						</Box>
						<Box width='100%' className='consequences-container'>
							{_.map(consequences, (consequence, index) => <ConsequenceStatement key={consequence._id} conq_index={index} consequence={consequence} consequenceOptions={filtered_consequences_options} nextPagesOptions={nextPagesOptions} numericalVariables={numericalVariables} textVariables={textVariables} incrementList={incrementList} handleUpdateConsequenceCondition={handleUpdateConsequenceCondition} handleUpdateConsequenceVariable={handleUpdateConsequenceVariable} handleUpdateConsequenceIncrement={handleUpdateConsequenceIncrement} handleUpdateIncrementValue={handleUpdateIncrementValue} handleRemoveConsequence={handleRemoveConsequence} canBeRemoved={consequences.length > 1} />)}

							<Button variant='text' color='primary' onClick={() => handleAddConsequense()}>
								{intl.messages['conditions.consequence.add_consequence']}
							</Button>
						</Box>
					</ConditionCardWrapper>
				</Box>

				<Box width='100%' display='flex' justifyContent='space-between'>
					<Box display='flex' alignItems='center' pl={1}>
						{status > 0 ? <Typography color='error' variant='body1'>
								<FormattedMessage id={`conditions.logic_error.case_${status}`} />
							</Typography> : <Button startIcon={<FileCopy />} variant='text' color='primary' size='small' onClick={() => cloneLogic(logic)}>
								<FormattedMessage id='conditions.clone_logic' />
							</Button>}
					</Box>

					<Button startIcon={<Delete />} variant='text' color='error' size='small' onClick={() => removeLogic(logic_index)}>
						<FormattedMessage id='conditions.delete_logic' />
					</Button>
				</Box>
			</ConditionWrapper>
		</Box>;
}
ConditionCard.propTypes = {
  logic_index: PropTypes.number,
  logic: PropTypes.object,
  nextPagesOptions: PropTypes.array,
  draftStory: PropTypes.object,
  conditionalFields: PropTypes.array,
  numericalVariables: PropTypes.array,
  textVariables: PropTypes.array,
  incrementList: PropTypes.array,
  updateHeaderCondtion: PropTypes.func,
  updateConditions: PropTypes.func,
  addConsequense: PropTypes.func,
  updateStatus: PropTypes.func,
  removeLogic: PropTypes.func,
  updateConsequenceCondition: PropTypes.func,
  updateConsequenceVariable: PropTypes.func,
  updateConsequenceIncrement: PropTypes.func,
  updateConsequenceIncrementValue: PropTypes.func,
  removeConsequence: PropTypes.func
};
function AlwaysCard({
  logic_index,
  logic,
  nextPagesOptions,
  updateConsequenceCondition,
  updateConsequenceVariable,
  updateConsequenceIncrement,
  updateConsequenceIncrementValue,
  removeConsequence,
  numericalVariables,
  textVariables,
  incrementList,
  updateHeaderCondtion,
  addConsequense,
  updateStatus,
  removeLogic
}) {
  const {
    cond_header,
    consequences
  } = logic;
  const [status, setStatus] = useState(0);
  const filtered_consequences_options = _.filter(consequences_options, option => option.value !== 'logic');
  useEffect(() => {
    const newStatus = validateLogic(logic);
    setStatus(newStatus);
    if (newStatus > 0) {
      updateStatus(false, logic_index);
    } else {
      updateStatus(true, logic_index);
    }
  }, [logic]);
  function handleUpdateHeaderCondtion({
    target: {
      value
    }
  }) {
    updateHeaderCondtion(value, logic_index);
  }
  function handleAddConsequense() {
    addConsequense(logic_index);
  }
  function handleUpdateConsequenceCondition(condition, conq_index) {
    updateConsequenceCondition(condition, conq_index, logic_index);
  }
  function handleUpdateConsequenceVariable(variable, conq_index) {
    updateConsequenceVariable(variable, conq_index, logic_index);
  }
  function handleUpdateConsequenceIncrement(increment, conq_index) {
    updateConsequenceIncrement(increment, conq_index, logic_index);
  }
  function handleUpdateIncrementValue(value, conq_index) {
    updateConsequenceIncrementValue(value, conq_index, logic_index);
  }
  function handleRemoveConsequence(conq_index) {
    removeConsequence(conq_index, logic_index);
  }
  return <Box display='grid' gridTemplateColumns='25px auto'>
			<Typography variant='h1'>
				<b>{`${logic_index + 1}.`}</b>
			</Typography>

			<ConditionWrapper width='100%' mb={1} $isError={status > 0}>
				<ConditionCardWrapper $isAlways $lastItem>
					<div className='condition-header'>
						<Dropdown fullWidth variant='outlined' value={cond_header} options={cond_headers} onChange={handleUpdateHeaderCondtion} />
					</div>
					<Box width='100%' className='consequences-container'>
						{_.map(consequences, (consequence, index) => <ConsequenceStatement key={consequence._id} conq_index={index} consequence={consequence} nextPagesOptions={nextPagesOptions} consequenceOptions={filtered_consequences_options} numericalVariables={numericalVariables} textVariables={textVariables} incrementList={incrementList} handleUpdateConsequenceCondition={handleUpdateConsequenceCondition} handleUpdateConsequenceVariable={handleUpdateConsequenceVariable} handleUpdateConsequenceIncrement={handleUpdateConsequenceIncrement} handleUpdateIncrementValue={handleUpdateIncrementValue} handleRemoveConsequence={handleRemoveConsequence} canBeRemoved={consequences.length > 1} />)}

						<Button variant='text' color='primary' onClick={() => handleAddConsequense()}>
							<FormattedMessage id='conditions.consequence.add_consequence' />
						</Button>
					</Box>
				</ConditionCardWrapper>

				<Box width='100%' display='flex' justifyContent='space-between'>
					<Box display='flex' alignItems='center' pl={1}>
						{status > 0 && <Typography color='error' variant='body1'>
								<FormattedMessage id={`conditions.logic_error.case_${status}`} />
							</Typography>}
					</Box>

					<Button startIcon={<Delete />} variant='text' color='error' size='small' onClick={() => removeLogic(logic_index)}>
						<FormattedMessage id='conditions.delete_logic' />
					</Button>
				</Box>
			</ConditionWrapper>
		</Box>;
}
AlwaysCard.propTypes = {
  logic_index: PropTypes.number,
  logic: PropTypes.object,
  nextPagesOptions: PropTypes.array,
  numericalVariables: PropTypes.array,
  textVariables: PropTypes.array,
  incrementList: PropTypes.array,
  updateHeaderCondtion: PropTypes.func,
  addConsequense: PropTypes.func,
  updateStatus: PropTypes.func,
  removeLogic: PropTypes.func,
  updateConsequenceCondition: PropTypes.func,
  updateConsequenceVariable: PropTypes.func,
  updateConsequenceIncrement: PropTypes.func,
  updateConsequenceIncrementValue: PropTypes.func,
  removeConsequence: PropTypes.func
};
function ConsequenceStatement({
  conq_index,
  consequence,
  consequenceOptions,
  nextPagesOptions,
  numericalVariables,
  textVariables,
  incrementList,
  handleUpdateConsequenceCondition,
  handleUpdateConsequenceVariable,
  handleUpdateConsequenceIncrement,
  handleUpdateIncrementValue,
  handleRemoveConsequence,
  canBeRemoved
}) {
  const intl = useIntl();
  const [incrementValue, setIncrementValue] = useState(consequence?.incrementValue || '');
  const current_consequence_options = consequence?.condition?.value === 'redirect' ? consequences_options : consequenceOptions;
  const delayUpdateConsequenceIncrementValue = useDebouncedCallback(handleUpdateIncrementValue, 400);
  useEffect(() => {
    if (consequence?.condition?.value === 'redirect') {
      if (consequence?.incrementValue) {
        setIncrementValue(consequence?.incrementValue);
        delayUpdateConsequenceIncrementValue(incrementValue, conq_index);
      } else {
        setIncrementValue('next');
        delayUpdateConsequenceIncrementValue('next', conq_index);
      }
    } else {
      setIncrementValue(consequence?.incrementValue || '');
      delayUpdateConsequenceIncrementValue(incrementValue, conq_index);
    }
  }, [consequence?.condition?.type]);
  function updateCondition(condition) {
    if (_.isEmpty(condition)) {
      return;
    }
    const jsonCond = JSON.parse(condition);
    handleUpdateConsequenceCondition(jsonCond, conq_index);
  }
  function updateVariable(variable) {
    if (_.isEmpty(variable)) {
      return;
    }
    const jsonVar = JSON.parse(variable);
    handleUpdateConsequenceVariable(jsonVar, conq_index);
  }
  function updateIncrement(increment) {
    if (_.isEmpty(increment)) {
      return;
    }
    const jsonIncre = JSON.parse(increment);
    handleUpdateConsequenceIncrement(jsonIncre, conq_index);
  }
  function updateIncrementValue(value) {
    setIncrementValue(value);
    delayUpdateConsequenceIncrementValue(value, conq_index);
  }
  function removeConsequence() {
    handleRemoveConsequence(conq_index);
  }
  function renderConsequenceSettings() {
    if (_.isEmpty(consequence?.condition)) {
      return <Box width='100%' className='consequence'>
					<GroupDropdown value={consequence?.condition || null} options={current_consequence_options} placeholder={intl.messages['conditions.consequence.add_consequence']} onChange={event => updateCondition(event.target.value)} variant='outlined' />
				</Box>;
    } else if (consequence.condition.type === 'numerical') {
      return <Box width='100%' gridTemplateColumns='1fr 1fr 10px 1fr' className='consequence'>
					<GroupDropdown value={consequence?.condition || null} options={current_consequence_options} placeholder={intl.messages['conditions.consequence.add_consequence']} onChange={event => updateCondition(event.target.value)} variant='outlined' />

					{_.includes(['divide', 'multiply'], consequence.condition.value) ? <>
							<GroupDropdown value={consequence.variable || null} options={numericalVariables} onChange={evt => updateVariable(evt.target.value)} placeholder={intl.messages['conditions.consequence.variable_selector']} variant='outlined' isVariables />

							<Box textAlign='center'>
								<b>
									<FormattedMessage id={`conditions.consequence.conjunction_${consequence.condition.value}`} />
								</b>
							</Box>

							{consequence.increment && consequence?.increment?.type === 'number' ? <Box width='100%' display='grid' gridTemplateColumns='1fr 1fr' gap={1}>
									<GroupDropdown value={consequence?.increment || null} options={incrementList} onChange={evt => updateIncrement(evt.target.value)} placeholder={intl.messages['conditions.consequence.increment_selector']} variant='outlined' isVariables />

									<TextField fullWidth type='number' variant='outlined' placeholder={intl.messages['conditions.consequence.input_number']} value={incrementValue} onChange={({
              target: {
                value
              }
            }) => updateIncrementValue(value)} error={_.isEmpty(incrementValue)} />
								</Box> : <GroupDropdown value={consequence?.increment || null} options={incrementList} onChange={evt => updateIncrement(evt.target.value)} placeholder={intl.messages['conditions.consequence.increment_selector']} variant='outlined' isVariables />}
						</> : <>
							{consequence.increment && consequence?.increment?.type === 'number' ? <Box width='100%' display='grid' gridTemplateColumns='1fr 1fr' gap={1}>
									<GroupDropdown value={consequence?.increment || null} options={incrementList} onChange={evt => updateIncrement(evt.target.value)} placeholder={intl.messages['conditions.consequence.increment_selector']} variant='outlined' isVariables />

									<TextField fullWidth type='number' variant='outlined' placeholder={intl.messages['conditions.consequence.input_number']} value={incrementValue} onChange={({
              target: {
                value
              }
            }) => updateIncrementValue(value)} error={_.isEmpty(incrementValue)} />
								</Box> : <GroupDropdown value={consequence?.increment || null} options={incrementList} onChange={evt => updateIncrement(evt.target.value)} placeholder={intl.messages['conditions.consequence.increment_selector']} variant='outlined' isVariables />}

							<Box textAlign='center'>
								<b>
									<FormattedMessage id={`conditions.consequence.conjunction_${consequence.condition.value}`} />
								</b>
							</Box>

							<GroupDropdown value={consequence.variable || null} options={numericalVariables} onChange={evt => updateVariable(evt.target.value)} placeholder={intl.messages['conditions.consequence.variable_selector']} variant='outlined' isVariables />
						</>}
				</Box>;
    } else if (consequence.condition.type === 'text') {
      return <Box width='100%' gridTemplateColumns='1fr 1fr 20px 1fr' className='consequence'>
					<GroupDropdown value={consequence?.condition || null} options={current_consequence_options} placeholder={intl.messages['conditions.consequence.add_consequence']} onChange={event => updateCondition(event.target.value)} variant='outlined' />

					<GroupDropdown value={consequence.variable || null} options={textVariables} onChange={evt => updateVariable(evt.target.value)} placeholder={intl.messages['conditions.consequence.variable_selector']} variant='outlined' isVariables />

					<Box textAlign='center'>
						<b>
							<FormattedMessage id='conditions.consequence.conjunction_replace' />
						</b>
					</Box>

					<TextField fullWidth type='text' variant='outlined' placeholder={intl.messages['conditions.consequence.input_text']} value={incrementValue} onChange={({
          target: {
            value
          }
        }) => updateIncrementValue(value)} error={_.isEmpty(incrementValue)} />
				</Box>;
    } else if (consequence.condition.type === 'logic') {
      return <Box width='100%' gridTemplateColumns='1fr 1fr' className='consequence'>
					<GroupDropdown value={consequence?.condition || null} options={current_consequence_options} placeholder={intl.messages['conditions.consequence.add_consequence']} onChange={event => updateCondition(event.target.value)} variant='outlined' />

					<Dropdown fullWidth variant='outlined' options={nextPagesOptions} value={incrementValue} onChange={({
          target: {
            value
          }
        }) => updateIncrementValue(value)} />
				</Box>;
    }
  }
  return <Box width='100%' display='flex' alignItems='center' mb={0.75}>
			{renderConsequenceSettings()}

			{canBeRemoved && <DeleteConsequenceButton variant='text' color='error' size='small' onClick={() => removeConsequence()}>
					<Delete />
				</DeleteConsequenceButton>}
		</Box>;
}
ConsequenceStatement.propTypes = {
  conq_index: PropTypes.number,
  consequence: PropTypes.object,
  consequenceOptions: PropTypes.array,
  nextPagesOptions: PropTypes.array,
  numericalVariables: PropTypes.array,
  textVariables: PropTypes.array,
  incrementList: PropTypes.array,
  handleUpdateConsequenceCondition: PropTypes.func,
  handleUpdateConsequenceVariable: PropTypes.func,
  handleUpdateConsequenceIncrement: PropTypes.func,
  handleUpdateIncrementValue: PropTypes.func,
  handleRemoveConsequence: PropTypes.func,
  canBeRemoved: PropTypes.bool
};
function initialLogics(logics) {
  if (_.isEmpty(logics)) {
    return [{
      _id: nanoid(),
      cond_header: 'if',
      conditions: DEFAULT_CONDITION,
      consequences: [{
        _id: nanoid()
      }]
    }];
  }
  return logics;
}
function getStatusBoolean(status) {
  if (_.isEmpty(status)) {
    return true;
  }
  return _.reduce(status, (sum, next) => sum && next);
}
function DisplayIncrementOption({
  option
}) {
  if (option?.name === 'inputNumber') {
    return <b>
				<FormattedMessage id={`conditions.increment.input_number`} />
			</b>;
  }
  return <>
			<b>{option.name}</b>
		</>;
}
DisplayIncrementOption.propTypes = {
  option: PropTypes.object
};
function DisplayLogicOption({
  option
}) {
  return <>
			<b>
				<FormattedMessage id={`common.consequence_ops.${option.value}`} />
			</b>
		</>;
}
DisplayLogicOption.propTypes = {
  option: PropTypes.object
};
function getNextPageName(page, pages) {
  if (page.next === 'next') {
    return <FormattedMessage id='conditions.consequence.redirect_otherwise.next' />;
  } else if (page.next === 'none') {
    return <FormattedMessage id='conditions.consequence.redirect_otherwise.none' />;
  } else {
    const nextPage = _.find(pages, ['_id', page.next]);
    if (nextPage) return <FormattedMessage id='conditions.consequence.redirect_otherwise.toPage' values={{
      pageName: nextPage.name
    }} />;else return '';
  }
}