import { useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

export default function useSlider({ initialValue, onUpdate, debounceDelay, digitsCount = 0 }) {
	const [value, setValue] = useState(initialValue)
	const debouncedUpdate = useDebouncedCallback(onUpdate, debounceDelay)

	const handleSliderChange = useCallback(
		(event, value) => {
			setValue(parseFloat(value.toFixed(digitsCount)))
		},
		[digitsCount]
	)

	const handleChangeCommitted = useCallback(
		(event, value) => {
			const newValue = parseFloat(value.toFixed(digitsCount))
			setValue(newValue)
			debouncedUpdate(newValue)
		},
		[debouncedUpdate, digitsCount]
	)

	// Handle Undo/Redo
	useEffect(() => {
		setValue(initialValue)
	}, [initialValue])

	return {
		value,
		setValue,
		handleChangeCommitted,
		handleSliderChange,
		debouncedUpdate,
	}
}
