import { SvgIcon, SvgIconProps } from '@mui/material';
import { snackeet_dark_grey } from 'styles/colors';

// Extend SvgIconProps for additional custom props if needed
export interface HotspotIconProps extends SvgIconProps {}
export default function HotspotIcon(props: HotspotIconProps) {
  return <SvgIcon {...props}>
			<svg width='1em' height='1em' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<circle cx={14} cy={14} r={14} fill={snackeet_dark_grey} />
				<path d='M13.345 20.79c-.41 0-.732-.128-.967-.382-.234-.254-.351-.6-.351-1.04 0-.45.112-1.054.337-1.816.234-.772.463-1.47.688-2.095.235-.625.352-1.04.352-1.245 0-.215-.069-.322-.205-.322-.342 0-.894.673-1.656 2.021l-.981-.571c1.436-2.422 2.754-3.633 3.955-3.633.82 0 1.23.518 1.23 1.553 0 .303-.097.786-.293 1.45L14.4 17.727a2.209 2.209 0 00-.161.777c0 .224.088.337.263.337.332 0 .943-.615 1.831-1.846l.616.571c-.528.977-1.158 1.758-1.89 2.344-.723.586-1.294.88-1.714.88zM17.52 7.385c0 .39-.132.747-.396 1.07-.263.321-.62.482-1.069.482-.45 0-.786-.131-1.01-.395-.225-.273-.338-.615-.338-1.025 0-.41.137-.772.41-1.084a1.379 1.379 0 011.085-.484c.439 0 .766.142.981.425.225.273.337.61.337 1.01z' fill='#fff' />
			</svg>
		</SvgIcon>;
}