import styled from '@styled';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { BaseInteraction } from '../styled';
function StoryResults({
  story_results
}) {
  const max_score = 0;
  const score = 0;
  return <StoryResultsContainer style={story_results.style}>
			<Score>{`${score}/${max_score}`}</Score>

			<ResultsTitle>{story_results.title}</ResultsTitle>
		</StoryResultsContainer>;
}
StoryResults.propTypes = {
  story_results: PropTypes.object
};
export default memo(StoryResults);
const StoryResultsContainer = styled(BaseInteraction)`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;
	width: 95%;
	padding: ${({
  theme
}) => theme.spacing(2, 1.5, 1.5)};
	background-color: ${({
  theme
}) => theme.palette.background.paper};
	margin: auto;
	scrollbar-gutter: stable;
`;
const Score = styled.div`
	color: ${({
  theme
}) => theme.palette.secondary.main};
	font-size: 32px;
	padding: ${({
  theme
}) => theme.spacing(1)};
`;
const ResultsTitle = styled.div`
	margin: ${({
  theme
}) => theme.spacing(1)};
	color: inherit;
	font-family: inherit;
	font-size: 24px;
`;