import { block as _block$ } from "million/react-server";
import React, { useContext } from 'react';
import SettingsContext from '../../context/SettingsContext';
import TenorContext from '../../context/TenorContext';
import { TenorImage } from '../../types/exposedTypes';
export interface ResultImageProps {
  image: TenorImage;
  searchTerm?: string;
}
const M$ResultImage = function ResultImage({
  image,
  searchTerm
}: ResultImageProps) {
  const settings = useContext(SettingsContext);
  const tenor = useContext(TenorContext);
  function onClick(): void {
    const func = settings.onGifClick;
    if (func) func(image);
    tenor.registerShare(image, searchTerm);
  }
  return <button type='button' className='gpr-btn gpr-result-image' onClick={onClick}>
			<img src={image.preview.url} height={image.preview.height} width={image.preview.width} loading='lazy' />
		</button>;
};
const ResultImage = M$ResultImage;
export default ResultImage;