import { ChevronLeft, ChevronRight, PlayArrowRounded } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { PREVIEW_WIDTH } from 'lib/constants';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { CarouselCard, CarouselContainer, CarouselDetailedCard, CarouselProductCard, CarouselRoundedCard, CarouselSlider, CarouselWrapper } from './styled';
export default function CarouselBase({
  carousel
}) {
  const {
    _id,
    items,
    item_type,
    outer_style,
    title,
    settings,
    inner_style
  } = carousel;
  const itemStyles = _.pick(inner_style, ['fontSize', 'backgroundColor', 'color', 'borderColor', 'borderStyle', 'borderRadius', 'borderWidth']);
  const doNotDisplayNavigation = item_type === 'product' ? items.length <= 1 : item_type === 'detailed' ? items.length * (parseFloat(inner_style?.width) + 16 + 16 * 2) + 32 <= PREVIEW_WIDTH : items.length * (parseFloat(inner_style?.width) + 16) <= PREVIEW_WIDTH;
  const navigationPos = getNavigationPos({
    width: inner_style?.width,
    item_type,
    shape: inner_style?.shape
  });
  function renderCarouselCard(card, index) {
    switch (item_type) {
      case 'card':
        return <CarouselCard key={card._id} $shape={inner_style?.shape} $overlayColor={inner_style?.overlayColor} $width={inner_style?.width} style={itemStyles}>
						<img src={card.image} alt={card.title} />

						<Box className='cover' display='flex' flexDirection='column' justifyContent='flex-end' p={1}>
							<span>{card.title}</span>
						</Box>

						{inner_style?.hasPlayButton && <Box className='play-container' display='flex' alignItems='center' justifyContent='center'>
								<PlayArrowRounded sx={{
              color: '#fff',
              fontSize: '4em'
            }} />
							</Box>}
					</CarouselCard>;
      case 'detailed':
        return <CarouselDetailedCard key={card._id} $shape={inner_style?.shape} $overlayColor={inner_style?.overlayColor} $width={inner_style?.width} style={itemStyles} $hasShadow={inner_style?.hasShadow} $shadowColor={inner_style?.shadowColor}>
						<div className='img-container'>
							<img src={card.image} alt={card.title} />
						</div>

						<div className='detailed-title'>
							<span>{card.title}</span>
						</div>

						<div className='detailed-subtitle'>{card.subtitle}</div>
					</CarouselDetailedCard>;
      case 'rounded':
        {
          const containerStyles = _.pick(inner_style, ['fontSize', 'backgroundColor', 'color']);
          const borderStyles = _.pick(inner_style, ['borderColor', 'borderStyle', 'borderWidth']);
          return <CarouselRoundedCard key={card._id} $shape={inner_style?.shape} $overlayColor={inner_style?.overlayColor} $width={inner_style?.width} style={containerStyles}>
						<div className='img-container' style={borderStyles}>
							<img src={card.image} alt={card.title} style={{
                borderWidth: inner_style?.inner_borderWidth,
                borderColor: inner_style?.inner_borderColor
              }} />

							{inner_style?.hasPlayButton && <Box className='play-container' display='flex' alignItems='center' justifyContent='center' style={{
                borderWidth: inner_style?.inner_borderWidth,
                borderColor: inner_style?.inner_borderColor
              }}>
									<PlayArrowRounded sx={{
                  color: '#fff',
                  fontSize: '4em'
                }} />
								</Box>}
						</div>

						<div className='detailed-title'>
							<span>{card.title}</span>
						</div>
					</CarouselRoundedCard>;
        }
      case 'product':
        return <CarouselProductCard key={card._id} $shape={inner_style?.shape} $overlayColor={inner_style?.overlayColor} $width={inner_style?.width} style={itemStyles}>
						<div className='img-container'>
							<img src={card.image} alt={card.title} />
						</div>

						<div className='detailed-title'>
							<span className='title'>{card.title}</span>
							<span className='description'>{card.subtitle}</span>
							{card?.btn_title && <div className='btn' style={{
              color: inner_style?.btn_color,
              backgroundColor: inner_style?.btn_backgroundColor
            }}>
									{card.btn_title}
								</div>}
						</div>
					</CarouselProductCard>;
      default:
        return null;
    }
  }
  return <CarouselContainer style={outer_style}>
			<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' px={1}>
				<Typography sx={{
        fontFamily: 'inherit',
        fontSize: '1em'
      }}>{title}</Typography>
			</Box>

			<Box width='100%' height='max-content' sx={{
      position: 'relative'
    }}>
				<CarouselSlider>
					<CarouselWrapper $isCenter={doNotDisplayNavigation} $isStretch={item_type === 'detailed'}>
						{items.map((card, index) => renderCarouselCard(card, index))}
					</CarouselWrapper>
				</CarouselSlider>

				{!doNotDisplayNavigation && <>
						<Box width='26px' height='26px' display='flex' justifyContent='center' alignItems='center' sx={{
          border: '1px solid #000',
          borderRadius: '50%',
          position: 'absolute',
          top: `${navigationPos}px`,
          left: '4px',
          backgroundColor: 'white',
          overflow: 'hidden',
          zIndex: '15'
        }}>
							<IconButton size='small' sx={{
            color: '#000'
          }}>
								<ChevronLeft fontSize='medium' />
							</IconButton>
						</Box>
						<Box width='26px' height='26px' display='flex' justifyContent='center' alignItems='center' sx={{
          border: '1px solid #000',
          borderRadius: '50%',
          position: 'absolute',
          top: `${navigationPos}px`,
          right: '4px',
          backgroundColor: 'white',
          overflow: 'hidden',
          zIndex: '15'
        }}>
							<IconButton size='small' sx={{
            color: '#000'
          }}>
								<ChevronRight fontSize='medium' />
							</IconButton>
						</Box>
					</>}
			</Box>
		</CarouselContainer>;
}
CarouselBase.propTypes = {
  carousel: PropTypes.object
};
function getNavigationPos({
  width,
  item_type,
  shape
}) {
  if (item_type === 'product') {
    return (80 + 16 + 2) / 2 - 4;
  } else if (item_type === 'rounded') {
    return (parseFloat(width) + 16) / 2 - 12;
  } else if (item_type === 'card') {
    if (shape === 'square') {
      return (parseFloat(width) + 16) / 2 - 12;
    }
    return (parseFloat(width) * 6 / 4 + 16) / 2 - 12;
  } else if (item_type === 'detailed') {
    if (shape === 'square') {
      return (parseFloat(width) + 68) / 2;
    }
    return (parseFloat(width) * 6 / 5 + 68) / 2;
  }
}