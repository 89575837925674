import { Button, TextField } from '@mui/material';
import styled from '@styled';
export const SnackeetWheel = styled.div`
	display: flex;
	flex-direction: column;

	/* Target wheel with styled component hash */
	.sc-gsTCUz {
		max-width: 320px;
		max-height: 320px;
	}
`;
export const SpinWheelButton = styled(Button)`
	color: ${({
  $textColor
}) => $textColor} !important;
	font-size: ${({
  $fontSize
}) => $fontSize}px !important;
	background-color: ${({
  $backgroundColor
}) => $backgroundColor} !important;
	border-width: ${({
  $borderWidth
}) => $borderWidth} !important;
	border-color: ${({
  $borderColor
}) => $borderColor} !important;
	border-radius: ${({
  $borderRadius
}) => $borderRadius} !important;
	border-style: solid !important;
	width: fit-content !important;
	padding: 10px 15px !important;
	margin: auto !important;
	will-change: transform;
	transition: transform 0.5s ease-in-out;

	&:hover {
		transform: scale(1.05);
	}
`;
export const WheelModal = styled.div`
	position: absolute;
	bottom: 24px;
	left: 50%;
	width: 330px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 15px;
	border-radius: 10px;
	color: ${({
  $color
}) => $color};
	background-color: ${({
  $backgroundColor
}) => $backgroundColor};
	z-index: 12;
	box-shadow: ${({
  theme
}) => theme.shadows[2]};
	transform: translate(-50%, 0);

	h1 {
		text-align: center;
		font-size: 28px;
		margin: 10px 0;
	}

	h3 {
		font-size: 16px;
	}

	.win-img {
		height: 150px;
	}

	.lose-img {
		height: 130px;
		margin-bottom: 20px;
	}

	.modal-description {
		overflow: auto;
		max-height: 200px;
		word-break: break-word;
		white-space: break-spaces;
		margin-bottom: 10px;
		text-align: center;
		padding: 10px 0;
	}
`;
export const FormInputModal = styled(TextField)`
	width: 100%;
	font-size: 18px;
	border: 1px solid !important;
	border-radius: 5px !important;
	padding: 10px !important;
	margin: 10px 0 !important;
	box-shadow: ${({
  theme
}) => theme.shadows[1]};
`;