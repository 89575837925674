export { default as Svg1Number } from './1Number'
export { default as Svg2Number } from './2Number'
export { default as Svg3Number } from './3Number'
export { default as Svg4Number } from './4Number'
export { default as Svg5Number } from './5Number'
export { default as Svg6Number } from './6Number'
export { default as Svg7Number } from './7Number'
export { default as Svg8Number } from './8Number'
export { default as Svg9Number } from './9Number'
export { default as Svg10Number } from './10Number'
export { default as AmpLinkIcon } from './AmpLinkIcon'
export { default as AmpSwipeUp } from './AmpSwipeUp'
export { default as Asterisk } from './Asterisk'
export { default as Backward } from './Backward'
export { default as CheckToSlot } from './CheckToSlot'
export { default as CodeBranch } from './CodeBranch'
export { default as Crown } from './Crown'
export { default as FlaskIcon } from './FlaskIcon'
export { default as Forward } from './Forward'
export { default as HotspotIcon } from './HotspotIcon'
export { default as InstagramCommentIcon } from './InstagramCommentIcon'
export { default as InstagramLikeIcon } from './InstagramLikeIcon'
export { default as InstagramReelsIcon } from './InstagramReelsIcon'
export { default as LinkIcon } from './LinkIcon'
export { default as Share } from './Share'
export { default as Text } from './Text'
export { default as ToBack } from './ToBack'
export { default as ToFront } from './ToFront'
export { default as Trophy } from './Trophy'
export { default as Variables } from './Variables'
