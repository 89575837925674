import { CursorArrowRaysIcon } from '@heroicons/react/24/outline';
import { SmartButton, ViewCarousel } from '@mui/icons-material';
import { Box, SvgIcon, Typography } from '@mui/material';
import styled from '@styled';
import * as entities from 'entities';
import { useStoryDispatch, useStoryState } from 'lib/editorStore';
import useStoryPagesOptions from 'lib/hooks/useStoryPagesOptions';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Dropdown from './Dropdown';
export default function DisplayRedirections({
  page
}) {
  const currentPage = useStoryState(s => s.page);
  const storyType = useStoryState(s => s.draftStory?.type);
  const storyPages = useStoryState(s => s.draftStory.pages);
  const dispatch = useStoryDispatch();
  const nextPagesOptions = useStoryPagesOptions({
    hasNoneRedirection: true,
    hasGoBack: true
  });
  function updateQuizCorrectRedirect(page_id) {
    dispatch({
      type: 'SET_QUIZ_ANSWER_CORRECT_REDIRECT',
      data: page_id
    });
  }
  function updateQuizIncorrectRedirect(page_id) {
    dispatch({
      type: 'SET_QUIZ_ANSWER_INCORRECT_REDIRECT',
      data: page_id
    });
  }
  function updateQuizPartialRedirect(page_id) {
    dispatch({
      type: 'SET_QUIZ_ANSWER_PARTIAL_REDIRECT',
      data: page_id
    });
  }
  function updateMultipleSelectionRedirect(page_id) {
    dispatch({
      type: 'SET_MULTIPLE_SELECTION_BUTTON',
      data: page_id
    });
  }
  function updateButtonRedirection(answer_id, page_id) {
    dispatch({
      type: 'UPDATE_ANSWER',
      data: {
        answer_id,
        path: 'payload.next_page',
        value: page_id
      }
    });
  }
  function updateCTARedirection(block_id, page_id) {
    dispatch({
      type: 'UPDATE_BLOCK',
      data: [{
        _id: block_id,
        path: 'cta_options.link',
        value: page_id
      }]
    });
  }
  function updateCarouselItemRedirection({
    carouselId,
    itemId,
    value
  }) {
    dispatch({
      type: 'UPDATE_CAROUSEL_ITEM',
      data: {
        _id: carouselId,
        itemId,
        path: 'click_options.link',
        value
      }
    });
  }
  function displayPage(page_id) {
    if (page_id === 'next') {
      return <Typography>
					<em>
						<FormattedMessage id='edition_menu.button.link.redirect.next' />
					</em>
				</Typography>;
    }
    if (page_id === 'none') {
      return <Typography>
					<em>
						<FormattedMessage id='edition_menu.cta_options.redirection_type.none' />
					</em>
				</Typography>;
    }
    const pageIdx = _.findIndex(storyPages, ['_id', page_id]);
    if (pageIdx > -1) {
      const redirectPage = storyPages[pageIdx];
      return <Typography>
					<em>
						Page{pageIdx + 1}. {redirectPage.name}
					</em>
				</Typography>;
    }
    return <Typography color='error'>
				<FormattedMessage id='condition.field_page.undefined' />
			</Typography>;
  }
  function renderBlocksRedirection() {
    const blocksCta = _.filter(page?.blocks, block => block?.cta_options?.redirectionType === 'internal');
    if (!_.isEmpty(blocksCta)) {
      return <Box>
					<Typography fontWeight='bold' variant='h3' component='div' display='flex' alignItems='center' gap={0.5} gutterBottom>
						<Box height='24px' width='24px' display='flex' alignItems='center' justifyContent='center' sx={{
            backgroundColor: '#004AAD',
            color: '#fff',
            borderRadius: '4px'
          }}>
							<SvgIcon fontSize='small'>
								<CursorArrowRaysIcon />
							</SvgIcon>
						</Box>
						<FormattedMessage id='conditions.cta_redirect.title' />
					</Typography>
					{_.map(blocksCta, block => <Box key={block._id} display='flex' alignItems='center' gap={1.5} mb={0.5} ml={1}>
							<Typography>
								<FormattedMessage id='conditions.cta_redirect.onClick' />
							</Typography>
							<Typography sx={{
            width: '170px'
          }} fontWeight='bold' noWrap>
								{getBlockName(block)}
							</Typography>
							<Typography color='info.main' fontWeight='bold'>
								<FormattedMessage id='common.consequence_ops.redirect' />
							</Typography>
							{page._id === currentPage._id && block?.cta_options?.link !== 'none' ? <Dropdown fullWidth={false} style={{
            width: '250px'
          }} onChange={({
            target: {
              value
            }
          }) => updateCTARedirection(block._id, value)} variant='outlined' placeholder={<FormattedMessage id='edition_menu.navigation.your_pages' />} options={nextPagesOptions} value={block?.cta_options?.link} /> : displayPage(block?.cta_options?.link)}
						</Box>)}
				</Box>;
    }
  }
  function renderCarouselsRedirection() {
    const carouselRedirections = _.filter(page?.carousels, carousel => _.some(carousel.items, item => item?.click_options?.type === 'internal'));
    if (!_.isEmpty(carouselRedirections)) {
      return <Box>
					<Typography fontWeight='bold' variant='h3' component='div' display='flex' alignItems='center' gap={0.5} gutterBottom>
						<Box height='24px' width='24px' display='flex' alignItems='center' justifyContent='center' sx={{
            backgroundColor: '#004AAD',
            color: '#fff',
            borderRadius: '4px'
          }}>
							<SvgIcon fontSize='small'>
								<ViewCarousel />
							</SvgIcon>
						</Box>
						<FormattedMessage id='conditions.carousels.title' />
					</Typography>

					{_.map(carouselRedirections, carousel => <Box key={carousel._id}>
							<Typography>{carousel.title}</Typography>
							{_.map(_.filter(carousel.items, item => item?.click_options?.type === 'internal'), item => <Box key={item._id} display='flex' alignItems='center' gap={1.5} mb={0.5} ml={1}>
										<Typography>
											<FormattedMessage id='conditions.cta_redirect.onClick' />
										</Typography>
										<Typography sx={{
              width: '170px'
            }} fontWeight='bold' noWrap>
											{item?.title}
										</Typography>
										<Typography color='info.main' fontWeight='bold'>
											<FormattedMessage id='common.consequence_ops.redirect' />
										</Typography>
										{page._id === currentPage._id && item?.click_options?.link !== 'none' ? <Dropdown fullWidth={false} style={{
              width: '250px'
            }} onChange={({
              target: {
                value
              }
            }) => updateCarouselItemRedirection({
              carouselId: carousel._id,
              itemId: item._id,
              value
            })} variant='outlined' placeholder={<FormattedMessage id='edition_menu.navigation.your_pages' />} options={nextPagesOptions} value={item?.click_options?.link} /> : displayPage(item?.click_options?.link)}
									</Box>)}
						</Box>)}
				</Box>;
    }
  }
  function renderButtonsRedirection() {
    if (page?.type !== 'multiple_choice' || page?.answers_properties?.type !== 'answer') return null;
    if (page?.answers_properties?.multiple_selection) {
      if (storyType === 'classic' && page?.answers_properties?.count_in_results) {
        return <Box>
						<Typography fontWeight='bold' variant='h3' component='div' display='flex' alignItems='center' gap={0.5} gutterBottom>
							<Box height='24px' width='24px' display='flex' alignItems='center' justifyContent='center' sx={{
              backgroundColor: '#FF5757',
              color: '#fff',
              borderRadius: '4px'
            }}>
								<SvgIcon fontSize='small'>
									<SmartButton />
								</SvgIcon>
							</Box>
							<FormattedMessage id='conditions.priority_logic.title' />
						</Typography>
						<Box display='flex' alignItems='center' gap={0.5} mb={1}>
							<Typography>
								<FormattedMessage id='edition_menu.answer.quiz_redirect_start' />
							</Typography>
							<Typography color='success.main' fontWeight='bold'>
								<FormattedMessage id='edition_menu.answer.quiz_redirect_correct' />
							</Typography>
							<Typography color='info.main' fontWeight='bold'>
								<FormattedMessage id='edition_menu.answer.quiz_redirect_end' />
							</Typography>
							{page._id === currentPage._id ? <Dropdown fullWidth={false} onChange={({
              target: {
                value
              }
            }) => updateQuizCorrectRedirect(value)} variant='outlined' placeholder={<FormattedMessage id='edition_menu.navigation.your_pages' />} options={nextPagesOptions} value={page?.answers_properties?.correct_redirect} /> : displayPage(page?.answers_properties?.correct_redirect)}
						</Box>
						<Box display='flex' alignItems='center' gap={1.5} mb={1}>
							<Typography>
								<FormattedMessage id='edition_menu.answer.quiz_redirect_start' />
							</Typography>
							<Typography color='error' fontWeight='bold'>
								<FormattedMessage id='edition_menu.answer.quiz_redirect_incorrect' />
							</Typography>
							<Typography color='info.main' fontWeight='bold'>
								<FormattedMessage id='edition_menu.answer.quiz_redirect_end' />
							</Typography>
							{page._id === currentPage._id ? <Dropdown style={{
              width: '250px'
            }} fullWidth={false} onChange={({
              target: {
                value
              }
            }) => updateQuizIncorrectRedirect(value)} variant='outlined' placeholder={<FormattedMessage id='edition_menu.navigation.your_pages' />} options={nextPagesOptions} value={page?.answers_properties?.incorrect_redirect} /> : displayPage(page?.answers_properties?.incorrect_redirect)}
						</Box>
						{page?.answers_properties?.partial_correct && <Box display='flex' alignItems='center' gap={0.5}>
								<Typography>
									<FormattedMessage id='edition_menu.answer.quiz_redirect_start' />
								</Typography>
								<Typography color='warning.main' fontWeight='bold'>
									<FormattedMessage id='edition_menu.answer.quiz_redirect_partial' />
								</Typography>
								<Typography color='info.main' fontWeight='bold'>
									<FormattedMessage id='edition_menu.answer.quiz_redirect_end' />
								</Typography>
								{page._id === currentPage._id ? <Dropdown fullWidth={false} onChange={({
              target: {
                value
              }
            }) => updateQuizPartialRedirect(value)} variant='outlined' placeholder={<FormattedMessage id='edition_menu.navigation.your_pages' />} options={nextPagesOptions} value={page?.answers_properties?.partial_redirect} /> : displayPage(page?.answers_properties?.partial_redirect)}
							</Box>}
					</Box>;
      }
      return <Box>
					<Typography fontWeight='bold' variant='h3' component='div' display='flex' alignItems='center' gap={0.5} gutterBottom>
						<Box height='24px' width='24px' display='flex' alignItems='center' justifyContent='center' sx={{
            backgroundColor: '#FF5757',
            color: '#fff',
            borderRadius: '4px'
          }}>
							<SvgIcon fontSize='small'>
								<SmartButton />
							</SvgIcon>
						</Box>
						<FormattedMessage id='conditions.priority_logic.title' />
					</Typography>

					<Box display='flex' alignItems='center' gap={0.5}>
						<Typography>
							<FormattedMessage id='conditions.priority_logic.page_answered' />
						</Typography>
						<Typography color='info.main' fontWeight='bold'>
							<FormattedMessage id='common.consequence_ops.redirect' />
						</Typography>
						{page._id === currentPage._id ? <Dropdown fullWidth={false} onChange={({
            target: {
              value
            }
          }) => updateMultipleSelectionRedirect(value)} variant='outlined' placeholder={<FormattedMessage id='edition_menu.navigation.your_pages' />} options={nextPagesOptions} value={page?.answers[0]?.payload?.next_page} /> : displayPage(page?.answers[0]?.payload?.next_page)}
					</Box>
				</Box>;
    } else {
      return <Box>
					<Typography fontWeight='bold' variant='h3' component='div' display='flex' alignItems='center' gap={0.5} gutterBottom>
						<Box height='24px' width='24px' display='flex' alignItems='center' justifyContent='center' sx={{
            backgroundColor: '#FF5757',
            color: '#fff',
            borderRadius: '4px'
          }}>
							<SvgIcon fontSize='small'>
								<SmartButton />
							</SvgIcon>
						</Box>
						<FormattedMessage id='conditions.priority_logic.title' />
					</Typography>
					{_.map(page?.answers, answer => <Box key={answer._id} display='flex' alignItems='center' gap={1.5} mb={0.5} ml={1}>
							<Typography style={{
            width: '230px'
          }} noWrap>
								<FormattedMessage id='conditions.priority_logic.answer' values={{
              answerTitle: refactorTitle(answer.title)
            }} />
							</Typography>
							<Typography color='info.main' fontWeight='bold'>
								<FormattedMessage id='common.consequence_ops.redirect' />
							</Typography>
							{page._id === currentPage._id ? <Dropdown fullWidth={false} style={{
            width: '250px'
          }} onChange={({
            target: {
              value
            }
          }) => updateButtonRedirection(answer._id, value)} variant='outlined' placeholder={<FormattedMessage id='edition_menu.navigation.your_pages' />} options={nextPagesOptions} value={answer?.payload?.next_page} /> : displayPage(answer?.payload?.next_page)}
						</Box>)}
				</Box>;
    }
  }
  return <Wrapper>
			{renderButtonsRedirection()}
			{renderBlocksRedirection()}
			{renderCarouselsRedirection()}
		</Wrapper>;
}
DisplayRedirections.propTypes = {
  page: PropTypes.object
};
const Wrapper = styled.div`
	display: grid;
	grid-gap: 10px;

	.MuiSelect-outlined {
		padding: ${({
  theme
}) => theme.spacing(0.5, 3, 0.5, 1)};
	}
`;
function refactorTitle(value = '') {
  const limit = 25;
  if (!value.length || value.length < limit) return `${value}`;
  return `${value?.substring(0, limit)}...`;
}
function convertTextFromHtml(value) {
  return entities.decodeXML(value.replace(/(<([^>]+)>)/gi, ''));
}
function getBlockName(block) {
  if (block?.name) return block.name;
  if (block?.type === 'text') {
    return convertTextFromHtml(block?.value);
  }
  return <FormattedMessage id={`editor.configuration.block_type.${block?.subType || block.type}`} />;
}