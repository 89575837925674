import styled from '@styled';
import SearchBar from './atoms/SearchBar';
export const ShapeSectionHeader = styled.div`
	background-color: white;
	padding-top: ${({
  theme
}) => theme.spacing(1)};
	display: grid;
	place-items: center;
	grid-template-columns: 50px 1fr 50px;
	grid-template-areas: 'icon title .';
	min-height: 40px;

	span {
		cursor: pointer;
	}

	& > .MuiIconButton-root {
		grid-area: icon;
	}

	& > .MuiTypography-root {
		grid-area: title;
	}
`;
export const ShapeGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: 60px;
	place-items: center;
	gap: 8px;
	overflow: auto;
	padding: ${({
  theme
}) => theme.spacing(0, 1)};
	transition: transform 300ms;
	will-change: transform;

	div {
		cursor: pointer;
	}
	svg {
		height: 30px;
		width: 30px;
		fill: #3e3e3e;

		g {
			fill: #3e3e3e;
		}
	}
	svg:hover {
		transform: scale(1.07);
	}
`;
export const ShapeSearch = styled(SearchBar)`
	max-width: unset;
	width: 95%;
	z-index: 2;
	margin: 16px auto !important;
`;