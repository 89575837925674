import { css } from '@emotion/react';
import { FormControl } from '@mui/material';
import styled from '@styled';
import { snackeet_dark_blue, snackeet_dark_grey } from 'styles/colors';
const withMaxWidth = css`
	max-width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
`;
export const DropdownOption = styled.div`
	b {
		color: ${snackeet_dark_blue};
	}
	i {
		margin-left: 8px;
		font-size: inherit;
	}
	em {
		color: ${snackeet_dark_grey};
	}
`;
export const DropdownContainer = styled(FormControl)`
	.MuiInputBase-input,
	.MuiInputBase-root,
	.MuiMenuItem-root {
		font-size: 14px;
		font-weight: bold;
	}

	.Mui-error {
		border: 1px solid ${({
  theme
}) => theme.palette.error.main};
	}

	${DropdownOption} {
		${({
  $maxWidth
}) => $maxWidth ? withMaxWidth : ''}
	}
`;