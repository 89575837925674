import { css } from '@emotion/react';
import { Autocomplete, Divider, TextField } from '@mui/material';
import styled from '@styled';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { snackeet_blue } from 'styles/colors';
const MaterialFieldSelector = ({
  className,
  handleOnChange,
  options,
  value
}) => {
  const intl = useIntl();
  const currentValue = _.find(options, ['name', value]) || null;
  return <CustomAutoComplete $isError={_.isNull(currentValue)} className={className} value={currentValue} options={options} groupBy={option => option.inputType} getOptionLabel={option => option.label} renderOption={(props, option) => <div {...props}>{option.label}</div>} renderGroup={option => {
    return <div key={option.key}>
						{option.key > 0 && <Divider style={{
        margin: '8px 0 16px'
      }} />}
						<GroupTitle>
							<b>{intl.messages[`conditions.field_group.${option.group}`]}</b>
						</GroupTitle>
						{option.children}
					</div>;
  }} onChange={(evt, newValue) => handleOnChange(newValue.name)} disableClearable renderInput={params => <TextField {...params} variant='outlined' error={_.isNull(currentValue)} />} />;
};
MaterialFieldSelector.propTypes = {
  className: PropTypes.string,
  handleOnChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.string
};
MaterialFieldSelector.displayName = 'MaterialFieldSelector';
export default MaterialFieldSelector;

// Style components
const errorDisplay = ({
  $isError,
  theme
}) => $isError && css`
		border: 1px solid ${theme.palette.error.main};
	`;
const CustomAutoComplete = styled(Autocomplete)`
	.MuiOutlinedInput-root {
		${errorDisplay}
	}
`;
const GroupTitle = styled.div`
	margin: ${({
  theme
}) => theme.spacing(1)};
	text-transform: uppercase;
	color: ${snackeet_blue};
`;