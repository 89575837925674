import * as React from 'react'

function SvgToBack(props) {
	return (
		<svg width='1em' height='1em' viewBox='0 0 41 41' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M17.03 24.028l-8.36-3.57c-.809-.346-.81-1.491-.003-1.839l11.449-4.923a1 1 0 01.8.005l11.063 4.9c.79.35.795 1.47.007 1.826l-7.957 3.593'
				stroke='#484747'
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
			<path
				d='M11.52 17.035L7.856 15.04a1 1 0 01.094-1.802l12.164-5.05a1 1 0 01.789.009l11.264 4.99a1 1 0 01.075 1.791l-3.72 2.035M20.523 19.024l.016 13m-3.003-2.497l3.003 2.497m0 0l2.997-2.504'
				stroke='#484747'
				strokeWidth={2}
				strokeMiterlimit={10}
			/>
		</svg>
	)
}

export default SvgToBack
