import { Favorite, FavoriteBorder, Lens, LensOutlined } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, IconButton, Rating as MuiRating, TextField, Typography } from '@mui/material';
import styled from '@styled';
import { CustomIcon } from 'components/icons';
import { RatingList } from 'lib/constants';
import _ from 'lodash';
import PropTypes from 'prop-types';
function NumberIconContainer(props) {
  const {
    value,
    ...delegatedProps
  } = props;
  return <span {...delegatedProps}>
			<CustomIcon name={`Svg${value}Number`} fontSize={'30px'} height={30} width={30} padding={0} margin={0.1} />
		</span>;
}
NumberIconContainer.propTypes = {
  value: PropTypes.number.isRequired
};
export default function RatingFeedbackPreview({
  form,
  category,
  max,
  style
}) {
  const fontFamily = style?.fontFamily || 'Poppins';
  const {
    title,
    message_placeholder,
    button_title,
    button_style
  } = form;
  const ratingModel = _.find(RatingList, {
    category
  });
  const fontSize = getRatingSize(max);
  function renderMuiRating(type) {
    switch (type) {
      case 'stars':
        return <MuiRating size='large' sx={{
          fontSize
        }} defaultValue={max - 2} max={max} readOnly />;
      case 'number':
        return <MuiRating size='large' sx={{
          fontSize
        }} defaultValue={max} IconContainerComponent={NumberIconContainer} max={max} readOnly />;
      case 'heart':
        return <RedMuiRating size='large' sx={{
          fontSize
        }} defaultValue={max} max={max} readOnly icon={<Favorite fontSize='inherit' />} emptyIcon={<FavoriteBorder fontSize='inherit' />} />;
      case 'lens':
        return <MuiRating size='large' sx={{
          fontSize
        }} defaultValue={max} max={max} readOnly icon={<Lens fontSize='inherit' />} emptyIcon={<LensOutlined fontSize='inherit' />} />;
      default:
        return null;
    }
  }
  return (
    // <FormWrapper $fontFamily={style.fontFamily}>
    <FormWrapper $fontFamily={fontFamily}>
			<div className='modal-header'>
				<IconButton color='inherit' size='large'>
					<ExpandMoreIcon color='inherit' />
				</IconButton>
			</div>
			<div className='form-wrapper'>
				{/* Feedback title */}
				<Typography variant='h2' textAlign='center' sx={{
          fontSize: '20px',
          fontFamily: 'inherit',
          mb: 2
        }}>
					{title}
				</Typography>
				{/* Rating */}
				<Box width='100%' display='flex' justifyContent='center' mb={1}>
					{category.startsWith('classic') ? renderMuiRating(category.split('_')[1]) : <RatingContainer>
							{ratingModel.imgs.map((img, idx) => <div key={idx} style={{
              backgroundImage: img.url,
              width: ratingModel.size,
              height: ratingModel.size
            }} />)}
						</RatingContainer>}
				</Box>

				{/* Feedback input */}
				<TextField fullWidth variant='outlined' multiline minRows={2} placeholder={message_placeholder} sx={{
          fontFamily: 'inherit'
        }} />

				{/* Submit button */}
				<Button fullWidth variant='contained' style={{
          marginTop: 16,
          fontFamily: 'inherit',
          ...button_style
        }}>
					{button_title}
				</Button>
			</div>
		</FormWrapper>
  );
}
RatingFeedbackPreview.propTypes = {
  content: PropTypes.array,
  cta_text: PropTypes.string,
  styles: PropTypes.object,
  theme: PropTypes.string
};

// Styled components
const FormWrapper = styled.div`
	width: 370px;
	max-height: 100%;
	border-radius: 16px;
	box-shadow: -2px -4px 32px -2px rgba(0, 0, 0, 0.38);
	background-color: rgb(250, 250, 250);
	font-family: ${({
  $fontFamily
}) => $fontFamily || 'Poppins'};
	overflow: auto;

	&::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}

	.modal-header {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.form-wrapper {
		width: 100%;
		background-color: rgb(250, 250, 250);
		padding: 8px 16px 16px;
		border-bottom-right-radius: 16px;
		border-bottom-left-radius: 16px;
		color: rgb(32, 33, 37);
	}
`;

/* ---- RATING ---- */

export const RatingInteractionContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${({
  theme
}) => theme.palette.background.paper};
	border-radius: ${({
  theme
}) => theme.shape.borderRadius}px;
	width: 100%;
	min-height: 70px;
	padding: ${({
  theme
}) => theme.spacing(2)};

	& > .MuiRating-root {
		margin: ${({
  theme
}) => theme.spacing(1)};
	}
`;
export const RatingContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	width: 100%;
	padding: ${({
  theme
}) => theme.spacing(1, 0)};
	div {
		transition: transform 150ms ease-in-out;
		width: 70px;
		height: 70px;
		border-radius: 50%;
		background-size: cover;
		cursor: pointer;
	}
	div:hover {
		transform: scale(1.1);
	}
`;
export const RedMuiRating = styled(MuiRating)`
	color: red;
`;
function getRatingSize(max) {
  if (max <= 6) {
    return '50px';
  } else if (max === 7) {
    return '45px';
  } else if (max === 8) {
    return '40px';
  } else if (max === 9) {
    return '35px';
  } else if (max === 10) {
    return '30px';
  }
}