import { ANSWERS_STATES } from 'lib/constants'
import { DraftStory } from 'lib/models'
import { getUrlParameters, isStoryPublishable } from 'lib/utils'
import _ from 'lodash'
import { elementsToCopyStorage } from 'services/LocalStorageSvc'

export const EMPTY_ELEMENT = {}
export const INITIAL_TEXT_EDITION_STATE = {
	isTextEditing: false,
	blockId: undefined,
}

export function getInitialState({ snackeet, published }) {
	const initialDraftStory = new DraftStory(snackeet)
	const initialPublished = new DraftStory(published)
	const initialElementsToCopy = elementsToCopyStorage.get() || []

	const { page: initialPageId, tab: initialTab = 'config' } = getUrlParameters(['page', 'tab'])

	// Load page from url if exists, load first page otherwise
	const initialPage = _.find(initialDraftStory.pages, { _id: initialPageId }) || _.head(initialDraftStory.pages)

	return {
		view: initialTab,
		draftStory: initialDraftStory,
		published: initialPublished,

		page: initialPage,
		selectedElement: EMPTY_ELEMENT,
		currentBlockEditorTab: '', // currentBlockEditor tab values: styles, animation, cta, editor
		currentTextBlockExpand: 'text_styles', // For text appearance only
		currentElementHover: '', // To find the hovering block in layers and preview
		currentCarouselItem: '', // To find the current carousel item
		currentButtonListButton: '', // To find the current buttonList button

		// Drawer & background & GDPR
		isBackgroundActive: true,
		isLayersSectionActive: false,
		isDisplaySubtitleDrawer: false,
		isSubtitleDrawerReady: false,
		isDisplayPageAnimationDrawer: false,
		isDiplayShareDrawer: false,
		isDisplayAdvancedLogic: false,
		isDisplaySinglePageOverlay: false,
		isConfirmButtonSettingsDisplayed: false,
		isOnTextEffect: false,
		textEditionState: INITIAL_TEXT_EDITION_STATE,

		isDisplayElementsDrawer: false,

		elementsToCopy: initialElementsToCopy,
		answersState: ANSWERS_STATES[0],
		backgroundStates: {},
		wheelComponentState: 'wheel',
		answersValuesCache: {},
		canUndo: false,
		canRedo: false,
		isDirty: false,
		isStale: false,
		isMoving: true, // Boolean to signal a moveable transformation is taking place
		autoSaveStatus: 'saved',
		canPublish: isStoryPublishable(initialDraftStory, initialPublished),
		contextMenu: {},
		copiedTextStyles: {},

		storyFeedback: {
			storyErrors: [],
			storyRecommendations: [],
			selectedFeedback: {},
		},
	}
}
