// Taken from https://www.npmjs.com/package/mini-virtual-list
import { useLayoutEffect, useState } from 'react'

const defaultSize = { width: 0, height: 0 }

const useSize = <T extends HTMLElement = HTMLElement>(
	node: HTMLElement,
	deps: any[] = []
): { width: number; height: number } => {
	const [size, setSize] = useState<{ width: number; height: number }>(defaultSize)

	useLayoutEffect(() => {
		if (node) {
			const handleResize = () => {
				const computedStyle = getComputedStyle(node)
				const float = parseFloat
				const width = node.clientWidth - float(computedStyle.paddingTop) - float(computedStyle.paddingBottom)
				const height = node.clientHeight - float(computedStyle.paddingLeft) - float(computedStyle.paddingRight)
				setSize({ height, width })
			}

			handleResize()
			window.addEventListener('resize', handleResize)
			window.addEventListener('orientationchange', handleResize)
			return () => {
				window.removeEventListener('resize', handleResize)
				window.removeEventListener('orientationchange', handleResize)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps.concat(node))

	return size
}

export default useSize
