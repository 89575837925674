import * as React from 'react'

function Svg1Number(props) {
	return (
		<svg width='1em' height='1em' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path d='M2.451 5h-.545V1.388l-1.093.401v-.492L2.366.714h.085V5z' fill='#000' />
		</svg>
	)
}

export default Svg1Number
