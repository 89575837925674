import axios from 'axios'
import { API_URL } from 'lib/constants'
import { CustomerError } from 'lib/Errors'
import * as localStorageSvc from 'services/LocalStorageSvc'

const StoryFolderSvc = {
	getAll,
	create,
	update,
	del,
}

export default StoryFolderSvc

async function getAll({ project, organization_id }) {
	try {
		const { data } = await axios.get(`${API_URL}/storyFolders`, {
			params: { project, organization_id },
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		if (!data.success) {
			throw new CustomerError('Could not retrieve story folders')
		}

		return data.folders
	} catch (err) {
		console.log('get all Story Folders', err)
		return []
	}
}

async function create({ project, organizationId, name }) {
	try {
		const { data } = await axios.post(
			`${API_URL}/storyFolders`,
			{
				name,
				project,
			},
			{
				params: { organization_id: organizationId },
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)
		if (!data.success) {
			throw new CustomerError('Could not retrieve story folders')
		}

		return {
			success: true,
			folders: data.folders,
		}
	} catch (err) {
		console.log('create Story Folder', err)
		return {
			success: false,
		}
	}
}

async function update({ folderId, name, project }) {
	await axios.patch(
		`${API_URL}/storyFolders/${folderId}`,
		{
			name,
		},
		{
			params: {
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		}
	)
}

async function del({ folderId, project }) {
	const { data } = await axios.delete(`${API_URL}/storyFolders/${folderId}`, {
		params: {
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})

	return data.success
}
