// Required: page, projects list, organization_id, customer role in organization
// Via role of client in organization -> 2 cases
// Case admin or super_admin: Display projects list to choose accessible projects
// Case agent: Only current project
// Then confirmation step and loading

import { Alert, Box, Button, Checkbox, CircularProgress, DialogActions, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ModalContent, ProjectsAccessWrapper } from 'components/styled/Modals';
import { useStoryState } from 'lib/editorStore';
import { sleep } from 'lib/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCustomerRights } from 'redux/customer/selectors';
import { getCurrentOrganizationId } from 'redux/organization/selectors';
import OrganizationsSvc from 'services/OrganizationsSvc';
import TemplatesSvc from 'services/TemplatesSvc';
import useAsyncEffect from 'use-async-effect';
import { useImmer } from 'use-immer';
import { ModalWrapper } from '../ModalWrapper';
const SaveAsPageTemplateModal = forwardRef(function SaveAsPageTemplateModal({
  trigger,
  onOpen
}, ref) {
  const intl = useIntl();
  const organizationId = useSelector(getCurrentOrganizationId);
  const currentCustomerRights = useSelector(getCustomerRights);
  const queryClient = useQueryClient();
  const project = useStoryState(s => s.draftStory.project);
  const page = useStoryState(s => s.page);
  const [states, setStates] = useImmer({
    accessProjects: [project],
    name: page?.name,
    organizationProjects: [],
    is_saving: false
  });
  useEffect(() => {
    setStates(draft => {
      draft.name = page.name;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  useAsyncEffect(async isMounted => {
    try {
      const projects = await OrganizationsSvc.getProjects(organizationId);
      if (isMounted()) {
        setStates(draft => {
          draft.organizationProjects = projects;
        });
      }
    } catch (error) {}
  }, []);

  // Handle Change Project Access
  function handleProjectAccessChange(project_id) {
    if (!_.includes(states.accessProjects, project_id)) {
      setStates(draft => {
        draft.accessProjects.push(project_id);
      });
    } else {
      setStates(draft => {
        draft.accessProjects = draft.accessProjects.filter(_id => _id !== project_id);
      });
    }
  }

  // Handle Change Template name
  function handleTemplateName(value) {
    setStates(draft => {
      draft.name = value;
    });
  }

  // Save as template
  async function saveAsTemplate() {
    setStates(draft => {
      draft.is_saving = true;
    });
    const data = await TemplatesSvc.savePageAsTemplate({
      page,
      name: states.name,
      accessibleProjects: states.accessProjects,
      project,
      organization_id: organizationId
    });
    queryClient.invalidateQueries({
      queryKey: ['customPageTemplates']
    });
    await sleep(1000);
    setStates(draft => {
      draft.is_saving = false;
    });
    ref.current?.close();
  }
  return <>
			<ModalWrapper title={intl.messages['modals.save_template.title']} trigger={trigger} size='sm' ref={ref} onOpen={onOpen}>
				<ModalContent>
					<Box my={2}>
						<Typography variant='h3' gutterBottom>
							{intl.messages['modals.save_template.name']}
						</Typography>
						<TextField fullWidth variant='outlined' value={states.name} onChange={evt => handleTemplateName(evt.target.value)} error={_.isEmpty(states.name)} />
					</Box>

					{currentCustomerRights.isAdmin ? <>
							<Typography variant='h3'>{intl.messages['modals.save_template.header.admin']}</Typography>
							<ProjectsAccessWrapper component='fieldset'>
								<FormGroup>
									{_.map(states.organizationProjects.slice(0, Math.ceil(states.organizationProjects.length / 2)), project => <FormControlLabel key={project._id} control={<Checkbox checked={_.includes(states.accessProjects, project._id)} name={project._id} onChange={() => handleProjectAccessChange(project._id)} />} label={project.name} />)}
								</FormGroup>
								<FormGroup>
									{_.map(states.organizationProjects.slice(Math.ceil(states.organizationProjects.length / 2)), project => <FormControlLabel key={project._id} control={<Checkbox checked={_.includes(states.accessProjects, project._id)} name={project._id} onChange={() => handleProjectAccessChange(project._id)} />} label={project.name} />)}
								</FormGroup>
							</ProjectsAccessWrapper>
							<Alert severity='info'>
								<Typography variant='body2'>
									{intl.messages['modals.save_template.info.admin']}
								</Typography>
							</Alert>
						</> : <>
							<Typography variant='h3'>{intl.messages['modals.save_template.header.agent']}</Typography>
							<Alert severity='warning'>
								<Typography variant='body2'>
									{intl.messages['modals.save_template.warning.agent']}
								</Typography>
							</Alert>
							<Alert severity='info'>
								<Typography variant='body2'>
									{intl.messages['modals.save_template.info.agent']}
								</Typography>
							</Alert>
						</>}
				</ModalContent>

				<DialogActions>
					<Button variant='text' onClick={() => ref.current?.close()}>
						<FormattedMessage id='button.cancel' />
					</Button>
					<Button variant='outlined' color='secondary' onClick={saveAsTemplate} disabled={states.is_saving || _.isEmpty(states.name)}>
						{states.is_saving && <CircularProgress style={{
            marginRight: '8px'
          }} color='primary' size={20} />}
						<FormattedMessage id='button.confirm' />
					</Button>
				</DialogActions>
			</ModalWrapper>
		</>;
});
SaveAsPageTemplateModal.propTypes = {
  trigger: PropTypes.object,
  onOpen: PropTypes.func
};
export default SaveAsPageTemplateModal;