import axios from 'axios'
import { API_URL } from 'lib/constants'

const SvgSvc = {
	get,
	getPreview,
	getSvg,
	getRecentlyUsed,
}

export default SvgSvc

async function get({ svgType, svgId }) {
	const urlPath = svgId ? `/svg/${svgType}/${svgId}` : `/svg/${svgType}`
	const { data } = await axios.get(`${API_URL}${urlPath}`)
	return data.results
}

async function getPreview() {
	const { data } = await axios.get(`${API_URL}/svg/preview`)
	return data.results
}

async function getSvg({ search, lang }) {
	const { data } = await axios.get(`${API_URL}/svg/search`, {
		params: {
			search,
			lang,
		},
	})

	return data.results
}

async function getRecentlyUsed({ shapes, limit }) {
	const { data } = await axios.post(`${API_URL}/svg/recent`, shapes, {
		params: {
			limit,
		},
	})

	return data.recently_used
}
