import useSize from 'lib/hooks/useSize';
import _find from 'lodash/find';
import { useInfiniteLoader, useMasonry, usePositioner, useResizeObserver } from 'masonic';
import PropTypes from 'prop-types';
import useScroller from './hooks/useScroller';

// Memoization adapted from https://github.com/jaredLunde/masonic/issues/69#issuecomment-825141331
function ItemsIndexesClosure() {
  const loadedIndexes = {};
  function reset() {
    for (const type in loadedIndexes) {
      loadedIndexes[type] = [];
    }
  }
  function isLoaded({
    type,
    startIndex,
    endIndex
  }) {
    return !!_find(loadedIndexes[type], {
      startIndex,
      endIndex
    });
  }
  function load({
    type,
    startIndex,
    endIndex
  }) {
    if (!loadedIndexes[type]) loadedIndexes[type] = [];
    loadedIndexes[type].push({
      startIndex,
      endIndex
    });
  }
  return {
    reset,
    isLoaded,
    load,
    loadedIndexes
  };
}
export const itemIndexes = ItemsIndexesClosure();
export function InfiniteMasonry({
  containerNode,
  isLoading,
  loadMore,
  items,
  render,
  minimumBatchSize = 25,
  threshold = 5,
  columnWidth = 170,
  columnGutter = 16,
  maxColumnCount = 5
}) {
  const {
    width,
    height
  } = useSize(containerNode, [isLoading]);
  const {
    scrollTop,
    isScrolling
  } = useScroller(containerNode);
  const positioner = usePositioner({
    width,
    columnWidth,
    columnGutter,
    maxColumnCount
  });
  const resizeObserver = useResizeObserver(positioner);
  const onRender = useInfiniteLoader(loadMore, {
    isItemLoaded: (index, items) => Boolean(items[index]) && Boolean(items[index].media_url),
    minimumBatchSize,
    threshold
  });
  return useMasonry({
    positioner,
    resizeObserver,
    items,
    height,
    scrollTop,
    isScrolling,
    render,
    onRender
  });
}
InfiniteMasonry.propTypes = {
  containerNode: PropTypes.any,
  isLoading: PropTypes.bool.isRequired,
  loadMore: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  render: PropTypes.func,
  minimumBatchSize: PropTypes.number,
  threshold: PropTypes.number,
  columnWidth: PropTypes.number,
  columnGutter: PropTypes.number,
  maxColumnCount: PropTypes.number
};