import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
const MaterialActionElement = ({
  className,
  handleOnClick,
  label,
  title
}) => <Button variant='contained' color='primary' className={className} title={title} size='small' onClick={e => handleOnClick(e)} sx={{
  fontSize: '12px'
}}>
		{label}
	</Button>;
MaterialActionElement.propTypes = {
  className: PropTypes.string,
  handleOnClick: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};
MaterialActionElement.displayName = 'MaterialActionElement';
export default MaterialActionElement;