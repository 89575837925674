import * as React from 'react'

function Svg4Number(props) {
	return (
		<svg width='1em' height='1em' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M2.958 3.567h.592v.443h-.592V5h-.545v-.99H.471v-.32L2.38.734h.577v2.833zm-1.872 0h1.327V1.476l-.064.117-1.263 1.974z'
				fill='#000'
			/>
		</svg>
	)
}

export default Svg4Number
