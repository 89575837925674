import { PagePreviewStoryButton } from 'components/atoms';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { InterfaceContainer } from '../styled';
function StartPageInterface({
  page,
  messages,
  theming
}) {
  return <>
			<InterfaceContainer style={page.interface_style}>
				{page.display_start_button && <PagePreviewStoryButton roundedButton={page.roundedButton} name='start' messages={messages} theming={theming} />}
			</InterfaceContainer>
		</>;
}
StartPageInterface.propTypes = {
  page: PropTypes.object,
  messages: PropTypes.object,
  theming: PropTypes.object
};
export default memo(StartPageInterface);