import { List, Menu, MenuItem } from '@mui/material';
import styled from '@styled';
const WIDTH = 300;
export const CurrentAccount = styled(List)`
	display: flex;
	align-items: center;
	.MuiListItem-container {
		width: ${WIDTH}px;
	}
`;
export const AccountsMenuList = styled(Menu)`
	.MuiList-root {
		width: ${WIDTH}px;
	}
`;
export const Option = styled(MenuItem)`
	font-size: 16px;
	min-height: 48px;
`;