import { css } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import styled from '@styled';
import { extractPositionProperties } from 'lib/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import BlockWrapper from './BlockWrapper';
export default function Countdown({
  _id,
  type,
  title,
  size = 'medium',
  bgColor,
  // ----------
  isDisplayTitle = true,
  fontFamily,
  titleColor,
  // ----------
  numberFontFamily,
  subTitleFontFamily,
  subTitleColor,
  numberBgColor,
  numberColor,
  isDisplaySeconds = true,
  days,
  hours,
  mins,
  seconds,
  // -----------
  value,
  outer_style,
  animation
}) {
  const intl = useIntl();
  const {
    positionProperties,
    visualProperties
  } = extractPositionProperties(outer_style);
  const leading0 = num => {
    const convertNum = num > 0 ? num : 0;
    return convertNum < 10 ? '0' + convertNum : `${convertNum}`;
  };
  return <BlockWrapper animation={animation} positionProperties={positionProperties}>
			<div style={{
      ...visualProperties,
      overflow: 'hidden'
    }}>
				<CountdownWrapper $bgColor={bgColor}>
					{isDisplayTitle && <TitleWrapper $fontFamily={fontFamily} $color={titleColor} $size={size}>
							{title}
						</TitleWrapper>}

					<ClockWrapper $isDisplaySeconds={isDisplaySeconds}>
						<CountdownElement time={leading0(getDays(value))} type={intl.messages['editor.block.countdown.days']} size={size} isDisplaySeconds={isDisplaySeconds} numberFontFamily={numberFontFamily} subTitleFontFamily={subTitleFontFamily} subTitleColor={subTitleColor} numberBgColor={numberBgColor} numberColor={numberColor} entry={days} />
						<CountdownElement time={leading0(getHours(value))} type={intl.messages['editor.block.countdown.hours']} size={size} isDisplaySeconds={isDisplaySeconds} numberFontFamily={numberFontFamily} subTitleFontFamily={subTitleFontFamily} subTitleColor={subTitleColor} numberBgColor={numberBgColor} numberColor={numberColor} entry={hours} />
						<CountdownElement time={leading0(getMinutes(value))} type={intl.messages['editor.block.countdown.minutes']} size={size} isDisplaySeconds={isDisplaySeconds} numberFontFamily={numberFontFamily} subTitleFontFamily={subTitleFontFamily} subTitleColor={subTitleColor} numberBgColor={numberBgColor} numberColor={numberColor} entry={mins} />
						{isDisplaySeconds && <CountdownElement time={leading0(getSeconds(value))} type={intl.messages['editor.block.countdown.seconds']} size={size} isDisplaySeconds={isDisplaySeconds} numberFontFamily={numberFontFamily} subTitleFontFamily={subTitleFontFamily} subTitleColor={subTitleColor} numberBgColor={numberBgColor} numberColor={numberColor} entry={seconds} />}
					</ClockWrapper>
				</CountdownWrapper>
			</div>
		</BlockWrapper>;
}
Countdown.propTypes = {
  _id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  outer_style: PropTypes.object,
  animation: PropTypes.object,
  title: PropTypes.string,
  isDisplaySeconds: PropTypes.bool,
  isDisplayTitle: PropTypes.bool,
  size: PropTypes.string,
  fontFamily: PropTypes.string,
  bgColor: PropTypes.string,
  numberBgColor: PropTypes.string,
  numberFontFamily: PropTypes.string,
  numberColor: PropTypes.string
};
function CountdownElement({
  time,
  type,
  size,
  isDisplaySeconds,
  numberFontFamily,
  subTitleFontFamily,
  subTitleColor,
  numberBgColor,
  numberColor,
  entry
}) {
  const timeMapped = time.split('');
  return <Box display='flex' alignItems='center' width='100%'>
			<div>
				<Box display='flex' alignItems='center'>
					{_.map(timeMapped, (num, index) => <ClockNumber key={index} $size={size} $isDisplaySeconds={isDisplaySeconds} $numberBgColor={numberBgColor} $numberFontFamily={numberFontFamily} $numberColor={numberColor}>
							{num}
						</ClockNumber>)}
				</Box>
				{entry.title ? <Typography style={{
        fontSize: '11px',
        fontFamily: subTitleFontFamily,
        color: subTitleColor
      }}>
						{entry.title}
					</Typography> : <Box height='16.5px' />}
			</div>
		</Box>;
}
CountdownElement.propTypes = {
  time: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  isDisplaySeconds: PropTypes.bool,
  numberFontFamily: PropTypes.string,
  subTitleFontFamily: PropTypes.string,
  subTitleColor: PropTypes.string,
  numberBgColor: PropTypes.string,
  numberColor: PropTypes.string,
  entry: PropTypes.object
};

// Styled Components
const CLOCK_NUMBER_STYLES = {
  small: {
    hasSeconds: ({
      theme
    }) => css`
			height: 32px;
			width: 24px;
			border-radius: 4px;
			font-size: 26px;
			margin-right: ${theme.spacing(0.4)};
		`,
    noSeconds: ({
      theme
    }) => css`
			height: 42px;
			width: 28px;
			border-radius: 6px;
			font-size: 32px;
			margin-right: ${theme.spacing(0.4)};
		`
  },
  medium: {
    hasSeconds: ({
      theme
    }) => css`
			height: 42px;
			width: 28px;
			border-radius: 6px;
			font-size: 30px;
			margin-right: ${theme.spacing(0.4)};
		`,
    noSeconds: ({
      theme
    }) => css`
			height: 50px;
			width: 36px;
			border-radius: 8px;
			font-size: 36px;
			margin-right: ${theme.spacing(0.4)};
		`
  },
  large: {
    hasSeconds: ({
      theme
    }) => css`
			height: 50px;
			width: 32px;
			border-radius: 6px;
			font-size: 36px;
			margin-right: ${theme.spacing(0.4)};
		`,
    noSeconds: ({
      theme
    }) => css`
			height: 60px;
			width: 40px;
			border-radius: 6px;
			font-size: 46px;
			margin-right: ${theme.spacing(0.4)};
		`
  }
};
const TITLE_STYLES = {
  small: css`
		font-size: 13px;
	`,
  medium: css`
		font-size: 14px;
	`,
  large: css`
		font-size: 16px;
	`
};
const CountdownWrapper = styled.div`
	height: 100%;
	width: 100%;
	background-color: ${({
  $bgColor
}) => $bgColor};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const TitleWrapper = styled.div`
	text-align: center;
	margin: ${({
  theme
}) => theme.spacing(0, 1.5, 1)};
	font-family: ${({
  $fontFamily
}) => $fontFamily};
	color: ${({
  $color
}) => $color};

	${({
  $size
}) => TITLE_STYLES[$size]};
`;
const ClockWrapper = styled.div`
	margin: ${({
  theme
}) => theme.spacing(0.5, 2, 1)};
	display: grid;
	grid-template-columns: ${({
  $isDisplaySeconds
}) => $isDisplaySeconds ? 'repeat(4, 1fr)' : 'repeat(3, 1fr)'};
	grid-gap: 8px;
`;
const ClockNumber = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({
  $numberBgColor
}) => $numberBgColor};
	color: ${({
  $numberColor
}) => $numberColor};
	font-family: ${({
  $numberFontFamily
}) => $numberFontFamily};
	font-weight: bold;

	${({
  $size,
  $isDisplaySeconds,
  theme
}) => $isDisplaySeconds ? CLOCK_NUMBER_STYLES[$size].hasSeconds({
  theme
}) : CLOCK_NUMBER_STYLES[$size].noSeconds({
  theme
})}
`;

// helper functions
function getDays(deadline) {
  const time = Date.parse(deadline) - Date.parse(new Date());
  return Math.floor(time / (1000 * 60 * 60 * 24));
}
function getHours(deadline) {
  const time = Date.parse(deadline) - Date.parse(new Date());
  return Math.floor(time / (1000 * 60 * 60) % 24);
}
function getMinutes(deadline) {
  const time = Date.parse(deadline) - Date.parse(new Date());
  return Math.floor(time / 1000 / 60 % 60);
}
function getSeconds(deadline) {
  const time = Date.parse(deadline) - Date.parse(new Date());
  return Math.floor(time / 1000 % 60);
}