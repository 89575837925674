/* eslint-disable react-hooks/exhaustive-deps */
import { Cancel, CheckBox, OpenInNew } from '@mui/icons-material';
import { Alert, AlertTitle, Box, Button, Chip, DialogActions, Divider, FormControl, InputLabel, ListItemButton, MenuItem, Select, Tooltip } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { Figure } from 'components/styled';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCustomerRights } from 'redux/customer/selectors';
import { getCurrentProjectId } from 'redux/project/selectors';
import DomainsSvc from 'services/DomainsSvc';
import { ModalWrapper } from '../ModalWrapper';
const StoryCustomDomainModal = forwardRef(({
  storyCustomFQDN,
  allowedFQDNCount = 0,
  onStoryFQDNUpdate
}, ref) => {
  const organization = useSelector(state => state.organization);
  const projectId = useSelector(getCurrentProjectId);
  const customerRights = useSelector(getCustomerRights);
  const [selectedFQDN, setSelectedFQDN] = useState(storyCustomFQDN ?? '');
  const {
    data: domains = [],
    isLoading
  } = useQuery({
    queryKey: ['domains', {
      organizationId: organization._id,
      projectId
    }],
    queryFn: async ({
      queryKey
    }) => {
      const {
        organizationId,
        projectId
      } = queryKey[1];
      return DomainsSvc.getAll({
        organizationId,
        projectId
      });
    }
  });
  const isLimitReached = domains.length === allowedFQDNCount;
  const hasAvailableDomains = !isLoading && domains.length > 0;
  const canSave = selectedFQDN !== storyCustomFQDN;
  const onConfirm = useCallback(() => {
    // Set story_fqdn to undefined if selectedFQDN is empty
    onStoryFQDNUpdate(selectedFQDN !== '' ? selectedFQDN : undefined);
    ref.current?.close();
  }, [selectedFQDN, onStoryFQDNUpdate]);
  const onClose = useCallback(() => {
    setSelectedFQDN(storyCustomFQDN);
    ref.current?.close();
  }, [storyCustomFQDN]);
  return <ModalWrapper size='sm' ref={ref} title={<FormattedMessage id='modals.story_custom_domain.header' />}>
			<Box sx={{
      overflow: 'auto',
      px: 2,
      py: 3,
      display: 'flex',
      flexDirection: 'column'
    }}>
				{hasAvailableDomains ? <FormControl sx={{
        width: 300,
        alignSelf: 'center'
      }}>
						<InputLabel id='custom-domain-label'>
							<FormattedMessage id='modals.story_custom_domain.input.label' />
						</InputLabel>

						<Select value={selectedFQDN} onChange={({
          target: {
            value
          }
        }) => setSelectedFQDN(value)} labelId='custom-domain-label' label={<FormattedMessage id='modals.story_custom_domain.input.label' />}>
							<MenuItem value={''} selected={selectedFQDN === ''}>
								<FormattedMessage id='common.none' />
							</MenuItem>

							{_.map(domains, domain => <MenuItem key={domain._id} value={domain.fqdn} selected={domain.fqdn === selectedFQDN} sx={{
            cursor: domain.isVerified ? 'pointer' : 'not-allowed'
          }}>
									<Box sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              gap: 2,
              pointerEvents: domain.isVerified ? 'auto' : 'none'
            }}>
										{domain.fqdn}
										{typeof domain.isVerified === 'boolean' && <RedirectionStatus isVerified={domain.isVerified} />}
									</Box>
								</MenuItem>)}
							<Divider />
							<Tooltip placement='right' title={isLimitReached ? <>
											<Alert severity='error'>
												<AlertTitle>
													<FormattedMessage id='modals.custom_domain.limit_reached.title' />
												</AlertTitle>
												<FormattedMessage id='modals.custom_domain.limit_reached.description' values={{
                count: allowedFQDNCount
              }} />
											</Alert>
										</> : ''}>
								<Box sx={{
              cursor: isLimitReached ? 'not-allowed' : 'pointer'
            }}>
									<MenuItem component={ListItemButton} href={`/${organization.slug}/settings?tab=domains`} disabled={isLimitReached}>
										<FormattedMessage id='modals.story_custom_domain.button.add' />
										<OpenInNew fontSize='small' sx={{
                  ml: 1
                }} />
									</MenuItem>
								</Box>
							</Tooltip>
						</Select>
					</FormControl> : <NoResults organization={organization} customerRights={customerRights} />}
			</Box>

			<DialogActions>
				<Button variant='text' onClick={onClose}>
					<FormattedMessage id='button.cancel' />
				</Button>

				<Button disabled={!hasAvailableDomains || !canSave} variant='outlined' color='secondary' onClick={onConfirm}>
					<FormattedMessage id='button.save' />
				</Button>
			</DialogActions>
		</ModalWrapper>;
});
StoryCustomDomainModal.propTypes = {
  storyId: PropTypes.string,
  allowedFQDNCount: PropTypes.number,
  storyRedirections: PropTypes.array,
  onStoryFQDNUpdate: PropTypes.func
};
StoryCustomDomainModal.displayName = 'StoryCustomDomainModal';
export default StoryCustomDomainModal;
function NoResults({
  organization,
  customerRights
}) {
  return <Figure height={150}>
			<img src='/static/placeholders/validation.svg' />
			<figcaption>
				{customerRights?.isAgent ? <FormattedMessage id='modals.story_custom_domain.no_results.instruction.agent' /> : <FormattedMessage id='modals.story_custom_domain.no_results.instruction' />}
			</figcaption>
			{customerRights?.isAdmin && <Button color='primary' variant='contained' endIcon={<OpenInNew />} href={`/${organization.slug}/settings?tab=domains`}>
					<FormattedMessage id='modals.story_custom_domain.button.add' />
				</Button>}
		</Figure>;
}
RedirectionStatus.propTypes = {
  isVerified: PropTypes.bool.isRequired
};
function RedirectionStatus({
  isVerified
}) {
  if (isVerified) {
    return <Chip size='small' icon={<CheckBox color='success' />} label={<FormattedMessage id='common.enabled' />} />;
  }
  return <Chip size='small' icon={<Cancel color='error' />} label={<FormattedMessage id='common.disabled' />} />;
}