import { Alert, Button, Checkbox, DialogActions, FormControlLabel, FormGroup, FormLabel, Typography } from '@mui/material';
import { ModalContent, ProjectsAccessWrapper } from 'components/styled/Modals';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useImmer } from 'use-immer';
import { ModalWrapper } from './ModalWrapper';
const ProjectAccessModal = forwardRef(({
  organizationProjects,
  accessProjects,
  memberToUpdate,
  onClose,
  onConfirm
}, ref) => {
  const [states, setStates] = useImmer({
    accessProjects: accessProjects || []
  });
  useEffect(() => {
    setStates(draft => {
      draft.accessProjects = memberToUpdate?.projects || [];
    });
  }, [memberToUpdate]);

  // Handle Change Project Access
  function handleProjectAccessChange(project_id) {
    if (!_.includes(states.accessProjects, project_id)) {
      setStates(draft => {
        draft.accessProjects.push(project_id);
      });
    } else {
      setStates(draft => {
        draft.accessProjects = draft.accessProjects.filter(_id => _id !== project_id);
      });
    }
  }
  return <ModalWrapper size='sm' ref={ref} title={<FormattedMessage id='organization.members.update.title' />} onClose={onClose}>
				<ModalContent>
					{memberToUpdate?.role === 'admin' ? <>
							<Typography variant='h3' gutterBottom>
								<FormattedMessage values={{
            username: memberToUpdate?.username,
            email: memberToUpdate?.email
          }} id='organization.members.update.header.admin' />{' '}
							</Typography>
							<Alert severity='warning'>
								<Typography variant='body2'>
									<FormattedMessage id='organization.members.warning.admin' />
								</Typography>
							</Alert>
						</> : <>
							<Typography variant='body1' gutterBottom>
								<FormattedMessage values={{
            username: memberToUpdate?.username,
            email: memberToUpdate?.email
          }} id='organization.members.update.header.agent' />
							</Typography>
							<Alert severity='warning'>
								<Typography variant='body2'>
									<FormattedMessage id='organization.members.warning.agent' />
								</Typography>
							</Alert>
							<ProjectsAccessWrapper component='fieldset'>
								<FormLabel component='legend'>
									<Typography variant='body1'>
										<FormattedMessage id='organization.members.assign.instruction' />
									</Typography>
								</FormLabel>
								<FormGroup>
									{_.map(organizationProjects.slice(0, Math.ceil(organizationProjects.length / 2)), project => <FormControlLabel key={project._id} control={<Checkbox checked={_.includes(states.accessProjects, project._id)} name={project._id} onChange={() => handleProjectAccessChange(project._id)} />} label={project.name} />)}
								</FormGroup>
								<FormGroup>
									{_.map(organizationProjects.slice(Math.ceil(organizationProjects.length / 2)), project => <FormControlLabel key={project._id} control={<Checkbox checked={_.includes(states.accessProjects, project._id)} name={project._id} onChange={() => handleProjectAccessChange(project._id)} />} label={project.name} />)}
								</FormGroup>
							</ProjectsAccessWrapper>
						</>}
				</ModalContent>
				<DialogActions>
					<Button variant='text' onClick={() => ref.current?.close()}>
						<FormattedMessage id='button.cancel' />
					</Button>
					<Button variant='outlined' color='secondary' onClick={() => onConfirm(states.accessProjects)}>
						<FormattedMessage id='button.confirm' />
					</Button>
				</DialogActions>
			</ModalWrapper>;
});
ProjectAccessModal.propTypes = {
  organizationProjects: PropTypes.array,
  accessProjects: PropTypes.array,
  memberToUpdate: PropTypes.object,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
};
ProjectAccessModal.displayName = 'ProjectAccessModal';
export default ProjectAccessModal;