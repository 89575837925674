import styled from '@styled';
export const PaginationContainer = styled.div`
	width: 80%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	margin-top: ${props => props.theme.spacing(1)};
`;
export const LoaderContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;