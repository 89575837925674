import { ChevronLeft, Close, Delete, DragIndicator, Edit, Image, InsertDriveFile, Movie, Publish } from '@mui/icons-material';
import { Box, Button, CircularProgress, Divider, FormControl, FormHelperText, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { IOSSwitch, TextFieldVariables } from 'atoms';
import { VariablesModal } from 'components/Modals';
import PagePreview from 'components/PagePreview/index';
import { ActionSettingsContainer, AnswerButtonSettingsWrapper, FileDownloaderInput, FileEntry } from 'components/styled/Modals';
import { FILE_TYPE_LIST } from 'lib/constants';
import useFiles from 'lib/hooks/useFiles';
import useUpload from 'lib/hooks/useUpload';
import { getValidVariableTypes, isUrl, reorder, unassignPageVariable, updateVariableData, variableExists } from 'lib/utils';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import Dropdown from 'organisms/Dropdown';
import { useCallback, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import slugify from 'slugify';
import { useImmer } from 'use-immer';
const fileImporterStyle = {
  display: 'none'
};
const other_paths = {
  partial_required_answer: 'required_answer',
  required_answer: 'partial_required_answer'
};
export default function PageActionButtonsSettings({
  onPreviousStep,
  onCancel,
  onSubmitConfig,
  currentPage,
  draftStory
}) {
  const intl = useIntl();
  const changeVariableRef = useRef();
  const [states, setStates] = useImmer({
    currentPage,
    answersSetting: initAnswersStates({
      answers_properties: currentPage.answers_properties,
      answers: currentPage.answers
    }, draftStory.messages)
  });
  const [currentVariables, setVariables] = useImmer(draftStory.variables);
  const [newVariable, setNewVariable] = useImmer({
    name: '',
    type: 'text'
  });
  const [fieldError, setFieldError] = useState(null);
  const variableParams = _.flatMap(draftStory.variables, variable => [variable.name]);
  function handleAddAnswer(buttonType) {
    setStates(draft => {
      draft.answersSetting.answers.push({
        _id: nanoid(),
        type: buttonType,
        title: intl.messages[`edition_menu.answer.${buttonType}.title`],
        rewards: {
          enabled: false,
          emoji: '👍'
        },
        payload: initPlayload(buttonType)
      });
    });
  }
  function handleRemoveAnswer(_id) {
    const idx = _.findIndex(states.answersSetting.answers, answer => answer._id === _id);
    setStates(draft => {
      draft.answersSetting.answers.splice(idx, 1);
    });
  }
  function handleChangeAnswerTitle(_id, value) {
    const idx = _.findIndex(states.answersSetting.answers, answer => answer._id === _id);
    setStates(draft => {
      draft.answersSetting.answers[idx].title = value;
    });
  }
  function handleChangeAnswerPayload(_id, path, value) {
    const idx = _.findIndex(states.answersSetting.answers, answer => answer._id === _id);
    setStates(draft => {
      draft.answersSetting.answers[idx].payload[path] = value;
    });
  }
  function handleUrl(_id, value) {
    handleChangeAnswerPayload(_id, 'web_url', value);
  }
  function handleChangeAnswerProperties(path, value) {
    setStates(draft => {
      draft.answersSetting.answers_properties[path] = value;
    });
  }
  function handleToggleRequired(path, value) {
    if (value === true) {
      const other_path = other_paths[path];
      setStates(draft => {
        draft.answersSetting.answers_properties[other_path] = false;
      });
    }
    setStates(draft => {
      draft.answersSetting.answers_properties[path] = value;
    });
  }
  function onAnswersDragEnd(result) {
    if (!result.destination) {
      return;
    }
    setStates(draft => {
      draft.answersSetting.answers = reorder(draft.answersSetting.answers, result.source.index, result.destination.index);
    });
  }

  // Variables

  function openVariablesModal() {
    changeVariableRef.current.open();
  }
  function handleNewVariableNameChange(value) {
    setFieldError(null);
    const slugValue = slugify(value, {
      remove: /[*+~%\<>§/;`=.(){}?,'"!:@#^|]/g
    });
    setNewVariable(draft => {
      draft.name = slugValue;
    });
  }
  function handleNewVariableTypeChange(value) {
    setNewVariable(draft => {
      draft.type = value;
    });
  }
  function addVariable() {
    if (_.isEmpty(newVariable.name)) {
      return setFieldError('Variable cannot be empty!');
    }
    if (variableExists({
      name: newVariable.name,
      currentVariables
    })) {
      return setFieldError('Variable already exists !');
    }
    setVariables(draft => {
      draft.push({
        ...newVariable,
        default: false,
        isUsed: false
      });
    });
    setNewVariable(draft => {
      draft.name = '';
      draft.type = 'text';
    });
    setFieldError(null);
  }
  function removeVariable(name) {
    const index = currentVariables.findIndex(variable => variable.name === name && !variable.default);
    if (index >= 0) {
      setVariables(draft => {
        draft.splice(index, 1);
      });
    }
  }
  function unassignVariable(name) {
    const idx = currentVariables.findIndex(variable => variable.name === name);
    if (idx >= 0) {
      setVariables(draft => {
        draft[idx] = unassignPageVariable(currentVariables[idx], states.currentPage._id);
      });
    }
    setStates(draft => {
      draft.currentPage.variable = '';
    });
  }
  function assignVariable(name) {
    if (states.currentPage?.variable) {
      const index = currentVariables.findIndex(variable => variable.name === states.currentPage?.variable.variable);
      if (index >= 0) {
        setVariables(draft => {
          draft[index] = unassignPageVariable(currentVariables[index], states.currentPage._id);
        });
      }
    }
    const idx = currentVariables.findIndex(variable => variable.name === name);
    if (idx >= 0) {
      setVariables(draft => {
        draft[idx] = updateVariableData(currentVariables[idx], {
          page: {
            _id: states.currentPage._id,
            type: states.currentPage.type
          }
        });
      });
    }
    setStates(draft => {
      draft.currentPage.variable = name;
    });
    setNewVariable(draft => {
      draft.name = '';
      draft.type = 'text';
    });
    setFieldError(null);
    changeVariableRef.current.close();
  }
  function onCloseVariablesModal() {
    setNewVariable(draft => {
      draft.name = '';
      draft.type = 'text';
    });
    setFieldError(null);
  }
  // ------------------------------------

  function handleSubmit() {
    onSubmitConfig({
      new_page: {
        ...states.currentPage,
        answers_properties: states.answersSetting.answers_properties,
        answers: states.answersSetting.answers
      },
      variables: currentVariables
    });
  }
  function renderActionSettings(answer) {
    switch (answer.type) {
      case 'link':
        return <Box display='grid' gridTemplateColumns='3fr 1.2fr' gap='4px' alignItems='center'>
						<FormControl variant='standard' fullWidth={true}>
							<TextFieldVariables minRows={2} placeholder='URL' textValue={answer.payload.web_url} onChange={value => handleUrl(answer._id, value)} variableParams={variableParams} />
							{_.isEmpty(answer.payload.web_url) && <FormHelperText id='component-error-text' error>
									<FormattedMessage id='edition_menu.answer.web_url.error' />
								</FormHelperText>}
							{answer.payload.web_url && !isUrl(answer.payload.web_url).success && <FormHelperText id='component-error-text' error>
									<FormattedMessage id={isUrl(answer.payload.web_url).messageId} />
								</FormHelperText>}
						</FormControl>

						<Box textAlign='center'>
							<Box display='flex' alignItems='center' justifyContent='center'>
								<Tooltip placement='top' title={<FormattedMessage id='edition_menu.answer.url_preview.hotspot' />}>
									<Typography variant='body1' component='label' sx={{
                  cursor: 'help'
                }}>
										<FormattedMessage id='edition_menu.answer.url_preview' />
									</Typography>
								</Tooltip>
							</Box>
							<IOSSwitch checked={answer.payload.auto_redirect} onChange={({
              target: {
                checked
              }
            }) => handleChangeAnswerPayload(answer._id, 'auto_redirect', checked)} color='primary' />
						</Box>
					</Box>;
      case 'file_uploader':
        return <Dropdown fullWidth multiple variant='outlined' value={answer.payload.file_types || []} onChange={({
          target: {
            value
          }
        }) => handleChangeAnswerPayload(answer._id, 'file_types', value)} options={FILE_TYPE_LIST} renderValue={renderFileUploaderValue} labelId='file_uploader' label={<FormattedMessage id='edition_menu.file_uploader.label' />} />;
      case 'file_downloader':
        return <FileDownloaderOptions answer={answer} handleAnswerUpdate={handleChangeAnswerPayload} />;
    }
  }
  return <>
			<AnswerButtonSettingsWrapper>
				<Box width='100%' height='100%' display='flex' justifyContent='center' alignItems='center' overflow='hidden'>
					<PagePreview size='large' page={{
          ...states.currentPage,
          answers_properties: states.answersSetting.answers_properties,
          answers: states.answersSetting.answers
        }} theming={draftStory.theming} messages={draftStory.messages} gdprConsent={draftStory?.gdprConsent} storyType={draftStory.type} />
				</Box>
				<Box height='600px' overflow='auto' p={1}>
					<Box width='100%'>
						<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2}>
							<Box pl={2}>
								<Typography variant='h2'>
									<FormattedMessage id='edition_menu.answer.actions.required_answer' />
								</Typography>
								<Typography variant='body1'>
									<FormattedMessage id='edition_menu.answer.actions.required_answer.description' />
								</Typography>
							</Box>

							<IOSSwitch checked={states.answersSetting.answers_properties?.required_answer} onChange={({
              target: {
                checked
              }
            }) => handleToggleRequired('required_answer', checked)} color='primary' disabled={!hasUploadButton(states.answersSetting.answers)} />
						</Box>

						<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2}>
							<Box pl={2}>
								<Typography variant='h2'>
									<FormattedMessage id='edition_menu.answer.actions.partial_required_answer' />
								</Typography>
								<Typography variant='body1'>
									<FormattedMessage id='edition_menu.answer.actions.partial_required_answer.description' />
								</Typography>
							</Box>

							<IOSSwitch checked={!!states.answersSetting.answers_properties.partial_required_answer} onChange={({
              target: {
                checked
              }
            }) => handleToggleRequired('partial_required_answer', checked)} color='primary' disabled={!hasUploadButton(states.answersSetting.answers)} />
						</Box>

						<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2}>
							<Box pl={2}>
								<Typography variant='h2'>Variable</Typography>
								<Typography variant='body1'>
									<FormattedMessage id='edition_menu.answer.variable.description' />
								</Typography>
							</Box>

							{states.currentPage?.variable ? <Box sx={{
              display: 'flex',
              alignItems: 'center',
              p: 0.5,
              m: 1,
              border: '0.5px solid rgb(72, 72, 71)',
              borderRadius: 1
            }}>
									<Typography variant='h3'>{states.currentPage?.variable}</Typography>
									<IconButton size='small' onClick={openVariablesModal}>
										<Edit />
									</IconButton>
									<IconButton size='small' onClick={() => unassignVariable(states.currentPage?.variable)}>
										<Close />
									</IconButton>
								</Box> : <Button variant='contained' size='medium' onClick={openVariablesModal} sx={{
              flexShrink: 0,
              m: 1
            }} disabled={!hasUploadButton(states.answersSetting.answers)}>
									<FormattedMessage id='edition_menu.variables.add' values={{
                count: 1
              }} />
								</Button>}

							<VariablesModal ref={changeVariableRef} currentVariables={currentVariables} currentVariable={states.currentPage?.variable} allowType={getValidVariableTypes('page', states.currentPage)} assignVariable={assignVariable} removeVariable={removeVariable} addNewVariable={addVariable} newVariable={newVariable} handleChangeNewVariableName={handleNewVariableNameChange} handleChangeNewVariableType={handleNewVariableTypeChange} fieldError={fieldError} onClose={onCloseVariablesModal} />
						</Box>
					</Box>

					<Divider />

					<div className='action-settings-header '>
						<div />
						<Typography variant='h3'>{intl.messages['edition_menu.answer_config.header.title']}</Typography>
						<Typography variant='h3'>{intl.messages['edition_menu.answer_config.header.type']}</Typography>
						<Typography variant='h3'>
							<FormattedMessage id='edition_menu.answer_config.header.settings' />
						</Typography>
					</div>
					<DragDropContext onDragEnd={onAnswersDragEnd}>
						<Droppable droppableId='droppable'>
							{(provided, snapshot) => <div {...provided.droppableProps} ref={provided.innerRef}>
									{_.map(states.answersSetting.answers, (answer, index) => <Draggable key={answer._id} draggableId={answer._id} index={index}>
											{(provided, snapshot) => <ActionSettingsContainer key={answer._id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} $isDragging={snapshot.isDragging}>
													<div className='drag-handle'>
														<DragIndicator />
													</div>
													<TextField fullWidth multiline variant='outlined' value={answer.title} onChange={evt => handleChangeAnswerTitle(answer._id, evt.target.value)} />

													<div>
														<Typography>
															{intl.messages[`edition_menu.navigation.button_type.${answer.type}`]}
														</Typography>
													</div>

													{renderActionSettings(answer)}

													<Tooltip title={states.answersSetting.answers.length > 1 ? <FormattedMessage id='button.delete' /> : <FormattedMessage id='edition_menu.quizz.answers.delete_error' />} placement='bottom'>
														<div>
															<IconButton size='small' disabled={states.answersSetting.answers.length <= 1} onClick={() => handleRemoveAnswer(answer._id)}>
																<Delete />
															</IconButton>
														</div>
													</Tooltip>
												</ActionSettingsContainer>}
										</Draggable>)}
									{provided.placeholder}
								</div>}
						</Droppable>
					</DragDropContext>

					<Box display='grid' gridTemplateColumns='1fr 1fr 1fr' gap='8px' alignItems='center' mt={2}>
						<Button variant='contained' color='secondary' onClick={() => handleAddAnswer('file_uploader')}>
							<FormattedMessage id='edition_menu.answer.add_button.file_uploader' />
						</Button>
					</Box>
				</Box>
			</AnswerButtonSettingsWrapper>

			<Box sx={{
      py: 2,
      px: 6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}>
				{onPreviousStep ? <Button variant='text' onClick={onPreviousStep} startIcon={<ChevronLeft />}>
						<FormattedMessage id='button.previous' />
					</Button> : onCancel ? <Button variant='text' onClick={onCancel}>
						<FormattedMessage id='button.cancel' />
					</Button> : <div />}

				<Button variant='contained' color='primary' disabled={confirmIsDiabled(states.answersSetting.answers)} onClick={() => handleSubmit()}>
					<FormattedMessage id='common.theming.confirm_button' />
				</Button>
			</Box>
		</>;
}

// Components

function FileDownloaderOptions({
  answer,
  handleAnswerUpdate
}) {
  const intl = useIntl();
  const fileImportRef = useRef();
  const {
    deleteFile
  } = useFiles();
  const onUploadSuccess = useCallback(uploadedFiles => {
    const uploadedFile = uploadedFiles[0];
    handleAnswerUpdate(answer._id, 'file', _.pick(uploadedFile, ['_id', 'type', 'sub_type', 'url', 'name']));
  }, [answer._id, handleAnswerUpdate]);
  const {
    isUploading,
    errorMsg,
    handleUpload
  } = useUpload({
    onUploadSuccess
  });
  async function removeDownloadedFile() {
    const fileToRemove = answer.payload.file._id;
    await deleteFile(fileToRemove);
    handleAnswerUpdate(answer._id, 'file', {});
  }
  function handleFileImport(evt) {
    if (!_.isEmpty(answer.payload.file)) {
      removeDownloadedFile();
    }
    // handleFileUpload(evt)
    const file = evt.target.files[0];
    if (file) {
      file.name ??= nanoid();
      handleUpload(file);
    }
  }
  if (!errorMsg && !_.isEmpty(answer.payload.file)) {
    return <FileEntry>
				<FileType fileType={answer.payload.file.type} />

				<div>{answer.payload.file.name}</div>

				<a target='_blank' rel='noreferrer' href={answer.payload.file.url}>
					<FormattedMessage id='common.preview' />
				</a>
				<IconButton onClick={removeDownloadedFile} size='small'>
					<Close fontSize='small' />
				</IconButton>
			</FileEntry>;
  }
  return <>
			<FileDownloaderInput>
				<Tooltip title={<FormattedMessage id='editor_menu.file_downloader.tooltip' />}>
					<Button size='medium' variant='contained' color='primary' onClick={() => fileImportRef.current?.click()} style={{
          margin: 'auto'
        }}
        // To handle error with Tooltip wrapping a disabled button
        component={!_.isEmpty(answer.payload.file) ? 'div' : undefined} disabled={!_.isEmpty(answer.payload.file)} startIcon={!isUploading ? <Publish /> : <CircularProgress color='secondary' size={20} />}>
						<FormattedMessage id='edition_menu.answer.upload_file.button' />
					</Button>
				</Tooltip>

				{errorMsg && <Box mt={1} color='error.main'>
						{errorMsg}
					</Box>}

				{!errorMsg && _.isEmpty(answer.payload.file) && <Typography variant='body1' color='error'>
						<FormattedMessage id='edition_menu.answer.file_download.error' />
					</Typography>}
			</FileDownloaderInput>

			<input ref={fileImportRef} style={fileImporterStyle} type='file' onChange={handleFileImport} />
		</>;
}
function FileType({
  fileType
}) {
  return <Box mr={1}>{fileType === 'image' ? <Image /> : fileType === 'video' ? <Movie /> : <InsertDriveFile />}</Box>;
}

// Helper functions
function initAnswersStates(initialStates, messages) {
  if (!_.isEmpty(initialStates)) return initialStates;
  return {
    answers_properties: {
      type: 'answer',
      multiple_selection: false,
      limit_answers: false,
      limit_answers_count: 2,
      partial_correct: false,
      required_answer: false,
      other_answer: false,
      count_in_results: true,
      confirm_button: messages.buttons.confirm,
      correct_redirect: 'next',
      incorrect_redirect: 'next',
      partial_redirect: 'next'
    },
    answers: []
  };
}
function initPlayload(buttonType) {
  switch (buttonType) {
    case 'link':
      return {
        web_url: '',
        auto_redirect: false
      };
    case 'file_uploader':
      return {
        file_types: ['pdf', 'image', 'video']
      };
    case 'file_downloader':
      return {
        file: {}
      };
  }
}
function hasUploadButton(answers) {
  return _.some(answers, ['type', 'file_uploader']);
}
function renderFileUploaderValue(selected) {
  const selectedValuesText = _.map(_.split(selected, ','), currentVal => {
    const fileType = _.find(FILE_TYPE_LIST, option => option.value === currentVal);
    return fileType?.text;
  });
  return _.join(selectedValuesText, ', ').trim(',');
}
function confirmIsDiabled(answers) {
  if (_.isEmpty(answers)) return true;
  return _.some(answers, answer => {
    if (answer.type === 'link') {
      if (_.isEmpty(answer.payload.web_url) || answer.payload.web_url && !isUrl(answer.payload.web_url).success) return true;
    } else if (answer.type === 'file_downloader') {
      if (_.isEmpty(answer.payload.file)) return true;
    }
    return false;
  });
}