import { AUTO_SAVE_DEBOUNCE_TIME } from 'lib/constants'
import { CustomerError } from 'lib/Errors'
import { PageErrors } from 'lib/models/PageErrors'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import { createContext, useCallback, useContext, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import SnackeetSvc from 'services/SnackeetSvc'
import { useDebouncedCallback } from 'use-debounce'
import { createStore } from 'zustand'
import { devtools, subscribeWithSelector } from 'zustand/middleware'
import { shallow } from 'zustand/shallow'
import { useStoreWithEqualityFn } from 'zustand/traditional'

import { getDispatcher } from './reducer'
import { getInitialState } from './state'

const EditorContext = createContext(null)

const createEditorStore = (props) =>
	createStore(
		subscribeWithSelector(
			devtools(
				(set) => ({
					...getInitialState(props),
					dispatch: getDispatcher(set),
				}),
				{
					name: `Editor ${props.snackeet.story_id}`,
					serialize: { options: true },
				}
			)
		)
	)

export default function EditorProvider({ children, snackeet, published, pageTemplates }) {
	const storeRef = useRef()
	if (!storeRef.current) {
		storeRef.current = createEditorStore({
			snackeet,
			published,
			pageTemplates,
		})
	}

	return <EditorContext.Provider value={storeRef.current}>{children}</EditorContext.Provider>
}
EditorProvider.propTypes = {
	children: PropTypes.node,
	snackeet: PropTypes.object,
	published: PropTypes.object,
	pageTemplates: PropTypes.object,
}

export function useAutoSave() {
	const intl = useIntl()
	const router = useRouter()
	const dispatch = useStoryDispatch()
	const storyStoreApi = useStoryStoreApi()
	const { enqueueSnackbar } = useSnackbar()

	const autoSaveSnackeet = useCallback(async () => {
		let updatedAt

		try {
			dispatch({ type: 'SET_IS_AUTO_SAVING' }, false)
			updatedAt = await SnackeetSvc.update(storyStoreApi.getState().draftStory)
		} catch (err) {
			if (err instanceof CustomerError) {
				// Redirect to logout
				router.push('/login')
			}

			// If bad request, then story data is stale
			if (err.response?.status === 400) {
				dispatch({ type: 'MARK_STORY_AS_STALE' }, false)
			} else {
				enqueueSnackbar(intl.messages['toast.error'], {
					variant: 'error',
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'bottom',
						horizontal: 'left',
					},
				})
			}
		} finally {
			dispatch(
				{
					type: 'SET_SNACKEET_AUTO_SAVED',
					data: { updatedAt },
				},
				false
			)
		}
	}, [dispatch, enqueueSnackbar, intl.messages, router, storyStoreApi])

	const debouncedSave = useDebouncedCallback(autoSaveSnackeet, AUTO_SAVE_DEBOUNCE_TIME)

	useEffect(
		() =>
			storyStoreApi.subscribe(
				(s) => _.pick(s, ['isDirty', 'autoSaveStatus', 'draftStory']),
				({ isDirty, autoSaveStatus }) => {
					if (isDirty && autoSaveStatus !== 'saving') {
						debouncedSave()
					}
				},
				{ equalityFn: shallow }
			),
		[debouncedSave, storyStoreApi]
	)
}

export function useStoryErrors() {
	const dispatch = useStoryDispatch()
	const storyStoreApi = useStoryStoreApi()

	const processStoryFeedback = useCallback(() => {
		const draftStory = storyStoreApi.getState().draftStory
		const newStoryFeedback = _.flatMap(draftStory.pages, (page) => {
			const pageErrors = new PageErrors(page).hasError(draftStory, page).build()
			return pageErrors ? [pageErrors] : []
		})

		const serializableStoryErrors = _.map(newStoryFeedback, (pageErrors) =>
			_.pick(pageErrors, ['_id', 'type', 'name', 'errors'])
		).filter((error) => !_.isEmpty(error.errors))

		const serializableStoryRecommendations = _.map(newStoryFeedback, (pageErrors) =>
			_.pick(pageErrors, ['_id', 'type', 'name', 'recommendations'])
		).filter((recommendation) => !_.isEmpty(recommendation.recommendations))

		dispatch(
			[
				{
					type: 'STORY_FEEDBACK.UPDATE_ERRORS',
					data: serializableStoryErrors,
				},
				{
					type: 'STORY_FEEDBACK.UPDATE_RECOMMENDATIONS',
					data: serializableStoryRecommendations,
				},
				{ type: 'STORY_FEEDBACK.RESET_SELECTED_FEEDBACK' },
			],
			false
		)
	}, [dispatch, storyStoreApi])

	const debouncedProcessFeedback = useDebouncedCallback(processStoryFeedback, 500)

	useEffect(() => {
		processStoryFeedback()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(
		() =>
			storyStoreApi.subscribe(
				(s) => _.pick(s, ['isDirty', 'draftStory']),
				({ isDirty }) => {
					if (isDirty) {
						debouncedProcessFeedback()
					}
				},
				{ equalityFn: shallow }
			),
		[debouncedProcessFeedback, storyStoreApi]
	)
}

export function useStoryDispatch() {
	const dispatch = useStoryState((state) => state.dispatch)
	return dispatch
}

export function useStoryState(selector) {
	const store = useContext(EditorContext)
	if (!store) throw new Error('Missing EditorContext.Provider in the tree')
	return useStoreWithEqualityFn(store, selector, shallow)
}

export function useStoryStoreApi() {
	const store = useContext(EditorContext)
	if (!store) throw new Error('Missing EditorContext.Provider in the tree')
	return store
}
