import React, { useMemo } from 'react';
import Body from './components/body/Body';
import Header from './components/header/Header';
import PickerMain from './components/PickerMain';
import PickerContext from './context/PickerContext';
import SettingsContext from './context/SettingsContext';
import TenorContext from './context/TenorContext';
import usePickerContext from './hooks/usePickerContext';
import useSettings from './hooks/useSettings';
import TenorManager from './managers/TenorManager';
import { ContentFilter, TenorAssetType, TenorImage, Theme } from './types/exposedTypes';
export interface GifPickerReactProps {
  tenorApiKey: string;
  onGifClick?: (gif: TenorImage) => void;
  assetType?: TenorAssetType;
  autoFocusSearch?: boolean;
  contentFilter?: ContentFilter;
  clientKey?: string;
  country?: string;
  locale?: string;
  width?: number | string;
  height?: number | string;
  categoryHeight?: number | string;
  theme?: Theme;
}
function GifPickerReact(props: GifPickerReactProps): JSX.Element {
  const settings = useSettings(props);
  const pickerContext = usePickerContext();
  const tenorManager: TenorManager = useMemo(() => new TenorManager({
    apiKey: settings.tenorApiKey,
    clientKey: settings.clientKey,
    country: settings.country,
    locale: settings.locale,
    contentFilter: settings.contentFilter,
    assetType: settings.assetType
  }), [settings.assetType]);
  return <SettingsContext.Provider value={settings}>
			<PickerContext.Provider value={pickerContext}>
				<TenorContext.Provider value={tenorManager}>
					<PickerMain>
						<Header />
						<Body width={props.width} />
					</PickerMain>
				</TenorContext.Provider>
			</PickerContext.Provider>
		</SettingsContext.Provider>;
}
export default GifPickerReact;