export const defaultMessages_EN = {
	buttons: {
		next: 'Skip',
		menu: 'Back to menu',
		restart: 'Start again',
		return: 'Back',
		send: 'Validate',
		start: 'Start',
		confirm: 'Confirm',
		redirection: 'Link',
	},
	error_messages: {
		email: {
			invalid: 'The email address is invalid',
			empty: 'Please fill in your email address',
		},
		phone: {
			invalid: 'This mobile number is invalid',
			empty: 'Please fill in your mobile number',
		},
	},
	navigation_error_messages: {
		back: `You can't go to previous page`,
		next: `You can't go to next page yet`,
	},
}

export const defaultMessages_FR = {
	buttons: {
		next: 'Passer',
		menu: 'Retour au menu',
		restart: 'Redémarrer',
		return: 'Retour',
		send: 'Valider',
		start: 'Démarrer',
		confirm: 'Confirmer',
		redirection: 'Lien URL',
	},
	error_messages: {
		email: {
			invalid: `L'adresse mail est invalide`,
			empty: 'Veuillez renseigner votre adresse e-mail',
		},
		phone: {
			invalid: 'Ce numéro de portable est invalide',
			empty: 'Veuillez renseigner votre numéro de portable',
		},
	},
	navigation_error_messages: {
		back: `Vous ne pouvez pas aller à la page précédente`,
		next: `Vous ne pouvez pas aller à la prochaine page`,
	},
}

export const defaultMessages_ES = {
	buttons: {
		next: 'Omitir',
		menu: 'volver al menú',
		restart: 'Empezar de nuevo',
		return: 'Atrás',
		send: 'Validar',
		start: 'Comienzo',
		confirm: 'Confirmar',
		redirection: 'Enlace',
	},
	error_messages: {
		email: {
			invalid: 'La dirección de correo electrónico es inválida',
			empty: 'Por favor introduzca su dirección de correo electrónico',
		},
		phone: {
			invalid: 'Este número de móvil no es válido',
			empty: 'Por favor, introduzca su número de móvil',
		},
	},
	navigation_error_messages: {
		back: `No se puede ir a la página anterior`,
		next: `Todavía no se puede pasar a la siguiente página`,
	},
}

export const defaultMessages_DE = {
	buttons: {
		next: 'Springen',
		menu: 'Zurück zum Menü',
		restart: 'Nochmal beginnen',
		return: 'Zurück',
		send: 'Bestätigen',
		start: 'Starten',
		confirm: 'Bestätigen Sie',
		redirection: 'Verknüpfung',
	},
	error_messages: {
		email: {
			invalid: 'Die e-mail Adresse ist ungültig',
			empty: 'Bitte geben Sie Ihre E-Mail-Adresse ein',
		},
		phone: {
			invalid: 'Diese Handynummer ist ungültig',
			empty: 'Bitte tragen Sie Ihre Handynummer ein',
		},
	},
	navigation_error_messages: {
		back: `Sie können nicht zur vorherigen Seite wechseln`,
		next: `Sie können noch nicht zur nächsten Seite wechseln`,
	},
}
