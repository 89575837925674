import { Menu, Settings } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, Paper } from '@mui/material';
import _ from 'lodash';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getInstagramAccounts } from 'redux/organization/selectors';
import AccountItem from './AccountItem';
import { AccountsMenuList, CurrentAccount, Option } from './AccountsMenu.style';
const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left'
};
const transformOrigin = {
  vertical: 'top',
  horizontal: 'left'
};
export default function AccountsMenu({
  selectedAccount,
  onAccountSelect,
  goToView
}) {
  const instagramAccounts = useSelector(getInstagramAccounts);
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'instagramAccountsMenu'
  });
  return <>
			<Paper>
				<CurrentAccount {...bindTrigger(popupState)} disablePadding>
					<AccountItem account={selectedAccount}>
						<IconButton size='small'>
							<Menu />
						</IconButton>
					</AccountItem>
				</CurrentAccount>
			</Paper>

			<AccountsMenuList {...bindMenu(popupState)} anchorOrigin={anchorOrigin} transformOrigin={transformOrigin}>
				{_.map(instagramAccounts, account => <AccountItem key={account._id} account={account} onSelect={() => {
        popupState.close();
        onAccountSelect(account)();
      }} isSelected={selectedAccount.pageId === account.pageId} />)}

				<Divider />
				<Option onClick={goToView('accounts')}>
					<ListItemIcon>
						<Settings />
					</ListItemIcon>
					<FormattedMessage id='integrations.instagram.accounts.button.manage' />
				</Option>
			</AccountsMenuList>
		</>;
}
AccountsMenu.propTypes = {
  selectedAccount: PropTypes.object,
  onAccountSelect: PropTypes.func,
  goToView: PropTypes.func
};