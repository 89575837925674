// Contain wheel interaction and wheel modal
// 2 Primary components: Wheel Preview and Game Settings
// 4 primary tabs: Wheel, Win, Lose, Form
// States with 2 mains objects: interaction and modal
import { Add, ChevronLeft, Close, ContentCopyOutlined, Delete, DragIndicator, Edit, ExpandMore, HelpOutline, Palette, Settings } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Collapse, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import { ColorInput, IOSSwitch, Slider, TextFieldEmoji, TextFieldVariables } from 'atoms';
import { ImagePreview } from 'components/atoms/AssetsPreview/MediaPreview';
import { CustomTab, CustomTabs } from 'components/atoms/CustomTabs';
import { GdprSettingsModal, ImagesSelectorModal, VariablesModal } from 'components/Modals';
import FontFamiliesDropdown from 'components/organisms/FontFamiliesDropdown';
import PagePreview from 'components/PagePreview/index';
import { WheelOptionSettingsContainer, WheelSettingsContainer } from 'components/styled/Modals';
import useGDPR from 'lib/hooks/useGDPR';
import { cleanUpCurrentPageVariables, getValidVariableTypes, reorder, unassignPageVariable, updateVariableData, variableExists } from 'lib/utils';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { useCallback, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import slugify from 'slugify';
import { useImmer } from 'use-immer';
const WHEEL_VARIABLE = ['prize'];
const getPercentProbability = _.curry(function (prizes, weight) {
  const weightSum = _.sumBy(prizes, 'weight');
  return `${(weight / weightSum * 100).toFixed(2)}%`;
});
export default function WheelSettings({
  onPreviousStep,
  onCancel,
  onSubmitConfig,
  currentPage,
  draftStory,
  recentColors,
  brandColors,
  updateRecentColor,
  recentFonts,
  brandFontFamily
}) {
  const intl = useIntl();
  const [state, setState] = useImmer({
    interaction: _.first(currentPage?.interactions),
    modal: currentPage?.modal
  });
  const variableParams = _.flatMap(draftStory?.variables, variable => [variable.name]);
  const [tab, setTab] = useState('options');
  const {
    gdprConsent,
    handleChangeGdprConsent
  } = useGDPR();
  const gdprSettingsModalRef = useRef();
  const changeVariableRef = useRef();
  const [currentVariables, setVariables] = useImmer(cleanUpCurrentPageVariables({
    currentPage,
    currentVariables: draftStory.variables
  }));
  const [newVariable, setNewVariable] = useImmer({
    name: '',
    type: 'text'
  });
  const [fieldError, setFieldError] = useState(null);

  // Common update value functions
  function updateInteractionComponent({
    component,
    field,
    value
  }) {
    setState(draft => {
      _.set(draft.interaction, `components.${component}.${field}`, value);
    });
  }
  function updateModalProperty({
    modal,
    field,
    value
  }) {
    setState(draft => {
      _.set(draft.modal, `${modal}.${field}`, value);
    });
  }
  function updateWheelFontSize(value) {
    setState(draft => {
      draft.interaction.components.wheel.style.fontSize = value;
      _.forEach(draft.interaction.components.wheel.properties.prizes, prize => {
        prize.style.fontSize = value;
      });
    });
  }
  function updateWheelFontFamily(value) {
    setState(draft => {
      draft.interaction.components.wheel.style.fontFamily = value;
      _.forEach(draft.interaction.components.wheel.properties.prizes, prize => {
        prize.style.fontFamily = value;
      });
    });
  }

  // Wheel options functions
  function addWheelOption() {
    setState(draft => {
      draft.interaction.components.wheel.properties.prizes.push({
        _id: nanoid(),
        option: 'New Option',
        weight: 1,
        isLose: false,
        style: {
          backgroundColor: '#D9F2B4',
          textColor: 'black',
          fontWeight: 'normal',
          fontSize: 20,
          fontFamily: state?.interaction?.components?.wheel?.style?.fontFamily || 'Poppins'
        }
      });
    });
  }
  const onAnswersDragEnd = useCallback(result => {
    if (!result.destination) {
      return;
    }
    setState(draft => {
      draft.interaction.components.wheel.properties.prizes = reorder(draft.interaction.components.wheel.properties.prizes, result.source.index, result.destination.index);
    });
  }, [setState]);
  function removeWheelOption(removeId) {
    setState(draft => {
      draft.interaction.components.wheel.properties.prizes = draft.interaction.components.wheel.properties.prizes.filter(prize => prize._id !== removeId);
    });
  }
  function duplicateWheelOption(duplicateId) {
    setState(draft => {
      const prizeIndex = draft.interaction.components.wheel.properties.prizes.findIndex(prize => prize._id === duplicateId);
      const duplicatePrize = _.cloneDeep(draft.interaction.components.wheel.properties.prizes[prizeIndex]);
      duplicatePrize._id = nanoid();
      draft.interaction.components.wheel.properties.prizes.splice(prizeIndex + 1, 0, duplicatePrize);
    });
  }
  function updateWheelOption({
    prizeId,
    field,
    value
  }) {
    setState(draft => {
      const prizeIndex = draft.interaction.components.wheel.properties.prizes.findIndex(prize => prize._id === prizeId);
      _.set(draft.interaction, `components.wheel.properties.prizes.${prizeIndex}.${field}`, value);
    });
  }

  // Variables

  function openVariablesModal() {
    changeVariableRef.current.open();
  }
  function handleNewVariableNameChange(value) {
    setFieldError(null);
    const slugValue = slugify(value, {
      remove: /[*+~%\<>§/;`=.(){}?,'"!:@#^|]/g
    });
    setNewVariable(draft => {
      draft.name = slugValue;
    });
  }
  function handleNewVariableTypeChange(value) {
    setNewVariable(draft => {
      draft.type = value;
    });
  }
  function addVariable() {
    if (_.isEmpty(newVariable.name)) {
      return setFieldError('Variable cannot be empty!');
    }
    if (variableExists({
      name: newVariable.name,
      currentVariables
    })) {
      return setFieldError('Variable already exists !');
    }
    setVariables(draft => {
      draft.push({
        ...newVariable,
        default: false,
        isUsed: false
      });
    });
    setNewVariable(draft => {
      draft.name = '';
      draft.type = 'text';
    });
    setFieldError(null);
  }
  function removeVariable(name) {
    const index = currentVariables.findIndex(variable => variable.name === name && !variable.default);
    if (index >= 0) {
      setVariables(draft => {
        draft.splice(index, 1);
      });
    }
  }
  function unassignVariable(name) {
    const idx = currentVariables.findIndex(variable => variable.name === name);
    if (idx >= 0) {
      setVariables(draft => {
        draft[idx] = unassignPageVariable(currentVariables[idx], currentPage._id);
      });
    }
    setState(draft => {
      draft.modal.form.variable = '';
    });
  }
  function assignVariable(name) {
    if (state.modal?.form?.variable) {
      const index = currentVariables.findIndex(variable => variable.name === state.modal.form.variable);
      if (index >= 0) {
        setVariables(draft => {
          draft[index] = unassignPageVariable(currentVariables[index], currentPage._id);
        });
      }
    }
    const idx = currentVariables.findIndex(variable => variable.name === name);
    if (idx >= 0) {
      setVariables(draft => {
        draft[idx] = updateVariableData(currentVariables[idx], {
          page: {
            _id: currentPage._id,
            type: currentPage.type
          }
        });
      });
    }
    setState(draft => {
      draft.modal.form.variable = name;
    });
    setNewVariable(draft => {
      draft.name = '';
      draft.type = 'text';
    });
    setFieldError(null);
    changeVariableRef.current.close();
  }
  function onCloseVariablesModal() {
    setNewVariable(draft => {
      draft.name = '';
      draft.type = 'text';
    });
    setFieldError(null);
  }
  // ------------------------------------

  // Modal image
  const onImageChange = useCallback(({
    url
  }) => {
    setState(draft => {
      _.set(draft.modal, `${tab}.img_url`, url);
    });
  }, [setState, tab]);
  const onUploadSuccess = useCallback(uploadedFiles => {
    if (uploadedFiles.length === 1) {
      const {
        _id,
        url
      } = uploadedFiles[0];
      onImageChange({
        url
      });
    }
  }, [onImageChange]);

  // Submit function
  function handleSubmit() {
    onSubmitConfig({
      new_page: {
        ...currentPage,
        interactions: [state.interaction],
        modal: state.modal
      },
      variables: currentVariables,
      newInteraction: state.interaction,
      newModal: state.modal,
      gdprConsent
    });
  }

  // Render functions
  function renderTabContent() {
    switch (tab) {
      case 'options':
        return <Box width='100%'>
						<Accordion sx={{
            boxShadow: 'none',
            border: '1px solid #ccc'
          }}>
							<AccordionSummary expandIcon={<ExpandMore />}>
								<Palette fontSize='small' sx={{
                mr: 1
              }} />
								<Typography variant='h2'>Wheel Styles</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Box display='grid' gridTemplateColumns='1fr 1fr' gap={2}>
									<Box>
										<Typography variant='h2'>
											<FormattedMessage id='edition_menu.game.wheel.field.wheel.header' />
										</Typography>
										<div className='property-line'>
											<Typography variant='body1' component='label' className='property-field'>
												<FormattedMessage id='common.fontFamily' />
											</Typography>
											<div className='property-content'>
												<FontFamiliesDropdown recentFonts={recentFonts} brandFontFamily={brandFontFamily} onChange={(evt, fontFamily) => updateWheelFontFamily(fontFamily)} value={state?.interaction?.components?.wheel?.style?.fontFamily || 'Poppins'} fullWidth isOutlined />
											</div>
										</div>
										<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.pointer_color' />} value={state?.interaction?.components?.pointer?.color || '#E24A2B'} updateValue={value => updateInteractionComponent({
                    component: 'pointer',
                    field: 'color',
                    value
                  })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
										<div className='property-line'>
											<Typography variant='body1' component='label' className='property-field'>
												<FormattedMessage id='edition_menu.game.wheel.field.wheel_border.size' />
											</Typography>
											<div className='property-content'>
												<Slider initial_value={parseInt(state?.interaction?.components?.wheel?.style?.outerBorderWidth)} onUpdate={value => updateInteractionComponent({
                        component: 'wheel',
                        field: 'style.outerBorderWidth',
                        value
                      })} maxValue={20} minValue={0} units='px' inputStep={1} />
											</div>
										</div>
										<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.wheel_border.color' />} value={state?.interaction?.components?.wheel?.style?.outerBorderColor} updateValue={value => updateInteractionComponent({
                    component: 'wheel',
                    field: 'style.outerBorderColor',
                    value
                  })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />

										<Typography variant='h2' mt={2}>
											<FormattedMessage id='edition_menu.game.wheel.field.items.header' />
										</Typography>
										<div className='property-line'>
											<Typography variant='body1' component='label' className='property-field'>
												<FormattedMessage id='common.font_size' />
											</Typography>
											<div className='property-content'>
												<Slider initial_value={parseInt(state?.interaction?.components?.wheel?.style?.fontSize)} onUpdate={value => updateWheelFontSize(value)} maxValue={30} minValue={5} units='px' inputStep={1} />
											</div>
										</div>
										<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' px={1} sx={{
                    paddingRight: '36px'
                  }}>
											<Box>
												<Typography variant='body2'>
													<FormattedMessage id='edition_menu.game.wheel.field.perpendicular_text' />
												</Typography>
											</Box>

											<IOSSwitch checked={state.interaction.components.wheel.properties.perpendicularText} onChange={({
                      target: {
                        checked
                      }
                    }) => updateInteractionComponent({
                      component: 'wheel',
                      field: 'properties.perpendicularText',
                      value: checked
                    })} color='primary' />
										</Box>
										<div className='property-line'>
											<Typography variant='body1' component='label' className='property-field'>
												<FormattedMessage id='edition_menu.game.wheel.field.wheel_border.size' />
											</Typography>
											<div className='property-content'>
												<Slider initial_value={parseInt(state?.interaction?.components?.wheel?.style?.radiusLineWidth)} onUpdate={value => updateInteractionComponent({
                        component: 'wheel',
                        field: 'style.radiusLineWidth',
                        value
                      })} maxValue={20} minValue={0} units='px' inputStep={1} />
											</div>
										</div>
										<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.wheel_border.color' />} value={state?.interaction?.components?.wheel?.style?.radiusLineColor} updateValue={value => updateInteractionComponent({
                    component: 'wheel',
                    field: 'style.radiusLineColor',
                    value
                  })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />

										<Typography variant='h2' mt={2}>
											<FormattedMessage id='edition_menu.game.wheel.field.center_circle.header' />
										</Typography>
										<div className='property-line'>
											<Typography variant='body1' component='label' className='property-field'>
												<FormattedMessage id='common.size' />
											</Typography>
											<div className='property-content'>
												<Slider initial_value={parseInt(state?.interaction?.components?.wheel?.style?.innerRadius)} onUpdate={value => updateInteractionComponent({
                        component: 'wheel',
                        field: 'style.innerRadius',
                        value
                      })} maxValue={10} minValue={0} units='px' inputStep={1} />
											</div>
										</div>
										<div className='property-line'>
											<Typography variant='body1' component='label' className='property-field'>
												<FormattedMessage id='edition_menu.game.wheel.field.wheel_border.size' />
											</Typography>
											<div className='property-content'>
												<Slider initial_value={parseInt(state?.interaction?.components?.wheel?.style?.innerBorderWidth)} onUpdate={value => updateInteractionComponent({
                        component: 'wheel',
                        field: 'style.innerBorderWidth',
                        value
                      })} maxValue={20} minValue={0} units='px' inputStep={1} />
											</div>
										</div>
										<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.wheel_border.color' />} value={state?.interaction?.components?.wheel?.style?.innerBorderColor} updateValue={value => updateInteractionComponent({
                    component: 'wheel',
                    field: 'style.innerBorderColor',
                    value
                  })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
									</Box>
									{/* Play Button */}
									<Box>
										<Typography variant='h2'>
											<FormattedMessage id='edition_menu.game.wheel.field.button_play' />
										</Typography>
										<div className='vertical-property-line'>
											<div className='vertical-property-field'>
												<Typography variant='body1' component='label'>
													<FormattedMessage id='common.title' />
												</Typography>
											</div>
											<div className='vertical-property-content'>
												<TextFieldEmoji textValue={state.interaction.components.button.properties.title} onChange={value => updateInteractionComponent({
                        component: 'button',
                        field: 'properties.title',
                        value
                      })} />
											</div>
										</div>
										<div className='property-line'>
											<Typography variant='body1' component='label' className='property-field'>
												<FormattedMessage id='common.font_size' />
											</Typography>
											<div className='property-content'>
												<Slider initial_value={parseInt(state?.interaction?.components?.button?.style?.fontSize)} onUpdate={value => updateInteractionComponent({
                        component: 'button',
                        field: 'style.fontSize',
                        value
                      })} maxValue={20} minValue={0} units='px' inputStep={1} />
											</div>
										</div>
										<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.text_colors' />} value={state?.interaction?.components?.button?.style?.color} updateValue={value => updateInteractionComponent({
                    component: 'button',
                    field: 'style.color',
                    value
                  })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
										<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.background_colors' />} value={state?.interaction?.components?.button?.style?.backgroundColor} updateValue={value => updateInteractionComponent({
                    component: 'button',
                    field: 'style.backgroundColor',
                    value
                  })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />

										<div className='property-line'>
											<Typography variant='body1' component='label' className='property-field'>
												<FormattedMessage id='edition_menu.game.wheel.field.wheel_border.size' />
											</Typography>
											<div className='property-content'>
												<Slider initial_value={parseInt(state?.interaction?.components?.button?.style?.borderWidth)} onUpdate={value => updateInteractionComponent({
                        component: 'button',
                        field: 'style.borderWidth',
                        value: `${value}px`
                      })} maxValue={20} minValue={0} units='px' inputStep={1} />
											</div>
										</div>
										<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.wheel_border.color' />} value={state?.interaction?.components?.button?.style?.borderColor} updateValue={value => updateInteractionComponent({
                    component: 'button',
                    field: 'style.borderColor',
                    value
                  })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
										<div className='property-line'>
											<Typography variant='body1' component='label' className='property-field'>
												<FormattedMessage id='common.radius' />
											</Typography>
											<div className='property-content'>
												<Slider initial_value={parseInt(state?.interaction?.components?.button?.style?.borderRadius)} onUpdate={value => updateInteractionComponent({
                        component: 'button',
                        field: 'style.borderRadius',
                        value: `${value}px`
                      })} maxValue={60} minValue={0} units='px' inputStep={1} />
											</div>
										</div>
									</Box>
								</Box>
							</AccordionDetails>
						</Accordion>
						{/* Options List */}
						<Box width='100%' mt={3}>
							<DragDropContext onDragEnd={onAnswersDragEnd}>
								<Droppable droppableId='droppable'>
									{(provided, snapshot) => <div {...provided.droppableProps} ref={provided.innerRef}>
											{_.map(state.interaction.components.wheel.properties.prizes, (prize, index) => <Draggable key={prize._id} draggableId={prize._id} index={index}>
														{(provided, snapshot) => <WheelOptionSettingsContainer key={prize._id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} $isDragging={snapshot.isDragging}>
																<div className='drag-handle'>
																	<DragIndicator />
																</div>

																<Box width='100%'>
																	<Box width='100%' display='grid' gridTemplateColumns='2fr 100px 1.5fr 1fr' gap={1}>
																		<TextField label={<FormattedMessage id='edition_menu.game.wheel.prizes.field.prize_label' />} value={prize.option} onChange={({
                            target: {
                              value
                            }
                          }) => updateWheelOption({
                            prizeId: prize._id,
                            field: 'option',
                            value
                          })} InputLabelProps={{
                            shrink: true
                          }} />
																		<TextField label={<FormattedMessage id='edition_menu.game.wheel.prizes.field.weight' />} value={prize.weight} onChange={({
                            target: {
                              value
                            }
                          }) => updateWheelOption({
                            prizeId: prize._id,
                            field: 'weight',
                            value: Number(value)
                          })} InputLabelProps={{
                            shrink: true
                          }} InputProps={{
                            inputProps: {
                              min: 0
                            },
                            endAdornment: <InputAdornment sx={{
                              fontSize: '11px',
                              color: '#aaa'
                            }} disableTypography position='end'>
																						{getPercentProbability(state.interaction.components.wheel.properties.prizes, prize.weight)}
																					</InputAdornment>
                          }} />
																		<TextField label={<FormattedMessage id='edition_menu.game.wheel.prizes.field.code' />} value={prize.code || ''} onChange={({
                            target: {
                              value
                            }
                          }) => updateWheelOption({
                            prizeId: prize._id,
                            field: 'code',
                            value
                          })} InputLabelProps={{
                            shrink: true
                          }} />
																		<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' px={1}>
																			<Box display='flex' gap={0.5} alignItems='center'>
																				<Typography variant='body2'>
																					<FormattedMessage id='edition_menu.game.wheel.prizes.field.losing_option' />
																				</Typography>
																				<Tooltip title={<FormattedMessage id='modals.hotspot.game.lose_option.message' />} placement='bottom'>
																					<HelpOutline fontSize='small' />
																				</Tooltip>
																			</Box>

																			<IOSSwitch checked={prize.isLose} onChange={({
                              target: {
                                checked
                              }
                            }) => updateWheelOption({
                              prizeId: prize._id,
                              field: 'isLose',
                              value: checked
                            })} color='primary' />
																		</Box>
																	</Box>
																	<Box width='100%' display='flex' gap={1}>
																		<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.background_colors' />} value={prize.style.backgroundColor} updateValue={value => updateWheelOption({
                            prizeId: prize._id,
                            field: 'style.backgroundColor',
                            value
                          })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} displayRecentColors={false} />

																		<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.text_colors' />} value={prize.style.textColor} updateValue={value => updateWheelOption({
                            prizeId: prize._id,
                            field: 'style.textColor',
                            value
                          })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} displayRecentColors={false} />
																	</Box>
																</Box>

																<Box display='flex' flexDirection='column' gap={2}>
																	<Tooltip title={state.interaction.components.wheel.properties.prizes?.length <= 2 ? intl.messages['button.delete'] : intl.messages['edition_menu.form.field.delete_error']} placement='bottom'>
																		<div>
																			<IconButton size='small' disabled={state.interaction.components.wheel.properties.prizes?.length <= 2} onClick={() => removeWheelOption(prize?._id)}>
																				<Delete />
																			</IconButton>
																		</div>
																	</Tooltip>
																	<Tooltip title={intl.messages['button.duplicate']} placement='bottom'>
																		<IconButton size='small' onClick={() => duplicateWheelOption(prize?._id)}>
																			<ContentCopyOutlined />
																		</IconButton>
																	</Tooltip>
																</Box>
															</WheelOptionSettingsContainer>}
													</Draggable>)}
											{provided.placeholder}
										</div>}
								</Droppable>
							</DragDropContext>
							<Button variant='contained' color='primary' onClick={addWheelOption} startIcon={<Add />}>
								<FormattedMessage id='edition_menu.game.wheel.add_prizes' />
							</Button>
						</Box>
					</Box>;
      case 'form':
        return <>
						<Box width='100%'>
							<Box width='100%' display='grid' gridTemplateColumns='1fr 1fr' gap={2}>
								<Box>
									<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' px={2}>
										<Box>
											<Typography variant='h2'>
												<FormattedMessage id='edition_menu.game.wheel.modal.form_enable' />
											</Typography>
											<Typography variant='body1'>
												<FormattedMessage id='edition_menu.game.wheel.modal.form_description' />
											</Typography>
										</Box>

										<IOSSwitch checked={state.modal.form.isActive} onChange={({
                    target: {
                      checked
                    }
                  }) => updateModalProperty({
                    modal: 'form',
                    field: 'isActive',
                    value: checked
                  })} color='primary' />
									</Box>
									<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' px={2}>
										<Box width='70%'>
											<Typography variant='h2'>
												<FormattedMessage id='edition_menu.game.wheel.email_variable' />
											</Typography>
										</Box>
										{state?.modal?.form?.variable ? <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    p: 0.5,
                    m: 1,
                    border: '0.5px solid rgb(72, 72, 71)',
                    borderRadius: 1
                  }}>
												<Typography variant='h3' sx={{
                      mx: 0.5
                    }}>
													{state?.modal?.form?.variable}
												</Typography>
												<IconButton size='small' onClick={openVariablesModal}>
													<Edit />
												</IconButton>
												<IconButton size='small' onClick={() => unassignVariable(state?.modal?.form?.variable)}>
													<Close />
												</IconButton>
											</Box> : <Button variant='contained' color='info' size='small' onClick={openVariablesModal} sx={{
                    flexShrink: 0,
                    m: 1
                  }}>
												<FormattedMessage id='edition_menu.variables.add' values={{
                      count: 1
                    }} />
											</Button>}
									</Box>
								</Box>
								<Box>
									<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' px={2}>
										<Box>
											<Typography variant='h2'>
												<FormattedMessage id='edition_menu.form.field_type.consent' />
											</Typography>
											<Collapse in={gdprConsent.active}>
												<Box ml='auto' width='fit-content'>
													<Button variant='outlined' size='small' onClick={() => gdprSettingsModalRef.current.open()} startIcon={<Settings />}>
														<FormattedMessage id='edition_menu.form.field_description.advanced_settings.gdprConsent' />
													</Button>

													<GdprSettingsModal ref={gdprSettingsModalRef} gdprConsent={gdprConsent} handleChangeGdprConsent={handleChangeGdprConsent} />
												</Box>
											</Collapse>
										</Box>

										<IOSSwitch checked={gdprConsent?.active} onChange={({
                    target: {
                      checked
                    }
                  }) => handleChangeGdprConsent({
                    path: 'active',
                    value: checked
                  })} color='primary' />
									</Box>
								</Box>
								{/* Email variable */}
							</Box>

							<Box width='100%' display='grid' gridTemplateColumns='1fr 1fr' gap={2}>
								<Box>
									{/* Form title */}
									<div className='vertical-property-line'>
										<div className='vertical-property-field'>
											<Typography variant='body1' component='label'>
												<FormattedMessage id='common.title' />
											</Typography>
										</div>
										<div className='vertical-property-content'>
											<TextFieldVariables minRows={1} textValue={state.modal.form.title} onChange={value => updateModalProperty({
                      modal: 'form',
                      field: 'title',
                      value
                    })} variableParams={variableParams} localVariable={WHEEL_VARIABLE} />
										</div>
									</div>

									{/* Form description */}
									<div className='vertical-property-line'>
										<div className='vertical-property-field'>
											<Typography variant='body1' component='label'>
												<FormattedMessage id='form.description' />
											</Typography>
										</div>
										<div className='vertical-property-content'>
											<TextFieldVariables minRows={1} textValue={state.modal.form.description} onChange={value => updateModalProperty({
                      modal: 'form',
                      field: 'description',
                      value
                    })} variableParams={variableParams} localVariable={WHEEL_VARIABLE} />
										</div>
									</div>

									{/* Input placeholder */}
									<div className='vertical-property-line'>
										<div className='vertical-property-field'>
											<Typography variant='body1' component='label'>
												<FormattedMessage id='common.placeholder' />
											</Typography>
										</div>
										<div className='vertical-property-content'>
											<TextFieldEmoji textValue={state.modal.form.placeholder} onChange={value => updateModalProperty({
                      modal: 'form',
                      field: 'placeholder',
                      value
                    })} />
										</div>
									</div>

									<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.text_colors' />} value={state?.modal?.form?.style?.color} updateValue={value => updateModalProperty({
                  modal: 'form',
                  field: 'style.color',
                  value
                })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
									<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.background_colors' />} value={state?.modal?.form?.style?.backgroundColor} updateValue={value => updateModalProperty({
                  modal: 'form',
                  field: 'style.backgroundColor',
                  value
                })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
								</Box>
								<Box>
									{/* Submit button */}
									<div className='vertical-property-line'>
										<div className='vertical-property-field'>
											<Typography variant='body1' component='label'>
												<FormattedMessage id='edition_menu.rating.modal.form_submit_btn' />
											</Typography>
										</div>
										<div className='vertical-property-content'>
											<TextFieldEmoji textValue={state.modal.form.submit_button} onChange={value => updateModalProperty({
                      modal: 'form',
                      field: 'submit_button',
                      value
                    })} />
										</div>
									</div>

									<div className='property-line'>
										<Typography variant='body1' component='label' className='property-field'>
											<FormattedMessage id='common.font_size' />
										</Typography>
										<div className='property-content'>
											<Slider initial_value={parseInt(state?.interaction?.components?.button_submit?.style?.fontSize)} onUpdate={value => updateInteractionComponent({
                      component: 'button_submit',
                      field: 'style.fontSize',
                      value
                    })} maxValue={20} minValue={0} units='px' inputStep={1} />
										</div>
									</div>
									<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.text_colors' />} value={state?.interaction?.components?.button_submit?.style?.color} updateValue={value => updateInteractionComponent({
                  component: 'button_submit',
                  field: 'style.color',
                  value
                })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
									<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.background_colors' />} value={state?.interaction?.components?.button_submit?.style?.backgroundColor} updateValue={value => updateInteractionComponent({
                  component: 'button_submit',
                  field: 'style.backgroundColor',
                  value
                })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
								</Box>
							</Box>
						</Box>
						<VariablesModal ref={changeVariableRef} currentVariables={currentVariables} currentVariable={state?.modal?.form?.variable} allowType={getValidVariableTypes('page', state.currentPage)} assignVariable={assignVariable} removeVariable={removeVariable} addNewVariable={addVariable} newVariable={newVariable} handleChangeNewVariableName={handleNewVariableNameChange} handleChangeNewVariableType={handleNewVariableTypeChange} fieldError={fieldError} onClose={onCloseVariablesModal} />
					</>;
      case 'winning':
        return <Box width='100%' display='grid' gridTemplateColumns='1fr 1fr' gap={2}>
						<Box>
							{/* Pop-up title */}
							<div className='vertical-property-line'>
								<div className='vertical-property-field'>
									<Typography variant='body1' component='label'>
										<FormattedMessage id='common.title' />
									</Typography>
								</div>
								<div className='vertical-property-content'>
									<TextFieldVariables minRows={1} textValue={state.modal.winning.title} onChange={value => updateModalProperty({
                  modal: 'winning',
                  field: 'title',
                  value
                })} variableParams={variableParams} localVariable={WHEEL_VARIABLE} />
								</div>
							</div>
							{/* Image */}
							<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' px={1} sx={{
              paddingRight: '36px'
            }}>
								<Box>
									<Typography variant='body2'>
										<FormattedMessage id='common.image' />
									</Typography>
								</Box>

								<ImagesSelectorModal trigger={<ImagePreview style={{
                width: '200px'
              }} inputValue={state.modal.winning.img_url} />} inputValue={state.modal.winning.img_url} onSelect={onImageChange} onUploadSuccess={onUploadSuccess} />
							</Box>
							{/* Description */}
							<div className='vertical-property-line'>
								<div className='vertical-property-field'>
									<Typography variant='body1' component='label'>
										<FormattedMessage id='form.description' />
									</Typography>
								</div>
								<div className='vertical-property-content'>
									<TextFieldVariables minRows={1} textValue={state.modal.winning.description} onChange={value => updateModalProperty({
                  modal: 'winning',
                  field: 'description',
                  value
                })} variableParams={variableParams} localVariable={WHEEL_VARIABLE} />
								</div>
							</div>
							{/* Display Code Switcher */}
							<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' px={1} sx={{
              paddingRight: '36px'
            }}>
								<Box>
									<Typography variant='body2'>
										<FormattedMessage id='edition_menu.game.wheel.modal.code' />
									</Typography>
								</Box>

								<IOSSwitch checked={state.modal.winning.hasCode} onChange={({
                target: {
                  checked
                }
              }) => updateModalProperty({
                modal: 'winning',
                field: 'hasCode',
                value: checked
              })} color='primary' />
							</Box>
							<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.text_colors' />} value={state?.modal?.winning?.style?.color} updateValue={value => updateModalProperty({
              modal: 'winning',
              field: 'style.color',
              value
            })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
							<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.background_colors' />} value={state?.modal?.winning?.style?.backgroundColor} updateValue={value => updateModalProperty({
              modal: 'winning',
              field: 'style.backgroundColor',
              value
            })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
						</Box>
						<Box>
							{/* Next button title */}
							<div className='vertical-property-line'>
								<div className='vertical-property-field'>
									<Typography variant='body1' component='label'>
										<FormattedMessage id={`edition_menu.game.wheel.button_next.header`} />
									</Typography>
								</div>
								<div className='vertical-property-content'>
									<TextFieldEmoji textValue={state.interaction.components.button_next.properties.title} onChange={value => updateInteractionComponent({
                  component: 'button_next',
                  field: 'properties.title',
                  value
                })} />
								</div>
							</div>

							<div className='property-line'>
								<Typography variant='body1' component='label' className='property-field'>
									<FormattedMessage id='common.font_size' />
								</Typography>
								<div className='property-content'>
									<Slider initial_value={parseInt(state?.interaction?.components?.button_next?.style?.fontSize)} onUpdate={value => updateInteractionComponent({
                  component: 'button_next',
                  field: 'style.fontSize',
                  value
                })} maxValue={20} minValue={0} units='px' inputStep={1} />
								</div>
							</div>
							<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.text_colors' />} value={state?.interaction?.components?.button_next?.style?.color} updateValue={value => updateInteractionComponent({
              component: 'button_next',
              field: 'style.color',
              value
            })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
							<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.background_colors' />} value={state?.interaction?.components?.button_next?.style?.backgroundColor} updateValue={value => updateInteractionComponent({
              component: 'button_next',
              field: 'style.backgroundColor',
              value
            })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />

							{/* Display redirect button switcher */}
							<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' px={1} sx={{
              paddingRight: '36px'
            }}>
								<Box>
									<Typography variant='body2'>
										<FormattedMessage id='edition_menu.game.wheel.modal.redirection_button' />
									</Typography>
								</Box>

								<IOSSwitch checked={state.modal.winning?.cta?.isActive} onChange={({
                target: {
                  checked
                }
              }) => updateModalProperty({
                modal: 'winning',
                field: 'cta.isActive',
                value: checked
              })} color='primary' />
							</Box>
							<Collapse in={state.modal.winning.cta?.isActive}>
								{/* Redirect button title */}
								<div className='vertical-property-line'>
									<div className='vertical-property-field'>
										<Typography variant='body1' component='label'>
											<FormattedMessage id='edition_menu.game.wheel.modal.redirection_button' />
										</Typography>
									</div>
									<div className='vertical-property-content'>
										<TextFieldEmoji textValue={state.modal.winning.cta.title} onChange={value => updateModalProperty({
                    modal: 'winning',
                    field: 'cta.title',
                    value
                  })} />
									</div>
								</div>
								{/* Redirect URL */}
								<div className='vertical-property-line'>
									<div className='vertical-property-field'>
										<Typography variant='body1' component='label'>
											<FormattedMessage id='edition_menu.navigation.button_type.link' />
										</Typography>
									</div>
									<div className='vertical-property-content'>
										<TextFieldVariables minRows={1} textValue={state.modal.winning?.cta?.url} onChange={value => updateModalProperty({
                    modal: 'winning',
                    field: 'description',
                    value
                  })} variableParams={variableParams} localVariable={WHEEL_VARIABLE} />
									</div>
								</div>

								<div className='property-line'>
									<Typography variant='body1' component='label' className='property-field'>
										<FormattedMessage id='common.font_size' />
									</Typography>
									<div className='property-content'>
										<Slider initial_value={parseInt(state?.interaction?.components?.button_link?.style?.fontSize)} onUpdate={value => updateInteractionComponent({
                    component: 'button_link',
                    field: 'style.fontSize',
                    value
                  })} maxValue={20} minValue={0} units='px' inputStep={1} />
									</div>
								</div>
								<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.text_colors' />} value={state?.interaction?.components?.button_link?.style?.color} updateValue={value => updateInteractionComponent({
                component: 'button_link',
                field: 'style.color',
                value
              })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
								<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.background_colors' />} value={state?.interaction?.components?.button_link?.style?.backgroundColor} updateValue={value => updateInteractionComponent({
                component: 'button_link',
                field: 'style.backgroundColor',
                value
              })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
							</Collapse>
						</Box>
					</Box>;
      case 'losing':
        return <Box width='100%' display='grid' gridTemplateColumns='1fr 1fr' gap={2}>
						<Box>
							{/* Pop-up title */}
							<div className='vertical-property-line'>
								<div className='vertical-property-field'>
									<Typography variant='body1' component='label'>
										<FormattedMessage id='common.title' />
									</Typography>
								</div>
								<div className='vertical-property-content'>
									<TextFieldVariables minRows={1} textValue={state.modal.losing.title} onChange={value => updateModalProperty({
                  modal: 'losing',
                  field: 'title',
                  value
                })} variableParams={variableParams} localVariable={WHEEL_VARIABLE} />
								</div>
							</div>
							{/* Image */}
							<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' px={1} sx={{
              paddingRight: '36px'
            }}>
								<Box>
									<Typography variant='body2'>
										<FormattedMessage id='common.image' />
									</Typography>
								</Box>

								<ImagesSelectorModal trigger={<ImagePreview style={{
                width: '200px'
              }} inputValue={state.modal.losing.img_url} />} inputValue={state.modal.losing.img_url} onSelect={onImageChange} onUploadSuccess={onUploadSuccess} />
							</Box>
							{/* Description */}
							<div className='vertical-property-line'>
								<div className='vertical-property-field'>
									<Typography variant='body1' component='label'>
										<FormattedMessage id='form.description' />
									</Typography>
								</div>
								<div className='vertical-property-content'>
									<TextFieldVariables minRows={1} textValue={state.modal.losing.description} onChange={value => updateModalProperty({
                  modal: 'losing',
                  field: 'description',
                  value
                })} variableParams={variableParams} localVariable={WHEEL_VARIABLE} />
								</div>
							</div>
							<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.text_colors' />} value={state?.modal?.losing?.style?.color} updateValue={value => updateModalProperty({
              modal: 'losing',
              field: 'style.color',
              value
            })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
							<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.background_colors' />} value={state?.modal?.losing?.style?.backgroundColor} updateValue={value => updateModalProperty({
              modal: 'losing',
              field: 'style.backgroundColor',
              value
            })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
						</Box>
						<Box>
							{/* Next button title */}
							<div className='vertical-property-line'>
								<div className='vertical-property-field'>
									<Typography variant='body1' component='label'>
										<FormattedMessage id={`edition_menu.game.wheel.button_next.header`} />
									</Typography>
								</div>
								<div className='vertical-property-content'>
									<TextFieldEmoji textValue={state.interaction.components.button_next.properties.title} onChange={value => updateInteractionComponent({
                  component: 'button_next',
                  field: 'properties.title',
                  value
                })} />
								</div>
							</div>

							<div className='property-line'>
								<Typography variant='body1' component='label' className='property-field'>
									<FormattedMessage id='common.font_size' />
								</Typography>
								<div className='property-content'>
									<Slider initial_value={parseInt(state?.interaction?.components?.button_next?.style?.fontSize)} onUpdate={value => updateInteractionComponent({
                  component: 'button_next',
                  field: 'style.fontSize',
                  value
                })} maxValue={20} minValue={0} units='px' inputStep={1} />
								</div>
							</div>
							<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.text_colors' />} value={state?.interaction?.components?.button_next?.style?.color} updateValue={value => updateInteractionComponent({
              component: 'button_next',
              field: 'style.color',
              value
            })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
							<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.background_colors' />} value={state?.interaction?.components?.button_next?.style?.backgroundColor} updateValue={value => updateInteractionComponent({
              component: 'button_next',
              field: 'style.backgroundColor',
              value
            })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />

							{/* Display redirect button switcher */}
							<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' px={1} sx={{
              paddingRight: '36px'
            }}>
								<Box>
									<Typography variant='body2'>
										<FormattedMessage id='edition_menu.game.wheel.modal.redirection_button' />
									</Typography>
								</Box>

								<IOSSwitch checked={state.modal.losing?.cta?.isActive} onChange={({
                target: {
                  checked
                }
              }) => updateModalProperty({
                modal: 'losing',
                field: 'cta.isActive',
                value: checked
              })} color='primary' />
							</Box>
							<Collapse in={state.modal.losing.cta?.isActive}>
								{/* Redirect button title */}
								<div className='vertical-property-line'>
									<div className='vertical-property-field'>
										<Typography variant='body1' component='label'>
											<FormattedMessage id='edition_menu.game.wheel.modal.redirection_button' />
										</Typography>
									</div>
									<div className='vertical-property-content'>
										<TextFieldEmoji textValue={state.modal.losing.cta.title} onChange={value => updateModalProperty({
                    modal: 'losing',
                    field: 'cta.title',
                    value
                  })} />
									</div>
								</div>
								{/* Redirect URL */}
								<div className='vertical-property-line'>
									<div className='vertical-property-field'>
										<Typography variant='body1' component='label'>
											<FormattedMessage id='edition_menu.navigation.button_type.link' />
										</Typography>
									</div>
									<div className='vertical-property-content'>
										<TextFieldVariables minRows={1} textValue={state.modal.losing?.cta?.url} onChange={value => updateModalProperty({
                    modal: 'losing',
                    field: 'description',
                    value
                  })} variableParams={variableParams} localVariable={WHEEL_VARIABLE} />
									</div>
								</div>

								<div className='property-line'>
									<Typography variant='body1' component='label' className='property-field'>
										<FormattedMessage id='common.font_size' />
									</Typography>
									<div className='property-content'>
										<Slider initial_value={parseInt(state?.interaction?.components?.button_link?.style?.fontSize)} onUpdate={value => updateInteractionComponent({
                    component: 'button_link',
                    field: 'style.fontSize',
                    value
                  })} maxValue={20} minValue={0} units='px' inputStep={1} />
									</div>
								</div>
								<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.text_colors' />} value={state?.interaction?.components?.button_link?.style?.color} updateValue={value => updateInteractionComponent({
                component: 'button_link',
                field: 'style.color',
                value
              })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
								<ColorInput label={<FormattedMessage id='edition_menu.game.wheel.field.background_colors' />} value={state?.interaction?.components?.button_link?.style?.backgroundColor} updateValue={value => updateInteractionComponent({
                component: 'button_link',
                field: 'style.backgroundColor',
                value
              })} recentColors={recentColors} brandColors={brandColors} updateRecentColors={updateRecentColor} />
							</Collapse>
						</Box>
					</Box>;
      default:
        return null;
    }
  }
  return <>
			<WheelSettingsContainer>
				<Box width='100%' height='100%' display='flex' justifyContent='center' alignItems='center' overflow='hidden'>
					<PagePreview size='large' page={{
          ...currentPage,
          interactions: [state.interaction],
          modal: state.modal
        }} theming={draftStory.theming} messages={draftStory.messages} gdprConsent={draftStory?.gdprConsent} storyType={draftStory.type} isRewardsEnabled wheelComponentState={tab} />
				</Box>

				<Box width='100%' height='100%' overflow='hidden'>
					<Box display='flex' alignItems='center' gap={1} mb={2}>
						<CustomTabs value={tab} indicatorColor='secondary' textColor='primary' onChange={(evt, value) => setTab(value)} sx={{
            width: '800px!important'
          }}>
							<CustomTab label={<FormattedMessage id='edition_menu.game.component.title.wheel' />} value='options' />
							<CustomTab label={<FormattedMessage id='edition_menu.game.component.title.form' />} value='form' />
							<CustomTab label={<FormattedMessage id='edition_menu.game.component.title.winning' />} value='winning' />
							<CustomTab label={<FormattedMessage id='edition_menu.game.component.title.losing' />} value='losing' />
						</CustomTabs>
					</Box>
					<Box width='100%' height='100%' overflow='auto' sx={{
          paddingBottom: '80px',
          paddingRight: '8px',
          scrollbarGutter: 'stable'
        }}>
						{renderTabContent()}
					</Box>
				</Box>
			</WheelSettingsContainer>

			<Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
				{onPreviousStep ? <Button variant='text' onClick={onPreviousStep} startIcon={<ChevronLeft />}>
						<FormattedMessage id='button.previous' />
					</Button> : onCancel ? <Button variant='text' onClick={onCancel}>
						<FormattedMessage id='button.cancel' />
					</Button> : <div />}

				<Button data-intercom-target='Confirm Button' variant='contained' onClick={handleSubmit}>
					<FormattedMessage id='common.theming.confirm_button' />
				</Button>
			</Box>
		</>;
}