import styled from '@styled';
import PropTypes from 'prop-types';
import SubmitButton from './SubmitButton';
export default function InstagramButton({
  children,
  ...delegatedProps
}) {
  return <StyledInstagramButton {...delegatedProps}>{children}</StyledInstagramButton>;
}
InstagramButton.propTypes = {
  children: PropTypes.node
};
const StyledInstagramButton = styled(SubmitButton)`
	width: max-content;
	color: #fff;
	border: none;

	background: radial-gradient(
			circle farthest-corner at 0% 150%,
			rgb(255, 225, 125) 0%,
			rgb(255, 205, 105) 10%,
			rgb(250, 145, 55) 18%,
			rgb(235, 65, 65) 38%,
			transparent 95%
		),
		linear-gradient(-15deg, rgb(35, 75, 215) -10%, rgb(195, 60, 190) 65%);
	background-size: 100% 120%;
`;