import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { InsertEmoticonTwoTone } from '@mui/icons-material';
import { Box, IconButton, Input, Popover } from '@mui/material';
import styled from '@styled';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import PropTypes from 'prop-types';
import React, { useEffect, useId, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
export default function TextFieldEmoji({
  textValue,
  placeholder,
  disableUnderline = false,
  onChange
}) {
  const uniqueId = useId();
  const [text, setText] = useState(textValue);
  const [cursorSelector, setCursorSelector] = useState(text?.length || 0);
  const delayedCursorPosition = useDebouncedCallback(handleCursorPosition, 1000);
  const popupStateEmojiPicker = usePopupState({
    variant: 'popover',
    popupId: `emoji-picker-${uniqueId}`
  });
  useEffect(() => {
    setText(textValue);
  }, [textValue]);
  function handleOnChange(evt) {
    onChange(evt.target.value);
    setText(evt.target.value);
    delayedCursorPosition(evt);
  }
  function addStrAtCursor(str, index, stringToAdd) {
    return `${str.substring(0, index)}${stringToAdd}${str.substring(index, str.length)}`;
  }
  function onEmojiClick(emoji) {
    const addEmoji = addStrAtCursor(text, cursorSelector, emoji.native);
    setText(addEmoji);
    onChange(addEmoji);
    popupStateEmojiPicker.close();
  }
  function handleCursorPosition(evt) {
    setCursorSelector(evt.target.selectionStart);
  }
  return <>
			<TextFieldContainer>
				<Input fullWidth multiline maxRows={5} disableUnderline={disableUnderline} placeholder={placeholder} value={text} onChange={handleOnChange} onClick={handleCursorPosition} />
				<IconButton size='small' {...bindTrigger(popupStateEmojiPicker)}>
					<InsertEmoticonTwoTone />
				</IconButton>
			</TextFieldContainer>

			<Popover {...bindPopover(popupStateEmojiPicker)} anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }} transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}>
				<Box width='280px'>
					<Picker data={data} onEmojiSelect={onEmojiClick} perLine={7} exceptEmojis={['recent']} />
				</Box>
			</Popover>
		</>;
}
TextFieldEmoji.propTypes = {
  textValue: PropTypes.string,
  placeholder: PropTypes.string,
  disableUnderline: PropTypes.bool,
  onChange: PropTypes.func
};
const TextFieldContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	svg {
		height: 20px;
		width: 20px;
	}
`;