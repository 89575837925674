import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
const MaterialDragHandle = forwardRef(({
  className,
  title
}, dragRef) => {
  return <span ref={dragRef} className={className} title={title}>
			<DragIndicatorIcon />
		</span>;
});
MaterialDragHandle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string
};
MaterialDragHandle.displayName = 'MaterialDragHandle';
export default MaterialDragHandle;