import { block as _block$ } from "million/react-server";
import { PhotoLibrary, VideoLibrary } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { StoryBubbleContainer } from './BannerBubble.style';
const MediaPlaceholder = memo(function MediaPlaceholder({
  icon
}) {
  return <div style={{
    backgroundCOlor: 'red'
  }}>{icon}</div>;
});
MediaPlaceholder.propTypes = {
  icon: PropTypes.node
};
const M$StoryBubble = function StoryBubble({
  thumbnail,
  thumbnailType,
  children
}) {
  const renderMedia = () => {
    if (thumbnailType === 'image') {
      return thumbnail ? <img src={thumbnail} /> : <MediaPlaceholder icon={<PhotoLibrary />} />;
    }
    return thumbnail ? <video src={thumbnail} autoPlay loop muted /> : <MediaPlaceholder icon={<VideoLibrary />} />;
  };
  return <StoryBubbleContainer>{children || renderMedia()}</StoryBubbleContainer>;
};
const StoryBubble = M$StoryBubble;
export default StoryBubble;
StoryBubble.propTypes = {
  thumbnail: PropTypes.string,
  thumbnailType: PropTypes.string,
  children: PropTypes.node
};