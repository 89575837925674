import { InputLabel, Select } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { DropdownContainer } from './LogicGroupDropdown.style';
export default function LogicGroupDropdown({
  labelId,
  label,
  options,
  value,
  isVariables = false,
  onChange,
  placeholder,
  useFontFamilies = false,
  fullWidth = true,
  nullable = false,
  maxWidth,
  ...delegatedProps
}) {
  const intl = useIntl();
  // handle error with disableUnderLine
  const {
    variant
  } = delegatedProps;
  const restProps = variant === 'outlined' ? delegatedProps : {
    ...delegatedProps,
    disableUnderline: true
  };
  const [open, setOpen] = useState(false);
  function handleOpen(evt) {
    setOpen(true);
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    evt.preventDefault();
  }
  function handleClose(evt) {
    setOpen(false);
    evt.stopPropagation();
    evt.nativeEvent.stopImmediatePropagation();
    evt.preventDefault();
  }
  return <DropdownContainer $maxWidth={maxWidth} fullWidth={fullWidth}>
			{label && labelId && <InputLabel id={labelId}>{label}</InputLabel>}
			<Select native open={open} labelId={labelId} value={JSON.stringify(value)} onOpen={handleOpen} onClose={handleClose} onChange={onChange} displayEmpty={true} error={_.isEmpty(value)} {...restProps}>
				{placeholder && <option value=''>{placeholder}</option>}
				{renderItems(options)}
			</Select>
		</DropdownContainer>;
  function renderItems(options) {
    return _.map(options, (option, index) => {
      if (option.type === 'group') {
        return <optgroup key={index} label={intl.messages[`conditions.consequence_group.${option.value}`]}>
						{_.map(option.options, (opt, idx) => <option value={JSON.stringify(opt)} key={idx}>
								{option.value === 'variable' ? opt.name : intl.messages[`common.consequence_ops.${isVariables ? opt.name : opt.value}`]}
							</option>)}
					</optgroup>;
      } else {
        return <option value={JSON.stringify(option)} key={index}>
						{option.type === 'variable' ? option.name : intl.messages[`common.consequence_ops.${isVariables ? option?.name : option.value}`]}
					</option>;
      }
    });
  }
}
LogicGroupDropdown.propTypes = {
  labelId: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  isVariables: PropTypes.bool,
  fullWidth: PropTypes.bool,
  nullable: PropTypes.bool
};