import { Box, CircularProgress, Pagination } from '@mui/material';
import SearchBar from 'atoms/SearchBar';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getAppLocale } from 'redux/customer/selectors';
import PexelsApi from 'services/externalMedia/PexelsSvc';
import useAsyncEffect from 'use-async-effect';
import { useDebouncedCallback } from 'use-debounce';
import { LoaderContainer, PaginationContainer } from './Galleries.style';
import ImageList from './MediaComponent/ImageList';
import VideoList from './MediaComponent/VideoList';
export default function PexelsGallery({
  handleUrl,
  search_type = 'photos',
  is_background
}) {
  const intl = useIntl();
  const appLocale = useSelector(getAppLocale);
  const [term, setTerm] = useState('');
  const [last_search, setLastSearch] = useState('');
  const [list, setList] = useState([]);
  const [page_count, setPageCount] = useState(0);
  const [is_loading, setLoad] = useState(false);
  const per_page = 50;
  const debouncedSearch = useDebouncedCallback(onSearchSubmit, 500);
  const pexelsApi = new PexelsApi(search_type, appLocale, is_background);
  useAsyncEffect(async isMounted => {
    // Get Trending
    if (term === '') {
      if (isMounted()) {
        setLoad(true);
        setPageCount(0);
      }
      const {
        data
      } = await pexelsApi.getTrending({
        per_page,
        page: 1
      });
      if (isMounted()) {
        setPageCount(Math.round(data.total_results / per_page));
        setList(_.get(data, search_type));
        setLoad(false);
      }
    }
  }, [term, search_type]);
  function onSearchChange(value) {
    setTerm(value);
    // Reset and block request if search term reset
    if (value === '') {
      // setPageCount(0)
    } else {
      debouncedSearch(value);
    }
  }
  async function onSearchSubmit(query) {
    setLoad(true);
    setPageCount(0);
    const {
      data
    } = await pexelsApi.search({
      query,
      per_page
    });
    setPageCount(Math.round(data.total_results / per_page));
    setLastSearch(query);
    setList(_.get(data, search_type));
    setLoad(false);
  }
  async function getOtherPage(e, pageIndex) {
    const apiMethod = term === '' ? 'getTrending' : 'search';
    setLoad(true);
    const {
      data
    } = await pexelsApi[apiMethod]({
      query: last_search,
      per_page,
      page: pageIndex
    });
    setList(_.get(data, search_type));
    setLoad(false);
  }
  return <>
			<Box width='100%'>
				<SearchBar fullWidth variant='standard' disableUnderline sx={{
        maxWidth: 'unset'
      }} value={term} onChange={onSearchChange} placeholder={intl.messages[`edition_menu.pexel_placeholder.${search_type}`]} />
			</Box>
			{is_loading ? <LoaderContainer>
					<CircularProgress disableShrink />
				</LoaderContainer> : search_type === 'photos' ? <ImageList images={list} onSelect={handleUrl} listType='pexels' /> : <VideoList videos={list} onSelect={handleUrl} listType='pexels' />}
			{page_count > 0 && <PaginationContainer>
					<Pagination size='medium' count={page_count} variant='outlined' color='primary' onChange={getOtherPage} shape='rounded' />
				</PaginationContainer>}
		</>;
}
PexelsGallery.propTypes = {
  handleUrl: PropTypes.func,
  search_type: PropTypes.string,
  is_background: PropTypes.bool
};