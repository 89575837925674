import { forwardRef } from 'react';
import { BannerBubble } from './BannerBubble';
import { BannerCard } from './BannerCard';
const BannerWidgetElement = forwardRef(function BannerWidgetElement({
  widget,
  story,
  ...delegatedProps
}, ref) {
  if (widget.banner_type === 'card') {
    return <BannerCard ref={ref} {...delegatedProps} widget={widget} story={story} />;
  }
  return <BannerBubble ref={ref} {...delegatedProps} widget={widget} story={story} />;
});
export default BannerWidgetElement;