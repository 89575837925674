import { ModalWrapper } from 'components/Modals';
import PageFormSettings from 'components/PageFormSettings';
import { useStoryDispatch, useStoryState } from 'lib/editorStore';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
export default function FormSettingsModal({
  trigger
}) {
  const ref = useRef();
  const draftStory = useStoryState(s => s.draftStory);
  const page = useStoryState(s => s.page);
  const dispatch = useStoryDispatch();
  function updateForm(data) {
    dispatch({
      type: 'UPDATE_PAGE_FORM',
      data
    });
  }
  function onCancel() {
    ref.current.close();
  }
  function onSubmitConfig({
    new_page,
    variables,
    newInteraction,
    gdprConsent
  }) {
    updateForm({
      newForm: newInteraction,
      variables,
      gdprConsent
    });
    ref.current.close();
  }
  return <ModalWrapper title={<FormattedMessage id='modals.interaction_settings.form' />} trigger={trigger} disableBackdropClick hasCloseButton={false} size='lg' ref={ref} PaperProps={{
    sx: {
      height: '100%'
    }
  }}>
			<PageFormSettings onCancel={onCancel} onSubmitConfig={onSubmitConfig} currentPage={page} draftStory={draftStory} />
		</ModalWrapper>;
}
FormSettingsModal.propTypes = {
  trigger: PropTypes.object
};