import styled from '@styled';
import _ from 'lodash';
import PropTypes from 'prop-types';
import TagsBase from './TagsBase';
export default function Tags({
  page
}) {
  return <TagsContainer>
			{_.map(page.tags, tag => <TagsBase key={tag._id} {...tag} />)}
		</TagsContainer>;
}
Tags.propTypes = {
  page: PropTypes.object
};
const TagsContainer = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	box-sizing: border-box;
	pointer-events: none;
	z-index: 2;
`;