import axios from 'axios'
import { API_URL, FACEBOOK_GRAPH_API_URL } from 'lib/constants'
import castArray from 'lodash/castArray'
import flatMap from 'lodash/flatMap'
import includes from 'lodash/includes'
import map from 'lodash/map'
import * as localStorageSvc from 'services/LocalStorageSvc'

const DEFAULT_BATCH_SIZE = 50
const MEDIA_FIELDS = [
	'media_url',
	'media_type',
	'thumbnail_url',
	'media_product_type',
	'comments_count',
	'like_count',
	'permalink',
	'children{media_type,media_url,thumbnail_url}',
].join(',')

const getRequestConfig = (assetsType) => {
	switch (assetsType) {
		case 'posts':
			return {
				edge: 'media',
				fields: MEDIA_FIELDS,
				surfaces: ['FEED'],
			}
		case 'stories':
			return {
				edge: 'stories',
				fields: MEDIA_FIELDS,
				surfaces: ['STORY'],
			}
		case 'reels':
			return {
				edge: 'media',
				fields: MEDIA_FIELDS,
				surfaces: ['REELS'],
			}
		default:
			throw new Error(`Unknown assets type: ${assetsType}`)
	}
}

const InstagramSvc = {
	updateAccounts,
	deleteAccount,
	getAssets,
	getAssetsByType,
}
export default InstagramSvc

async function updateAccounts({ organization_id, project_id, access_token, fb_user_id }) {
	const { data } = await axios.post(
		`${API_URL}/integrations/instagram`,
		{
			organization_id,
			project_id,
			access_token,
			fb_user_id,
		},
		{
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		}
	)

	if (!data.success) {
		throw new Error('Request failed')
	}

	return data.organization
}

async function deleteAccount({ page_id, organization_id, project_id }) {
	const { data } = await axios.delete(`${API_URL}/integrations/instagram`, {
		params: {
			organization_id,
			project_id,
			page_id,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})

	if (!data.success) {
		throw new Error('Request failed')
	}

	return data.organization
}

async function getAssetsByType({ account, assetsType, mediaTypes, next }) {
	if (!account?.instagramId) {
		return []
	}

	const { edge, fields, surfaces } = getRequestConfig(assetsType)

	const url = next ?? `${FACEBOOK_GRAPH_API_URL}/${account.instagramId}/${edge}`
	const params = next
		? {}
		: {
				fields,
				access_token: account.longLivedToken,
				limit: DEFAULT_BATCH_SIZE,
		  }

	const {
		data: { data: assets, paging },
	} = await axios({ url, params })

	const filteredAssets = filterAssets({
		assets,
		mediaTypes,
		surfaces,
	})

	return {
		assets: filteredAssets,
		paging,
	}
}

async function getAssets({ account, assetsTypes, mediaTypes }) {
	return Promise.all(
		map(assetsTypes, async (assetsType) => {
			try {
				const { assets, paging } = await getAssetsByType({
					account,
					assetsType,
					mediaTypes,
				})

				return {
					assetsType,
					assets,
					paging,
					error: undefined,
				}
			} catch (axiosError) {
				const error = axiosError.response?.data?.error
				console.log('Error', error || axiosError)
				return {
					assetsType,
					assets: [],
					paging: undefined,
					error,
				}
			}
		})
	)
}

// Filter assets by media type and surface (media product type)
function filterAssets({ assets, mediaTypes = ['IMAGE', 'VIDEO'], surfaces }) {
	// media_url may be omitted from responses if the media contains copyrighted material,
	// or has been flagged for a copyright violation.
	const filter = (asset) => {
		const isValidUrl = Boolean(asset.media_url)
		const isAcceptedSurface = includes(surfaces, asset.media_product_type)
		const isAcceptedMediaType = castArray(mediaTypes).includes(asset.media_type)
		return isValidUrl && isAcceptedSurface && isAcceptedMediaType ? [asset] : []
	}

	return flatMap(assets, (asset) =>
		asset.media_type === 'CAROUSEL_ALBUM'
			? flatMap(asset.children.data, (child) =>
					filter({
						...child,
						media_product_type: asset.media_product_type,
						comments_count: asset.comments_count,
						like_count: asset.like_count,
						permalink: asset.permalink,
					})
			  )
			: filter(asset)
	)
}
