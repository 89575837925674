import styled from '@styled';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
export default function AmpFormPreview({
  content,
  success,
  submit,
  isForm = false,
  cta_text,
  styles,
  theme
}) {
  function renderElement(element) {
    switch (element.type) {
      case 'input':
        switch (element.sub_type) {
          case 'consent':
            return <div key={element._id} className='field-container'>
								<label className='checkbox-container'>
									<input type='checkbox' name='consent' value='true' className='form-checkbox' />
									<div className='checkbox-label'>
										<p>{element.label}</p>
									</div>
								</label>
							</div>;
          case 'textLong':
            return <div key={element._id} className='field-container'>
								{element?.label && <label className='form-label'>{element.label}</label>}
								<textarea className='form-input' placeholder={element.placeholder} id={element?.variable || element._id} type={getInputType(element.sub_type)} name={element.sub_type} />
							</div>;
          default:
            return <div key={element._id} className='field-container'>
								{element?.label && <label className='form-label'>{element.label}</label>}
								<input className='form-input' placeholder={element.placeholder} id={element?.variable || element._id} type={getInputType(element.sub_type)} name={element.sub_type} />
							</div>;
        }
      case 'content':
        switch (element.sub_type) {
          case 'title':
            {
              const textWithBreaks = element.value.split('\n').map((line, index, array) => <Fragment key={index}>
								{line}
								{index < array.length - 1 && <br />}
							</Fragment>);
              const styles = getTextElementStyles(element);
              return <h2 key={element._id} style={styles}>
								{textWithBreaks}
							</h2>;
            }
          case 'paragraph':
            {
              // Map each line to a <p> element with <br> for line breaks
              const textWithBreaks = element.value.split('\n').map((line, index, array) => <Fragment key={index}>
								{line}
								{index < array.length - 1 && <br />}
							</Fragment>);
              const styles = getTextElementStyles(element);
              return <p key={element._id} style={styles}>
								{textWithBreaks}
							</p>;
            }
          case 'disclamer':
            {
              // Map each line to a <p> element with <br> for line breaks
              const textWithBreaks = element.value.split('\n').map((line, index, array) => <Fragment key={index}>
								{line}
								{index < array.length - 1 && <br />}
							</Fragment>);
              const styles = getTextElementStyles(element);
              return <p key={element._id} style={styles}>
								{textWithBreaks}
							</p>;
            }
          case 'button':
            return <button key={element._id} className='submit-button' style={element.styles}>
								{element.value}
							</button>;
          case 'image':
            return <img key={element._id} className='element-img' src={element.value} />;
        }
    }
  }
  return <FormWrapper $fontFamily={styles.fontFamily} $theme={theme}>
			<div className='modal-header'>
				<div className='header-title-and-close'>
					<span className='attachment-title'>{cta_text}</span>
				</div>
				<div className='attachment-domain-label'>api.snackeet.link</div>
			</div>
			<div className='form-wrapper'>
				{isForm ? <>
						{content.map(element => renderElement(element))}

						<button className='submit-button' style={submit.styles}>
							{submit.title}
						</button>
					</> : success.map(element => renderElement(element))}
			</div>
		</FormWrapper>;
}
AmpFormPreview.propTypes = {
  content: PropTypes.array,
  success: PropTypes.array,
  submit: PropTypes.object,
  isForm: PropTypes.bool,
  cta_text: PropTypes.string,
  styles: PropTypes.object,
  theme: PropTypes.string
};

// Styled components
const FormWrapper = styled.div`
	width: 370px;
	max-height: 100%;
	border-radius: 16px;
	box-shadow: -2px -4px 32px -2px rgba(0, 0, 0, 0.38);
	background-color: ${({
  $theme
}) => $theme === 'dark' ? 'rgb(32, 33, 37)' : 'rgb(250, 250, 250)'};
	font-family: ${({
  $fontFamily
}) => $fontFamily};
	overflow: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	.form-wrapper {
		width: 100%;
		background-color: ${({
  $theme
}) => $theme === 'dark' ? 'rgb(32, 33, 37)' : 'rgb(250, 250, 250)'};
		padding: 8px 16px 16px;
		border-bottom-right-radius: 16px;
		border-bottom-left-radius: 16px;
		color: ${({
  $theme
}) => $theme === 'light' ? 'rgb(32, 33, 37)' : 'rgb(250, 250, 250)'};
	}

	h2 {
		font-size: 1.75rem;
		line-height: 2rem;
		font-weight: bold;
		margin: 8px 0px;
		font-family: inherit;
	}

	p {
		font-size: 1rem;
		line-height: 1.25rem;
		margin: 8px 0px;
		font-family: inherit;
	}

	.element-img {
		width: 100%;
		height: auto;
		margin: 8px 0;
		border-radius: 4px;
	}

	.modal-header {
		background-color: ${({
  $theme
}) => $theme === 'dark' ? 'rgb(32, 33, 37)' : 'rgb(255, 255, 255)'};
		color: ${({
  $theme
}) => $theme === 'light' ? 'rgb(32, 33, 37)' : 'rgb(250, 250, 250)'};
		padding-bottom: 40px;
		display: flex;
		justify-content: center;
		position: sticky;
		top: 0;
		border-top-right-radius: 16px;
		border-top-left-radius: 16px;
	}
	.header-title-and-close {
		height: 44px;
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		border-top-right-radius: 16px;
		border-top-left-radius: 16px;
		box-shadow: 0px 0px 1px #ccc;
	}
	.attachment-title {
		display: block;
		position: absolute;
		width: calc(100% - 80px);
		font-size: 18px;
		font-family: 'Roboto', sans-serif;
		font-weight: bold;
		line-height: 40px;
		overflow: hidden;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.attachment-domain-label {
		background-color: ${({
  $theme
}) => $theme === 'dark' ? 'rgb(32, 33, 37)' : 'rgb(255, 255, 255)'};
		color: ${({
  $theme
}) => $theme === 'light' ? 'rgb(32, 33, 37)' : 'rgb(250, 250, 250)'};
		display: block;
		font-family: 'Roboto', sans-serif;
		font-size: 15px;
		font-weight: 300;
		overflow: hidden;
		position: absolute;
		text-align: center;
		text-overflow: ellipsis;
		top: 35px;
		white-space: nowrap;
		width: calc(100% - 112px);
	}

	.field-container {
		margin: 8px 0px;
	}

	.form-label {
		display: block;
		font-size: 0.875rem;
		line-height: 1.25rem;
		font-family: inherit;
		font-weight: 500;
		margin-bottom: 6px;
		color: ${({
  $theme
}) => $theme === 'light' ? 'rgb(63 63 70)' : 'rgb(250, 250, 250)'};
	}

	.form-input {
		width: 100%;
		border: 2px solid #ccc;
		border-radius: 0.375rem;
		padding-left: 1rem;
		padding-right: 1rem;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		outline: none;
		font-family: inherit;
		pointer-events: none;
	}
	.form-input:focus {
		border-color: rgb(99 102 241);
	}

	.form-checkbox {
		display: block;
		height: 24px;
		width: 24px;
		cursor: pointer;
		border: 2px solid #ccc;
		border-radius: 0.375rem;
		color: rgb(99 102 241);
	}
	.form-checkbox:focus {
		border-color: rgb(99 102 241);
		color: rgb(99 102 241);
	}
	.checkbox-container {
		display: grid;
		grid-template-columns: 24px auto;
		grid-gap: 12px;
	}
	.checkbox-label {
		p {
			margin: 0.25rem 0 0;
			color: ${({
  $theme
}) => $theme === 'light' ? 'rgb(63 63 70)' : 'rgb(250, 250, 250)'};
			font-size: 0.875rem;
			line-height: 1.25rem;
		}
	}

	.submit-container {
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 24px 0px 8px;
	}

	.submit-button {
		width: 100%;
		margin: 16px auto 8px;
		padding: 8px 16px;
		min-height: 48px;
		font-family: inherit;
	}
`;

// helper function
function getInputType(sub_type) {
  switch (sub_type) {
    case 'email':
      return 'email';
    case 'number':
      return 'number';
    case 'phone':
      return 'tel';
    case 'website':
      return 'url';
    case 'date':
      return 'date';
    default:
      return 'text';
  }
}
function getTextElementStyles(element) {
  return _.pick(element?.styles, ['fontSize', 'color']);
}