import { initGdprConsent } from 'lib/constants'
import { useStoryState } from 'lib/editorStore'
import castArray from 'lodash/castArray'
import { useCallback } from 'react'
import { useImmer } from 'use-immer'

export default function useGDPR() {
	const storyLanguage = useStoryState((s) => s.draftStory?.story_settings?.language)
	const draftGdprConsent = useStoryState((s) => s.draftStory?.gdprConsent)
	const [gdprConsent, setGdprConsent] = useImmer(initGdprConsent(draftGdprConsent, storyLanguage))

	const handleChangeGdprConsent = useCallback(
		(operations) => {
			if (!operations) return

			setGdprConsent((draft) => {
				for (const operation of castArray(operations)) {
					draft[operation.path] = operation.value
				}
			})
		},
		[setGdprConsent]
	)

	return {
		gdprConsent,
		handleChangeGdprConsent,
	}
}
