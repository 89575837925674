import { DEFAULT_TIMER_VALUE, DEFAULT_VOLUME } from 'lib/constants'
import * as translation from 'lib/constants/InteractionTranslation'
import { generateVariableName } from 'lib/utils'
import _ from 'lodash'
import { nanoid } from 'nanoid'
import { snackeet_blue } from 'styles/colors'

import { buttonStyles } from './buttonStyles'

export function getBlockInitialValue({
	type,
	subType,
	buttonType,
	textType = 'paragraph',
	initialProperties = {},
	leaderboard_id,
}) {
	const defaultBlock = {
		_id: nanoid(),
		type,
		value: '',
		outer_style: {},
		animation: {
			name: '',
			duration: 1000,
			delay: 0,
			forever: false,
			displayDelay: 0,
		},
		keepRatio: false,
	}

	switch (type) {
		case 'text':
			switch (textType) {
				case 'heading_1':
					defaultBlock.value = '<h1>Heading 1</h1>'
					defaultBlock.inner_style = {
						color: 'rgba(0, 0, 0, 1)',
						backgroundColor: 'rgba(255, 255, 255, 0)',
						fontSize: '32px',
						fontFamily: 'Roboto',
						justifyContent: 'center',
						textAlign: 'center',
						lineHeight: '1.25',
						paddingLeft: '6px',
						paddingTop: '6px',
						paddingRight: '6px',
						paddingBottom: '6px',
					}
					defaultBlock.outer_style = {
						width: '250px',
						height: 'auto',
						opacity: '100%',
						transform: 'translateX(25px) translateY(35px) rotate(0deg)',
						top: '0px',
						left: '0px',
						borderColor: 'rgba(0, 0, 0, 1)',
						borderStyle: 'solid',
						borderRadius: '0px',
						borderWidth: '0px',
					}
					break
				case 'heading_2':
					defaultBlock.value = '<h2>Heading 2</h2>'
					defaultBlock.inner_style = {
						color: 'rgba(0, 0, 0, 1)',
						backgroundColor: 'rgba(255, 255, 255, 0)',
						fontSize: '24px',
						fontFamily: 'Roboto',
						justifyContent: 'center',
						textAlign: 'center',
						lineHeight: '1.25',
						paddingLeft: '6px',
						paddingTop: '6px',
						paddingRight: '6px',
						paddingBottom: '6px',
					}
					defaultBlock.outer_style = {
						width: '250px',
						height: 'auto',
						opacity: '100%',
						transform: 'translateX(25px) translateY(35px) rotate(0deg)',
						top: '0px',
						left: '0px',
						borderColor: 'rgba(0, 0, 0, 1)',
						borderStyle: 'solid',
						borderRadius: '0px',
						borderWidth: '0px',
					}
					break
				case 'heading_3':
					defaultBlock.value = `<h3>Heading 3</h3>`
					defaultBlock.inner_style = {
						color: 'rgba(0, 0, 0, 1)',
						backgroundColor: 'rgba(255, 255, 255, 0)',
						fontSize: '20px',
						fontFamily: 'Roboto',
						justifyContent: 'center',
						textAlign: 'center',
						lineHeight: '1.25',
						paddingLeft: '6px',
						paddingTop: '6px',
						paddingRight: '6px',
						paddingBottom: '6px',
					}
					defaultBlock.outer_style = {
						width: '250px',
						height: 'auto',
						opacity: '100%',
						transform: 'translateX(25px) translateY(35px) rotate(0deg)',
						top: '0px',
						left: '0px',
						borderColor: 'rgba(0, 0, 0, 1)',
						borderStyle: 'solid',
						borderRadius: '0px',
						borderWidth: '0px',
					}
					break
				case 'paragraph':
					defaultBlock.value = `<p>Paragraph</p>`
					defaultBlock.inner_style = {
						color: 'rgba(0, 0, 0, 1)',
						backgroundColor: 'rgba(255, 255, 255, 0)',
						fontSize: '16px',
						fontFamily: 'Roboto',
						justifyContent: 'center',
						textAlign: 'center',
						lineHeight: '1.25',
						paddingLeft: '6px',
						paddingTop: '6px',
						paddingRight: '6px',
						paddingBottom: '6px',
					}
					defaultBlock.outer_style = {
						width: '250px',
						height: 'auto',
						opacity: '100%',
						transform: 'translateX(25px) translateY(35px) rotate(0deg)',
						top: '0px',
						left: '0px',
						borderColor: 'rgba(0, 0, 0, 1)',
						borderStyle: 'solid',
						borderRadius: '0px',
						borderWidth: '0px',
					}
					break
				case 'first_text':
					defaultBlock.value = '<h1>Question</h1>'
					defaultBlock.inner_style = {
						color: 'rgba(0, 0, 0, 1)',
						backgroundColor: 'rgba(255, 255, 255, 0)',
						fontSize: '32px',
						fontFamily: 'Roboto',
						justifyContent: 'center',
						textAlign: 'center',
						lineHeight: '1.25',
						paddingLeft: '6px',
						paddingTop: '6px',
						paddingRight: '6px',
						paddingBottom: '6px',
					}
					defaultBlock.outer_style = {
						width: '359px',
						height: 'auto',
						opacity: '100%',
						transform: 'translateX(0px) translateY(150px) rotate(0deg)',
						top: '0px',
						left: '0px',
						borderColor: 'rgba(0, 0, 0, 1)',
						borderStyle: 'solid',
						borderRadius: '0px',
						borderWidth: '0px',
					}
					break
				default:
					defaultBlock.inner_style = {
						color: 'rgba(0, 0, 0, 1)',
						backgroundColor: 'rgba(255, 255, 255, 0)',
						fontSize: '16px',
						fontFamily: 'Roboto',
						justifyContent: 'center',
						textAlign: 'center',
						lineHeight: '1.25',
						paddingLeft: '6px',
						paddingTop: '6px',
						paddingRight: '6px',
						paddingBottom: '6px',
					}
					defaultBlock.outer_style = {
						width: '200px',
						height: 'auto',
						opacity: '100%',
						transform: 'translateX(25px) translateY(35px) rotate(0deg)',
						top: '0px',
						left: '0px',
						borderColor: 'rgba(0, 0, 0, 1)',
						borderStyle: 'solid',
						borderRadius: '0px',
						borderWidth: '0px',
					}
			}
			defaultBlock.effect = 'none'

			if (subType === 'button') {
				_.assign(defaultBlock, buttonStyles.text[buttonType])
			}
			break
		case 'image':
			defaultBlock.value = ''
			defaultBlock.inner_style = {
				backgroundSize: 'cover',
				backgroundPosition: '50% 50%',
			}
			defaultBlock.outer_style = {
				opacity: '100%',
				transform: 'translateX(25px) translateY(35px) rotate(0deg)',
				top: '0px',
				left: '0px',
				width: '270px',
				height: '170px',
				borderColor: 'rgba(255, 255, 255, 1)',
				borderStyle: 'solid',
				borderRadius: '4px',
				borderWidth: '0px',
				overflow: 'hidden',
			}
			break
		case 'video':
			defaultBlock.value = ''
			defaultBlock.inner_style = {
				objectFit: 'cover',
				objectPosition: '50% 50%',
			}
			defaultBlock.outer_style = {
				transform: 'translateX(25px) translateY(35px) rotate(0deg)',
				top: '0px',
				left: '0px',
				opacity: '100%',
				width: '270px',
				height: '170px',
				borderColor: 'rgba(0, 0, 0, 1)',
				borderStyle: 'solid',
				borderRadius: '0px',
				borderWidth: '0px',
				overflow: 'hidden',
			}
			defaultBlock.props = {
				start: undefined,
				end: undefined,
				volume: DEFAULT_VOLUME,
				autoplay: true,
				loop: true,
			}
			defaultBlock.subtitles_props = {
				snapToLines: false,
				isActive: false,
				line: 90,
				subtitles: undefined,
			}
			break
		case 'calendar':
			defaultBlock.value = ''
			defaultBlock.outer_style = {
				transform: 'translateX(0px) translateY(0px) rotate(0deg)',
				top: '0px',
				left: '0px',
				width: '359px',
				height: '445px',
				borderColor: 'rgba(0, 0, 0, 1)',
				borderStyle: 'solid',
				borderRadius: '0px',
				borderWidth: '0px',
			}
			defaultBlock.required = true
			break
		case 'iframe':
			defaultBlock.value = ''
			defaultBlock.outer_style = {
				transform: 'translateX(40px) translateY(100px) rotate(0deg)',
				top: '0px',
				left: '0px',
				width: '270px',
				height: '400px',
				borderColor: 'rgba(0, 0, 0, 1)',
				borderStyle: 'solid',
				borderRadius: '0px',
				borderWidth: '0px',
			}
			break
		case 'leaderboard':
			defaultBlock.value = leaderboard_id
			defaultBlock.outer_style = {
				transform: 'translateX(0px) translateY(60px) rotate(0deg)',
				top: '0px',
				left: '0px',
				width: '359px',
				height: '445px',
				borderColor: 'rgba(0, 0, 0, 1)',
				borderStyle: 'solid',
				borderRadius: '0px',
				borderWidth: '0px',
			}
			break
		case 'countdown':
			defaultBlock.value = new Date()
			defaultBlock.title = 'Enter your title'
			defaultBlock.titleColor = 'rgba(0, 0, 0, 1)'
			defaultBlock.size = 'medium'
			defaultBlock.fontFamily = 'Roboto'
			defaultBlock.bgColor = 'rgba(255, 255, 255, 1)'
			defaultBlock.numberFontFamily = 'Roboto'
			defaultBlock.numberBgColor = 'rgba(0, 0, 0, 0.2)'
			defaultBlock.numberColor = 'rgba(0, 0, 0, 1)'
			defaultBlock.subTitleFontFamily = 'Roboto'
			defaultBlock.subTitleColor = 'rgba(0, 0, 0, 1)'
			defaultBlock.isDisplaySeconds = true
			defaultBlock.isDisplayTitle = true
			defaultBlock.days = {
				title: 'Days',
			}
			defaultBlock.hours = {
				title: 'Hours',
			}
			defaultBlock.mins = {
				title: 'Minutes',
			}
			defaultBlock.seconds = {
				title: 'Seconds',
			}
			defaultBlock.outer_style = {
				transform: 'translateX(20px) translateY(120px) rotate(0deg)',
				top: '0px',
				left: '0px',
				width: '320px',
				height: '120px',
				borderColor: 'rgba(0, 0, 0, 1)',
				borderStyle: 'solid',
				borderRadius: '4px',
				borderWidth: '0px',
			}
			break
		case 'location':
			defaultBlock.value = ''
			defaultBlock.outer_style = {
				transform: 'translateX(25px) translateY(35px) rotate(0deg)',
				top: '0px',
				left: '0px',
				width: '270px',
				height: '170px',
				borderColor: 'rgba(0, 0, 0, 1)',
				borderStyle: 'solid',
				borderRadius: '0px',
				borderWidth: '0px',
			}
			break
		case 'shape':
			defaultBlock.value = 'rect'
			defaultBlock.outer_style = {
				transform: 'translateX(10px) translateY(25px) rotate(0deg)',
				top: '0px',
				left: '0px',
				width: '80px',
				height: '80px',
				opacity: '100%',
				color: 'rgba(62, 62, 62, 1)',
				backgroundColor: 'rgba(255, 255, 255, )',
				borderStyle: 'solid',
				borderWidth: '0px',
				borderColor: 'rgba(0, 0, 0, 0)',
				borderRadius: '0px',
			}

			if (subType === 'button') {
				_.assign(defaultBlock, buttonStyles.shape[buttonType])
			}
			break
		case 'icon':
			defaultBlock.value = 'https://static.snackeet.com/snackeet_assets/V2/social_network/014-instagram.png'
			defaultBlock.inner_style = {
				color: 'rgba(62, 62, 62, 1)',
				fontSize: '30px',
			}
			defaultBlock.outer_style = {
				transform: 'translateX(10px) translateY(25px) rotate(0deg)',
				top: '0px',
				left: '0px',
				width: '60px',
				height: '60px',
				opacity: '100%',
				backgroundColor: 'rgba(255, 255, 255, 1)',
				borderStyle: 'solid',
				borderWidth: '0px',
				borderColor: 'rgba(0, 0, 0, 0)',
				borderRadius: '40px',
			}

			if (subType === 'button') {
				_.assign(defaultBlock, buttonStyles.icon[buttonType])
			}
			break
		case 'social-network':
			defaultBlock.value = 'social'
			defaultBlock.outer_style = {
				color: '#000000',
				fontSize: '18px',
				fontWeight: 'normal',
				padding: '8px 0',
				width: '220px',
				height: 'auto',
				textAlign: 'center',
				lineHeight: 'normal',
				borderColor: 'rgba(0, 0, 0, 1)',
				borderStyle: 'solid',
				borderRadius: '0px',
				borderWidth: '0px',
				transform: 'translateX(70px) translateY(250px) rotate(0deg)',
			}
			defaultBlock.networks = ['Facebook', 'Instagram', 'Twitter']
			defaultBlock.links = {
				facebook: '',
				instagram: '',
				twitter: '',
			}
			defaultBlock.iconSize = 50
			break
		case 'share':
			defaultBlock.value = 'share'
			defaultBlock.outer_style = {
				color: '#000000',
				fontSize: '18px',
				fontWeight: 'normal',
				padding: '8px 0',
				width: '220px',
				height: 'auto',
				textAlign: 'center',
				lineHeight: 'normal',
				borderColor: 'rgba(0, 0, 0, 1)',
				borderStyle: 'solid',
				borderRadius: '0px',
				borderWidth: '0px',
				transform: 'translateX(70px) translateY(250px) rotate(0deg)',
			}
			defaultBlock.networks = ['Facebook', 'Whatsapp', 'Twitter']
			defaultBlock.iconSize = 50
			break
		case 'messenger':
			defaultBlock.value = ''
			defaultBlock.outer_style = {
				width: '220px',
				height: 'auto',
				backgroundColor: 'rgba(47, 49, 97, 1)',
				borderColor: 'rgba(0, 0, 0, 1)',
				borderStyle: 'solid',
				borderRadius: '5px',
				borderWidth: '0px',
				transform: 'translateX(70px) translateY(250px) rotate(0deg)',
			}
			defaultBlock.iconSize = 50
			defaultBlock.platforms = [
				{
					name: 'Phonecall',
					properties_fields: {
						number: '',
					},
				},
				{
					name: 'SMS',
					properties_fields: {
						number: '',
						message: '',
					},
				},
			]
			break
		case 'networks':
			defaultBlock.value = ''
			defaultBlock.outer_style = {
				width: '220px',
				height: 'auto',
				backgroundColor: 'rgba(47, 49, 97, 1)',
				color: 'rgba(255, 255, 255, 1)',
				borderColor: 'rgba(0, 0, 0, 1)',
				borderStyle: 'solid',
				borderRadius: '5px',
				borderWidth: '0px',
				transform: 'translateX(70px) translateY(250px) rotate(0deg)',
			}
			defaultBlock.iconSize = 50
			defaultBlock.hasShadow = true
			defaultBlock.platforms = [
				{
					name: 'Phonecall',
					properties_fields: {
						number: '',
					},
				},
				{
					name: 'SMS',
					properties_fields: {
						number: '',
						message: '',
					},
				},
				{
					name: 'Facebook',
					properties_fields: {
						link: '',
					},
				},
			]
			break
		default:
			break
	}

	for (const key in initialProperties) {
		initialProperties[key] && _.set(defaultBlock, key, initialProperties[key])
	}

	return defaultBlock
}

export function getBackgroundElementInitialValue(type) {
	const backgroundElement = {
		type,
		value: '',
		dimmer_opacity: '0%',
		dimmer_colors: ['rgba(0, 0, 0, 1)'],
		dimmer_gradient: {
			type: 'linear',
			direction: 0,
		},
	}

	switch (type) {
		case 'color':
			backgroundElement.value = 'rgba(255, 255, 255, 1)'
			backgroundElement.outer_style = {
				borderColor: 'rgba(255, 255, 255, 1)',
				borderStyle: 'solid',
				borderWidth: '0px',
			}
			backgroundElement.gradient_props = {
				enabled: false,
				gradient: {
					type: 'linear',
					direction: 45,
					colors: ['rgba(255,214,52,1)', 'rgba(239,18,158,1)'],
				},
			}
			break
		case 'image':
			backgroundElement.inner_style = {
				objectFit: 'cover',
			}
			backgroundElement.outer_style = {
				borderColor: 'rgba(255, 255, 255, 1)',
				borderStyle: 'solid',
				borderWidth: '0px',
			}
			backgroundElement.animation = {
				name: '',
				duration: 15000,
				delay: 0,
				forever: false,
				displayDelay: 0,
			}
			break
		case 'video':
			backgroundElement.inner_style = {
				backgroundColor: 'rgba(255, 255, 255, 1)',
				objectFit: 'cover',
				objectPosition: '50% 50%',
			}
			backgroundElement.outer_style = {
				borderColor: 'rgba(255, 255, 255, 1)',
				borderStyle: 'solid',
				borderWidth: '0px',
			}
			backgroundElement.animation = {
				name: '',
				duration: 15000,
				delay: 0,
				forever: false,
				displayDelay: 0,
			}
			backgroundElement.props = {
				start: undefined,
				end: undefined,
				volume: DEFAULT_VOLUME,
				autoplay: true,
				loop: false,
			}
			backgroundElement.subtitles_props = {
				snapToLines: false,
				isActive: false,
				line: 90,
				subtitles: undefined,
			}
			break
		default:
			break
	}

	return backgroundElement
}

export function getInteractionInitialValue({ type, sub_type, lang, variables, messages }) {
	switch (type) {
		case 'form': {
			const initialForm = {
				_id: nanoid(),
				type: 'form',
				next_redirection: 'next',
				next_button: messages.buttons.next,
				send_button: messages.buttons.send,
				form_properties: {
					email_verification: false,
					unique_email: false,
					hasCaptcha: false,
					hasDisposableValidator: false,
				},
				contact: [],
				fields: [
					{
						_id: nanoid(),
						type: 'text',
						sub_type: 'text',
						variable: generateVariableName('text', variables),
						required: true,
						placeholder: translation.form_field_placeholder_text[lang],
						settings: {
							char_limit: false,
							char_number: 25,
						},
					},
				],
			}

			if (sub_type === 'contact_form') {
				initialForm.fields = [
					{
						_id: nanoid(),
						type: 'text',
						sub_type: 'text',
						variable: generateVariableName('text', variables),
						required: true,
						placeholder: translation.form_field_placeholder_text[lang],
						settings: {
							char_limit: false,
							char_number: 25,
						},
					},
					{
						_id: nanoid(),
						type: 'email',
						sub_type: 'email',
						variable: generateVariableName('email', variables),
						required: true,
						placeholder: translation.form_field_placeholder_email[lang],
						settings: {
							hasDefaultDomain: false,
							defaultDomains: [],
						},
					},
				]
			} else if (sub_type === 'contact_form_complet') {
				initialForm.fields = [
					{
						_id: nanoid(),
						type: 'text',
						sub_type: 'firstname',
						variable: generateVariableName('firstname', variables),
						required: true,
						placeholder: translation.form_field_placeholder_firstname[lang],
						settings: {
							char_limit: false,
							char_number: 25,
						},
					},
					{
						_id: nanoid(),
						type: 'text',
						sub_type: 'lastname',
						variable: generateVariableName('lastname', variables),
						required: true,
						placeholder: translation.form_field_placeholder_lastname[lang],
						settings: {
							char_limit: false,
							char_number: 25,
						},
					},
					{
						_id: nanoid(),
						type: 'email',
						sub_type: 'email',
						variable: generateVariableName('email', variables),
						required: true,
						placeholder: translation.form_field_placeholder_email[lang],
						settings: {
							hasDefaultDomain: false,
							defaultDomains: [],
						},
					},
					{
						_id: nanoid(),
						type: 'phone',
						sub_type: 'phone',
						variable: generateVariableName('phone', variables),
						required: true,
						placeholder: translation.form_field_placeholder_phone[lang],
						settings: {
							defaultCountry: 'fr',
						},
					},
					{
						_id: nanoid(),
						type: 'text',
						sub_type: 'text',
						variable: generateVariableName(sub_type, variables),
						required: true,
						placeholder: translation.form_field_placeholder_text[lang],
						settings: {
							char_limit: false,
							char_number: 25,
						},
					},
				]
			} else if (sub_type === 'leaderboard_form') {
				initialForm.fields = [
					{
						_id: nanoid(),
						type: 'text',
						sub_type: 'username',
						variable: generateVariableName('username', variables),
						required: true,
						placeholder: translation.form_field_placeholder_username[lang],
						settings: {
							char_limit: false,
							char_number: 25,
						},
					},
					{
						_id: nanoid(),
						type: 'email',
						sub_type: 'email',
						variable: generateVariableName('email', variables),
						required: true,
						placeholder: translation.form_field_placeholder_email[lang],
						settings: {
							hasDefaultDomain: false,
							defaultDomains: [],
						},
					},
				]
			} else if (sub_type === 'newsletter_form') {
				initialForm.fields = [
					{
						_id: nanoid(),
						type: 'email',
						sub_type: 'email',
						variable: generateVariableName('email', variables),
						required: true,
						placeholder: translation.form_field_placeholder_email[lang],
						settings: {
							hasDefaultDomain: false,
							defaultDomains: [],
						},
					},
					{
						_id: nanoid(),
						type: 'checkbox',
						sub_type: 'checkbox',
						variable: generateVariableName('checkbox', variables),
						required: true,
						title: translation.form_field_placeholder_checkbox[lang],
						settings: {},
					},
				]
			} else if (sub_type === 'event_registration') {
				initialForm.fields = [
					{
						_id: nanoid(),
						type: 'email',
						sub_type: 'email',
						variable: generateVariableName('email', variables),
						required: true,
						placeholder: translation.form_field_placeholder_email[lang],
						settings: {
							hasDefaultDomain: false,
							defaultDomains: [],
						},
					},
					{
						_id: nanoid(),
						type: 'date',
						sub_type: 'date',
						variable: generateVariableName('date', variables),
						required: true,
						placeholder: translation.form_field_placeholder_date[lang],
						settings: {
							format: 'DD/MM/YYYY',
							activeDateRange: false,
							minDate: new Date(),
							maxDate: new Date(),
						},
					},
				]
				// Basics Forms
			} else if (sub_type === 'number_form') {
				initialForm.fields = [
					{
						_id: nanoid(),
						type: 'number',
						sub_type: 'number',
						variable: generateVariableName('number', variables),
						required: true,
						placeholder: translation.form_field_placeholder_number[lang],
						settings: {
							activeMinMax: false,
							min: 0,
							max: 100,
							digit_limit: false,
							digit_number: 5,
						},
					},
				]
			} else if (sub_type === 'dropdown_form') {
				initialForm.fields = [
					{
						_id: nanoid(),
						type: 'select',
						sub_type: 'select',
						variable: generateVariableName('select', variables),
						required: true,
						placeholder: translation.form_field_placeholder_select[lang],
						settings: {
							multiple: false,
							limit_choices: false,
							limit: 4,
							options: [`New Option 1`, `New Option 2`],
						},
					},
				]
			} else if (sub_type === 'checkbox_form') {
				initialForm.fields = [
					{
						_id: nanoid(),
						type: 'checkbox',
						sub_type: 'checkbox',
						variable: generateVariableName('checkbox', variables),
						required: true,
						title: translation.form_field_placeholder_checkbox[lang],
						settings: {},
					},
				]
			} else if (sub_type === 'date_form') {
				initialForm.fields = [
					{
						_id: nanoid(),
						type: 'date',
						sub_type: 'date',
						variable: generateVariableName('date', variables),
						required: true,
						placeholder: translation.form_field_placeholder_date[lang],
						settings: {
							format: 'DD/MM/YYYY',
							activeDateRange: false,
							minDate: new Date(),
							maxDate: new Date(),
						},
					},
				]
			}

			return initialForm
		}
		case 'rating':
			return {
				_id: nanoid(),
				type: 'rating',
				required: false,
				hasForm: false,
				has_percentage: false,
				feedback_conditions: [],
				max: 5,
				title: translation.rating_title[lang],
				subtitle: '',
				category: 'classic_stars',
				style: {
					backgroundColor: 'rgba(255, 255, 255, 0)',
					color: 'rgba(72, 71, 71, 100)',
					fontFamily: 'Poppins',
					fontSize: '16px',
					subtileFontSize: '14px',
				},
			}
		case 'media_answer': {
			const defaultMediaAnswer = {
				_id: nanoid(),
				type: 'media_answer',
				title: translation.media_answer_title[lang],
				hasVideo: true,
				videoTitle: translation.media_answer_videoButton[lang],
				hasAudio: true,
				audioTitle: translation.media_answer_audioButton[lang],
				hasText: true,
				textTitle: translation.media_answer_textButton[lang],
				hasSelfie: true,
				selfieTitle: translation.media_answer_selfieButton[lang],
				duration: 120,
				required: false,
				messages: {
					start_button: translation.media_answer_start_button[lang],
					restart_button: translation.media_answer_restart_button[lang],
					confirm_button: translation.media_answer_confirm_button[lang],
					text_placeholder: translation.media_answer_text_placeholder[lang],
					modify_response: translation.media_answer_modify_response[lang],
					continue_button: translation.media_answer_continue_button[lang],
				},
				style: {
					color: '#FFFFFF',
					backgroundColor: '#2F3161',
					fontFamily: 'Poppins',
					fontSize: '14px',
				},
				containerStyle: {
					color: '#000000',
					backgroundColor: 'rgba(255, 255, 255, 0.6)',
					fontFamily: 'Poppins',
					fontSize: '16px',
				},
			}

			if (sub_type === 'media_answer_image') {
				defaultMediaAnswer.hasSelfie = true
				defaultMediaAnswer.hasAudio = false
				defaultMediaAnswer.hasVideo = false
				defaultMediaAnswer.hasText = false
			} else if (sub_type === 'media_answer_video') {
				defaultMediaAnswer.hasVideo = true
				defaultMediaAnswer.hasSelfie = false
				defaultMediaAnswer.hasAudio = false
				defaultMediaAnswer.hasText = false
			} else if (sub_type === 'media_answer_audio') {
				defaultMediaAnswer.hasAudio = true
				defaultMediaAnswer.hasSelfie = false
				defaultMediaAnswer.hasVideo = false
				defaultMediaAnswer.hasText = false
			}

			return defaultMediaAnswer
		}
		case 'game': {
			let initialGame = {
				_id: nanoid(),
				type: 'game',
			}
			switch (sub_type) {
				case 'wheel':
					initialGame = {
						...initialGame,
						sub_type,
						required: false,
						components: {
							wheel: {
								properties: {
									perpendicularText: false,
									textDistance: 60,
									prizes: [
										{
											_id: nanoid(),
											option: 'Option 1',
											weight: 1,
											isLose: false,
											style: {
												backgroundColor: '#BCF4F5',
												textColor: '#000000',
												fontWeight: 'normal',
												fontSize: 20,
												fontFamily: 'Poppins',
											},
										},
										{
											_id: nanoid(),
											option: 'Option 2',
											weight: 1,
											isLose: false,
											style: {
												backgroundColor: '#B4EBCA',
												textColor: '#000000',
												fontWeight: 'normal',
												fontSize: 20,
												fontFamily: 'Poppins',
											},
										},
										{
											_id: nanoid(),
											option: 'Option 3',
											weight: 1,
											isLose: false,
											style: {
												backgroundColor: '#D9F2B4',
												textColor: '#000000',
												fontWeight: 'normal',
												fontSize: 20,
												fontFamily: 'Poppins',
											},
										},
										{
											_id: nanoid(),
											option: 'Option 4',
											weight: 1,
											isLose: false,
											style: {
												backgroundColor: '#D3FAC7',
												textColor: '#000000',
												fontWeight: 'normal',
												fontSize: 20,
												fontFamily: 'Poppins',
											},
										},
									],
								},
								style: {
									textColors: ['#000', '#FFF'],
									backgroundColors: ['#D1F4FA', '#005792'],
									outerBorderColor: 'rgba(0, 0, 0, 1)',
									outerBorderWidth: 5,
									innerRadius: 1,
									innerBorderWidth: 0,
									innerBorderColor: 'rgba(0, 0, 0, 1)',
									radiusLineColor: 'rgba(255, 255, 255, 1)',
									radiusLineWidth: 3,
									fontSize: 20,
									fontFamily: 'Poppins',
								},
							},
							button: {
								properties: {
									title: translation.game_playButton[lang],
								},
								style: {
									backgroundColor: 'rgba(0, 0, 0, 1)',
									borderWidth: '0px',
									borderColor: 'rgba(0, 0, 0, 1)',
									borderRadius: '20px',
									color: 'rgba(255, 255, 255, 1)',
									fontSize: 14,
								},
							},
							button_next: {
								properties: {
									title: translation.game_nextButton[lang],
								},
								style: {
									backgroundColor: 'rgba(177, 177, 177, 1)',
									color: 'rgba(0, 0, 0, 1)',
									fontSize: 14,
								},
							},
							button_submit: {
								style: {
									backgroundColor: 'rgb(82,112,232)',
									color: 'rgba(255, 255, 255, 1)',
									fontSize: 14,
								},
							},
							button_link: {
								style: {
									backgroundColor: 'rgb(82,112,232)',
									color: 'rgba(255, 255, 255, 1)',
									fontSize: 14,
								},
							},
						},
					}
					break
			}
			return initialGame
		}
		default:
			break
	}
}

export function getAmpAttachmentInitialValue({ type, lang }) {
	switch (type) {
		case 'outlink':
			return {
				_id: `amp-${nanoid()}`,
				type: 'outlink',
				link: '',
				name: translation.amp_outlink_text[lang],
				isDarkTheme: false,
				hasIcon: true,
				iconUrl: '',
			}
		default:
			break
	}
}

export function getAmpInteractionInitialValue({ type }) {
	switch (type) {
		case 'amp_poll':
			return {
				_id: `amp-${nanoid()}`,
				type: 'amp_poll',
				prompt_text: 'Enter a question',
				prompt_size: 'medium',
				prompt_color: 'rgba(255, 255, 255, 1)',
				theme: 'light',
				backgroundColor: 'rgba(12, 104, 234, 1)',
				alignment: 'center',
				option_style: 'flat',
				options: [
					{
						_id: nanoid(),
						title: 'Option 1',
						confetti: '👍',
					},
					{
						_id: nanoid(),
						title: 'Option 2',
						confetti: '👍',
					},
				],
				outer_style: {
					transform: 'translateX(45px) translateY(100px) rotate(0deg)',
					top: '0px',
					left: '0px',
					width: '270px',
					height: 'auto',
				},
			}
		case 'amp_binary_poll':
			return {
				_id: `amp-${nanoid()}`,
				type: 'amp_binary_poll',
				prompt_text: 'Enter a question',
				prompt_size: 'medium',
				prompt_color: 'rgba(255, 255, 255, 1)',
				theme: 'light',
				backgroundColor: 'rgba(12, 104, 234, 1)',
				alignment: 'center',
				option_color: 'rgba(12, 104, 234, 1)',
				options: [
					{
						_id: nanoid(),
						title: 'YES',
						confetti: '👍',
					},
					{
						_id: nanoid(),
						title: 'NO',
						confetti: '👎',
					},
				],
				outer_style: {
					transform: 'translateX(80px) translateY(250px) rotate(0deg)',
					top: '0px',
					left: '0px',
					width: '200px',
					height: 'auto',
				},
			}
		case 'amp_image_poll':
			return {
				_id: `amp-${nanoid()}`,
				type: 'amp_image_poll',
				prompt_text: 'Enter a question',
				prompt_size: 'medium',
				prompt_color: 'rgba(255, 255, 255, 1)',
				theme: 'light',
				backgroundColor: 'rgba(12, 104, 234, 1)',
				alignment: 'center',
				option_style: 'flat',
				options: [
					{
						_id: nanoid(),
						title: '',
						imageUrl: '',
						confetti: '👍',
					},
					{
						_id: nanoid(),
						title: '',
						imageUrl: '',
						confetti: '👍',
					},
				],
				outer_style: {
					transform: 'translateX(78px) translateY(150px) rotate(0deg)',
					top: '0px',
					left: '0px',
					width: '204px',
					height: 'auto',
				},
			}
		case 'amp_quiz':
			return {
				_id: `amp-${nanoid()}`,
				type: 'amp_quiz',
				prompt_text: 'Enter a question',
				prompt_size: 'medium',
				prompt_color: 'rgba(255, 255, 255, 1)',
				theme: 'light',
				backgroundColor: 'rgba(12, 104, 234, 1)',
				alignment: 'center',
				option_style: 'flat',
				option_color: 'rgba(12, 104, 234, 1)',
				options: [
					{
						_id: nanoid(),
						title: 'Option 1',
						confetti: '👍',
						isCorrect: true,
					},
					{
						_id: nanoid(),
						title: 'Option 2',
						confetti: '👍',
						isCorrect: false,
					},
				],
				outer_style: {
					transform: 'translateX(45px) translateY(100px) rotate(0deg)',
					top: '0px',
					left: '0px',
					width: '250px',
					height: 'auto',
				},
			}
		case 'amp_image_quiz':
			return {
				_id: `amp-${nanoid()}`,
				type: 'amp_image_quiz',
				prompt_text: 'Enter a question',
				prompt_size: 'medium',
				prompt_color: 'rgba(255, 255, 255, 1)',
				theme: 'light',
				backgroundColor: 'rgba(12, 104, 234, 1)',
				alignment: 'center',
				option_style: 'flat',
				option_color: 'rgba(12, 104, 234, 1)',
				options: [
					{
						_id: nanoid(),
						title: 'Image 1',
						imageUrl: '',
						confetti: '👍',
						isCorrect: true,
					},
					{
						_id: nanoid(),
						title: 'Image 2',
						imageUrl: '',
						confetti: '👍',
						isCorrect: false,
					},
				],
				outer_style: {
					transform: 'translateX(78px) translateY(100px) rotate(0deg)',
					top: '0px',
					left: '0px',
					width: '204px',
					height: 'auto',
				},
			}
		case 'amp_slider':
			return {
				_id: `amp-${nanoid()}`,
				type: 'amp_slider',
				prompt_text: 'Enter a question',
				option_color: 'rgba(12, 104, 234, 1)',
				icon: '👍',
				theme: 'light',
				option_style: 'flat',
				outer_style: {
					transform: 'translateX(78px) translateY(100px) rotate(0deg)',
					top: '0px',
					left: '0px',
					width: '280px',
					height: 'auto',
				},
			}
		default:
			break
	}
}

export function getTimerInitialValue({ isEnabled = true } = {}) {
	return {
		_id: nanoid(),
		type: 'timer',
		value: DEFAULT_TIMER_VALUE,
		enabled: isEnabled,
		visible: false,
		inner_style: {
			color: 'rgb(0, 0, 0)',
			fontSize: '18px',
			borderColor: 'rgb(0, 0, 0)',
			borderWidth: '2px',
			backgroundColor: 'rgb(255, 255, 255)',
		},
		outer_style: {
			transform: 'translateX(30px) translateY(50px) rotate(0deg)',
			top: '0px',
			left: '0px',
			height: '61px',
			width: '61px',
		},
		keepRatio: true,
	}
}
export function getTagInitialValue() {
	return {
		_id: nanoid(),
		placement: 'bottom',
		type: 'tag',
		description: {
			title: '',
			price: '',
			url: '',
		},
		inner_style: {
			backgroundColor: 'rgb(255, 255, 255)',
		},
		outer_style: {
			transform: 'translateX(30px) translateY(50px) rotate(0deg)',
			top: '0px',
			left: '0px',
			width: '150px',
			height: '110px',
		},
		tag_style: {
			backgroundColor: 'rgb(255, 255, 255)',
			color: 'rgb(72, 71, 71)',
		},
		price_style: {
			color: 'rgb(82,112,232)',
		},
	}
}

export function getCarouselInitialValue(type) {
	const defaultBlock = {
		_id: nanoid(),
		type: 'carousel',
		outer_style: {
			transform: 'translateX(0px) translateY(120px) rotate(0deg)',
			top: '0px',
			left: '0px',
			height: 'auto',
			fontFamily: 'Poppins',
			fontSize: '24px',
			backgroundColor: 'rgba(255, 255, 255, 0)',
			color: 'rgba(0, 0, 0, 100)',
			borderColor: 'rgba(0, 0, 0, 1)',
			borderStyle: 'solid',
			borderRadius: '0px',
			borderWidth: '0px',
		},
		items: [
			{
				_id: nanoid(),
				image: '',
				title: 'Card 1',
				subtitle: 'Subtitle 1',
				btn_title: 'Add To Cart',
				click_options: {
					type: 'external',
					link: '',
					attachment: {},
					file: {},
				},
			},
			{
				_id: nanoid(),
				image: '',
				title: 'Card 2',
				subtitle: 'Subtitle 2',
				btn_title: 'Add To Cart',
				click_options: {
					type: 'external',
					link: '',
					attachment: {},
					file: {},
				},
			},
		],
		title: 'Carousel Title',
		settings: {
			hasControls: true,
		},
		animation: {
			name: '',
			duration: 1000,
			delay: 0,
			forever: false,
			displayDelay: 0,
		},
	}
	switch (type) {
		case 'card':
			defaultBlock.item_type = 'card'
			defaultBlock.inner_style = {
				width: '140px',
				borderColor: 'rgba(125, 125, 125, 1)',
				borderStyle: 'solid',
				borderRadius: '8px',
				borderWidth: '1px',
				fontSize: '14px',
				color: 'rgba(255, 255, 255, 1)',
				backgroundColor: 'rgba(255, 255, 255, 1)',

				hasShadow: true,
				shadowColor: 'rgba(125, 125, 125, 1)',
				shape: 'rectangle',
				hasPlayButton: true,
				overlayColor: 'rgba(0, 0, 0, 0.3)',
				inner_borderWidth: '3px',
				inner_borderColor: 'rgba(255, 255, 255, 1)',

				btn_color: 'rgba(255, 255, 255, 1)',
				btn_backgroundColor: 'rgba(0, 0, 0, 1)',
			}
			break
		case 'detailed':
			defaultBlock.item_type = 'detailed'
			defaultBlock.inner_style = {
				width: '140px',
				borderColor: 'rgba(125, 125, 125, 1)',
				borderStyle: 'solid',
				borderRadius: '8px',
				borderWidth: '1px',
				fontSize: '14px',
				color: 'rgba(0, 0, 0, 1)',
				backgroundColor: 'rgba(255, 255, 255, 1)',

				hasShadow: true,
				shadowColor: 'rgba(125, 125, 125, 1)',
				shape: 'rectangle',
				hasPlayButton: true,
				overlayColor: 'rgba(0, 0, 0, 0.3)',
				inner_borderWidth: '3px',
				inner_borderColor: 'rgba(255, 255, 255, 1)',

				btn_color: 'rgba(255, 255, 255, 1)',
				btn_backgroundColor: 'rgba(0, 0, 0, 1)',
			}
			break
		case 'rounded':
			defaultBlock.item_type = 'rounded'
			defaultBlock.inner_style = {
				width: '100px',
				borderColor: 'rgba(0, 0, 0, 1)',
				borderStyle: 'solid',
				borderRadius: '8px',
				borderWidth: '1px',
				fontSize: '14px',
				color: 'rgba(0, 0, 0, 1)',
				backgroundColor: 'rgba(255, 255, 255, 1)',

				hasShadow: false,
				shadowColor: 'rgba(125, 125, 125, 1)',
				shape: 'rectangle',
				hasPlayButton: true,
				overlayColor: 'rgba(0, 0, 0, 0.3)',
				inner_borderWidth: '3px',
				inner_borderColor: 'rgba(255, 255, 255, 1)',

				btn_color: 'rgba(255, 255, 255, 1)',
				btn_backgroundColor: 'rgba(0, 0, 0, 1)',
			}
			break
		case 'product':
			defaultBlock.item_type = 'product'
			defaultBlock.inner_style = {
				width: '140px',
				borderColor: 'rgba(0, 0, 0, 1)',
				borderStyle: 'solid',
				borderRadius: '8px',
				borderWidth: '1px',
				fontSize: '14px',
				color: 'rgba(0, 0, 0, 1)',
				backgroundColor: 'rgba(255, 255, 255, 0.3)',

				hasShadow: false,
				shadowColor: 'rgba(125, 125, 125, 1)',
				shape: 'rectangle',
				hasPlayButton: true,
				overlayColor: 'rgba(0, 0, 0, 0.3)',
				inner_borderWidth: '3px',
				inner_borderColor: 'rgba(255, 255, 255, 1)',

				btn_color: 'rgba(255, 255, 255, 1)',
				btn_backgroundColor: 'rgba(0, 0, 0, 1)',
			}
			break
	}
	return defaultBlock
}

export function getButtonListInitialValue() {
	const defaultBlock = {
		_id: nanoid(),
		type: 'buttonList',
		outer_style: {
			transform: 'translateX(0px) translateY(120px) rotate(0deg)',
			top: '0px',
			left: '0px',
			width: '300px',
			height: '100px',
			fontFamily: 'Poppins',
			fontSize: '16px',
			backgroundColor: 'rgba(255, 255, 255, 0)',
			borderColor: 'rgba(0, 0, 0, 1)',
			borderStyle: 'solid',
			borderRadius: '0px',
			borderWidth: '0px',
		},
		inner_style: {
			backgroundColor: 'rgba(0, 0, 0, 100)',
			color: 'rgba(255, 255, 255, 100)',
			borderColor: 'rgba(0, 0, 0, 1)',
			borderStyle: 'solid',
			borderRadius: '16px',
			borderWidth: '1px',
			textAlign: 'center',
		},
		buttons: [
			{
				_id: nanoid(),
				title: 'Link',
				hasIcon: false,
				image: '',
				icon: '',
				click_options: {
					link: '',
					type: 'external',
					attachment: {},
					file: {},
					copyValue: '',
					phoneNumber: '',
					defaultMessage: '',
				},
			},
		],
	}

	return defaultBlock
}

export function getButtonInitialValues(type, answersLength = 0) {
	switch (type) {
		case 'pages':
			return [
				{
					_id: nanoid(),
					correct: false,
					title: `Answer ${answersLength + 1}`,
					next: {
						type,
						value: 'next',
					},
					rewards: {
						enabled: false,
						emoji: '👍',
					},
				},
				{
					_id: nanoid(),
					correct: false,
					title: `Answer ${answersLength + 2}`,
					next: {
						type,
						value: 'next',
					},
					rewards: {
						enabled: false,
						emoji: '👍',
					},
				},
			]
		case 'link':
			return {
				_id: nanoid(),
				correct: false,
				title: `Answer ${answersLength + 1}`,
				next: {
					type,
					value: '',
					auto_redirect: false,
				},
			}
		case 'file_downloader':
			return {
				_id: nanoid(),
				correct: false,
				title: `Answer ${answersLength + 1}`,
				next: {
					type,
					value: '',
				},
			}
		case 'file_uploader':
			return {
				_id: nanoid(),
				correct: false,
				title: `Answer ${answersLength + 1}`,
				next: {
					type,
					value: ['pdf', 'image', 'video'],
				},
			}
		default:
			break
	}
}

export function initDefaultTheming() {
	return _.cloneDeep({
		answers: {
			default: {
				borderWidth: '1px',
				borderStyle: 'solid',
				borderColor: 'rgba(255, 255, 255, 1)',
				backgroundColor: snackeet_blue,
				color: 'rgba(255, 255, 255, 1)',
				borderRadius: '30px',
				fontFamily: 'Poppins',
				fontSize: '16px',
				justifyContent: 'center',
				fontWeight: 'bold',
			},
			correct: {
				borderWidth: '1px',
				borderStyle: 'solid',
				borderColor: 'rgba(0, 153, 31, 1)',
				borderRadius: '30px',
				backgroundColor: 'rgba(255, 255, 255, 1)',
				color: 'rgba(0, 153, 31, 1)',
				fontFamily: 'Poppins',
				fontSize: '16px',
				justifyContent: 'center',
				fontWeight: 'bold',
			},
			incorrect: {
				borderWidth: '1px',
				borderStyle: 'solid',
				borderColor: 'rgba(165, 0, 0, 1)',
				borderRadius: '30px',
				backgroundColor: 'rgba(255, 255, 255, 1)',
				color: 'rgba(165, 0, 0, 1)',
				fontFamily: 'Poppins',
				fontSize: '16px',
				justifyContent: 'center',
				fontWeight: 'bold',
			},
			selected: {
				borderWidth: '1px',
				borderStyle: 'solid',
				borderColor: 'rgba(0, 153, 31, 1)',
				borderRadius: '30px',
				backgroundColor: 'rgba(255, 255, 255, 1)',
				color: 'rgba(0, 153, 31, 1)',
				fontFamily: 'Poppins',
				fontSize: '16px',
				justifyContent: 'center',
				fontWeight: 'bold',
			},
		},
		image_answers: {
			width: '120px',
			height: '120px',
			borderWidth: '0px',
			borderStyle: 'solid',
			borderColor: 'rgba(255, 255, 255, 1)',
			backgroundColor: 'rgba(219, 219, 219, 1)',
			color: 'rgba(255, 255, 255, 1)',
			borderRadius: '8px',
			fontFamily: 'Poppins',
			fontSize: '14px',
			justifyContent: 'center',
			fontWeight: 'bold',
		},
		fields: {
			backgroundColor: 'rgba(255, 255, 255, 1)',
			borderWidth: '1px',
			borderStyle: 'solid',
			borderColor: 'rgba(204, 204, 204, 1)',
			color: 'rgba(0, 0, 0, 1)',
			borderRadius: '5px',
			fontFamily: 'Poppins',
			fontSize: '16px',
			padding: '15px',
			width: '100%',
		},
		labels: {
			fontFamily: 'Poppins',
			fontSize: '12px',
			color: 'rgba(0, 0, 0, 1)',
			marginBottom: '2px',
		},
		buttons: {
			backgroundColor: snackeet_blue,
			borderColor: 'rgba(255, 255, 255, 1)',
			borderWidth: '1px',
			borderStyle: 'solid',
			color: '#fff',
			borderRadius: '30px',
			fontFamily: 'Poppins',
			fontSize: '16px',
			justifyContent: 'center',
			fontWeight: 'bold',
		},
		confirm_button: {
			backgroundColor: '#00cc00',
			borderColor: 'rgba(255, 255, 255, 1)',
			borderWidth: '0px',
			borderStyle: 'solid',
			color: '#fff',
			borderRadius: '30px',
			fontFamily: 'Poppins',
			fontSize: '16px',
			justifyContent: 'center',
			fontWeight: 'bold',
		},
		consent_message: {
			color: '#484747',
		},
	})
}

export function getInitRoundedButton() {
	return {
		height: '120px',
		width: '120px',
		borderRadius: '50%',
		fontSize: '20px',
		overflow: 'hidden',
	}
}

export function getPresetButtonStyle(type) {
	switch (type) {
		case 'outlined_square':
			return {
				borderRadius: '0px',
			}
		case 'outlined_round':
			return {
				borderRadius: '10px',
			}
		case 'outlined_circle':
			return {
				borderRadius: '100px',
			}
	}
}

export function getModalInitialValue({ sub_type, lang }) {
	switch (sub_type) {
		case 'wheel':
			return {
				winning: {
					title: translation.game_modal_winning_header[lang],
					description: translation.game_modal_winning_description[lang],
					img_url: '',
					hasCode: true,
					cta: {
						isActive: false,
						type: 'redirection',
						title: translation.game_modal_winning_button[lang],
						url: '',
					},
					style: {
						backgroundColor: '#FFF',
						color: '#000',
						fontFamily: 'inherit',
					},
				},
				losing: {
					title: translation.game_modal_losing_header[lang],
					description: translation.game_modal_losing_description[lang],
					img_url: '',
					cta: {
						isActive: false,
						type: 'redirection',
						title: translation.game_modal_losing_button[lang],
						url: '',
					},
					style: {
						backgroundColor: '#FFF',
						color: '#000',
						fontFamily: 'inherit',
					},
				},
				form: {
					title: translation.game_modal_form_header[lang],
					description: translation.game_modal_form_description[lang],
					placeholder: translation.game_modal_form_placeholder[lang],
					submit_button: translation.game_modal_form_submitButton[lang],
					style: {
						backgroundColor: '#FFF',
						color: '#000',
						fontFamily: 'inherit',
					},
					isActive: false,
				},
			}
		default:
			break
	}
}

export function getAttachmentElement({ type, sub_type, value }) {
	// 2 types: input for form and content
	// Each type have multiples sub_type
	switch (type) {
		case 'content':
			switch (sub_type) {
				case 'title':
					return {
						_id: `amp-${nanoid()}`,
						type: 'content',
						sub_type: 'title',
						value: value || 'Title',
						styles: {
							textAlign: 'center',
							fontWeight: 'bold',
							fontFamily: 'Poppins',
						},
					}
				case 'paragraph':
					return {
						_id: `amp-${nanoid()}`,
						type: 'content',
						sub_type: 'paragraph',
						value: value || 'paragraph',
						styles: {
							textAlign: 'center',
							fontWeight: 'normal',
							fontFamily: 'Poppins',
						},
					}
				case 'disclamer':
					return {
						_id: `amp-${nanoid()}`,
						type: 'content',
						sub_type: 'disclamer',
						value: 'disclamer',
						styles: {
							textAlign: 'center',
							fontFamily: 'Poppins',
						},
					}
				case 'image':
					return {
						_id: `amp-${nanoid()}`,
						type: 'content',
						sub_type: 'image',
						value: '',
						height: 300,
						width: 200,
					}
				case 'button':
					return {
						_id: `amp-${nanoid()}`,
						type: 'content',
						sub_type: 'button',
						value: 'Click me',
						url: 'https://snackeet.com',
						styles: {
							textAlign: 'center',
							fontSize: '24px',
							borderWidth: '1px',
							borderStyle: 'solid',
							borderColor: 'rgba(255, 255, 255, 1)',
							backgroundColor: snackeet_blue,
							color: 'rgba(255, 255, 255, 1)',
							borderRadius: '16px',
						},
					}
				case 'iframe':
					return {
						_id: `amp-${nanoid()}`,
						type: 'content',
						sub_type: 'iframe',
						value: '',
						height: '400px',
						styles: {
							borderRadius: '8px',
						},
					}
			}
			break

		// INPUT ELEMENTS
		case 'input':
			switch (sub_type) {
				case 'name':
					return {
						_id: `amp-${nanoid()}`,
						type: 'input',
						sub_type: 'name',
						placeholder: 'Enter your name',
						label: 'Your name is ?',
						required: true,
					}
				case 'email':
					return {
						_id: `amp-${nanoid()}`,
						type: 'input',
						sub_type: 'email',
						placeholder: 'Enter your email',
						label: 'Your email is ?',
						required: true,
					}
				case 'phone':
					return {
						_id: `amp-${nanoid()}`,
						type: 'input',
						sub_type: 'email',
						placeholder: 'Enter your email',
						label: 'Your email is ?',
						required: true,
					}
				case 'text':
					return {
						_id: `amp-${nanoid()}`,
						type: 'input',
						sub_type: 'text',
						placeholder: 'Enter your email',
						label: 'Your email is ?',
						required: true,
					}
				case 'longText':
					return {
						_id: `amp-${nanoid()}`,
						type: 'input',
						sub_type: 'longText',
						placeholder: 'Enter your email',
						label: 'Your email is ?',
						required: true,
					}
				case 'website':
					return {
						_id: `amp-${nanoid()}`,
						type: 'input',
						sub_type: 'website',
						placeholder: 'Enter your email',
						label: 'Your email is ?',
						required: true,
					}
				case 'number':
					return {
						_id: `amp-${nanoid()}`,
						type: 'input',
						sub_type: 'number',
						placeholder: 'Enter your email',
						label: 'Your email is ?',
						required: true,
					}
				case 'consent':
					return {
						_id: `amp-${nanoid()}`,
						type: 'input',
						sub_type: 'consent',
						placeholder: 'Enter your email',
						label: 'Your email is ?',
						required: true,
					}
			}
	}
}
