import { BANNER_CIRCLE_SIZE } from 'lib/constants';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { StoryBorderSvg } from './BannerBubble.style';
const StoryBorder = memo(function StoryBorder({
  widgetId,
  size = BANNER_CIRCLE_SIZE,
  settings
}) {
  return <StoryBorderSvg $size={size} viewBox={`0 0 ${size} ${size}`} xmlns='http://www.w3.org/2000/svg'>
			<linearGradient spreadMethod='pad' id={`gradient-border-${widgetId}`} x1='0' y1='70%' x2='70%' y2='0'>
				<stop offset='0%' style={{
        stopOpacity: 1,
        stopColor: settings.start_color
      }}></stop>
				<stop offset='100%' style={{
        stopOpacity: 1,
        stopColor: settings.end_color
      }}></stop>
			</linearGradient>
			<rect width={size} height={size} rx={`${settings.border_radius}%`} fill='none' stroke={`url(#gradient-border-${widgetId})`}></rect>
		</StoryBorderSvg>;
});
StoryBorder.propTypes = {
  size: PropTypes.number,
  settings: PropTypes.object
};
export default StoryBorder;