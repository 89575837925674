import styled from '@styled';
import { LOGO_PLATFORMS } from 'lib/constants';
import { extractPositionProperties } from 'lib/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import BlockWrapper from './BlockWrapper';
export default function NetworksBlock({
  outer_style,
  animation,
  platforms,
  iconSize,
  hasShadow
}) {
  if (_.isEmpty(platforms)) {
    return null;
  }
  const {
    positionProperties,
    visualProperties
  } = extractPositionProperties(outer_style);
  const {
    borderStyle,
    blockStyle
  } = extractBlockStyle(visualProperties);
  return <BlockWrapper animation={animation} positionProperties={positionProperties}>
			<div style={{
      ...blockStyle,
      textAlign: 'center'
    }}>
				{_.map(platforms, (network, index) => renderMessengerPlatforms(network)({
        key: index,
        borderStyle,
        size: iconSize,
        hasShadow
      }))}
			</div>
		</BlockWrapper>;
}
NetworksBlock.propTypes = {
  outer_style: PropTypes.object,
  animation: PropTypes.object,
  platforms: PropTypes.array,
  iconSize: PropTypes.number,
  hasShadow: PropTypes.bool
};

/* eslint-disable react/prop-types */
/* eslint-disable react/display-name  */
function renderMessengerPlatforms({
  name
}) {
  function renderIconPlatform(name) {
    const iconPlateform = _.find(LOGO_PLATFORMS, ['name', name]);
    return iconPlateform.icon;
  }
  return ({
    key,
    borderStyle,
    size,
    hasShadow
  }) => <NetworkPlateformButton key={key} $size={size} $svgSize={getSvgSize(size)} style={borderStyle} $hasShadow={hasShadow}>
			{renderIconPlatform(name)}
		</NetworkPlateformButton>;
}
function getSvgSize(size) {
  return size - 20;
}
function extractBlockStyle(visualProperties) {
  const borderStyle = _.omit(visualProperties, ['height', 'width', 'animationFillMode']);
  const blockStyle = _.omit(visualProperties, ['borderStyle', 'borderWidth', 'borderRadius', 'borderColor', 'backgroundColor']);
  return {
    borderStyle,
    blockStyle
  };
}
export const NetworkPlateformButton = styled.div`
	width: ${({
  $size
}) => $size}px;
	height: ${({
  $size
}) => $size}px;
	border-radius: ${({
  borderRadius
}) => borderRadius};
	margin: ${({
  theme
}) => theme.spacing(0.6, 1)};
	display: inline-flex;
	vertical-align: middle;
	box-shadow: ${({
  theme,
  $hasShadow
}) => $hasShadow ? theme.shadows[2] : 'none'};

	svg {
		width: ${({
  $svgSize
}) => $svgSize}px;
		height: ${({
  $svgSize
}) => $svgSize}px;
		min-height: 15px;
		min-width: 15px;
		color: inherit;
		margin: auto;
	}
`;