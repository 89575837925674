import Box, { BoxProps } from '@mui/material/Box';
import styled from '@styled';
import usePrevious from 'lib/hooks/usePrevious';
import _ from 'lodash';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import type { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';
type AnimationProperties = {
  delay?: number; // Delay before the start of animation in milliseconds
  duration?: number; // Duration of the animation in milliseconds
  displayDelay?: number; // Delay before displaying the element (before delay)
  forever?: boolean; // Loop the reveal animation forever
  name?: string; // Name of the animate.css animation
};
type AnimateProps = {
  animation: AnimationProperties;
  children?: ReactNode;
  onReveal?: () => void; // Function called once the element is revealed
  sx?: BoxProps['sx'];
};
const getTransitionClasses = (name: AnimationProperties['name'], forever: AnimationProperties['forever']) => {
  if (!name) return undefined;
  const transitionClasses: CSSTransitionClassNames = {
    appear: 'animate__animated',
    appearActive: `animate__${name}`
  };

  // When the animation has a loop, make sure the Animate.css class is persisted in appear-done state
  if (forever) {
    transitionClasses.appearDone = `${transitionClasses.appear} ${transitionClasses.appearActive}`;
  }
  return transitionClasses;
};
export default function Animate({
  children,
  sx,
  animation,
  onReveal
}: AnimateProps) {
  const {
    name,
    delay = 0,
    duration = 1000,
    displayDelay = 0,
    forever = false
  } = animation;
  const previousAnimation = usePrevious(animation);
  const nodeRef = useRef<HTMLDivElement>(null);
  const isDisplayed = useDisplay(displayDelay);
  const transitionClasses = getTransitionClasses(name, forever);

  // State to control re-rendering
  const [key, setKey] = useState(0);

  // useEffect to detect changes in animation parameters
  useEffect(() => {
    // Trigger re-render to restart animation
    // Use the usePrevious hook to keep the CSSTransition rerender only when changing animation
    if (previousAnimation && !_.isEqual(animation, previousAnimation)) {
      setKey(prevKey => prevKey + 1);
    }
  }, [animation]);
  if (!isDisplayed) {
    return null;
  }
  return <CSSTransition key={key} nodeRef={nodeRef} timeout={duration + delay} onEntered={onReveal} appear in classNames={transitionClasses}>
			<AnimationWrapper ref={nodeRef} duration={duration} delay={delay} forever={forever} sx={sx}>
				{children}
			</AnimationWrapper>
		</CSSTransition>;
}
type AnimationWrapperProps = Pick<AnimationProperties, 'duration' | 'delay' | 'forever'>;
const AnimationWrapper = styled(Box, {
  shouldForwardProp: propName => !['duration', 'delay', 'forever'].includes(propName)
})<AnimationWrapperProps>(({
  duration,
  delay,
  forever
}) => `
		--animate-duration: ${duration}ms;
		--animate-delay: ${delay}ms;
		animation-delay: var(--animate-delay);
		${forever && `animation-iteration-count: infinite;`}
`);
function useDisplay(displayDelay: number) {
  const isInitiallyDisplayed = displayDelay === 0;
  const [isDisplayed, setIsDisplayed] = useState(isInitiallyDisplayed);

  // If present, handle delay of element display
  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (!isInitiallyDisplayed) {
      timeoutId = setTimeout(() => setIsDisplayed(true), displayDelay);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  return isDisplayed;
}