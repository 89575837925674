import { Button, Chip, List, Tab, Tabs } from '@mui/material';
import styled from '@styled';
export const InstagramAssetsContainer = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;

	[role='tabpanel'] {
		overflow: auto;
		width: 100%;
		min-height: 400px;
		padding: ${({
  theme
}) => theme.spacing(0.5)};
	}
`;
export const ViewHeader = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-areas: 'back title option';
	width: 100%;
	place-items: flex-start;
	padding: ${({
  theme
}) => theme.spacing(0, 1, 1, 1)};
`;
export const AccountsList = styled(List)`
	min-width: 450px;
	overflow: auto;
`;
export const StoryLimitationsList = styled.ul`
	font-size: 14px;
	max-width: 500px;

	li {
		margin-bottom: ${({
  theme
}) => theme.spacing(1)};
	}
`;
export const InstagramTabs = styled(Tabs)`
	border-bottom: 1px solid #efefef;
	overflow: visible;
	margin-bottom: 0;

	.MuiTabs-fixed {
		overflow-x: visible;
	}

	.MuiTabs-indicator {
		height: 1px;
		background-color: #262626;
	}

	.MuiTab-labelIcon {
		min-height: unset;
		padding: 8px;
	}

	.MuiTab-textColorInherit {
		opacity: 0.4;
	}
	.Mui-selected {
		opacity: 1;
	}
`;
export const InstagramTab = styled(Tab)`
	.MuiTab-root {
		min-width: 140px;
	}
	.MuiTab-wrapper {
		display: flex;
		flex-direction: row;
		align-items: center;

		.MuiSvgIcon-root {
			font-size: 16px;
			margin: ${({
  theme
}) => theme.spacing(0, 1, 0, 0)};
		}
	}
`;
export const SelectedIndexLabel = styled(Chip)`
	min-width: 32px;
`;
SelectedIndexLabel.defaultProps = {
  color: 'primary'
};
export const SelectedCountContainer = styled.div`
	padding: ${({
  theme
}) => theme.spacing(1)};
	font-size: 14px;
	place-self: center;
	display: flex;
	align-items: center;
`;
export const ValidateButton = styled(Button)`
	display: flex;
	align-items: center;
	margin-right: ${({
  theme
}) => theme.spacing(1)};
`;
ValidateButton.defaultProps = {
  variant: 'outlined'
};