import styled from '@styled';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CarouselBase from './CarouselBase';
export default function Carousels({
  page
}) {
  return page?.carousels && !_.isEmpty(page.carousels) && <CarouselContainer>
				{page.carousels.map(carousel => <CarouselBase key={carousel._id} carousel={carousel} />)}
			</CarouselContainer>;
}
Carousels.propTypes = {
  page: PropTypes.object
};
const CarouselContainer = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	box-sizing: border-box;
	pointer-events: none;
	z-index: 3;
`;