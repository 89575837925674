import _ from 'lodash';
import PropTypes from 'prop-types';
import AmpAttachment from './AmpAttachment';
import OutLink from './Outlink';
export default function AmpAttachments({
  amp_attachments
}) {
  const attachment = _.first(amp_attachments);
  if (attachment?.type === 'outlink') {
    return <OutLink attachment={attachment} />;
  }
  if (['amp_attachment', 'amp_form'].includes(attachment?.type)) {
    return <AmpAttachment attachment={attachment} />;
  }
  return null;
}
AmpAttachments.propTypes = {
  amp_attachments: PropTypes.array
};