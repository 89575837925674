import { Close, DragIndicator, Publish } from '@mui/icons-material';
import { Box, Button, Collapse, DialogActions, IconButton, List, ListItem, TextField, Typography } from '@mui/material';
import { IOSSwitch } from 'atoms';
import { ImportLists, InputList, ListContainer, ModalContent } from 'components/styled/Modals';
import { DragableProperties } from 'containers/Editor/StoryEditor/Properties/Properties.style';
import { reorder } from 'lib/utils';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { forwardRef, memo, useCallback, useImperativeHandle, useRef } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useImmer } from 'use-immer';
import * as XLSX from 'xlsx';
import { ModalWrapper } from '../ModalWrapper';
const fileImporterStyle = {
  display: 'none'
};
const ListSettingsModal = forwardRef(({
  field_id,
  field_settings,
  handleChangeFieldSettings,
  disableBackdropClick = true,
  disableEscapeKeyDown = true
}, ref) => {
  const intl = useIntl();
  const importRef = useRef();
  const fileImportRef = useRef();
  const [currentStates, setCurrentStates] = useImmer({
    fileName: '',
    list: initList(field_settings?.options),
    multiple: field_settings?.multiple,
    limit_choices: field_settings?.limit_choices,
    limit: field_settings.limit,
    importLists: {},
    importHeader: '',
    isImporting: false
  });
  const open = () => {
    importRef.current.open();
  };
  const close = () => {
    importRef.current.close();
  };
  function handleFileImport(evt) {
    const file = evt.target.files[0];
    if (!file) {
      return;
    }
    setCurrentStates(draft => {
      draft.fileName = file.name;
    });
    const reader = new FileReader();
    reader.onload = event => {
      const bstr = event.target.result;
      const wb = XLSX.read(bstr, {
        type: 'string'
      });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, {
        header: 1
      });

      /* Update state */
      setCurrentStates(draft => {
        draft.importLists = convertToArray(data);
        draft.isImporting = true;
      });
    };
    reader.readAsText(file);
  }
  function convertToArray(json) {
    const headers = json[0];
    const result = {};
    for (let i = 0; i < headers.length; i++) {
      result[headers[i]] = [];
      for (let j = 1; j < json.length; j++) {
        const currentLine = json[j];
        if (!_.isEmpty(currentLine[i]?.toString())) {
          result[headers[i]].push(currentLine[i]?.toString());
        }
      }
    }
    return result;
  }
  function handleImportList(header) {
    const newList = _.cloneDeep(currentStates.importLists[header]);
    setCurrentStates(draft => {
      draft.list = initList(newList);
      draft.importHeader = header;
    });
  }
  function nextStep() {
    setCurrentStates(draft => {
      draft.isImporting = false;
    });
  }
  function handleUpdateProperty(path, value) {
    if (path === 'multiple' && value === false) {
      setCurrentStates(draft => {
        draft.multiple = false;
        draft.limit_choices = false;
      });
    } else {
      setCurrentStates(draft => {
        draft[path] = value;
      });
    }
  }
  function confirmOptions() {
    const listTopUpdate = extractListOptions(_.cloneDeep(currentStates.list));
    handleChangeFieldSettings(field_id, {
      options: listTopUpdate,
      multiple: currentStates.multiple,
      limit_choices: currentStates.limit_choices,
      limit: currentStates.limit
    });
    close();
  }
  const handleOptionUpdate = useCallback((value, index) => {
    setCurrentStates(draft => {
      draft.list[index].value = value;
    });
  }, [setCurrentStates]);
  const removeOption = useCallback(index => {
    setCurrentStates(draft => {
      draft.list.splice(index, 1);
    });
  }, [setCurrentStates]);
  function addOption() {
    setCurrentStates(draft => {
      draft.list.push({
        _id: nanoid(),
        value: `${intl.messages['edition_menu.form.field_settings.new_option']}`
      });
    });
  }
  function onOptionsDragEnd(result) {
    if (!result.destination) {
      return;
    }
    setCurrentStates(draft => {
      draft.list = reorder(draft.list, result.source.index, result.destination.index);
    });
  }
  useImperativeHandle(ref, () => ({
    open,
    close
  }));
  return <ModalWrapper title={intl.messages['modals.list_settings.header']} ref={importRef} size='md' hasCloseButton={false} disableBackdropClick={disableBackdropClick} disableEscapeKeyDown={disableEscapeKeyDown}>
				<ModalContent>
					<div>
						{currentStates.isImporting ? <>
								<Typography variant='h3'>
									<FormattedMessage id='modal.list_settings.instruction' />
								</Typography>
								<ImportLists>
									{_.map(_.keys(currentStates.importLists), (header, index) => <ListContainer key={index} $isActive={header === currentStates.importHeader} onClick={() => handleImportList(header)}>
											<Typography className='list-header' variant='h3'>
												{header}
											</Typography>
											<div className='option-container'>
												{_.map(currentStates.importLists[header], (option, index) => <div key={index}>{option}</div>)}
											</div>
										</ListContainer>)}
								</ImportLists>
							</> : <DragableProperties>
								<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} px={4}>
									<Box pl={2}>
										<Typography variant='h2'>
											{intl.messages['edition_menu.form.field_settings.multiple']}
										</Typography>
										<Typography variant='body1'>
											{intl.messages['edition_menu.form.field_settings.multiple.message']}
										</Typography>
									</Box>

									<IOSSwitch checked={!!currentStates?.multiple} onChange={({
              target: {
                checked
              }
            }) => handleUpdateProperty('multiple', checked)} color='primary' />
								</Box>

								<Collapse in={currentStates.multiple}>
									<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} px={4}>
										<Box pl={2}>
											<Typography variant='h2'>
												{intl.messages['edition_menu.form.field_settings.limit_choices']}
											</Typography>
											<Typography variant='body1'>
												{intl.messages['edition_menu.form.field_settings.limit_choices.message']}
											</Typography>
										</Box>

										<Box width='160px' display='grid' gridTemplateColumns='1fr 1fr' gap='4px' alignItems='center' className='list-container'>
											{currentStates.limit_choices ? <TextField type='number' variant='outlined' value={currentStates.limit} onChange={({
                  target: {
                    value
                  }
                }) => handleUpdateProperty('limit', value)} /> : <div />}
											<Box width='100%' display='flex' justifyContent='flex-end'>
												<IOSSwitch checked={!!currentStates?.limit_choices} onChange={({
                    target: {
                      checked
                    }
                  }) => handleUpdateProperty('limit_choices', checked)} color='primary' />
											</Box>
										</Box>
									</Box>
								</Collapse>

								<Box px={4}>
									<div className='property-header'>
										<div className='line-before' />
										<Typography variant='h2' component='label'>
											<b>Options Settings</b>
											{/* <b><FormattedMessage id='edition_menu.answer.settings_modal.buttons' /></b> */}
										</Typography>
										<div className='line-after' />
									</div>
								</Box>

								<InputList>
									<DragDropContext onDragEnd={onOptionsDragEnd}>
										<Droppable droppableId='field-droppable'>
											{provided => <List {...provided.droppableProps} ref={provided.innerRef} dense>
													{_.map(currentStates.list, (option, index) => <Draggable key={option._id} draggableId={option._id} index={index}>
															{provided => <ListItem ref={provided.innerRef} {...provided.draggableProps}>
																	<div {...provided.dragHandleProps}>
																		<DragIndicator />
																	</div>

																	<OptionItem key={option._id} index={index} option={option} handleOptionUpdate={handleOptionUpdate} removeOption={removeOption} />
																</ListItem>}
														</Draggable>)}
													{provided.placeholder}
												</List>}
										</Droppable>
									</DragDropContext>

									<div>
										<Button variant='contained' color='secondary' onClick={() => addOption()}>
											<FormattedMessage id='edition_menu.form.add_option' />
										</Button>
									</div>
								</InputList>
							</DragableProperties>}
					</div>
					<input ref={fileImportRef} style={fileImporterStyle} type='file' accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' onChange={handleFileImport} />
				</ModalContent>

				<DialogActions>
					<Button variant='outlined' color='primary' size='large' onClick={() => fileImportRef.current?.click()} startIcon={<Publish />}>
						{intl.messages['button.import_file']}
					</Button>

					<Button variant='outlined' size='large' onClick={() => close()}>
						{intl.messages['button.cancel']}
					</Button>

					{currentStates.isImporting ? <Button variant='outlined' color='secondary' size='large' onClick={() => nextStep()}>
							{intl.messages['button.next']}
						</Button> : <Button variant='outlined' color='secondary' size='large' onClick={() => confirmOptions()}>
							{intl.messages['button.confirm']}
						</Button>}
				</DialogActions>
			</ModalWrapper>;
});
ListSettingsModal.propTypes = {
  field_id: PropTypes.string,
  field_settings: PropTypes.object,
  handleChangeFieldSettings: PropTypes.func,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool
};
ListSettingsModal.displayName = 'ListSettingsModal';
export default ListSettingsModal;
const OptionItem = memo(function OptionItem({
  index,
  option,
  handleOptionUpdate,
  removeOption
}) {
  return <div className='option-container'>
			<span>{`${index + 1}.`}</span>
			<TextField variant='standard' fullWidth value={option.value} onChange={({
      target: {
        value
      }
    }) => handleOptionUpdate(value, index)} />
			<IconButton size='small' onClick={() => removeOption(index)}>
				<Close />
			</IconButton>
		</div>;
});
OptionItem.propTypes = {
  index: PropTypes.number,
  option: PropTypes.object,
  handleOptionUpdate: PropTypes.func,
  removeOption: PropTypes.func
};
function initList(list) {
  return _.map(list, option => ({
    _id: nanoid(),
    value: option
  }));
}
function extractListOptions(list) {
  return _.map(list, option => option.value);
}