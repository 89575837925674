import _assign from 'lodash/assign'

export class UrlAssetIdentifier {
	constructor({ url, instagramId }) {
		if (!url) {
			throw new Error('UrlAssetIdentifier needs an URL')
		}
		_assign(this, { url, instagramId })
	}
}
