import axios from 'axios'
import { API_URL } from 'lib/constants'
import * as localStorageSvc from 'services/LocalStorageSvc'

const FilesSvc = {
	get,
	getFavicons,
	getLoaders,
	del,
	update,
	searchByQuery,
	partialUpdate,
	transcription,
	jsonToVtt,
}

export default FilesSvc

async function searchByQuery({ query, project }) {
	try {
		const { data } = await axios.get(`${API_URL}/files`, {
			params: {
				project,
				...query,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data
	} catch (error) {
		console.log('getFiles', error)
		return {
			success: false,
		}
	}
}

async function get({ file_id, project }) {
	try {
		const { data } = await axios.get(`${API_URL}/files/${file_id}`, {
			params: {
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data.file
	} catch (error) {
		console.log('getFile', error)
		return {
			success: false,
		}
	}
}

async function getFavicons({ project }) {
	try {
		const { data } = await axios.get(`${API_URL}/files/favicons`, {
			params: {
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data.faviconList
	} catch (error) {
		console.log('getFavicons', error)
		return []
	}
}

async function getLoaders({ project }) {
	try {
		const { data } = await axios.get(`${API_URL}/files/loaders`, {
			params: {
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data.loaders
	} catch (error) {
		console.log('getLoaders', error)
		return []
	}
}

async function update({ file, file_id, project }) {
	try {
		const { data } = await axios.put(`${API_URL}/files/${file_id}`, file, {
			params: {
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		return data
	} catch (error) {
		console.log('updateFile', error)
		return {
			success: false,
		}
	}
}

async function partialUpdate({ partialData, file_id, project }) {
	try {
		const { data } = await axios.patch(`${API_URL}/files/${file_id}`, partialData, {
			params: {
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
	} catch (err) {
		console.log('updateFile', err)
		const { data } = err.response
		throw new Error(data.message)
	}
}

async function del({ file_id, project }) {
	if (!file_id) {
		throw new Error('deleteFile() - Invalid id')
	}

	return axios.delete(`${API_URL}/files/${file_id}`, {
		params: {
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
}

async function transcription({ data, project }) {
	return axios.post(`${API_URL}/files/transcription`, data, {
		params: {
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
}

async function jsonToVtt({ data, project }) {
	return axios.post(`${API_URL}/files/subtitle`, data, {
		params: {
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
}
