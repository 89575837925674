import * as React from 'react'

function Svg9Number(props) {
	return (
		<svg width='1em' height='1em' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M2.747 3.125a1.32 1.32 0 01-.407.325 1.092 1.092 0 01-.516.123c-.246 0-.46-.06-.644-.181a1.186 1.186 0 01-.422-.51c-.1-.22-.15-.464-.15-.73 0-.285.054-.542.162-.77.109-.229.263-.403.462-.525.2-.12.432-.181.698-.181.422 0 .754.158.996.474.244.315.366.745.366 1.29v.158c0 .83-.164 1.436-.492 1.819-.328.38-.823.576-1.486.586H1.21v-.457h.114c.448-.008.791-.124 1.031-.349.24-.226.372-.584.393-1.072zm-.835 0a.83.83 0 00.501-.167.98.98 0 00.337-.413v-.217c0-.355-.077-.644-.231-.867-.155-.223-.35-.334-.586-.334a.695.695 0 00-.575.275c-.144.182-.216.422-.216.721 0 .291.069.531.208.72.14.188.328.282.562.282z'
				fill='#000'
			/>
		</svg>
	)
}

export default Svg9Number
