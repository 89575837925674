import { css } from '@emotion/react';
import { Delete } from '@mui/icons-material';
import { Chip as MuiChip, IconButton, ListItem as MuiListItem, ListItemSecondaryAction, ListItemText as MuiListItemText, Tooltip } from '@mui/material';
import styled from '@styled';
import { ConfirmationModal } from 'components/Modals';
import humanizeDuration from 'humanize-duration';
import dayjs from 'lib/dayjs';
import PropTypes from 'prop-types';
import { forwardRef, memo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
const ListItem = styled(MuiListItem)`
	font-size: 16px;
	min-height: 50px;
`;
const ListItemText = styled(MuiListItemText)`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	max-width: 200px;
`;
const errorStyle = ({
  theme
}) => css`
	color: ${theme.palette.error.main};
	border: 1px solid ${theme.palette.error.main};
`;
const Chip = styled(MuiChip)`
	cursor: inherit;
	margin-right: 8px;
	${({
  $error
}) => $error && errorStyle}
`;
const AccountItem = forwardRef(function AccountItem({
  account,
  expiration,
  isSelected,
  onSelect,
  onDelete,
  children
}, ref) {
  const intl = useIntl();
  const deleteModalRef = useRef();
  const hasSecondaryAction = expiration || Boolean(onDelete) || Boolean(children);
  return <ListItem ref={ref} dense button onClick={onSelect} selected={isSelected}>
			<ListItemText disableTypography primary={`@${account.username}`} />
			{hasSecondaryAction && <ListItemSecondaryAction>
					{expiration && <ExpirationChip expiresAt={account.expiresAt} language={intl.locale} />}
					{onDelete && <>
							<IconButton size='small' edge='end' onClick={() => deleteModalRef.current?.open()}>
								<Delete />
							</IconButton>

							<ConfirmationModal size='sm' confirmationModalRef={deleteModalRef} title={<FormattedMessage id='integrations.instagram.delete_account.title' />} onConfirm={onDelete} firstLine={<FormattedMessage id='integrations.instagram.delete_account.instructions.first_line' />} secondLine={<FormattedMessage id='integrations.instagram.delete_account.instructions.second_line' />} confirmText={<FormattedMessage id='common.yes' />} cancelText={<FormattedMessage id='common.no' />} />
						</>}
					{children}
				</ListItemSecondaryAction>}
		</ListItem>;
});
AccountItem.propTypes = {
  account: PropTypes.object,
  link: PropTypes.bool,
  expiration: PropTypes.bool,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
  children: PropTypes.node
};
export default AccountItem;
const ExpirationChip = memo(function ExpirationChip({
  expiresAt,
  language
}) {
  if (typeof expiresAt === 'undefined') {
    return null;
  }
  const expiresIn = dayjs(expiresAt).utc().diff(dayjs(), 'millisecond');
  const timeRemaining = expiresIn > 0 ? humanizeDuration(expiresIn, {
    units: ['d', 'h', 'm'],
    round: true,
    largest: 1,
    language
  }) : <FormattedMessage id='common.expired' />;
  return <Tooltip title={expiresIn > 0 ? <FormattedMessage id='common.expires_in' values={{
    timeRemaining
  }} /> : <FormattedMessage id='common.expired' />}>
			<Chip $error={expiresIn <= 0} label={timeRemaining} variant='outlined' />
		</Tooltip>;
});
ExpirationChip.propTypes = {
  expiresAt: PropTypes.string,
  language: PropTypes.string
};