import { BarsArrowDownIcon, BarsArrowUpIcon, CalendarIcon } from '@heroicons/react/20/solid';
import { DateRange, DriveFileRenameOutline, FiberManualRecord } from '@mui/icons-material';
import { Box, Button, Checkbox, DialogActions, DialogContent, InputLabel, List, ListItem, ListItemButton, ListItemSecondaryAction, ListItemText, MenuItem, SvgIcon, TextField, Tooltip, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import styled from '@styled';
import { useQuery } from '@tanstack/react-query';
import SearchBar from 'atoms/SearchBar';
import BannerWidgetElement from 'components/BannerWidgetElement';
import Loader from 'components/Loader';
import { SnackeetCardLabel } from 'components/styled/SnackeetCard';
import { STORIES_COLORS } from 'lib/constants';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentProjectId, getCurrentProjectName } from 'redux/project/selectors';
import PublishedStoriesSvc from 'services/PublishedStoriesSvc';
import { useImmer } from 'use-immer';
import { ModalWrapper } from '../ModalWrapper';
const PUBLISHED_STORY_FIELDS = ['story_id', 'name', 'active', 'snapshot_url_png', 'created_at', 'type', 'pages'];
const DESCRIPTION_SECONDARY_PROPS = {
  variantMapping: {
    body2: 'div'
  }
};
export default function AddWidgetStoryModal({
  trigger,
  widget,
  onStoryAdd
}) {
  const modalRef = useRef();
  const intl = useIntl();
  const project = useSelector(getCurrentProjectId);
  const projectName = useSelector(getCurrentProjectName);
  const [currentSelectedStoriesId, setCurrentSelectedStoriesId] = useImmer(_.map(widget.stories, 'story_id'));
  const [filterValue, setFilterValue] = useState('');
  const [sortBy, setSortBy] = useState('created_at');
  const [order, setOrder] = useState('desc');
  const {
    isLoading,
    data: publishedStories
  } = useQuery({
    queryKey: ['publishedStories', {
      project,
      isAmp: widget.isAmp
    }],
    queryFn: async ({
      queryKey
    }) => {
      const {
        project,
        isAmp
      } = queryKey[1];
      const publishedStories = await PublishedStoriesSvc.getAll({
        project,
        fields: PUBLISHED_STORY_FIELDS.join(',')
      });
      return _.sortBy(_.flatMap(publishedStories, story => {
        const shouldInclude = isAmp && story.type === 'amp' || !isAmp && story.type !== 'amp';
        if (!shouldInclude) return [];
        return {
          ...story,
          created_at: new Date(story.created_at)
        };
      }), 'created_at').reverse();
    }
  });
  const onConfirm = useCallback(() => {
    const updatedWidgetStories = formatSelectedStories({
      currentWidgetStories: widget.stories,
      publishedStories,
      currentSelectedStoriesId
    });
    onStoryAdd(updatedWidgetStories);
    modalRef.current.close();
  }, [currentSelectedStoriesId, onStoryAdd, publishedStories, widget.stories]);

  // Update inner state selected stories based on the stories provided in props
  useEffect(() => {
    const updatedSelectedStoriesId = _.map(widget.stories, 'story_id');
    if (!_.isEqual(updatedSelectedStoriesId, currentSelectedStoriesId)) {
      setCurrentSelectedStoriesId(() => {
        return updatedSelectedStoriesId;
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [widget.stories, setCurrentSelectedStoriesId]);

  // Open modal on mount when no selected stories after a certain delay
  useEffect(() => {
    if (_.isEmpty(widget.stories)) {
      setTimeout(() => modalRef.current?.open(), 1000);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);
  return <ModalWrapper size='md' ref={modalRef} trigger={trigger} title={<FormattedMessage id='modal.widget_banner.story_add.header' />} PaperProps={{
    sx: {
      height: '100%'
    }
  }}>
			<StyledDialogContent>
				{isLoading ? <Loader $heightPx={100} size={50} name='ThreeBounce' /> : <>
						<Box px={1} mb={1} fontSize={14} display='flex' gap={2} alignItems='flex-end' justifyContent='space-between'>
							<Box flexGrow={1} maxWidth={500}>
								<p>
									<FormattedMessage id='modal.widget_banner.story_add.description' values={{
                projectName
              }} />
								</p>

								<SearchBar value={filterValue} onChange={value => setFilterValue(value)} placeholder={intl.formatMessage({
              id: 'placeholder.search'
            })} variant='outlined' size='small' sx={{
              boxShadow: 0,
              pb: 0,
              maxWidth: 'unset'
            }} />
							</Box>
							<Box>
								<InputLabel sx={{
              fontWeight: 500,
              textAlign: 'start'
            }}>
									<FormattedMessage id='common.sort_by' />
								</InputLabel>
								<TextField select InputProps={{
              startAdornment: <SvgIcon>
												{sortBy === 'name' ? <DriveFileRenameOutline /> : <DateRange />}
											</SvgIcon>
            }} size='small' value={sortBy} onChange={evt => setSortBy(evt.target.value)} sx={{
              minWidth: 170,
              backgroundColor: 'background.paper'
            }}>
									<MenuItem value='name'>
										<FormattedMessage id='common.name' />
									</MenuItem>
									<MenuItem value='created_at'>Date</MenuItem>
								</TextField>
							</Box>
							<Box>
								<InputLabel sx={{
              fontWeight: 500,
              textAlign: 'start'
            }}>
									<FormattedMessage id='common.order' />
								</InputLabel>
								<TextField select InputProps={{
              startAdornment: <SvgIcon>
												{order === 'desc' ? <BarsArrowDownIcon /> : <BarsArrowUpIcon />}
											</SvgIcon>
            }} size='small' value={order} onChange={evt => setOrder(evt.target.value)} sx={{
              minWidth: 170,
              backgroundColor: 'background.paper'
            }}>
									<MenuItem value='desc'>
										<FormattedMessage id='snackeet_metrics.label.desc' />
									</MenuItem>
									<MenuItem value='asc'>
										<FormattedMessage id='snackeet_metrics.label.asc' />
									</MenuItem>
								</TextField>
							</Box>
						</Box>
						<StoriesList widget={widget} publishedStories={publishedStories} filterValue={filterValue} sortBy={sortBy} order={order} widgetStories={widget.stories} selectedStoriesId={currentSelectedStoriesId} setCurrentSelectedStoriesId={setCurrentSelectedStoriesId} />
					</>}
			</StyledDialogContent>

			<DialogActions>
				<Button variant='text' onClick={() => modalRef.current?.close()}>
					<FormattedMessage id='button.cancel' />
				</Button>

				<Button variant='outlined' color='secondary' onClick={onConfirm} disabled={_.isEqual(_.map(widget.stories, 'story_id'), currentSelectedStoriesId)}>
					<FormattedMessage id='button.confirm' />
				</Button>
			</DialogActions>
		</ModalWrapper>;
}
AddWidgetStoryModal.propTypes = {
  trigger: PropTypes.node,
  widget: PropTypes.object.isRequired,
  selectedStories: PropTypes.array,
  onStoryAdd: PropTypes.func
};
function StoriesList({
  widget,
  publishedStories,
  filterValue,
  sortBy,
  order,
  widgetStories,
  selectedStoriesId,
  setCurrentSelectedStoriesId
}) {
  const sortedStories = arraySort(publishedStories, getComparator(order, sortBy));
  const filteredStories = filterValue ? _.filter(sortedStories, story => _.includes(story.name.toLowerCase(), filterValue.toLowerCase())) : sortedStories;
  const handleItemClick = useCallback(storyId => () => {
    if (_.some(widgetStories, {
      story_id: storyId
    })) return;
    setCurrentSelectedStoriesId(draft => {
      if (draft.includes(storyId)) {
        _.remove(draft, id => id === storyId);
      } else {
        draft.push(storyId);
      }
    });
  }, [setCurrentSelectedStoriesId]);
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  }
  function arraySort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  if (_.isEmpty(filteredStories)) {
    return <Box width='100%' height='200px' display='flex' justifyContent='center' alignItems='center'>
				<Typography color='textSecondary' variant='h2'>
					<FormattedMessage id='modal.widget_banner.story_add.no_stories' />
				</Typography>
			</Box>;
  }
  return <StyledList>
			{_.map(filteredStories, story => {
      const isSelected = selectedStoriesId.includes(story.story_id);
      return <ListItem key={story.story_id}>
						<ListItemButton onClick={handleItemClick(story.story_id)} selected={isSelected} disabled={_.some(widgetStories, {
          story_id: story.story_id
        })}>
							<Box position='relative'>
								<BannerWidgetElement widget={widget} story={story} size={50} $disabledAnimation={true} disabledName $storyBorder={widget.appearance.story_border} />
								<Tooltip title={story.active ? <FormattedMessage id='common.active' /> : <FormattedMessage id='common.disabled' />}>
									<StoryStatus $isActive={story.active}>
										<FiberManualRecord fontSize='small' />
									</StoryStatus>
								</Tooltip>
							</Box>
							<ListItemText primary={<b>{story.name}</b>} secondary={<StoryDescription story={story} />} secondaryTypographyProps={DESCRIPTION_SECONDARY_PROPS} />
							<ListItemSecondaryAction>
								{_.some(widgetStories, {
              story_id: story.story_id
            }) ? <Typography variant='body2' sx={{
              fontWeight: 'bold'
            }}>
										<FormattedMessage id='common.added' />
									</Typography> : <Checkbox checked={isSelected} />}
							</ListItemSecondaryAction>
						</ListItemButton>
					</ListItem>;
    })}
		</StyledList>;
}
StoriesList.propTypes = {
  widget: PropTypes.object.isRequired,
  stories: PropTypes.array,
  filterValue: PropTypes.string,
  sortBy: PropTypes.string,
  order: PropTypes.string,
  selectedStoriesId: PropTypes.array,
  setState: PropTypes.func
};
function StoryDescription({
  story
}) {
  return <Box display='flex' flexDirection='column' alignItems='flex-start' gap={1}>
			<SnackeetCardLabel $color={STORIES_COLORS[story.type]}>
				<FormattedMessage id={`common.snackeet_type.${story.type}`} />
			</SnackeetCardLabel>
			<Box display='flex' alignItems='center' pl={0.5}>
				<SvgIcon fontSize='small'>
					<CalendarIcon />
				</SvgIcon>
				<div>
					<FormattedMessage id='stories_list.created' />
					&nbsp;
					<FormattedDate value={new Date(story.created_at)} year='numeric' month='numeric' day='numeric' />
				</div>
			</Box>
		</Box>;
}
StoryDescription.propTypes = {
  story: PropTypes.object
};
const StyledList = styled(List)`
	display: grid;
	grid-template-columns: repeat(2, minmax(auto, 350px));
	justify-content: center;
	grid-auto-flow: dense;
	overflow: auto;

	.MuiListItemText-primary {
		padding-left: ${({
  theme
}) => theme.spacing(0.5)};
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		&:hover {
			white-space: initial;
		}
	}

	.MuiListItemText-secondary {
		.MuiSvgIcon-root {
			margin-right: ${({
  theme
}) => theme.spacing(1)};
		}
	}
`;
const StyledDialogContent = styled(DialogContent)`
	display: flex;
	flex-direction: column;
`;
const StoryStatus = styled.div`
	position: absolute;
	bottom: 0px;
	right: 18px;
	border-radius: 50%;
	background-color: ${({
  theme
}) => theme.palette.background.paper};
	color: ${({
  $isActive
}) => $isActive ? green[500] : red[500]};
	display: grid;
	place-items: center;
`;
function formatSelectedStories({
  currentWidgetStories,
  publishedStories,
  currentSelectedStoriesId
}) {
  return _.flatMap(currentSelectedStoriesId, storyId => {
    const addedStory = _.find(currentWidgetStories, {
      story_id: storyId
    });
    if (addedStory) {
      return addedStory;
    } else {
      const story = _.find(publishedStories, {
        story_id: storyId
      });
      const firstPageBackground = _.get(story, 'pages[0].background_element');
      if (firstPageBackground?.type === 'video') {
        return {
          story_id: story.story_id,
          thumbnail: firstPageBackground.value,
          thumbnail_type: 'video',
          snapshot_url_png: story.snapshot_url_png,
          name: story.name
        };
      } else if (story) {
        return {
          story_id: story.story_id,
          snapshot_url_png: story.snapshot_url_png,
          name: story.name
        };
      }
    }
    return [];
  });
}