import 'prismjs/themes/prism-tomorrow.css';
import { Box, DialogActions, DialogContent, DialogContentText, Link } from '@mui/material';
import { ModalWrapper } from 'components/Modals';
import { ScriptContainer } from 'containers/Editor/SharingView/SharingView.style';
import useClipboard from 'lib/hooks/useClipboard';
import SubmitButton from 'organisms/buttons/SubmitButton';
import Prism from 'prismjs';
import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
const EmbedBannerWidgetCodeModal = forwardRef(function EmbedBannerWidgetCodeModal({
  widget,
  onClose
}, ref) {
  const {
    copyText
  } = useClipboard();
  const widgetCode = createBannerWidgetScript(widget);
  useEffect(() => {
    if (widget._id) {
      setTimeout(Prism.highlightAll, 0);
    }
  }, [widget]);
  return <ModalWrapper ref={ref} size='sm' title={<FormattedMessage id='modal.widget_banner_code.header' />} onClose={onClose} onOpen={() => setTimeout(Prism.highlightAll, 0)}>
			<DialogContent dividers>
				<DialogContentText component='div'>
					<FormattedMessage id='modal.widget_banner_code.instructions' />
				</DialogContentText>

				<ScriptContainer>
					<code className='language-html'>{widgetCode}</code>
				</ScriptContainer>

				<Box mt={2}>
					<DialogContentText component='div'>
						💡 &nbsp; <FormattedMessage id='sharing_view.display_mode.instruction_variables' />
						<Box mt={1}>
							<Link href='https://help.snackeet.com/en/articles/6830409-customizing-webstories-with-variables-using-the-snackeet-widget' target='_blank' underline='hover'>
								<FormattedMessage id='sharing_view.display_mode.variables_article_link' />
							</Link>
						</Box>
					</DialogContentText>
				</Box>
			</DialogContent>

			<DialogActions>
				<SubmitButton onClick={() => copyText(widgetCode)}>
					<FormattedMessage id='modal.widget_banner_code.button.copy' />
				</SubmitButton>
			</DialogActions>
		</ModalWrapper>;
});
EmbedBannerWidgetCodeModal.propTypes = {
  widget: PropTypes.object,
  onClose: PropTypes.func
};
export default EmbedBannerWidgetCodeModal;
function createBannerWidgetScript(widget) {
  const widgetId = widget._id;
  const projectId = widget.project;
  const widgetAlias = widget.dynamic_alias;
  if (!widgetId && !widgetAlias) {
    return '';
  }
  const widgetIdentifier = widgetAlias ? `widgetScript.dataset.snktDynamicAlias="${widgetAlias}";` : `widgetScript.dataset.snktId="${widgetId}";`;
  return `
			<!-- Snackeet -->
			<script>
				var currentScript = document.currentScript;
				var widgetScript = document.createElement('script');
				widgetScript.src="https://widget.snackeet.com/script.js";
				widgetScript.defer = true;
				widgetScript.dataset.snktProjectId="${projectId}";
				${widgetIdentifier}
				currentScript.insertAdjacentElement('afterend', widgetScript);
			</script>
			<!-- End Snackeet -->
		`;
}