import { Cancel, Create, DragIndicator, PhotoLibrary, PlayArrow, VideoLibrary, Warning } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { PREVIEW_HEIGHT, PREVIEW_WIDTH } from 'lib/constants';
import { isStoryNotScheduled } from 'lib/utils';
import PropTypes from 'prop-types';
import { forwardRef, memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { StoryDimmer } from '../BannerBubble';
import { ActionButtons, BannerCardActionArea, BannerCardContainer, CardCaption, Overlay, PageStacks, PlayIconContainer, StoryCardContainer } from './BannerCard.style';
const BannerCard = forwardRef(function BannerCard({
  widget,
  story,
  platform = 'desktop',
  size,
  draggableProps,
  dragHandleProps,
  elementIndex,
  onSelect,
  onDelete,
  ...delegatedProps
}, ref) {
  const intl = useIntl();
  const isStoryDisabled = story.active === false || isStoryNotScheduled(story.story_schedule);
  const hasPlayIcon = widget.appearance.banner.has_play_icon;
  const cardWidth = widget.placement[platform].width;
  const cardHeight = widget.placement[platform].height ?? cardWidth * PREVIEW_HEIGHT / PREVIEW_WIDTH;
  const captionFontSize = widget.appearance[platform].fontSize;
  const cardRadius = widget.appearance.border_radius ?? 16;
  const caption = story.display_name ?? story.name;
  const hasOptions = typeof onSelect === 'function' || typeof onDelete === 'function' || Boolean(dragHandleProps);
  let $height = cardHeight;
  let $width = cardWidth;
  let $fontSize = captionFontSize;
  let $borderRadius = cardRadius;

  // Custom size specified (dimensions of showcased banner in widget editor)
  if (size) {
    $height = size;
    $width = size * (cardWidth / cardHeight);
    // Compute a fontSize proportional to thse real fontSize
    $fontSize = parseFloat((captionFontSize * ($width / cardWidth)).toFixed(1));
    $borderRadius = parseFloat((cardRadius * ($width / cardWidth)).toFixed(1));
  }
  const handleElementClick = useCallback(evt => {
    typeof onSelect === 'function' && onSelect(evt);
  }, [onSelect]);
  return <BannerCardActionArea {...draggableProps}>
			<BannerCardContainer ref={ref} $width={$width} $height={$height} onClick={handleElementClick} style={{
      '--banner-card-radius': `${$borderRadius}px`
    }} {...delegatedProps}>
				<PageStacks $color='#898F97'>
					<div />
					<div />
				</PageStacks>

				<StoryCard cardShadow={widget.appearance.card_shadow} width={widget.placement[platform].size} thumbnail={story.thumbnail ?? story.snapshot_url_png} thumbnailType={story.thumbnail_type ?? 'image'} />

				{(caption || hasPlayIcon) && <Overlay>
						{hasPlayIcon && <PlayIconContainer>
								<PlayArrow />
							</PlayIconContainer>}
						{caption && <CardCaption $fontSize={$fontSize}>{caption}</CardCaption>}
					</Overlay>}

				{isStoryDisabled && <>
						<StoryDimmer $settings={widget.appearance.story_border} />
						<Tooltip title={intl.messages['widget_editor.stories.alert.story_disabled']} position='top'>
							<Box position='absolute' top='-25px' left='calc(50% - 10px)'>
								<Warning fontSize='small' color='secondary' />
							</Box>
						</Tooltip>
					</>}
			</BannerCardContainer>

			{hasOptions && <ActionButtons>
					{typeof onSelect === 'function' && <IconButton size='small' onClick={onSelect} className='hoverable'>
							<Create />
						</IconButton>}

					{dragHandleProps && <IconButton size='small' {...dragHandleProps}>
							<DragIndicator />
						</IconButton>}

					{typeof onDelete === 'function' && <IconButton size='small' onClick={onDelete} className='hoverable'>
							<Cancel />
						</IconButton>}
				</ActionButtons>}
		</BannerCardActionArea>;
});
BannerCard.propTypes = {
  widget: PropTypes.object.isRequired,
  story: PropTypes.object.isRequired,
  platform: PropTypes.string,
  size: PropTypes.number,
  draggableProps: PropTypes.object,
  dragHandleProps: PropTypes.object,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func
};
export default BannerCard;
const MediaPlaceholder = memo(function MediaPlaceholder({
  icon
}) {
  return <div>{icon}</div>;
});
MediaPlaceholder.propTypes = {
  icon: PropTypes.node
};
const StoryCard = forwardRef(function StoryCard({
  cardShadow,
  borderRadius,
  thumbnail,
  thumbnailType,
  children,
  ...delegatedProps
}, ref) {
  const renderMedia = () => {
    if (thumbnailType === 'image') {
      return thumbnail ? <img src={thumbnail} /> : <MediaPlaceholder icon={<PhotoLibrary />} />;
    }
    return thumbnail ? <video src={thumbnail} autoPlay loop muted /> : <MediaPlaceholder icon={<VideoLibrary />} />;
  };
  return <StoryCardContainer ref={ref} $cardShadow={cardShadow} $borderRadius={borderRadius} {...delegatedProps}>
			{children || renderMedia()}
		</StoryCardContainer>;
});
StoryCard.propTypes = {
  cardShadow: PropTypes.bool,
  borderRadius: PropTypes.number,
  thumbnail: PropTypes.string,
  thumbnailType: PropTypes.string,
  children: PropTypes.node
};