import { css } from '@emotion/react';
import { ButtonBase, TextField } from '@mui/material';
import styled from '@styled';
import { quizImageStatusSvg, quizTextStatusSvg } from 'lib/constants';

// Components for Survey/Quiz

export const SurveyNumberUser = styled.div`
	margin-right: 10px;
	align-self: flex-end;
	display: flex;
	align-items: center;
	font-size: 15px;
	color: #484747;
	padding: 2px 15px;
	border: 1px solid;
	border-radius: 20px;
	background: white;

	svg {
		height: 15px;
		width: 15px;
	}
`;
export const PercentageProgressBar = styled.div`
	position: absolute;
	left: -100%;
	width: 100%;
	height: 100%;
	transform: translateX(${({
  percentage
}) => percentage ?? 0}%);
	background-color: var(--percentage-background-color);
`;
export const ImageAnswerOption = styled.div`
	position: absolute;
	inset: 0;
	overflow: hidden;

	& > img {
		height: 100%;
		width: 100%;
		object-fit: cover;
		position: absolute;
		top: 0;
		left: 0;
	}

	& > span {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 25px;
		position: absolute;
		bottom: 0;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.4);
		padding: 6px 4px;
		text-align: center;
		overflow: hidden;
		word-break: break-word;
		white-space: break-spaces;
	}

	&::before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0.65;
		z-index: 1;
		transform-origin: bottom;
		transition: transform 0.3s cubic-bezier(0.3, 0, 0, 1);
		transform: scaleY(var(--percentage-transform-scale, 0));
		background-color: var(--percentage-background-color);
	}
`;
export const TextQuizAnswerChoice = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 1.5em;
	width: 1.5em;
	max-height: 40px;
	max-width: 40px;
	border-radius: 50%;
	padding: 0.3em;
	border: 0.1em solid;
	border-color: transparent;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: 60%;

	&.correct {
		border-color: var(--correct-color);
		color: var(--correct-color);
		background-image: url(${quizTextStatusSvg.correct});
	}

	&.incorrect {
		border-color: var(--incorrect-color);
		color: var(--incorrect-color);
		background-image: url(${quizTextStatusSvg.incorrect});
	}
`;
export const TextAnswerChoiceLetter = styled(TextQuizAnswerChoice)`
	border: 0.08em solid;
	padding: 0.31em;
	margin-right: 1em;
	background-position: 50%;
	background-size: 1.5em;
	background-color: transparent;

	font-family: Poppins, sans-serif;
	font-size: 1em;
	line-height: 1em;
	font-weight: 500;
`;
export const ImageQuizAnswerChoice = styled.div`
	position: absolute;
	top: 5px;
	right: 5px;
	border-radius: 50%;
	height: 25px;
	width: 25px;
	z-index: 1;
	box-shadow: 0 0 0 0.125em rgba(255, 255, 255, 1) inset;
	background-color: ${({
  theme
}) => theme.palette.primary.main};
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 50%;
`;
export const ImageAnswerChoiceLetter = styled(ImageQuizAnswerChoice)`
	border: 0.05em solid;
	padding: 0.31em;
	background-position: 50%;
	background-size: 1.5em;
	background-color: rgba(0, 0, 0, 0.3);

	font-family: Poppins, sans-serif;
	font-size: 1em;
	line-height: 1em;
	font-weight: 500;
`;

// Main components for types of buttons

export const TextAnswerButton = styled(ButtonBase)`
	pointer-events: auto;
	overflow: hidden;
	flex-shrink: 0;

	&.MuiButtonBase-root {
		position: relative;
		width: 290px;
		min-height: 40px;
		margin: ${({
  theme
}) => theme.spacing(0.5, 'auto')};
		padding: ${({
  theme
}) => theme.spacing(1, 4)};
		border-radius: 60px;
		display: flex;
		align-items: center;
		text-transform: initial;
		line-height: 1.5;
		word-break: break-word;
		white-space: break-spaces;
	}
`;
export const TextAnswerButtonWithResults = styled(TextAnswerButton)`
	&.MuiButtonBase-root {
		padding: ${({
  theme
}) => theme.spacing(1)};
		display: grid;
		grid-template-columns: minmax(20px, 44px) 1fr minmax(20px, 44px);
		grid-gap: 4px;
		align-items: center;
	}

	--percentage-background-color: rgba(0, 0, 0, 0.2);

	&.correct {
		background-color: var(--correct-color) !important;
	}

	&.incorrect {
		background-color: var(--incorrect-color) !important;
	}

	&.selected.correct {
		color: #fff !important;
		border: none !important;
		--percentage-background-color: var(--correct-color-shaded);
	}

	&.selected.incorrect {
		color: #fff !important;
		border: none !important;
		--percentage-background-color: var(--incorrect-color-shaded);
	}
`;
export const ImageAnswerButton = styled(ButtonBase)`
	pointer-events: auto;

	&.MuiButtonBase-root {
		margin: ${({
  theme
}) => theme.spacing(1)};
		cursor: inherit;
		&:hover {
			transform: scale(1.02);
		}
	}
	&.MuiButtonBase-root.Mui-disabled {
		opacity: 0.9 !important;
		filter: grayscale(100%);
	}

	${ImageAnswerOption} {
		border-radius: ${({
  style
}) => style?.borderRadius};
	}

	&.selected:not(.correct):not(.incorrect)::after {
		content: '';
		position: absolute;
		inset: 0;
		background-color: ${({
  theme
}) => theme.palette.action.active};
		border-radius: ${({
  style
}) => style?.borderRadius};
	}

	&.correct {
		${ImageQuizAnswerChoice} {
			background-color: var(--correct-color);
			background-image: url(${quizImageStatusSvg.correct});
		}
	}

	&.incorrect {
		${ImageQuizAnswerChoice} {
			background-color: var(--incorrect-color);
			background-image: url(${quizImageStatusSvg.incorrect});
		}
	}
`;
export const StyledActionButton = styled(TextAnswerButton)`
	input {
		opacity: 0;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		max-width: 100%;
		height: 100%;
	}
`;

// Other Answer
export const StyledTextField = styled(TextField)`
	.MuiInputBase-root {
		cursor: inherit;
		background-color: white;
		font-size: inherit;
		font-family: inherit;
		font-weight: inherit;
		text-decoration: inherit;
		min-height: 40px;
	}
	.MuiInputBase-input {
		cursor: inherit;
		text-align: center;
	}
	.MuiOutlinedInput-root {
		border-radius: inherit;
	}
	.MuiOutlinedInput-input {
		padding: 10px;
	}
`;
StyledTextField.defaultProps = {
  variant: 'outlined',
  fullWidth: true
};

// Common for all types of answers
const textButtonsContainer = css`
	& > .answers {
		flex-direction: column;
		align-items: center;
	}
`;
const imageButtonsContainer = css`
	& > .answers {
		justify-content: center;
		flex-wrap: wrap;
	}
`;
export const AnswersContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-height: 100%;
	border-radius: 4px;
	padding: ${({
  theme
}) => theme.spacing(1, 2, 1.25)};
	overflow: auto;
	overflow-x: hidden;

	& > .answers {
		width: 100%;
		display: flex;
		overflow-y: auto;
		overflow-x: hidden;
	}

	${({
  $hasImageButtons
}) => $hasImageButtons ? imageButtonsContainer : textButtonsContainer};

	${TextAnswerButton}, ${ImageAnswerButton}, ${StyledActionButton}, ${StyledTextField} {
		cursor: ${({
  $isSelected
}) => $isSelected ? 'inherit' : 'pointer'};
		pointer-events: ${({
  $isSelected
}) => $isSelected ? 'none' : 'auto'};
	}
`;