import styled from '@styled';
import { extractPositionProperties } from 'lib/utils';
import PropTypes from 'prop-types';
import { memo } from 'react';
import BlockWrapper from './BlockWrapper';
const IconElement = styled.div`
	width: ${props => props.$size};
	height: ${props => props.$size};
	background-color: ${props => props.$backgroundColor};

	mask-image: ${({
  $maskImage
}) => `url(${$maskImage})`};
	mask-size: 100% 100%;
`;
function Icon({
  value,
  outer_style,
  inner_style,
  animation
}) {
  const transform = inner_style?.transform;
  const {
    positionProperties,
    visualProperties
  } = extractPositionProperties(outer_style);
  return <BlockWrapper animation={animation} positionProperties={{
    ...positionProperties
  }}>
			<div style={{
      ...visualProperties,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transform
    }}>
				<IconElement $backgroundColor={inner_style.color} $size={inner_style.fontSize} $maskImage={value} />
			</div>
		</BlockWrapper>;
}
Icon.propTypes = {
  value: PropTypes.string,
  outer_style: PropTypes.object,
  inner_style: PropTypes.object,
  animation: PropTypes.object
};
export default memo(Icon);