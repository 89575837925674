import styled from '@styled';
import { LOGO_PLATFORMS } from 'lib/constants';
import { extractPositionProperties } from 'lib/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactWhatsapp from 'react-whatsapp';
import BlockWrapper from './BlockWrapper';
export default function MessengerBlock({
  outer_style,
  animation,
  platforms,
  iconSize
}) {
  if (_.isEmpty(platforms)) {
    return null;
  }
  const {
    positionProperties,
    visualProperties
  } = extractPositionProperties(outer_style);
  const {
    borderStyle,
    blockStyle
  } = extractBlockStyle(visualProperties);
  return <BlockWrapper animation={animation} positionProperties={positionProperties}>
			<div style={{
      ...blockStyle,
      textAlign: 'center'
    }}>
				{_.map(platforms, (network, index) => renderMessengerPlatforms(network)({
        key: index,
        borderStyle,
        size: iconSize
      }))}
			</div>
		</BlockWrapper>;
}
MessengerBlock.propTypes = {
  outer_style: PropTypes.object,
  animation: PropTypes.object,
  platforms: PropTypes.array,
  iconSize: PropTypes.number
};

/* eslint-disable react/prop-types */
/* eslint-disable react/display-name  */
function renderMessengerPlatforms({
  name,
  properties_fields
}) {
  function renderIconPlatform(name) {
    const iconPlateform = _.find(LOGO_PLATFORMS, ['name', name]);
    return iconPlateform.icon;
  }
  switch (name) {
    case 'Phonecall':
      {
        return ({
          key,
          borderStyle,
          size
        }) => <a key={key} href={`tel:${properties_fields?.number}`}>
					<MessengerPlateformButton $size={size} $svgSize={getSvgSize(size)} style={borderStyle}>
						{renderIconPlatform(name)}
					</MessengerPlateformButton>
				</a>;
      }
    case 'SMS':
      {
        return ({
          key,
          borderStyle,
          size
        }) => <a key={key} href={`sms:${properties_fields?.number}?&body=${properties_fields?.message || ''}`}>
					<MessengerPlateformButton $size={size} $svgSize={getSvgSize(size)} style={borderStyle}>
						{renderIconPlatform(name)}
					</MessengerPlateformButton>
				</a>;
      }
    case 'Mail':
      {
        return ({
          key,
          borderStyle,
          size
        }) => <a key={key} href={`mailto:${properties_fields?.mail}`}>
					<MessengerPlateformButton $size={size} $svgSize={getSvgSize(size)} style={borderStyle}>
						{renderIconPlatform(name)}
					</MessengerPlateformButton>
				</a>;
      }
    case 'Skype':
      {
        return ({
          key,
          borderStyle,
          size
        }) => <a key={key} href={`skype:${properties_fields?.user_id}?call`}>
					<MessengerPlateformButton $size={size} $svgSize={getSvgSize(size)} style={borderStyle}>
						{renderIconPlatform(name)}
					</MessengerPlateformButton>
				</a>;
      }
    case 'Whatsapp':
      {
        return ({
          key,
          borderStyle,
          size
        }) => <MessengerPlateformButton key={key} $size={size} $svgSize={getSvgSize(size)} style={borderStyle}>
					<WhatsappButton number={properties_fields?.number || ''} message={properties_fields?.message || ''}>
						{renderIconPlatform(name)}
					</WhatsappButton>
				</MessengerPlateformButton>;
      }
    case 'Telegram':
      {
        return ({
          key,
          borderStyle,
          size
        }) => <MessengerPlateformButton key={key} $size={size} $svgSize={getSvgSize(size)} style={borderStyle} onClick={() => window.open(`https://t.me/${properties_fields?.user_id.replace('@', '')}`, '_blank')}>
					{renderIconPlatform(name)}
				</MessengerPlateformButton>;
      }
    case 'Messenger':
      {
        return ({
          key,
          borderStyle,
          size
        }) => <MessengerPlateformButton key={key} $size={size} $svgSize={getSvgSize(size)} style={borderStyle} onClick={() => window.open(generateProtocol(properties_fields?.link), '_blank')}>
					{renderIconPlatform(name)}
				</MessengerPlateformButton>;
      }
    default:
      break;
  }
}
function getSvgSize(size) {
  return size - 20;
}
function extractBlockStyle(visualProperties) {
  const borderStyle = _.omit(visualProperties, ['height', 'width', 'animationFillMode']);
  const blockStyle = _.omit(visualProperties, ['borderStyle', 'borderWidth', 'borderRadius', 'borderColor', 'backgroundColor']);
  return {
    borderStyle,
    blockStyle
  };
}
function generateProtocol(url) {
  return url.includes('https://') || url.includes('http://') ? url : `https://${url}`;
}
const MessengerPlateformButton = styled.div`
	width: ${({
  $size
}) => $size}px;
	height: ${({
  $size
}) => $size}px;
	background-color: ${({
  bgColor
}) => bgColor};
	border-radius: ${({
  borderRadius
}) => borderRadius};
	margin: ${({
  theme
}) => theme.spacing(0.5, 1)};
	display: inline-flex;
	vertical-align: middle;
	box-shadow: ${({
  theme
}) => theme.shadows[2]};
	cursor: pointer;
	transition: all ease-out 0.3s;

	svg {
		width: ${({
  $svgSize
}) => $svgSize}px;
		height: ${({
  $svgSize
}) => $svgSize}px;
		min-height: 15px;
		min-width: 15px;
		color: white;
		margin: auto;
	}

	&:hover {
		filter: brightness(0.9);
	}
`;
const WhatsappButton = styled(ReactWhatsapp)`
	background: none;
	width: 100%;
	border: none;
	cursor: pointer;
`;