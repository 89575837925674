import { Publish } from '@mui/icons-material';
import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { BetaChip } from 'components/atoms/Chips';
import InstagramAssets from 'components/InstagramAssets';
import { MediaCategory, MediaSelectorSideBar } from 'components/styled/Modals';
import { GifGallery, IllustrationsGallery, ImageGallery, PexelsGallery, RecentImagesGallery } from 'containers/Editor/StoryEditor/MediaAssets/Galleries';
import { IMAGES_SELECTOR_LIST } from 'lib/constants';
import useUpload from 'lib/hooks/useUpload';
import { UrlAssetIdentifier } from 'lib/models';
import { MIME_TYPES } from 'lib/upload';
import { isUrl } from 'lib/utils';
import _ from 'lodash';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getProjectRecentlyUsed } from 'redux/project/selectors';
import { ModalWrapper } from '.';
import { HighlightedDropzone } from './SelectorModal.style';
const fileImporterStyle = {
  display: 'none'
};
const previewImageStyle = {
  width: '100%',
  objectFit: 'contain'
};
const giphyRegex = /giphy/i;
const pexelsRegex = /pexels/i;
const snackeetStorage = /storage.googleapis.com\/snackeet/i;
const isGif = /ct=g/i;
const ImagesSelectorModal = forwardRef(function ImagesSelectorModal({
  trigger,
  inputValue,
  onUploadSuccess,
  onSelect,
  isBackground,
  categoryList
}, ref) {
  const imagesModalRef = useRef();
  const fileImporterRef = useRef();
  const queryClient = useQueryClient();
  const [highlight, setHighlight] = useState(false);
  const [tab, setTab] = useState('import');
  const currentRecentlyUsed = useSelector(getProjectRecentlyUsed);
  useImperativeHandle(ref, () => ({
    open: () => imagesModalRef.current?.open(),
    close: () => imagesModalRef.current?.close()
  }));
  const {
    isUploading,
    handleUpload,
    ImportingModal,
    importingModalProps
  } = useUpload({
    acceptedMimeTypes: Object.values(MIME_TYPES.image),
    onUploadSuccess
  });

  // Preset category via current link
  useEffect(() => {
    if (inputValue) {
      if (!_.isEmpty(currentRecentlyUsed?.images)) {
        setTab('recently_used');
      } else if (snackeetStorage.test(inputValue)) {
        setTab('import');
      } else if (giphyRegex.test(inputValue)) {
        if (isGif.test(inputValue)) {
          setTab('gif');
        } else {
          setTab('sticker');
        }
      } else if (pexelsRegex.test(inputValue)) {
        setTab('pexels');
      }
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [inputValue]);
  const onFileSelected = useCallback(async files => {
    const uploadedFiles = await handleUpload(files);
    queryClient.invalidateQueries({
      queryKey: ['images']
    });

    // Close if only one file was uploaded because we automatically select it
    if (uploadedFiles?.length === 1) {
      imagesModalRef.current?.close();
    }
    // Clear the selected file and allows the file input to be re-opened for selection
    if (fileImporterRef.current) fileImporterRef.current.value = null;

    // Open import tab after upload
    if (tab === 'recently_used') {
      setTab('import');
    }
  }, [handleUpload, queryClient, tab]);
  const onDragOver = useCallback(evt => {
    evt.preventDefault();
    setHighlight(true);
  }, []);
  const onDragLeave = useCallback(() => {
    setHighlight(false);
  }, []);
  const onFileDrop = useCallback(evt => {
    evt.preventDefault();
    const files = Array.from(evt.dataTransfer.files);
    onFileSelected(files);
    setHighlight(false);
  }, [onFileSelected]);
  const onFileImporterChange = useCallback(evt => {
    const files = Array.from(evt.target.files);
    onFileSelected(files);
  }, [onFileSelected]);
  function onImageSelected({
    url,
    file_id,
    name
  }) {
    onSelect({
      url,
      file_id,
      name
    });
    imagesModalRef.current?.close();
  }
  async function onInstagramImageSelected(evtData) {
    const fileUrl = new UrlAssetIdentifier({
      url: evtData.media_url,
      instagramId: evtData.id
    });
    await handleUpload(fileUrl);
    imagesModalRef.current?.close();
  }
  function isIncludedInCategories(category) {
    return categoryList ? _.includes(categoryList, category.value) : true;
  }
  function renderMediaSelector(category) {
    switch (category) {
      case 'import':
      case 'recently_used':
        return <Box sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          overflow: 'auto'
        }} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onFileDrop}>
						<Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2
          }}>
							<Button size='large' variant='contained' color='secondary' disabled={isUploading} onClick={() => fileImporterRef.current?.click()} startIcon={!isUploading ? <Publish /> : <CircularProgress color='primary' size={20} />}>
								<FormattedMessage id='button.import' />
							</Button>
							<Typography variant='h4'>
								<FormattedMessage id='modals.images.import.instruction' />
							</Typography>
						</Box>

						{category === 'import' ? <ImageGallery onSelect={onImageSelected} /> : <RecentImagesGallery onSelect={onImageSelected} />}

						<HighlightedDropzone $highlight={highlight}>
							<Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}>
								<Image src='/static/placeholders/upload_media.svg' alt='Upload media' height={300} width={300} />
								<Box sx={{
                mt: -4,
                fontSize: '1.5rem'
              }}>
									<FormattedMessage id='modals.images.import.dropzone' />
								</Box>
							</Box>
						</HighlightedDropzone>
					</Box>;
      case 'link':
        return <LinkTab inputValue={inputValue} onImageSelected={onImageSelected} />;
      case 'illustrations':
        return <IllustrationsGallery onSelect={onImageSelected} />;
      case 'pexels':
        return <PexelsGallery handleUrl={onImageSelected} is_background={isBackground} />;
      // case 'pixabay':
      // 	return <PixabayGallery handleUrl={onImageSelected} is_background={isBackground} />
      case 'gif':
        return <GifGallery key='gif' handleUrl={onImageSelected} />;
      case 'sticker':
        return <GifGallery key='sticker' assetType='sticker' handleUrl={onImageSelected} />;
      case 'instagram':
        return <InstagramAssets mediaTypes={['IMAGE']} assetsTypes={['posts', 'stories']} onAssetSelect={onInstagramImageSelected} />;
    }
  }
  return <>
			<ModalWrapper title={<FormattedMessage id='modals.images.header' />} trigger={trigger} ref={imagesModalRef} size='lg' sx={{
      '.MuiDialog-paper': {
        height: '100%'
      }
    }}>
				<Box sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'auto'
      }}>
					<MediaSelectorSideBar>
						{IMAGES_SELECTOR_LIST.filter(isIncludedInCategories).map((category, index) => <MediaCategory key={index} $isSelected={tab === category.value} onClick={() => setTab(category.value)}>
								{typeof category.logo === 'string' ? <img src={category.logo} /> : category.logo}
								<Box sx={{
              mx: 1.5
            }}>
									<FormattedMessage id={`modals.images.type_${category.value}`} />
								</Box>
								{category.isBeta && <BetaChip />}
							</MediaCategory>)}
					</MediaSelectorSideBar>

					<Box sx={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 0.5,
          flex: 1
        }}>
						{renderMediaSelector(tab)}
					</Box>
				</Box>

				<input ref={fileImporterRef} style={fileImporterStyle} type='file' multiple accept={Object.values(MIME_TYPES.image).join(',')} onChange={onFileImporterChange} />
			</ModalWrapper>

			<ImportingModal {...importingModalProps} />
		</>;
});
ImagesSelectorModal.propTypes = {
  trigger: PropTypes.object,
  inputValue: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
  isBackground: PropTypes.bool,
  categoryList: PropTypes.array
};
export default ImagesSelectorModal;
function LinkTab({
  inputValue,
  onImageSelected
}) {
  const intl = useIntl();
  const [currentInput, setCurrentInput] = useState(inputValue);
  const [hasError, setHasError] = useState(false);
  const inputRef = useHotkeys('enter, return', () => onImageSelected({
    url: currentInput
  }), {
    preventDefault: true,
    enableOnFormTags: true
  }, [onImageSelected, currentInput]);
  const handleInputChange = useCallback(({
    target: {
      value
    }
  }) => {
    setCurrentInput(value);
    setHasError(!isUrl(value).success);
  }, []);
  return <Box width='80%' display='flex' flexDirection='column' alignItems='center'>
			<Box display='flex' justifyContent='center' height={350} width='100%' mb={1}>
				<img src={currentInput || '/static/placeholders/media_link.svg'} style={previewImageStyle} />
			</Box>

			<Box fontSize={16}>
				<FormattedMessage id='editor.image_import.info' />
			</Box>

			<Box my={2} width='100%'>
				<TextField ref={inputRef} multiline minRows={3} size='small' fullWidth variant='outlined' placeholder={intl.formatMessage({
        id: 'placeholder.image_url'
      })} onChange={handleInputChange} value={currentInput} error={hasError} />
			</Box>

			{hasError && <Box color='error.main' fontSize={12} mb={2}>
					<FormattedMessage id='modals.images.link.invalid' />
				</Box>}

			<Button variant='contained' color='primary' size='large' disabled={hasError} onClick={() => onImageSelected({
      url: currentInput
    })}>
				<FormattedMessage id='button.confirm' />
			</Button>
		</Box>;
}