import { Box, Button } from '@mui/material'
import styled from '@styled'
import { snackeet_dark_grey } from 'styles/colors'

export const SnackeetCardArea = styled(Box)`
	position: relative;
	box-shadow: ${({ theme }) => theme.shadows[1]};
	transition: box-shadow 300ms ease-in;
	display: flex;

	&:hover {
		box-shadow: ${(props) => props.theme.shadows[4]};
	}
`
SnackeetCardArea.defaultProps = {
	minHeight: 140,
	borderRadius: 1,
	bgcolor: 'background.paper',
}

export const SnackeetCardLabel = styled.label`
	flex-shrink: 0;
	border-radius: 16px;
	padding: ${({ theme }) => theme.spacing(0.5, 1)};
	color: ${({ $color, theme }) => $color ?? theme.palette.text.primary};
	border: 1px solid ${({ $color }) => $color ?? snackeet_dark_grey};
	font-weight: bold;
	${({ $textTransform }) => ($textTransform ? `text-transform: ${$textTransform};` : '')}
	cursor: inherit;
`

export const SnackeetCardInfo = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: ${({ theme }) => theme.spacing(1)};
	padding-bottom: ${({ theme }) => theme.spacing(3.5)};
	row-gap: ${({ theme }) => theme.spacing(1)};
	height: 100%;
`

export const SnackeetCardDateSection = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	row-gap: ${({ theme }) => theme.spacing(1)};
	column-gap: ${({ theme }) => theme.spacing(2)};

	svg {
		height: 16px;
		width: 16px;
		color: ${snackeet_dark_grey};
	}

	& > div {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		column-gap: ${({ theme }) => theme.spacing(0.5)};
	}
`

export const SnackeetCardBody = styled(Button)`
	display: grid;
	grid-gap: ${({ theme }) => theme.spacing(1)};
	width: 100%;
	text-transform: unset;
	border-color: ${({ theme }) => theme.palette.divider};

	&:not(:last-child) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}

	&:not(:first-child) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
`
SnackeetCardBody.defaultProps = {
	variant: 'outlined',
}

export const SnackeetOrganizationEditButton = styled(Button)`
	display: grid;
	place-items: center;
	color: inherit;
	border-color: ${({ theme }) => theme.palette.divider};
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;

	:hover {
		background-color: #e6e6e6;
	}

	svg {
		height: 20px;
	}
`
SnackeetOrganizationEditButton.defaultProps = {
	variant: 'outlined',
}

export const SnackeetCardLogo = styled(Box)`
	display: grid;
	place-items: center;

	img {
		height: 100%;
		width: 100%;
		object-fit: contain;
	}
`
