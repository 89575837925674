import { Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from '@styled';
import { DEFAULT_COLORS, TRANSPARENT_IMG } from 'lib/constants';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { FormattedMessage } from 'react-intl';
const BootstrapTooltip = styled(({
  className,
  ...props
}) => <Tooltip {...props} classes={{
  popper: className
}} />)(({
  theme
}) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'rgba(255, 255, 255, 0.9)',
    padding: '3px 8px',
    fontSize: '9px',
    marginTop: '6px !important'
  }
}));
const styles = {
  picker: {
    width: '250px',
    padding: '0',
    boxSizing: 'initial',
    background: '#fff',
    borderRadius: '0px',
    boxShadow: 'none'
  },
  color: {
    width: '30px',
    height: '30px',
    position: 'relative',
    marginTop: '4px',
    marginLeft: '4px',
    borderRadius: '3px'
  },
  alpha: {
    position: 'relative',
    height: '16px',
    marginTop: '4px',
    overflow: 'hidden'
  },
  Alpha: {
    slider: {
      height: '14px',
      width: '6px'
    }
  }
};
const CustomColorPicker = forwardRef(({
  value,
  onUpdate,
  recentColors = [],
  brandColors = []
}, ref) => {
  const [color, setColor] = useState(value);

  // Handle Undo/Redo
  useEffect(() => {
    if (value !== color) {
      setColor(value);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [value]);
  function onChangeComplete(color) {
    const {
      r,
      g,
      b,
      a
    } = color.rgb;
    const rgbaString = `rgba(${r}, ${g}, ${b}, ${a})`;
    return onUpdate(rgbaString);
  }
  function handleClickOptionalColor(value) {
    return onUpdate(value);
  }
  return <ColorPickerWrapper>
			<SketchPicker ref={ref} styles={styles} color={color} onChange={color => setColor(color.rgb)} onChangeComplete={onChangeComplete} presetColors={DEFAULT_COLORS} />
			{!_.isEmpty(recentColors) && <div>
					<Typography variant='body1' style={{
        fontWeight: 'bold'
      }}>
						<FormattedMessage id='common.recently_used' />
					</Typography>
					<RecentColorsWrapper>
						{_.map(recentColors, recentColor => <BootstrapTooltip key={recentColor._id} title={recentColor.color}>
								<ColorBlock key={recentColor._id} $recentColor={recentColor.color} onClick={() => handleClickOptionalColor(recentColor.color)} />
							</BootstrapTooltip>)}
					</RecentColorsWrapper>
				</div>}
			{!_.isEmpty(brandColors) && <div>
					<Typography variant='body1' style={{
        fontWeight: 'bold'
      }}>
						<FormattedMessage id='common.brand_colors' />
					</Typography>
					<RecentColorsWrapper>
						{_.map(brandColors, (brandColor, index) => <BootstrapTooltip key={index} title={brandColor}>
								<ColorBlock key={index} $recentColor={brandColor} onClick={() => handleClickOptionalColor(brandColor)} />
							</BootstrapTooltip>)}
					</RecentColorsWrapper>
				</div>}
		</ColorPickerWrapper>;
});
CustomColorPicker.propTypes = {
  value: PropTypes.string,
  onUpdate: PropTypes.func,
  recentColors: PropTypes.array,
  brandColors: PropTypes.array
};
CustomColorPicker.displayName = 'CustomColorPicker';
export default CustomColorPicker;

// Styled Components
const ColorPickerWrapper = styled.div`
	padding: ${({
  theme
}) => theme.spacing(1)};
`;
const RecentColorsWrapper = styled.div`
	width: 270px;
	margin: 0 -10px;
	padding: 4px 0 0 10px;
	display: flex;
	flex-wrap: wrap;
	position: relative;
`;
const ColorBlock = styled.div`
	width: 16px;
	height: 16px;
	background-image: url(${TRANSPARENT_IMG});
	background-size: 350px 350px;
	margin: 0 10px 10px 0;
	border-radius: 3px;
	cursor: pointer;
	box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px inset;
	position: relative;

	&::after {
		position: absolute;
		content: ' ';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 3px;
		box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px inset;
		z-index: 0;
		background-color: ${({
  $recentColor
}) => $recentColor || '#fff'};
	}
`;