import Animate from 'components/Animate';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ButtonListContainer, ButtonsWrapper, CTAButton, ImageCTAButton, MaskImage } from './styled';
export default function ButtonListBase({
  buttonList
}) {
  const {
    _id,
    buttons,
    outer_style,
    inner_style,
    animation
  } = buttonList;
  const refactoredAnimation = {
    name: animation?.name || '',
    duration: animation?.duration || 1000,
    delay: animation?.delay || 0,
    forever: false,
    displayDelay: animation?.displayDelay || 0
  };
  const btnColor = _.get(inner_style, 'color', 'black');
  function renderButton(btn) {
    if (btn?.hasIcon && btn?.icon) {
      return <ImageCTAButton key={btn._id} style={{
        ...inner_style
      }}>
					<MaskImage $color={btnColor} $maskImageUrl={btn.icon} />
					{btn.title}
				</ImageCTAButton>;
    }
    if (!btn?.hasIcon && btn?.image) {
      return <ImageCTAButton key={btn._id} style={{
        ...inner_style
      }}>
					<img src={btn.image} width='28px' height='28px' style={{
          borderRadius: inner_style?.borderRadius
        }} />
					{btn.title}
				</ImageCTAButton>;
    }
    return <CTAButton key={btn._id} style={{
      ...inner_style
    }}>
				{btn.title}
			</CTAButton>;
  }
  return <ButtonListContainer style={outer_style}>
			<Animate animation={refactoredAnimation} sx={{
      height: '100%',
      width: '100%'
    }}>
				<ButtonsWrapper>{buttons.map((btn, index) => renderButton(btn))}</ButtonsWrapper>
			</Animate>
		</ButtonListContainer>;
}
ButtonListBase.propTypes = {
  buttonList: PropTypes.object
};