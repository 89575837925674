import { CircularProgress, Pagination } from '@mui/material';
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { ConfirmationModal } from 'components/Modals';
import useProjectRecentlyUsed from 'lib/hooks/useProjectRecentlyUsed';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentProjectId } from 'redux/project/selectors';
import FilesSvc from 'services/FilesSvc';
import { LoaderContainer, PaginationContainer } from './Galleries.style';
import ImageList from './MediaComponent/ImageList';
const PER_PAGE = 20;
async function fetchImages({
  queryKey
}) {
  const {
    project,
    per_page,
    page
  } = queryKey[1];
  const {
    success,
    files = [],
    count
  } = await FilesSvc.searchByQuery({
    project,
    query: {
      type: 'image',
      per_page,
      page
    }
  });
  if (success) {
    return {
      list: files,
      pageCount: Math.ceil(count / per_page)
    };
  }
}
export default function ImageGallery({
  onSelect
}) {
  const project = useSelector(getCurrentProjectId);
  const {
    removeRecentlyUsed
  } = useProjectRecentlyUsed();
  const [page, setPage] = useState(1);
  const [fileIdToDelete, setFileIdToDelete] = useState('');
  const deleteModalRef = useRef();
  const queryClient = useQueryClient();
  const {
    data: {
      list = [],
      pageCount = 0
    } = {},
    isLoading,
    isFetching,
    isPlaceholderData
  } = useQuery({
    queryKey: ['images', {
      project,
      page,
      per_page: PER_PAGE
    }],
    queryFn: fetchImages,
    placeholderData: keepPreviousData
  });
  async function onDelete() {
    await Promise.allSettled([FilesSvc.del({
      file_id: fileIdToDelete,
      project
    }), removeRecentlyUsed('images', fileIdToDelete)]);
    queryClient.invalidateQueries({
      queryKey: ['images']
    });
    deleteModalRef.current?.close();
  }
  function selectFileToDelete(image_id) {
    setFileIdToDelete(image_id);
    deleteModalRef.current?.open();
  }

  // Prefetch the next page
  useEffect(() => {
    if (!isPlaceholderData && page < pageCount) {
      queryClient.prefetchQuery({
        queryKey: ['images', {
          project,
          page: page + 1,
          per_page: PER_PAGE
        }],
        queryFn: fetchImages
      });
    }
  }, [isPlaceholderData, page, pageCount, project, queryClient]);
  return <>
			{isLoading || isFetching ? <LoaderContainer>
					<CircularProgress disableShrink />
				</LoaderContainer> : <ImageList listType='gallery' images={list} onSelect={onSelect} onDelete={selectFileToDelete} />}
			{pageCount > 0 && <PaginationContainer>
					<Pagination size='medium' count={pageCount} variant='outlined' color='primary' onChange={(_evt, pageIndex) => {
        setPage(pageIndex);
      }} shape='rounded' page={page} />
				</PaginationContainer>}

			<ConfirmationModal size='sm' confirmationModalRef={deleteModalRef} title={<FormattedMessage id='modal.file_deletion.confirmation.header.image' />} onConfirm={onDelete} firstLine={<FormattedMessage id='modal.file_deletion.confirmation.sub_header' />} secondLine={<FormattedMessage id='modal.file_deletion.confirmation.instructions' />} confirmText={<FormattedMessage id='common.yes' />} cancelText={<FormattedMessage id='common.no' />} />
		</>;
}
ImageGallery.propTypes = {
  onSelect: PropTypes.func
};