import styled from '@styled';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ButtonListBase from './ButtonListBase';
export default function ButtonLists({
  page
}) {
  return page?.buttonLists && !_.isEmpty(page.buttonLists) && <ButtonListsContainer>
				{page?.buttonLists.map(buttonList => <ButtonListBase key={buttonList?._id} buttonList={buttonList} />)}
			</ButtonListsContainer>;
}
ButtonLists.propTypes = {
  page: PropTypes.object
};
const ButtonListsContainer = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	box-sizing: border-box;
	pointer-events: none;
	z-index: 3;
`;