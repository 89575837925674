import { TrashIcon } from '@heroicons/react/24/outline';
import { CreateNewFolder, Edit, Folder, FolderOutlined } from '@mui/icons-material';
import { Box, Button, DialogActions, DialogContent, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon, TextField, Tooltip, Typography } from '@mui/material';
import styled from '@styled';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ConfirmationModal, ModalWrapper } from 'components/Modals';
import { Figure } from 'components/styled';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import SubmitButton from 'organisms/buttons/SubmitButton';
import PropTypes from 'prop-types';
import { forwardRef, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentProjectId } from 'redux/project/selectors';
import StoryFolderSvc from 'services/StoryFolderSvc';
const FolderSelectionModal = forwardRef(function FolderSelectionModal({
  project,
  draftStory,
  publishedStory,
  onFolderSelect,
  folders,
  onClose
}, folderSelectionModalRef) {
  const newFolderModalRef = useRef();
  const deleteFolderModalRef = useRef();
  const editFolderModalRef = useRef();
  const [selectedFolderId, setSelectedFolderId] = useState();
  const [folderIdToEdit, setFolderIdToEdit] = useState();
  const folderToEdit = _.find(folders, {
    _id: folderIdToEdit
  });
  const queryClient = useQueryClient();
  const deleteStoryFolderMutation = useMutation({
    mutationFn: StoryFolderSvc.del,
    onError: error => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['storyFolders', {
          project
        }]
      });
      deleteFolderModalRef.current?.close();
    }
  });
  function handleOpen() {
    const currentFolder = _.find(folders, {
      _id: publishedStory?.folderId
    });
    setSelectedFolderId(currentFolder?._id);
  }
  function handleClose() {
    setSelectedFolderId();
    setFolderIdToEdit();
    onClose();
  }
  function handleSelectFolder(folderId) {
    setSelectedFolderId(prevFolderId => {
      // Unselect the folder if it's already selected
      if (prevFolderId === folderId) {
        return undefined;
      } else {
        return folderId;
      }
    });
  }
  async function onConfirm() {
    // Only update if the selected folder is different from the current one
    if (selectedFolderId !== publishedStory?.folderId) {
      onFolderSelect({
        selectedFolderId,
        storyId: publishedStory?.story_id
      });
    }
    folderSelectionModalRef.current?.close();
  }
  async function onConfirmDelete() {
    await deleteStoryFolderMutation.mutate({
      project,
      folderId: folderIdToEdit
    });
    deleteFolderModalRef.current?.close();
  }
  return <>
			<ModalWrapper ref={folderSelectionModalRef} size='xs' onClose={handleClose} onOpen={handleOpen}>
				<DialogContent dividers>
					{_.isEmpty(folders) ? <Figure height={150}>
							<img src='/static/placeholders/empty_folder.svg' />
							<figcaption>
								<FormattedMessage id='modal.story_folder_selection.empty.title' />
							</figcaption>
							<Box fontSize={16} mb={1} textAlign='center'>
								<FormattedMessage id='modal.story_folder_selection.empty.instruction' />
							</Box>
							<Button variant='outlined' startIcon={<Folder />} onClick={() => newFolderModalRef.current?.open()}>
								<FormattedMessage id='modal.story_folder_selection.button.create' />
							</Button>
						</Figure> : <>
							<Typography variant='body1' sx={{
            mb: 2,
            fontSize: 16
          }}>
								<FormattedMessage id='modal.story_folder_selection.description' values={{
              storyName: draftStory?.name
            }} />
							</Typography>
							<List disablePadding>
								{_.map(folders, folder => <ListItem key={folder._id} disablePadding secondaryAction={<Box sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}>
												<Tooltip title={<FormattedMessage id='modal.story_folder_edition.title' />} placement='right' disableInteractive>
													<IconButton edge='end' aria-label='edit' size='small' onClick={() => {
                  setFolderIdToEdit(folder._id);
                  setTimeout(() => editFolderModalRef.current?.open(), 0);
                }}>
														<Edit fontSize='small' />
													</IconButton>
												</Tooltip>

												<Tooltip title={<FormattedMessage id='modal.story_folder_deletion.title' />} placement='right' disableInteractive>
													<IconButton edge='end' aria-label='delete' size='small' onClick={() => {
                  setFolderIdToEdit(folder._id);
                  setTimeout(() => deleteFolderModalRef.current?.open(), 0);
                }}>
														<SvgIcon fontSize='inherit'>
															<TrashIcon />
														</SvgIcon>
													</IconButton>
												</Tooltip>
											</Box>}>
										<FolderItem onClick={() => handleSelectFolder(folder._id)} selected={selectedFolderId === folder._id}>
											<ListItemIcon sx={{
                  minWidth: 40
                }} size='small'>
												{selectedFolderId === folder._id ? <Folder fontSize='small' /> : <FolderOutlined fontSize='small' />}
											</ListItemIcon>
											<ListItemText disableTypography primary={<Typography variant='body1' component='span' sx={{
                  width: 'fit-content',
                  fontWeight: 500,
                  mr: 2
                }}>
														{folder.name}
													</Typography>} secondary={<Typography variant='body2' component='span' sx={{
                  width: 'fit-content'
                }}>
														<FormattedMessage values={{
                    count: folder.storyCount
                  }} id='common.stories_count' />
													</Typography>} />
										</FolderItem>
									</ListItem>)}
							</List>
						</>}
				</DialogContent>

				<DialogActions sx={{
        justifyContent: 'space-between'
      }}>
					<Button onClick={() => folderSelectionModalRef.current?.close()}>
						<FormattedMessage id='button.cancel' />
					</Button>
					{!_.isEmpty(folders) && <Button variant='outlined' startIcon={<CreateNewFolder />} onClick={() => newFolderModalRef.current?.open()}>
							<FormattedMessage id='modal.story_folder_selection.button.create' />
						</Button>}
					<SubmitButton onClick={onConfirm} disabled={selectedFolderId === publishedStory?.folderId}>
						<FormattedMessage id='button.confirm' />
					</SubmitButton>
				</DialogActions>
			</ModalWrapper>

			<NewFolderModal ref={newFolderModalRef} />

			<EditFolderModal ref={editFolderModalRef} folderToEdit={folderToEdit} />

			<ConfirmationModal size='xs' confirmationModalRef={deleteFolderModalRef} title={<FormattedMessage id='modal.story_folder_deletion.title' />} onClose={() => setFolderIdToEdit()} onConfirm={onConfirmDelete} firstLine={<>
						<FormattedMessage id='modal.story_folder_deletion.question' values={{
        folderName: folderToEdit?.name
      }} />
						<br />
						<br />
						<FormattedMessage id='modal.story_folder_deletion.warning' />
					</>} confirmText={<FormattedMessage id='common.yes' />} cancelText={<FormattedMessage id='common.no' />} />
		</>;
});
FolderSelectionModal.propTypes = {
  project: PropTypes.string,
  draftStory: PropTypes.object,
  publishedStory: PropTypes.object,
  folders: PropTypes.array,
  onFolderSelect: PropTypes.func,
  onClose: PropTypes.func
};
export default FolderSelectionModal;
const MAX_FOLDER_NAME_LENGTH = 25;
const NewFolderModal = forwardRef(function NewFolderModal(_props, newFolderModalRef) {
  const intl = useIntl();
  const project = useSelector(getCurrentProjectId);
  const [name, setName] = useState('');
  const {
    enqueueSnackbar
  } = useSnackbar();
  const queryClient = useQueryClient();
  const addStoryFolderMutation = useMutation({
    mutationFn: StoryFolderSvc.create,
    onError: error => {
      console.error(error);
      enqueueSnackbar(intl.messages['toast.error.creation.storyFolder'], {
        variant: 'error'
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['storyFolders', {
          project
        }]
      });
      newFolderModalRef.current?.close();
      setName('');
      enqueueSnackbar(intl.messages['toast.success.creation.storyFolder'], {
        variant: 'success'
      });
    }
  });
  async function onConfirm() {
    addStoryFolderMutation.mutate({
      project,
      name
    });
  }
  function handleChange({
    target: {
      value
    }
  }) {
    if (value.length <= MAX_FOLDER_NAME_LENGTH) {
      setName(value);
    }
  }
  function handleEnterKey(evt) {
    if ((evt.key === 'Enter' || evt.keyCode === 13) && name !== '') {
      onConfirm(evt);
    }
  }
  return <ModalWrapper ref={newFolderModalRef} size='xs' title={<FormattedMessage id='modal.story_folder_creation.header' />}>
			<DialogContent dividers sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2
    }}>
				<TextField size='small' variant='outlined' fullWidth={true} label={intl.formatMessage({
        id: 'modal.story_folder_creation.input'
      })} value={name} onChange={handleChange} error={name === ''} onKeyUp={handleEnterKey} InputProps={{
        endAdornment: <FolderNameLimitIndicator $length={name.length}>
								{name.length} / {MAX_FOLDER_NAME_LENGTH}
							</FolderNameLimitIndicator>
      }} />
			</DialogContent>

			<DialogActions>
				<SubmitButton variant='contained' color='primary' size='small' sx={{
        flexShrink: 0
      }} loading={addStoryFolderMutation.isPending} disabled={name === ''} onClick={onConfirm}>
					<FormattedMessage id='modal.story_folder_selection.button.create' />
				</SubmitButton>
			</DialogActions>
		</ModalWrapper>;
});
const EditFolderModal = forwardRef(function EditFolderModal({
  folderToEdit
}, editFolderModalRef) {
  const intl = useIntl();
  const project = useSelector(getCurrentProjectId);
  const [name, setName] = useState(folderToEdit?.name ?? '');
  const queryClient = useQueryClient();
  const editStoryFolderMutation = useMutation({
    mutationFn: StoryFolderSvc.update,
    onError: error => {
      console.error(error);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['storyFolders', {
          project
        }]
      });
      editFolderModalRef.current?.close();
      setName('');
    }
  });
  function onConfirm() {
    editStoryFolderMutation.mutate({
      folderId: folderToEdit?._id,
      name,
      project
    });
  }
  function handleChange({
    target: {
      value
    }
  }) {
    if (name.length <= MAX_FOLDER_NAME_LENGTH) {
      setName(value);
    }
  }
  function handleEnterKey(evt) {
    if ((evt.key === 'Enter' || evt.keyCode === 13) && name !== '') {
      onConfirm();
    }
  }
  return <ModalWrapper ref={editFolderModalRef} size='xs' title={<FormattedMessage id='modal.story_folder_edition.title' />} onOpen={() => setName(folderToEdit?.name)}>
			<DialogContent dividers sx={{
      display: 'flex',
      alignItems: 'center',
      gap: 2
    }}>
				<TextField size='small' variant='outlined' fullWidth={true} label={intl.formatMessage({
        id: 'modal.story_folder_creation.input'
      })} value={name} onChange={handleChange} error={name === ''} onKeyUp={handleEnterKey} InputProps={{
        endAdornment: <FolderNameLimitIndicator $length={name.length}>
								{name.length} / {MAX_FOLDER_NAME_LENGTH}
							</FolderNameLimitIndicator>
      }} />
			</DialogContent>

			<DialogActions>
				<SubmitButton variant='contained' color='primary' size='small' sx={{
        flexShrink: 0
      }} loading={editStoryFolderMutation.isPending} disabled={name === ''} onClick={onConfirm}>
					<FormattedMessage id='modal.story_folder_edition.button.edit' />
				</SubmitButton>
			</DialogActions>
		</ModalWrapper>;
});
const FolderItem = styled(ListItemButton)`
	&.Mui-selected {
		outline: 1px solid ${({
  theme
}) => theme.palette.text.secondary};
	}
`;
const FolderNameLimitIndicator = styled.span`
	flex-shrink: 0;
	font-weight: 500;

	color: ${({
  $length,
  theme
}) => $length < MAX_FOLDER_NAME_LENGTH ? theme.palette.success.main : theme.palette.error.main};
`;