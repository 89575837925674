import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Instance from './Instance';
import PulseButton from './PulseButton';
export default function TagsBase({
  inner_style,
  outer_style,
  tag_style,
  price_style,
  placement,
  description
}) {
  const [display, setDisplay] = useState(true);
  return <Box display='absolute' style={outer_style}>
			<PulseButton {...{
      inner_style,
      placement,
      display,
      setDisplay
    }} />
			<Instance {...{
      description,
      placement,
      display,
      setDisplay,
      tag_style,
      price_style
    }} />
		</Box>;
}
TagsBase.propTypes = {
  _id: PropTypes.string,
  inner_style: PropTypes.object,
  outer_style: PropTypes.object,
  placement: PropTypes.string,
  description: PropTypes.object,
  tag_style: PropTypes.object,
  price_style: PropTypes.object
};