import AmpAttachmentSettings from 'components/AmpAttachmentSettings';
import { ModalWrapper } from 'components/Modals';
import { useStoryDispatch, useStoryState } from 'lib/editorStore';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
export default function AmpAttachmentSettingsModal({
  trigger
}) {
  const ref = useRef();
  const intl = useIntl();
  const draftStory = useStoryState(s => s.draftStory);
  const recently_used = useStoryState(s => s.draftStory?.recently_used);
  const brand_colors = useStoryState(s => s.draftStory?.brand_colors);
  const brand_fontFamily = useStoryState(s => s.draftStory?.brand_fontFamily);
  const page = useStoryState(s => s.page);
  const currentAttachment = _.find(page?.amp_attachments, ['type', 'amp_attachment']);
  const dispatch = useStoryDispatch();
  function onCancel() {
    ref.current.close();
  }
  function onSubmitConfig(newInteraction) {
    dispatch({
      type: 'UPDATE_ATTACHMENT',
      data: {
        newInteraction
      }
    });
    ref.current.close();
  }
  function onRecentColorsUpdate(color) {
    dispatch({
      type: 'SET_RECENTLY_USED',
      data: {
        path: 'colors.block',
        value: color
      }
    }, false);
  }
  return <ModalWrapper title={intl.messages['modals.amp_attachment.modal.settings']} trigger={trigger} disableBackdropClick hasCloseButton={false} size='lg' ref={ref}>
			<AmpAttachmentSettings onCancel={onCancel} onSubmitConfig={onSubmitConfig} currentPage={page} draftStory={draftStory} currentAttachment={currentAttachment} onRecentColorsUpdate={onRecentColorsUpdate} recentColors={recently_used?.colors?.block} brandColors={brand_colors} recentFonts={recently_used?.font_families} brandFontFamily={brand_fontFamily} />
		</ModalWrapper>;
}
AmpAttachmentSettingsModal.propTypes = {
  trigger: PropTypes.object
};