import { Box } from '@mui/material';
import styled from '@styled';
import PropTypes from 'prop-types';

// This components helps displaying scaled down content
// Based on: https://stackoverflow.com/questions/37141902/transform-scale-keeps-the-original-space-around-the-scaled-element

export default function ScaleBox({
  scale = 1,
  style,
  fullWidth,
  fullHeight,
  children,
  ...rest
}) {
  return <ScaleBoxContainer style={style} {...rest}>
			<Box width={fullWidth * scale} height={fullHeight * scale} />
			<ScaleBoxContent $scale={scale}>{children}</ScaleBoxContent>
		</ScaleBoxContainer>;
}
ScaleBox.propTypes = {
  scale: PropTypes.number,
  style: PropTypes.object,
  fullWidth: PropTypes.number,
  fullHeight: PropTypes.number,
  children: PropTypes.node
};
const ScaleBoxContainer = styled.div`
	position: relative;
	display: inline-block;
`;
const ScaleBoxContent = styled.div`
	transform: scale(${({
  $scale
}) => $scale});
	transform-origin: left top;
	position: absolute;
	top: 0;
	left: 0;
`;