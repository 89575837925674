import _ from 'lodash';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { InterfaceContainer } from '../styled';
import RatingInteraction from './RatingInteraction';
function RatingPageInterface({
  page
}) {
  const rating = _.head(page.interactions);
  return <InterfaceContainer style={page.interface_style}>
			<div className='interactions'>
				<RatingInteraction rating={rating} />
			</div>
		</InterfaceContainer>;
}
RatingPageInterface.propTypes = {
  page: PropTypes.object
};
export default memo(RatingPageInterface);