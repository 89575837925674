import { CustomIcon } from 'components/icons';
import PropTypes from 'prop-types';
import { AttachmentButton, AttachmentContainer, AttachmentText } from './style';
export default function AmpAttachment({
  attachment
}) {
  const {
    theme,
    cta_text
  } = attachment;
  return <AttachmentContainer $theme={theme}>
			<AttachmentButton $theme={theme}>
				<CustomIcon name='AmpSwipeUp'
      // style={{ color: theme === 'dark' ? 'white' : 'black' }}
      color={theme === 'dark' ? 'white' : 'black'} />
			</AttachmentButton>

			<AttachmentText $theme={theme}>{cta_text}</AttachmentText>
		</AttachmentContainer>;
}
AmpAttachment.propTypes = {
  attachment: PropTypes.object
};