import { css } from '@emotion/react';
import { Card, TextField } from '@mui/material';
import styled from '@styled';
import { transparentize } from 'polished';
import { snackeet_dark_grey } from 'styles/colors';
export const CreateStoryDetails = styled.div`
	display: flex;
	flex-direction: column;
	padding: ${({
  theme
}) => theme.spacing(2)};
	font-size: 20px;

	.story-field {
		width: 100%;
		display: flex;
		align-items: center;
		padding: ${({
  theme
}) => theme.spacing(1, 0)};
		margin-bottom: ${({
  theme
}) => theme.spacing(2)};
	}

	label {
		width: 100px;
		margin-left: ${({
  theme
}) => theme.spacing(2)};
	}

	input {
		background: none;
	}
`;
export const CreateStoryContent = styled.div`
	background-color: rgb(245, 245, 245);
	overflow: auto;
	display: grid;
	place-items: center;
	box-shadow:
		inset 0px -4px 2px -4px ${snackeet_dark_grey},
		inset 0px 4px 2px -4px ${snackeet_dark_grey};

	/* Important */
	flex-basis: 550px;
	flex-grow: 1;
	flex-shrink: 1;
`;
export const CreateStoryModalFooterContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	align-items: center;
	width: 100%;
	padding: ${({
  theme
}) => theme.spacing(1, 1)};
	margin-top: 4px;

	.MuiChip-root {
		height: 22px;
	}
	.MuiChip-label {
		font-size: 10px;
	}

	@media (min-height: 580px) {
		padding: ${({
  theme
}) => theme.spacing(1, 2)};

		.MuiChip-root {
			height: 26px;
		}
		.MuiChip-label {
			font-size: 11px;
		}
	}

	@media (min-height: 730px) {
		padding: ${({
  theme
}) => theme.spacing(2, 4)};
		.MuiChip-root {
			height: 32px;
		}
		.MuiChip-label {
			font-size: 12px;
		}
	}
`;
export const CreateStoryInput = styled(TextField)`
	.MuiInputBase-root {
		width: 400px;
		height: 40px;
		padding: ${({
  theme
}) => theme.spacing(0, 1)};
		font-size: inherit;
	}
`;
CreateStoryInput.defaultProps = {
  variant: 'standard'
};
export const StoryCardsContainer = styled(CreateStoryContent)`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 8px;
	padding: ${({
  theme
}) => theme.spacing(1, 2)};
`;
export const StoryGroup = styled.button`
	display: grid;
	width: 90%;
	grid-template-rows: repeat(2, auto) 1fr;
	gap: ${({
  theme
}) => theme.spacing(1)};

	position: relative;
	padding: ${({
  theme
}) => theme.spacing(2, 1)};
	color: ${({
  theme
}) => theme.palette.text.primary};
	font-size: 0.875rem;
	line-height: 1.75;
	min-width: 64px;
	cursor: pointer;

	background-color: transparent;
	border: none;
	border-radius: ${({
  theme
}) => theme.shape.borderRadius}px;
	outline-width: 1px;
	outline-style: solid;
	outline-color: ${({
  theme
}) => theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'};

	&.selected {
		background-color: ${({
  $storyColor
}) => transparentize(0.9, $storyColor)};
		outline-color: ${({
  $storyColor
}) => $storyColor};
		outline-width: 4px;
	}

	&:hover {
		:not(.selected) {
			outline-width: 2px;
			outline-color: ${({
  theme
}) => theme.palette.text.primary};
		}
	}

	h1 {
		font-size: 22px;
		b {
			color: ${({
  $storyColor
}) => $storyColor};
		}
	}
	h2 {
		font-size: 12px;
		font-weight: 400;
	}

	.MuiChip-outlined {
		height: 22px;
	}
	.MuiChip-label {
		font-size: 9px;
	}

	@media (min-height: 580px) {
		gap: ${({
  theme
}) => theme.spacing(1)};
		padding: ${({
  theme
}) => theme.spacing(1)};
		h1 {
			font-size: 24px;
		}

		h2 {
			font-size: 12px;
		}

		.MuiChip-outlined {
			height: 32px;
		}
		.MuiChip-label {
			font-size: 11px;
		}
	}

	@media (min-height: 730px) {
		gap: ${({
  theme
}) => theme.spacing(1)};
		h1 {
			font-size: 30px;
		}

		h2 {
			font-size: 16px;

			b {
				font-size: 20px;
			}
		}

		.MuiChip-label {
			font-size: 14px;
		}
	}
`;
const isDisabledStyle = css`
	cursor: not-allowed;
	filter: grayscale(100%);
	&:hover {
		transform: none;
	}
	.MuiTouchRipple-child {
		background: none !important;
	}
`;
export const StoryCard = styled(Card)`
	place-self: center;
	margin: ${({
  theme
}) => theme.spacing(2)};
	overflow: initial;
	width: 100%;
	max-width: min(300px, 80%);
	display: flex;
	align-items: center;
	cursor: pointer;

	.MuiCardContent-root {
		align-self: stretch;
		flex: 2;
		justify-self: start;
		align-self: flex-start;
		padding: ${({
  theme
}) => theme.spacing(1, 2)};

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		text-align: start;
		font-size: 11px;
		font-family: ${({
  theme
}) => theme.typography.body1.fontFamily};
		font-weight: 400;

		ul {
			margin: 4px 0;
			width: 100%;
			padding-inline-start: 20px;
		}
	}

	.MuiCardMedia-root {
		flex: 1;
		max-width: 80px;
		max-height: 120px;
		position: relative;
		border-radius: 10px;
		transform: scale(1.1) translateX(8px);
		box-shadow: ${({
  theme
}) => theme.shadows[8]};
	}

	@media (min-height: 580px) {
		.MuiCardContent-root {
			font-size: 10px;
			padding: ${({
  theme
}) => theme.spacing(1)};
		}

		.MuiCardMedia-root {
			max-width: 100px;
			max-height: 200px;
		}
	}

	@media (min-height: 730px) {
		max-width: min(550px, 80%);

		.MuiCardContent-root {
			font-size: 12px;
		}

		.MuiCardMedia-root {
			max-width: 140px;
			max-height: 250px;
		}

		li {
			padding: 1px 0;
		}
	}

	@media (min-height: 900px) {
		.MuiCardMedia-root {
			max-width: 300px;
			max-height: 400px;
		}
	}

	${({
  $isDisabled
}) => $isDisabled && isDisabledStyle}
`;
StoryCard.defaultProps = {
  // raised: true,
};
export const BrandsContainer = styled.div`
	padding: ${props => props.theme.spacing(2)};
	width: 100%;
	overflow: auto;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	row-gap: ${({
  theme
}) => theme.spacing(2)};
`;
export const NoBrandPlaceholder = styled.div`
	width: 100%;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	background-color: white;
	box-shadow: ${props => props.theme.shadows[1]};
	border-radius: 8px;
	margin: ${({
  $marginBottom,
  theme
}) => $marginBottom && theme.spacing(0, 0, 3, 0)};

	&:hover {
		box-shadow: ${props => props.theme.shadows[4]};
	}
`;

// Templates Selection
export const TemplatesSelectionContainer = styled.div`
	height: 100%;
	width: 100%;
	display: grid;
	grid-template-columns: ${({
  $noNavBar
}) => $noNavBar ? 'auto 5fr' : 'minmax(200px, 1fr) 5fr'};
	gap: 24px;
	overflow: auto;

	nav {
		overflow: auto;
	}

	& > div {
		display: flex;
		flex-direction: column;
		overflow: auto;
	}
`;
export const TemplatesSection = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: auto;
	min-height: calc(100vh - 260px);

	& > div {
		display: flex;
		flex-direction: column;
	}
`;
export const TemplateSectionButtonsContainer = styled.div`
	padding: ${({
  theme
}) => theme.spacing(1, 3)};
	display: flex;
	flex-direction: row;
	column-gap: ${({
  theme
}) => theme.spacing(2)};
	align-items: center;
`;
export const TemplateSectionCategory = styled.div`
	display: flex;
	padding: ${({
  theme
}) => theme.spacing(2)};
	margin: ${({
  theme
}) => theme.spacing(1, 2)};
	font-size: 20px;
	font-weight: 500;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;