import { AlternateEmailOutlined, AssignmentIndOutlined, CheckBoxOutlined, ChevronLeft, DateRangeOutlined, HelpOutline, LanguageOutlined, ListOutlined, LooksOneOutlined, MessageOutlined, PersonOutline, PhoneOutlined, VerifiedUserOutlined } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, SvgIcon, Tooltip, Typography } from '@mui/material';
import { IOSSwitch } from 'atoms';
import { PremiumChip } from 'atoms/Chips';
import { FormErrorMessagesModal } from 'components/Modals';
import PagePreview from 'components/PagePreview/index';
// Styles
import { AddNewFieldContainer, FormSettingsWrapper } from 'components/styled/Modals';
import * as translation from 'lib/constants/InteractionTranslation';
import useGDPR from 'lib/hooks/useGDPR';
import useSubscriptionFeatures from 'lib/hooks/useSubscriptionFeatures';
import { cleanUpCurrentPageVariables, generateVariableName, reorder, sleep, unassignFieldVariable, updateVariableData } from 'lib/utils';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';
import { useImmer } from 'use-immer';

// Components
import FieldSettings from './FieldSettings';
import GdprSettings from './GdprSettings';
const SUB_TYPES_LIST = ['firstname', 'lastname', 'email', 'phone', 'text', 'number', 'date', 'select', 'website', 'checkbox'];
const ICONS_LIST = {
  firstname: <PersonOutline />,
  lastname: <PersonOutline />,
  email: <AlternateEmailOutlined />,
  phone: <PhoneOutlined />,
  text: <MessageOutlined />,
  number: <LooksOneOutlined />,
  date: <DateRangeOutlined />,
  select: <ListOutlined />,
  website: <LanguageOutlined />,
  checkbox: <CheckBoxOutlined />,
  username: <AssignmentIndOutlined />
};
export default function PageFormSettings({
  onPreviousStep,
  onCancel,
  onSubmitConfig,
  currentPage,
  draftStory
}) {
  const {
    featureSet: {
      recaptcha,
      disposable_email
    }
  } = useSubscriptionFeatures();
  const [states, setStates] = useImmer({
    currentPage,
    interaction: initAnswersStates(draftStory.story_settings.language, draftStory.variables, draftStory.messages, _.first(currentPage?.interactions))
  });
  const [currentVariables, setVariables] = useImmer(cleanUpCurrentPageVariables({
    currentPage,
    currentVariables: draftStory.variables
  }));
  const {
    gdprConsent,
    handleChangeGdprConsent
  } = useGDPR();
  const [verificationEmailDisable, setVerificationEmailDisable] = useState(true);
  const [uniqueEmailDisable, setUniqueEmailDisable] = useState(true);
  const [isAdding, setIsAdding] = useState(false);

  // Trigger when fields change to update current variables
  useEffect(() => {
    // Update current variables via fields
    // by adding new variables or update current variable trackingDatas
    const variables = _.cloneDeep(currentVariables);
    for (const field of states?.interaction?.fields) {
      if (field.variable) {
        const index = _.findIndex(variables, ['name', field.variable]);
        if (index > -1) {
          // Update trackingDatas
          setVariables(draft => {
            draft[index] = updateVariableData(variables[index], {
              page: {
                _id: states.currentPage._id,
                type: states.currentPage.type
              },
              field: field._id
            });
          });
        } else if (index === -1) {
          // Create new variable
          setVariables(draft => {
            draft.push({
              name: field.variable,
              type: getVariableType(field.type),
              default: false,
              isUsed: true,
              trackingDatas: [{
                page: {
                  _id: states.currentPage._id,
                  type: states.currentPage.type
                },
                field: field._id
              }]
            });
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states?.interaction?.fields]);

  // Check if unique email can be activate
  useEffect(() => {
    if (canApplyUniqueEmail(states.interaction?.fields)) {
      setUniqueEmailDisable(false);
    } else {
      // When disable unique email switcher if current unique_email & email_verification true, change to false
      if (states.interaction?.form_properties?.unique_email) {
        handleChangeFormProperties('unique_email', false);
      }
      setUniqueEmailDisable(true);
    }
    if (canApplyVerificationEmail(states.interaction?.fields)) {
      setVerificationEmailDisable(false);
    } else {
      // When disable unique email switcher if current unique_email & email_verification true, change to false
      if (states.interaction?.form_properties?.email_verification) {
        handleChangeFormProperties('email_verification', false);
      }
      if (states.interaction?.form_properties?.hasDisposableValidator) {
        handleChangeFormProperties('hasDisposableValidator', false);
      }
      setVerificationEmailDisable(true);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [states.interaction]);

  // Check if can undisable verification_email
  function canApplyVerificationEmail(fields) {
    return _.some(fields, {
      type: 'email'
    });
  }

  // Check if can undisable unique_email
  function canApplyUniqueEmail(fields) {
    return _.some(fields, {
      type: 'email',
      variable: 'email'
    });
  }
  async function handleAddField(sub_type) {
    if (isAdding) return;
    setIsAdding(true);
    const newField = initField(draftStory.story_settings.language, sub_type, currentVariables);
    setStates(draft => {
      draft.interaction.fields.push(newField);
    });
    await sleep(300);
    setIsAdding(false);
  }

  // When remove Field, need to clean up variable
  function handleRemoveField(_id) {
    const index = _.findIndex(states.interaction.fields, field => field._id === _id);
    if (index > -1) {
      // Clean up variables
      const idx = currentVariables.findIndex(variable => variable.name === states.interaction.fields[index]?.variable);
      if (idx >= 0) {
        setVariables(draft => {
          draft[idx] = unassignFieldVariable(currentVariables[idx], _id);
        });
      }
      setStates(draft => {
        draft.interaction.fields.splice(index, 1);
      });
    }
  }
  function handleChangeFormProperties(path, value) {
    setStates(draft => {
      draft.interaction.form_properties[path] = value;
    });
  }
  function handleChangeFieldProperty(_id, path, value) {
    const idx = _.findIndex(states.interaction.fields, field => field._id === _id);
    setStates(draft => {
      draft.interaction.fields[idx][path] = value;
    });
  }
  function handleChangeFieldSettings(_id, path, value) {
    const idx = _.findIndex(states.interaction.fields, field => field._id === _id);
    setStates(draft => {
      draft.interaction.fields[idx].settings[path] = value;
    });
  }
  function handleChangeSettings(_id, value) {
    const idx = _.findIndex(states.interaction.fields, field => field._id === _id);
    setStates(draft => {
      draft.interaction.fields[idx].settings = value;
    });
  }
  function onFieldsDragEnd(result) {
    if (!result.destination) {
      return;
    }
    setStates(draft => {
      draft.interaction.fields = reorder(draft.interaction.fields, result.source.index, result.destination.index);
    });
  }
  function handleSubmit() {
    onSubmitConfig({
      new_page: {
        ...states.currentPage,
        interactions: [states.interaction]
      },
      variables: currentVariables,
      newInteraction: states.interaction,
      gdprConsent
    });
  }
  return <>
			<FormSettingsWrapper>
				<Box width='100%' height='100%' display='flex' justifyContent='center' alignItems='center' overflow='hidden'>
					<PagePreview size='large' page={{
          ...states.currentPage,
          interactions: [states.interaction]
        }} theming={draftStory.theming} messages={draftStory.messages} gdprConsent={gdprConsent} storyType={draftStory.type} />
				</Box>

				<Box overflow='auto' p={1}>
					<Box width='100%' display='grid' gridTemplateColumns='1fr 1fr' gap={1}>
						<Box>
							<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2} height={39}>
								<Box pl={2} display='flex' alignItems='center' gap={1}>
									<Typography variant='h2'>
										<FormattedMessage id='edition_menu.form.email_verification' />
									</Typography>
									<Tooltip title={<FormattedMessage id='edition_menu.form.email_verification_instruction' />} placement='right'>
										<HelpOutline color='disabled' />
									</Tooltip>
								</Box>

								<IOSSwitch checked={states.interaction.form_properties.email_verification} disabled={verificationEmailDisable} onChange={({
                target: {
                  checked
                }
              }) => handleChangeFormProperties('email_verification', checked)} color='primary' />
							</Box>

							<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2} height={39}>
								<Box pl={2} display='flex' alignItems='center' gap={1}>
									<Typography variant='h2'>
										<FormattedMessage id='edition_menu.form.unique_email' />
									</Typography>
									<Tooltip title={<FormattedMessage id='edition_menu.form.unique_email_instruction' />} placement='right'>
										<HelpOutline color='disabled' />
									</Tooltip>
								</Box>

								<IOSSwitch checked={states.interaction.form_properties.unique_email} disabled={uniqueEmailDisable} onChange={({
                target: {
                  checked
                }
              }) => handleChangeFormProperties('unique_email', checked)} color='primary' />
							</Box>

							<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2} height={39}>
								<Box pl={2} display='flex' alignItems='center' gap={1}>
									<Typography variant='h2'>
										<FormattedMessage id='edition_menu.form.email_disposable' />
									</Typography>
									<PremiumChip />
									<Tooltip title={<FormattedMessage id='edition_menu.form.email_disposable_instruction' />} placement='right'>
										<HelpOutline color='disabled' />
									</Tooltip>
								</Box>

								<IOSSwitch checked={states?.interaction?.form_properties?.hasDisposableValidator} disabled={!disposable_email || verificationEmailDisable} onChange={({
                target: {
                  checked
                }
              }) => handleChangeFormProperties('hasDisposableValidator', checked)} color='primary' />
							</Box>
						</Box>

						<Box>
							<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2} height={39}>
								<Box pl={2} display='flex' alignItems='center' gap={1}>
									<Typography variant='h2'>
										<FormattedMessage id='edition_menu.form.custom_error_messages' />
									</Typography>
									<Tooltip title={<FormattedMessage id='edition_menu.form.custom_error_messages.instruction' />} placement='right'>
										<HelpOutline color='disabled' />
									</Tooltip>
								</Box>

								<FormErrorMessagesModal trigger={<Button variant='contained' color='primary' size='small'>
											<FormattedMessage id='button.customize' />
										</Button>} form={states.interaction} />
							</Box>
							<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2} height={39}>
								<Box pl={2} display='flex' alignItems='center' gap={1}>
									<Typography variant='h2'>
										<FormattedMessage id='edition_menu.form.email_captcha' />
									</Typography>
									<PremiumChip />
									<Tooltip title={<FormattedMessage id='edition_menu.form.email_captcha_instruction' />} placement='right'>
										<HelpOutline color='disabled' />
									</Tooltip>
								</Box>

								<IOSSwitch checked={states?.interaction?.form_properties?.hasCaptcha} disabled={!recaptcha} onChange={({
                target: {
                  checked
                }
              }) => handleChangeFormProperties('hasCaptcha', checked)} color='primary' />
							</Box>
						</Box>
					</Box>

					<Divider />

					<DragDropContext onDragEnd={onFieldsDragEnd}>
						<Droppable droppableId='droppable'>
							{(provided, snapshot) => <div {...provided.droppableProps} ref={provided.innerRef}>
									{_.map(states.interaction.fields, (field, index) => <Draggable key={field._id} draggableId={field._id} index={index}>
											{(provided, snapshot) => <FieldSettings key={field._id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} isDragging={snapshot.isDragging} field={field} canDelete={states.interaction.fields.length > 1} handleRemoveField={handleRemoveField} currentVariables={currentVariables} currentPage={states.currentPage} handleChangeFieldProperty={handleChangeFieldProperty} handleChangeFieldSettings={handleChangeFieldSettings} handleChangeSettings={handleChangeSettings} setVariables={setVariables} />}
										</Draggable>)}
									{provided.placeholder}
								</div>}
						</Droppable>
					</DragDropContext>

					{gdprConsent?.active && <GdprSettings gdprConsent={gdprConsent} handleChangeGdprConsent={handleChangeGdprConsent} />}

					<Box textAlign='center' px={1}>
						<Divider />
						<Typography variant='h4'>
							<FormattedMessage id='edition_menu.form.add_field.description' />
						</Typography>
						<AddNewFieldContainer>
							{_.map(SUB_TYPES_LIST, (sub_type, index) => <Box key={index} textAlign='center'>
									<IconButton onClick={() => handleAddField(sub_type)} size='large'>
										<SvgIcon>{ICONS_LIST[sub_type]}</SvgIcon>
									</IconButton>
									<Typography>
										<FormattedMessage id={`edition_menu.form.field_type.${sub_type}`} />
									</Typography>
								</Box>)}
							<Tooltip title={gdprConsent?.active ? <FormattedMessage id='edition_menu.form.gdpr.gdpr_activated' /> : <FormattedMessage id='edition_menu.form.gdpr.activate' />} placement='bottom'>
								<Box key='gdpr' textAlign='center'>
									<IconButton onClick={() => handleChangeGdprConsent({
                  path: 'active',
                  value: true
                })} disabled={gdprConsent?.active} size='large'>
										<SvgIcon>
											<VerifiedUserOutlined />
										</SvgIcon>
									</IconButton>
									<Typography color={gdprConsent?.active ? 'textSecondary' : 'textPrimary'}>
										<FormattedMessage id='edition_menu.form.field_type.consent' />
									</Typography>
								</Box>
							</Tooltip>

							<Box key='gdpr' textAlign='center'>
								<IconButton onClick={() => handleAddField('username')} disabled={_.some(states?.interaction?.fields, ['variable', '_username'])} size='large'>
									<SvgIcon>
										<AssignmentIndOutlined />
									</SvgIcon>
								</IconButton>
								<Typography color={_.some(states?.interaction?.fields, ['variable', '_username']) ? 'textSecondary' : 'textPrimary'}>
									<FormattedMessage id='edition_menu.form.field_type.username' />
								</Typography>
							</Box>
						</AddNewFieldContainer>
					</Box>
				</Box>
			</FormSettingsWrapper>

			<Box p={2} display='flex' alignItems='center' justifyContent='space-between'>
				{onPreviousStep ? <Button variant='text' onClick={onPreviousStep} startIcon={<ChevronLeft />}>
						<FormattedMessage id='button.previous' />
					</Button> : onCancel ? <Button variant='text' onClick={onCancel}>
						<FormattedMessage id='button.cancel' />
					</Button> : <div />}

				<Button data-intercom-target='Confirm Button' variant='contained' onClick={handleSubmit}>
					<FormattedMessage id='common.theming.confirm_button' />
				</Button>
			</Box>
		</>;
}
PageFormSettings.propTypes = {
  onPreviousStep: PropTypes.func,
  onCancel: PropTypes.func,
  onSubmitConfig: PropTypes.func,
  currentPage: PropTypes.object,
  draftStory: PropTypes.object
};

// Helper functions
// Init
function initAnswersStates(lang, variables, messages, initialStates) {
  if (!_.isEmpty(initialStates)) return initialStates;
  return {
    _id: nanoid(),
    type: 'form',
    next_redirection: 'next',
    next_button: messages.buttons.next,
    send_button: messages.buttons.send,
    form_properties: {
      email_verification: false,
      unique_email: false
    },
    contact: [],
    fields: [{
      ...initField(lang, 'text', variables)
    }]
  };
}
function initField(lang, sub_type, variables) {
  switch (sub_type) {
    case 'firstname':
    case 'lastname':
    case 'username':
    case 'text':
      return {
        _id: nanoid(),
        type: 'text',
        sub_type,
        variable: generateVariableName(sub_type, variables),
        required: true,
        placeholder: translation[`form_field_placeholder_${sub_type}`][lang],
        settings: {
          char_limit: false,
          char_number: 25
        }
      };
    case 'website':
      return {
        _id: nanoid(),
        type: 'website',
        sub_type,
        variable: generateVariableName(sub_type, variables),
        required: true,
        placeholder: translation.form_field_placeholder_website[lang],
        settings: {}
      };
    case 'email':
      return {
        _id: nanoid(),
        type: 'email',
        sub_type,
        variable: generateVariableName(sub_type, variables),
        required: true,
        placeholder: translation.form_field_placeholder_email[lang],
        settings: {
          hasDefaultDomain: false,
          defaultDomains: []
        }
      };
    case 'phone':
      return {
        _id: nanoid(),
        type: 'phone',
        sub_type,
        variable: generateVariableName(sub_type, variables),
        required: true,
        placeholder: translation.form_field_placeholder_phone[lang],
        settings: {
          defaultCountry: 'fr'
        }
      };
    case 'number':
      return {
        _id: nanoid(),
        type: 'number',
        sub_type,
        variable: generateVariableName(sub_type, variables),
        required: true,
        placeholder: translation.form_field_placeholder_number[lang],
        settings: {
          activeMinMax: false,
          min: 0,
          max: 100,
          digit_limit: false,
          digit_number: 5
        }
      };
    case 'date':
      return {
        _id: nanoid(),
        type: 'date',
        sub_type,
        variable: generateVariableName(sub_type, variables),
        required: true,
        placeholder: translation.form_field_placeholder_date[lang],
        settings: {
          format: 'DD/MM/YYYY',
          activeDateRange: false,
          minDate: new Date(),
          maxDate: new Date()
        }
      };
    case 'select':
      return {
        _id: nanoid(),
        type: 'select',
        sub_type,
        variable: generateVariableName(sub_type, variables),
        required: true,
        placeholder: translation.form_field_placeholder_select[lang],
        settings: {
          multiple: false,
          limit_choices: false,
          limit: 4,
          options: [`New Option 1`, `New Option 2`]
        }
      };
    case 'checkbox':
      return {
        _id: nanoid(),
        type: 'checkbox',
        sub_type,
        variable: generateVariableName(sub_type, variables),
        required: true,
        title: translation.form_field_placeholder_checkbox[lang],
        settings: {}
      };
  }
}

// Get variable type via field type
function getVariableType(field_type) {
  if (field_type === 'date') return 'date';
  if (field_type === 'number') return 'number';
  if (field_type === 'checkbox') return 'boolean';
  return 'text';
}