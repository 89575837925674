import AttchmentSettings from 'components/AttachmentSettings';
import AttachmentTemplateSelector from 'components/AttachmentTemplateSelector';
import { useStoryDispatch, useStoryState } from 'lib/editorStore';
import { getAttachmentElement } from 'lib/models';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useImmer } from 'use-immer';
import { ModalWrapper } from '../ModalWrapper';
export default function AttachmentSettingsModal({
  trigger,
  currentAttachment,
  updateCtaBlock,
  isCreating
}) {
  const ref = useRef();
  const dispatch = useStoryDispatch();
  const draftStory = useStoryState(s => s.draftStory);
  const recently_used = useStoryState(s => s.draftStory?.recently_used);
  const brand_colors = useStoryState(s => s.draftStory?.brand_colors);
  const [step, setStep] = useState(isCreating ? 0 : 1);
  const [attachment, setAttachment] = useImmer(initAttachment(currentAttachment, draftStory.story_settings.language));
  function onRecentColorsUpdate(color) {
    dispatch({
      type: 'SET_RECENTLY_USED',
      data: {
        path: 'colors.block',
        value: color
      }
    }, false);
  }
  function onPreviousStep() {
    if (step > 0) {
      setStep(step - 1);
    } else {
      setStep(0);
    }
  }
  function resetAttachment() {
    setAttachment(() => initAttachment(currentAttachment, draftStory.story_settings.language));
  }

  // Apply template
  function onSelectTemplate(template) {
    if (template) {
      // Clean up template
      const newTemplate = _.cloneDeep(template);
      // Apply template
      setAttachment(draft => {
        draft.content = newTemplate.content;
        draft.styles = newTemplate.styles;
        draft.theme = newTemplate.theme;
      });
    }
    setStep(1);
  }
  function onSubmitConfig(NewAttachement) {
    updateCtaBlock(NewAttachement);
    ref.current.close();
  }
  function onCancel() {
    setStep(isCreating ? 0 : 1);
    ref.current.close();
  }
  return <ModalWrapper title={generateModalTile(step)} trigger={trigger} ref={ref} onOpen={resetAttachment}
  // Avoid close modal when editing interacttion
  disableBackdropClick={step === 1} hasCloseButton={step !== 1} size='lg'>
			{step === 0 && <AttachmentTemplateSelector onSelect={onSelectTemplate} onCancel={onCancel} attachmentType='story_attachment' />}
			{step === 1 && <AttchmentSettings onPreviousStep={onPreviousStep} onCancel={onCancel} onSubmitConfig={onSubmitConfig} currentAttachment={attachment} draftStory={draftStory} recentColors={recently_used?.colors?.block} brandColors={brand_colors} updateRecentColor={onRecentColorsUpdate} />}
		</ModalWrapper>;
}
AttachmentSettingsModal.propTypes = {
  trigger: PropTypes.object,
  currentAttachment: PropTypes.object,
  updateCtaBlock: PropTypes.func,
  isCreating: PropTypes.bool
};
function generateModalTile(step) {
  switch (step) {
    case 0:
      return <FormattedMessage id='modals.amp_attachment.modal.template' />;
    case 1:
      return <FormattedMessage id='modals.amp_attachment.modal.settings' />;
  }
}

// Helper function
function initAttachment(currentAttachment = {}, lang) {
  if (currentAttachment.hasOwnProperty('content')) {
    return currentAttachment;
  }
  return {
    _id: nanoid(),
    type: 'story_attachment',
    theme: 'light',
    styles: {
      fontFamily: 'Roboto'
    },
    content: [getAttachmentElement({
      type: 'content',
      sub_type: 'title',
      value: 'Inspiration of the Day'
    }), getAttachmentElement({
      type: 'content',
      sub_type: 'paragraph',
      value: `Life is a journey, and every step you take is a chance to learn and grow. Embrace the challenges and savor the victories.`
    }), getAttachmentElement({
      type: 'content',
      sub_type: 'button'
    })]
  };
}