import { css } from '@emotion/react';
import styled from '@styled';
import { extractPositionProperties, getObjectPosition } from 'lib/utils';
import PropTypes from 'prop-types';
import { memo } from 'react';
import BlockWrapper from './BlockWrapper';
const maskImageCss = ({
  $maskImage
}) => $maskImage && css`
		mask-image: ${`url(${$maskImage})`};
		mask-size: 100% 100%;
	`;
const ImgZoomCss = ({
  inner_style
}) => css`
	transform-origin: ${getObjectPosition(inner_style)};
`;
const StyledImageBlock = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	${maskImageCss}

	img {
		width: 100%;
		height: 100%;

		object-fit: cover;
		object-position: ${({
  inner_style
}) => getObjectPosition(inner_style)};
		transform: ${({
  inner_style
}) => inner_style.zoom ? `scale(${inner_style.zoom})` : 'initial'};

		${({
  inner_style
}) => inner_style?.zoom > 1 && ImgZoomCss({
  inner_style
})};
	}
`;
function Image({
  value,
  outer_style,
  inner_style,
  animation
}) {
  const {
    backgroundSize,
    backgroundPosition,
    transform,
    filter
  } = inner_style;
  const {
    positionProperties,
    visualProperties
  } = extractPositionProperties(outer_style);

  // Reveal override the height so we need to pass the height of the image to the StyledImageBlock component
  return <BlockWrapper animation={animation} positionProperties={positionProperties}>
			<div style={{
      ...visualProperties,
      transform,
      filter
    }}>
				<StyledImageBlock inner_style={inner_style} $maskImage={inner_style?.maskImage}>
					<img src={value} alt='' />
				</StyledImageBlock>
			</div>
		</BlockWrapper>;
}
Image.propTypes = {
  value: PropTypes.string,
  outer_style: PropTypes.object,
  inner_style: PropTypes.object,
  animation: PropTypes.object
};
export default memo(Image);