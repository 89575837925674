import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import styled from '@styled';
import PropTypes from 'prop-types';
export default function Instance({
  description,
  placement,
  display,
  setDisplay,
  tag_style,
  price_style
}) {
  const {
    title,
    price,
    url
  } = description;
  return display && <TagContentContainer style={tag_style} placement={placement} $isLongText={title.trim().length > 13}>
				<div />
				<div>
					<div className='line-clamp'>{title}</div>
					<div className='price' style={price_style}>
						{price}
					</div>
				</div>
				{url && <div>
						<ArrowForwardIosIcon fontSize='small' />
					</div>}
			</TagContentContainer>;
}
Instance.propTypes = {
  description: PropTypes.object,
  placement: PropTypes.string,
  display: PropTypes.bool,
  setDisplay: PropTypes.func,
  tag_style: PropTypes.object,
  price_style: PropTypes.object
};
const TagContentContainer = styled.div`
	border-radius: 5px;
	background-color: #fff;
	width: 120px;
	min-height: 45px;
	position: absolute;
	display: flex;
	pointer-events: auto;
	top: ${({
  placement,
  $isLongText
}) => placement === 'bottom' ? '36px' : $isLongText ? '8px' : '21px'};
	left: 15px;
	cursor: pointer;
	& > div:nth-child(1) {
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-bottom: ${({
  placement,
  style
}) => placement === 'bottom' && `8px solid ${style.backgroundColor}`};
		border-top: ${({
  placement,
  style
}) => placement === 'top' && `8px solid ${style.backgroundColor}`};
		position: absolute;
		left: calc(50% - 8px);
		top: ${({
  placement,
  $isLongText
}) => placement === 'bottom' ? '-7px' : $isLongText ? '60px' : '45px'};
	}
	& > div:nth-child(2) {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
		padding: ${({
  theme
}) => theme.spacing(1)};

		.tag-title {
			text-overflow: ellipsis;
			overflow: hidden;
			font-size: 12px;
			font-weight: 400;
			line-height: 1.2em;
			max-height: 2.4em;
			max-width: 84px;
			margin: 0;
		}
		.tag-price {
			text-overflow: ellipsis;
			overflow: hidden;
			max-height: 20px;
			font-size: 12px;
			color: #5270e8;
		}
	}
	div:nth-child(3) {
		width: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-left: 1px solid #ebeced;
		cursor: pointer;
		background-color: ${({
  style
}) => style.backgroundColor}; // Add Background color again for aplly filter on it
		border-radius: 0 5px 5px 0;
		&:hover {
			filter: brightness(85%);
		}
	}
	box-shadow:
		0 2.8px 2.2px rgba(0, 0, 0, 0.034),
		0 6.7px 5.3px rgba(0, 0, 0, 0.048),
		0 12.5px 10px rgba(0, 0, 0, 0.06),
		0 22.3px 17.9px rgba(0, 0, 0, 0.072),
		0 100px 80px rgba(0, 0, 0, 0.12);
`;