import { css } from '@emotion/react';
import styled from '@styled';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
function HorizontalCollapse({
  in: inProp,
  style,
  className,
  direction,
  children
}) {
  const [collapsed, setCollapsed] = useState(!inProp);
  useEffect(() => {
    setCollapsed(!inProp);
  }, [inProp]);
  return <CollapseWrapper $direction={direction} $isCollapsed={collapsed} style={style} className={className}>
			{children}
		</CollapseWrapper>;
}
HorizontalCollapse.propTypes = {
  direction: PropTypes.string,
  in: PropTypes.bool,
  style: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};
const collapsedStyle = ({
  $isCollapsed,
  $direction
}) => $isCollapsed && css`
		overflow: hidden;
		max-height: 0;
		transform: ${$direction === 'left' ? 'scaleX(0) translateX(100%)' : 'scaleX(0) translateX(-100%)'};
		opacity: 0;
	`;
const CollapseWrapper = styled.div`
	transition:
		transform 0.3s ease-in-out,
		opacity 0.4s ease-in-out;
	transform-origin: ${({
  $direction
}) => $direction === 'left' ? 'right center' : 'left center'};

	${collapsedStyle};
`;
export default HorizontalCollapse;