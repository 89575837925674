import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { AmpBinaryInteractionBase, BinaryOptionsContainer, PromptTextContainer } from './style';
export default function BinaryPollInteraction({
  ampInteraction
}) {
  const intl = useIntl();
  const {
    _id,
    prompt_text,
    prompt_size,
    prompt_color,
    theme,
    backgroundColor,
    alignment,
    option_color,
    options,
    outer_style
  } = ampInteraction;
  return <AmpBinaryInteractionBase $backgroundColor={backgroundColor} style={outer_style}>
			{/* Prompt text */}
			<PromptTextContainer $promptColor={prompt_color} $alignment={alignment} $promptSize={prompt_size}>
				<p>{prompt_text}</p>
			</PromptTextContainer>

			{/* Options */}
			<BinaryOptionsContainer $theme={theme} $optionColor={option_color}>
				<div key={options[0]._id} className='option-container'>
					<span>{options[0].title}</span>
				</div>
				<div className='divider' />
				<div key={options[1]._id} className='option-container'>
					<span>{options[1].title}</span>
				</div>
			</BinaryOptionsContainer>
		</AmpBinaryInteractionBase>;
}
BinaryPollInteraction.propTypes = {
  ampInteraction: PropTypes.object
};