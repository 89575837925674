/* eslint-disable prefer-regex-literals */
import styled from '@styled';
import getVideoId from 'get-video-id';
import PropTypes from 'prop-types';
import React from 'react';
export const REGEX_VIDEO_LOOM = new RegExp(/^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:loom\.com\/share|loom\.com\/embed)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?/);
export const REGEX_VIDEO_VADOOTV_landing = new RegExp(/^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:api\.vadoo\.tv\/landing_page\?vid=)([a-zA-Z0-9]+)(?:_[\w_-]+)?/);
export const REGEX_VIDEO_VADOOTV_embed = new RegExp(/^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:api\.vadoo\.tv\/iframe_test\?id=)([a-zA-Z0-9]+)(?:_[\w_-]+)?/);
export const REGEX_VIDEO_WAVE_VIDEO = new RegExp(/^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:watch\.wave\.video|embed\.wave\.video)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?/);
export const REGEX_VIDEO_ADILO = new RegExp(/^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:adilo\.bigcommand\.com\/watch)\/([a-zA-Z0-9]+)(?:_[\w_-]+)?/);
export const REGEX_VIDEO_SUMPLAYER = new RegExp(/^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:sumplayer\.com\/video)\/([a-zA-Z0-9]+)(?:-[\w_-]+)?/);
export const REGEX_VIDEO_TIKTOK = new RegExp(/^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:tiktok\.com\/)/);
export const REGEX_VIDEO_BIGVU = new RegExp(/^(?:(?:https?):)?(?:\/\/)?(?:www\.)?(?:desk\.bigvu\.tv\/)/);
export default function IframeVideo({
  value
}) {
  return <StyledIframe src={getIframeSrc(value)} frameBorder='0' scrolling='no' allowFullScreen />;
}
IframeVideo.propTypes = {
  value: PropTypes.string
};

// The strategy is check for link of which hosting then get the video_id
function getIframeSrc(value) {
  // Adilo
  if (REGEX_VIDEO_ADILO.test(value) || REGEX_VIDEO_SUMPLAYER.test(value) || REGEX_VIDEO_BIGVU.test(value)) {
    return value;
  }
  // Wave Video
  if (REGEX_VIDEO_WAVE_VIDEO.test(value)) {
    return value.replace('watch', 'embed');
  }
  // Loom
  if (REGEX_VIDEO_LOOM.test(value)) {
    return value.replace('share', 'embed');
  }
  // VadooTV
  if (REGEX_VIDEO_VADOOTV_landing.test(value) || REGEX_VIDEO_VADOOTV_embed.test(value)) {
    return value.replace('landing_page', 'iframe_test').replace('vid', 'id');
  }
  // Tiktok
  if (REGEX_VIDEO_TIKTOK.test(value)) {
    const {
      id
    } = getVideoId(value);
    return `https://tiktok.com/embed/v2/${id}`;
  }
}
export const StyledIframe = styled.iframe`
	width: 100%;
	height: 100%;
	border: none;
`;