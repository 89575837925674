import { immerable } from 'immer'
import { generateVariableName, reorder } from 'lib/utils'
import _ from 'lodash'
import { nanoid } from 'nanoid'

import { Button } from './Button'
import { getPresetButtonStyle, initDefaultTheming } from './initialValues'
import { Page } from './Page'

const DEFAULT_SEO_CONFIG = {
	allow_indexing: true,
	type: '',
	metas: [],
}

export class DraftStory {
	[immerable] = true

	constructor(snackeet) {
		if (snackeet) {
			return this._createFromExisting(snackeet)
		} else {
			return this._createNew()
		}
	}

	_createFromExisting(snackeet) {
		_.assign(this, snackeet)

		if (!_.isEmpty(snackeet.pages)) {
			this.pages = _.map(snackeet.pages, (page) => new Page(page))
			return this
		}

		return this
	}

	_createNew() {
		this.type = 'unset'
		this.tags = []
		this.estimate_duration = 0
		this.status = 'draft'
		this.messages = undefined
		this.theming = undefined
		this.is_api_template = false
		this.integrations = {}
		this.gdprConsent = {}
		this.story_settings = {}
		this.recently_used = {
			shape: {
				shapes: [],
				color: 'rgba(62, 62, 62, 1)',
			},
			font_families: [],
			colors: {
				background: [],
				block: [],
			},
		}
		this.metadata = {
			description: 'This story is powered by Snackeet.',
			image: '',
		}
	}

	// Respect builder pattern for functions that set, name, type & language
	setStoryName(name) {
		this.name = name
		return this
	}

	setStoryType(type) {
		this.type = type

		if (_.isEmpty(this.pages)) {
			this.pages = createInitialPages(this.type)
		}

		this.story_settings = initStorySettings(type)
		this.share_settings = initShareSettings(type)

		return this
	}

	classicToSinglePage() {
		this.type = 'single_page'
		// Remove ending page(s) and keep only the first page
		this.pages = [this.pages[0]]

		// Disable timer of the first page
		this.pages[0].updateTimer([
			{ path: 'value', value: 0 },
			{ path: 'enabled', value: false },
			{ path: 'visible', value: false },
		])
		return this
	}

	singlePageToClassic(newPage) {
		// Create ending Page
		this.type = 'classic'
		const endingPage = new Page().setType({ type: 'ending_page' }).setName('End').addStoryResults()
		// Add new page and ending page
		this.pages = [this.pages[0], newPage, endingPage]

		return this
	}

	setStoryLanguage(language, defaultMessages) {
		this.story_settings.language = language

		if (defaultMessages) {
			this.messages = defaultMessages
		}
		return this
	}

	setAmpStoryPublisher({ path, value }) {
		_.set(this.story_settings.publisher, path, value)
	}

	setAmpStorySeoConfig({ path, value }) {
		if (!this.story_settings.seo_config) {
			this.story_settings.seo_config = DEFAULT_SEO_CONFIG
		}
		_.set(this.story_settings.seo_config, path, value)
	}

	setStoryBrand(brand) {
		// Apply new brand preset
		this.brand_colors = brand?.colors
		this.brand_logo = brand?.logo
		this.brand_fontFamily = brand?.fontFamily
		this.brand_networks = brand?.networks

		if (brand?.brand_favicon) {
			this.story_favicon = brand.brand_favicon
		}

		// Theming
		for (const theme in brand.theming) {
			_.set(this.theming, theme, brand.theming[theme])
		}
	}

	duplicatePage(_id, variables = []) {
		const pageToDuplicateIndex = _.findIndex(this.pages, ['_id', _id])

		const duplicatedPage = _.cloneDeep(this.pages[pageToDuplicateIndex])
		duplicatedPage._id = nanoid()
		duplicatedPage.name = duplicatePageName(duplicatedPage.name)

		// Change elements ids
		if (!_.isEmpty(duplicatedPage.blocks)) {
			duplicatedPage.blocks = _.map(duplicatedPage.blocks, (block) => ({
				...block,
				_id: nanoid(),
			}))
		}
		if (!_.isEmpty(duplicatedPage.tags)) {
			duplicatedPage.tags = _.map(duplicatedPage.tags, (tag) => ({
				...tag,
				_id: nanoid(),
			}))
		}
		if (!_.isEmpty(duplicatedPage.carousels)) {
			duplicatedPage.carousels = _.map(duplicatedPage.carousels, (carousel) => ({
				...carousel,
				_id: nanoid(),
			}))
		}
		if (!_.isEmpty(duplicatedPage.buttonLists)) {
			duplicatedPage.buttonLists = _.map(duplicatedPage.buttonLists, (buttonList) => ({
				...buttonList,
				_id: nanoid(),
			}))
		}
		if (!_.isEmpty(duplicatedPage.answers)) {
			duplicatedPage.answers = _.map(duplicatedPage.answers, (answer) => new Button(answer).duplicate())
		}
		if (!_.isEmpty(duplicatedPage.interactions)) {
			duplicatedPage.interactions = _.map(duplicatedPage.interactions, (interaction) => {
				if (interaction.type !== 'form') {
					return { ...interaction, _id: nanoid() }
				}
				return {
					...interaction,
					_id: nanoid(),
					fields: dupliacteFormFields(interaction?.fields, variables),
				}
			})
		}
		// Duplicate AMP interactions
		if (!_.isEmpty(duplicatedPage.amp_interactions)) {
			duplicatedPage.amp_interactions = _.map(duplicatedPage.amp_interactions, (interaction) => {
				return { ...interaction, _id: `amp-${nanoid()}` }
			})
		}
		if (!_.isEmpty(duplicatedPage.amp_attachments)) {
			duplicatedPage.amp_attachments = _.map(duplicatedPage.amp_attachments, (attachment) => {
				if (attachment.type !== 'amp_form') {
					return { ...attachment, _id: nanoid() }
				}
				return {
					...attachment,
					_id: `amp-${nanoid()}`,
					content: dupliacteFormFields(attachment?.content, variables),
				}
			})
		}

		// Duplicate Page's Logics
		if (!_.isEmpty(duplicatedPage.logics)) {
			duplicatedPage.logics = updatePageLogicsIds({
				logics: duplicatedPage.logics,
				duplicatedPage,
				pageToDuplicate: _.cloneDeep(this.pages[pageToDuplicateIndex]),
			})
		}

		this.pages.splice(pageToDuplicateIndex + 1, 0, duplicatedPage)
		return duplicatedPage
	}

	changePageLayout(_id, layout, variables) {
		const currentPageIndex = _.findIndex(this.pages, ['_id', _id])
		const currentPage = this.pages[currentPageIndex]
		currentPage.changeLayout(layout, variables)
		return currentPage
	}

	deletePage(_id) {
		const indexPageToRemove = _.findIndex(this.pages, { _id })
		if (indexPageToRemove !== -1 && !['ending_page'].includes(this.pages[indexPageToRemove]?.type)) {
			this.pages.splice(indexPageToRemove, 1)
		}

		// Return the index of the previous page
		const previousPageIndex = indexPageToRemove > 0 ? indexPageToRemove - 1 : 0
		return previousPageIndex
	}

	togglePageDisable(_id) {
		const currentPageIndex = _.findIndex(this.pages, ['_id', _id])
		const currentPage = this.pages[currentPageIndex]
		currentPage.togglePageDisabled()
		return currentPage
	}

	setAnswerButtonShape({ type, buttonType = 'text' }) {
		if (buttonType === 'image') {
			const currentAnswerStyle = _.cloneDeep(this.theming.image_answers)
			_.assign(currentAnswerStyle, getPresetButtonStyle(type))
			_.set(this.theming, 'image_answers', currentAnswerStyle)
		} else {
			const currentAnswerStyle = _.cloneDeep(this.theming.answers.default)
			_.assign(currentAnswerStyle, getPresetButtonStyle(type))
			_.set(this.theming, 'answers.default', currentAnswerStyle)
		}
	}

	updateTheming({ path, value }) {
		const [answerType, answerState, property] = path.split('.')
		if (answerType === 'answer' && answerState === 'default') {
			if (
				[
					'justifyContent',
					'textDecoration',
					'fontFamily',
					'fontWeight',
					'fontSize',
					'borderWidth',
					'borderRadius',
				].includes(property)
			) {
				_.set(this.theming, `${answerType}.selected.${property}`, value)
				_.set(this.theming, `${answerType}.correct.${property}`, value)
				_.set(this.theming, `${answerType}.incorrect.${property}`, value)
			}
		}
		_.set(this.theming, path, value)
	}

	updateRecentlyUsed({ path, value }) {
		const current_recently_used = _.cloneDeep(this.recently_used)
		const newValue = updateRecentlyUsedValue({
			current_recently_used,
			path,
			value,
		})

		_.set(this.recently_used, path, newValue)
	}

	updateMessages({ path, value }) {
		_.set(this.messages, path, value)
	}

	updateTimerStyle(styleOperations) {
		for (const operation of _.castArray(styleOperations)) {
			const { path, value } = operation

			this.pages = _.map(this.pages, (page) => {
				if (page.type === 'ending_page') {
					return page
				}

				const updatedPage = _.cloneDeep(page)
				if (path === 'size') {
					_.set(updatedPage.timer, 'outer_style.width', value)
					_.set(updatedPage.timer, 'outer_style.height', value)
				}
				_.set(updatedPage.timer, path, value)
				return updatedPage
			})
		}
	}

	resetTheming(path) {
		const defaultTheming = initDefaultTheming()
		// Partial reset
		if (path) {
			const defaultValues = _.get(defaultTheming, path)
			const [answerType, answerState, property] = path.split('.')

			if (answerType === 'answer' && answerState === 'default') {
				const defaultSelectedValues = _.get(defaultTheming, `${answerType}.selected.${property}`)
				_.set(this.theming, `${answerType}.selected.${property}`, defaultSelectedValues)
				const defaultCorrectValues = _.get(defaultTheming, `${answerType}.correct.${property}`)
				_.set(this.theming, `${answerType}.correct.${property}`, defaultCorrectValues)
				const defaultIncorrectValues = _.get(defaultTheming, `${answerType}.incorrect.${property}`)
				_.set(this.theming, `${answerType}.incorrect.${property}`, defaultIncorrectValues)
			}
			_.set(this.theming, path, defaultValues)
		} else {
			// Full reset
			this.theming = defaultTheming
		}
	}

	reorderEndings(result) {
		const pages_copy = _.cloneDeep(this.pages)
		let endings = _.remove(pages_copy, (page) => page.type === 'ending_page')
		endings = reorder(endings, result.source.index, result.destination.index)
		this.pages = [...pages_copy, ...endings]
	}

	reorderPages(result) {
		let pages_copy = _.cloneDeep(this.pages)
		const home_page = _.remove(pages_copy, (page) => page.type === 'start_page')
		const endings = _.remove(pages_copy, (page) => page.type === 'ending_page')
		pages_copy = reorder(pages_copy, result.source.index, result.destination.index)
		this.pages = [...home_page, ...pages_copy, ...endings]
	}

	setPageName(_id, name) {
		const pageIndex = _.findIndex(this.pages, ['_id', _id])
		_.set(this.pages[pageIndex], 'name', name)
	}

	setIntegration(integrationName, integrationData) {
		_.set(this, `integrations.${integrationName}`, integrationData)
		if (!integrationData) {
			_.unset(this, `integrations.${integrationName}`)
		}
	}

	setMetadata(operations) {
		for (const operation of _.castArray(operations)) {
			_.set(this.metadata, operation.path, operation.value)
		}
	}

	setStoryCustomUrl({ story_slug, story_fqdn }) {
		this.story_fqdn = story_fqdn
		this.story_slug = story_slug
	}

	setStoryFavicon(story_favicon) {
		this.story_favicon = story_favicon
	}

	toggleGdprConsent() {
		_.set(this, 'gdprConsent.active', !this.gdprConsent?.active)
		return this.gdprConsent.active
	}

	setGdprConsent(data) {
		for (const path in data) {
			_.set(this, `gdprConsent.${path}`, data[path])
			if (!data[path]) {
				_.unset(this, `gdprConsent.${path}`)
			}
		}
	}

	updateNotification(operations) {
		for (const operation of operations) {
			const { notificationName, path, value } = operation
			const index = _.findIndex(this.story_settings.notifications, {
				name: notificationName,
			})
			this.story_settings.notifications[index][path] = value
		}
	}

	updateReply({ path, value }) {
		this.story_settings.reply[path] = value
	}

	toggleDisplayLiveResults() {
		this.story_settings.display_live_results = !this.story_settings.display_live_results
	}

	toggleRefreshUser() {
		this.story_settings.refresh_user = !this.story_settings.refresh_user
	}

	toggleRedirectOnCompleted() {
		this.story_settings.redirect_on_completed = !this.story_settings.redirect_on_completed
	}

	toggleApiTemplate() {
		this.is_api_template = !this.is_api_template
	}

	setSharing(action) {
		const { data, path } = action
		const shareSettings = this.share_settings
		if (path === 'social_networks') {
			_.set(shareSettings, path, [...data])
			return
		}
		_.set(shareSettings, path, data)

		this.share_settings = shareSettings
	}

	toggleSharing() {
		this.share_settings.active = !this.share_settings?.active
	}
}

// Helper functions

function updatePageLogicsIds({ logics, duplicatedPage, pageToDuplicate }) {
	const newLogics = _.cloneDeep(logics)
	const newPageIds = {}
	const newFieldIds = {}
	const newAnswerIds = {}

	newPageIds[pageToDuplicate._id] = duplicatedPage._id
	if (pageToDuplicate.type === 'form') {
		if (pageToDuplicate.interactions) {
			const currentForm = _.find(pageToDuplicate.interactions, ['type', 'form'])
			const newForm = _.find(duplicatedPage.interactions, ['type', 'form'])
			_.each(currentForm.fields, (field, idx) => {
				newFieldIds[field._id] = newForm.fields[idx]._id
			})
		}
	}

	if (pageToDuplicate.type === 'multiple_choice') {
		_.each(pageToDuplicate.answers, (answer, idx) => {
			newAnswerIds[answer._id] = duplicatedPage.answers[idx]._id
		})
	}

	_.each(newLogics, (logic) => {
		if (!_.isEmpty(logic.conditions)) {
			logic.conditions.rules = updateRulesIds({
				rules: logic.conditions.rules,
				newPageIds,
				newAnswerIds,
				newFieldIds,
			})
		}
	})

	return newLogics
}

function updateRulesIds({ rules, newPageIds, newAnswerIds, newFieldIds }) {
	const newRules = _.cloneDeep(rules)

	for (const rule of newRules) {
		if (!_.isEmpty(rule.rules)) {
			rule.rules = updateRulesIds({
				rules: rule.rules,
				newPageIds,
				newAnswerIds,
				newFieldIds,
			})
		} else {
			if (rule.field.startsWith('answers.')) {
				const currentPageId = rule.field.split('.')[1]

				if (newPageIds[currentPageId]) {
					rule.field = `answers.${newPageIds[currentPageId]}`
				}
				if (newAnswerIds[rule.value]) {
					rule.value = newAnswerIds[rule.value]
				}
			} else if (rule.field.startsWith('form.')) {
				const currentPageId = rule.field.split('.')[1]
				const currentFieldId = rule.field.split('.')[2]

				if (newPageIds[currentPageId]) {
					rule.field = `form.${newPageIds[currentPageId]}.${newFieldIds[currentFieldId]}`
				}
			} else if (rule.field.startsWith('rating.')) {
				const currentPageId = rule.field.split('.')[1]

				if (newPageIds[currentPageId]) {
					rule.field = `rating.${newPageIds[currentPageId]}`
				}
			} else if (rule.field.startsWith('media_answer.')) {
				const currentPageId = rule.field.split('.')[1]

				rule.field = `media_answer.${newPageIds[currentPageId]}`
			}
		}
	}

	return newRules
}

// Update recently_used
function updateRecentlyUsedValue({ current_recently_used, path, value }) {
	switch (path) {
		case 'shape.shapes': {
			const currentValue = _.get(current_recently_used, path)
			// find index of recent shape in recently_used
			const index = _.findIndex(currentValue, (shape) => _.isEqual(shape.data, value))

			if (index !== 0) {
				if (index > 0) {
					currentValue.splice(index, 1)
				}

				if (currentValue.length === 12) {
					currentValue.pop()
				}

				currentValue.unshift({ _id: nanoid(), data: value })
			}
			return currentValue
		}
		case 'shape.color':
			return value
		case 'font_families': {
			const currentValue = _.get(current_recently_used, path)
			// find index of recent font in recently_used
			const index = _.findIndex(currentValue, (font) => _.isEqual(font, value))

			if (index !== 0) {
				if (index > 0) {
					currentValue.splice(index, 1)
				}

				if (currentValue.length === 4) {
					currentValue.pop()
				}

				currentValue.unshift(value)
			}
			return currentValue
		}
		case 'colors.background': {
			const currentValue = _.get(current_recently_used, path)
			// find index of recent bg color in recently_used
			const index = _.findIndex(
				currentValue,
				(bgColor) =>
					(value.type === 'color' && value.color === bgColor.color) ||
					(value.type === 'gradient' && _.isEqual(bgColor.gradient, value.gradient))
			)

			if (index !== 0) {
				if (index > 0) {
					currentValue.splice(index, 1)
				}

				if (currentValue.length === 7) {
					currentValue.pop()
				}

				currentValue.unshift({ _id: nanoid(), ...value })
			}
			return currentValue
		}
		case 'colors.block': {
			const currentValue = _.get(current_recently_used, path)
			// find index of recent color in recently_used
			const index = _.findIndex(currentValue, (blockColor) => blockColor.color === value)

			if (index !== 0) {
				// If color already exists, remove it
				if (index > 0) {
					currentValue.splice(index, 1)
				}
				// If there are already 30 colors, remove the last one
				if (currentValue.length === 30) {
					currentValue.pop()
				}

				// Add the new color at the beginning of the array
				currentValue.unshift({ _id: nanoid(), color: value })
			}
			return currentValue
		}
		case 'text_styles': {
			let currentValue = _.get(current_recently_used, path)
			if (!currentValue) {
				currentValue = {}
			}

			for (const operation of _.castArray(value)) {
				const { path, value } = operation
				_.set(currentValue, path, value)
			}

			return currentValue
		}
		case 'background': {
			return value
		}
	}
}

function createInitialPages(storyType) {
	if (storyType === 'single_page') {
		const blankPage = new Page().setType({ type: 'blank' }).setName('Landing Page')

		// Disable timer
		blankPage.updateTimer([
			{ path: 'value', value: 0 },
			{ path: 'enabled', value: false },
			{ path: 'visible', value: false },
		])

		return [blankPage]
	}

	if (storyType === 'amp') {
		const ampPage = new Page().setType({ type: 'amp_page' }).setName('Cover Page')

		return [ampPage]
	}

	// Classic story
	// const startPage = new Page().setType({ type: 'start_page' }).setName('Home').hideStartButton()
	const startPage = new Page().setType({ type: 'blank' }).setName('Home')

	const endPage = new Page().setType({ type: 'ending_page' }).setName('End').addStoryResults()

	return [startPage, endPage]
}

function initShareSettings(storyType) {
	if (storyType === 'amp') {
		return {
			email: {
				active: true,
			},
			facebook: {
				active: true,
				app_id: 1410324386157154,
			},
			pinterest: {
				active: true,
			},
			tumblr: {
				active: true,
			},
			twitter: {
				active: true,
			},
			whatsapp: {
				active: true,
			},
			line: {
				active: true,
			},
			sms: {
				active: true,
			},
		}
	}

	return {
		active: false,
		titre: 'My story',
		description: 'This story is powered by Snackeet.',
		social_networks: [],
	}
}

function initStorySettings(storyType) {
	if (storyType === 'amp') {
		return {
			language: 'en',
			disable_powered_by: false,
			display_live_results: true,
			refresh_user: false,
			publisher: {
				name: '',
				logo: '',
				poster_portrait: '',
				poster_square: '',
				poster_landscape: '',
			},
			seo_config: DEFAULT_SEO_CONFIG,
			notifications: [
				{
					name: 'answers',
					active: false,
					completedOnly: false,
					hasContactOnly: false,
					sendTo: '',
				},
			],
			reply: {
				active: false,
				replyTo: '',
				subject: '',
				imgURL: '',
				message: '',
			},
		}
	}

	return {
		language: 'en',
		disable_powered_by: false,
		disable_progress_bar: false,
		display_live_results: true,
		display_navigation_buttons: false,
		navigation_method: 'tap',
		refresh_user: false,
		seo_config: DEFAULT_SEO_CONFIG,
		notifications: [
			{
				name: 'answers',
				active: false,
				completedOnly: false,
				hasContactOnly: false,
				sendTo: '',
			},
		],
		reply: {
			active: false,
			replyTo: '',
			subject: '',
			imgURL: '',
			message: '',
		},
		desktop_wallpaper: {
			enabled: false,
			type: 'image',
			value: '',
		},
	}
}

function duplicatePageName(name) {
	const matchedArray = name.match(/\((\d+)\)$/i)
	if (matchedArray) {
		const count = parseInt(matchedArray[1])
		return name.replace(new RegExp(`\\(${count}\\)$`), `(${count + 1})`)
	}
	return `${name} (1)`
}

// Change default variable and _id
function dupliacteFormFields(fields, variables) {
	const new_fields = _.cloneDeep(fields)
	const tempVariables = _.cloneDeep(variables)

	for (const field of new_fields) {
		field._id = nanoid()
		if (field.variable) {
			const new_variableName = generateVariableName(field?.sub_type, tempVariables)

			const idx = _.findIndex(tempVariables, ['name', field.variable])
			if (idx !== -1) {
				tempVariables[idx].isUsed = true
			} else {
				// Create new variable
				tempVariables.push({
					name: new_variableName,
					isUsed: true,
				})
			}

			field.variable = new_variableName
		}
	}

	return new_fields
}
