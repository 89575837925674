// Required: story, projects list, organization_id, customer role in organization
// Via role of client in organization -> 2 cases
// Case admin or super_admin: Display projects list to choose accessible projects
// Case agent: Only current project
// Then confirmation step and loading

import { Alert, Box, Button, Checkbox, CircularProgress, DialogActions, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ModalContent, ProjectsAccessWrapper } from 'components/styled/Modals';
import { sleep } from 'lib/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCustomerRights } from 'redux/customer/selectors';
import { getCurrentOrganizationId } from 'redux/organization/selectors';
import OrganizationsSvc from 'services/OrganizationsSvc';
import TemplatesSvc from 'services/TemplatesSvc';
import { useImmer } from 'use-immer';
import { ModalWrapper } from '../ModalWrapper';
const SaveAsStoryTemplateModal = forwardRef(function SaveAsStoryTemplateModal({
  draftStory,
  publishedStory,
  onClose,
  onOpen
}, ref) {
  const organizationId = useSelector(getCurrentOrganizationId);
  const currentCustomerRights = useSelector(getCustomerRights);
  const [states, setStates] = useImmer({
    accessProjects: [],
    name: '',
    organizationProjects: [],
    isSaving: false
  });
  const queryClient = useQueryClient();
  const {
    data: organizationProjects = []
  } = useQuery({
    queryKey: ['projects', {
      organization_id: organizationId
    }],
    queryFn: ({
      queryKey
    }) => {
      const {
        organization_id
      } = queryKey[1];
      return OrganizationsSvc.getProjects(organization_id);
    }
  });
  useEffect(() => {
    setStates(draft => {
      if (draftStory) {
        draft.accessProjects = [draftStory.project];
        draft.name = draftStory?.name;
      } else {
        draft.accessProjects = [];
        draft.name = '';
      }
    });
  }, [draftStory, setStates]);

  // Handle Change Project Access
  function handleProjectAccessChange(project_id) {
    if (!_.includes(states.accessProjects, project_id)) {
      setStates(draft => {
        draft.accessProjects.push(project_id);
      });
    } else {
      setStates(draft => {
        draft.accessProjects = draft.accessProjects.filter(_id => _id !== project_id);
      });
    }
  }

  // Handle Change Template name
  function handleTemplateName(value) {
    setStates(draft => {
      draft.name = value;
    });
  }

  // Save as template
  async function saveAsTemplate() {
    setStates(draft => {
      draft.isSaving = true;
    });
    const data = await TemplatesSvc.saveStoryAsTemplate({
      story: {
        ...draftStory,
        snapshot_url_png: publishedStory.snapshot_url_png
      },
      name: states.name,
      accessibleProjects: states.accessProjects,
      project: draftStory.project,
      organization_id: organizationId
    });
    queryClient.invalidateQueries({
      queryKey: ['customStoryTemplates']
    });
    await sleep(1000);
    setStates(draft => {
      draft.isSaving = false;
    });
    ref.current?.close();
  }
  return <>
			<ModalWrapper title={<FormattedMessage id='modals.save_template.title' />} size='sm' ref={ref} onClose={onClose} onOpen={onOpen}>
				<ModalContent>
					<Box mb={1.5}>
						<Typography variant='h3' style={{
            marginBottom: '4px'
          }}>
							<FormattedMessage id='modals.save_template.name' />
						</Typography>
						<TextField fullWidth variant='outlined' value={states.name} onChange={evt => handleTemplateName(evt.target.value)} error={_.isEmpty(states.name)} />
					</Box>

					{currentCustomerRights.isAdmin ? <>
							<Typography variant='h3'>
								<FormattedMessage id='modals.save_template.story.header.admin' />
							</Typography>
							<ProjectsAccessWrapper component='fieldset'>
								<FormGroup>
									{_.map(organizationProjects.slice(0, Math.ceil(organizationProjects.length / 2)), project => <FormControlLabel key={project._id} control={<Checkbox checked={_.includes(states.accessProjects, project._id)} name={project._id} onChange={() => handleProjectAccessChange(project._id)} />} label={project.name} />)}
								</FormGroup>
								<FormGroup>
									{_.map(organizationProjects.slice(Math.ceil(organizationProjects.length / 2)), project => <FormControlLabel key={project._id} control={<Checkbox checked={_.includes(states.accessProjects, project._id)} name={project._id} onChange={() => handleProjectAccessChange(project._id)} />} label={project.name} />)}
								</FormGroup>
							</ProjectsAccessWrapper>
							<Alert severity='info'>
								<Typography variant='body2'>
									<FormattedMessage id='modals.save_template.story.info.admin' />
								</Typography>
							</Alert>
						</> : <>
							<Typography variant='h3'>
								<FormattedMessage id='modals.save_template.story.header.agent' />
							</Typography>
							<Alert severity='warning'>
								<Typography variant='body2'>
									<FormattedMessage id='modals.save_template.story.warning.agent' />
								</Typography>
							</Alert>
							<Alert severity='info'>
								<Typography variant='body2'>
									<FormattedMessage id='modals.save_template.story.info.agent' />
								</Typography>
							</Alert>
						</>}
				</ModalContent>

				<DialogActions>
					<Button variant='text' onClick={() => ref.current?.close()}>
						<FormattedMessage id='button.cancel' />
					</Button>
					<Button variant='outlined' color='secondary' onClick={() => saveAsTemplate()} disabled={states.isSaving || _.isEmpty(states.name)}>
						{states.isSaving && <CircularProgress style={{
            marginRight: '8px'
          }} color='primary' size={20} />}
						<FormattedMessage id='button.confirm' />
					</Button>
				</DialogActions>
			</ModalWrapper>
		</>;
});
SaveAsStoryTemplateModal.propTypes = {
  story: PropTypes.object,
  trigger: PropTypes.object,
  onClose: PropTypes.func,
  onOpen: PropTypes.func
};
export default SaveAsStoryTemplateModal;