import { Box } from '@mui/material';
import styled from '@styled';
import { snackeet_dark_grey } from 'styles/colors';
export const SliderContainer = styled(Box)``;
SliderContainer.defaultProps = {
  minWidth: '170px',
  width: '90%',
  display: 'flex',
  flexDirection: 'column'
};
const BaseSliderSection = styled.div`
	.MuiSlider-root {
		flex: 2;
		margin: ${({
  theme
}) => theme.spacing(0, 2, 0, 0.5)};
	}

	.MuiSlider-track,
	.MuiSlider-rail {
		height: 8px;
		border-radius: 4px;
	}

	.MuiSlider-rail {
		background-color: ${snackeet_dark_grey};
	}

	.MuiSlider-thumbColorPrimary {
		box-shadow: ${({
  theme
}) => theme.shadows[1]};
		height: 15px;
		width: 15px;
		/* margin-top: -3.5px; */

		&:hover {
			box-shadow: 0px 0px 0px 5px rgba(31, 31, 31, 0.16);
		}
	}
`;
export const SliderSection = styled(BaseSliderSection)`
	display: flex;
	align-items: center;

	.slider-input {
		outline: none;
		border: none;
		min-width: 25px !important;
		max-width: 35px;
		font-weight: bold;
	}

	.slider-unit {
		width: 10px;
		font-size: 12px;
	}
`;
export const ZoomSliderContainer = styled(BaseSliderSection)`
	display: flex;
	align-items: center;
	width: 100%;

	.MuiList-root {
		min-width: 100px;
	}
`;