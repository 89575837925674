import { PagePreviewStoryButton } from 'atoms';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { memo } from 'react';
import { InterfaceContainer } from '../styled';
import RatingInteraction from './RatingInteraction';
import StoryResults from './StoryResults';
function EndPageInterface({
  page,
  storyType,
  messages,
  theming,
  hasQuiz
}) {
  const story_results = _.find(page.interactions, {
    type: 'story_results'
  });
  const rating = _.find(page.interactions, {
    type: 'rating'
  });
  const hasResults = _.get(story_results, 'visible') && hasQuiz;
  return <InterfaceContainer style={page.interface_style}>
			<div className='interactions'>
				{hasResults && storyType === 'classic' && <StoryResults story_results={story_results} />}

				{rating && <RatingInteraction rating={rating} />}

				{(page.restart_allowed || page.redirection_button?.active) && <div className='ending-page-actions'>
						{page.redirection_button?.active && <PagePreviewStoryButton name='redirection' theming={theming} messages={messages} />}
						{page.restart_allowed && <PagePreviewStoryButton name='restart' theming={theming} messages={messages} />}
					</div>}
			</div>
		</InterfaceContainer>;
}
EndPageInterface.propTypes = {
  page: PropTypes.object,
  storyType: PropTypes.string,
  messages: PropTypes.object,
  theming: PropTypes.object,
  hasQuiz: PropTypes.bool
};
export default memo(EndPageInterface);