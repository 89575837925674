// Component adapted from https://github.com/MrBartusek/gif-picker-react

import { useSelector } from 'react-redux';
import { getAppLocale } from 'redux/customer/selectors';
import ErrorBoundary from './components/ErrorBoundary';
import GifPickerReact from './GifPickerReact';
import { TenorAssetType } from './types/exposedTypes';
type GifGalleryProps = {
  assetType?: TenorAssetType;
  handleUrl: (url: {
    url: string;
  }) => void;
};
export default function GifGallery({
  assetType,
  handleUrl
}: GifGalleryProps) {
  const appLocale = useSelector(getAppLocale);
  return <ErrorBoundary>
			<GifPickerReact assetType={assetType} tenorApiKey={process.env.NEXT_PUBLIC_TENOR_API_KEY!} onGifClick={({
      url
    }) => handleUrl({
      url
    })} locale={appLocale} width='100%' height='100%' />
		</ErrorBoundary>;
}