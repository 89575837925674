import { Favorite, FavoriteBorder, Lens, LensOutlined } from '@mui/icons-material';
import { Box, Rating as MuiRating } from '@mui/material';
import { CustomIcon } from 'components/icons';
import { RatingList } from 'lib/constants';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { RatingContainer, RatingInteractionContainer, RedMuiRating } from '../styled';
function NumberIconContainer(props) {
  const {
    value,
    ...delegatedProps
  } = props;
  return <span {...delegatedProps}>
			<CustomIcon name={`Svg${value}Number`} fontSize={'30px'} height={30} width={30} padding={0} margin={0.1} />
		</span>;
}
NumberIconContainer.propTypes = {
  value: PropTypes.number.isRequired
};
export default function RatingInteraction({
  rating
}) {
  const {
    _id,
    title,
    subtitle,
    max,
    category,
    style = {}
  } = rating;
  const ratingModel = _.find(RatingList, {
    category
  });
  const fontSize = getRatingSize(max);
  function renderMuiRating(type) {
    switch (type) {
      case 'stars':
        return <MuiRating name={`rating_${_id}`} sx={{
          fontSize
        }} defaultValue={max - 2} max={max} readOnly />;
      case 'number':
        return <MuiRating name={`rating_${_id}`} sx={{
          fontSize
        }} defaultValue={max - 2} IconContainerComponent={NumberIconContainer} max={max} readOnly />;
      case 'heart':
        return <RedMuiRating name={`rating_${_id}`} sx={{
          fontSize
        }} defaultValue={max - 2} max={max} readOnly icon={<Favorite fontSize='inherit' />} emptyIcon={<FavoriteBorder fontSize='inherit' />} />;
      case 'lens':
        return <MuiRating name={`rating_${_id}`} sx={{
          fontSize
        }} defaultValue={max - 2} max={max} readOnly icon={<Lens fontSize='inherit' />} emptyIcon={<LensOutlined fontSize='inherit' />} />;
      default:
        return null;
    }
  }
  return <RatingInteractionContainer style={{
    backgroundColor: style?.backgroundColor || 'rgba(255, 255, 255, 100)'
  }}>
			{title && <Box fontWeight='bold' textAlign='center' mb={1} style={{
      fontSize: style?.fontSize || '16px',
      fontFamily: style?.fontFamily || 'Poppins',
      color: style?.color || 'rgba(72, 71, 71, 100)'
    }}>
					{title}
				</Box>}

			{category.startsWith('classic') ? renderMuiRating(category.split('_')[1]) : <RatingContainer>
					{ratingModel.imgs.map((img, idx) => <div key={idx} style={{
        backgroundImage: img.url,
        width: ratingModel.size,
        height: ratingModel.size
      }} />)}
				</RatingContainer>}

			{subtitle && <Box textAlign='center' mb={1} style={{
      fontSize: style?.subtileFontSize || '14px',
      fontFamily: style?.fontFamily || 'Poppins',
      color: style?.color || 'rgba(72, 71, 71, 100)'
    }}>
					{subtitle}
				</Box>}
		</RatingInteractionContainer>;
}
RatingInteraction.propTypes = {
  rating: PropTypes.object.isRequired
};
function getRatingSize(max) {
  if (max <= 6) {
    return '50px';
  } else if (max === 7) {
    return '45px';
  } else if (max === 8) {
    return '40px';
  } else if (max === 9) {
    return '35px';
  } else if (max === 10) {
    return '30px';
  }
}