import { Delete } from '@mui/icons-material';
import { Box, Button, Collapse, DialogActions, IconButton, TextField, Typography } from '@mui/material';
import { IOSSwitch } from 'atoms';
import CustomDatePicker from 'components/organisms/CustomDatePicker';
import dayjs from 'lib/dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useImmer } from 'use-immer';
import isEmail from 'validator/lib/isEmail';
import { ModalWrapper } from '../ModalWrapper';
const FieldAdvancedSettingsModal = forwardRef(({
  field_id,
  field_settings,
  field_type,
  handleChangeFieldSettings
}, ref) => {
  const intl = useIntl();
  const configRef = useRef();
  const [settings, setSettings] = useImmer(field_settings);
  const open = () => {
    configRef.current.open();
  };
  const close = () => {
    configRef.current.close();
  };
  function handleChangeProperty(path, value) {
    setSettings(draft => {
      draft[path] = value;
    });
  }
  function onConfirm() {
    handleChangeFieldSettings(field_id, settings);
    configRef.current.close();
  }
  useImperativeHandle(ref, () => ({
    open,
    close
  }));
  function renderSettings(field_type) {
    switch (field_type) {
      case 'text':
      case 'firstname':
      case 'lastname':
        return <>
							<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2}>
								<Box pl={2}>
									<Typography variant='h2'>
										<FormattedMessage id='edition_menu.form.field_settings.limit_text' />
									</Typography>
									<Typography variant='body1'>
										<FormattedMessage id='edition_menu.form.field_settings.limit_number.message' />
									</Typography>
								</Box>

								<IOSSwitch checked={!!settings.char_limit} onChange={({
              target: {
                checked
              }
            }) => handleChangeProperty('char_limit', checked)} color='primary' />
							</Box>

							<Collapse in={settings?.char_limit}>
								<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2}>
									<Box pl={2}>
										<Typography variant='h2'>
											{intl.messages['edition_menu.form.field.text_limit.detail']}
										</Typography>
									</Box>
									<Box>
										<TextField variant='standard' placeholder={intl.messages['common.value']} type='number' value={settings.char_number} onChange={({
                  target: {
                    value
                  }
                }) => handleChangeProperty('char_number', value)} />
									</Box>
								</Box>
							</Collapse>
						</>;
      case 'email':
        return <>
							<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2}>
								<Box pl={2}>
									<Typography variant='h2'>
										{intl.messages['edition_menu.form.field_settings.hasDefaultDomain']}
									</Typography>
									<Typography variant='body1'>
										{intl.messages['edition_menu.form.field_settings.hasDefaultDomain.message']}
									</Typography>
								</Box>

								<IOSSwitch checked={!!settings?.hasDefaultDomain} onChange={({
              target: {
                checked
              }
            }) => handleChangeProperty('hasDefaultDomain', checked)} color='primary' />
							</Box>

							<Collapse in={settings?.hasDefaultDomain}>
								<DefaultDomains defaultDomains={settings?.defaultDomains} handleChangeProperty={handleChangeProperty} />
							</Collapse>
						</>;
      case 'number':
        return <>
							<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2}>
								<Box pl={2}>
									<Typography variant='h2'>
										<FormattedMessage id='edition_menu.form.field_settings.activeMinMax' />
									</Typography>
									<Typography variant='body1'>
										<FormattedMessage id='edition_menu.form.field_settings.activeMinMax.message' />
									</Typography>
								</Box>

								<IOSSwitch checked={settings.activeMinMax} onChange={({
              target: {
                checked
              }
            }) => handleChangeProperty('activeMinMax', checked)} color='primary' />
							</Box>

							<Collapse in={settings?.activeMinMax}>
								<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2}>
									<Box pl={2}>
										<Typography variant='h2'>Min</Typography>
									</Box>
									<Box>
										<TextField variant='standard' placeholder={intl.messages['common.value']} type='number' value={settings.min} onChange={({
                  target: {
                    value
                  }
                }) => handleChangeProperty('min', value)} />
									</Box>
								</Box>

								<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2}>
									<Box pl={2}>
										<Typography variant='h2'>Max</Typography>
									</Box>
									<Box>
										<TextField variant='standard' placeholder={intl.messages['common.value']} type='number' value={settings.max} onChange={({
                  target: {
                    value
                  }
                }) => handleChangeProperty('max', value)} />
									</Box>
								</Box>
							</Collapse>

							<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2}>
								<Box pl={2}>
									<Typography variant='h2'>
										<FormattedMessage id='edition_menu.form.field_settings.limit_number' />
									</Typography>
									<Typography variant='body1'>
										<FormattedMessage id='edition_menu.form.field_settings.limit_number.message' />
									</Typography>
								</Box>

								<IOSSwitch checked={!!settings.digit_limit} onChange={({
              target: {
                checked
              }
            }) => handleChangeProperty('digit_limit', checked)} color='primary' />
							</Box>

							<Collapse in={settings?.digit_limit}>
								<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2}>
									<Box pl={2}>
										<Typography variant='h2'>
											{intl.messages['edition_menu.form.field.number_limit.detail']}
										</Typography>
									</Box>
									<Box>
										<TextField variant='standard' placeholder={intl.messages['common.value']} type='number' value={settings.digit_number} onChange={({
                  target: {
                    value
                  }
                }) => handleChangeProperty('digit_number', value)} />
									</Box>
								</Box>
							</Collapse>
						</>;
      case 'date':
        return <>
							<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' pr={2}>
								<Box pl={2}>
									<Typography variant='h2' component='label'>
										<FormattedMessage id='edition_menu.form.field_settings.activeDateRange' />
									</Typography>
									<Typography variant='body1'>
										<FormattedMessage id='edition_menu.form.field_settings.activeMinMax.message' />
									</Typography>
								</Box>
								<IOSSwitch checked={settings.activeDateRange} onChange={({
              target: {
                checked
              }
            }) => handleChangeProperty('activeDateRange', checked)} color='primary' />
							</Box>

							<Collapse in={settings?.activeDateRange}>
								<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mt={2} pr={2}>
									<Box pl={2}>
										<Typography variant='h2'>
											{intl.messages['edition_menu.form.field_description.after']}
										</Typography>
									</Box>
									<Box>
										<CustomDatePicker value={settings.minDate} onChange={date => handleChangeProperty('minDate', date)} format={settings.format} />
									</Box>
								</Box>

								<Box width='100%' display='flex' justifyContent='space-between' alignItems='center' mb={2} pr={2}>
									<Box pl={2}>
										<Typography variant='h2'>
											{intl.messages['edition_menu.form.field_description.before']}
										</Typography>
									</Box>
									<Box>
										<CustomDatePicker value={settings.maxDate} onChange={date => handleChangeProperty('maxDate', date)} format={settings.format} />
									</Box>
								</Box>
							</Collapse>
						</>;
      case 'select':
        return <div />;
    }
  }
  return <ModalWrapper title={generateModalTitle(field_type, intl)} ref={configRef} size='sm' hasCloseButton={false} disableBackdropClick disableEscapeKeyDown>
				<Box overflow='auto' p={1}>
					{renderSettings(field_type)}
				</Box>

				<DialogActions>
					<Button variant='text' color='primary' onClick={() => close()}>
						<FormattedMessage id='button.cancel' />
					</Button>

					<Button variant='outlined' color='secondary' disabled={isConfirmDisabled(settings, field_type)} onClick={() => onConfirm()}>
						<FormattedMessage id='common.theming.confirm_button' />
					</Button>
				</DialogActions>
			</ModalWrapper>;
});
FieldAdvancedSettingsModal.propTypes = {
  fieldSettings: PropTypes.object,
  field_type: PropTypes.string,
  handleChangeFieldSettings: PropTypes.func
};
FieldAdvancedSettingsModal.displayName = 'FieldAdvancedSettingsModal';
export default FieldAdvancedSettingsModal;

// Components Functions
function DefaultDomains({
  defaultDomains = [],
  handleChangeProperty
}) {
  const intl = useIntl();
  function addNewDomain() {
    const newDomains = _.cloneDeep(defaultDomains);
    newDomains.push('');
    handleChangeProperty('defaultDomains', newDomains);
  }
  function removeDomain(index) {
    const newDomains = _.cloneDeep(defaultDomains);
    newDomains.splice(index, 1);
    handleChangeProperty('defaultDomains', newDomains);
  }
  function handleChangeDomain(value, index) {
    const newDomains = _.cloneDeep(defaultDomains);
    newDomains[index] = value;
    handleChangeProperty('defaultDomains', newDomains);
  }
  return <Box>
			{!_.isEmpty(defaultDomains) && _.map(defaultDomains, (domain, index) => <Box key={index} width='100%' display='grid' gridTemplateColumns='auto 1fr 50px' gap='8px' mb={2} pr={2}>
						<Box pl={2} pt={1}>
							<Typography variant='h2'>
								{intl.messages['edition_menu.form.field_settings.defaultDomain']}
							</Typography>
						</Box>
						<Box width='100%'>
							<TextField variant='standard' fullWidth placeholder={intl.messages['edition_menu.form.field_settings.defaultDomainPlaceholder']} value={domain} onChange={({
          target: {
            value
          }
        }) => handleChangeDomain(value, index)} error={_.isEmpty(domain) || !isValidDomain(domain)} />
							<Box display='flex' justifyContent='space-between' px={0.5}>
								<Typography variant='body2' color='textSecondary'>
									<em>
										{intl.messages['edition_menu.form.field_settings.defaultDomainPlaceholder']}
									</em>
								</Typography>

								{_.isEmpty(domain) ? <Typography variant='body2' color='error'>
										{intl.messages['edition_menu.form.field_settings.defaultDomainEmpty']}
									</Typography> : !isValidDomain(domain) && <Typography variant='body2' color='error'>
											{intl.messages['edition_menu.form.field_settings.defaultDomainInvalid']}
										</Typography>}
							</Box>
						</Box>
						<IconButton size='small' onClick={() => removeDomain(index)}>
							<Delete />
						</IconButton>
					</Box>)}
			<Button variant='contained' color='secondary' size='large' onClick={() => addNewDomain()}>
				{intl.messages['edition_menu.form.field.default_domain.add']}
			</Button>
		</Box>;
}

// Helper functions

function isValidDomain(value) {
  if (isEmail(value)) return false;
  return isEmail(`example${value}`);
}
function isConfirmDisabled(settings, type) {
  if (type === 'email' && settings?.hasDefaultDomain) {
    if (_.isEmpty(settings?.defaultDomains)) return true;
    if (_.some(settings?.defaultDomains, domain => _.isEmpty(domain) || !isValidDomain(domain))) return true;
  }
  if (type === 'date' && settings?.activeDateRange) {
    if (!_.isDate(settings?.minDate) || !_.isDate(settings?.maxDate)) {
      if (dayjs.isDayjs(settings?.minDate) && dayjs.isDayjs(settings?.maxDate)) {
        return false;
      }
      return true;
    }
  }
  return false;
}
function generateModalTitle(field_type, intl) {
  switch (field_type) {
    case 'email':
      return intl.messages['edition_menu.form.modal_title.email'];
    case 'number':
      return intl.messages['edition_menu.form.modal_title.number'];
    case 'date':
      return intl.messages['edition_menu.form.modal_title.email'];
    default:
      return intl.messages['edition_menu.form.modal_title.email'];
  }
}