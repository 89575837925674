import axios from 'axios'
import { API_URL } from 'lib/constants'
import * as localStorageSvc from 'services/LocalStorageSvc'

const DomainSvc = {
	getAll,
	create,
	del,
	update,
	isAvailable,
}
export default DomainSvc

interface NotFoundPageProps {
	[key: string]: any
	title: string
	subHeader: string
	accentColor: string
	ctaText: string
	ctaUrl: string
}

export interface DomainDocument {
	_id: string
	organizationId: string
	ownerId: string
	ownerType: 'subscription' | 'license'
	fqdn: string
	isVerified: boolean
	lastVerifiedAt?: Date
	notFoundPage: Partial<NotFoundPageProps>
	accessibleProjects?: string[]
}

type GetAllProps = {
	organizationId: DomainDocument['organizationId']
	projectId: string
}
async function getAll({ organizationId, projectId }: GetAllProps): Promise<DomainDocument[]> {
	const { data } = await axios({
		method: 'get',
		url: `${API_URL}/domains`,
		params: {
			organization_id: organizationId,
			project: projectId,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
	return data?.domains
}

async function create({
	fqdn,
	isVerified,
	organizationId,
	accessibleProjects,
}: Pick<DomainDocument, 'fqdn' | 'isVerified' | 'organizationId' | 'accessibleProjects'>): Promise<DomainDocument> {
	const { data } = await axios({
		method: 'post',
		url: `${API_URL}/domains`,
		data: {
			organization_id: organizationId,
			fqdn,
			isVerified,
			accessibleProjects,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
	return data
}

type UpdateProps = {
	_id: DomainDocument['_id']
	organizationId: DomainDocument['organizationId']
	isVerified?: DomainDocument['isVerified']
	accessibleProjects?: DomainDocument['accessibleProjects']
	notFoundPage?: DomainDocument['notFoundPage']
}
async function update({
	_id,
	organizationId,
	isVerified,
	accessibleProjects,
	notFoundPage,
}: UpdateProps): Promise<boolean> {
	const { data } = await axios({
		method: 'patch',
		url: `${API_URL}/domains/${_id}`,
		data: {
			organization_id: organizationId,
			isVerified,
			accessibleProjects,
			notFoundPage,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
	return data?.domain
}

async function del({ _id, organizationId }: Pick<DomainDocument, '_id' | 'organizationId'>): Promise<boolean> {
	const { data } = await axios({
		method: 'delete',
		url: `${API_URL}/domains/${_id}`,
		params: {
			organization_id: organizationId,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
	return data?.success
}

async function isAvailable(domain: DomainDocument['fqdn']): Promise<boolean> {
	const { data } = await axios({
		method: 'get',
		url: `${API_URL}/domains/validate`,
		params: {
			domain,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})
	return data?.success
}
