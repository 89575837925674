import { css } from '@emotion/react';
import { Button, Rating, TextField } from '@mui/material';
import styled from '@styled';
import { interfaceElementSelectedStyle } from 'components/styled/Editor';
export const BaseInteraction = styled.div`
	pointer-events: auto;
	cursor: inherit;
	border-radius: 4px;
	width: auto;
	max-height: 100%;
	margin: 0;
	padding: ${({
  theme
}) => theme.spacing(0, 2.5)};
	overflow: ${({
  $overflow
}) => $overflow ? 'hidden' : 'auto'};

	${({
  theme,
  $isSelected
}) => $isSelected && interfaceElementSelectedStyle({
  theme
})};
`;
export const GameInteractionContainer = styled(BaseInteraction)`
	padding: 0;
`;
export const RatingInteractionContainer = styled(BaseInteraction)`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${({
  theme
}) => theme.palette.background.paper};
	border-radius: ${({
  theme
}) => theme.shape.borderRadius}px;
	width: 100%;
	min-height: 70px;
	padding: ${({
  theme
}) => theme.spacing(2)};

	& > .MuiRating-root {
		margin: ${({
  theme
}) => theme.spacing(1)};
	}
`;
export const MediaAnswerInteractionContainer = styled(BaseInteraction)`
	padding: ${({
  theme
}) => theme.spacing(1, 0)};
	background-color: rgba(255, 255, 255, 0.6);
	text-align: center;
`;

/* ---- RATING ---- */

export const RatingContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	width: 100%;
	padding: ${({
  theme
}) => theme.spacing(1, 0)};
	div {
		transition: transform 150ms ease-in-out;
		width: 70px;
		height: 70px;
		border-radius: 50%;
		background-size: cover;
		cursor: pointer;
	}
	div:hover {
		transform: scale(1.1);
	}
`;
export const RedMuiRating = styled(Rating)`
	color: red;
`;

/* ---- MEDIA ANSWER ---- */

const lonelyButton = css`
	height: 70px;
	width: 260px;

	display: flex;
	flex-direction: row;

	svg {
		margin-right: 8px;
	}
`;
export const MediaAnswerButton = styled(Button)`
	height: 80px;
	min-width: 64px;
	max-width: 260px;
	overflow: hidden;
	font-size: 14px;
	text-align: center;
	padding: 16px 16px;

	display: flex;
	flex-direction: column;

	${({
  $isAlone
}) => $isAlone && lonelyButton}
`;

/* ---- GAME ---- */

export const SnackeetWheel = styled.div`
	display: flex;
	flex-direction: column;
	.sc-gsTCUz {
		max-width: 320px;
		max-height: 320px;
		visibility: visible !important;
	}
`;
export const SpinWheelButton = styled(Button)`
	&& {
		color: ${({
  $textColor
}) => $textColor};
		font-size: ${({
  $fontSize
}) => $fontSize}px;
		background-color: ${({
  $backgroundColor
}) => $backgroundColor};
		border-width: ${({
  $borderWidth
}) => $borderWidth};
		border-color: ${({
  $borderColor
}) => $borderColor};
		border-radius: ${({
  $borderRadius
}) => $borderRadius};
		border-style: solid;
		width: fit-content;
		margin: auto;
		padding: 10px 15px;
		pointer-events: none;
	}
`;
export const WheelModal = styled.div`
	display: flex;
	flex-direction: column;
	width: 330px;
	height: 100%;
	padding: 15px;
	border-radius: 10px;
	color: ${({
  $color
}) => $color};
	background-color: ${({
  $backgroundColor
}) => $backgroundColor};
	box-shadow: ${({
  theme
}) => theme.shadows[2]};

	h1 {
		text-align: center;
		font-size: 28px;
		margin: 10px 0;
	}

	h3 {
		font-size: 16px;
	}

	.win-img {
		height: 150px;
	}

	.lose-img {
		height: 130px;
		margin-bottom: 20px;
	}

	.modal-description {
		overflow: auto;
		max-height: 200px;
		word-break: break-word;
		white-space: break-spaces;
		padding: 10px 0;
		text-align: center;
	}
`;
export const FormInputModal = styled(TextField)`
	width: 100%;
	font-size: 18px;
	border: 1px solid;
	border-radius: 5px;
	padding: 10px;
	margin: 10px 0;
	/* background: ${({
  theme
}) => theme.palette.divider}; */
	box-shadow: ${({
  theme
}) => theme.shadows[1]};
`;

/* ---- SHARE (Deprecated) ---- */

export const ShareBlockContainer = styled(BaseInteraction)`
	width: auto;
	margin: ${({
  theme
}) => theme.spacing(1, 'auto')};
	padding: ${({
  theme
}) => theme.spacing(1, 0)};
	text-align: center;

	& > div:last-of-type {
		margin-bottom: 0;
	}

	.share-icons {
		display: flex;
		justify-content: center;

		svg {
			margin: ${({
  theme
}) => theme.spacing(0, 1)};
		}
	}
`;