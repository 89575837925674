import { useSelector } from 'react-redux'
import { getCurrentProjectId } from 'redux/project/selectors'
import FilesSvc from 'services/FilesSvc'

export default function useFiles() {
	const project = useSelector(getCurrentProjectId)

	const getFile = async (file_id) => {
		return FilesSvc.get({ file_id, project })
	}

	const getFaviconFiles = async () => {
		return FilesSvc.getFavicons({ project })
	}

	const getLoaderFiles = async () => {
		return FilesSvc.getLoaders({ project })
	}

	const updateFile = async (file, file_id) => {
		return FilesSvc.update({ file, file_id, project })
	}

	const searchFiles = async (query) => {
		return FilesSvc.searchByQuery({ query, project })
	}

	const partialUpdate = async (partialData, file_id) => {
		return FilesSvc.partialUpdate({ partialData, file_id, project })
	}

	const deleteFile = async (file_id) => {
		return FilesSvc.del({ file_id, project })
	}

	const transcriptionVideo = async (data) => {
		return FilesSvc.transcription({ data, project })
	}

	const generateVTT = async (data) => {
		return FilesSvc.jsonToVtt({ data, project })
	}

	return {
		getFile,
		getFaviconFiles,
		getLoaderFiles,
		updateFile,
		searchFiles,
		partialUpdate,
		deleteFile,
		transcriptionVideo,
		generateVTT,
	}
}
