import { Cancel, CheckCircle } from '@mui/icons-material';
import { Alert, Box, Button, Checkbox, CircularProgress, DialogActions, FormControlLabel, FormGroup, InputAdornment, Link, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import SubmitButton from 'components/organisms/buttons/SubmitButton';
import { ProjectsAccessWrapper } from 'components/styled/Modals';
import { sleep } from 'lib/utils';
import { isFQDN } from 'lib/utils/domainUtils';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCustomerRights } from 'redux/customer/selectors';
import { getCurrentOrganizationId } from 'redux/organization/selectors';
import { getCurrentProjectId } from 'redux/project/selectors';
import DomainsSvc from 'services/DomainsSvc';
import OrganizationsSvc from 'services/OrganizationsSvc';
import { verifyCNAME } from 'services/UtilsSvc';
import { useDebouncedCallback } from 'use-debounce';
import { useImmer } from 'use-immer';
import { ModalWrapper } from '../ModalWrapper';
import { Loader, RedirectionStatusIcon } from './DomainsModals.style';
const domainCreationSteps = ['specify_domain', 'verify_domain', 'select_projects'];
const ALLOWED_DOMAIN_CHARACTERS = /^[a-z0-9-_.]$/i;
function getInitialState(projectId) {
  return {
    fqdn: '',
    accessibleProjects: [projectId],
    isValid: false,
    isAvailable: false,
    isCheckingAvailability: false,
    isVerified: null,
    isVerifying: false
  };
}
function getRedirectionStatus(isVerified) {
  if (_.isNil(isVerified)) return 'unset';
  if (isVerified) return 'valid';
  return 'invalid';
}
export default function CreateDomainModal({
  children,
  onCreate
}) {
  const ref = useRef();
  const intl = useIntl();
  const queryClient = useQueryClient();
  const {
    enqueueSnackbar
  } = useSnackbar();
  const organizationId = useSelector(getCurrentOrganizationId);
  const projectId = useSelector(getCurrentProjectId);
  const currentCustomerRights = useSelector(getCustomerRights);
  const [activeStep, setActiveStep] = useState(0);
  const [state, setState] = useImmer(() => getInitialState(projectId));
  const {
    data: projects = []
  } = useQuery({
    queryKey: ['projects', {
      organizationId
    }],
    queryFn: async ({
      queryKey
    }) => {
      const {
        organizationId
      } = queryKey[1];
      return OrganizationsSvc.getProjects(organizationId);
    }
  });
  const hasMultipleProjects = projects?.length > 1;
  const redirectionStatus = getRedirectionStatus(state.isVerified);
  const steps = hasMultipleProjects ? domainCreationSteps : domainCreationSteps.slice(0, 2);
  const createDomainMutation = useMutation({
    mutationFn: async ({
      fqdn,
      isVerified,
      accessibleProjects
    }) => {
      try {
        const {
          success,
          domain
        } = await DomainsSvc.create({
          organizationId,
          fqdn,
          isVerified,
          accessibleProjects
        });
        if (!success) {
          throw new Error('Domain creation request failed');
        }
        enqueueSnackbar(intl.formatMessage({
          id: 'toast.success.creation.domain'
        }), {
          variant: 'success'
        });
        queryClient.invalidateQueries({
          queryKey: ['domains']
        });
        ref.current?.close();
        onCreate(domain);
      } catch (error) {
        enqueueSnackbar(intl.formatMessage({
          id: 'toast.error.creation'
        }), {
          variant: 'error'
        });
      }
    }
  });

  // Checks if the domain is a valid FQDN and if it is not already used
  const validateFqdn = useDebouncedCallback(async domain => {
    if (!isFQDN(domain)) {
      setState(draft => {
        draft.isValid = false;
        draft.isAvailable = false;
        draft.isCheckingAvailability = false;
      });
      return;
    }
    setState(draft => {
      draft.isValid = true;
      draft.isCheckingAvailability = true;
    });
    const isAvailable = await DomainsSvc.isAvailable(state.fqdn);
    setState(draft => {
      draft.isAvailable = isAvailable;
      draft.isCheckingAvailability = false;
    });
  }, 500);

  // Checks if the CNAME record is valid
  async function verifyFqdn() {
    if (!state.fqdn) {
      return;
    }
    setState(draft => {
      draft.isVerifying = true;
    });
    const isVerified = await verifyCNAME(state.fqdn);

    // Add artificial delay to show loader
    await sleep(500);
    setState(draft => {
      draft.isVerified = isVerified;
      draft.isVerifying = false;
    });
  }
  const toggleProjectAccess = useCallback(_id => () => {
    setState(draft => {
      if (!_.includes(draft.accessibleProjects, _id)) {
        draft.accessibleProjects.push(_id);
      } else {
        _.remove(draft.accessibleProjects, value => value === _id);
      }
    });
  }, [setState]);
  function handleFQDNChange({
    target: {
      value
    },
    ...evt
  }) {
    setState(draft => {
      draft.fqdn = value;
    });
    validateFqdn(value);
  }
  function onKeyDown(evt) {
    if (evt.key === 'Enter' || evt.keyCode === 13) {
      evt.preventDefault();
      if (state.isValid) {
        setActiveStep(1);
      }
    } else if (evt.key.length === 1 && !ALLOWED_DOMAIN_CHARACTERS.test(evt.key)) {
      evt.preventDefault();
    }
  }
  function renderNextButton() {
    if (activeStep === 0) {
      return <Button variant='outlined' color='primary' disabled={!state.isValid} onClick={() => setActiveStep(1)}>
					<FormattedMessage id='button.next' />
				</Button>;
    }
    if (activeStep === 1 && hasMultipleProjects) {
      return <Button variant='outlined' color='primary' disabled={!state.isValid || !state.isVerified} onClick={() => setActiveStep(2)}>
					<FormattedMessage id='button.next' />
				</Button>;
    }
    return <SubmitButton disabled={!state.isValid || !state.isVerified || _.isEmpty(state.accessibleProjects) || createDomainMutation.isPending} variant='outlined' color='secondary' onClick={() => createDomainMutation.mutate(state)} loading={createDomainMutation.isPending}>
				<FormattedMessage id='button.confirm' />
			</SubmitButton>;
  }
  return <ModalWrapper ref={ref} title={<FormattedMessage id='modals.create_custom_domain.header' />} trigger={children} size='sm' onClose={() => {
    setActiveStep(0);
    setState(() => getInitialState(projectId));
  }}>
			<Box sx={{
      overflow: 'auto',
      p: 3,
      display: 'flex',
      flexDirection: 'column',
      gap: 3
    }}>
				{hasMultipleProjects && <Stepper activeStep={activeStep} alternativeLabel sx={{
        px: 5,
        '.MuiStepIcon-root.Mui-completed': {
          color: 'green'
        }
      }}>
						{steps.map((label, index) => <Step key={label} completed={activeStep > index}>
								<StepLabel>
									<FormattedMessage id={`modals.create_custom_domain.stepper.label.${label}`} />
								</StepLabel>
							</Step>)}
					</Stepper>}

				{activeStep === 0 && <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        px: 3
      }}>
						<Box fontSize={16} fontWeight='bold'>
							<FormattedMessage id='modals.create_custom_domain.step.1.title' />
						</Box>
						<Box mb={1} fontSize={14}>
							<FormattedMessage id='modals.create_custom_domain.step.1.description' />
							<i>story.customer.com</i>
						</Box>
						<TextField fullWidth error={!state.isValid} variant='outlined' placeholder='story.customer.com' InputProps={{
          startAdornment: <InputAdornment position='start'>{`https://`}</InputAdornment>,
          endAdornment: <Box ml={1}>
										{state.isCheckingAvailability ? <CircularProgress size={20} /> : !state.isValid || !state.isAvailable ? <Cancel color='error' /> : <CheckCircle color='primary' />}
									</Box>
        }} value={state.fqdn} onChange={handleFQDNChange} onKeyDown={onKeyDown} />
						{state.fqdn !== '' && <>
								{!state.isValid ? <Alert severity='warning'>
										<FormattedMessage id='modals.create_custom_domain.invalid' />
									</Alert> : !state.isAvailable ? <Alert severity='warning'>
										<FormattedMessage id='modals.create_custom_domain.unavailable' />
									</Alert> : null}
							</>}
					</Box>}

				{activeStep === 1 && <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        px: 3,
        fontSize: 14
      }}>
						<Box fontSize={16} fontWeight='bold' mb={1}>
							<FormattedMessage id='modals.create_custom_domain.step.2.title' />
						</Box>
						<Link href='https://help.snackeet.com/en/articles/6130690-how-to-setup-a-custom-domain-cname' target='_blank' underline='hover'>
							💡 &nbsp; <FormattedMessage id='modals.create_custom_domain.article_link' />
						</Link>
						<Box>
							a) <FormattedMessage id='modals.create_custom_domain.step.2.description.a' />
						</Box>
						<Box>
							b) <FormattedMessage id='modals.create_custom_domain.step.2.description.b' />
							<Box ml={0.5} mr={2} fontWeight='bold' component='span'>
								custom.snkt.io
							</Box>
						</Box>
						<Box sx={{
          display: 'flex',
          alignItems: 'center',
          mt: 3
        }}>
							<Box>
								<FormattedMessage id='modals.create_custom_domain.step.2.redirection_status' />
							</Box>
							<Box ml={2} mr={3} display='flex' alignItems='center' borderBottom={2} borderColor='success'>
								<RedirectionStatus status={redirectionStatus} />
							</Box>
							<Button variant='contained' size='small' color='primary' onClick={verifyFqdn}>
								<FormattedMessage id='button.verify' />
								{state.isVerifying && <Loader color='secondary' />}
							</Button>
						</Box>
						{redirectionStatus === 'unset' && <Alert severity='warning'>
								<FormattedMessage id='modals.create_custom_domain.alert.redirection_status' />
							</Alert>}
					</Box>}

				{activeStep === 2 && hasMultipleProjects && <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        px: 3
      }}>
						{currentCustomerRights.isAdmin ? <>
								<Typography variant='h3'>
									<FormattedMessage id='modals.create_custom_domain.access_admin' />
								</Typography>
								<ProjectsAccessWrapper component='fieldset'>
									<FormGroup>
										{_.map(projects?.slice(0, Math.ceil(projects.length / 2)), project => <FormControlLabel key={project._id} control={<Checkbox checked={_.includes(state.accessibleProjects, project._id)} name={project._id} onChange={toggleProjectAccess(project._id)} />} label={project.name} />)}
									</FormGroup>
									<FormGroup>
										{_.map(projects?.slice(Math.ceil(projects.length / 2)), project => <FormControlLabel key={project._id} control={<Checkbox checked={_.includes(state.accessibleProjects, project._id)} name={project._id} onChange={toggleProjectAccess(project._id)} />} label={project.name} />)}
									</FormGroup>
								</ProjectsAccessWrapper>
							</> : <>
								<Typography variant='h3'>
									<FormattedMessage id='modals.create_custom_domain.access_agent' />
								</Typography>
								<Alert severity='warning'>
									<Typography variant='body2'>
										<FormattedMessage id='modals.create_custom_domain.warning_agent' />
									</Typography>
								</Alert>
							</>}
					</Box>}
			</Box>

			<DialogActions>
				{activeStep >= 1 && <Button variant='text' onClick={() => setActiveStep(prevStep => prevStep - 1)}>
						<FormattedMessage id='button.previous' />
					</Button>}

				{renderNextButton()}
			</DialogActions>
		</ModalWrapper>;
}
function RedirectionStatus({
  status
}) {
  return <>
			<FormattedMessage id={`common.${status}`} />
			<Box mx={0.5} />
			<RedirectionStatusIcon status={status} />
		</>;
}