import axios from 'axios'
import { API_URL } from 'lib/constants'
import * as localStorageSvc from 'services/LocalStorageSvc'

const BrandsSvc = {
	get,
	getAll,
	create,
	update,
	del,
}

export default BrandsSvc

async function getAll({ organization_id, project }) {
	try {
		const { data } = await axios.get(`${API_URL}/brands/`, {
			params: {
				organization_id,
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		if (!data.success) {
			throw new Error('Could not retrieve brands')
		}
		return data.brands
	} catch (error) {
		return []
	}
}

async function get({ organization_id, project, brand_id }) {
	try {
		const { data } = await axios.get(`${API_URL}/brands/${brand_id}`, {
			params: {
				organization_id,
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})
		if (!data.success) {
			throw new Error('Could not retrieve brands')
		}
		return data.brand
	} catch (error) {
		return []
	}
}

async function create({ organization_id, project, brand }) {
	try {
		const { data } = await axios.post(`${API_URL}/brands/`, brand, {
			params: {
				organization_id,
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})

		return data
	} catch (error) {
		return {
			success: false,
		}
	}
}

async function update({ organization_id, project, brand }) {
	try {
		const { data } = await axios.put(
			`${API_URL}/brands/${brand._id}`,
			{ brand },
			{
				params: {
					organization_id,
					project,
				},
				headers: {
					authorization: `JWT ${localStorageSvc.getLocalToken()}`,
				},
			}
		)

		return {
			success: data.success,
		}
	} catch (err) {
		return {
			success: false,
		}
	}
}

async function del({ organization_id, project, brand_id }) {
	try {
		const { data } = await axios.delete(`${API_URL}/brands/${brand_id}`, {
			params: {
				organization_id,
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		})

		return data
	} catch (error) {
		return {
			success: false,
		}
	}
}
