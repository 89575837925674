import axios from 'axios'
import { API_URL } from 'lib/constants'

const ImageSvc = {
	getPreview,
	getImage,
}

export default ImageSvc

async function getPreview() {
	const { data } = await axios.get(`${API_URL}/image/preview`)
	return data.results
}

async function getImage({ search, lang }) {
	const { data } = await axios.get(`${API_URL}/image/search`, {
		params: {
			search,
			lang,
		},
	})

	return data.results
}
