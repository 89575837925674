import { css } from '@emotion/react';
import Button from '@mui/material/Button';
import styled from '@styled';
const roundedStyle = ({
  $isRounded
}) => $isRounded && css`
		&&.MuiButton-root {
			border-radius: 27px;
			min-height: 45px;
			padding: 4px 24px;
		}
	`;
const loadingStyle = ({
  $isLoading
}) => $isLoading && css`
		cursor: wait;
	`;
const SnackeetButton = styled(Button)`
	${roundedStyle}
	${loadingStyle}
`;
export default SnackeetButton;