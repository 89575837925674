import { Box, Button, DialogActions, Input, Link } from '@mui/material';
import { useStoryDispatch, useStoryState } from 'lib/editorStore';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useImmer } from 'use-immer';
import { ErrorMessagesContainer } from '../../../containers/Editor/StoryEditor/Properties/Properties.style';
import { ModalWrapper } from '..';
const ERROR_FIELDS = ['email', 'phone'];
const ERROR_NAMES = ['empty', 'invalid'];
export default function FormErrorMessagesModal({
  trigger
}) {
  const dispatch = useStoryDispatch();
  const storyErrorMessages = useStoryState(s => s.draftStory.messages.error_messages);
  const [errorFields, updateErrorFields] = useImmer(storyErrorMessages);
  const modalRef = useRef();

  // handle Undo/Redo & reset
  useEffect(() => {
    if (!_.isEqual(storyErrorMessages, errorFields)) {
      updateErrorFields(draft => {
        return storyErrorMessages;
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [storyErrorMessages]);
  function dispatchReset(path) {
    dispatch({
      type: 'RESET_DEFAULT_ERROR_MESSAGES',
      data: path
    });
  }
  function handleFieldChange({
    fieldName,
    errorName,
    value
  }) {
    updateErrorFields(draft => {
      draft[fieldName][errorName] = value;
    });
  }
  function onUpdate() {
    dispatch({
      type: 'PARTIAL_EDIT_MESSAGES',
      data: {
        path: 'error_messages',
        value: errorFields
      }
    });
    modalRef.current?.close();
  }
  function onCancel() {
    updateErrorFields(draft => {
      return storyErrorMessages;
    });
    modalRef.current?.close();
  }
  return <ModalWrapper ref={modalRef} trigger={trigger} size='sm' title={<FormattedMessage id='modals.story_errors.title' />}>
			<ErrorMessagesContainer>
				{_.map(ERROR_FIELDS, fieldName => <Box key={fieldName} display='flex' flexDirection='column'>
						<div className='error-header'>
							<FormattedMessage id={`languageview.${fieldName}`} />
							<Link component='button' onClick={() => dispatchReset(fieldName)} underline='always' variant='body1' sx={{
            color: 'white',
            textDecorationColor: 'inherit'
          }}>
								<FormattedMessage id='button.reset' />
							</Link>
						</div>
						<div className='error-messages'>
							{_.map(ERROR_NAMES, errorName => <div key={errorName}>
									<b>
										<FormattedMessage id={`languageview.${errorName}`} />:
									</b>
									<Input fullWidth onChange={({
              target
            }) => handleFieldChange({
              fieldName,
              value: target.value,
              errorName
            })} value={errorFields[fieldName][errorName]} />
								</div>)}
						</div>
					</Box>)}
			</ErrorMessagesContainer>

			<DialogActions>
				<Button variant='text' onClick={onCancel}>
					<FormattedMessage id='button.cancel' />
				</Button>
				<Button variant='outlined' color='secondary' onClick={onUpdate}>
					<FormattedMessage id='button.confirm' />
				</Button>
			</DialogActions>
		</ModalWrapper>;
}
FormErrorMessagesModal.propTypes = {
  trigger: PropTypes.node,
  form: PropTypes.object
};