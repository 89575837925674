import axios from 'axios'
import { API_URL } from 'lib/constants'

import * as localStorageSvc from './LocalStorageSvc'

const UploadSvc = {
	upload,
	uploadFromUrl,
	uploadFonts,
}

export default UploadSvc

async function upload({ project, url, formData, params = {} }) {
	if (!project) {
		throw new Error('Missing "project" parameter for upload')
	}

	if (!url) {
		throw new Error('Missing "url" parameter for upload')
	}

	const token = localStorageSvc.getLocalToken()

	const { data } = await axios.post(`${API_URL}${url}`, formData, {
		params: {
			...params,
			project,
		},
		headers: {
			'Content-Type': 'multipart/form-data',
			authorization: `JWT ${token}`,
		},
		onUploadProgress(progressEvent) {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
			// console.log({ progressEvent, percentCompleted })
		},
	})
	return data
}

async function uploadFromUrl({ project, urlAssetIdentifiers = [] }) {
	if (!project) {
		throw new Error('Missing "project" parameter for upload')
	}

	const token = localStorageSvc.getLocalToken()

	const { data } = await axios.post(
		`${API_URL}/upload/url`,
		{
			urlAssetIdentifiers,
		},
		{
			params: {
				project,
			},
			headers: {
				authorization: `JWT ${token}`,
			},
		}
	)
	return data
}

async function uploadFonts({ files, organization_id }) {
	const formData = new FormData()
	for (const file of files) {
		formData.append('blob', file, file.name)
	}

	const { data } = await axios.post(`${API_URL}/upload/fonts`, formData, {
		params: {
			organization_id,
		},
		headers: {
			'Content-Type': 'multipart/form-data',
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
		onUploadProgress(progressEvent) {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
			console.log({ progressEvent, percentCompleted })
		},
	})
	return data
}
