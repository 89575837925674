import makeStyles from '@mui/styles/makeStyles';
import { extractPositionProperties } from 'lib/utils';
import PropTypes from 'prop-types';
import { memo } from 'react';
import BlockWrapper from './BlockWrapper';
const useStyles = makeStyles(theme => ({
  text: props => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    lineHeight: 'normal',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    '& p, h1, h2, h3': {
      // Important: set minimum height to paragraphs (handle line breaks)
      margin: '0px',
      minHeight: '1em',
      borderLeft: '0.1px solid transparent'
    },
    '& a': {
      color: 'inherit',
      textDecoration: 'inherit'
    }
  })
}));
function Text({
  value,
  outer_style,
  inner_style,
  animation
}) {
  const classes = useStyles();
  const {
    positionProperties,
    visualProperties
  } = extractPositionProperties(outer_style);
  const textStyle = {
    ...visualProperties,
    ...inner_style
  };
  return <BlockWrapper animation={animation} positionProperties={positionProperties}>
			<div className={classes.text} style={textStyle}>
				<div dangerouslySetInnerHTML={{
        __html: value
      }} />
			</div>
		</BlockWrapper>;
}
Text.propTypes = {
  value: PropTypes.string,
  outer_style: PropTypes.object,
  inner_style: PropTypes.object,
  animation: PropTypes.object
};
export default memo(Text);