import { SvgIcon } from '@mui/material'

export default function InstagramCommentIcon(props) {
	return (
		<SvgIcon width='24' height='24' viewBox='0 0 24 24' {...props}>
			<path
				d='M18.656 15.008C19.8711 12.9061 20.2795 10.4337 19.8048 8.05273C19.3301 5.67173 18.0048 3.54498 16.0765 2.06979C14.1482 0.594593 11.7488 -0.128137 9.32657 0.0366118C6.9043 0.201361 4.62486 1.24232 2.91408 2.96502C1.20329 4.68772 0.178172 6.97433 0.030237 9.39768C-0.117698 11.821 0.621664 14.2153 2.1102 16.1334C3.59874 18.0514 5.73463 19.3619 8.11887 19.82C10.5031 20.2782 12.9726 19.8527 15.066 18.623L20 20L18.656 15.008Z'
				fill='currentColor'
			/>
		</SvgIcon>
	)
}
