import { block as _block$ } from "million/react-server";
interface VideoThumbnailProps {
  src: string;
  onClick?: () => void;
  [key: string]: any;
}
const M$VideoThumbnail = function VideoThumbnail({
  src,
  ...delegatedProps
}: VideoThumbnailProps) {
  const youtubeVideoId = extractYouTubeId(src);
  if (youtubeVideoId) {
    // Display medium quality version of the thumbnail
    return <img src={`https://img.youtube.com/vi/${youtubeVideoId}/mqdefault.jpg`} onClick={delegatedProps.onClick} />;
  }
  return <video src={src} {...delegatedProps} />;
};
const VideoThumbnail = M$VideoThumbnail;
export default VideoThumbnail;
function extractYouTubeId(url: string): string | null {
  try {
    const urlObject = new URL(url);
    const params = new URLSearchParams(urlObject.search);
    return params.get('v');
  } catch (err) {
    return null;
  }
}