import { keyframes } from '@emotion/css';
import { css } from '@emotion/react';
import { Box, Button, Popover, ToggleButtonGroup } from '@mui/material';
import styled from '@styled';
import { transparentize } from 'polished';
import { black, snackeet_blue, snackeet_border, snackeet_dark_grey } from 'styles/colors';

// Editor - Element Properties

const SlideFromRight = keyframes`
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0);
	}
`;
export const PropertyBlock = styled(Box)`
	width: 100%;
	position: relative;

	.property-header {
		width: 100%;
		height: 25px;
		display: grid;
		grid-template-columns: 1fr auto 1fr;
		grid-gap: 16px;
		place-items: center;
		color: #8b8b8b;
		margin: ${({
  theme
}) => theme.spacing(1, 0)};

		.line-before {
			background-color: #8b8b8b;
			width: 100%;
			height: 1px;
		}
		.line-after {
			background-color: #8b8b8b;
			width: 100%;
			height: 1px;
		}
	}

	.property-line {
		width: 100%;
		display: flex;
		align-items: center;
		margin: ${({
  theme
}) => theme.spacing(0.5, 0)};
	}

	.vertical-property-line {
		margin: ${({
  theme
}) => theme.spacing(1.5, 0)};

		.vertical-property-field {
			padding: ${({
  theme
}) => theme.spacing(1)};
			background-color: #f9fafb;
			border-radius: 8px;
			border: solid #afafaf;
			font-weight: bold;
			font-size: 12px;
			border-width: 1px 1px 0px 1px;
			border-bottom-left-radius: 0px;
			border-bottom-right-radius: 0px;
		}

		.vertical-property-content {
			padding: ${({
  theme
}) => theme.spacing(1)};
			border-radius: 8px;
			background-color: white;
			border: 1px solid #afafaf;
			border-top-left-radius: 0px;
			border-top-right-radius: 0px;
		}
	}

	.setting-line {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: ${({
  theme
}) => theme.spacing(0.5, 0)};
		margin: ${({
  theme
}) => theme.spacing(1, 0)};

		.MuiTypography-root {
			/* font-weight: bold; */
			font-size: 12px;
		}

		.MuiSelect-icon {
			color: ${({
  theme
}) => theme.palette.primary.main};
		}

		.MuiSwitch-root {
			margin-left: ${({
  theme
}) => theme.spacing(1)};
		}
	}

	.property-line-footer {
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}

	& > .property-line:first-child {
		margin-top: 0;
	}
	& > .property-line:last-child {
		margin-bottom: 0;
	}

	.property-subtitle {
		border: none;
		margin: 0;

		& > .property-field {
			background: inherit;
			border: none;
		}

		& > .property-field::after {
			height: 0;
		}
	}

	.property-field {
		min-width: 40px;
		width: 70px;
		min-height: 38px;
		height: 100%;
		position: relative;
		display: flex;
		align-items: center;
		font-size: 12px;
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
	}

	.property-content {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: ${({
  theme
}) => theme.spacing(1)};

		h3 {
			text-overflow: ellipsis;
			max-width: 100px;
			overflow: hidden;
		}

		/* input {
			font-weight: bold;
		} */

		.MuiSelect-root {
			font-weight: bold;
		}
		.MuiSelect-outlined {
			padding-top: 8px;
			padding-bottom: 8px;
		}
		.MuiAutocomplete-inputRoot {
			padding-top: 0px;
			padding-bottom: 0px;
			padding-left: 0px;
			font-size: 11px;
		}

		.MuiOutlinedInput-input {
			padding: 8px;
		}

		.MuiInputBase-multiline {
			padding: 0px;
		}
	}

	.effect-list {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-auto-flow: row;
		grid-auto-rows: 80px;
		gap: 6px;
		height: 260px;
		overflow-y: auto;
		padding: ${({
  theme
}) => theme.spacing(0, 1)};
		overflow: hidden;
	}

	.colors-preset-list {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(7, 1fr);
		grid-auto-flow: row;
		grid-auto-rows: 40px;
		gap: 8px;
		padding: ${({
  theme
}) => theme.spacing(0, 0.5)};
		margin: ${({
  theme
}) => theme.spacing(1, 0)};
	}

	.button-preset-list {
		width: 100%;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		padding: ${({
  theme
}) => theme.spacing(0, 0.5)};
		margin: ${({
  theme
}) => theme.spacing(0.5, 0)};

		.button-shape {
			cursor: pointer;
			transition: ease-in-out 200ms;

			&:hover {
				transform: scale(1.05);
			}
		}
	}

	.gradient-edition {
		width: 100%;
		margin: ${({
  theme
}) => theme.spacing(1, 0, 2.5)};

		.gradient-preview {
			.MuiButton-root {
				min-width: 50px;
			}
			.MuiInputBase-input {
				padding: 4px;
			}
		}

		.gradient-colors {
			.MuiInputBase-input {
				padding: 4px;
			}
		}
	}

	.fullHeight {
		height: 100%;
		min-height: unset;
	}

	.MuiAccordion-root {
		box-shadow: none;
	}

	.MuiAccordionDetails-root {
		padding: 0px 10px 8px;
	}
`;
PropertyBlock.defaultProps = {
  py: 0.5
};
const disabledButtonStyle = ({
  $isDisabled,
  theme
}) => $isDisabled && css`
		background-color: ${theme.palette.background.primary};
		cursor: not-allowed;
		color: #afafaf;

		.status-dot {
			background-color: #ccc !important;
		}

		&:hover {
			background-color: ${theme.palette.background.primary};
		}
	`;
export const MediaButton = styled.div`
	border: none;
	background-color: inherit;
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: ${({
  theme
}) => theme.spacing(0.5, 1)};
	cursor: pointer;

	p {
		text-align: center;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
	}

	.status-dot {
		position: absolute;
		top: 4px;
		right: 4px;
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background-color: #ccc;
	}
	.status-dot.active {
		background-color: ${({
  theme
}) => theme.palette.success.main};
	}

	&:hover {
		background-color: rgba(72, 71, 71, 0.04);
	}

	${disabledButtonStyle}
`;
export const BackgroundMediaButton = styled(MediaButton)`
	max-width: 180px;
	flex-direction: row;
	padding: ${({
  theme
}) => theme.spacing(1.5, 1)};
	border: 1px solid rgba(0, 0, 0, 0.23);
	background-color: ${({
  theme
}) => theme.palette.background.paper};
	border-radius: 4px;
`;
const horizontalButtons = css`
	grid-auto-flow: column;
	width: 100%;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
`;
const verticalButtons = css`
	grid-auto-flow: row;
	height: 100%;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
`;
export const MediaButtonsContainer = styled(Box)`
	display: grid;
	grid-auto-columns: minmax(0, 1fr);
	border: 1px solid rgba(0, 0, 0, 0.23);
	background-color: ${({
  theme
}) => theme.palette.background.paper};
	overflow: hidden;
	${({
  direction = 'horizontal'
}) => direction === 'vertical' ? verticalButtons : horizontalButtons}

	${MediaButton} {
		&:not(:last-of-type) {
			${({
  direction = 'horizontal'
}) => direction === 'vertical' ? 'border-bottom: 1px solid rgba(0, 0, 0, 0.23);' : 'border-right: 1px solid rgba(0, 0, 0, 0.23);'}
		}
	}
`;

// Apply z-index to ensure cards don't disappear while dragging
export const DragableProperties = styled.div`
	z-index: 1;
	width: 100%;
	padding: ${({
  theme
}) => theme.spacing(0, 0, 1)};

	.MuiListItem-root {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0;
		width: 100%;
		margin: ${({
  theme
}) => theme.spacing(1.5, 0, 3)};
		color: ${({
  theme
}) => theme.palette.text.primary};
		font-size: 14px;

		.drag-handle {
			height: 24px;
		}

		.drag-platform {
			height: 90px;
			margin-right: ${({
  theme
}) => theme.spacing(1)};
			display: grid;
			place-items: center;
		}
	}

	.draggable {
		top: auto !important;
		left: auto !important;
	}

	.setting-line {
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: ${({
  theme
}) => theme.spacing(0.5, 0)};
		padding: ${({
  theme
}) => theme.spacing(0, 1)};

		.MuiSelect-icon {
			color: ${({
  theme
}) => theme.palette.primary.main};
		}
	}

	.redirect {
		display: flex;
		justify-content: center;
		align-items: center;
		svg {
			height: 20px;
			transform: rotate(180deg);
		}

		.noError {
			color: inherit;
		}

		.isError {
			color: ${({
  theme
}) => theme.palette.error.main};
			font-weight: bold;
		}
	}

	.list-container {
		.MuiOutlinedInput-root {
			input {
				padding: ${({
  theme
}) => theme.spacing(1, 1.5)};
			}
		}
		.MuiOutlinedInput-input {
			padding: ${({
  theme
}) => theme.spacing(1.2, 1.5)};
		}
	}
`;
export const ElementPropertiesContainer = styled.div`
	height: 100%;
	overflow: auto;

	& > section {
		display: flex;
		position: relative;
		flex-direction: column;
		gap: ${({
  theme
}) => theme.spacing(1)};
		padding-top: ${({
  theme
}) => theme.spacing(1)};
		width: 100%;
		height: 100%;
		overflow: auto;
		background-color: ${({
  theme
}) => theme.palette.background.primary};
		border: 1px solid ${snackeet_border};
		border-top: none;
		animation: ${SlideFromRight} 0.3s ease-out forwards;

		& > div:last-child {
			padding-bottom: 80px;
		}

		.MuiAccordionDetails-root {
			${PropertyBlock} {
				padding-top: 0;
			}
		}
	}
`;
export const PropertiesScrollSection = styled(Box)`
	overflow: auto;
	scrollbar-gutter: stable;
`;
PropertiesScrollSection.defaultProps = {
  px: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 1
};
export const ErrorMessagesContainer = styled.div`
	display: flex;
	justify-content: space-around;
	padding: ${({
  theme
}) => theme.spacing(2)};
	padding-top: 0;

	.error-header {
		font-size: 14px;
		font-weight: bold;
		border-radius: 4px;
		padding: ${({
  theme
}) => theme.spacing(1, 2)};
		display: flex;
		justify-content: space-between;
		background-color: ${({
  theme
}) => theme.palette.primary.main};
		color: #fff;
		a {
			color: #fff;
			cursor: pointer;
		}
	}

	.error-messages {
		display: flex;
		flex-direction: column;
		width: 250px;

		& > div {
			margin: ${({
  theme
}) => theme.spacing(1)};
		}
	}
`;
export const SelectElementContainer = styled.div`
	padding: ${({
  theme
}) => theme.spacing('15%', 1)};

	p {
		color: #c2c2c2;
		font-size: 18px;
		font-weight: 400;
		text-align: center;
		line-height: 23px;
		max-width: 200px;
		margin: auto;
	}
`;
export const WheelPrize = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	background: white;
	border-radius: 5px;
	border: 1px solid #ccc;

	.wheel-fields {
		display: flex;
		width: 100%;
		flex-direction: column;
		margin-right: 5px;
		background: white;
		border-radius: 5px 0 0 5px;
		border-right: 1px solid ${({
  theme
}) => theme.palette.divider};

		.wheel-field {
			display: flex;
			flex-direction: column;
			padding: 10px;

			.wheel-field-title {
				margin-bottom: 5px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: ${snackeet_dark_grey};
			}
		}

		.wheel-field:not(:last-of-type) {
			border-bottom: 1px solid ${({
  theme
}) => theme.palette.divider};
		}
	}
`;
export const BackgroundTypeSelection = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
`;
export const RemoveFieldButton = styled.div`
	outline: none;
	background-color: transparent;
	border: none;
	font-size: 21px;
	cursor: pointer;
	color: ${black};
	&:hover {
		color: ${snackeet_blue};
	}
`;
export const FieldLine = styled.div`
	display: inline-flex;
	align-items: center;
	margin-bottom: 8px;
	.checkbox {
		color: #00cc00;
		margin-right: 10px;
	}
`;
export const AnswersHeader = styled(PropertyBlock)`
	height: max-content;
	text-align: left;
`;
const largeButtonStyles = ({
  $isLarge,
  theme
}) => $isLarge && css`
		margin-top: ${theme.spacing(1)};

		.MuiButton-label {
			font-size: 16px;
		}
	`;
export const ChangePageTypeButton = styled(Button)`
	color: ${({
  theme
}) => theme.palette.primary.dark};
	background-color: white;
	width: fit-content;
	box-shadow: ${({
  theme
}) => theme.shadows[1]};
	margin-left: auto !important;
	margin-right: auto !important;

	.MuiButton-label {
		font-size: 12px;
	}

	svg {
		height: 20px;
		padding-right: 5px;
	}

	${largeButtonStyles}
`;
export const FormField = styled(PropertyBlock)`
	display: flex;
	position: relative;
	flex-direction: column;
	width: 100%;
	padding: ${({
  theme
}) => theme.spacing(1, 4)};

	&:not(:last-of-type) {
		margin-bottom: ${({
  theme
}) => theme.spacing(1)};
	}
	.text {
		font-weight: 600;
		margin-top: 8px;
	}
	.checkbox {
		margin-left: 8px;
	}

	.checkbox-container {
		display: inline-flex;
		align-items: center;
	}

	.options-list {
		max-height: calc(100vh - 590px);
		overflow: auto;
		margin-bottom: ${({
  theme
}) => theme.spacing(1)};
	}

	.property-line {
		margin: ${({
  theme
}) => theme.spacing(1.5, 0)};
	}

	.product-link {
		input {
			padding: ${({
  theme
}) => theme.spacing(1)};
		}
	}
`;
export const BrandList = styled.div`
	width: 100%;
	margin: ${({
  theme
}) => theme.spacing(2, 0)};
	display: grid;
	grid-gap: 8px;
	grid-template-columns: repeat(4, 1fr);
	align-items: center;
	justify-content: center;

	img {
		width: 100%;
	}
`;
export const CardAnimation = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: ${({
  $isBlockAnimation
}) => $isBlockAnimation ? `80px` : `100px`};
	height: ${({
  $isBlockAnimation
}) => $isBlockAnimation ? `80px` : `100px`};
	border: ${({
  theme,
  $isActive
}) => $isActive ? `3px solid ${theme.palette.primary.main}` : `2px solid ${theme.palette.divider}`};
	border-radius: 10px;
	padding: 5px;
	overflow: hidden;
	cursor: pointer;

	.preview-animation {
		/* background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(132,234,255,1) 100%); */
		background: ${({
  theme
}) => theme.palette.primary.main};
		box-shadow: ${({
  theme
}) => theme.shadows[1]};
		width: 35px;
		height: ${({
  $isBlockAnimation
}) => $isBlockAnimation ? `35px` : `63px`};
		border-radius: 5px;
		animation-duration: 2000ms;
		animation-iteration-count: 0;
	}

	p {
		margin: 0;
		padding-top: 5px;
		font-weight: bold;
	}

	:hover .preview-animation {
		animation-iteration-count: infinite;
	}
`;

// ThemeProperties & SharingBlockOptions - Button text property

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({
  theme
}) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0, 0.5),
    border: '1px solid #ccc',
    minWidth: '40px',
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius
    }
  }
}));
export const ShapeWrapper = styled(Popover)`
	.MuiPopover-paper {
		width: 350px;
		min-height: 500px;
		max-height: 500px;
	}
`;
export const FileDownloaderInput = styled.div`
	width: 100%;
	padding: ${({
  theme
}) => theme.spacing(1)};
	display: flex;
	align-items: center;
	flex-direction: column;

	.MuiButton-root {
		width: 200px;
	}
`;
export const FileEntry = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
	background-color: ${({
  theme
}) => transparentize(0.8, theme.palette.primary.main)};
	width: 100%;
	border-radius: 4px;
	padding: ${({
  theme
}) => theme.spacing(1)};

	span {
		flex: 1;
		text-align: center;
		text-overflow: ellipsis;
		overflow: hidden;
		height: 15px;
	}

	.MuiIconButton-root {
		margin-left: ${({
  theme
}) => theme.spacing(1)};
	}
`;