import { Box, Button, Menu, Typography } from '@mui/material';
import { Slider } from 'atoms';
import _ from 'lodash';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
const DEFAULT_MARGINS = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
};
const DEFAULT_VALUES = [0, 10, 20, 40, 60, 100, 140, 220];
export default function MarginSetter({
  margins = DEFAULT_MARGINS,
  onUpdate
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);

  // Open menu handler
  const handleOpenMenu = (edge, event) => {
    setSelectedEdge(edge);
    setAnchorEl(event.currentTarget);
  };

  // Close menu handler
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedEdge(null);
  };

  // Update margin value for the selected edge
  const handleSetMargin = newValue => {
    console.log('handleSetMargin', selectedEdge, newValue);
    if (selectedEdge) {
      const newMargins = _.cloneDeep(margins);
      newMargins[selectedEdge] = newValue;
      onUpdate(newMargins);
    }
  };
  return <Box sx={{
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateRows: '24px minmax(8px, 1fr) 24px',
    justifyItems: 'center',
    gap: 0.5,
    placeItems: 'center'
  }}>
			{/* Edge buttons for margins */}
			{['top', 'right', 'bottom', 'left'].map(edge => <Button key={edge} sx={{
      textTransform: 'none',
      ...(edge === 'top' && {
        gridArea: '1 / 2 / 2 / 3'
      }),
      ...(edge === 'right' && {
        gridArea: '2 / 3 / 3 / 4'
      }),
      ...(edge === 'bottom' && {
        gridArea: '3 / 2 / 4 / 3'
      }),
      ...(edge === 'left' && {
        gridArea: '2 / 1 / 3 / 2'
      })
    }} onMouseDown={e => handleOpenMenu(edge, e)}>
					{margins[edge]} px
				</Button>)}

			{/* The Element whose margin is being set */}
			<Box sx={{
      border: '2px solid',
      borderColor: theme => theme.palette.divider,
      gridArea: '2 / 2 / 3 / 3',
      placeSelf: 'stretch'
    }} />

			{/* Margin options menu */}
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
				<Box sx={{
        padding: 2
      }}>
					{/* Slider to adjust margin */}
					{selectedEdge && <>
							<Typography variant='subtitle1' gutterBottom>
								<FormattedMessage id={`widget_editor.appearance.margin.${selectedEdge}`} />
							</Typography>
							<Slider onUpdate={value => handleSetMargin(Number(value))} initial_value={margins[selectedEdge] ?? 0} maxValue={300} minValue={0} units='px' sliderStep={1} debounceDelay={0} />
							<Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 2
          }}>
								{DEFAULT_VALUES.map(value => <Button key={value} onClick={() => handleSetMargin(value)}>
										{value}
									</Button>)}
							</Box>
						</>}
				</Box>
			</Menu>
		</Box>;
}