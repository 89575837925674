import { immerable } from 'immer'
import * as translation from 'lib/constants/InteractionTranslation'
import { moveBlockDown, moveBlockUp, reorder } from 'lib/utils'
import _ from 'lodash'
import { nanoid } from 'nanoid'
import { Frame } from 'scenejs'

import { Button } from './Button'
import {
	getAmpAttachmentInitialValue,
	getAmpInteractionInitialValue,
	getBackgroundElementInitialValue,
	getBlockInitialValue,
	getButtonListInitialValue,
	getCarouselInitialValue,
	getInitRoundedButton,
	getInteractionInitialValue,
	getModalInitialValue,
	getTagInitialValue,
	getTimerInitialValue,
} from './initialValues'

const DEFAULT_CONDITION = {
	id: 'root',
	combinator: 'and',
	not: false,
	rules: [],
}

export class Page {
	[immerable] = true

	constructor(page_data) {
		if (page_data) {
			return this._createFromExisting(page_data)
		} else {
			return this._createNew()
		}
	}

	_createFromExisting(page_data) {
		_.assign(this, page_data)

		if (page_data.type === 'multiple_choice') {
			this.answers = _.map(page_data.answers, (answer) => new Button(answer))
			if (typeof this.answers_properties.other_answer === 'object') {
				this.answers_properties = {
					...this.answers_properties,
					other_answer: new Button(this.answers_properties.other_answer),
				}
			}
		}
	}

	_createNew() {
		this._id = nanoid()
		this.name = ''
		this.animation = { name: '', duration: 100 }
		this.background_element = getBackgroundElementInitialValue('empty')
		this.next = 'next'
		this.tags = []
		this.blocks = []
		this.carousels = []
		this.buttonLists = []
	}

	// Page data

	setName(name) {
		this.name = name
		return this
	}

	changeLayout(layout, variables, completelyChange = false) {
		if (!layout) {
			// If layout undefined, reset page from scratch
			this.background_element = getBackgroundElementInitialValue('color')
			this.blocks = []
			this.tags = []
			this.carousels = []
			this.buttonLists = []
		} else {
			// Change elements ids
			this.background_element = layout.background_element

			this.blocks = _.map(layout?.blocks, (block) => ({
				...block,
				_id: nanoid(),
			}))

			this.tags = _.map(layout.tags, (tag) => ({ ...tag, _id: nanoid() }))
			this.carousels = _.map(layout.carousels, (carousel) => ({ ...carousel, _id: nanoid() }))
			this.buttonLists = _.map(layout.buttonLists, (buttonList) => ({ ...buttonList, _id: nanoid() }))

			if (layout.type === 'game') {
				copyGameLayout(this, layout)
			}

			if (completelyChange) {
				if (layout.type === 'form') {
					copyPageForm(this, layout, variables)
				}

				if (layout.type === 'media_answer') {
					copyMediaAnswer(this, layout)
				}

				if (layout.type === 'multiple_choice') {
					copyPageAnswers(this, layout)
				}

				if (layout.type === 'rating') {
					copyPageRating(this, layout)
				}

				if (layout.type === 'amp_page') {
					if (!_.isEmpty(layout.amp_interactions)) {
						copyAmpInteraction(this, layout, variables)
					}
					if (!_.isEmpty(layout.amp_attachments)) {
						copyAmpAttachment(this, layout, variables)
					}
				}
			}
		}

		return this
	}

	setType({ type, sub_type, messages, variables, lang = 'en' }) {
		this.type = type

		const setStartPage = () => {
			this.timer = getTimerInitialValue()
			this.disabled = false
			this.rewards = {
				enabled: false,
				emoji: '👍',
			}
			this.roundedButton = {
				enabled: false,
				style: getInitRoundedButton(),
			}
			this.display_start_button = true
			this.logics = []
		}

		const setMultipleChoicePage = () => {
			this.timer = getTimerInitialValue()
			this.disabled = false
			this.variable = undefined
			this.rewards = {
				enabled: false,
				emoji: '👍',
			}
			this.answers_properties = {
				type: undefined,
			}
			this.interactions = undefined
			this.logics = []
			this.on_enter = {
				enabled: false,
				condition: DEFAULT_CONDITION,
				redirect: 'next',
			}
		}

		const setFormPage = () => {
			this.answers = undefined
			this.timer = getTimerInitialValue({ isEnabled: false })
			this.disabled = false
			this.interactions = [
				getInteractionInitialValue({
					type: 'form',
					sub_type,
					lang,
					variables,
					messages,
				}),
			]
			this.answers_properties = undefined
			this.logics = []
			this.on_enter = {
				enabled: false,
				condition: DEFAULT_CONDITION,
				redirect: 'next',
			}
		}

		const setRatingPage = () => {
			this.variable = undefined
			this.disabled = false
			this.timer = getTimerInitialValue()
			this.interactions = [
				getInteractionInitialValue({
					type: 'rating',
					lang,
					variables,
					messages,
				}),
			]
			this.answers = undefined
			this.answers_properties = undefined
			this.logics = []
			this.on_enter = {
				enabled: false,
				condition: DEFAULT_CONDITION,
				redirect: 'next',
			}
		}

		const setMediaAnswerPage = () => {
			this.variable = undefined
			this.disabled = false
			this.answers = undefined
			this.timer = getTimerInitialValue({ isEnabled: false })
			this.interactions = [getInteractionInitialValue({ type: 'media_answer', sub_type, lang })]
			this.answers_properties = undefined
			this.logics = []
			this.on_enter = {
				enabled: false,
				condition: DEFAULT_CONDITION,
				redirect: 'next',
			}
		}

		const setGamePage = () => {
			this.variable = undefined
			this.disabled = false
			this.answers = undefined
			this.timer = getTimerInitialValue({ isEnabled: false })
			this.interactions = [getInteractionInitialValue({ type: 'game', sub_type, lang })]
			this.modal = getModalInitialValue({ sub_type, lang })
			this.answers_properties = undefined
			this.logics = []
			this.on_enter = {
				enabled: false,
				condition: DEFAULT_CONDITION,
				redirect: 'next',
			}
		}

		const setBlankPage = () => {
			this.answers = undefined
			this.disabled = false
			this.interactions = []
			this.timer = getTimerInitialValue()
			this.logics = []
			this.on_enter = {
				enabled: false,
				condition: DEFAULT_CONDITION,
				redirect: 'next',
			}
		}

		const setAmpPage = () => {
			this.amp_interactions = []
			this.disabled = false
			this.amp_attachments = []
			this.timer = getTimerInitialValue()
		}

		const setEndingPage = () => {
			this.interactions = []
			this.restart_allowed = false
			this.disabled = false
			this.redirection_button = {}
			this.rewards = {
				enabled: false,
				emoji: '👍',
			}
		}

		if (type === 'start_page') {
			setStartPage()
		} else if (type === 'multiple_choice') {
			setMultipleChoicePage()
		} else if (type === 'form') {
			setFormPage()
		} else if (type === 'rating') {
			setRatingPage()
		} else if (type === 'media_answer') {
			setMediaAnswerPage()
		} else if (type === 'game') {
			setGamePage()
		} else if (type === 'blank') {
			setBlankPage()
		} else if (type === 'ending_page') {
			setEndingPage()
		} else if (type === 'amp_page') {
			setAmpPage()
		}

		if (type !== 'ending_page' && !this.next) {
			this.next = 'next'
		}

		return this
	}

	setButtonGroupType({ buttonGroupType, buttonsText }) {
		// Common properties
		this.answers_properties.type = buttonGroupType === 'upload_file' ? 'action' : 'answer'
		this.answers_properties.confirm_button = buttonsText.confirm
		this.answers_properties.required_answer = false
		this.answers = []

		if (buttonGroupType === 'upload_file') {
			this.answers_properties.partial_required_answer = false
			this.answers_properties.next_button = false

			this.answers = [new Button().setType('file_uploader').setTitle(buttonsText.default)]
		} else {
			this.answers_properties.multiple_selection = false
			this.answers_properties.limit_answers = false
			this.answers_properties.limit_answers_count = 2

			this.answers_properties.other_answer = false
			this.answers_properties.count_response_percentage = false
			this.answers_properties.has_choice_letters = true

			if (buttonGroupType.startsWith('quiz')) {
				this.answers_properties.count_in_results = true
				this.answers_properties.required_answer = true

				// Add quiz properties
				this.answers_properties.partial_correct = false
				this.answers_properties.correct_redirect = 'next'
				this.answers_properties.incorrect_redirect = 'next'
				this.answers_properties.partial_redirect = 'next'
			} else if (buttonGroupType.startsWith('poll')) {
				this.answers_properties.count_in_results = false
				this.answers_properties.count_response_percentage = true
				this.answers_properties.required_answer = true

				// Remove quiz properties
				delete this.answers_properties.partial_correct
				delete this.answers_properties.correct_redirect
				delete this.answers_properties.incorrect_redirect
				delete this.answers_properties.partial_redirect
			} else if (buttonGroupType.startsWith('vote')) {
				this.answers_properties.count_in_results = false
				this.answers_properties.count_response_percentage = true
				this.answers_properties.multiple_selection = false
				this.answers_properties.participant_display = 'number_and_icon'
				this.answers_properties.has_choice_letters = false
				this.answers_properties.required_answer = true

				// Remove quiz properties
				delete this.answers_properties.partial_correct
				delete this.answers_properties.correct_redirect
				delete this.answers_properties.incorrect_redirect
				delete this.answers_properties.partial_redirect
			}

			if (buttonGroupType.includes('image')) {
				this.answers = [
					new Button().setType('image').setTitle(`${buttonsText.default} 1`),
					new Button().setType('image').setTitle(`${buttonsText.default} 2`),
				]
			} else {
				this.answers = [
					new Button().setType('text').setTitle(`${buttonsText.default} 1`),
					new Button().setType('text').setTitle(`${buttonsText.default} 2`),
				]
			}
		}
		return this
	}

	setAnswerButtonType(buttonType) {
		if (!['image', 'text'].includes(buttonType)) return this

		_.each(this.answers, (answer) => {
			if (!(answer instanceof Button)) {
				answer = new Button(answer)
			}
			answer.setType(buttonType)
		})
	}

	enableQuizMode() {
		if (this.answers_properties.type !== 'answer') return this

		this.answers_properties.count_in_results = true

		// Add quiz properties
		this.answers_properties.partial_correct = false
		this.answers_properties.correct_redirect = 'next'
		this.answers_properties.incorrect_redirect = 'next'
		this.answers_properties.partial_redirect = 'next'

		// Reset other answer
		this.answers_properties.other_answer = false

		return this
	}

	disableQuizMode() {
		if (this.answers_properties.type !== 'answer') return this

		this.answers_properties.count_in_results = false

		delete this.answers_properties.partial_correct
		delete this.answers_properties.correct_redirect
		delete this.answers_properties.incorrect_redirect
		delete this.answers_properties.partial_redirect

		return this
	}

	setRatingCategory(rating_category, intl) {
		this.interactions = [
			{
				_id: nanoid(),
				type: 'rating',
				required: false,
				max: 5,
				title: intl.messages['editor.configuration.rating_default_text'],
				subtitle: '',
				category: rating_category,
				style: {
					backgroundColor: '#FFFFFF',
					color: 'rgba(72, 71, 71, 100)',
					fontFamily: 'Poppins',
					fontSize: '16px',
					subtileFontSize: '14px',
				},
			},
		]
		return this
	}

	addRatingToEndPage(rating_category, lang = 'en') {
		// Create a new rating interaction and add it to the ending page
		const newInteraction = {
			_id: nanoid(),
			type: 'rating',
			required: false,
			max: 5,
			title: translation.rating_title[lang],
			subtitle: '',
			category: rating_category,
			style: {
				backgroundColor: '#FFFFFF',
				color: 'rgba(72, 71, 71, 100)',
				fontFamily: 'Poppins',
				fontSize: '16px',
				subtileFontSize: '14px',
			},
		}

		this.interactions.push(newInteraction)

		// Disable the story result at ending page
		const storyResults = _.find(this.interactions, { type: 'story_results' })
		if (storyResults) {
			storyResults.visible = false
		}

		return newInteraction
	}

	removeRatingFromEndPage() {
		const storyResults = _.find(this.interactions, { type: 'story_results' })

		this.interactions = [storyResults]

		return this
	}

	setPageNext(page_id) {
		this.next = page_id
		return this
	}

	setRestartAllowed(value) {
		this.restart_allowed = value
		return this
	}

	setRedirectionButton(data) {
		if (!this.redirection_button) this.redirection_button = {}
		for (const prop in data) {
			_.set(this.redirection_button, prop, data[prop])
		}
		return this
	}

	// Multiple Choice

	toggleMultipleSelection() {
		this.answers_properties.multiple_selection = !this.answers_properties.multiple_selection
		return this
	}

	toggleLimitAnswers() {
		this.answers_properties.limit_answers = !this.answers_properties.limit_answers
		return this
	}

	setLimitAnswersCount(value) {
		this.answers_properties.limit_answers_count = value
		return this
	}

	setPageConfirmButton(value) {
		this.answers_properties.confirm_button = value
		return this
	}

	togglePartialCorrect() {
		this.answers_properties.partial_correct = !this.answers_properties.partial_correct
		return this
	}

	toggleRequiredAnswer() {
		this.answers_properties.required_answer = !this.answers_properties.required_answer
		if (this.answers_properties.required_answer) {
			this.timer.value = 0
		}
		return this
	}

	toggleOtherAnswer(intl) {
		if (this.answers_properties.other_answer) {
			this.answers_properties.other_answer = false
		} else {
			const otherAnswer = new Button()
				.setType('other_answer')
				.setTitle(intl.messages['edition_menu.answer.other_answer.title'])
			otherAnswer._id = btoa(this._id)
			this.answers_properties.other_answer = otherAnswer
		}
		return this
	}

	toggleCountInResults() {
		this.answers_properties.count_in_results = !this.answers_properties.count_in_results
		return this
	}

	toggleCountResponsePercentages() {
		this.answers_properties.count_response_percentage = !this.answers_properties.count_response_percentage
		return this
	}

	toggleNextButton() {
		this.answers_properties.next_button = !this.answers_properties.next_button
		return this
	}

	setAnimation(operations = []) {
		for (const operation of _.castArray(operations)) {
			const { path, value } = operation
			_.set(this.animation, path, value)
		}
		return this
	}

	// Quiz answers page's result redirection
	setCorrectRedirect(value = 'next') {
		this.answers_properties.correct_redirect = value
		return this
	}

	setIncorrectRedirect(value = 'next') {
		this.answers_properties.incorrect_redirect = value
		return this
	}

	setPartialRedirect(value = 'next') {
		this.answers_properties.partial_redirect = value
		return this
	}

	// Page Rewards

	togglePageRewards() {
		this.rewards.enabled = !this.rewards.enabled
		return this
	}

	updateRewardEmoji(value) {
		this.rewards.emoji = value
		return this
	}

	// on_enter condition

	toggleOnEnterCondition(value) {
		this.on_enter.enabled = value
		return this
	}

	setOnEnterCondition(condition) {
		this.on_enter.condition = condition
		return this
	}

	setOnEnterRedirect(page_id) {
		this.on_enter.redirect = page_id
		return this
	}

	// Conditions Logics

	setLogics(logics) {
		this.logics = logics
		return this
	}

	// Start Button
	displayStartButton() {
		this.display_start_button = true
		return this
	}

	hideStartButton() {
		this.display_start_button = false
		const timerOperations = [
			{
				path: 'enabled',
				value: true,
			},
		]
		this.updateTimer(timerOperations)
		return this
	}

	// Rounded Start Button

	toggleRoundedButton() {
		this.roundedButton.enabled = !this.roundedButton.enabled
		return this
	}

	updateRoundedButtonStyle(operations) {
		for (const operation of operations) {
			const { path, value } = operation
			_.set(this.roundedButton.style, path, value)
		}
		return this
	}

	// Timer

	updateTimer(operations) {
		for (const operation of _.castArray(operations)) {
			const { path, value } = operation
			if (path === 'size') {
				_.set(this.timer, 'outer_style.width', value)
				_.set(this.timer, 'outer_style.height', value)
			} else {
				_.set(this.timer, path, value)
			}
		}

		// Prevent page animation from exceeding timer (timer in s / animation duration in ms)
		if (this.timer?.enabled && this.animation.duration > this.timer?.value * 1000) {
			this.animation.duration = this.timer?.value * 1000
		}

		return this
	}

	hideTimer(timerOperations) {
		for (const operation of _.castArray(timerOperations)) {
			if (this.timer?._id === operation._id && this.type !== 'ending_page') {
				this.timer.visible = false
			}
		}
		return this
	}

	toggleTimerVisible() {
		this.timer.visible = !this.timer.visible
		return this
	}

	// Disabled
	togglePageDisabled() {
		if (this.disabled) {
			this.disabled = false
		} else {
			this.disabled = true
		}
	}

	// Background Element

	updateBackgroundElement(operations) {
		for (const operation of _.castArray(operations)) {
			const { path, value, isSet = true } = operation
			isSet ? _.set(this.background_element, path, value) : _.unset(this.background_element, path)
		}
		return this
	}

	changeBackgroundElementType({ newBackgroundType, backgroundStates }) {
		this.background_element =
			backgroundStates[newBackgroundType] || getBackgroundElementInitialValue(newBackgroundType)
		return this
	}

	setBackgroundElement(value) {
		this.background_element = value
		return this
	}

	toggleBackgroundGradient() {
		this.background_element.gradient_props.enabled = !this.background_element.gradient_props.enabled
		return this
	}

	// ------------ AMP Interactions & attachments ------------
	addOutlinkAttachment({ type, lang }) {
		const newOutlinkAttachment = getAmpAttachmentInitialValue({
			type,
			lang,
		})
		if (!this.amp_attachments) {
			this.amp_attachments = []
		}
		this.amp_attachments.push(newOutlinkAttachment)
		return newOutlinkAttachment
	}

	addAttachment(newAttachment) {
		if (!this.amp_attachments) {
			this.amp_attachments = []
		}

		this.amp_attachments.push(newAttachment)
		return newAttachment
	}

	removeAmpAttachment(_id) {
		_.remove(this.amp_attachments, (attachment) => attachment._id === _id)
	}

	updateOutlink(data) {
		const { value, path } = data
		const index = _.findIndex(this.amp_attachments, (interaction) => interaction.type === 'outlink')
		if (index >= 0) {
			_.set(this.amp_attachments[index], path, value)
		}
	}

	updateAmpForm(data) {
		const { value, path } = data
		const index = _.findIndex(this.amp_attachments, (interaction) => interaction.type === 'amp_form')
		if (index >= 0) {
			_.set(this.amp_attachments[index], path, value)
		}
	}

	updateAmpAttachment(data) {
		const { value, path } = data
		const index = _.findIndex(this.amp_attachments, (interaction) => interaction.type === 'amp_attachment')
		if (index >= 0) {
			_.set(this.amp_attachments[index], path, value)
		}
	}

	updateAttachment(newAttachment) {
		this.amp_attachments = [newAttachment]

		return newAttachment
	}

	updateAmpInteraction(data) {
		const { _id, value, path } = data
		const index = _.findIndex(this.amp_interactions, (amp_interaction) => amp_interaction._id === _id)
		if (index >= 0) {
			_.set(this.amp_interactions[index], path, value)
		}
	}

	addAmpInteraction({ type }) {
		const newAmpInteraction = getAmpInteractionInitialValue({ type })
		if (!this.amp_interactions) {
			this.amp_interactions = []
		}
		this.amp_interactions.push(newAmpInteraction)
		return newAmpInteraction
	}

	removeAmpInteraction(_id) {
		_.remove(this.amp_interactions, (amp_interaction) => amp_interaction._id === _id)
	}

	// Interactions

	setInteraction({ _id, field, value }) {
		const interaction = _.find(this.interactions, { _id })
		if (!interaction) return

		_.set(interaction, field, value)
	}

	addPrize({ _id }) {
		const wheelInteraction = _.find(this.interactions, { _id })
		if (!wheelInteraction) return

		const prizes = wheelInteraction.components?.wheel?.properties?.prizes
		if (!prizes) return

		const prizeCount = prizes.length
		prizes.push({
			_id: nanoid(),
			option: `Option ${prizeCount + 1}`,
			weight: 1,
			isLose: false,
		})
	}

	removePrize({ _id, prizeId }) {
		const wheelInteraction = _.find(this.interactions, { _id })
		if (!wheelInteraction) return

		const prizes = wheelInteraction.components?.wheel?.properties?.prizes
		if (!prizes) return

		_.remove(prizes, { _id: prizeId })
	}

	updatePrize({ _id, field, value, prizeId }) {
		const wheelInteraction = _.find(this.interactions, { _id })
		if (!wheelInteraction) return

		const prizes = wheelInteraction.components?.wheel?.properties?.prizes
		if (!prizes) return

		const prize = _.find(prizes, { _id: prizeId })
		if (!prize) return

		_.set(prize, field, value)
	}

	updateGameModal({ path, field, value }) {
		if (this.modal) {
			_.set(this.modal, `${path}.${field}`, value)
		}
	}

	updateGameInteraction(newInteraction) {
		const index = _.findIndex(this.interactions, (interaction) => interaction.type === 'game')
		if (index >= 0) {
			this.interactions[index] = newInteraction
		}
	}

	updateModal(newModal) {
		this.modal = newModal
	}

	// Form

	updateForm(newForm) {
		const index = _.findIndex(this.interactions, (interaction) => interaction.type === 'form')
		if (index >= 0) {
			this.interactions[index] = newForm
		}
	}

	updateFormField(data) {
		const { value, path } = data
		const index = _.findIndex(this.interactions, (interaction) => interaction.type === 'form')
		if (index >= 0) {
			_.set(this.interactions[index], path, value)
		}
	}

	setRating(_id, rating) {
		const index = _.findIndex(this.interactions, (interaction) => interaction._id === _id)
		if (index >= 0) {
			this.interactions[index] = rating

			return this.interactions[index]
		}
	}

	setMediaAnswer(_id, media_answer) {
		const index = _.findIndex(this.interactions, (interaction) => interaction._id === _id)
		if (index >= 0) {
			this.interactions[index] = media_answer

			return this.interactions[index]
		}
	}

	// setSharing (action) {
	// 	const { data, path } = action
	// 	const index = _.findIndex(this.interactions, interaction => interaction.type === 'share')
	// 	if (path === 'social_networks') {
	// 		if (this.interactions[index].social_networks.includes(data)) {
	// 			_.remove(this.interactions[index].social_networks, o => o === data)
	// 		} else {
	// 			this.interactions[index].social_networks.push(data)
	// 		}
	// 	} else {
	// 		_.set(this.interactions[index], path, data)
	// 	}

	// 	return this.interactions[index]
	// }

	addStoryResults() {
		if (!_.find(this.interactions, { type: 'story_results' })) {
			this.interactions.push({
				_id: nanoid(),
				visible: true,
				type: 'story_results',
				title: 'Results',
				style: {},
			})
		}
		return this
	}

	toggleStoryResults() {
		const storyResults = _.find(this.interactions, {
			type: 'story_results',
		})
		if (storyResults) {
			storyResults.visible = !storyResults.visible
			return storyResults
		}
		return this
	}

	activeStoryResults() {
		const storyResults = _.find(this.interactions, {
			type: 'story_results',
		})
		if (storyResults) {
			storyResults.visible = true

			this.interactions = [storyResults]

			return storyResults
		}
		return this
	}

	updateInterfaceLayer(pageOperations) {
		for (const operation of _.castArray(pageOperations)) {
			const { path, value } = operation
			_.set(this, path, value)
		}
		return this
	}

	updateInteraction(interactionOperations) {
		for (const operation of _.castArray(interactionOperations)) {
			const { path, value } = operation
			_.set(this.interactions[0], path, value)
		}
		return this
	}

	updateAmpInteractions(ampInteractionOperations) {
		for (const operation of _.castArray(ampInteractionOperations)) {
			const { path, value } = operation
			_.set(this.amp_interactions[0], path, value)
		}
		return this
	}

	removeAmpInteractions(operations) {
		const idsToRemove = _.map(_.castArray(operations), '_id')
		_.remove(this.amp_interactions, (ampInteraction) => idsToRemove.includes(ampInteraction._id))
		return this
	}

	updateAnswerProperties(answersOperations) {
		for (const operation of _.castArray(answersOperations)) {
			const { path, value } = operation
			_.set(this.answers_properties, path, value)
		}
		return this
	}

	// Blocks

	addBlock(data) {
		const { blockType, subType, buttonType, textType, initialProperties, text_styles, leaderboard_id } = data

		// Before adding new block, remove all empty blocks
		_.remove(this.blocks, isBlockEmpty)
		if (_.isEmpty(this.blocks)) {
			this.blocks = []
		}

		if (blockType === 'text' && !_.isEmpty(text_styles)) {
			const textBlock = getBlockInitialValue({
				type: blockType,
				textType,
				initialProperties,
			})

			for (const props in text_styles) {
				_.assign(textBlock[props], text_styles[props])
			}

			this.blocks.push(textBlock)
		} else {
			this.blocks.push(
				getBlockInitialValue({
					type: blockType,
					leaderboard_id,
					subType,
					buttonType,
					textType,
					initialProperties,
				})
			)
		}

		return _.last(this.blocks)
	}

	updateBlock(operations) {
		for (const operation of _.castArray(operations)) {
			const { _id, path, value, isSet = true } = operation
			const blockCopy = _.find(this.blocks, { _id })
			isSet ? _.set(blockCopy, path, value) : _.unset(blockCopy, path)
		}
	}

	duplicateBlock(operations) {
		return _.flatMap(_.castArray(operations), (operation) => {
			const { _id } = operation
			const blockToDuplicate = _.find(this.blocks, ['_id', _id])
			if (!blockToDuplicate) {
				return []
			}

			const duplicatedBlock = _.cloneDeep(blockToDuplicate)
			duplicatedBlock._id = nanoid()
			return [duplicatedBlock]
		})
	}

	removeBlock(operations) {
		const idsToRemove = _.map(_.castArray(operations), '_id')
		_.remove(this.blocks, (block) => idsToRemove.includes(block._id))
	}

	applyCopiedStyles({ _id, copiedTextStyles }) {
		const newStyles = _.cloneDeep(copiedTextStyles)
		const blockToUpdate = _.find(this.blocks, (block) => block._id === _id)

		// To keep the current position & guarantee that all styles are copied
		newStyles.outer_style.transform = blockToUpdate.outer_style.transform

		for (const props in newStyles) {
			_.set(blockToUpdate, props, newStyles[props])
		}
	}

	reorderBlocks(result) {
		this.blocks = reorder(this.blocks, result.source.index, result.destination.index)
	}

	reorderBlockContextMenu(selectedElement, action) {
		const blocksToMove = _.filter(_.castArray(selectedElement), ['type', 'block'])
		const blocksToMoveIds = _.map(blocksToMove, '_id')

		switch (action) {
			case 'BRING_FORWARD':
				moveBlockUp(this.blocks, blocksToMoveIds)
				break
			case 'SEND_BACKWARD':
				moveBlockDown(this.blocks, blocksToMoveIds)
				break
			case 'BRING_TO_FRONT': {
				const selectedBlocks = _.remove(this.blocks, (block) => blocksToMoveIds.includes(block._id))
				this.blocks.push(...selectedBlocks)
				break
			}
			case 'SEND_TO_BACK': {
				const selectedBlocks = _.remove(this.blocks, (block) => blocksToMoveIds.includes(block._id))
				this.blocks.unshift(...selectedBlocks)
			}
		}
	}

	setSocialNetwork({ value, path, _id }) {
		const blockCopy = _.find(this.blocks, { _id })
		if (path === 'networks') {
			blockCopy.networks = value
		}
	}

	setMessenger({ value: updatedPlatformsNames, _id }) {
		const messengerBlock = _.find(this.blocks, { _id })
		const currentPlatformsNames = _.map(messengerBlock.platforms, 'name')
		const platformsToRemove = _.difference(currentPlatformsNames, updatedPlatformsNames)
		const platformsToAdd = _.difference(updatedPlatformsNames, currentPlatformsNames)

		// Prevent removing all platforms
		if (messengerBlock.platforms.length > 1) {
			_.remove(messengerBlock.platforms, (platform) => platformsToRemove.includes(platform.name))
		}

		messengerBlock.platforms = _.concat(
			messengerBlock.platforms,
			_.map(platformsToAdd, (name) => ({ name }))
		)
	}

	updateMessengerProperties({ value, name, path, _id }) {
		const messengerBlock = _.find(this.blocks, { _id })
		const platformIndex = _.findIndex(messengerBlock.platforms, { name })
		if (platformIndex !== -1) {
			_.set(messengerBlock.platforms[platformIndex], path, value)
		}
	}

	setNetworks({ value: updatedPlatformsNames, _id }) {
		const networksBlock = _.find(this.blocks, { _id })
		const currentPlatformsNames = _.map(networksBlock.platforms, 'name')
		const platformsToRemove = _.difference(currentPlatformsNames, updatedPlatformsNames)
		const platformsToAdd = _.difference(updatedPlatformsNames, currentPlatformsNames)

		// Prevent removing all platforms
		if (networksBlock.platforms.length > 1) {
			_.remove(networksBlock.platforms, (platform) => platformsToRemove.includes(platform.name))
		}

		networksBlock.platforms = _.concat(
			networksBlock.platforms,
			_.map(platformsToAdd, (name) => ({ name }))
		)
	}

	updateNetworksProperties({ value, name, path, _id }) {
		const networksBlock = _.find(this.blocks, { _id })
		const platformIndex = _.findIndex(networksBlock.platforms, { name })
		if (platformIndex !== -1) {
			_.set(networksBlock.platforms[platformIndex], path, value)
		}
	}

	reorderElementBlock({ result, field, _id }) {
		const blockCopy = _.find(this.blocks, { _id })
		blockCopy[field] = reorder(blockCopy[field], result.source.index, result.destination.index)
	}

	// Answers

	setAnswers(data) {
		this.answers = data
	}

	addAnswer({ type, buttonTitle }) {
		const newButton = new Button().setType(type).setTitle(buttonTitle)
		this.answers.push(newButton)
	}

	deleteAnswer(answer_id) {
		_.remove(this.answers, (answer) => answer._id === answer_id)
	}

	reorderAnswer(result) {
		this.answers = reorder(this.answers, result.source.index, result.destination.index)
	}

	// Tags

	createTag() {
		const newTag = getTagInitialValue()
		if (!this.tags) this.tags = []
		this.tags.push(newTag)
		return newTag
	}

	updateTag(operations) {
		for (const operation of _.castArray(operations)) {
			const { _id, path, value } = operation
			const currentTag = _.find(this.tags, (tag) => tag._id === _id)
			_.set(currentTag, path, value)
		}
	}

	updateTagPlacement(_id) {
		const tagCopy = _.find(this.tags, (tag) => tag._id === _id)
		if (tagCopy.placement === 'bottom') {
			const newOuterStyle = updatePlacement(tagCopy, 'top')
			_.set(tagCopy, 'outer_style', newOuterStyle)
			_.set(tagCopy, 'placement', 'top')
		} else {
			const newOuterStyle = updatePlacement(tagCopy, 'bottom')
			_.set(tagCopy, 'outer_style', newOuterStyle)
			_.set(tagCopy, 'placement', 'bottom')
		}
	}

	duplicateTag(operations) {
		return _.flatMap(_.castArray(operations), (operation) => {
			const { _id } = operation
			const tagToDuplicate = _.find(this.tags, ['_id', _id])
			if (!tagToDuplicate) {
				return []
			}

			const duplicatedTag = _.cloneDeep(tagToDuplicate)
			duplicatedTag._id = nanoid()
			return [duplicatedTag]
		})
	}

	removeTag(operations) {
		const idsToRemove = _.map(_.castArray(operations), '_id')
		_.remove(this.tags, (tag) => idsToRemove.includes(tag._id))
	}

	// Carousel
	addCarousel(type) {
		const newCarousel = getCarouselInitialValue(type)
		if (!this.carousels) this.carousels = []
		this.carousels.push(newCarousel)
		return newCarousel
	}

	updateCarousel(operations) {
		for (const operation of _.castArray(operations)) {
			const { _id, path, value } = operation
			const currentCarousel = _.find(this.carousels, (carousel) => carousel._id === _id)
			_.set(currentCarousel, path, value)
		}
	}

	removeCarousel(operations) {
		const idsToRemove = _.map(_.castArray(operations), '_id')
		_.remove(this.carousels, (carousel) => idsToRemove.includes(carousel._id))
	}

	duplicateCarousel(operations) {
		return _.flatMap(_.castArray(operations), (operation) => {
			const { _id } = operation
			const carouselToDuplicate = _.find(this.carousels, ['_id', _id])
			if (!carouselToDuplicate) {
				return []
			}

			const duplicatedCarousel = _.cloneDeep(carouselToDuplicate)
			duplicatedCarousel._id = nanoid()
			return [duplicatedCarousel]
		})
	}

	updateCarouselItem({ _id, field, value, itemId }) {
		const currentCarousel = _.find(this.carousels, { _id })
		if (!currentCarousel) return

		const items = currentCarousel.items
		if (!items) return

		const item = _.find(items, { _id: itemId })
		if (!item) return

		_.set(item, field, value)
	}

	setCarouselItem({ _id, value, itemId }) {
		const currentCarousel = _.find(this.carousels, { _id })
		if (!currentCarousel) return

		const items = currentCarousel.items
		if (!items) return

		const itemIdx = _.findIndex(items, { _id: itemId })
		if (itemIdx > -1) {
			items[itemIdx] = value
		}
	}

	// ButtonList
	addButtonList() {
		const newButtonList = getButtonListInitialValue()
		if (!this.buttonLists) this.buttonLists = []
		this.buttonLists.push(newButtonList)
		return newButtonList
	}

	updateButtonList(operations) {
		for (const operation of _.castArray(operations)) {
			const { _id, path, value } = operation
			const currentButtonList = _.find(this.buttonLists, (buttonList) => buttonList._id === _id)
			_.set(currentButtonList, path, value)
		}
	}

	removeButtonList(operations) {
		const idsToRemove = _.map(_.castArray(operations), '_id')
		_.remove(this.buttonLists, (buttonList) => idsToRemove.includes(buttonList._id))
	}

	duplicateButtonList(operations) {
		return _.flatMap(_.castArray(operations), (operation) => {
			const { _id } = operation
			const buttonListToDuplicate = _.find(this.buttonLists, ['_id', _id])
			if (!buttonListToDuplicate) {
				return []
			}

			const duplicatedButtonList = _.cloneDeep(buttonListToDuplicate)
			duplicatedButtonList._id = nanoid()
			return [duplicatedButtonList]
		})
	}

	updateButtonListButton({ _id, field, value, buttonId }) {
		const currentBouttonList = _.find(this.buttonLists, { _id })
		if (!currentBouttonList) return

		const buttons = currentBouttonList.buttons
		if (!buttons) return

		const button = _.find(buttons, { _id: buttonId })
		if (!button) return

		_.set(button, field, value)
	}

	// variable

	setVariable(value) {
		this.variable = value
	}

	unsetVariable() {
		this.variable = undefined
	}

	setPageOverlay(data) {
		this.overlay = data
	}
}

// Helpers

function isBlockEmpty(block) {
	return !block.value && !['messenger', 'networks'].includes(block.type)
}

function updatePlacement(tag, direction) {
	const frame = new Frame({
		width: tag.outer_style.width,
		height: tag.outer_style.height,
		left: tag.outer_style.left,
		top: tag.outer_style.top,
		transform: tag.outer_style.transform,
	})
	const currentY = parseFloat(frame.get('transform', 'translateY'))

	if (direction === 'bottom') {
		frame.set('transform', 'translateY', `${currentY + 70}px`)
	} else {
		frame.set('transform', 'translateY', `${currentY - 70}px`)
	}

	const cssObject = frame.toCSSObject()
	return cssObject
}

function copyAmpInteraction(page, template) {
	const ampInteraction = _.cloneDeep(_.first(template.amp_interactions))

	ampInteraction._id = `amp-${nanoid()}`

	if (!_.isEmpty(ampInteraction.options)) {
		for (const option of ampInteraction.options) {
			option._id = nanoid()
		}
	}

	page.amp_interactions = [ampInteraction]
}

function copyAmpAttachment(page, template, variables) {
	const ampAttachment = _.cloneDeep(_.first(template.amp_attachments))

	ampAttachment._id = `amp-${nanoid()}`

	if (ampAttachment?.type === 'amp_form') {
		for (const field of ampAttachment.content) {
			if (field?.type === 'input') {
				field._id = nanoid()
				field.variable = generateVariableName(field.sub_type, variables, ampAttachment.fields)
			}
		}
	}

	page.amp_attachments = [ampAttachment]
}

function copyPageAnswers(page, template) {
	const templateAnswers = _.cloneDeep(template.answers)
	const templateAnswersProperties = _.cloneDeep(template.answers_properties)

	// Refactor answer buttons
	for (const answer of templateAnswers) {
		answer._id = nanoid()
		if (templateAnswersProperties.type === 'answer') {
			answer.payload.next_page = 'next'
		}
	}

	_.assign(
		page.answers_properties,
		_.omit(templateAnswersProperties, ['correct_redirect', 'incorrect_redirect', 'partial_redirect'])
	)
	page.answers = templateAnswers
}

function copyPageRating(page, template) {
	const currentRatingInteraction = _.find(page.interactions, getRatingInteraction)
	const templateRatingInteraction = _.cloneDeep(_.find(template.interactions, getRatingInteraction))

	_.assign(currentRatingInteraction, _.omit(templateRatingInteraction, ['_id']))

	function getRatingInteraction(interaction) {
		return interaction.type === 'rating'
	}
}

function copyPageForm(page, template, variables) {
	const currentFormInteraction = _.find(page.interactions, getFormInteraction)
	const templateFormInteraction = _.cloneDeep(_.find(template.interactions, getFormInteraction))

	// Refactor template form interaction fields
	for (const field of templateFormInteraction.fields) {
		field._id = nanoid()
		field.variable = generateVariableName(field.sub_type, variables, templateFormInteraction.fields)
	}

	_.assign(currentFormInteraction, _.omit(templateFormInteraction, ['_id', 'next_redirection']))

	function getFormInteraction(interaction) {
		return interaction.type === 'form'
	}
}

function copyMediaAnswer(page, template) {
	const currentInteraction = _.find(page.interactions, getInteraction)
	const templateInteraction = _.cloneDeep(_.find(template.interactions, getInteraction))

	_.assign(currentInteraction, _.omit(templateInteraction, ['_id']))

	function getInteraction(interaction) {
		return interaction.type === 'media_answer'
	}
}

function copyGameLayout(page, layout) {
	const currentWheelInteraction = _.find(page.interactions, getWheelInteraction)
	const newWheelInteraction = _.find(layout.interactions, getWheelInteraction)

	if (!newWheelInteraction || !currentWheelInteraction) return

	currentWheelInteraction.components = newWheelInteraction.components

	function getWheelInteraction(interaction) {
		return interaction.type === 'game' && interaction.sub_type === 'wheel'
	}
}

// Generate variable name to assign variable by default
function generateVariableName(sub_type, variables, fields) {
	if (sub_type === 'email') return 'email'

	let variableName = sub_type

	let isInValid =
		_.some(variables, { name: variableName, isUsed: true }) || _.some(fields, { variable: variableName })

	let i = 0
	while (isInValid) {
		i++
		variableName = `${sub_type}_${i}`

		isInValid =
			_.some(variables, { name: variableName, isUsed: true }) || _.some(fields, { variable: variableName })
	}

	return variableName
}
