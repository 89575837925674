import _ from 'lodash';
import PropTypes from 'prop-types';
import AmpSlider from './AmpSlider';
import BinaryPollInteraction from './BinaryPoll';
import ImagePollInteraction from './ImagePoll';
import AmpImageQuizInteraction from './ImageQuiz';
import PollInteraction from './Poll';
import AmpQuizInteraction from './Quiz';
export default function AmpInteractions({
  amp_interactions
}) {
  const ampInteraction = _.first(amp_interactions);
  switch (ampInteraction?.type) {
    case 'amp_poll':
      return <PollInteraction ampInteraction={ampInteraction} />;
    case 'amp_binary_poll':
      return <BinaryPollInteraction ampInteraction={ampInteraction} />;
    case 'amp_image_poll':
      return <ImagePollInteraction ampInteraction={ampInteraction} />;
    case 'amp_quiz':
      return <AmpQuizInteraction ampInteraction={ampInteraction} />;
    case 'amp_image_quiz':
      return <AmpImageQuizInteraction ampInteraction={ampInteraction} />;
    case 'amp_slider':
      return <AmpSlider ampInteraction={ampInteraction} />;
    default:
      return null;
  }
}
AmpInteractions.propTypes = {
  amp_interactions: PropTypes.array
};