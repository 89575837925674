import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import styled from '@styled';
import { DEFAULT_FONT_FAMILIES } from 'lib/constants';
import useSubscriptionFeatures from 'lib/hooks/useSubscriptionFeatures';
import { getFontName } from 'lib/utils';
import _ from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getFonts } from 'redux/fonts/selectors';
const getOptionLabel = option => option.text ?? '';
const getOptionSelected = (option, value) => option.value === value.value;
const groupBy = option => option.group;
const renderInput = (params, isOutlined = false) => {
  if (isOutlined) {
    return <TextField {...params} InputProps={{
      ...params.InputProps
    }} variant='outlined' />;
  }
  return <TextField variant='standard' {...params} InputProps={{
    ...params.InputProps,
    disableUnderline: true
  }} />;
};
const renderGroup = option => <Group key={option.key}>
		<GroupTitle>
			<b>
				<FormattedMessage id={`edition_menu.font_family.group_${option.group}`} />
			</b>
		</GroupTitle>
		{option.children}
	</Group>;
export default function FontFamiliesDropdown({
  value,
  recentFonts = [],
  brandFontFamily = [],
  onChange,
  fullWidth = false,
  textAlign = 'start',
  isOutlined = false
}) {
  const router = useRouter();
  const customFonts = useSelector(getFonts);
  const {
    hasCustomFonts
  } = useSubscriptionFeatures();
  const fontFamilyOptions = getOptions({
    customFonts,
    recentFonts,
    brandFontFamily,
    hasCustomFonts
  });
  const defaultValue = _.find(fontFamilyOptions, font => font.value === value);
  const [inputValue, setInputValue] = useState('');
  const [selectValue, setSelectValue] = useState(defaultValue);
  const renderOption = (props, option) => {
    if (option.group === 'custom' && option.value === 'add_new_custom_font') {
      return <Link href={`/${router.query.organization_slug}/brands?tab=fonts`} rel='noopener noreferrer' target='_blank'>
					<Button onClick={() => {}} sx={{
          fontSize: '12px'
        }} variant='text' size='small' color='secondary'>
						<FormattedMessage id='edition_menu.font_family.add_new' />
					</Button>
				</Link>;
    }
    return <span {...props} style={{
      fontFamily: option.value,
      fontSize: '10px'
    }}>
				{option.text}
			</span>;
  };
  const onInputChange = useCallback((evt, val) => setInputValue(val), []);
  const handleOnSelectChange = useCallback((event, option) => {
    if (option) {
      setSelectValue(option);
      onChange(event, option.value);
    }
  }, [onChange]);
  return <FontFamilyWrapper $fontFamily={value} $textAlign={textAlign}>
			<Autocomplete fullWidth={fullWidth} options={fontFamilyOptions} inputValue={inputValue} disableClearable={true} onInputChange={onInputChange} value={selectValue} defaultValue={defaultValue} getOptionLabel={getOptionLabel} isOptionEqualToValue={getOptionSelected} groupBy={groupBy} onChange={handleOnSelectChange} renderGroup={renderGroup} renderOption={renderOption} renderInput={param => renderInput(param, isOutlined)} />
		</FontFamilyWrapper>;
}
FontFamiliesDropdown.propTypes = {
  value: PropTypes.string,
  recentFonts: PropTypes.array,
  brandFontFamily: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  textAlign: PropTypes.string,
  isOutlined: PropTypes.bool
};

// Helper functions
function getOptions({
  recentFonts,
  brandFontFamily,
  customFonts,
  hasCustomFonts
}) {
  const customBrandFont = _.find(customFonts, {
    font_id: brandFontFamily
  });
  const isDefaultBrandFamily = DEFAULT_FONT_FAMILIES.includes(brandFontFamily);
  const isDeletedBrandFontFamily = !customBrandFont && !isDefaultBrandFamily;
  const recentOptions = recentFonts.filter(isAllowedAsRecent(brandFontFamily, customFonts)).map(fontFamily => ({
    group: 'recent',
    value: fontFamily,
    text: fontFamily
  }));

  // Display brand font family if NOT deleted
  const brandOptions = !isDeletedBrandFontFamily ? [{
    group: 'brand',
    value: brandFontFamily,
    text: customBrandFont ? getFontName(customBrandFont) : brandFontFamily
  }] : [];
  const customOptions = _.map(customFonts, font => ({
    group: 'custom',
    value: font.font_id,
    text: getFontName(font)
  }));
  if (hasCustomFonts) {
    customOptions.push({
      group: 'custom',
      value: 'add_new_custom_font',
      text: '+ Add New Font'
    });
  }

  // Ignore recent fonts and brand fontFamily
  const defaultOptions = _.flatMap(DEFAULT_FONT_FAMILIES, fontFamily => !_.includes([brandFontFamily, ...recentFonts], fontFamily) ? [{
    group: 'default',
    value: fontFamily,
    text: fontFamily
  }] : []);
  return [...recentOptions, ...brandOptions, ...customOptions, ...defaultOptions];
}

// A recent font SHOULD NOT the brandFontFamily nor any of the custom fonts
// And it SHOULD BE one of the default fonts (ignore deleted custom fonts)
function isAllowedAsRecent(brandFontFamily, customFonts) {
  return recentFont => recentFont !== brandFontFamily && !_.find(customFonts, {
    font_id: recentFont
  }) && DEFAULT_FONT_FAMILIES.includes(recentFont);
}

// Styled components
const FontFamilyWrapper = styled.div`
	width: 100%;

	.MuiAutocomplete-input {
		font-family: ${({
  $fontFamily
}) => $fontFamily};
		text-align: ${({
  $textAlign
}) => $textAlign};
	}
`;
const Group = styled.div`
	&:not(:first-of-type) {
		padding-top: 8px;
	}

	&:not(:last-of-type) {
		padding-bottom: 8px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}
`;
const GroupTitle = styled.div`
	margin: ${({
  theme
}) => theme.spacing(1)};
	text-transform: uppercase;
	color: ${({
  theme
}) => theme.palette.primary.main};
	font-size: 11px;
`;