import autoBind from 'auto-bind'
import * as entities from 'entities'
import { immerable } from 'immer'
import { PREVIEW_HEIGHT, PREVIEW_WIDTH } from 'lib/constants'
import { extractConditionalFields, isUrl } from 'lib/utils'
import { isValidPhoneNumber } from 'libphonenumber-js'
import _ from 'lodash'
import isEmail from 'validator/lib/isEmail'

const default_redirections = ['next', 'none', 'back']
export class PageErrors {
	[immerable] = true

	constructor(page) {
		this._id = page._id
		this.type = page.type
		this.name = page.name
		this.errors = []
		this.recommendations = []
		autoBind(this)
	}

	hasError(updatedSnackeet, page) {
		this.hasBackgroundError(page)
		if (page.type !== 'ending_page' && !default_redirections.includes(page.next)) {
			this.pageRedirectionInvalid(page, updatedSnackeet)
		}

		if (page.type !== 'ending_page') {
			this.firstPageDisabled(page, updatedSnackeet)
		}

		if (page.on_enter && page.on_enter.enabled && page.on_enter.redirect !== 'next') {
			this.onEnterRedirectInvalid(page, updatedSnackeet)
		}
		if (page.type === 'ending_page') {
			this.endingPageRedirectButtonInvalid(page)
		}

		if (!_.isEmpty(page.blocks)) {
			this.hasBlockError(page, updatedSnackeet)
			this.hasMediaBlockRecommendation(page)
		}

		if (!_.isEmpty(page.carousels)) {
			this.hasCarouselError(page, updatedSnackeet)
		}

		if (!_.isEmpty(page.buttonLists)) {
			this.hasButtonListError(page, updatedSnackeet)
		}

		if (!_.isEmpty(page.tags)) {
			this.hasTagError(page)
		}

		if (page?.type !== 'amp_page' && page?.timer?.enabled && page?.background_element?.type === 'video') {
			this.hasIncorrespondTimer(page)
		}

		this.recommendChangePageName(page)

		if (!_.isEmpty(page.logics)) {
			this.hasLogicRedirectInvalid(page, updatedSnackeet)
			this.hasLogicFieldInvalid(page, updatedSnackeet)
		}

		if (page.type === 'multiple_choice') {
			if (page.answers_properties.type === 'answer') {
				if (
					updatedSnackeet.type === 'classic' &&
					page.answers_properties.count_in_results &&
					page.answers_properties.multiple_selection
				) {
					this.hasCorrectRedirectError(page, updatedSnackeet)
					this.hasIncorrectRedirectError(page, updatedSnackeet)
					if (page.answers_properties.partial_correct) {
						this.hasPartialRedirectError(page, updatedSnackeet)
					}
				} else {
					this.hasButtonRedirectionError(page, updatedSnackeet)
				}
			} else {
				this.hasActionButtonError(page)
			}
			this.recommendButtonsCount(page)
		} else if (page.type === 'form') {
			// if (updatedSnackeet.gdprConsent.active) {
			// 	this.recommendIncompleteConsentText(updatedSnackeet, page)
			// }

			this.formPageNextRedirectInvaild(page, updatedSnackeet)

			this.recommendFormFieldsCount(page)
		} else if (page.type === 'game') {
			this.hasEmptyURLField(page)
		}

		// For AMP only
		if (updatedSnackeet?.type === 'amp') {
			// Errors of outlink button configuration
			if (!_.isEmpty(page.amp_attachments)) {
				this.hasInvalidOutlink(page)
			}

			// Errors of AMP Interactions
			if (!_.isEmpty(page.amp_interactions)) {
				this.hasInvalidInteraction(page)
			}
		}

		return this
	}

	// /************************************* Errors list ******************************************************/

	// Page redirect (next) to another page_id but page not found
	pageRedirectionInvalid(page, updatedSnackeet) {
		const redirectionPage = _.find(updatedSnackeet.pages, (snackeetPage) => snackeetPage._id === page.next)
		if (!redirectionPage || redirectionPage?.disabled) {
			this.errors.push({
				type: '',
				message_id: 'error_messages.page.next',
				error_type: 'settings-next',
			})
		}
	}

	// First page is disabled
	firstPageDisabled(page, updatedSnackeet) {
		const pageIndex = _.findIndex(updatedSnackeet.pages, (snackeetPage) => snackeetPage._id === page._id)

		if (pageIndex === 0 && page.disabled) {
			this.errors.push({
				type: 'page',
				message_id: 'error_messages.page.first_page_disabled',
				error_type: 'first_page_disabled',
			})
		}
	}

	// Page on_enter is enabled but ortherwise redirect to page_id not found
	onEnterRedirectInvalid(page, updatedSnackeet) {
		const redirectionPage = _.find(
			updatedSnackeet.pages,
			(snackeetPage) => snackeetPage._id === page.on_enter.redirect
		)

		if (!redirectionPage || redirectionPage?.disabled) {
			this.errors.push({
				type: 'settings',
				message_id: 'error_messages.page.on_enter.redirect',
				error_type: 'settings-on_enter-redirect',
			})
		}
	}

	// Form Page
	// Form Page next button redirect to page_id not found
	formPageNextRedirectInvaild(page, updatedSnackeet) {
		const form = _.head(page.interactions)
		if (form.next_redirection && form.next_redirection !== 'next') {
			const redirectionPage = _.find(
				updatedSnackeet.pages,
				(snackeetPage) => snackeetPage._id === form.next_redirection
			)
			if (!redirectionPage || redirectionPage?.disabled) {
				this.errors.push({
					type: 'system_buttons_next',
					message_id: 'error_messages.form.next_button',
					error_type: 'form_next_redirection',
				})
			}
		}
	}

	// Ending Page Redirection button missing url
	endingPageRedirectButtonInvalid(page) {
		if (page?.redirection_button?.active) {
			if (_.isEmpty(page.redirection_button.url)) {
				this.errors.push({
					type: 'ending_page',
					message_id: 'error_messages.page.redirection_button',
					error_type: 'settings-redirection-button',
				})
			}
		}
	}

	// Background type media but missing url value
	hasBackgroundError(page) {
		if (page.background_element) {
			if (['video', 'image'].includes(page.background_element.type) && !page.background_element.value) {
				this.errors.push({
					type: 'background',
					message_id: `error_messages.background.${page.background_element.type}`,
				})
			}
		}
	}

	// Carousel item missing properties
	hasCarouselError(page, updatedSnackeet) {
		_.each(page.carousels, (carousel) => {
			const { _id, items, element_id } = carousel
			function CarouselError(message_id, item_id) {
				return {
					type: 'carousel',
					item_id,
					element_id: _id,
					message_id,
				}
			}
			// Check if carousel have the same element_id
			if (
				updatedSnackeet?.is_api_template &&
				element_id &&
				_.some(page.carousels, (b) => b.element_id === element_id && b._id !== carousel._id)
			) {
				this.errors.push(new CarouselError(`error_messages.carousel.api_template.name`))
			}

			_.each(items, (item) => {
				if (!item.image) {
					this.errors.push(new CarouselError(`error_messages.carousel.image`, item._id))
				}

				if (item?.click_options?.type === 'external') {
					const isUrlResult = isUrl(item?.click_options?.link, 'error_messages.carousel.external_link')
					if (!isUrlResult.success) {
						this.errors.push(new CarouselError(isUrlResult.messageId, item._id))
					}
				} else if (
					item?.click_options?.type === 'internal' &&
					!default_redirections.includes(item?.click_options?.link)
				) {
					const redirectPage = _.find(updatedSnackeet.pages, { _id: item?.click_options?.link })

					if (!redirectPage || redirectPage?.disabled) {
						this.errors.push(new CarouselError('error_messages.carousel.internal.nonexistent', item._id))
					}
				} else if (item?.click_options?.type === 'attachment') {
					if (!item?.click_options?.attachment?.hasOwnProperty('content')) {
						this.errors.push(new CarouselError('error_messages.carousel.attachment', item._id))
					}
				} else if (item?.click_options?.type === 'download') {
					if (_.isEmpty(item?.click_options.file)) {
						this.errors.push(new CarouselError('error_messages.carousel.download', item._id))
					}
				}
			})
		})
	}

	// ButtonList item missing properties
	hasButtonListError(page, updatedSnackeet) {
		_.each(page.buttonLists, (buttonList) => {
			const { _id, buttons, element_id } = buttonList
			function ButtonListError(message_id, button_id) {
				return {
					type: 'buttonList',
					button_id,
					element_id: _id,
					message_id,
				}
			}
			// Check if carousel have the same element_id
			if (
				updatedSnackeet?.is_api_template &&
				element_id &&
				_.some(page.buttonLists, (b) => b.element_id === element_id && b._id !== buttonList._id)
			) {
				this.errors.push(new ButtonListError(`error_messages.buttonList.api_template.name`))
			}

			_.each(buttons, (button) => {
				if (button?.click_options?.type === 'external') {
					const isUrlResult = isUrl(button?.click_options?.link, 'error_messages.buttonList.external_link')
					if (!isUrlResult.success) {
						this.errors.push(new ButtonListError(isUrlResult.messageId, button._id))
					}
				} else if (
					button?.click_options?.type === 'internal' &&
					!default_redirections.includes(button?.click_options?.link)
				) {
					const redirectPage = _.find(updatedSnackeet.pages, { _id: button?.click_options?.link })

					if (!redirectPage || redirectPage?.disabled) {
						this.errors.push(
							new ButtonListError('error_messages.buttonList.internal.nonexistent', button._id)
						)
					}
				} else if (button?.click_options?.type === 'attachment') {
					if (!button?.click_options?.attachment?.hasOwnProperty('content')) {
						this.errors.push(new ButtonListError('error_messages.buttonList.attachment', button._id))
					}
				} else if (button?.click_options?.type === 'download') {
					if (_.isEmpty(button?.click_options.file)) {
						this.errors.push(new ButtonListError('error_messages.buttonList.download', button._id))
					}
				} else if (button?.click_options?.type === 'mailto') {
					if (!isEmail(button?.click_options.link)) {
						this.errors.push(new ButtonListError('error_messages.buttonList.mailto.not_valid', button._id))
					}
				} else if (['phoneTo', 'messageTo'].includes(button?.click_options?.type)) {
					if (_.isEmpty(button?.click_options?.phoneNumber)) {
						this.errors.push(new ButtonListError('error_messages.buttonList.phoneNumber', button._id))
					}
				}
			})
		})
	}

	// Block type media but missing url value
	hasBlockError(page, updatedSnackeet) {
		_.each(page.blocks, (block) => {
			const { type, value, links = {}, networks = [], platforms = [], cta_options, element_id } = block
			function BlockError(message_id) {
				return {
					type: 'block',
					sub_type: type,
					element_id: block._id,
					message_id,
				}
			}

			// Check if blocks have the same element_id
			if (
				updatedSnackeet?.is_api_template &&
				element_id &&
				_.some(page.blocks, (b) => b.element_id === element_id && b._id !== block._id)
			) {
				this.errors.push(new BlockError(`error_messages.block.api_template.name`))
			}

			// Check if valid CTA
			if (cta_options?.enabled) {
				if (cta_options.tooltipEnabled && !cta_options.tooltip) {
					this.errors.push(new BlockError(`error_messages.block.cta.toolip`))
				}
				if (cta_options.redirectionType === 'external') {
					const isUrlResult = isUrl(`https://${cta_options.link}`, 'error_messages.block.cta')
					if (!isUrlResult.success) {
						this.errors.push(new BlockError(isUrlResult.messageId))
					}
				} else if (cta_options.redirectionType === 'mailto') {
					if (!isEmail(cta_options.link)) {
						this.errors.push(new BlockError('error_messages.block.cta.mailto'))
					}
				} else if (
					cta_options.redirectionType === 'internal' &&
					!default_redirections.includes(cta_options.link)
				) {
					// Check if page exists (important if page was deleted)
					const redirectPage = _.find(updatedSnackeet.pages, { _id: cta_options.link })

					if (!redirectPage || redirectPage?.disabled) {
						this.errors.push(new BlockError('error_messages.block.cta.internal.nonexistent'))
					}
				} else if (cta_options.redirectionType === 'attachment') {
					if (!cta_options.attachment?.hasOwnProperty('content')) {
						this.errors.push(new BlockError('error_messages.block.cta.attachment'))
					}
				} else if (cta_options.redirectionType === 'copyToClipboard') {
					if (!cta_options.copyValue) {
						this.errors.push(new BlockError('error_messages.block.cta.copyToClipboard'))
					}
				} else if (cta_options.redirectionType === 'download') {
					if (_.isEmpty(cta_options.file)) {
						this.errors.push(new BlockError('error_messages.block.cta.download'))
					}
				}
			}

			if (type === 'social-network') {
				_.each(networks, (network) => {
					const lowerName = network.toLowerCase()
					if (links[lowerName] === '' || !links[lowerName]) {
						this.errors.push(new BlockError(`error_messages.block.social_network`))
					}
				})
			} else if (type === 'messenger') {
				_.each(platforms, (platform) => {
					if (
						_.includes(['Phonecall', 'SMS', 'Whatsapp'], platform.name) &&
						!platform.properties_fields?.number
					) {
						this.errors.push(new BlockError(`error_messages.block.messenger.number`))
					} else if (
						_.includes(['Skype', 'Telegram'], platform.name) &&
						!platform.properties_fields?.user_id
					) {
						this.errors.push(new BlockError(`error_messages.block.messenger.user_id`))
					} else if (platform.name === 'Messenger' && !platform.properties_fields?.link) {
						this.errors.push(new BlockError(`error_messages.block.messenger.link`))
					} else if (platform.name === 'Mail' && !platform.properties_fields?.mail) {
						this.errors.push(new BlockError(`error_messages.block.messenger.mail`))
					}
				})
			} else if (type === 'networks') {
				_.each(platforms, (platform) => {
					if (
						_.includes(['Phonecall', 'SMS', 'Whatsapp'], platform.name) &&
						!isValidPhoneNumber(platform.properties_fields?.number || '')
					) {
						this.errors.push(new BlockError(`error_messages.block.networks.number`))
					} else if (
						_.includes(['Skype', 'Telegram'], platform.name) &&
						!platform.properties_fields?.user_id
					) {
						this.errors.push(new BlockError(`error_messages.block.networks.user_id`))
					} else if (
						_.includes(
							[
								'Messenger',
								'Facebook',
								'LinkedIn',
								'Twitter',
								'Pinterest',
								'Instagram',
								'YouTube',
								'TikTok',
								'Discord',
								'Github',
								'Website',
							],
							platform?.name
						) &&
						!isUrl(platform.properties_fields?.link)?.success
					) {
						this.errors.push(new BlockError(`error_messages.block.networks.link`))
					} else if (platform.name === 'Mail' && !isEmail(platform.properties_fields?.mail || '')) {
						this.errors.push(new BlockError(`error_messages.block.networks.mail`))
					}
				})
			} else if (['video', 'image', 'sticker', 'calendar', 'text'].includes(type)) {
				const contentValue = value?.replace(/(<([^>]+)>)/gi, '') // To remove all tag from text block and detect if the value is null
				if (!contentValue) {
					this.errors.push(new BlockError(`error_messages.block.${type}`))
				}
			} else if (['location', 'iframe'].includes(type) && !value) {
				this.errors.push(new BlockError(`error_messages.block.${type}`))
			}
		})
	}

	// media blocks recommendations
	hasMediaBlockRecommendation(page) {
		_.each(page.blocks, (block) => {
			const { type, outer_style } = block
			function BlockRecommendation(message_id) {
				return {
					type: 'block',
					sub_type: type,
					element_id: block._id,
					message_id,
				}
			}

			if (
				['image', 'video'].includes(type) &&
				!validateMediaBlockSize({ height: outer_style?.height || 0, width: outer_style?.width || 0 })
			) {
				this.recommendations.push(new BlockRecommendation(`error_messages.block.${type}_size`))
			}
		})
	}

	// Tag missing title
	hasTagError(page) {
		_.map(page.tags, (tag) => {
			const { title, url } = tag.description
			if (!title) {
				this.errors.push({
					type: 'tag',
					element_id: tag._id,
					message_id: 'error_messages.tag.title',
					error_type: 'tag-title',
				})
			}
		})
	}

	// timer incorrespond with background video
	hasIncorrespondTimer(page) {
		const timerCount = parseFloat(page?.timer?.value)
		const backgroundVideoDuration =
			(parseFloat(page?.background_element?.props?.end) - parseFloat(page?.background_element?.props?.start)) /
			parseFloat(page?.background_element?.props?.speed || 1)

		if (_.floor(timerCount, 1) !== _.floor(backgroundVideoDuration, 1)) {
			this.recommendations.push({
				type: 'background',
				message_id: 'error_messages.timer.background_video',
				error_type: 'timer-background',
			})
		}
	}

	// Multiple_choice page

	// Button redirection to page_id but page not found
	hasButtonRedirectionError(page, updatedSnackeet) {
		_.map(page.answers, (answer) => {
			const { payload } = answer
			if (payload.next_page !== 'next') {
				const redirectionPage = _.find(updatedSnackeet.pages, (page) => page._id === payload.next_page)
				if (!redirectionPage || redirectionPage?.disabled) {
					this.errors.push({
						type: 'answer-button',
						message_id: 'error_messages.button.redirection',
					})
				}
			}
		})
	}

	// Quizz answers page
	hasCorrectRedirectError(page, updatedSnackeet) {
		if (page.answers_properties.correct_redirect !== 'next') {
			const redirectionPage = _.find(
				updatedSnackeet.pages,
				(p) => p._id === page.answers_properties.correct_redirect
			)
			if (!redirectionPage || redirectionPage?.disabled) {
				this.errors.push({
					type: 'answer-button',
					message_id: 'error_messages.button.quiz.correct_redirect',
				})
			}
		}
	}

	hasIncorrectRedirectError(page, updatedSnackeet) {
		if (page.answers_properties.incorrect_redirect !== 'next') {
			const redirectionPage = _.find(
				updatedSnackeet.pages,
				(p) => p._id === page.answers_properties.incorrect_redirect
			)
			if (!redirectionPage || redirectionPage?.disabled) {
				this.errors.push({
					type: 'answer-button',
					message_id: 'error_messages.button.quiz.incorrect_redirect',
				})
			}
		}
	}

	hasPartialRedirectError(page, updatedSnackeet) {
		if (page.answers_properties.partial_redirect !== 'next') {
			const redirectionPage = _.find(
				updatedSnackeet.pages,
				(p) => p._id === page.answers_properties.partial_redirect
			)
			if (!redirectionPage || redirectionPage?.disabled) {
				this.errors.push({
					type: 'answer-button',
					message_id: 'error_messages.button.quiz.partial_redirect',
				})
			}
		}
	}

	// Action buttons, link missing url or file downloader missing file
	hasActionButtonError(page) {
		_.map(page.answers, (answer) => {
			const { payload, type } = answer
			if (type === 'link' && !payload.web_url) {
				this.errors.push({
					type: 'action-button',
					button_id: answer._id,
					message_id: 'error_messages.button.link',
					error_type: 'button-url',
				})
			} else if (type === 'file_downloader' && _.isEmpty(payload.file)) {
				this.errors.push({
					type: 'action-button',
					button_id: answer._id,
					message_id: 'error_messages.button.file_downloader',
					error_type: 'button-file',
				})
			}
		})
	}

	// Game page

	hasEmptyURLField(page) {
		_.forEach(page.modal, (value, key) => {
			if (value?.cta && value?.cta.isActive && _.isEmpty(value?.cta.url)) {
				this.errors.push({
					type: 'game',
					sub_type: 'wheel',
					page_id: page._id,
					message_id: 'error_messages.game.wheel.url_empty',
					error_type: 'url-empty',
					component: key,
				})
			}
		})
	}

	// Page Logics

	// Check consequence redirection page_id
	hasLogicRedirectInvalid(page, updatedSnackeet) {
		for (const logic of page.logics) {
			if (_.some(logic.consequences, (conq) => conq?.condition?.value === 'redirect')) {
				const redirect_consequence = _.find(logic.consequences, (conq) => conq?.condition?.value === 'redirect')
				const redirectPageId = redirect_consequence.incrementValue

				if (redirectPageId !== 'next') {
					const redirectionPage = _.find(
						updatedSnackeet.pages,
						(snackeetPage) => snackeetPage._id === redirectPageId
					)
					if (!redirectionPage || redirectionPage?.disabled) {
						this.errors.push({
							type: 'settings',
							message_id: 'error_messages.page.logics.consequence_redirect',
							error_type: 'settings-logics-redirect',
						})
					}
				}
			}
		}
	}

	// Check for each logic in page
	hasLogicFieldInvalid(page, updatedSnackeet) {
		const conditionalFields = extractConditionalFields(updatedSnackeet, page)
		for (const logic of page.logics) {
			if (logic.cond_header === 'if') {
				const status = validateConditionField(logic?.conditions?.rules, conditionalFields)
				if (status > 0) {
					this.errors.push({
						type: 'settings',
						message_id: 'error_messages.page.logics.conditions_field',
						error_type: 'settings-logics-conditions_field',
					})
					break
				} else {
					const valStatus = validateConditionValue(logic?.conditions?.rules, conditionalFields)
					if (valStatus > 0) {
						this.errors.push({
							type: 'settings',
							message_id: 'error_messages.page.logics.conditions_value',
							error_type: 'settings-logics-conditions_value',
						})
						break
					}
				}
			}
		}
	}

	// --------------------------- AMP Stories -----------------------------

	// Outlink buttons
	// Outlink button with an invalid link
	hasInvalidOutlink(page) {
		const outlink = _.find(page.amp_attachments, ['type', 'outlink'])

		if (outlink && !isUrl(outlink.link)?.success) {
			this.errors.push({
				type: 'outlink',
				sub_type: 'link',
				page_id: page._id,
				element_id: outlink._id,
				message_id: 'error_messages.amp_attachments.outlink.link',
				error_type: 'amp_outlink-link-invalid',
			})
		}

		if (outlink && _.isEmpty(outlink.name)) {
			this.errors.push({
				type: 'outlink',
				sub_type: 'title',
				page_id: page._id,
				element_id: outlink._id,
				message_id: 'error_messages.amp_attachments.outlink.title',
				error_type: 'amp_outlink-title-invalid',
			})
		}
	}

	// Invalid Amp Interaction
	hasInvalidInteraction(page) {
		const amp_interaction = _.first(page.amp_interactions)

		// 2 cases with images or text only
		// text only
		if (_.includes(['amp_poll', 'amp_binary_poll', 'amp_quiz'], amp_interaction.type)) {
			_.map(amp_interaction.options, (option) => {
				if (_.isEmpty(option.title)) {
					this.errors.push({
						type: 'amp_interaction',
						sub_type: 'title',
						page_id: page._id,
						element_id: amp_interaction._id,
						element_type: amp_interaction.type,
						message_id: 'error_messages.amp_interactions.title',
						error_type: 'amp_interaction-title-invalid',
					})
				}

				return option
			})
		} else if (_.includes(['amp_image_poll', 'amp_image_quiz'], amp_interaction.type)) {
			_.map(amp_interaction.options, (option) => {
				if (_.isEmpty(option.imageUrl)) {
					this.errors.push({
						type: 'amp_interaction',
						sub_type: 'image',
						page_id: page._id,
						element_id: amp_interaction._id,
						element_type: amp_interaction.type,
						message_id: 'error_messages.amp_interactions.image',
						error_type: 'amp_interaction-image-invalid',
					})
				}
				if (_.isEmpty(option.title)) {
					this.errors.push({
						type: 'amp_interaction',
						sub_type: 'image_title',
						page_id: page._id,
						element_id: amp_interaction._id,
						element_type: amp_interaction.type,
						message_id: 'error_messages.amp_interactions.image_title',
						error_type: 'amp_interaction-image_title-invalid',
					})
				}

				return option
			})
		}
	}

	// /************************************* Recommendations list ******************************************************/

	// Page name not changed
	recommendChangePageName(page) {
		if (page.name === 'New Page' || _.includes(page.name, 'Copy')) {
			this.recommendations.push({
				type: 'page',
				page_id: page._id,
				message_id: 'error_messages.page.name',
				error_type: 'page-name',
			})
		}
	}

	// Too much answer buttons in a page
	recommendButtonsCount(page) {
		if (page.answers.length > 6) {
			this.recommendations.push({
				type: 'buttons',
				page_id: page._id,
				message_id: 'error_messages.page.answers_count',
				error_type: 'answers-count',
			})
		}
	}

	// Too much fields in a form
	recommendFormFieldsCount(page) {
		const formInteraction = _.head(page.interactions)
		if (formInteraction?.fields.length > 5) {
			this.recommendations.push({
				type: 'form',
				form_id: formInteraction._id,
				message_id: 'error_messages.page.fields_count',
				recommend_type: 'fields-count',
			})
		}
	}

	build() {
		if (!_.isEmpty(this.errors) || !_.isEmpty(this.recommendations)) {
			return this
		}
	}
}

function convertTextFromHtml(value) {
	return entities.decodeXML(
		value
			.replace(/(<([^>]+)>)/gi, '') // Remove tags
			.replace(' ', '') // Remove spaces
	)
}

function validateConditionField(rules, extractConditionalFields) {
	let result = 0

	for (const rule of rules) {
		if (rule.rules && !_.isEmpty(rule.rules)) {
			result = validateConditionField(rule.rules, extractConditionalFields)
		} else if (!_.some(extractConditionalFields, ['name', rule.field])) {
			result = 1
			break
		}
	}

	return result
}

function validateConditionValue(rules, extractConditionalFields) {
	let result = 0

	for (const rule of rules) {
		if (rule.rules && !_.isEmpty(rule.rules)) {
			result = validateConditionValue(rule.rules, extractConditionalFields)
		} else if (
			rule?.value &&
			!['var_greatest', 'var_smallest', '=', '!=', '<', '>', '<=', '>='].includes(rule?.operator)
		) {
			const field = _.find(extractConditionalFields, ['name', rule.field])

			if (field && !_.isEmpty(field?.values) && !_.some(field.values, ['name', rule.value])) {
				result = 1
				break
			}
		}
	}

	return result
}

function validateMediaBlockSize({ height, width }) {
	const heightValue = parseInt(height)
	const widthValue = parseInt(width)

	if (heightValue >= PREVIEW_HEIGHT * 0.9 && widthValue >= PREVIEW_WIDTH * 0.9) {
		return false
	}

	return true
}
