import { Cancel, CheckBox, IndeterminateCheckBox } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import styled from '@styled';
export const Loader = styled(CircularProgress)`
	width: 20px !important;
	height: 20px !important;
	display: flex;
	margin-left: 8px;
`;
export function RedirectionStatusIcon({
  status
}) {
  switch (status) {
    case 'unset':
      return <IndeterminateCheckBox color='primary' />;
    case 'valid':
      return <CheckBox color='primary' />;
    case 'invalid':
      return <Cancel color='error' />;
    default:
      return null;
  }
}