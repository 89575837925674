import * as React from 'react'

function Svg7Number(props) {
	return (
		<svg width='1em' height='1em' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path d='M3.424 1.04L1.657 5H1.09l1.76-3.82H.542V.734h2.883v.305z' fill='#000' />
		</svg>
	)
}

export default Svg7Number
