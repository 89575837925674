import { Box, Skeleton, Typography } from '@mui/material';
import styled from '@styled';
import { getRealMediaDimension, sleep } from 'lib/utils';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import Cropper from 'react-easy-crop';
import useAsyncEffect from 'use-async-effect';
import { useDebouncedCallback } from 'use-debounce';
import { useImmer } from 'use-immer';
import Slider from './Sliders/Slider';
export const CROPZONE_EDGE = 220;
export const CROPSIZE_EDGE_MAX = 190;
export default function MediaReframe({
  type = 'image',
  url,
  blockWidth = 359,
  blockHeight = 630,
  onReframe,
  zoom = 1,
  crop = {
    x: 0,
    y: 0
  }
}) {
  const [frameState, setFrameState] = useImmer({
    width: blockHeight,
    height: blockHeight,
    ratio: 1,
    isLoading: true,
    isError: false,
    crop,
    zoom
  });
  const delayedOnReframe = useDebouncedCallback(onReframe, 1000);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    delayedOnReframe({
      crop: frameState.crop,
      zoom: frameState.zoom,
      croppedArea
    });
  }, [frameState.crop, frameState.zoom, delayedOnReframe]);
  const onZoomChange = useCallback(value => {
    setFrameState(draft => {
      draft.zoom = value;
    });
    delayedOnReframe({
      crop: frameState.crop,
      zoom: value
    });
  }, [frameState.crop, delayedOnReframe, setFrameState]);
  useAsyncEffect(async isMounted => {
    // Check if private organization is appsumo Organization
    setFrameState(draft => {
      draft.isLoading = true;
    });
    const {
      height,
      width,
      ratio,
      isError
    } = await initFrame({
      url,
      type,
      blockWidth,
      blockHeight
    });
    if (isMounted()) {
      setFrameState(draft => {
        draft.width = width;
        draft.height = height;
        draft.ratio = ratio;
        draft.isError = isError;
        draft.isLoading = false;
      });
    }
  }, [url, blockWidth, blockHeight]);
  if (frameState.isError) return null;
  if (frameState.isLoading) {
    return <MediaReframeLoader />;
  }
  return <Wrapper>
			<div className='crop-container'>
				<Cropper video={type === 'video' ? url : ''} image={type === 'image' ? url : ''} crop={frameState.crop} zoom={frameState.ratio * frameState.zoom} cropSize={{
        width: frameState.width,
        height: frameState.height
      }} onCropChange={value => setFrameState(draft => {
        draft.crop = value;
      })} onCropComplete={onCropComplete} zoomWithScroll={false} style={{
        cropAreaStyle: {
          border: '1px solid #F29938',
          color: 'rgba(0, 0, 0, 0.5)',
          borderRadius: '4px'
        }
      }} />
			</div>
			<div className='controls'>
				<Typography className='controls-title' variant='body1'>
					<b>Zoom</b>
				</Typography>
				<Slider initial_value={frameState.zoom * 100} maxValue={400} minValue={100} inputStep={1} onUpdate={value => {
        onZoomChange(value / 100);
      }} units={'%'} />
			</div>
		</Wrapper>;
}
MediaReframe.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
  blockWidth: PropTypes.number,
  blockHeight: PropTypes.number,
  onReframe: PropTypes.func,
  zoom: PropTypes.number,
  crop: PropTypes.object
};
export function MediaReframeLoader() {
  return <Wrapper>
			<Box position='absolute' top={0} right={34}>
				<Skeleton variant='rectangular' width={CROPZONE_EDGE} height={CROPZONE_EDGE} />
			</Box>
			<div className='controls'>
				<Skeleton variant='rectangular' width={180} height={30} />
			</div>
		</Wrapper>;
}

// Helper functions

// This function initializes the frame by calculating the height, width, and ratio
// for a given media element based on block dimensions and cropping rules.
async function initFrame({
  url,
  type,
  blockHeight,
  blockWidth
}) {
  // Retrieve the height and width of the media element from the provided URL and type.
  const {
    height,
    width
  } = await getRealMediaDimension({
    type,
    url
  });

  // console.log('Inputs', {
  // 	width,
  // 	height,
  // 	blockWidth,
  // 	blockHeight,
  // })

  // Sleep to delay the rendering of MediaReframe to avoid rerender preview
  // which causes flashing effect
  await sleep(300);

  // If the height or width is not available, return default values with isError flag set to true.
  if (!height || !width) {
    return {
      height: CROPSIZE_EDGE_MAX,
      width: CROPSIZE_EDGE_MAX,
      ratio: CROPSIZE_EDGE_MAX / CROPZONE_EDGE,
      isError: true
    };
  }

  // Calculate the aspect ratio of the media element.
  const mediaAspectRatio = height / width;

  // Determine the block orientation (portrait or landscape) and media aspect ratio.

  // Case 1: Portrait Block
  if (blockHeight > blockWidth) {
    if (mediaAspectRatio > 1) {
      // Case 1.1: Media is Portrait
      // Calculate crop dimensions for portrait media with portrait block.
      const cropWidth = blockWidth * (CROPSIZE_EDGE_MAX / blockHeight);
      const cropRatio = CROPSIZE_EDGE_MAX / cropWidth;
      if (cropRatio > mediaAspectRatio) {
        // console.log('case 1-1')
        // If the crop ratio is greater than media aspect ratio, adjust width.
        if (width < CROPSIZE_EDGE_MAX && mediaAspectRatio >= 2.2) {
          return {
            height,
            width: blockWidth * (height / blockHeight),
            ratio: 1,
            isError: false
          };
        }
        if (height < CROPSIZE_EDGE_MAX) {
          return {
            height: CROPSIZE_EDGE_MAX,
            width: cropWidth,
            ratio: CROPSIZE_EDGE_MAX / blockHeight * (blockHeight / height),
            isError: false
          };
        }
        return {
          height: CROPSIZE_EDGE_MAX,
          width: cropWidth,
          ratio: CROPSIZE_EDGE_MAX / CROPZONE_EDGE,
          isError: false
        };
      } else {
        // console.log('case 1-2')
        // If the crop ratio is less than or equal to media aspect ratio, adjust height
        if (width < CROPSIZE_EDGE_MAX && mediaAspectRatio >= 2.18) {
          return {
            height: blockHeight * (width / blockWidth),
            width,
            ratio: 1,
            isError: false
          };
        }
        return {
          height: CROPSIZE_EDGE_MAX,
          width: cropWidth,
          ratio: cropWidth / (CROPZONE_EDGE / mediaAspectRatio),
          isError: false
        };
      }
    } else {
      // console.log('case 1-3')
      // Case 1.2: Media is Landscape
      if (height < CROPSIZE_EDGE_MAX && height < blockHeight) {
        // If media height is smaller than CROPSIZE_EDGE_MAX and block height,
        // adjust the width while maintaining the aspect ratio.
        return {
          height,
          width: CROPSIZE_EDGE_MAX * (height / blockHeight),
          ratio: 1,
          isError: false
        };
      }
      return {
        height: CROPSIZE_EDGE_MAX,
        width: blockWidth * (CROPSIZE_EDGE_MAX / blockHeight),
        ratio: CROPSIZE_EDGE_MAX / (CROPZONE_EDGE * mediaAspectRatio),
        isError: false
      };
    }
  }
  // Case 2: Landscape Block
  else if (blockHeight < blockWidth) {
    if (mediaAspectRatio < 1) {
      // Case 2.1: Media is Portrait
      // Calculate crop dimensions for portrait media with landscape block.
      const cropHeight = blockHeight * (CROPSIZE_EDGE_MAX / blockWidth);
      const cropRatio = cropHeight / CROPSIZE_EDGE_MAX;
      if (cropRatio > mediaAspectRatio) {
        // console.log('case 2-1')
        // If the crop ratio is greater than media aspect ratio, adjust height.
        return {
          height: cropHeight,
          width: CROPSIZE_EDGE_MAX,
          ratio: cropHeight / (CROPZONE_EDGE * mediaAspectRatio),
          isError: false
        };
      } else {
        // console.log('case 2-2')
        // If the crop ratio is less than or equal to media aspect ratio, set width.
        return {
          height: cropHeight,
          width: CROPSIZE_EDGE_MAX,
          ratio: CROPSIZE_EDGE_MAX / CROPZONE_EDGE,
          isError: false
        };
      }
    } else {
      // Case 2.2: Media is Landscape
      // console.log('case 2-3')
      if (width < CROPSIZE_EDGE_MAX) {
        // If media width is smaller than CROPSIZE_EDGE_MAX, adjust height while maintaining aspect ratio.
        return {
          height: blockHeight * (width / blockWidth),
          width,
          ratio: 1,
          isError: false
        };
      }
      return {
        height: blockHeight * (CROPSIZE_EDGE_MAX / blockWidth),
        width: CROPSIZE_EDGE_MAX,
        ratio: CROPSIZE_EDGE_MAX / (CROPZONE_EDGE / mediaAspectRatio),
        isError: false
      };
    }
  }
  // Case 3: Square Block
  else {
    if (mediaAspectRatio > 1) {
      // console.log('case 3-1')
      // Case 3.1: Media is Portrait
      if (width < CROPSIZE_EDGE_MAX) {
        // If media width is smaller than CROPSIZE_EDGE_MAX, adjust both height and width.
        return {
          height: CROPSIZE_EDGE_MAX,
          width: CROPSIZE_EDGE_MAX,
          ratio: CROPSIZE_EDGE_MAX / width,
          isError: false
        };
      }
      return {
        height: CROPSIZE_EDGE_MAX,
        width: CROPSIZE_EDGE_MAX,
        ratio: CROPSIZE_EDGE_MAX / (CROPZONE_EDGE / mediaAspectRatio),
        isError: false
      };
    } else {
      // console.log('case 3-2')
      // Case 3.2: Media is Landscape or Square
      if (height < CROPSIZE_EDGE_MAX) {
        // If media height is smaller than CROPSIZE_EDGE_MAX, adjust both height and width.
        return {
          height: CROPSIZE_EDGE_MAX,
          width: CROPSIZE_EDGE_MAX,
          ratio: CROPSIZE_EDGE_MAX / height,
          isError: false
        };
      }
      return {
        height: CROPSIZE_EDGE_MAX,
        width: CROPSIZE_EDGE_MAX,
        ratio: CROPSIZE_EDGE_MAX / CROPZONE_EDGE,
        isError: false
      };
    }
  }
}

// Styled components
const Wrapper = styled.div`
	width: 100%;

	.crop-container {
		position: absolute;
		top: 0;
		right: 50px;
		width: ${CROPZONE_EDGE}px;
		height: ${CROPZONE_EDGE}px;
		background-color: #000;
	}

	.controls {
		position: absolute;
		bottom: 10px;
		left: 50%;
		width: 60%;
		transform: translateX(-50%);
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.controls-title {
		margin-right: 8px;
	}
`;