import axios from 'axios'
import { API_URL } from 'lib/constants'
import * as localStorageSvc from 'services/LocalStorageSvc'

const TemplatesSvc = {
	// PageTemplates
	savePageAsTemplate,
	getPageTemplates,
	updatePageTemplate,
	removePageTemplate,

	// StoryTemplates
	saveStoryAsTemplate,
	getStoryTemplates,
	updateStoryTemplate,
	removeStoryTemplate,

	// AttachmentTemplates
	saveAttachmentAsTemplate,
	getAttachmentTemplates,
	updateAttachmentTemplate,
	removeAttachmentTemplate,
}

export default TemplatesSvc

// Page Templates
async function savePageAsTemplate({ page, name, accessibleProjects, project, organization_id }) {
	const { data } = await axios.post(
		`${API_URL}/templates/pages`,
		{ page, accessibleProjects, name },
		{
			params: {
				organization_id,
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		}
	)

	return data
}

async function getPageTemplates({ project, organization_id }) {
	const { data } = await axios.get(`${API_URL}/templates/pages`, {
		params: {
			organization_id,
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})

	return data.pageTemplates || []
}

async function updatePageTemplate({ project, organization_id, pageTemplate }) {
	const { data } = await axios.patch(`${API_URL}/templates/pages/update`, pageTemplate, {
		params: {
			organization_id,
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})

	return data
}

async function removePageTemplate({ project, organization_id, pageTemplate_id }) {
	const { data } = await axios.delete(`${API_URL}/templates/pages/${pageTemplate_id}`, {
		params: {
			organization_id,
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})

	return data
}

// Story Templates
async function saveStoryAsTemplate({ story, name, accessibleProjects, project, organization_id }) {
	const { data } = await axios.post(
		`${API_URL}/templates/stories`,
		{ story, accessibleProjects, name },
		{
			params: {
				organization_id,
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		}
	)

	return data
}

async function getStoryTemplates({ project, organization_id }) {
	const { data } = await axios.get(`${API_URL}/templates/stories`, {
		params: {
			organization_id,
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})

	return data.storyTemplates || []
}

async function updateStoryTemplate({ project, organization_id, storyTemplate }) {
	const { data } = await axios.patch(`${API_URL}/templates/stories/update`, storyTemplate, {
		params: {
			organization_id,
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})

	return data
}

async function removeStoryTemplate({ project, organization_id, storyTemplate_id }) {
	const { data } = await axios.delete(`${API_URL}/templates/stories/${storyTemplate_id}`, {
		params: {
			organization_id,
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})

	return data
}

// Attachment Templates
async function saveAttachmentAsTemplate({ attachment, name, accessibleProjects, project, organization_id }) {
	const { data } = await axios.post(
		`${API_URL}/templates/attachments`,
		{ attachment, accessibleProjects, name },
		{
			params: {
				organization_id,
				project,
			},
			headers: {
				authorization: `JWT ${localStorageSvc.getLocalToken()}`,
			},
		}
	)

	return data
}

async function getAttachmentTemplates({ project, organization_id }) {
	const { data } = await axios.get(`${API_URL}/templates/attachments`, {
		params: {
			organization_id,
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})

	return data.attachmentTemplates || []
}

async function updateAttachmentTemplate({ project, organization_id, attachmentTemplate }) {
	const { data } = await axios.patch(`${API_URL}/templates/attachments/update`, attachmentTemplate, {
		params: {
			organization_id,
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})

	return data
}

async function removeAttachmentTemplate({ project, organization_id, attachmentTemplate_id }) {
	const { data } = await axios.delete(`${API_URL}/templates/attachments/${attachmentTemplate_id}`, {
		params: {
			organization_id,
			project,
		},
		headers: {
			authorization: `JWT ${localStorageSvc.getLocalToken()}`,
		},
	})

	return data
}
