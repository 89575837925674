import { TrashIcon } from '@heroicons/react/24/outline';
import { Check } from '@mui/icons-material';
import { Box, Button, DialogActions, IconButton, SvgIcon, TextField, Tooltip, Typography } from '@mui/material';
import { HotspotIcon } from 'components/icons/custom';
import { ChipVariable, DefaultVariable, ModalContent } from 'components/styled/Modals';
import { LEADERBOARD_VARIABLES, VARIABLE_TYPE_OPTIONS, VARIABLES_TYPES_COLORS } from 'lib/constants';
import { useStoryState } from 'lib/editorStore';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalWrapper } from '../ModalWrapper';
const VariablesModal = forwardRef(({
  currentVariables,
  currentVariable,
  allowType,
  assignVariable,
  removeVariable,
  addNewVariable,
  newVariable,
  handleChangeNewVariableName,
  handleChangeNewVariableType,
  fieldError,
  onClose
}, ref) => {
  const {
    pages,
    type
  } = useStoryState(s => s.draftStory);
  const createVariableRef = useRef();
  const handleOpen = () => {
    createVariableRef.current.open();
  };
  return <>
				<ModalWrapper size='md' ref={ref} title={<FormattedMessage id='modals.variables.header' values={{
      count: 1
    }} />} onClose={onClose} hasCloseButton={false} disableBackdropClick>
					<ModalContent>
						{allowType && <Typography variant='h3' gutterBottom ml={1} component='div'>
								<FormattedMessage id='modals.variables.allowType.head' />{' '}
								<ChipVariable size='small' label={<Typography variant='body2'>
											<FormattedMessage id={`edition_menu.form.field_type.${allowType}`} />
										</Typography>} $bgColor={VARIABLES_TYPES_COLORS[allowType]} />{' '}
								<FormattedMessage id='modals.variables.allowType.foot' />
							</Typography>}

						<div className='variables-container'>
							{type === 'classic' && <Box mb={2}>
									<Typography variant='h2' className='variables-title'>
										<FormattedMessage id='modals.variables.leaderboard.subtitle' />
									</Typography>

									<div className='sub-container'>
										{currentVariables.filter(variable => variable.default && _.includes(LEADERBOARD_VARIABLES, variable.name)).map(variable => <DefaultVariable key={variable.name} onClick={() => assignVariable(variable.name)} disabled={variable.type !== allowType}>
													<Typography variant='body2'>{variable.name}</Typography>
													<Box textAlign='center'>
														<ChipVariable size='small' label={<Typography variant='body2'>
																	<FormattedMessage id={`edition_menu.form.field_type.${variable.type}`} />
																</Typography>} $bgColor={VARIABLES_TYPES_COLORS[variable.type]} />
													</Box>

													{variable.isUsed && <Box display='flex' alignItems='center' justifyContent='flex-end'>
															{variable.name === currentVariable ? <Typography variant='body2'>
																	<FormattedMessage id='modals.variables.assigned_here' />
																</Typography> : <Typography variant='body2'>
																	<FormattedMessage id='modals.variables.assigned' />
																</Typography>}
															<Tooltip title={<TrackingDetails trackingDatas={variable.trackingDatas} pages={pages} />}>
																<Box display='flex' justifyContent='center' alignItems='center'>
																	<HotspotIcon fontSize='inherit' sx={{
                        ml: 1
                      }} />
																</Box>
															</Tooltip>
														</Box>}
												</DefaultVariable>)}
									</div>
								</Box>}

							<Box>
								<Typography variant='h2' className='variables-title'>
									<FormattedMessage id='modals.variables.default.subtitle' />
								</Typography>

								<div className='sub-container'>
									{currentVariables.filter(variable => variable.default && !_.includes(LEADERBOARD_VARIABLES, variable.name)).map(variable => <DefaultVariable key={variable.name} onClick={() => assignVariable(variable.name)} disabled={variable.type !== allowType}>
												<Typography variant='body2'>{variable.name}</Typography>
												<Box textAlign='center'>
													<ChipVariable size='small' label={<Typography variant='body2'>
																<FormattedMessage id={`edition_menu.form.field_type.${variable.type}`} />
															</Typography>} $bgColor={VARIABLES_TYPES_COLORS[variable.type]} />
												</Box>

												{variable.isUsed && <Box display='flex' alignItems='center' justifyContent='flex-end'>
														{variable.name === currentVariable ? <Typography variant='body2'>
																<FormattedMessage id='modals.variables.assigned_here' />
															</Typography> : <Typography variant='body2'>
																<FormattedMessage id='modals.variables.assigned' />
															</Typography>}
														<Tooltip title={<TrackingDetails trackingDatas={variable.trackingDatas} pages={pages} />}>
															<Box display='flex' justifyContent='center' alignItems='center'>
																<HotspotIcon fontSize='inherit' sx={{
                        ml: 1
                      }} />
															</Box>
														</Tooltip>
													</Box>}
											</DefaultVariable>)}
								</div>
							</Box>

							<Box>
								<Box display='flex' alignItems='center' marginY='10px'>
									<Typography variant='h2' className='variables-title variable-perso'>
										<FormattedMessage id='modals.variables.custom.title' />
									</Typography>

									<Button variant='contained' color='primary' size='small' onClick={handleOpen}>
										<FormattedMessage id='modals.variables.custom.create' />
									</Button>
								</Box>

								<div className='sub-container'>
									{currentVariables.filter(variable => !variable.default).map(variable => <DefaultVariable key={variable.name} onClick={() => assignVariable(variable.name)} disabled={variable.type !== allowType}>
												<Typography variant='body2'>{variable.name}</Typography>
												<Box textAlign='center'>
													<ChipVariable size='small' label={<Typography variant='body2'>
																<FormattedMessage id={`edition_menu.form.field_type.${variable.type}`} />
															</Typography>} $bgColor={VARIABLES_TYPES_COLORS[variable.type]} />
												</Box>
												{variable.isUsed ? <Box display='flex' alignItems='center' justifyContent='flex-end'>
														{variable.name === currentVariable ? <Typography variant='body2'>
																<FormattedMessage id='modals.variables.assigned_here' />
															</Typography> : <Typography variant='body2'>
																<FormattedMessage id='modals.variables.assigned' />
															</Typography>}
														<Tooltip title={<TrackingDetails trackingDatas={variable.trackingDatas} pages={pages} />}>
															<Box display='flex' justifyContent='center' alignItems='center'>
																<HotspotIcon fontSize='inherit' sx={{
                        ml: 1
                      }} />
															</Box>
														</Tooltip>
													</Box> : <Box display='flex' alignItems='center' justifyContent='flex-end' style={{
                  pointerEvents: 'auto'
                }}>
														<IconButton size='small' onClick={evt => {
                    evt.preventDefault();
                    evt.stopPropagation();
                    removeVariable(variable.name);
                  }}>
															<SvgIcon>
																<TrashIcon />
															</SvgIcon>
														</IconButton>
													</Box>}
											</DefaultVariable>)}
								</div>
							</Box>
						</div>
					</ModalContent>
					<DialogActions>
						<Button variant='contained' color='secondary' onClick={() => {
          ref.current.close();
        }}>
							<FormattedMessage id='common.theming.confirm_button' />
						</Button>
					</DialogActions>
				</ModalWrapper>

				<ModalWrapper size='sm' ref={createVariableRef} title={<FormattedMessage id='modals.variables.custom.create' />}>
					<ModalContent>
						<Typography variant='body2' className='custom-subtitle'>
							<FormattedMessage id='modals.variables.custom.subtitle' />
						</Typography>
						<div className='create-variable'>
							<div className='form-variable'>
								<Box>
									<Typography variant='h4'>
										<FormattedMessage id='modals.variables.create_variable.type' />
									</Typography>
									<Box px={2} py={1} width='100%' display='grid' gridTemplateColumns='1fr 1fr 1fr 1fr' gap={3}>
										{_.map(VARIABLE_TYPE_OPTIONS, type => <Box key={type.value} display='flex' justifyContent='space-between' alignItems='center' onClick={() => handleChangeNewVariableType(type.value)} p={1} sx={{
                  border: `1.5px solid ${newVariable.type === type.value ? VARIABLES_TYPES_COLORS[type.value] : '#ccc'}`,
                  color: `${newVariable.type === type.value ? VARIABLES_TYPES_COLORS[type.value] : '#ccc'}`,
                  borderRadius: '4px',
                  cursor: 'pointer'
                }}>
												<b>{type.text}</b>
												{newVariable.type === type.value && <Check size='small' />}
											</Box>)}
									</Box>
									<Box height={30}>
										<Typography variant='body2'>
											<FormattedMessage id={`modals.variables.description.${newVariable.type}`} />
										</Typography>
									</Box>
								</Box>
								<Box mb={1}>
									<Typography variant='h4' gutterBottom>
										<FormattedMessage id='modals.variables.create_variable.name' />
									</Typography>

									<TextField variant='outlined' fullWidth error={fieldError} placeholder='Custom variable' InputProps={{
                disableUnderline: false
              }} value={newVariable.name} onChange={({
                target: {
                  value
                }
              }) => handleChangeNewVariableName(value)} helperText={fieldError} />
								</Box>
							</div>
							<Box display='flex' justifyContent='flex-end'>
								<Button variant='contained' color='primary' onClick={addNewVariable}>
									<FormattedMessage id='modals.variables.create_variable.create_btn' />
								</Button>
							</Box>
						</div>
					</ModalContent>
				</ModalWrapper>
			</>;
});
VariablesModal.propTypes = {
  currentVariables: PropTypes.array,
  currentVariable: PropTypes.string,
  allowType: PropTypes.string,
  assignVariable: PropTypes.func,
  removeVariable: PropTypes.func,
  addNewVariable: PropTypes.func,
  newVariable: PropTypes.object,
  handleChangeNewVariableName: PropTypes.func,
  handleChangeNewVariableType: PropTypes.func,
  fieldError: PropTypes.string,
  onClose: PropTypes.func
};
VariablesModal.displayName = 'VariablesModal';
export default VariablesModal;
function TrackingDetails({
  trackingDatas,
  pages
}) {
  const [states, setStates] = useState([]);
  useEffect(() => {
    setStates(getTrackingInfos(trackingDatas, pages));
  }, [trackingDatas]);
  function getTrackingInfos(trackingDatas, pages) {
    const infos = [];
    _.each(trackingDatas, tracking => {
      const currentPage = _.find(pages, ['_id', tracking.page._id]);
      if (currentPage) {
        const info = {
          page: {
            name: currentPage.name,
            type: currentPage.type
          }
        };
        if (tracking.field) {
          const formInteraction = _.find(currentPage.interactions, ['type', 'form']);
          if (formInteraction) {
            const currentField = _.find(formInteraction.fields, ['_id', tracking.field]);
            if (currentField) {
              info.field = currentField.type;
            }
          }
        }
        infos.push(info);
      }
    });
    return infos;
  }
  return <Box>
			<Box width='100%' textAlign='center' mb={0.5}>
				<Typography variant='h3'>
					<FormattedMessage id='modals.variables.tooltip.header' />
				</Typography>
			</Box>
			{_.map(states, (info, index) => <Box key={index} width='100%' mb={1}>
					<Typography variant='body2'>
						<b>
							<FormattedMessage id='modals.variables.tooltip.page_name' />
						</b>
						{info.page.name}
					</Typography>
					<Typography variant='body2'>
						<b>
							<FormattedMessage id='modals.variables.tooltip.page_type' />
						</b>
						{info.page.type}
					</Typography>
					{info.field && <Typography variant='body2'>
							<b>
								<FormattedMessage id='modals.variables.tooltip.field' />
							</b>
							{info.field}
						</Typography>}
				</Box>)}
		</Box>;
}
TrackingDetails.propTypes = {
  trackingDatas: PropTypes.array,
  pages: PropTypes.array
};