import _ from 'lodash';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { AmpInteractionBase, OptionsContainer, PromptTextContainer } from './style';
export default function PollInteraction({
  ampInteraction
}) {
  const intl = useIntl();
  const {
    _id,
    prompt_text,
    prompt_size,
    prompt_color,
    theme,
    backgroundColor,
    alignment,
    option_style,
    options,
    outer_style
  } = ampInteraction;
  return <AmpInteractionBase $backgroundColor={backgroundColor} $isTransparent={option_style === 'transparent'} style={outer_style}>
			{/* Prompt text */}
			<PromptTextContainer $promptColor={prompt_color} $alignment={alignment} $promptSize={prompt_size}>
				<p>{prompt_text}</p>
			</PromptTextContainer>

			{/* Options */}
			<OptionsContainer $theme={theme} $optionStyle={option_style} $isTransparent={option_style === 'transparent'}>
				{_.map(options, option => <div key={option._id} className='option-container'>
						<span>{option.title}</span>
					</div>)}
			</OptionsContainer>
		</AmpInteractionBase>;
}
PollInteraction.propTypes = {
  ampInteraction: PropTypes.object
};