import { ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import { Box, Button, Checkbox, DialogActions, DialogContent, FormControlLabel, FormGroup, FormLabel, IconButton, Link, SvgIcon, TextField, Tooltip, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ColorInput } from 'atoms';
import { CustomTab, CustomTabs } from 'components/atoms/CustomTabs';
import Hotspot from 'components/organisms/Hotspot';
import StoryViewerNotFoundPage from 'components/StoryViewerNotFoundPage';
import { ProjectsAccessWrapper } from 'components/styled/Modals';
import useClipboard from 'lib/hooks/useClipboard';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentOrganizationId } from 'redux/organization/selectors';
import OrganizationsSvc from 'services/OrganizationsSvc';
import { useImmer } from 'use-immer';
import { ModalWrapper } from '../ModalWrapper';
const EditDomainModal = forwardRef(({
  domain,
  onUpdate,
  onClose
}, ref) => {
  const confirmationModalRef = useRef();
  const organizationId = useSelector(getCurrentOrganizationId);
  const {
    data: projects = []
  } = useQuery({
    queryKey: ['projects', {
      organizationId
    }],
    queryFn: async ({
      queryKey
    }) => {
      const {
        organizationId
      } = queryKey[1];
      return OrganizationsSvc.getProjects(organizationId);
    }
  });
  const [tab, setTab] = useState('settings');
  const [currentDomain, setCurrentDomain] = useImmer({});
  const projectsToRemove = _.map(_.difference(domain?.accessibleProjects, currentDomain?.accessibleProjects), projectId => {
    const project = _.find(projects, {
      _id: projectId
    });
    return project?.name;
  });
  const toggleProjectAccess = useCallback(_id => () => {
    setCurrentDomain(draft => {
      if (!_.includes(draft.accessibleProjects, _id)) {
        draft.accessibleProjects.push(_id);
      } else {
        _.remove(draft.accessibleProjects, value => value === _id);
      }
    });
  }, [setCurrentDomain]);
  function onConfirm() {
    if (!_.isEmpty(projectsToRemove)) {
      // Open confirmation modal
      confirmationModalRef.current?.open();
      return;
    }
    confirmUpdate();
  }
  function confirmUpdate() {
    onUpdate(currentDomain);
    ref.current?.close();
    confirmationModalRef.current?.close();
  }
  return <>
			<ModalWrapper size='md' ref={ref} title={<FormattedMessage id='modals.story_custom_domain.header' />} onOpen={() => {
      setCurrentDomain(() => domain);
    }} onClose={() => {
      onClose();
      setCurrentDomain(() => {});
    }}>
				<Box sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        p: 3,
        pt: 0,
        overflowY: 'auto'
      }}>
					<CustomTabs centered value={tab} onChange={(_evt, newValue) => setTab(newValue)} sx={{
          alignSelf: 'center'
        }}>
						<CustomTab value='settings' label='Sitemap & Workspaces' />
						<CustomTab value='not_found_page' label='Not found page' />
					</CustomTabs>

					{tab === 'settings' && <Settings currentDomain={currentDomain} projects={projects} toggleProjectAccess={toggleProjectAccess} />}

					{tab === 'not_found_page' && <NotFoundPageEditor currentDomain={currentDomain} setCurrentDomain={setCurrentDomain} />}
				</Box>

				<DialogActions>
					<Button variant='text' onClick={() => ref.current?.close()}>
						<FormattedMessage id='button.cancel' />
					</Button>

					<Button variant='outlined' color='secondary' onClick={onConfirm} disabled={_.isEqual(domain, currentDomain)}>
						<FormattedMessage id='button.save' />
					</Button>
				</DialogActions>
			</ModalWrapper>

			<ModalWrapper size='sm' ref={confirmationModalRef} title={<FormattedMessage id='domain.edit_modal.confirmation.title' />}>
				<DialogContent dividers sx={{
        fontSize: 16
      }}>
					<FormattedMessage id='domain.edit_modal.confirmation.projects_list' />
					<ul>
						{_.map(projectsToRemove, (projectName, index) => <li key={index}>{projectName}</li>)}
					</ul>

					<FormattedMessage id='domain.edit_modal.confirmation.confirm_text' />
				</DialogContent>
				<DialogActions>
					<Button variant='text' onClick={() => {
          confirmationModalRef.current?.close();
        }}>
						<FormattedMessage id='common.no' />
					</Button>
					<Button variant='outlined' color='secondary' onClick={confirmUpdate}>
						<FormattedMessage id='common.yes' />
					</Button>
				</DialogActions>
			</ModalWrapper>
		</>;
});
EditDomainModal.propTypes = {
  domain: PropTypes.object,
  onUpdate: PropTypes.func,
  onClose: PropTypes.func
};
EditDomainModal.displayName = 'EditDomainModal';
export default EditDomainModal;
function Settings({
  currentDomain,
  projects,
  toggleProjectAccess
}) {
  const {
    copyText
  } = useClipboard();
  const sitemapUrl = `https://${currentDomain?.fqdn}/sitemap.xml`;
  return <Box sx={{
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 3,
    overflowY: 'auto'
  }}>
			<Box fontSize={14} mb={3}>
				<Box fontSize={16} fontWeight='bold'>
					Sitemap
				</Box>

				<Box display='flex' alignItems='center'>
					<span>{sitemapUrl}</span>

					<Tooltip title={<FormattedMessage id='common.copy' />} placement='top'>
						<IconButton onClick={() => copyText(sitemapUrl)} size='large' sx={{
            ml: 1
          }}>
							<SvgIcon fontSize='small'>
								<ClipboardDocumentIcon />
							</SvgIcon>
						</IconButton>
					</Tooltip>
				</Box>

				<Link href='https://help.snackeet.com/en/articles/6948510-how-to-submit-your-sitemap-to-google-search-console' target='_blank' underline='hover'>
					💡 &nbsp; <FormattedMessage id='domains.edit_modal.article_sitemap_link' />
				</Link>
			</Box>

			{projects?.length > 1 && <Box sx={{
      overflowY: 'auto'
    }}>
					<FormLabel component='legend'>
						<Box fontSize={16} fontWeight='bold' mb={1}>
							<FormattedMessage id='domains.edit_modal.workspaces' />
						</Box>
						<Typography variant='body1'>
							<FormattedMessage id='modals.create_custom_domain.access_admin' />
						</Typography>
					</FormLabel>
					<ProjectsAccessWrapper component='fieldset'>
						<FormGroup>
							{_.map(projects?.slice(0, Math.ceil(projects.length / 2)), project => <FormControlLabel key={project._id} control={<Checkbox checked={_.includes(currentDomain?.accessibleProjects, project._id)} name={project._id} onChange={toggleProjectAccess(project._id)} />} label={project.name} />)}
						</FormGroup>
						<FormGroup>
							{_.map(projects?.slice(Math.ceil(projects.length / 2)), project => <FormControlLabel key={project._id} control={<Checkbox checked={_.includes(currentDomain?.accessibleProjects, project._id)} name={project._id} onChange={toggleProjectAccess(project._id)} />} label={project.name} />)}
						</FormGroup>
					</ProjectsAccessWrapper>
				</Box>}
		</Box>;
}
function NotFoundPageEditor({
  currentDomain,
  setCurrentDomain
}) {
  const notFoundPage = currentDomain?.notFoundPage;
  const handleTextChange = useCallback(field => ({
    target: {
      value
    }
  }) => {
    setCurrentDomain(draft => {
      _.set(draft, `notFoundPage.${field}`, value);
    });
  }, [setCurrentDomain]);
  return <Box>
			<Box sx={{
      display: 'grid',
      gridTemplateColumns: '1fr 1fr'
    }}>
				<Box sx={{
        display: 'grid',
        gridTemplateColumns: '130px 1fr',
        gap: 2,
        alignContent: 'start',
        alignItems: 'end'
      }}>
					<Typography variant='body1' component='label'>
						<FormattedMessage id='domains.edit_modal.notFoundPage.field.accentColor' />
					</Typography>
					<ColorInput hasWrapper={false} hasLabel={false} value={notFoundPage?.accentColor ?? '#ff8008'} updateValue={value => setCurrentDomain(draft => {
          _.set(draft, `notFoundPage.accentColor`, value);
        })} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }} transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }} />

					<Typography variant='body1' component='label' sx={{
          display: 'flex',
          gap: 1
        }}>
						<FormattedMessage id='domains.edit_modal.notFoundPage.field.title' />
						<Hotspot message='domains.edit_modal.notFoundPage.tooltip.title' placement='bottom-end' />
					</Typography>
					<TextField variant='standard' value={notFoundPage?.title} onChange={handleTextChange('title')} />

					<Typography variant='body1' component='label'>
						<FormattedMessage id='domains.edit_modal.notFoundPage.field.subHeader' />
					</Typography>
					<TextField variant='standard' value={notFoundPage?.subHeader} onChange={handleTextChange('subHeader')} />

					<Typography variant='body1' component='label'>
						<FormattedMessage id='domains.edit_modal.notFoundPage.field.ctaText' />
					</Typography>
					<TextField variant='standard' value={notFoundPage?.ctaText} onChange={handleTextChange('ctaText')} />

					<Typography variant='body1' component='label'>
						<FormattedMessage id='domains.edit_modal.notFoundPage.field.ctaUrl' />
					</Typography>
					<TextField label='' variant='standard' value={notFoundPage?.ctaUrl} onChange={handleTextChange('ctaUrl')} />
				</Box>

				<Box sx={{
        display: 'grid',
        placeItems: 'center'
      }}>
					<StoryViewerNotFoundPage {...notFoundPage} />
				</Box>
			</Box>
		</Box>;
}