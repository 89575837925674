import { Button, TextField } from '@mui/material';
import styled from '@styled';
export const NumberInputContainer = styled.div`
	height: 30px;
	width: 90%;
	display: grid;
	grid-template-columns: 1fr 1.5fr 1fr;
	color: ${({
  theme
}) => theme.palette.divider};
`;
const NumberInputButton = styled(Button)`
	height: 30px;
	min-width: 45px;
	box-shadow: none;
	background-color: ${({
  theme
}) => theme.palette.grey[100]};
	color: ${({
  theme
}) => theme.palette.text.secondary};

	&:hover {
		background-color: ${({
  theme
}) => theme.palette.grey[200]};
	}

	&:disabled {
		cursor: not-allowed;
		pointer-events: auto;
		background-color: ${({
  theme
}) => theme.palette.action.disabled};
	}
`;
NumberInputButton.defaultProps = {
  size: 'small',
  variant: 'outlined',
  color: 'inherit'
};
export const DecreaseButton = styled(NumberInputButton)`
	border-radius: 4px 0px 0px 4px;
	border-right: none;
`;
export const IncreaseButton = styled(NumberInputButton)`
	border-radius: 0px 4px 4px 0px;
	border-left: none;
`;
export const NumberInputTextField = styled(TextField)`
	.MuiInputBase-root {
		background-color: ${({
  theme
}) => theme.palette.background.paper};
		height: 30px;
		border-radius: 0px;
	}

	input {
		padding: 0 4px;
		text-align: center;
		font-size: 14px;
	}
`;
NumberInputTextField.defaultProps = {
  variant: 'outlined',
  type: 'number',
  fullWidth: true
};
export const NumberInputOption = styled.div`
	cursor: pointer;
	width: 80px;
	padding: ${({
  theme
}) => theme.spacing(0.5, 1.5)};
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 14px;

	&:hover {
		${({
  $isActive,
  theme
}) => !$isActive && `background-color: ${theme.palette.action.hover};`}
	}

	${({
  $isActive,
  theme
}) => $isActive && `background-color: ${theme.palette.action.selected};;`}
`;