// Required: project, projects list, organization_id, customer role in organization
// Via role of client in organization -> 2 cases
// Case admin or super_admin: Display projects list to choose accessible projects
// Case agent: Only current project
// Then confirmation step and loading

import { Box, Button, CircularProgress, DialogActions, TextField, Typography } from '@mui/material';
import { ModalContent } from 'components/styled/Modals';
import { sleep } from 'lib/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentOrganizationId } from 'redux/organization/selectors';
import { getCurrentProjectId } from 'redux/project/selectors';
import OrganizationsSvc from 'services/OrganizationsSvc';
import useAsyncEffect from 'use-async-effect';
import { useImmer } from 'use-immer';
import { ModalWrapper } from './ModalWrapper';
export default function CreateLeaderboardModal({
  onCreate,
  trigger
}) {
  const intl = useIntl();
  const modalWrapperRef = useRef();
  const organizationId = useSelector(getCurrentOrganizationId);
  const projectId = useSelector(getCurrentProjectId);
  const [states, setStates] = useImmer({
    name: '',
    organizationProjects: [],
    is_saving: false
  });
  useAsyncEffect(async isMounted => {
    try {
      const projects = await OrganizationsSvc.getProjects(organizationId);
      if (isMounted()) {
        setStates(draft => {
          draft.organizationProjects = projects;
        });
      }
    } catch (error) {}
  }, []);
  function handleChangeLeaderboardName(value) {
    setStates(draft => {
      draft.name = value;
    });
  }
  async function createNewLeaderboard() {
    setStates(draft => {
      draft.is_saving = true;
    });
    const data = await onCreate(states.name);
    await sleep(1000);
    setStates(draft => {
      draft.is_saving = false;
    });
    modalWrapperRef.current?.close();
  }
  return <>
			<ModalWrapper title={intl.messages['modals.create_leaderboard.title']} trigger={trigger} size='sm' ref={modalWrapperRef}>
				<ModalContent>
					<Box mb={1.5}>
						<Typography variant='h3' style={{
            marginBottom: '4px'
          }}>
							{intl.messages['modals.create_leaderboard.name_label']}
						</Typography>
						<TextField fullWidth variant='outlined' placeholder={intl.messages['modals.create_leaderboard.name_placeholder']} value={states.name} onChange={evt => handleChangeLeaderboardName(evt.target.value)} error={_.isEmpty(states.name)} />
					</Box>
				</ModalContent>

				<DialogActions>
					<Button variant='text' onClick={() => modalWrapperRef.current?.close()}>
						<FormattedMessage id='button.cancel' />
					</Button>
					<Button variant='outlined' color='secondary' onClick={() => createNewLeaderboard()} disabled={states.is_saving || _.isEmpty(states.name)}>
						{states.is_saving && <CircularProgress style={{
            marginRight: '8px'
          }} color='primary' size={20} />}
						<FormattedMessage id='button.confirm' />
					</Button>
				</DialogActions>
			</ModalWrapper>
		</>;
}
CreateLeaderboardModal.propTypes = {
  onCreate: PropTypes.func,
  trigger: PropTypes.object
};