import AddIcon from '@mui/icons-material/Add';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, Button, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { LimitedFeatureWrapper } from 'atoms';
import { PremiumChip } from 'atoms/Chips';
import Loader from 'components/Loader';
import ConfirmationModal from 'components/Modals/ConfirmationModal';
import ManageTemplateAccessModal from 'components/Modals/ManageTemplateAccessModal';
import { Figure } from 'components/styled';
import { TemplateCard, TemplatesWrapper } from 'components/styled/Modals';
import { NEW_TAGS, TAGS_TEMPLATE } from 'lib/constants';
import useSubscriptionFeatures from 'lib/hooks/useSubscriptionFeatures';
import _ from 'lodash';
import InstagramButton from 'organisms/buttons/InstagramButton';
import { useCallback, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { getCurrentOrganizationId } from 'redux/organization/selectors';
import { getCurrentProjectId } from 'redux/project/selectors';
import PublishedStoriesSvc from 'services/PublishedStoriesSvc';
import TemplatesSvc from 'services/TemplatesSvc';
import { useImmer } from 'use-immer';
import { TemplateSectionButtonsContainer, TemplateSectionCategory, TemplatesSection, TemplatesSelectionContainer } from './CreateStoryModal.style';
import StoryTemplatePreview from './StoryTemplatePreview';
const CUSTOM_TEMPLATE_SECTION = 'custom-templates';
const WAYPOINT_BOTTOM_OFFSET = '50%';
const WAYPOINT_TOP_OFFSET = '45%';
const template_tags = [...NEW_TAGS, ...TAGS_TEMPLATE];
export default function StoryTemplateSelection({
  instagramModalRef,
  storyType,
  onSelect
}) {
  const intl = useIntl();
  const previewDrawerRef = useRef();
  const confirmationRef = useRef();
  const accessModalRef = useRef();
  const organizationId = useSelector(getCurrentOrganizationId);
  const projectId = useSelector(getCurrentProjectId);
  const {
    featureSet: {
      instagram: hasInstagram,
      custom_templates: hasCustomTemplates
    }
  } = useSubscriptionFeatures();
  const [state, setState] = useImmer({
    currentSection: CUSTOM_TEMPLATE_SECTION,
    selectedTemplate: {}
  });
  const setCurrentSection = useCallback(value => setState(draft => {
    draft.currentSection = value;
  }), [setState]);
  const {
    data: defaultStoryTemplates
  } = useQuery({
    queryKey: ['defaultStoryTemplates'],
    queryFn: PublishedStoriesSvc.getTemplates
  });
  const {
    data: customStoryTemplates,
    isLoading: isLoadingCustomStoryTemplates
  } = useQuery({
    queryKey: ['customStoryTemplates', {
      organization_id: organizationId,
      project: projectId
    }],
    queryFn: ({
      queryKey
    }) => {
      if (!hasCustomTemplates) return Promise.resolve([]);
      const {
        project,
        organization_id
      } = queryKey[1];
      return TemplatesSvc.getStoryTemplates({
        project,
        organization_id
      });
    }
  });
  const queryClient = useQueryClient();
  const updateCustomStoryTemplateMutation = useMutation({
    mutationFn: TemplatesSvc.updateStoryTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['customStoryTemplates']
      });
    }
  });
  const deleteCustomStoryTemplateMutation = useMutation({
    mutationFn: TemplatesSvc.removeStoryTemplate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['customStoryTemplates']
      });
      // Always reset selectTemplate because it is selected before it is deleted
      setState(draft => {
        draft.selectedTemplate = {};
      });
      confirmationRef.current.close();
      previewDrawerRef.current.close();
    }
  });
  const filteredDefaultTemplates = _.filter(defaultStoryTemplates, {
    type: storyType
  });
  const filteredMyTemplates = _.filter(customStoryTemplates, template => template?.story?.type === storyType);
  const onWayPointEnter = useCallback(sectionId => () => {
    setCurrentSection(sectionId);
  }, [setCurrentSection]);
  const onSectionClick = useCallback(sectionId => () => {
    setCurrentSection(sectionId);
    const sectionNode = document.getElementById(`waypoint-${sectionId}`);
    if (sectionNode) {
      sectionNode.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  }, [setCurrentSection]);
  function onTemplateSelect(template) {
    setState(draft => {
      if (draft.selectedTemplate !== template._id) {
        draft.selectedTemplate = template;
      }
    });
    previewDrawerRef.current?.open();
  }
  function onTemplateConfirm() {
    if (state.selectedTemplate.story) {
      // Custom template
      onSelect({
        templateId: state.selectedTemplate._id,
        createType: 'myTemplate'
      });
    } else {
      // Default template
      onSelect({
        templateId: state.selectedTemplate.story_id,
        createType: 'defaultTemplate'
      });
    }
  }
  function onProjectAccessChange(project_id) {
    setState(draft => {
      if (!_.includes(draft.selectedTemplate.accessibleProjects, project_id)) {
        draft.selectedTemplate.accessibleProjects.push(project_id);
      } else {
        _.remove(draft.selectedTemplate.accessibleProjects, id => id === project_id);
      }
    });
  }
  return <TemplatesSelectionContainer>
			<List component='nav'>
				<LimitedFeatureWrapper disabled={!hasCustomTemplates}>
					<ListItemButton divider selected={state.currentSection === CUSTOM_TEMPLATE_SECTION} onClick={onSectionClick(CUSTOM_TEMPLATE_SECTION)}>
						<ListItemText primary={<>
									{intl.messages['modals.templates.tabs.custom']} {`(${filteredMyTemplates.length})`}
								</>} />
						<PremiumChip />
					</ListItemButton>
				</LimitedFeatureWrapper>

				{template_tags.map((tag, index) => {
        const tagCount = getTagCount({
          tag,
          templates: defaultStoryTemplates,
          storyType
        });
        return tagCount > 0 ? <ListItemButton key={index} selected={state.currentSection === tag} onClick={onSectionClick(tag)}>
							<ListItemText primaryTypographyProps={{
            sx: {
              fontWeight: NEW_TAGS.includes(tag) ? 'bold' : 'normal'
            }
          }} primary={getTagLabel({
            intl,
            tag,
            tagCount
          })} />
						</ListItemButton> : null;
      })}
			</List>

			<div>
				<TemplateSectionButtonsContainer>
					{/* CREATE FROM SCRATCH */}
					<Typography variant='h2'>
						<FormattedMessage id='modals.create_snackeet.steps.choose_one' />
					</Typography>
					<Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={() => onSelect({
          templateId: '',
          createType: 'scratch'
        })} data-intercom-target='From Scratch'>
						<FormattedMessage id='modals.create_snackeet.steps.from_scratch' />
					</Button>

					<Typography variant='h2'>
						<FormattedMessage id='modals.create_snackeet.steps.or' />
					</Typography>

					{/* CREATE FROM INSTAGRAM IMPORT */}
					<LimitedFeatureWrapper placement='top' disabled={!hasInstagram}>
						<InstagramButton startIcon={<InstagramIcon />} onClick={() => {
            if (!hasInstagram) return;
            instagramModalRef.current?.open();
          }} sx={{
            cursor: hasInstagram ? 'pointer' : 'not-allowed'
          }}>
							<FormattedMessage id='modals.create_snackeet.steps.from_instagram' />
						</InstagramButton>
					</LimitedFeatureWrapper>
				</TemplateSectionButtonsContainer>

				<TemplatesSection>
					<Waypoint key={CUSTOM_TEMPLATE_SECTION} bottomOffset={WAYPOINT_BOTTOM_OFFSET} topOffset={WAYPOINT_TOP_OFFSET} onEnter={onWayPointEnter(CUSTOM_TEMPLATE_SECTION)}>
						<div id={`waypoint-${CUSTOM_TEMPLATE_SECTION}`}>
							<TemplateSectionCategory>
								{intl.messages['modals.templates.tabs.custom']}
								<Box ml={1}>
									<PremiumChip size='medium' />
								</Box>
							</TemplateSectionCategory>

							<TemplatesWrapper>
								{isLoadingCustomStoryTemplates ? <Loader $heightPx={100} size={50} name='ThreeBounce' /> : _.isEmpty(filteredMyTemplates) ? <Figure height={100}>
										<img src='/static/placeholders/empty_folder.svg' />
										<figcaption>
											<FormattedMessage id='modals.templates.story.empty' />
										</figcaption>
									</Figure> : _.map(filteredMyTemplates, template => <TemplateCard key={template._id} onClick={() => onTemplateSelect(template)}>
											<img src={template.story.snapshot_url_png || '/static/images/vertical-placeholder.jpeg'} alt={template.name} />

											<Button variant='contained' color='secondary'>
												<FormattedMessage id='common.preview' />
											</Button>

											<figcaption>{template.name}</figcaption>
										</TemplateCard>)}
							</TemplatesWrapper>
						</div>
					</Waypoint>

					{template_tags.map((tag, index) => {
          const relevantTemplates = _.filter(filteredDefaultTemplates, template => _.includes(template.tags, tag));
          return relevantTemplates.length > 0 ? <Waypoint key={index} bottomOffset={WAYPOINT_BOTTOM_OFFSET} topOffset={WAYPOINT_TOP_OFFSET} onEnter={onWayPointEnter(tag)}>
								<div id={`waypoint-${tag}`}>
									<TemplateSectionCategory>{getTagLabel({
                  intl,
                  tag
                })}</TemplateSectionCategory>

									<TemplatesWrapper>
										{_.map(relevantTemplates, (template, templateIndex) => <TemplateCard key={template.story_id} onClick={() => onTemplateSelect(template)} data-intercom-target={tag === 'engagement' && index === 0 && templateIndex === 0 ? 'First Story Template' : undefined}>
												<img src={template.snapshot_url_png || '/static/images/vertical-placeholder.jpeg'} alt={template.name} />

												<Button variant='contained' color='secondary'>
													<FormattedMessage id='common.preview' />
												</Button>

												<figcaption title={template.name}>{template.name}</figcaption>
											</TemplateCard>)}
									</TemplatesWrapper>
								</div>
							</Waypoint> : null;
        })}
				</TemplatesSection>
			</div>

			<ConfirmationModal size='sm' confirmationModalRef={confirmationRef} title={<FormattedMessage id='modals.templates.delete.title' />} onConfirm={() => deleteCustomStoryTemplateMutation.mutate({
      project: projectId,
      organization_id: organizationId,
      storyTemplate_id: state.selectedTemplate?._id
    })} firstLine={<FormattedMessage id='modal.file_deletion.confirmation.sub_header' />} secondLine={<FormattedMessage id='modal.file_deletion.confirmation.instructions' />} confirmText={<FormattedMessage id='common.yes' />} cancelText={<FormattedMessage id='common.no' />} />
			<ManageTemplateAccessModal ref={accessModalRef} templateToUpdate={state.selectedTemplate} onProjectAccessChange={onProjectAccessChange} onConfirm={() => updateCustomStoryTemplateMutation.mutate({
      project: projectId,
      organization_id: organizationId,
      storyTemplate: state.selectedTemplate
    })} />
			<StoryTemplatePreview ref={previewDrawerRef} selectedTemplate={state.selectedTemplate} onTemplateConfirm={onTemplateConfirm} openConfirmationModal={() => confirmationRef.current.open()} openAccessModal={() => accessModalRef.current.open()} />
		</TemplatesSelectionContainer>;
}

// Helper functions
function getTagLabel({
  intl,
  tag,
  tagCount
}) {
  let label = intl.messages[`common.story_tag.${tag}`];
  if (tagCount) {
    label += ` (${tagCount})`;
  }
  return label;
}
function getTagCount({
  tag,
  templates,
  storyType
}) {
  // Return positive number for "all" tag to be displayed in view
  if (tag === 'all') return 1;
  return _.reduce(templates, (sum, template) => template.type === storyType && template.tags?.includes(tag) ? sum + 1 : sum, 0);
}