import { LockOutlined } from '@mui/icons-material';
import { Alert, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
export default function LimitedFeatureWrapper({
  disabled = false,
  placement = 'bottom',
  title,
  children
}) {
  if (disabled) {
    return <Tooltip placement={placement} title={title ?? <Alert severity='warning' icon={<LockOutlined />}>
							<Typography variant='body2'>
								<FormattedMessage id='subscription.features.disabled' />
							</Typography>
						</Alert>}>
				<div style={{
        cursor: 'not-allowed'
      }}>{children}</div>
			</Tooltip>;
  }
  return children;
}
LimitedFeatureWrapper.propTypes = {
  disabled: PropTypes.bool,
  placement: PropTypes.string,
  title: PropTypes.node,
  children: PropTypes.node
};