const DB_NAME = 'Snackeet'
const OBJECT_STORE_NAME = 'pageTemplates'

function openDatabase() {
	return new Promise((resolve, reject) => {
		const request = indexedDB.open(DB_NAME, 1)

		request.onerror = (event) => {
			reject(`Error opening database: ${event.target.error}`)
		}

		request.onsuccess = (event) => {
			const db = event.target.result
			resolve(db)
		}

		request.onupgradeneeded = (event) => {
			const db = event.target.result
			db.createObjectStore(OBJECT_STORE_NAME, { autoIncrement: true })
		}
	})
}

export async function saveToIndexedDB(data) {
	const db = await openDatabase()
	const transaction = db.transaction([OBJECT_STORE_NAME], 'readwrite')
	const objectStore = transaction.objectStore(OBJECT_STORE_NAME)

	objectStore.put(data, 1) // Assuming the ID is 1, modify as needed

	await transaction.complete
}

export async function fetchFromIndexedDB() {
	const db = await openDatabase()
	const transaction = db.transaction([OBJECT_STORE_NAME], 'readonly')
	const objectStore = transaction.objectStore(OBJECT_STORE_NAME)

	return new Promise((resolve, reject) => {
		const request = objectStore.get(1) // Assuming the ID is 1, modify as needed

		request.onsuccess = (event) => {
			resolve(event.target.result)
		}

		request.onerror = (event) => {
			reject(`Error fetching data: ${event.target.error}`)
		}
	})
}

// Clear indexedDB
export async function clearIndexedDB() {
	const request = indexedDB.deleteDatabase(DB_NAME)

	return new Promise((resolve, reject) => {
		request.onerror = function (event) {
			reject(`Error deleting database: ${event.target.error}`)
		}

		request.onsuccess = function () {
			resolve()
		}
	})
}
