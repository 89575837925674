import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogTitle, IconButton, Slide, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import styled from '@styled';
import { cloneElement, forwardRef, useImperativeHandle, useState } from 'react';
import { snackeet_dark_grey } from 'styles/colors';
const Transition = forwardRef(function Transition(props: TransitionProps & {
  children: React.ReactElement<any, any>;
}, ref: React.Ref<unknown>) {
  return <Slide direction='up' ref={ref} {...props} />;
});
export interface ModalWrapperProps {
  trigger?: React.ReactNode;
  title?: string | React.ReactNode;
  children?: React.ReactNode;
  size?: string;
  hasCloseButton?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  extendMaxWidth?: boolean;
  fullScreen?: boolean;
  disableBackdropClick?: boolean;
  disableEscapeKeyDown?: boolean;
  sx?: SxProps<Theme>;
}
type CloseFunctionType = (evt?: React.MouseEvent<HTMLElement> | {}, reason?: string) => void;
export interface ModalWrapperRef {
  open: () => void;
  close: CloseFunctionType;
  isOpened: boolean;
}
export const ModalWrapper = forwardRef(({
  trigger,
  title,
  children,
  size = 'md',
  hasCloseButton = true,
  onOpen,
  onClose,
  extendMaxWidth = true,
  fullScreen = false,
  disableBackdropClick = false,
  disableEscapeKeyDown = false,
  sx,
  ...delegatedProps
}: ModalWrapperProps, ref: React.Ref<ModalWrapperRef | undefined>) => {
  const [isOpened, setIsOpened] = useState(false);
  const open = (evt?: React.MouseEvent<HTMLElement>) => {
    evt?.preventDefault && evt.preventDefault();
    setIsOpened(true);
    if (onOpen) {
      onOpen();
    }
  };
  const close: CloseFunctionType = (evt, reason) => {
    if (disableBackdropClick && reason === 'backdropClick') {
      return false;
    }
    if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
      return false;
    }
    if (evt instanceof Event) {
      evt.preventDefault();
    }
    setIsOpened(false);
    if (onClose) {
      onClose();
    }
  };
  useImperativeHandle(ref, () => ({
    open,
    close,
    isOpened
  }));
  const enhancedTrigger = trigger && cloneElement((trigger as React.ReactElement<any>), {
    onClick: open
  });
  return <>
				{enhancedTrigger}

				<StyledDialog fullWidth={extendMaxWidth} open={isOpened} TransitionComponent={Transition} maxWidth={(size as 'md')} fullScreen={fullScreen} onClose={close} disableRestoreFocus sx={sx} {...delegatedProps}>
					<ModalHeader close={close} hasCloseButton={hasCloseButton}>
						{title}
					</ModalHeader>

					{children}
				</StyledDialog>
			</>;
});
ModalWrapper.displayName = 'ModalWrapper';
interface ModalHeaderProps {
  children?: React.ReactNode;
  close: CloseFunctionType;
  hasCloseButton?: boolean;
}
export function ModalHeader({
  children,
  close,
  hasCloseButton = true
}: ModalHeaderProps) {
  return <StyledDialogTitle>
			<Typography sx={{
      fontSize: '20px'
    }} component='div'>
				{children}
			</Typography>
			{hasCloseButton && <Box sx={{
      position: 'absolute',
      right: 5,
      top: 0,
      height: '100%',
      display: 'grid',
      placeItems: 'center',
      color: 'grey.500'
    }}>
					<IconButton className='close-button' onClick={evt => close(evt, '')} size='large'>
						<Close />
					</IconButton>
				</Box>}
		</StyledDialogTitle>;
}
const StyledDialog = styled(Dialog)`
	.MuiDialogContent-root {
		border: none;
	}

	.MuiDialogActions-root {
		box-shadow: 0px -4px 2px -4px ${snackeet_dark_grey};
		margin-top: 4px;
	}
`;
const StyledDialogTitle = styled(DialogTitle)`
	position: relative;
	/* .MuiDialogTitle-root { */
	padding: ${({
  theme
}) => theme.spacing(1, 3, 1, 2)};
	/* } */

	@media (min-height: 580px) {
		/* .MuiDialogTitle-root { */
		padding: ${({
  theme
}) => theme.spacing(2, 3, 1, 2)};
		/* } */
	}

	@media (min-height: 730px) {
		/* .MuiDialogTitle-root { */
		padding: ${({
  theme
}) => theme.spacing(2, 4, 2, 2)};
		/* } */
	}
`;