import { css } from '@emotion/react';
import styled from '@styled';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import * as Icons from './custom';
const setHeight = ({
  height
}) => height && css`
		height: ${typeof height === 'string' ? height : `${height}px`};
		svg {
			height: ${typeof height === 'string' ? height : `${height}px`};
		}
	`;
const setWidth = ({
  width
}) => width && css`
		width: ${typeof width === 'string' ? width : `${width}px`};
		svg {
			width: ${typeof width === 'string' ? width : `${width}px`};
		}
	`;
const IconContainer = styled.div`
	font-size: ${({
  $fontSize
}) => $fontSize || 'inherit'};
	color: ${({
  $color
}) => $color};
	border-radius: 50%;
	padding: ${({
  theme,
  $padding
}) => theme.spacing($padding)};
	margin: ${({
  theme,
  $margin
}) => typeof $margin === 'string' ? $margin : theme.spacing($margin)};
	${setHeight}
	${setWidth}
`;
const CustomIcon = forwardRef(({
  name,
  icon,
  margin,
  padding,
  color,
  fontSize,
  ...props
}, ref) => {
  const IconComponent = icon || Icons[name];
  return IconComponent && <IconContainer ref={ref} $margin={margin} $color={color} $padding={padding} $fontSize={fontSize} {...props}>
				<IconComponent />
			</IconContainer>;
});
CustomIcon.propTypes = {
  name: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  padding: PropTypes.number,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.any,
  color: PropTypes.string
};
CustomIcon.defaultProps = {
  padding: 0,
  margin: 0,
  height: 20,
  width: 20,
  color: 'inherit'
};
CustomIcon.displayName = 'CustomIcon';
export default CustomIcon;