import * as React from 'react'

function Svg10Number(props) {
	return (
		<svg width='1.3em' height='1em' viewBox='0 0 7 6' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
			<path
				d='M2.267 5h-.545V1.388l-1.093.401v-.492L2.182.714h.085V5zm4.262-1.82c0 .635-.108 1.107-.325 1.416-.217.308-.556.463-1.016.463-.456 0-.792-.15-1.011-.452-.219-.302-.332-.753-.34-1.353V2.53c0-.627.108-1.092.325-1.397.217-.305.557-.457 1.02-.457.459 0 .797.147 1.013.442.217.293.328.746.334 1.36v.703zm-.542-.74c0-.46-.064-.794-.193-1.002-.129-.211-.333-.317-.612-.317-.278 0-.48.105-.607.314-.127.209-.192.53-.196.963v.868c0 .46.066.801.2 1.022.134.219.337.328.609.328.267 0 .465-.103.594-.31.131-.207.2-.534.205-.979V2.44z'
				fill='#000'
			/>
		</svg>
	)
}

export default Svg10Number
