import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { Box, Button, DialogActions, DialogContent, List, ListItem, ListItemText, Typography } from '@mui/material';
import styled from '@styled';
import SubmitButton from 'components/organisms/buttons/SubmitButton';
import PagePreview from 'components/PagePreview/index';
import { StickyCheck } from 'components/styled';
import { saveAs } from 'file-saver';
import { PREVIEW_HEIGHT, PREVIEW_WIDTH } from 'lib/constants';
import { useStoryState } from 'lib/editorStore';
import { extractFileName } from 'lib/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCurrentProjectId } from 'redux/project/selectors';
import ExportSvc from 'services/ExportSvc';
import { useImmer } from 'use-immer';
import { ModalWrapper } from '../ModalWrapper';
const DESCRIPTION_SECONDARY_PROPS = {
  variantMapping: {
    body2: 'div'
  }
};
const ExportPagesModal = forwardRef(function ExportPagesModal(_props, ref) {
  const project = useSelector(getCurrentProjectId);
  const pages = useStoryState(s => s.draftStory.pages);
  const theming = useStoryState(s => s.draftStory.theming);
  const messages = useStoryState(s => s.draftStory.messages);
  const gdprConsent = useStoryState(s => s.draftStory.gdprConsent);
  const storyId = useStoryState(s => s.draftStory.story_id);
  const storyType = useStoryState(s => s.draftStory.type);
  const [state, setState] = useImmer({
    isExporting: false,
    selectedPages: []
  });
  const onConfirm = useCallback(async () => {
    setState(draft => {
      draft.isExporting = true;
    });
    const {
      data,
      headers
    } = await ExportSvc.exportStory({
      project,
      story_id: storyId,
      format: 'png',
      pages: state.selectedPages
    });
    setState(draft => {
      draft.isExporting = false;
    });
    const filename = extractFileName(headers['content-disposition']);
    const blob = new Blob([data], {
      type: headers['content-type']
    });
    saveAs(blob, filename);
    ref.current.close();
  }, [project, state.selectedPages, storyId]);
  const handleSelection = useCallback(command => () => {
    setState(draft => {
      if (command === 'SELECT_ALL') {
        draft.selectedPages = _.map(pages, page => page._id);
      } else if (command === 'UNSELECT_ALL') {
        draft.selectedPages = [];
      }
    });
  }, [pages, setState]);
  return <ModalWrapper size='md' ref={ref} title={<FormattedMessage id='modals.story_export.title' />}>
			<StyledDialogContent>
				<Box px={1} mb={1} fontSize={14} display='flex' alignItems='center' justifyContent='space-between'>
					<Box flex={1}>
						<p>
							<FormattedMessage id='modals.story_export.instructions' />
						</p>
					</Box>
					<Button size='small' startIcon={<CheckBox />} disabled={state.selectedPages.length === pages.length} onClick={handleSelection('SELECT_ALL')}>
						<FormattedMessage id='button.select_all' />
					</Button>
					<Button size='small' startIcon={<CheckBoxOutlineBlank />} disabled={state.selectedPages.length === 0} onClick={handleSelection('UNSELECT_ALL')}>
						<FormattedMessage id='button.unselect_all' />
					</Button>
				</Box>
				<PagesList pages={pages} theming={theming} messages={messages} gdprConsent={gdprConsent} storyType={storyType} selectedPages={state.selectedPages} setState={setState} />
			</StyledDialogContent>

			<DialogActions>
				<Button variant='text' onClick={() => ref.current?.close()}>
					<FormattedMessage id='button.cancel' />
				</Button>

				<SubmitButton variant='outlined' color='primary' onClick={onConfirm} disabled={_.isEmpty(state.selectedPages)} loading={state.isExporting}>
					<FormattedMessage id='button.confirm' />
				</SubmitButton>
			</DialogActions>
		</ModalWrapper>;
});
export default ExportPagesModal;
function PagesList({
  pages,
  theming,
  messages,
  gdprConsent,
  storyType,
  selectedPages,
  setState
}) {
  const handleItemClick = useCallback(page_id => () => {
    setState(draft => {
      if (draft.selectedPages.includes(page_id)) {
        _.remove(draft.selectedPages, _id => _id === page_id);
      } else {
        draft.selectedPages.push(page_id);
      }
    });
  }, [setState]);
  if (_.isEmpty(pages)) {
    return <Box width='100%' height='200px' display='flex' justifyContent='center' alignItems='center'>
				<Typography color='textSecondary' variant='h2'>
					<FormattedMessage id='leaderboard.stories_modal.no_stories' />
				</Typography>
			</Box>;
  }
  return <StyledList>
			{_.map(pages, page => {
      const isSelected = selectedPages.includes(page._id);
      return <ListItem button key={page._id} onClick={handleItemClick(page._id)}>
						<Box position='relative' textAlign='center' margin='auto'>
							<PageCard $isSelected={isSelected}>
								<StickyCheck sx={{
              opacity: isSelected ? 1 : 0,
              transition: 'opacity 300ms ease-in'
            }} />
								<PagePreview size='medium' page={page} theming={theming} messages={messages} gdprConsent={gdprConsent} storyType={storyType} />
							</PageCard>
							<ListItemText primary={<b>{page.name}</b>} secondaryTypographyProps={DESCRIPTION_SECONDARY_PROPS} />
						</Box>
					</ListItem>;
    })}
		</StyledList>;
}
PagesList.propTypes = {
  pages: PropTypes.array,
  theming: PropTypes.object,
  messages: PropTypes.object,
  gdprConsent: PropTypes.object,
  storyType: PropTypes.string,
  selectedPages: PropTypes.array,
  setState: PropTypes.func
};
const StyledList = styled(List)`
	display: grid;
	grid-template-columns: repeat(3, minmax(auto, 230px));
	grid-gap: 16px;
	justify-content: center;
	grid-auto-flow: dense;
	overflow: auto;

	.MuiListItemText-primary {
		padding-left: ${({
  theme
}) => theme.spacing(0.5)};
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		&:hover {
			white-space: initial;
		}
	}

	.MuiListItemText-secondary {
		.MuiSvgIcon-root {
			margin-right: ${({
  theme
}) => theme.spacing(1)};
		}
	}
`;
const StyledDialogContent = styled(DialogContent)`
	display: flex;
	flex-direction: column;
`;
const PageCard = styled.div`
	margin: auto;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	aspect-ratio: ${PREVIEW_WIDTH} / ${PREVIEW_HEIGHT};
	border-radius: 8px;
	overflow: hidden;
	outline: ${({
  $isSelected,
  theme
}) => $isSelected ? `2px solid ${theme.palette.primary.dark}` : 'none'};
`;