import { Box } from '@mui/material';
import styled from '@styled';
export const MediaPreviewContainer = styled(Box)`
	position: relative;
	display: flex;
	place-items: center;
	background-color: linear-gradient(
		90deg,
		rgba(0, 0, 0, 0.8) 0%,
		rgba(255, 255, 255, 1) 50%,
		rgba(0, 0, 0, 0.8) 100%
	);
	cursor: pointer;
	overflow: hidden;

	img,
	video {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
`;
MediaPreviewContainer.defaultProps = {
  height: '100px',
  width: '100%'
};
export const Dimmer = styled.div`
	position: absolute;
	inset: 0;
	background-color: rgba(0, 0, 0, 0.3);
	color: white;
`;

// Like AssetNameContainer from MediaComponents
export const AssetName = styled.div`
	width: 100%;
	position: absolute;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(3px);
	color: #fff;

	span {
		width: 100%;
		padding: 2px 4px 1px;
		max-height: 4em;
		font-size: 10px;
		line-height: 1.4em;
		word-wrap: break-word;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
		font-family: Poppins, sans-serif;
	}
`;
export const AssetPlaceholderContainer = styled.div`
	position: absolute;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
	background-color: rgba(0, 0, 0, 0.4);
	color: white;
	padding: ${({
  theme
}) => theme.spacing(1)};
	margin: 1px;

	&:hover {
		outline: 1px solid ${({
  theme
}) => theme.palette.text.primary};
	}

	@container (max-width: 150px) {
		p {
			display: none;
		}
	}
`;
export const ContainerQueryWrapper = styled.div`
	container-type: inline-size;
	width: 100%;
	height: 100%;
`;