import { PREVIEW_LEADERBOARD_URL } from 'lib/constants';
import { extractPositionProperties } from 'lib/utils';
import PropTypes from 'prop-types';
import BlockWrapper from './BlockWrapper';
const leaderboardStyle = {
  border: 'none',
  width: '100%',
  height: '100%'
};
export default function Leaderboard({
  value,
  outer_style,
  animation
}) {
  const leaderboardURL = `${PREVIEW_LEADERBOARD_URL}/${value}`;
  const {
    positionProperties,
    visualProperties
  } = extractPositionProperties(outer_style);
  return <BlockWrapper animation={animation} positionProperties={positionProperties}>
			<div style={{
      ...visualProperties
    }}>
				<iframe src={leaderboardURL} style={leaderboardStyle} />
			</div>
		</BlockWrapper>;
}
Leaderboard.propTypes = {
  value: PropTypes.string,
  outer_style: PropTypes.object,
  animation: PropTypes.object
};