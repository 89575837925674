import { extractPositionProperties } from 'lib/utils';
import PropTypes from 'prop-types';
import BlockWrapper from './BlockWrapper';
const iframeStyle = {
  border: 'none',
  width: '100%',
  height: '100%'
};
export default function Location({
  value,
  outer_style,
  animation
}) {
  const {
    positionProperties,
    visualProperties
  } = extractPositionProperties(outer_style);
  if (isValidLocationEmbed(value)) {
    return <BlockWrapper animation={animation} positionProperties={positionProperties}>
				<div style={{
        ...visualProperties,
        overflow: 'hidden'
      }}>
					<iframe src={isValidLocationEmbed(value) === 'regex 1' ? extractMapSrc(value) : value} style={iframeStyle} />
				</div>
			</BlockWrapper>;
  }
  return null;
}
Location.propTypes = {
  value: PropTypes.string,
  outer_style: PropTypes.object,
  animation: PropTypes.object,
  required: PropTypes.bool
};
function extractMapSrc(str) {
  return str.split('src=')[1].split('><')[0].split('"')[1];
}
export function isValidLocationEmbed(str) {
  const regex1 = /<iframe\s*src="https:\/\/www\.google\.com\/maps\/embed\?[^"]+"*\s*[^>]+>*<\/iframe>/gm;
  const regex2 = /https:\/\/www\.google\.com\/maps\/embed\?[^"]+/gm;
  if (str.match(regex1)) {
    return 'regex 1';
  } else if (str.match(regex2)) {
    return 'regex 2';
  }
  return false;
}